import { Editor, Element, Transforms, Range, Path, NodeEntry, Node } from '../TypedSlate';
import { ICustomDescendant, ICustomEditor, ICustomElement, ListStyleTypes } from '../models';
import {
  IListElement,
  IListItemElement,
  IParagraphElement,
  OrderedListTypes,
  UnorderedListTypes,
} from '../models/elements';

const ORDERED_LIST_TYPES: ReadonlyArray<OrderedListTypes> = [
  'decimal',
  'decimalLeadingZero',
  'lowerLatin',
  'upperLatin',
  'lowerRoman',
  'upperRoman',
];

const UNORDERED_LIST_TYPES: ReadonlyArray<UnorderedListTypes> = [
  'square',
  'circle',
  'disc',
];

export const isList = (node: ICustomDescendant): node is IListElement => {
  return (node as ICustomElement).type === 'list';
};

export const isListItem = (node: ICustomDescendant): node is IListItemElement => {
  return (node as ICustomElement).type === 'listItem';
};

export const isOrderedList = (type: ListStyleTypes): type is OrderedListTypes => {
  return ORDERED_LIST_TYPES.includes(type as any);
};

export const isUnorderedList = (type: ListStyleTypes): type is UnorderedListTypes => {
  return UNORDERED_LIST_TYPES.includes(type as any);
};

export const isListActive = (editor: ICustomEditor) => {
  const [list] = Editor.nodes(editor, {
    match: n =>
      !Editor.isEditor(n) && Element.isElement(n) && isList(n),
  });
  return !!list;
};

export const isSpecificListActive = (editor: ICustomEditor, listStyleType: ListStyleTypes) => {
  const [list] = Editor.nodes(editor, {
    match: n =>
      !Editor.isEditor(n) && Element.isElement(n) && isList(n) && n.listStyleType === listStyleType,
  });
  return !!list;
};

export const isOrderedListActive = (editor: ICustomEditor) => {
  const [list] = Editor.nodes(editor, {
    match: n =>
      !Editor.isEditor(n) && Element.isElement(n) && isList(n) && isOrderedList(n.listStyleType),
  });
  return !!list;
};

export const isUnorderedListActive = (editor: ICustomEditor) => {
  const [list] = Editor.nodes(editor, {
    match: n =>
      !Editor.isEditor(n) && Element.isElement(n) && isList(n) && isUnorderedList(n.listStyleType),
  });
  return !!list;
};

export const getActiveListType = (editor: ICustomEditor) => {
  const lists = Editor.nodes<IListElement>(editor, {
    match: n =>
      !Editor.isEditor(n) && Element.isElement(n) && isList(n),
  });
  const firstList = lists.next().value;
  if (firstList) {
    const [list] = firstList;
    return list.listStyleType;
  }
  return null;
};

export const toggleList = (editor: ICustomEditor, subType: ListStyleTypes) => {
  const isAlreadyActive = isSpecificListActive(editor, subType);
  if (isAlreadyActive) {
    removeList(editor);
    return;
  }

  const isAnyListActive = isListActive(editor);
  if (isAnyListActive) {
    updateList(editor, subType);
    return;
  }

  createList(editor, subType);
};

export const updateList = (editor: ICustomEditor, listStyleType: ListStyleTypes) => {
  const newValue: Partial<IListElement> = {
    listStyleType,
  };
  Transforms.setNodes(
    editor,
    newValue,
    {
      match: n => !Editor.isEditor(n) && Element.isElement(n) && isList(n),
    });
};

export const removeList = (editor: ICustomEditor) => {
  const isNested = getListNestingLevel(editor) > 1;

  if (isNested) {

    Transforms.unwrapNodes(editor, {
      mode: 'lowest',
      match: n => !Editor.isEditor(n) && Element.isElement(n) && isList(n),
      split: true,
    });

    const [listItem, listItemPath] = findAncestorListItem(editor);
    if (!listItem) return;

    const siblingListPath = Path.next(listItemPath);
    const hasSiblingList = Node.has(editor, siblingListPath);
    if (hasSiblingList) {
      const currentNbChild = listItem.children.length;
      Transforms.moveNodes(editor, { at: siblingListPath, to: listItemPath.concat(currentNbChild) });
    }

    const updatedListItem = Node.get(editor, listItemPath);
    Transforms.unwrapNodes(editor, {
      mode: 'lowest',
      match: n => !Editor.isEditor(n) && Element.isElement(n) && isListItem(n) && n !== updatedListItem,
      split: true,
    });

  } else {

    Transforms.unwrapNodes(editor, {
      mode: 'lowest',
      match: n => !Editor.isEditor(n) && Element.isElement(n) && isListItem(n),
      split: true,
    });
    Transforms.unwrapNodes(editor, {
      mode: 'lowest',
      match: n => !Editor.isEditor(n) && Element.isElement(n) && isList(n),
      split: true,
    });
  }
};

export const createList = (editor: ICustomEditor, listStyleType: ListStyleTypes) => {
  if (!editor.selection) {
    return;
  }
  if (Range.isCollapsed(editor.selection) || Path.equals(editor.selection.anchor.path, editor.selection.focus.path)) {
    // Find ancestor paragraph.
    // Wrap paragraph into listItem
    // Wrap listItem into list
    const paragraph = findAncestorParagraph(editor);

    const listItem: IListItemElement = { type: 'listItem', children: [] };
    Transforms.wrapNodes(editor, listItem,  { at: paragraph[1] });

    const list: IListElement = { listStyleType, type: 'list', children: [] };
    Transforms.wrapNodes(editor, list, { at: paragraph[1] });
  } else {
    const nodes = Editor.nodes<(IParagraphElement | IListElement)>(editor, {
      mode: 'highest',
      match: (n) => {
        return !Editor.isEditor(n) && Element.isElement(n) && (n.type === 'paragraph' || n.type === 'list');
      },
    });

    for (const [, path] of nodes) {
      const listItem: IListItemElement = { type: 'listItem', children: [] };
      Transforms.wrapNodes(editor, listItem,  { at: path });
    }

    const list: IListElement = { listStyleType, type: 'list', children: [] };
    Transforms.wrapNodes(editor, list);
  }
};

export const nestList = (editor: ICustomEditor, listStyleType: ListStyleTypes) => {
  // create a new list as a child of the previous listItem,
  // and then add the current item to that new list.

  const [listItem, listItemPath] = findAncestorListItem(editor);
  if (!listItem) return;

  const previousSiblingPath = Path.previous(listItemPath);
  const previousSibling = Node.has(editor, previousSiblingPath) && Node.get(editor, previousSiblingPath) as IListItemElement;
  const previousSiblingHasNestedList =
    previousSibling &&
    isList(previousSibling.children[previousSibling.children.length - 1]);

  if (previousSiblingHasNestedList) {
    const previousNestedListPath = previousSiblingPath.concat([previousSibling.children.length - 1]);
    const previousNestedList = Node.get(editor, previousNestedListPath) as IListElement;
    const newPath = previousNestedListPath.concat(previousNestedList.children.length);
    Transforms.moveNodes(editor, { at: listItemPath, to: newPath });
    return;
  }

  const lastChildIndex = listItem.children.length - 1;
  const lastChild = listItem.children[lastChildIndex];
  let validatedListStyleType = listStyleType;
  if (isList(lastChild)) {
    validatedListStyleType = lastChild.listStyleType;
  }

  const list: IListElement = { listStyleType: validatedListStyleType, type: 'list', children: [] };
  const listInsertPath = Path.previous(listItemPath).concat(1);

  Transforms.moveNodes(editor, { at: listItemPath, to: listInsertPath });
  Transforms.wrapNodes(editor, list, { at: listInsertPath });

  if (isList(lastChild)) {
    const childListPath = listInsertPath.concat([0, lastChildIndex]);
    const childList = Node.get(editor, childListPath);
    Transforms.unwrapNodes(editor, {
      at: childListPath,
      match: n => n === childList,
      split: true,
    });
    Transforms.liftNodes(editor, { at: childListPath });
  }
};

export const createListItem = (editor: ICustomEditor) => {
  // split paragraph using insertBreak();
  // note where the current list item path
  // wrap the new paragraph with a new list item
  // move all the siblings to the new list item
  // move the new list item up in the hierarchy
  editor.insertBreak();
  const [currentListItem, currentListItemPath] = findAncestorListItem(editor);
  const listItem: IListItemElement = { type: 'listItem', children: [] };
  Transforms.wrapNodes(editor, listItem);
  currentListItem.children.slice(2).forEach(() => {
    Transforms.moveNodes(editor, { at: currentListItemPath.concat(2), to: currentListItemPath.concat([1, 1]) });
  });
  Transforms.liftNodes(editor, { at: currentListItemPath.concat(1) });
};

export const convertListStyleTypeToCSS = (listStyleType: ListStyleTypes) => {
  const upperToHyphenLower = (match: string) => {
    return '-' + match.toLowerCase();
  };
  return listStyleType.replace(/[A-Z]/g, upperToHyphenLower);
};

export const findAncestorParagraph = (editor: ICustomEditor): NodeEntry<IParagraphElement> => {
  if (!editor.selection)return null;

  let path = Path.parent(editor.selection.anchor.path);
  while ((Editor.node(editor, path) as NodeEntry<ICustomElement>)[0].type !== 'paragraph') {
    path = Path.parent(path);
  }
  return Editor.node(editor, path) as NodeEntry<IParagraphElement>;
};

export const findAncestorListItem = (editor: ICustomEditor): NodeEntry<IListItemElement> => {
  if (!editor.selection)return null;

  let path = Path.parent(editor.selection.anchor.path);
  while ((Editor.node(editor, path) as NodeEntry<ICustomElement>)[0].type !== 'listItem') {
    path = Path.parent(path);
  }
  return Editor.node(editor, path) as NodeEntry<IListItemElement>;
};

export const nestedListStyleType = (listStyleType: ListStyleTypes): ListStyleTypes => {
  switch (listStyleType) {
    case 'none':
      return 'none';
    case 'disc':
      return 'circle';
    case 'circle':
      return 'square';
    case 'square':
      return 'disc';
    case 'decimal':
    case 'decimalLeadingZero':
      return 'lowerLatin';
    case 'lowerLatin':
      return 'lowerRoman';
    case 'lowerRoman':
      return 'decimal';
    case 'upperLatin':
      return 'upperRoman';
    case 'upperRoman':
      return 'decimal';
  }
  return listStyleType;
};

const getListNestingLevel = (editor: ICustomEditor) => {
  const lists = Editor.nodes(editor, {
    match: n => !Editor.isEditor(n) && Element.isElement(n) && isList(n),
  });
  let count = 0;
  while (!lists.next().done) {
    count += 1;
  }
  return count;
};
