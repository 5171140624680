import React from 'react';
import styled from 'styled-components';
import {CaretRightIcon, Tooltip} from '../..';
import { Collapsable } from '../layout/Collapsable';


interface IExpandableItemProps {
  Icon?: React.ReactNode;
  TitleIcon?: React.ReactNode;
  title: string;
  Menu?: React.ReactNode;
  isOpen?: boolean;
  primary?: boolean;
  className?: string;
  closeTooltip?: React.ReactNode;
  openTooltip?: React.ReactNode;
  setIsOpen?: (isOpen: boolean) => void;
}

export const ExpandableItem: React.FC<IExpandableItemProps> = (props) => {
  const {
    Icon,
    TitleIcon,
    title,
    isOpen,
    setIsOpen,
    children,
    primary,
    className,
    Menu,
    closeTooltip = 'Close',
    openTooltip = 'Open',
  } = props;
  const onToggle = () => setIsOpen(!isOpen);

  const toggleCaret = isOpen
    ? (
      <Tooltip title={closeTooltip}>
        <span><CaretRightIcon /></span>
      </Tooltip>
    ) : (
      <Tooltip title={openTooltip}>
        <span><CaretRightIcon /></span>
      </Tooltip>
    );
  return (
    <Container
      primary={primary}
      className={className}
    >
      <Row>
        {Icon && (
          <IconWrapper>
            {Icon}
          </IconWrapper>
        )}
        {setIsOpen && (
          <ToggleIcon
            isOpen={isOpen}
            onClick={onToggle}
          >
            {toggleCaret}
          </ToggleIcon>
        )}
        {TitleIcon && (
          <TitleIconWrapper>
            {TitleIcon}
          </TitleIconWrapper>
        )}
        <TitleLabel
          title={title}
        >
          {title}
        </TitleLabel>
        {Menu}
      </Row>
      <StyledCollapsable
        isOpen={isOpen}
      >
        {children}
      </StyledCollapsable>
    </Container>
  );
}

interface IPrimary {
  primary?: boolean;
}

const Container = styled.div<IPrimary>`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  border: 1px solid ${(props) => props.primary ? props.theme.card.selected.borderColor : props.theme.card.default.borderColor};
  border-radius: 8px;
  box-sizing: border-box;
  min-width: 0;
  position: relative;
  color: ${(props) => props.theme.card.default.color};
  background:  ${(props) => props.primary ? props.theme.card.selected.backgroundColor : props.theme.card.default.backgroundColor};

  &:hover {
    ${(props) => props.primary ? props.theme.card.selected.hover : props.theme.card.default.hover};
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding-right: 8px;
  padding-left: 8px;
`;

const IconWrapper = styled.div`
  color: ${(props) => props.theme.colorset.grey10};
`;

const TitleIconWrapper = styled.div`
  display: flex;
  align-self: center;
`;

const TitleLabel = styled.div`
  flex: 1;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

interface IIcon {
  isOpen: boolean;
}

const ToggleIcon = styled.div<IIcon>`
  cursor: pointer;
  height: 16px;
  width: 16px;

  & svg {
    ${(props) => props.isOpen ? 'transform: rotate(90deg);' : null}
    transition: transform 200ms cubic-bezier(0.4, 0, 0.2, 1);
  }
`;

const StyledCollapsable = styled(Collapsable)`
  padding-left: 32px;
  padding-right: 6px;
`;

