import styled from 'styled-components';
import { IClassDefinition } from './models/configuration';

/**
 * p.margin 1px --> equivalent to style applied on TEXT_EDITOR_CLASS_NAME.
 */
interface IRichTextEditableWrapperProps {
  classes: ReadonlyArray<IClassDefinition>;
  textClassName: string;
}

export const RichTextEditableWrapper = styled.div<IRichTextEditableWrapperProps>`
  width: 100%;
  p{
    margin: 1px;
  }

  ${(props) => props.classes.map((c) => {
    if (c.className === null) {
      return `.${props.textClassName} {${c.css}}`;
    }
    return `.${props.textClassName} .${c.className} {${c.css}}`;
  })};
`;
