import { ContentElement } from '../../elements/abstract/ContentElement';
import { ElementCodes } from '../../elements/abstract/ElementCodes';
import { RealElement } from '../../elements/abstract/RealElement';
import { AbstractFormatter } from '../../elements/formats/AbstractFormatter';
import { BaseNumberFormatter } from '../../elements/formats/BaseNumberFormatter';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';
import { XMath } from '../../core/XMath';
import { XRound } from '../../core/XRound';

/**
 *
 */
export class WNumber extends RealElement {

  private _base: number;

  private _divisor: number;

  private _squared: boolean;

  /**
   * Computed value.
   */
  private _value: number;

  public get base(): number {
    return this._base;
  }

  public get divisor():number {
    return this._divisor;
  }

  public get squared(): boolean{
    return this._squared;
  }

  private get value(): number {
    return this._value;
  }

  private _formatter:BaseNumberFormatter;

  public get formatter():BaseNumberFormatter{return this._formatter;}

  constructor(
      base: number,
      divisor: number,
      squared: boolean,
      formatter:BaseNumberFormatter){

    super();
    if(!formatter){
      throw new Error('Formatter required');
    }
    this._base = Number(base);
    this._divisor = Number(divisor);
    this._squared = squared;

    let n = Number(divisor) === 1 ? Number(base) : XMath.safeDiv(Number(base), Number(divisor));
    if(squared){
      n = XMath.safeSqrt(n);
    }

    this._value = n;
    this._formatter = formatter;
  }

  public get hasRoundingError(): boolean {
    return XRound.detectRoundingError(this._value);
  }

  public applyFormat(formatter:AbstractFormatter):ContentElement{
    if(formatter instanceof BaseNumberFormatter){
      return new WNumber(
        this._base,
        this._divisor,
        this._squared,
        <BaseNumberFormatter>formatter );
    }
    return this;
  }

  public getFormat():AbstractFormatter{
    return this._formatter;
  }

  public toNumber():number{
    return this.value;
  }

  public toSpeakText():string{
    return this.formatter.toSpeakText(this.value);
  }

  public equalsTo(value:ContentElement):boolean{
    if(value instanceof WNumber){
      return this.value === (<WNumber>value ).value;
    }
    return false;
  }

  public multiply(n: WNumber): WNumber {
    if(this._squared && n.squared){
      return new WNumber(
        XMath.safeTimes(this.base, n.base),
        XMath.safeTimes(this.divisor, n.divisor),
        true,
        this.formatter.culture.numberFormatter
      );
    }
    return new WNumber(
      XMath.safeTimes(
        this.squared ? XMath.safeSqrt(this.base) : this.base,
        n.squared ? XMath.safeSqrt(n.base) : n.base),
      XMath.safeTimes(
        this.divisor,
        n.divisor),
      false,
      this.formatter.culture.numberFormatter);
  }

  public multiplyN(n: number): WNumber {
    const base: number = this.squared ? XMath.safeSqrt(this.base) : this.base;
    const divisor: number = this.squared ? XMath.safeSqrt(this.divisor) : this.divisor;
    return new WNumber(
      XMath.safeTimes(base, n),
      divisor,
      false,
      this.formatter.culture.numberFormatter
    );
  }

  /**
   * Indicates that when formatted, the number won't appear to
   * have the same precision as it have internally.
   */
  public get approximate():boolean{
    return this.formatter.precisionLoss(this.value);
  }

  /**
   * Returns the value as it appears when formatted.
   */
  public get formatValue():number{
    return this.formatter.formatValue(this.value);
  }

  public toString():string {
    return this.value.toString();
  }

  public toText(strict:boolean):string{
    return this.formatter.toLocaleString(this.value);
  }

  public writeTo(exporter:IMarkupExporter = null):boolean{
    if(exporter){
      exporter.writer.appendNumber(this.formatter.toLocaleString(this.value));
    }
    return true;
  }

  public toAbsoluteValue():RealElement {
    if(this._squared){
      return new WNumber(Math.abs(this._value), 1, false, this.formatter);
    }
    return new WNumber(Math.abs(this._base), Math.abs(this._divisor), false, this.formatter);
  }

  public toOpposite():RealElement {
    if(this._squared){
      return new WNumber(-this._value, 1, false, this.formatter);
    }
    return new WNumber(-this._base, this._divisor, false, this.formatter);
  }

  public getElementCode():string{
    return ElementCodes.TOKEN_NUMBER;
  }

  public static parseElement(element:ContentElement):WNumber{
    return element instanceof WNumber ? <WNumber>element  : null;
  }

  public getType():string {
    return 'number';
  }

}
