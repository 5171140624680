import { HistoryEventTypes } from './HistoryEventTypes';

export type IHistoryEventValueFormatsMap = {
  [key in HistoryEventTypes]: HistoryValueFormats;
};

export type HistoryValueFormats =
  'none' |
  'dateString' |
  'jsonString' |
  'boolString' |
  'authorId' |
  'themeId' |
  'subjectId' |
  'activityKind' |
  'activityNavigationMode' |
  'contentRole' |
  'alignmentQuality' |
  'string' |
  'linkUrl' |
  'imageUrl' |
  'index' |
  'commaSeparatedArray' |
  'creationPhase' |
  'commaSeparatedTerritoryIdArray' |
  'commaSeparatedContentTagIdArray';

export const historyEventValueFormatsMap: IHistoryEventValueFormatsMap = {
  activityAnnouncedDateChanged: 'dateString',
  activityAuthorChanged: 'authorId',
  activityCreated: 'none',
  activityDeleted: 'none',
  activityDuplicated: 'none',
  activityExtraDataChanged: 'jsonString',
  activityKindChanged: 'activityKind',
  activityMetadataDescriptionChanged: 'string',
  activityMetadataHrefChanged: 'linkUrl',
  activityMetadataTitleChanged: 'string',
  activityMetadataTitleImageChanged: 'imageUrl',
  activityNavigationChanged: 'activityNavigationMode',
  activityPageAdded: 'index',
  activityPageMoved: 'index',
  activityPageRemoved: 'index',
  activityPageVariantsChanged: 'commaSeparatedArray',
  activityPageVisibilityChanged: 'boolString',
  activityPreferredPageThumbnailChanged: 'none',
  activityPublicationStatusChanged: 'creationPhase',
  activityRenamed: 'string',
  bookAuthorsChanged: 'string',
  bookCreated: 'none',
  bookCreatorChanged: 'authorId',
  bookIconChanged: 'imageUrl',
  bookIsbnChanged: 'string',
  bookLanguagesChanged: 'commaSeparatedArray',
  bookTitleChanged: 'string',
  bookSubjectChanged: 'subjectId',
  bookToCChapterCreated: 'index',
  bookToCChapterDescriptionChanged: 'string',
  bookToCChapterExtraDataChanged: 'jsonString',
  bookToCChapterIconChanged: 'imageUrl',
  bookToCChapterMoved: 'index',
  bookToCChapterRemoved: 'index',
  bookToCChapterThemeChanged: 'themeId',
  bookToCChapterTitleChanged: 'string',
  bookToCChapterVariantsChanged: 'commaSeparatedArray',
  bookToCChapterVisibilityChanged: 'boolString',
  bookToCElementActivityInserted: 'index',
  bookToCElementContentRoleChanged: 'contentRole',
  bookToCElementCreated: 'index',
  bookToCElementDescriptionChanged: 'string',
  bookToCElementExtraDataChanged: 'jsonString',
  bookToCElementHrefChanged: 'linkUrl',
  bookToCElementIconChanged: 'imageUrl',
  bookToCElementMoved: 'index',
  bookToCElementRemoved: 'index',
  bookToCElementThemeChanged: 'themeId',
  bookToCElementTitleChanged: 'string',
  bookToCElementTitleImageChanged: 'imageUrl',
  bookToCElementVariantsChanged: 'commaSeparatedArray',
  bookToCElementVisibilityChanged: 'boolString',
  bookToCRootDescriptionChanged: 'string',
  bookToCRootExtraDataChanged: 'jsonString',
  bookToCRootIconChanged: 'imageUrl',
  bookToCRootShortTitleChanged: 'string',
  bookToCRootThemeChanged: 'themeId',
  bookToCRootTitleChanged: 'string',
  bookToCRootVariantsChanged: 'commaSeparatedArray',
  bookToCRootVisibilityChanged: 'boolString',
  bookToCTopicCreated: 'index',
  bookToCTopicDescriptionChanged: 'string',
  bookToCTopicExtraDataChanged: 'jsonString',
  bookToCTopicIconChanged: 'imageUrl',
  bookToCTopicMoved: 'index',
  bookToCTopicRemoved: 'index',
  bookToCTopicThemeChanged: 'themeId',
  bookToCTopicTitleChanged: 'string',
  bookToCTopicVariantsChanged: 'commaSeparatedArray',
  bookToCTopicVisibilityChanged: 'boolString',
  bookTranslatorsChanged: 'string',
  courseCreated: 'none',
  courseDeleted: 'none',
  courseRenamed: 'string',
  courseSubjectChanged: 'subjectId',
  courseVisibilityChanged: 'boolString',
  curriculumArchived: 'none',
  curriculumCreated: 'none',
  curriculumDeleted: 'none',
  curriculumRenamed: 'string',
  curriculumRestored: 'none',
  libraryArchived: 'none',
  libraryAuthorsChanged: 'string',
  libraryBookAdded: 'index',
  libraryBookMoved: 'index',
  libraryBookRemoved: 'none',
  libraryTerritoriesChanged: 'commaSeparatedTerritoryIdArray',
  libraryCreated: 'none',
  libraryCreatorChanged: 'authorId',
  libraryDuplicated: 'none',
  libraryIsbnChanged: 'string',
  libraryRenamed: 'string',
  libraryRestored: 'none',
  libraryTranslatorsChanged: 'string',
  pageConverted: 'none',
  pageCreated: 'none',
  pageDuplicated: 'none',
  pageAuthorChanged: 'authorId',
  pageThumbnailViewportChanged: 'jsonString',
  standardActivityAlignmentAdded: 'none',
  standardActivityAlignmentQualityChanged: 'alignmentQuality',
  standardActivityAlignmentRemoved: 'none',
  standardPageAlignmentAdded: 'none',
  standardPageAlignmentRemoved: 'alignmentQuality',
  standardPageAlignmentQualityChanged: 'none',
  standardCreated: 'none',
  standardDeleted: 'none',
  standardDescriptionChanged: 'string',
  standardDisplayIdChanged: 'string',
  standardIndentChanged: 'string', // ???
  standardNotesChanged: 'string',
  standardPositionChanged: 'index',
  pageContentTagsChanged: 'commaSeparatedContentTagIdArray',
  contentTagCreated: 'none',
  contentTagUpdated: 'none',
  contentTagDeleted: 'none',
  contentTagsMerged: 'commaSeparatedContentTagIdArray',
};
