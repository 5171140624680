import { ContentElement } from '../elements/abstract/ContentElement';
import { Node } from '../elements/abstract/Node';
import { BaseTimeFormatter } from '../elements/formats/BaseTimeFormatter';
import { SemicolonTimeOfDayFormatter } from '../elements/formats/time/SemicolonTimeOfDayFormatter';
import { ShortTimeOfDayFormatter } from '../elements/formats/time/ShortTimeOfDayFormatter';
import { TimeModel } from '../elements/models/TimeModel';
import { WTimeOfDay } from '../elements/tokens/WTimeOfDay';
import { IWriter } from '../expr/conversion/writers/IWriter';
import { InputCapabilities } from './InputCapabilities';
import { KeyboardConfiguration } from './KeyboardConfiguration';
import { CommonError } from './CommonError';
import { BaseCorrector } from './BaseCorrector';

/**
 *
 */
export class CTimeOfDay extends BaseCorrector {

  public parse(value:string):Node{

    let time:WTimeOfDay = this.parseTime(value, new SemicolonTimeOfDayFormatter(this.env.culture, this.env.culture.configuration.twelveHoursClock));
    let format:string = 'semicolon';

    if(!time){
      time = this.parseTime(value, new ShortTimeOfDayFormatter(this.env.culture, this.env.culture.configuration.twelveHoursClock));
      format = 'short';
    }

    if(!time){
      return null;
    }

    const node:Node = new Node(time);
    const hms:number[] = [];
    hms.push(time.hours, time.minutes);
    if(time.seconds !== 0 || !time.undefinedSeconds){
      hms.push(time.seconds);
    }
    node.userData = `FormatTime(TimeOfDay(${hms.join(', ')}), "${format}")`;
    return node;
  }

  private parseTime(valueArg:string, format:BaseTimeFormatter):WTimeOfDay{
    const value = this.sanitizeInput(valueArg);
    const time:TimeModel = format.fromLocaleString(value);
    return time ? WTimeOfDay.parseModel(time, format) : null;
  }

  public correct(
      value:string,
      target:ContentElement,
      ...targets:any[]):boolean{

    const target2:WTimeOfDay = <WTimeOfDay>target ;
    const value2:WTimeOfDay = this.parseTime(value, target2.formatter);

    if(value2){
      return 	value2.hours === target2.hours &&
          value2.minutes === target2.minutes &&
          value2.seconds === target2.seconds;
    }

    const example:WTimeOfDay =
      new WTimeOfDay(
        8,
        5,
        0,
        target2.undefinedSeconds,
        target2.formatter);

    this.raiseError(
      target2.seconds === 0 && target2.undefinedSeconds ?
        CommonError.TIME_HM_INVALID :
        CommonError.TIME_HMS_INVALID,
      [example.toLocaleString()]);

    return false;
  }

  public get mathKeyboard():number{
    return KeyboardConfiguration.TIME;
  }

  public get inputCapabilities():InputCapabilities{
    const i:InputCapabilities = new InputCapabilities();
    i.text = true;
    return i;
  }

  public writeTo(
      w:IWriter,
      target:ContentElement,
      ...targets:any[]):void{

    const timeOfDay:WTimeOfDay = <WTimeOfDay>target ;
    w.beginText();
    w.writeRaw(timeOfDay.toString());
    w.endText();
  }

}
