import React from 'react';
import styled from 'styled-components';
import { Collapsable } from '../layout/Collapsable';
import { SectionHeader } from '../layout/SectionHeader';

export interface IAccordionProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'title'> {
  readonly title: React.ReactNode;
  readonly isOpen: boolean;
  readonly highlighted?: boolean;
  readonly onToggle: () => void;
}

export const ACCORDION_TESTID = 'ACCORDION_TESTID';

export const Accordion: React.FC<IAccordionProps> = ({ title, isOpen, highlighted, onToggle, children, ...props }) => {
  const onClickHeader = (event: React.MouseEvent) => {
    onToggle();
    event.stopPropagation();
  };

  return (
    <Container
      data-testid={ACCORDION_TESTID}
      {...props}
    >
      <SectionHeader
        isOpen={isOpen}
        onToggle={onClickHeader}
      >
        {title}
      </SectionHeader>
      <Collapsable isOpen={isOpen}>
        {children}
      </Collapsable>
    </Container>
  );
};

const Container = styled.div`
`;
