import loadScript from 'load-script';

const TeXZillaScript = 'https://media.scolab.com/Temp/TeXZilla-min.js';
export type TexZilla = Window['TeXZilla'];

const loadTeXZilla = (force: boolean = false): Promise<TexZilla> => new Promise((resolve, reject) => {
  if (force || window.TeXZilla === undefined) {
    loadScript(TeXZillaScript, (err: any) => {
      if (err) {
        reject(err);
      } else {
        resolve(window.TeXZilla);
      }
    });
  } else {
    resolve(window.TeXZilla);
    // reject(new Error('TeXZilla is already defined in the window scope'));
  }
});

export default loadTeXZilla;
