import { IPrng } from '../../core/prng/IPrng';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Generate a number with different digits.
 *
 * Nombre aléatoire à chiffres différents.
 * Génère un nombre aléatoire avec chiffres différents (rétrocompatibilité).
 * Utilisez plutôt la fonction 'RandomNumber'.
 */
export class RandomDigits extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 1){
      return args.expectingArguments(1, 1);
    }
    if (args.getString(0)) {
      const n:number =
        RandomDigits.parse(
          args.getString(0).getString(),
          args.env.options.restrictDigitZero,
          args.prng);

      return args.env.culture.parseNumber(n);
    }
    return null;
  }

  /**
   *
   */
  public static parse(
      pattern:string,
      nozeros:boolean,
      prng:IPrng):number{

    if(pattern.length === 0){
      throw new Error();
    }

    const digits:any[] = [1, 2, 3, 4, 5, 6, 7, 8, 9]; // Note that we don't include 0 here, we will include it or not based on the option

    if(!nozeros){
      digits.unshift(0);
    }

    let i:number;

    const chars:any[] = pattern.split('');
    let char:string;
    let placeholders:number = 0;
    let index:number;

    for(i = 0 ; i < chars.length ; i++){
      char = chars[i];
      if(char >= '0' && char <= '9'){
        const digit:number = Number(char);
        index = digits.indexOf(digit);
        if(index > -1){
          digits.splice(index, 1);
        }
      }else if(char === '#'){
        placeholders++;
      }
    }

    if(placeholders > digits.length){
      throw new Error(); // not enough distinct digits to create a number that respect this
    }

    let decimal:boolean	= false;

    i = 0;
    while(i < chars.length){
      char = chars[i];
      if(char === '#'){
        if((i === 0 || i === chars.length - 1) && digits[0] === 0){
          // on s'assure que 0 n'est pas pris comme premier chiffre ou dernier chiffre du nombre
          index = 1 + prng.randomIndex(digits.length - 1);
        }else if(i === chars.length - 2 && chars[i + 1] === '#' && digits.length === 2 && digits[0] === 0){
          // si on est à l'avant dernière caractère et que le prochain élément du patron est un PATTERN_CHAR et qu'il reste seulement deux chiffres dans la liste dont le 0
          // on s'assure d'utiliser maintenant le 0 plutôt que le laisser à la fin
          index = 0;
        }else{
          index = prng.randomIndex(digits.length);
        }

        chars[i] = digits[index];

        digits.splice(index, 1); // retire le chiffre des la liste des chiffres possibles
        i++;
      }else if(char === '.' || char === ','){
        if(decimal){
          digits.splice(i, 1);
        }else{
          chars[i] = '.';
          i++;
        }
        decimal = true;
      }else if(char >= '0' && char <= '9'){
        i++;
      }else{
        chars.splice(i, 1);
      }
    }

    return Number(chars.join(''));
  }

}
