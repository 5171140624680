import { XString } from '../../core/XString';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WList } from '../../elements/tokens/WList';
import { WString } from '../../elements/tokens/WString';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class BoxExpression extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length < 1 || args.length > 2){
      return args.expectingArguments(1, 2);
    }

    const expression:WString = args.getString(0);
    if(!expression){
      return null;
    }

    let o:any[] = [];
    if(args.length > 1){
      const parameters:WList = args.getReals(1);
      if(!parameters){
        return null;
      }
      o = o.concat(parameters.toNumbers());
    }

    o.unshift(expression.toString());

    const rawExpression:string = XString.substitute.apply(null, o);

    return args.env.createExpression(rawExpression);
  }

}
