import { MmlWriter } from '../../core/mml/MmlWriter';
import { TokenElement } from '../../elements/abstract/TokenElement';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';
import { StemLeafAttributes } from '../../funcs/stemLeaf/model/StemLeafAttributes';
import { StemLeafModel } from '../../funcs/stemLeaf/model/StemLeafModel';
import { StemLeafRowModel } from '../../funcs/stemLeaf/model/StemLeafRowModel';
import { WList } from '../../elements/tokens/WList';

/**
 *
 */
export class WStemLeafDiagram extends TokenElement {

  private _values1:WList;

  public get values1():WList{return this._values1;}

  private _values2:WList;

  public get values2():WList{return this._values2;}

  private _settings:StemLeafAttributes;

  public get settings():StemLeafAttributes{return this._settings;}

  /**
   *
   */
  constructor(
      values1:WList,
      values2:WList,
      settings:StemLeafAttributes) {

    super();
    this._values1 = values1;
    this._values2 = values2;
    this._settings = settings;
  }

  /**
   *
   */
  public writeTo(exporter:IMarkupExporter = null):boolean{
    if(exporter){
      this.writeDiagramTo(exporter.writer);
    }
    return true;
  }

  /**
   *
   */
  private writeDiagramTo(w:MmlWriter):void{
    const model:StemLeafModel =
      StemLeafModel.parse(
        this.values1.toNumbersV(),
        this.values2 ? this.values2.toNumbersV() : null,
        this.settings.showEmptyStems);

    this.writeTableOpeningTo(w, model.dual);
    this.writeHeaderTo(w, model.dual);

    let j:number;
    let i:number;

    for(i = 0 ; i < model.rows.length ; i++){
      const row:StemLeafRowModel = model.rows[i];
      const leaves1:number[] = row.leaves;
      const leaves2:number[] = row.leaves2 ? row.leaves2 : [];

      if(model.dual){
        w.beginTr();

        if(this.settings.showLeavesCount){
          this.writeStringTd(w, leaves2.length.toString());
        }

        w.beginTd();
        for(j = 0 ; j < leaves2.length ; j++){
          if(this.settings.showTallies){
            w.appendText('X');
          }else{
            w.appendNumber(String(leaves2[j]));
          }
        }
        w.endTd();

        w.endTr();
      }

      this.writeStringTd(w, String(row.stem));

      w.beginTd();
      for(j = 0 ; j < leaves1.length ; j++){
        if(this.settings.showTallies){
          w.appendText('X');
        }else{
          w.appendNumber(String(leaves1[j]));
        }
      }
      w.endTd();

      if(this.settings.showLeavesCount){
        this.writeStringTd(w, leaves1.length.toString());
      }

      w.endTr();
    }

    w.endTable();
  }

  /**
   *
   */
  private writeTableOpeningTo(w:MmlWriter, bDual:boolean):void{
    w.beginTable();
    w.rowlines = 'solid none';

    if(this.settings.showLeavesCount && bDual){
      w.columnalign = 'right right center left left';
      w.columnlines = 'none solid solid none';
    }else if(bDual){
      w.columnalign = 'right center left';
      w.columnlines = 'solid solid none';
    }else if(this.settings.showLeavesCount){
      w.columnalign = 'right left left';
      w.columnlines = 'solid none';
    }else{
      w.columnalign = 'right left';
      w.columnlines = 'solid none';
    }
  }

  /**
   *
   */
  private writeHeaderTo(w:MmlWriter, bDual:boolean):void{
    const stemLabel:string = this.settings.stemLabel;
    const leavesLabel:string = this.settings.leavesLabel;
    const countLabel:string = this.settings.countLabel;

    w.beginTr();

    let header:any[];

    if(this.settings.showLeavesCount && bDual){
      header = [countLabel, leavesLabel, stemLabel, leavesLabel, countLabel];
    }else if(bDual){
      header = [leavesLabel, stemLabel, leavesLabel];
    }else if(this.settings.showLeavesCount){
      header = [stemLabel, leavesLabel, countLabel];
    }else{
      header = [stemLabel, leavesLabel];
    }

    for(let i:number = 0 ; i < header.length ; i++){
      w.beginTd();
      w.appendText(header[i]);
      w.endTd();
    }

    w.endTr();
  }

  /**
   *
   */
  private writeStringTd(w:MmlWriter, value:string):void{
    w.beginTd();
    w.appendText(value);
    w.endTd();
  }

  public getType():string {
    return 'stemLeafDiagram';
  }
}
