import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';

/**
 * Ajoute une colonne contenant la somme de chaque ligne dans une matrice.
 */
export class TotalColumn extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 1){
      return args.expectingArguments(1, 1);
    }
    if(args.getMatrix(0)){
      return this.append(args.getMatrix(0), args.env);
    }
    return null;
  }

  /**
   *
   */
  private append(mat:WMatrix, env:Environment):WMatrix{
    const o:RealElement[] = mat.values.concat();
    let c:number;
    let r:number;
    const z:number = mat.columns;

    const n0:RealElement = env.culture.createNumber(0);

    for(r = 0 ; r < mat.rows ; r++){
      o.splice(r * z + z + r, 0, n0);
    }

    for(c = 0 ; c < mat.columns ; c++){
      for(r = 0 ; r < mat.rows ; r++){
        const t:number = Math.round(r * z + z + r);
        o[t] = env.reals.add(o[t], mat.valueAt(r, c));
      }
    }

    return new WMatrix(o, mat.columns + 1, mat.formatter);
  }

}
