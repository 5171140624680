import React from 'react';
import { ThemeProvider } from 'styled-components';
import { ITheme, ThemeName } from '../../themes/ITheme';
import { ThemeDark, ThemeDark2 } from '../../themes/ThemeDark';
import { ThemeDefault } from '../../themes/ThemeDefault';

export const NestedContainer: React.FC = (props) => {
  const { children } = props;
  return (
    <ThemeProvider theme={themeSwitcher}>
      {children}
    </ThemeProvider>
  );
};

const themeDark = (new ThemeDark()).theme;
const themeDark2 = (new ThemeDark2()).theme;
const themeDefault = (new ThemeDefault()).theme;

const themeSwitcher = (theme: ITheme): ITheme => {
  switch (theme.name) {
    case ThemeName.dark: {
      if (theme.nestingLevel === 1) {
        return themeDark2;
      }
      return themeDark;

    }
    case ThemeName.light: return themeDefault;
    default: return null;
  }
};
