import { IDictionary } from '../../../../js/utils/IDictionary';

import { XMath } from '../../../core/XMath';
import { XString } from '../../../core/XString';
import { RealElement } from '../../../elements/abstract/RealElement';
import { TokenElement } from '../../../elements/abstract/TokenElement';
import { BaseQuadraticEquationFormatter } from '../../../elements/formats/BaseQuadraticEquationFormatter';
import { WNotANumber } from '../../../elements/tokens/WNotANumber';
import { WPolynomial } from '../../../elements/tokens/WPolynomial';
import { WQuadratic } from '../../../elements/tokens/WQuadratic';
import { RealsImpl } from '../../../elements/utils/RealsImpl';
import { ExpressionsUtil } from '../../../expr/ExpressionsUtil';
import { NoExtensions } from '../../../expr/NoExtensions';
import { MParam } from '../../../expr/conversion/models/MParam';
import { ParamTypes } from '../../../expr/conversion/models/ParamTypes';
import { CultureInfo } from '../../../localization/CultureInfo';

/**
 * A(x - x1)(x - x2)
 */
export class FactoredQuadraticFormatter extends BaseQuadraticEquationFormatter {

  private _orderHint:number[];

  constructor(
      culture:CultureInfo,
      orderHint:number[] = null){
    super(culture);
    this._orderHint = orderHint;
  }

  private position(
      x:number,
      i:number):number{
    if(!this._orderHint){
      return i;
    }
    const k:number = this._orderHint.indexOf(x);
    return k !== -1 ? k : i;
  }

  public tokens(
      value:WQuadratic,
      display:boolean):any[]{

    const realsImpl:RealsImpl = new RealsImpl(this.culture, true);

    const n_1:RealElement = this.culture.createNumber(-1);
    const n0:RealElement = this.culture.createNumber(0);
    const n1:RealElement = this.culture.createNumber(1);
    const n2:RealElement = this.culture.createNumber(2);

    const timesA:RealElement = realsImpl.times(n2, value.A);
    const left:RealElement = value.B.toOpposite();
    const right:TokenElement = realsImpl.sqrt(value.discriminantToReal);

    if(right instanceof WNotANumber){
      return [];
    }

    const beforeX1:RealElement = realsImpl.subtract(left, <RealElement>right );
    const beforeX2:RealElement = realsImpl.add(left, <RealElement>right );

    let x1:RealElement = realsImpl.divideR(beforeX1, timesA, true);
    let x2:RealElement = realsImpl.divideR(beforeX2, timesA, true);

    if(XMath.isInteger(timesA.toNumber())){
      if( XMath.isInteger(beforeX1.toNumber())) {
        x1 = (this.culture.createRational(beforeX1.toNumber(), timesA.toNumber())).reduce();
      }

      if( XMath.isInteger(beforeX2.toNumber())) {
        x2 = (this.culture.createRational(beforeX2.toNumber(), timesA.toNumber())).reduce();
      }
    }

    if(display && this._orderHint != null){
      if(this.position(x1.toNumber(), 0) > this.position(x2.toNumber(), 1)){
        const xTemp:RealElement = x1;
        x1 = x2;
        x2 = xTemp;
      }
    }

    const pA:MParam = new MParam(ParamTypes.REAL, 'A', value.A, n1, n_1);
    let px1:MParam = new MParam(ParamTypes.REAL, 'x1', x1, n0, null);
    let px2:MParam = new MParam(ParamTypes.REAL, 'x2', x2, n0, null);

    const o:any[] = [];
    if(display){
      o.push('(', value.xLabel);
      if(px1.value.toNumber() > 0){
        o.push('−', px1);
      }else if(px1.value.toNumber() < 0 ){
        px1 = new MParam(ParamTypes.REAL, 'x1', x1.toAbsoluteValue(), n0, null);
        o.push('+', px1);
      }

      o.push(')', '(', value.xLabel);
      if(px2.value.toNumber() > 0){
        o.push('−', px2);
      }else if(px2.value.toNumber() < 0 ){
        px2 = new MParam(ParamTypes.REAL, 'x2', x2.toAbsoluteValue(), n0, null);
        o.push('+', px2);
      }
      o.push(')');
      if(pA.value.toNumber() === -1){
        o.unshift('−');
      }else if(pA.value.toNumber() !== 1){
        o.unshift(pA);
      }

    }else{
      o.push(pA, '(', value.xLabel, '−', px1, ')', '(', value.xLabel, '−', px2, ')');
    }
    return o;
  }

  public copy(
      value:WQuadratic,
      parameters:IDictionary):WQuadratic{

    const A:RealElement = parameters.hasOwnProperty('A') ? parameters['A'] : value.A;
    const x1:RealElement = parameters.hasOwnProperty('x1') ? parameters['x1'] : this.culture.createNumber(value.xintercept[0]);
    const x2:RealElement = parameters.hasOwnProperty('x2') ? parameters['x2'] : this.culture.createNumber(value.xintercept[1]);

    const expressionUtil:ExpressionsUtil =
      new ExpressionsUtil(
        this.culture,
        new NoExtensions());

    const poly:WPolynomial =
      expressionUtil.toPolynomial(
        XString.substitute(
          '{0}({3}-{1})({3}-{2})',
          String(A.toNumber()),
          String(x1.toNumber()),
          String(x2.toNumber()),
          value.xLabel
        ));

    if(poly){
      const quad = WQuadratic.parsePolynomial(poly, this);
      return new WQuadratic(quad.A, quad.B, quad.C, quad.formatter, quad.p0, quad.p1, quad.p2, value.yLabel, value.xLabel);
    }

    return null;
  }

}
