import { Node } from '../../../elements/abstract/Node';
import { WPolynomial } from '../../../elements/tokens/WPolynomial';
import { Environment } from '../../../expr/Environment';
import { TokensImporter } from '../../../expr/conversion/input/TokensImporter';
import { Skeleton } from '../../../expr/manipulation/Skeleton';
import { Plus } from '../../../funcs/arithmetic/Plus';
import { Times } from '../../../funcs/arithmetic/Times';
import { AbstractRule } from '../../../expr/manipulation/rules/AbstractRule';

/**
 * c(a + b) = ca + cb
 *
 * Handled cases
 *  a. Two polynomials with 2 monomials each
 *  b. ...
 */
export class Distributivity extends AbstractRule {

  constructor(){
    super(true, true);
  }

  public applyNode(node:Node, stateMode:number, env:Environment):Node{
    const skeleton:string = Skeleton.createSkeleton(node);

    if(Distributivity.lookup.indexOf(skeleton) !== -1){
      const a:WPolynomial = <WPolynomial>node.childs[1].value ;
      const b:WPolynomial = <WPolynomial>node.childs[2].value ;
      // (a + b)(c + d) --> a(c + d) + b(c + d)
      if(a.numMonomials >= 2 && b.numMonomials >= 2){
        const tokens:any[] = [];
        for(let i:number = 0 ; i < a.numMonomials ; i++){
          if(i > 0){
            tokens.push(Plus.getInstance());
          }
          tokens.push(
            a.extractMonomial(i),
            Times.getInstance(),
            b);
        }
        return TokensImporter.importTokens(tokens, env);
      }
    }

    return null;
  }

  private static lookup:any[] = Distributivity.createLookup();

  private static createLookup():any[]{
    const f:string = '*({0},{1})';
    const a:any[] = ['p'];
    const b:any[] = ['p'];
    return Skeleton.combine(f, [a, b]);
  }

}
