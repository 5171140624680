import { Store } from '../../dependencyInjection/Store';

@Store('UndoRedoFastForwardStore')
export class UndoRedoFastForwardStore {

  public operationIsFastforwardable: boolean;

  constructor() {
    this.operationIsFastforwardable = false;
    // TODO: Fix the fast forwarding system
    // The undo-redo system only detects change after an operation (mobx action) is complete.
    // If operationIsFastforwardable was both set and unset during the operation,
    // only the final value (false) will be seen at the time of adding the operation in the undo-redo stack.
    // This can happen when the fastforwardableOperationCommands end up being called from inside an action.
  }
}
