import { Point } from '../../js/geom/Point';

import { ArrayCollection } from '../../js/collections/ArrayCollection';

import { IOperation } from '../../js/undo/IOperation';

import { Compartment } from './Compartment';
import { Padding } from './Padding';
import { HLine } from './HLine';
import { ElementaryOperation } from './ElementaryOperation';

/**
 *
 */
export class AbstractStep implements IOperation {

  private operation:ElementaryOperation;

  /**
   *
   */
  constructor(operation:ElementaryOperation){
    this.operation = operation;
  }

  /**
   * Compartments involved in the step.
   */
  public source:Compartment[] = [];

  /**
   *
   */
  public source2:Compartment[] = [];

  /**
   *
   */
  public related:Compartment[] = [];

  /**
   * New compartments added as a
   * result of this step.
   */
  public target:Compartment[] = [];

  /**
   * List of choices for input method.
   */
  public select:string[] = [];

  /**
   *
   */
  public lines:HLine[] = [];

  public performRedo():void{this.show(true);}

  public performUndo():void{this.show(false);}

  private show(value:boolean):void{
    const state:string = value ? Compartment.NORMAL : null;
    this.setState(this.source, state);
    this.setState(this.source2, state);
    this.setState(this.target, state);
    this.setState(this.related, state);

    for(let i:number = 0 ; i < this.lines.length ; i++){
      const l:HLine = this.lines[i];
      if(l){
        l.visible = value;
      }
    }
  }

  public highlightSource():void{
    this.setState(this.source, Compartment.SOURCE);
    this.setState(this.source2, Compartment.NORMAL);
  }

  public highlightTarget():void{
    this.setState(this.target, Compartment.TARGET);
  }

  private setState(compartments:Compartment[], state:string):void{
    for(let i:number = 0 ; i < compartments.length ; i++){
      if(compartments[i] == null){
        continue;
      }
      compartments[i].state = state;
    }
  }

  /**
   * Returns the position {x: column index, y: line index}
   */
  public get targetPosition():Point{
    if(this.targetText.length === 0){
      return null;
    }
    let col:number = Number.MAX_SAFE_INTEGER;
    let row:number = Number.MAX_SAFE_INTEGER;
    for(let i:number = 0 ; i < this.target.length ; i++){
      const c:Compartment = this.target[i];
      if(!c){
        continue;
      }
      col = Math.min(c.column, col);
      row = Math.min(c.row, row);
    }
    return new Point(col, row);
  }

  public get targetText():string{
    return Compartment.stringifyRow(this.target);
  }

  public listLines(list:ArrayCollection):void{
    for(let i:number = 0 ; i < this.lines.length ; i++){
      const line:HLine = this.lines[i];
      if(!line){
        continue;
      }
      if(!list.contains(line)){
        list.addItem(line);
      }
    }
  }

  public listCompartments(list:ArrayCollection):void{
    this.listCompartments2(list, this.source);
    this.listCompartments2(list, this.source2);
    this.listCompartments2(list, this.target);
    this.listCompartments2(list, this.related);
  }

  private listCompartments2(
      list:ArrayCollection,
      compartments:Compartment[]):void{
    for(let i:number = 0 ; i < compartments.length ; i++){
      const c:Compartment = compartments[i];
      if(!c){
        continue;
      }
      if(!list.contains(c)){
        list.addItem(c);
      }
    }
  }

  /**
   * Write a number to a target. Also add the created
   * compartments inside the source of this step.
   */
  protected writeNumber(
      n:number,
      row:Compartment[],
      state:Compartment[],
      padLeft:Padding,
      padRight:Padding):void{

    let c:Compartment;
    const s:string = String(n);
    const o:any[] = s.split('.');
    let d:string;
    let i:number;
    let digits:any[];

    if(padLeft){
      this.pad(row, state, padLeft.length - String(o[0]).length, padLeft.filler);
    }

    digits = String(o[0]).split('');
    for(i = 0 ; i < digits.length ; i++){
      d = digits[i];
      if(d === '-'){
        c = Compartment.createChar('−');
      }else{
        c = Compartment.createDigit(Number(d));
      }
      state.push(c);
      row.push(c);
    }
    const sep:Compartment = this.operation.decimalSeparator();
    if(o.length > 1){
      state.push(sep);
      row.push(sep);
      digits = String(o[1]).split('');
      for(i = 0 ; i < digits.length ; i++){
        d = digits[i];
        c = Compartment.createDigit(Number(d));
        state.push(c);
        row.push(c);
      }
      if(padRight){
        this.pad(row, state, padRight.length - String(o[1]).length, padRight.filler);
      }
    }else if(padRight){
      state.push(sep);
      row.push(sep);
      this.pad(row, state, padRight.length, padRight.filler);
    }
  }

  private pad(
      row:Compartment[],
      state:Compartment[],
      length:number,
      filler:Compartment):void{

    for(let i:number = 0 ; i < length ; i++){
      let c:Compartment = filler;
      if(c){
        c = c.clone();
        state.push(c);
      }
      row.push(c);
    }
  }

  public next():AbstractStep{
    return null;
  }

  private _description:string;

  public get description():string{
    return this._description;
  }

  protected setDescription(value:string):void{
    this._description = value;
  }

}
