import { IPageCopy } from '../IPageCopy';

export const PAGE_CONTENT_CHANGED = 'page-content-changed';

export interface IPageContentChangedMessage {
  type: typeof PAGE_CONTENT_CHANGED;
  payload: {
    pageGuid: string;
    pageCopy: IPageCopy;
  };
}
