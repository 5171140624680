import styled from 'styled-components';
import { Dropdown, DROPDOWN_CONTENT_CLASSNAME } from '../../utils/Dropdown';
import { Input } from '../Input';
import { ColorSet } from '../../../themes/ColorSet';
import { themedColor } from '../../../themes/utils';

export const Section = styled.div`
  padding: 8px;
`;

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${themedColor({ dark: ColorSet.grey800, light: ColorSet.grey100 })};
  min-height: 1px;
`;

export const DropdownLabel = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.colorset.grey06};
  padding: 10px 8px;
`;

export const MultiSelectFilterInput = styled(Input)`
  width: 100%;
`;

export const MultiSelectDropdown = styled(Dropdown)`
  .${DROPDOWN_CONTENT_CLASSNAME} {
    display: ${(props) => props.open ? 'flex' : 'none'};
    flex-direction: column;
  }
`;
