import { IDictionary } from '../../../../js/utils/IDictionary';

import { XString } from '../../../core/XString';
import { ContentElement } from '../../../elements/abstract/ContentElement';
import { RealElement } from '../../../elements/abstract/RealElement';
import { BaseListNFormatter } from '../../../elements/formats/BaseListNFormatter';
import { DenominationFormatter } from '../../../elements/formats/numbers/DenominationFormatter';
import { IMarkupExporter } from '../../../elements/markers/IMarkupExporter';
import { CultureInfo } from '../../../localization/CultureInfo';

/**
 * https://mail.google.com/mail/?shva=1#inbox/13e8f94b047e6964
 */
export class DenominationsTallyFormatter extends BaseListNFormatter {

  /**
   *
   */
  constructor(culture:CultureInfo){
    super(culture);
  }

  /**
   *
   */
  public writeTo(
      exporter:IMarkupExporter,
      items:ContentElement[],
      noParenthesis:boolean,
      useUnambiguousItemSeparator:boolean):boolean{

    // TODO:
    return false;
  }

  /**
   *
   */
  public toText(
      items:ContentElement[],
      strict:boolean,
      noParenthesis:boolean,
      useUnambiguousItemSeparator:boolean):string{
    const tally:IDictionary = {};
    const denominations:any[] = this.culture.currency.denominations;
    let ki:string;
    for(let i:number = 0 ; i < items.length ; i++){
      const n:number = (<RealElement>items[i] ).toNumber();
      ki = n.toString();
      if(denominations.indexOf(n) === -1){
        return null; // Not a valid denomination
      }
      if(!tally.hasOwnProperty(ki)){
        tally[ki] = 0;
      }
      tally[ki]++;
    }

    const o:any[] = [];
    const paperThreshold:number = this.culture.currency.paperThreshold;
    const denominationFormat:DenominationFormatter = new DenominationFormatter(this.culture);
    for(let k:number = denominations.length - 1 ; k >= 0 ; k--){
      ki = denominations[k];
      if(tally.hasOwnProperty(ki)){
        const resourceName =
          Number(ki) >= paperThreshold ?
            'countPaper' :
            'countCoins';
        let s = this.culture.getString(`Denomination.${resourceName}`);
        s = XString.pluralize(s, this.culture.isPlural(tally[ki]));
        o.push(XString.substitute(
          s,
          tally[ki],
          denominationFormat.toLocaleString(Number(ki))));
      }
    }

    return o.join(', ');
  }

}
