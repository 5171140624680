import React from 'react';
import styled from 'styled-components';
import { TagInstance, VariantType } from '../tagging/TagInstance';

export interface ITagsProps {
  readonly tags: ReadonlyArray<{ label: string, uid: string, hasError?: boolean }>;
  readonly onRemoveTag: (uid: string) => void;
  readonly variant?: VariantType;
  readonly removeTooltip?: React.ReactNode;
}

export const Tags: React.FC<ITagsProps> = (
  {
    tags,
    onRemoveTag,
    variant = 'outlined',
    removeTooltip = 'Remove',
  },
) => {

  const tagsArray = tags.map((tag: { label: string, uid: string, hasError?: boolean }) => {
    return (
      <TagInstance
        capitalize
        key={tag.uid}
        id={tag.uid}
        action='remove'
        size='big'
        variant={tag.hasError ? 'error' : variant}
        onAction={onRemoveTag}
        removeTooltip={removeTooltip}
      >
        {tag.label}
      </TagInstance>
    );
  });

  return (
    <TagsWrapper>
      {tagsArray}
    </TagsWrapper>
  );
};

export const TagsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 4px;

  div {
    height: 32px!important;
    box-sizing: border-box;
  }
`;
