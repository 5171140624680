/**
 *
 */
export class ParamTypes {

  public static REAL:string = 'reals';

  public static POSITIVE:string = 'positive';

}
