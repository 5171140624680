import { XRound } from '../../core/XRound';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { TokenElement } from '../../elements/abstract/TokenElement';
import { WEulerConstant } from '../../elements/tokens/WEulerConstant';
import { WLog } from '../../elements/tokens/WLog';
import { WNotANumber } from '../../elements/tokens/WNotANumber';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';

/**
 * Logarithm.
 */
export class LogN extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length < 1 || args.length > 2){
      return args.expectingArguments(1, 2);
    }

    const n:RealElement = args.getReal(0);
    const base:RealElement = args.length > 1 ? args.getReal(1) : args.env.culture.createNumber(10);

    if(n && base){
      return LogN.parse(n, base, args.length === 2, args.env);
    }

    return null;
  }

  /**
   *
   */
  public static parse(
      n:RealElement,
      base:RealElement,
      displayBase:boolean,
      env:Environment):TokenElement{

    if(n.toNumber() <= 0){
      return WNotANumber.getInstance();
    }
    if(base.toNumber() <= 0){
      return WNotANumber.getInstance();
    }

    if(env.options.preserveLogs){
      return new WLog(
        n,
        base,
        displayBase);
    }

    const l:number =
      base instanceof WEulerConstant ?
        Math.log(n.toNumber()) :
        Math.log(n.toNumber()) / Math.log(base.toNumber());

    return env.culture.createNumber(XRound.safeRound(l));
  }

}
