import { XString } from '../core/XString';
import { ContentElement } from '../elements/abstract/ContentElement';
import { Node } from '../elements/abstract/Node';
import { RealElement } from '../elements/abstract/RealElement';
import { SymbolElement } from '../elements/abstract/SymbolElement';
import { TokenElement } from '../elements/abstract/TokenElement';
import { WFraction } from '../elements/tokens/WFraction';
import { WNumber } from '../elements/tokens/WNumber';
import { WPolynomial } from '../elements/tokens/WPolynomial';
import { WPower } from '../elements/tokens/WPower';
import { WRadical } from '../elements/tokens/WRadical';
import { Environment } from '../expr/Environment';
import { IWriter } from '../expr/conversion/writers/IWriter';
import { Skeleton } from '../expr/manipulation/Skeleton';
import { KeyboardConfiguration } from './KeyboardConfiguration';
import { InputCapabilities } from './InputCapabilities';
import { COptions } from './COptions';
import { CRadical } from './CRadical';
import { CPower } from './CPower';
import { CPolynomial } from './CPolynomial';
import { CNumber } from './CNumber';
import { BaseCorrector } from './BaseCorrector';

/**
 * Division of
 * 	a) radical/number, number/radical, radical/radical
 *  b) power/number, number/power, power/power
 *  c) polynomial/number, number/polynomial, polynomial/polynomial
 */
export class CDivision extends BaseCorrector {

  private tokenCode:string;

  private rootIndices:number[];

  private cnum:CNumber;

  private cpoly:CPolynomial;

  private cpow:CPower;

  private crad:CRadical;

  constructor(
      tokenCode:string,
      rootIndices:number[]){
    super();
    this.tokenCode = tokenCode;
    this.rootIndices = rootIndices;
  }

  public configure(origin:ContentElement, options:COptions, env:Environment, useLatex:boolean):void{
    super.configure(origin, options, env, useLatex);
    this.cnum = new CNumber(null, null, true, false);
    this.cpoly = new CPolynomial(false, false, false);
    this.cpow = new CPower();
    this.crad = new CRadical(this.rootIndices);
    super.configureOther(this.cnum);
    super.configureOther(this.cpoly);
    super.configureOther(this.cpow);
    super.configureOther(this.crad);
  }

  public parse(value:string):Node{
    const parts:string[] = this.splitDivision(value);
    if(parts.length === 1){
      return this.parsePart(parts[0]);
    }
    if(parts.length === 2){
      const numerator:Node = this.parsePart(parts[0]);
      const denominator:Node = this.parsePart(parts[1]);
      if(numerator && numerator.isLeaf && denominator && denominator.isLeaf){
        const node:Node =
          new Node(
            new WFraction(
              <TokenElement>numerator.value ,
              <TokenElement>denominator.value ));
        node.userData = 'Fraction((' + numerator.userData + '), (' + denominator.userData + '))';
        return node;
      }
    }
    return null;
  }

  private parsePart(value:string):Node{
    if(this.cpoly.parse(value)){
      return this.cpoly.parse(value);
    }
    if(this.cpow.parse(value)){
      return this.cpow.parse(value);
    }
    if(this.crad.parse(value)){
      return this.crad.parse(value);
    }
    if(this.cnum.parse(value)){
      return this.cnum.parse(value);
    }
    return null;
  }

  private corrector(target:ContentElement):BaseCorrector{
    if(target instanceof WNumber){
      return this.cnum;
    }
    if(target instanceof WPolynomial){
      return this.cpoly;
    }
    if(target instanceof SymbolElement){
      return this.cpoly;
    }
    if(target instanceof WPower){
      return this.cpow;
    }
    if(target instanceof WRadical){
      return this.crad;
    }
    return null;
  }

  public correct(
      value:string,
      target:ContentElement,
      ...targets:any[]):boolean{

    const parts:string[] = this.splitDivision(value);
    let tn:ContentElement;
    let td:ContentElement;

    if(target instanceof WFraction){
      tn = this.validateElement((<WFraction>target ).numerator);
      td = this.validateElement((<WFraction>target ).denominator);
    }else{
      tn = this.validateElement(target);
      td = this.validateElement(targets[0]);
    }

    return 	this.correct2(parts[0], tn) &&
        this.correct2(parts[1], td);
  }

  private splitDivision(valueArg:string):string[]{
    let value = this.translateInput(valueArg);

    const z:string[] = [];
    // Replace exponent tag to avoid the / being treated as a division sign.
    value = value.split('</sup>').join('ÿ');
    const o:any[] = value.split('/');
    for(let i:number = 0 ; i < o.length ; i++){
      let s:string = o[i];
      s = s.split('ÿ').join('</sup>');
      s = XString.trimEnclosingParenthesis(s);
      z.push(s);
    }

    return z;
  }

  private translateInput(valueArg:string):string{
    let value = valueArg;
    if(this.useLatex){
      value = this.sanitizeInput(value);
      value = value.replace(/\^\{\}/g, '');
      value = value.replace(/\^\{([^\}]+)\}/g, '<sup>$1</sup>');
      value = value.replace(/\^(.)/g, '<sup>$1</sup>');

      if(this.tokenCode === 'z'){
        value = value.replace(/\\sqrt\{([^\}]+)\}/, '√$1');
        value = value.replace(/\\sqrt\[2\]\{([^\}]+)\}/, '√$1');
        value = value.replace(/\\sqrt\[3\]\{([^\}]+)\}/, '∛$1');
        value = value.replace(/\\sqrt\[4\]\{([^\}]+)\}/, '∜$1');
      }

      value = value.replace(/(\\frac\{)([^\}]+)(\}\{)([^\}]+)(\})/g, '($2)/($4)');
    }

    if(this.tokenCode === 'p'){
      // −1/4a<sup>3</sup>y<sup>4</sup>
      // <sup> tags can be ignored since polynomial input handles exponents automatically
      value = value.split('<sup>').join('');
      value = value.split('</sup>').join('');
    }

    return value;
  }

  private correct2(
      value:string,
      target:ContentElement):boolean{

    const c:BaseCorrector = this.corrector(target);
    if(c){
      return c.correct(value, target);
    }
    throw new Error('unhandled');
  }

  public get inputCapabilities():InputCapabilities{
    let symbols:string = '/';
    if(this.tokenCode === 'z'){
      symbols = this.rootIndices ? (this.rootIndices.indexOf(3) !== -1 || this.rootIndices.indexOf(4) !== -1 ? '/√∛∜' : '/√') : '/√∛∜';
    }
    if(this.tokenCode === 'p'){
      symbols = '/π−+';
    }
    const o:InputCapabilities = super.inputWithSymbols(symbols);
    o.radical = this.tokenCode === 'z';
    o.superscript = this.tokenCode === 'y' || this.tokenCode === 'p';
    o.polynomial = this.tokenCode === 'p';
    o.fraction = true;
    return o;
  }

  public get mathKeyboard():number{
    if(this.tokenCode === 'p'){
      return KeyboardConfiguration.POLYNOMIAL_DIV;
    }
    if(this.tokenCode === 'z'){
      return KeyboardConfiguration.RADICAL_DIV;
    }
    if(this.tokenCode === 'y'){
      return KeyboardConfiguration.POWER_DIV;
    }
    return KeyboardConfiguration.NONE;
  }

  public writeTo(
      w:IWriter,
      target:ContentElement,
      ...targets:any[]):void{

    let tn:ContentElement;
    let td:ContentElement;

    if(target instanceof WFraction){
      tn = (<WFraction>target ).numerator;
      td = (<WFraction>target ).denominator;
    }else{
      tn = target;
      td = targets[0];
    }

    w.beginFraction();
    this.writeTarget(w, tn);
    w.startParagraph();
    w.beginDenominator();
    this.writeTarget(w, td);
    w.endFraction();
  }

  private writeTarget(
      w:IWriter,
      target:ContentElement):void{

    if(target instanceof WRadical){
      CRadical.writeRadical(w, <WRadical>target , this.useLatex);
    }else if(target instanceof WPower){
      CPower.writePower(w, <WPower>target );
    }else if(target instanceof WPolynomial || target instanceof SymbolElement){
      CPolynomial.writePolynomialOrSymbolOrReal(w, target);
    }else{
      w.writeNumber((<RealElement>target ).toNumber());
    }
  }

  private validateElement(
      element:ContentElement):TokenElement{
    if(element instanceof SymbolElement){
      return <SymbolElement>element ;
    }
    if(element instanceof WRadical){
      return <WRadical>element ;
    }
    if(element instanceof WPower){
      return <WPower>element ;
    }
    if(element instanceof WNumber){
      return <WNumber>element ;
    }
    if(element instanceof WPolynomial){
      return <WPolynomial>element ;
    }
    if(element instanceof RealElement){
      return this.env.culture.createNumber((<RealElement>element ).toNumber());
    }
    return null;
  }

  private static acceptToken(
      token:TokenElement):boolean{

    return token instanceof SymbolElement ||
      token instanceof WRadical ||
      token instanceof WPower ||
      token instanceof RealElement ||
      token instanceof WPolynomial;
  }

  /**
   * a. Radical, power, polynomial, symbol or real number,
   * b. At least one radical, power or polynomial.
   */
  public static validate(
      node:Node,
      tokenCode:string):boolean{

    if(node.isLeaf && node.value instanceof WFraction){
      const fraction:WFraction = <WFraction>node.value ;
      if(		CDivision.acceptToken(fraction.numerator) &&
          CDivision.acceptToken(fraction.denominator)){
        if(		fraction.numerator.getElementCode() === tokenCode ||
            fraction.denominator.getElementCode() === tokenCode){
          return true;
        }
      }
    }

    const skeleton:string = Skeleton.createSkeleton(node);
    if(skeleton == null){
      return false;
    }
    const skeletons:any[] =
      Skeleton.combine(
        '/({0},{1})',
        [['n', 'r', tokenCode],
         ['n', 'r', tokenCode]]);

    if(skeleton.indexOf(tokenCode) === -1){
      return false;
    }
    return skeletons.indexOf(skeleton) !== -1;
  }

}
