import { DivisionNumber } from './division/DivisionNumber';
import { DivInitEnglish } from './division/english/DivInitEnglish';
import { DivNormalizeEnglish } from './division/english/DivNormalizeEnglish';
import { DivisionNormalizerEnglish } from './division/english/DivisionNormalizerEnglish';
import { DivisionModel } from './models/DivisionModel';
import { CultureInfo } from '../localization/CultureInfo';
import { AbstractStep } from './AbstractStep';
import { Compartment } from './Compartment';
import { ElementaryOperation } from './ElementaryOperation';

/**
 *
 */
export class LongDivisionOperationEnglish extends ElementaryOperation {

  public model:DivisionModel;

  // State variables
  public rawDivisor:Compartment[] = [];

  public rawDividend:Compartment[] = [];

  public divisor:Compartment[] = [];

  public result:Compartment[] = [];

  public steps:any[] = [];

  public normalize:Compartment[] = [];

  // Execution variables
  public dividend:DivisionNumber = new DivisionNumber(0);

  private _rest:number[] = [];

  private _decimals:number = 0;

  public separatorAdded:boolean = false;

  constructor(
      model:DivisionModel,
      culture:CultureInfo){
    super(culture);
    this.model = model;
    this.dividend = new DivisionNumber( model.normalizedDividend );
    super.init();
  }

  public padding(
      step:Compartment[]):number{
    let c:number = 0;
    for(let i:number = 0 ; i < step.length ; i++){
      if(step[i] == null){
        c++;
      }else if(step[i].text !== '−'){
        break;
      }
    }
    return c;
  }

  public get decimals():number {
    return this._decimals;
  }

  public set decimals(value:number) {
    this._decimals = value;
  }

  public get rest():number[] {
    return this._rest;
  }

  public set rest(value:number[]) {
    this._rest = value;
  }

  public getRestToUInt():number {
    return Math.floor(Number(this.rest.join('')));
  }

  public get lastStep():Compartment[]{
    if(this.steps.length === 0){
      return null;
    }
    return this.steps[this.steps.length - 1];
  }

  protected next():AbstractStep{
    if(this.model.restModeWithDecimals){
      return null;
    }
    if(this.lastOperation == null){
      if(this.model.originalDivisor === 0){
        return null;
      }
      if ( this.model.originalDivisor < this.model.normalizedDivisor ) {
        return new DivNormalizeEnglish(this);
      }
      return new DivInitEnglish(this);
    }
    return this.lastOperation.next();
  }

  protected finalize():void{
    let c:Compartment;
    let k:number;
    const v:number = (this.rawDividend.length > 0 && this.rawDivisor.length > 0) ? 5 : 1;

    for(k = 0 ; k < this.rawDivisor.length ; k++){
      c = this.rawDivisor[k];
      if(c){
        c.row = 0;
        c.column = k;
      }
    }

    for(k = 0 ; k < this.rawDividend.length ; k++){
      c = this.rawDividend[k];
      if(c){
        c.row = 0;
        c.column = this.rawDivisor.length + k;
      }
    }

    for(k = 0; k < this.normalize.length; k++ ) {
      c = this.normalize[k];
      if(c) {
        c.row = 2;
        c.column = Math.floor(((this.rawDivisor.length + this.rawDividend.length) - this.normalize.length) / 2) + k;
      }
    }

    const l:number = this.divisor.length;

    for(let r:number = 0 ; r < this.steps.length ; r++){
      const row:Compartment[] = this.steps[r];
      for(k = 0 ; k < row.length ; k++){
        c = row[k];
        if(c){
          c.row = r + v;
          c.column = l + k;
        }
      }
    }

    for(k = 0 ; k < this.divisor.length ; k++){
      c = this.divisor[k];
      if(c){
        c.row = v;
        c.column = k;
      }
    }

    const align:number = Math.floor(this.model.normalizedDividend).toString().length - this.model.quotientDigits;

    for(k = 0 ; k < this.result.length ; k++){
      c = this.result[k];
      if(c){
        c.row = v - 1;
        c.column = k + l + 1 + align;
      }
    }
  }

  protected validate():void{
    this.error = this.quotient !== this.model.quotient;
  }

  /**
   * Returns the block of compartments that contains the last result.
   */
  private get actualResult():Compartment[]{
    return this.result;
  }

  public get quotient():string{
    const s:any[] = [];
    for(let i:number = 0 ; i < this.actualResult.length ; i++){
      const c:Compartment = this.actualResult[i];
      if(c){
        s.push(c.text);
      }
    }
    return this.trimLeadingZeros(s.join(''));
  }

  /**
   * Trim leading zeros unless value equals "0".
   */
  private trimLeadingZeros(valueArg:string):string{
    let value = valueArg;
    const i:number = 0;
    while(i < value.length - 1){
      const a:string = value.charAt(0);
      const b:string = value.charAt(1);
      if(a === '0' && b >= '0' && b <= '9'){
        value = value.substring(1);
      }else{
        break;
      }
    }
    return value;
  }

  public static createOperation(
      dividend:number,
      divisor:number,
      decimals:number,
      culture:CultureInfo,
      interactive:boolean):LongDivisionOperationEnglish{

    const restLabel:string = culture.getString('LongDivisionEnglish.restLabel');

    const model:DivisionModel =
      new DivisionModel(
        dividend,
        divisor,
        decimals,
        restLabel,
        culture.numberFormatter.decimalSeparator,
        new DivisionNormalizerEnglish(),
        interactive);

    return new LongDivisionOperationEnglish(model, culture);
  }

}
