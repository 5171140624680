import React from 'react';
import styled from 'styled-components';
import { IKeyboardSection } from './Keyboard.models';
import { CustomKeypad } from './CustomKeypad';

export interface IActionsKeypad {
  readonly actionSection?: IKeyboardSection;
  readonly onKeyPress: (key: string) => void;
}

export class ActionsKeypad extends React.PureComponent<IActionsKeypad, {}> {

  public render(): JSX.Element {
    const {
      actionSection,
      onKeyPress,
    } = this.props;
    if (!actionSection) {
      return null;
    }
    const container = actionSection.container || ActionKeypadContainer;

    return (
      <CustomKeypad
        rows={actionSection.rows}
        container={container}
        onKeyPress={onKeyPress}
      />
    );
  }
}

const ActionKeypadContainer = styled.div`
  box-sizing: content-box;
  max-width: 60px;
  min-width: 40px;
  margin-left: -1px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.colorset.grey04};
`;
