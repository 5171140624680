import React from 'react';

/**
 * Set a ref to a value
 * Code taken from: https://github.com/mui/material-ui/blob/d3ef60158ba066779102fba775dda6765e2cc0f5/packages/mui-utils/src/setRef/setRef.ts
 */
export function setRef<T>(
  ref: React.MutableRefObject<T | null> | ((instance: T | null) => void) | null | undefined,
  value: T | null,
): void {
  if (typeof ref === 'function') {
    ref(value);
  } else if (ref) {
    ref.current = value;
  }
}
