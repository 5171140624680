import { IDictionary } from '../../../js/utils/IDictionary';

import { XObject } from '../../core/XObject';
import { AnonymousFunction } from '../../elements/abstract/AnonymousFunction';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { Expression } from '../../elements/abstract/Expression';
import { ListElement } from '../../elements/abstract/ListElement';
import { Node } from '../../elements/abstract/Node';
import { RealElement } from '../../elements/abstract/RealElement';
import { BoundVariable } from '../../elements/constructs/BoundVariable';
import { Otherwise } from '../../elements/constructs/Otherwise';
import { Piece } from '../../elements/constructs/Piece';
import { Piecewise } from '../../elements/constructs/Piecewise';
import { IEval } from '../../elements/effofeks/IEval';
import { VariableX } from '../../elements/models/VariableX';
import { WBoolean } from '../../elements/tokens/WBoolean';
import { WEval } from '../../elements/tokens/WEval';
import { WInterval } from '../../elements/tokens/WInterval';
import { Environment } from '../../expr/Environment';
import { ApplyRecursive } from '../../expr/manipulation/ApplyRecursive';
import { RecursionGuard } from '../../core/RecursionGuard';

/**
 *
 */
export class Evaluate {

  private recall:boolean;

  private recursionGuard:RecursionGuard;

  private body:Node;

  private range:string;

  private bvars:IDictionary; // key: bound variable symbol, value: index in the argument list

  private carguments:number; // argument count expected

  private x:VariableX;

  private env:Environment;

  constructor(
      ref:AnonymousFunction,
      env:Environment,
      recall:boolean = false){

    this.body = ref.node;
    this.range = ref.range;
    this.bvars = ref.bvars;
    this.recursionGuard = ref.recursionGuard;
    this.carguments = XObject.getProps(this.bvars).length;

    if(this.range === AnonymousFunction.REAL_NUMBERS){
      // Replace polynomials with variable x with a polynomial evaluator
      this.x = new VariableX();
      this.body = this.body.clone();
      this.replaceX(this.body);
    }

    this.env = env;
    this.recall = recall;
  }

  public getArgumentsCount():number{
    return this.carguments;
  }

  private replaceX(node:Node):void{
    for(let i:number = 0 ; i < node.numChildren ; i++){
      const child:Node = node.childs[i];
      this.replaceX(child);
    }

    const e:IEval = node.value.toEval();
    if(e != null){
      node.value = new WEval(e, this.x);
    }
  }

  public get intervals():WInterval[]{
    if(this.range === AnonymousFunction.REAL_NUMBERS){
      const piecewise:Node = this.body.lastChild;
      if(piecewise.value instanceof Piecewise){
        const o:WInterval[] = [];
        for(let i:number = 0 ; i < piecewise.childs.length ; i++){
          const piece:Node = piecewise.childs[i];
          if(piece.value instanceof Piece){
            o.push(<WInterval>(this.evaluateNode(piece.childs[1], null)[0]) );
          }
        }
        return o;
      }
    }
    return null;
  }

  /**
   * Cache the evaluation result when bound variables
   * doesn't exists in any of the function parts.
   */
  private pieces:IDictionary = {};

  private tests:IDictionary = {};

  private flatten(
      values:ContentElement[],
      capacity:number):ContentElement[]{

    let i:number;

    const args:ContentElement[] = [];
    if(values.length === capacity){
      for(i = 0 ; i < values.length ; i++){
        args.push(values[i]);
      }
    }else if(values.length === 1){
        if(values[0] instanceof ListElement){
          const list:ListElement = <ListElement>values[0] ;
          if(list.count === capacity){
            for(i = 0 ; i < list.count ; i++){
              args.push(list.getItemAt(i));
            }
          }
        }
      }

    return args;
  }

  public evaluateN(values:ContentElement[]):ContentElement{
    return this.evaluateImpl(values);
  }

  public evaluate1(arg1:ContentElement):ContentElement{
    const values:ContentElement[] = [];
    values.push(arg1);
    return this.evaluateImpl(values);
  }

  public evaluate2(arg1:ContentElement, arg2:ContentElement):ContentElement{
    const values:ContentElement[] = [];
    values.push(arg1);
    values.push(arg2);
    return this.evaluateImpl(values);
  }

  public evaluateB(values:ContentElement[]):boolean{
    const result:ContentElement = this.evaluateImpl(values);
    return result instanceof WBoolean ? (<WBoolean>result ).toBoolean() : false;
  }

  private evaluateImpl(values:ContentElement[]):ContentElement{
    this.recall ? this.recursionGuard.recall() : this.recursionGuard.call();
    const args:ContentElement[] = this.flatten(values, this.carguments);
    if(args.length !== this.carguments){
      throw new Error('argument count mismatch');
    }
    if(this.body.childs.length === 0){
      return null;
    }

    const piecewise:Node = this.body.lastChild;
    let result:any[];

    if(this.range === AnonymousFunction.REAL_NUMBERS){
      this.x.xValue = this.env.culture.createNumber(RealElement.parseDecimal(args[0]));
    }

    if(piecewise.value instanceof Piecewise){
      // Substitute the bound variables by their actual value
      for(let i:number = 0 ; i < piecewise.numChildren ; i++){
        const piece:Node = piecewise.childs[i];
        const pieceKey:string = String(i);
        if(piece.value instanceof Piece){
          let test:ContentElement;
          if(this.tests.hasOwnProperty(pieceKey)){
            test = this.tests[pieceKey];
          }else{
            result = this.evaluateNode(piece.childs[1], args);
            if(!result[1]){
              this.tests[pieceKey] = result[0];
            }
            test = result[0];
          }
          if(test){
            let ok:boolean = false;
            if(test instanceof WInterval){
              ok = (<WInterval>test ).contains(this.x.xValue.toNumber());
            }else if(test instanceof WBoolean){
              ok = (<WBoolean>test ).toBoolean();
            }
            if(ok){
              if(this.pieces.hasOwnProperty(pieceKey)){
                return this.pieces[pieceKey];
              }
              result = this.evaluateNode(piece.childs[0], args);
              if(!result[1]){
                this.pieces[pieceKey] = result[0];
              }
              return this.evaluateElement(result[0]);
            }
          }
        }else if(piece.value instanceof Otherwise){
          // Only one child expression to evaluate,
          // make a clone before subtituting the bound variables
          if(this.pieces.hasOwnProperty(pieceKey)){
            return this.pieces[pieceKey];
          }
          result = this.evaluateNode(piece.childs[0], args);
          if(!result[1]){
            this.pieces[pieceKey] = result[0];
          }
          return this.evaluateElement(result[0]);
        }else{
          throw new Error();// Required Piece or Otherwise
        }
      }
    }else{
      throw new Error(); // Required piecewise
    }

    return null;
  }

  /**
   * Returns an array with the following format: [result, isbound]
   */
  private evaluateNode(
      nodeArg:Node,
      args:ContentElement[]):any[]{

    const node = nodeArg.clone();
    const isBound:boolean = Evaluate.substitute(node, args, this.bvars, []);
    const expr:Expression = new Expression(node);
    const apply:ApplyRecursive = new ApplyRecursive(expr, this.env);
    const result:Expression = apply.simplify(false, false, false);
    if(result.root.isLeaf){
      return [result.root.value, isBound];
    }
    return [null, true];
  }

  /**
   *
   */
  private evaluateElement(
      value:ContentElement):ContentElement{

    if(value instanceof WEval){
      return this.env.culture.createNumber((<WEval>value ).toNumber());
    }

    return value;
  }

  /**
   * Recursive bound variables substitution.
   */
  private static substitute(
      parent:Node,
      args:ContentElement[],
      bvars:IDictionary,
      localVars:string[]):boolean{

    if(parent.value instanceof BoundVariable){
      // Actual bound variable substitution
      // use this.arguments because argument is a local reserved keyword

      const varName:string = (<BoundVariable>parent.value ).getVariable().getSymbol();

      if(localVars.indexOf(varName) === -1){
        if(bvars.hasOwnProperty(varName)){
          parent.value = args[bvars[varName]];
        }
      }

      return true;
    }

    if(parent.value instanceof AnonymousFunction){
      const innerFunction:AnonymousFunction = <AnonymousFunction>parent.value ;
      const innerBody:Node = innerFunction.node.clone();

      if(Evaluate.substitute(innerBody, args, bvars, localVars.concat((<string[]>XObject.getProps(innerFunction.bvars))))){
        parent.value = new AnonymousFunction(innerBody, innerFunction.range);
        return true;
      }
      return false;
    }

    if(parent.value instanceof WEval){
      return true;
    }
    let o:boolean = false;
    for(let i:number = 0 ; i < parent.numChildren ; i++){
      const child:Node = parent.childs[i];
      if(Evaluate.substitute(child, args, bvars, localVars)){
        o = true;
      }
    }
    return o;
  }

}
