import styled from 'styled-components';

export const AkWrapper = styled.div`
  background: ${props => props.theme.colorset.grey01};
  border-radius: calc(${props => props.theme.baseBorderRadius} / 2);
  padding: ${props => props.theme.baseVerticalPadding} ${props => props.theme.baseHorizontalPadding};

  // old console CSS
  ///*width: 100%;*/
  //.ak-renderer-document .media-single {
  //}
  //
  //.ak-renderer-document {
  //  /* margin: auto;*/
  //  border-radius: 4px;
  //}
  //
  //.akEditor {
  //  color: black;
  //}
  //
  //padding: 4px;
  //
  //& > div {
  //  padding: 4px;
  //
  //  .ak-renderer-document {
  //    background: white;
  //  }
  //}
`;
