import { Point } from '../../../js/geom/Point';

import { RestrictExpression } from '../../elements/models/RestrictExpression';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WExpression } from '../../elements/tokens/WExpression';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class RestrictOperations extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length < 2 || args.length > 3){
      return args.expectingArguments(2, 3);
    }

    if(args.length === 2){
      if(args.getExpression(0) && args.getReal(1)) {
        return this.restrict(args.getExpression(0), args.getReal(1));
      }
    }else if(args.length === 3){
      if(args.getExpression(0) && args.getReal(1) && args.getReal(2)) {
        return this.restrict2(args.getExpression(0), args.getReal(1), args.getReal(2));
      }
    }

    return null;
  }

  /**
   *
   */
  private restrict(
      expr:WExpression,
      operations:RealElement):WExpression{

    if(!operations.isWholeNumber()){
      return null;
    }

    const r:RestrictExpression =
      expr.restrict ?
      expr.restrict.clone() :
      new RestrictExpression();

    r.operations = new Point(operations.toNumber(), operations.toNumber());
    return expr.setRestrict(r);
  }

  /**
   *
   */
  private restrict2(
      expr:WExpression,
      minimum:RealElement,
      maximum:RealElement):WExpression{

    if(!minimum.isWholeNumber()){
      return null;
    }
    if(!maximum.isWholeNumber()){
      return null;
    }

    const r:RestrictExpression =
      expr.restrict ?
      expr.restrict.clone() :
      new RestrictExpression();

    r.operations = new Point(minimum.toNumber(), maximum.toNumber());
    return expr.setRestrict(r);
  }

}
