import { XMath } from '../../core/XMath';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { FractionFormatter } from '../../elements/formats/rationals/FractionFormatter';
import { WRational } from '../../elements/tokens/WRational';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';

/**
 * Fraction aléatoire.
 */
export class RandomFraction extends FunctionElement {

  /**
   * Retourne une fraction dont la valeur se trouve entre <i>a</i> et <i>b</i> avec le dénominateur spécifié.
   * Retourne une fraction dont la valeur se trouve entre <i>a</i> et <i>b</i> avec un dénominateur qui se trouve dans la liste.
   * Retourne une fraction dont la valeur se trouve entre <i>a</i> et <i>b</i> avec un dénominateur qui se trouve dans l'ensemble.
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 3){
      return args.expectingArguments(3, 3);
    }

    const min:RealElement = args.getReal(0);
    if(!min){
      return null;
    }

    const max:RealElement = args.getReal(1);
    if(!max){
      return null;
    }

    let denominators:number[] = null;

    if(args.getWholeNumber(2)){
      denominators = [];
      denominators.push(args.getWholeNumber(2).toNumber());
    }else if(args.getReals(2)){
      denominators = args.getReals(2).toNumbersV();
    }else if(args.getFiniteSet(2)){
      denominators = args.getFiniteSet(2).toList().toNumbersV();
    }

    if(denominators){
      return this.fractionBetween(min.toNumber(), max.toNumber(), denominators, args.env);
    }

    return null;
  }

  /**
   * PRIVATE
   * Generate a fraction in the interval [a, b] with one of the given denominators in d
   */
  private fractionBetween(
      a:number,
      b:number,
      denominatorsArg:number[],
      env:Environment):WRational{

    if(denominatorsArg.length === 0){
      return null; // Une liste de dénominateurs doit être spécifiée
    }

    const denominators = denominatorsArg.concat();

    const min:number = Math.min(a, b);
    const max:number = Math.max(a, b);

    let numerator:number;
    let denominator:number;

    // 1. Éliminer les dénominateurs qui ne permettent pas de générer une fraction comprise entre les bornes
    let d:number = 0;
    while(d < denominators.length){
      denominator = denominators[d];
      if(		denominator < 2 ||
          Math.ceil(denominator * min) > Math.floor(denominator * max)){

        denominators.splice(d, 1);
      }else{
        d++;
      }
    }

    if(denominators.length === 0){
      throw new Error(); // tous les dénominateurs ont été écartés dans la vérification précédante
    }

    // 2. Check if there is at least one possible numerator
    let nums:boolean = false;
    for(d = 0 ; d < denominators.length ; d++){
      denominator = denominators[d];

      for(	numerator = Math.ceil(denominator * min) ;
          numerator <= Math.floor(denominator * max) ;
          numerator++) {

        if(numerator !== 0){
          if(denominators.indexOf(denominator / XMath.gcd(numerator, denominator)) !== -1){
            nums = true;
            break;
          }
        }
      }
    }

    if(!nums){
      throw new Error();
    }

    // Petite modification. Afin de respecter la spécification NM1,
    // le dénominateur de la fraction générée simplifiée DOIT
    // faire partie de la liste de dénominateurs. C'est pourquoi, il faut
    // aussi faire la liste des numérateurs possibles pour chaque dénominateur
    // et ensuite choisir un dénominateur et un numérateur.

    // Créer la fraction
    while(true){
      denominator = denominators[env.prng.randomIndex(denominators.length)];

      numerator =
        env.random.between(
          Math.ceil(denominator * min),
          Math.floor(denominator * max));

      if(numerator !== 0){
        if(denominators.indexOf(denominator / XMath.gcd(numerator, denominator)) !== -1){
          break;
        }
      }
    }

    const r:WRational =
      new WRational(
        numerator,
        denominator,
        FractionFormatter.getImproperNotation(env.culture));

    const r2:RealElement = r.normalize(env.options.simplifyRationals);
    return r2 instanceof WRational ? <WRational>r2  : r;
  }

}
