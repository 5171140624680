import { BaseNumberFormatter } from '../../../elements/formats/BaseNumberFormatter';
import { CultureInfo } from '../../../localization/CultureInfo';

/**
 *
 */
export class CardRankFormatter extends BaseNumberFormatter {

  /**
   *
   */
  constructor(culture:CultureInfo){
    super(culture);
  }

  /**
   *
   */
  public toLocaleString(value:number):string{
    if(!this.willFormat(value)){
      return this.culture.formatNumber(value);
    }

    const card:number = Math.round(value);
    const rank:number = ((card - 1) % 13) + 1;

    return this.culture.getString(`CardRanks.${String(rank)}`);
  }

  /**
   *
   */
  public toSpeakText(value:number):string{
    return this.willFormat(value) ? null : this.culture.numberFormatter.toSpeakText(value);
  }

  /**
   *
   */
  public willFormat(value:number):boolean{
    return Math.round(value) === value && value >= 1 && value <= 52;
  }

  /**
   *
   */
  public isDictionaryKey():boolean{
    return true;
  }

}
