import { ClientApplicationModel } from '../../models/ClientApplicationModel';
import { IContentObjectData } from '../../models/IContentObjectData';
import { api } from '../../utils/fetch';

export interface IOBContentObjectsListResponse {
  objects: ReadonlyArray<IContentObjectData>;
}

export const getContentObjectList = async (): Promise<IOBContentObjectsListResponse> => {
  const response = await api<IOBContentObjectsListResponse>(`/Publisher/ContentObjects/List?${ClientApplicationModel.getInstance().params}`);
  return response.json();
};
