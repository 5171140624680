/**
 *
 */
export class XTypes {

  /**
   *
   */
  public static isString(value:any):boolean{
    return typeof value === 'string' || value instanceof String;
  }

}
