import React from 'react';
import ReactDOM from 'react-dom';
import styled, { keyframes } from 'styled-components';
import PopupTip from '../../../assets/popupTip.svg';
import { POPUP_ZINDEX } from '../../../themes/zIndex';

interface ITooltipPopupProps {
  readonly targetElement: HTMLElement;
  readonly isOpen: boolean;
}

export const TooltipPopup: React.FC<ITooltipPopupProps> = React.memo((props) => {
  const {
    targetElement,
    isOpen,
    children,
  } = props;
  if (!isOpen || !targetElement) {
    return null;
  }
  const targetPosition = targetElement.getBoundingClientRect();
  const positionX = targetPosition.left + targetPosition.width / 2;
  const positionY = targetPosition.top - 16;
  return ReactDOM.createPortal(
    (
      <Container
        positionX={positionX}
        positionY={positionY}
      >
        {children}
        <StyledPopupTip />
      </Container>
    ),
    document.body,
  );
});

const fadeInAnimation = keyframes`
  from {
    opacity: 0;
    transform: translate(-50%, -100%) scale(0);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -100%) scale(1);
  }
`;

interface IContainerProps {
  readonly positionX: number;
  readonly positionY: number;
}

const Container = styled.div<IContainerProps>`
  animation-name: ${fadeInAnimation};
  animation-duration: 0.2s;
  background-color: ${props => props.theme.tooltip.default.backgroundColor};
  border-radius: 4px;
  color: ${props => props.theme.tooltip.default.color};
  font-family: ${props => props.theme.fontFamily};
  font-size: 14px;
  font-weight: 600;
  left: ${props => props.positionX}px;
  line-height: 20px;
  padding: 6px 8px;
  position: fixed;
  top: ${props => props.positionY}px;
  transform: translate(-50%, -100%);
  z-index: ${POPUP_ZINDEX};
`;

const StyledPopupTip = styled(PopupTip)`
  height: 14px;
  left: 50%;
  position: absolute;
  top: 100%;
  transform: translateX(-50%);
`;
