import React from 'react';
import { countBy } from 'lodash';
import { getSubjectName } from '../../queries/subjectsQueries';
import { SubjectTag } from './SubjectTag';

type WithSubjectId = {
  subjectId: number;
}

export const getDistinctSubjectTags = <T extends WithSubjectId>(objectsWithSubjectId: T[]): JSX.Element[] => {
  const allSubjectIds = objectsWithSubjectId.map((object) => object.subjectId);
  const subjectCounts = countBy(allSubjectIds);
  const distinctSubjects = Object.keys(subjectCounts);

  return distinctSubjects.map((subjectId) => {
    const subjectName = getSubjectName(Number(subjectId));
    if (!subjectName) { return null; }

    const count = distinctSubjects.length > 1 ? ` (${subjectCounts[subjectId]})` : null;

    return (
      <SubjectTag
        key={subjectId}
        subjectName={subjectName}
      >
        {count}
      </SubjectTag>
    );
  });
};
