import { IDictionary } from '../../../js/utils/IDictionary';

import { XObject } from '../../core/XObject';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { TokenElement } from '../../elements/abstract/TokenElement';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';
import { WStructDef } from '../../elements/tokens/WStructDef';

/**
 *
 */
export class WStruct extends TokenElement {

  private origin:WStructDef;

  private properties:IDictionary;

  constructor(
      origin:WStructDef,
      properties:IDictionary){
    super();
    this.origin = origin;
    this.properties = properties;
  }

  public writeTo(exporter:IMarkupExporter = null):boolean{
    if(exporter){
      exporter.writer.appendText(`[${this.origin.definition.name}_Obj]`);
    }
    return true;
  }

  public hasProperty(prop:string):boolean{
    return this.properties.hasOwnProperty(prop);
  }

  public getProperty(prop:string):ContentElement{
    if(this.hasProperty(prop)){
      return this.properties[prop];
    }
    return null;
  }

  public get names():any[]{
    return XObject.getProps(this.properties);
  }

  public getType():string {
    return 'struct';
  }
}
