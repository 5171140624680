import { ReactEditor } from '../TypedSlate';
import { ICustomEditor } from '../models/editor';

export const getEditorDomElement = (editor: ICustomEditor) => {
  return ReactEditor.toDOMNode(editor, editor);
};

export const focusEditor = (editor: ICustomEditor) => {
  try {
    ReactEditor.focus(editor);
  } catch (e) {
    console.log(e);
  }
};
