import { XRound } from '../../../core/XRound';
import { MmlWriter } from '../../../core/mml/MmlWriter';
import { RealElement } from '../../../elements/abstract/RealElement';
import { BaseMatrixFormatter } from '../../../elements/formats/BaseMatrixFormatter';
import { IMarkupExporter } from '../../../elements/markers/IMarkupExporter';
import { CultureInfo } from '../../../localization/CultureInfo';

/**
 * Format a matrix to look like a table with titles
 * and total row and total columns added.
 */
export class TableFormatter extends BaseMatrixFormatter {

  private rowTitles:string[];

  private columnTitles:string[];

  constructor(
      culture:CultureInfo,
      rowTitles:string[],
      columnTitles:string[]){
    super(culture);
    this.rowTitles = rowTitles;
    this.columnTitles = columnTitles;
  }

  /**
   *
   */
  public writeTo(exporter:IMarkupExporter, values:RealElement[], columns:number):boolean {

    const w:MmlWriter = exporter.writer;

    this.beginWriteTable(w);

    const rows:number = values.length / columns;

    let title:string;
    let total:number;

    let r:number;
    let c:number;

    // sum of the columns
    const totalRow:number[] = [];

    // header
    w.beginTr();
    w.beginTd(); // top left corner
    w.endTd(); // top left corner
    for(c = 0 ; c < columns ; c++){
      title = c < this.columnTitles.length ? this.columnTitles[c] : null;
      this.writeTitleTd(w, title);
      totalRow.push(0);
    }
    w.beginTd(); // top right corner
    w.endTd(); // top right corner
    w.endTr();

    // body
    for(r = 0 ; r < rows ; r++){
      w.beginTr();
      title = r < this.rowTitles.length ? this.rowTitles[r] : null;
      this.writeTitleTd(w, title);

      total = 0;
      for(c = 0 ; c < columns ; c++){
        const n:number = values[r * columns + c].toNumber();
        totalRow[c] += n;
        total += n;
        this.writeNumberTd(w, n);
      }

      this.writeNumberTd(w, XRound.safeRound(total));
      w.endTr();
    }

    // footer
    w.beginTr();
    w.beginTd(); // bottom left corner
    w.endTd(); // bottom left corner
    total = 0;
    for(c = 0 ; c < columns ; c++){
      total += totalRow[c];
      this.writeNumberTd(w, XRound.safeRound(totalRow[c]));
    }
    this.writeNumberTd(w, XRound.safeRound(total)); // bottom right corner
    w.endTr();

    w.endTable();

    return true;
  }

  private beginWriteTable(writer:MmlWriter):void{
    writer.beginTable();
    writer.frame = 'solid';
    writer.rowlines = 'solid';
    writer.columnlines = 'solid';
  }

  private writeTitleTd(writer:MmlWriter, value:string):void{
    writer.beginTd();
    if(value){
      writer.appendText(value);
    }
    writer.endTd();
  }

  private writeNumberTd(writer:MmlWriter, value:number):void{
    writer.beginTd();
    writer.appendNumber(this.culture.formatNumber(value));
    writer.endTd();
  }

}
