import { ContentElement } from '../../elements/abstract/ContentElement';
import { WDictionary } from '../../elements/tokens/WDictionary';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { MarkupImporter } from '../../expr/conversion/input/MarkupImporter';
import { StringImporter } from '../../expr/conversion/input/StringImporter';
import { ParseBase } from '../../funcs/expr/ParseBase';

/**
 * Parse user input, with some localization rules applied.
 */
export class Parse extends ParseBase {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length < 1 || args.length > 2){
      return args.expectingArguments(1, 2);
    }

    let parameters:WDictionary = null;
    if(args.length > 1){
      parameters = args.getDictionary(1);
      if(!parameters){
        return null;
      }
    }

    if(args.getString(0)){
      try{
        this.newValueNode =
          new StringImporter(
            args.env.culture.revertNumberLocalization(args.getString(0).getString()),
            this.parseDictionary(parameters, args.env),
            args.env,
            false).expr.root;
      }catch(e){
        //
      }
    }else if(args.getMarkup(0)){
      try{
        const importer:MarkupImporter =
          new MarkupImporter(
            args.env,
            this.parseDictionary(parameters, args.env));

        this.newValueNode = importer.getExpression(args.getMarkup(0).toElement()).root;
      }catch(e){
        //
      }
    }

    return null;
  }

}
