import { BaseNumberFormatter } from '../../../elements/formats/BaseNumberFormatter';
import { Pieces } from '../../../funcs/chess/Pieces';
import { CultureInfo } from '../../../localization/CultureInfo';

/**
 *
 */
export class ChessPieceFormatter extends BaseNumberFormatter {

  /**
   *
   */
  constructor(culture:CultureInfo){
    super(culture);
  }

  /**
   *
   */
  public toLocaleString(value:number):string{
    return ChessPieceFormatter.figurine(value);
  }

  /**
   *
   */
  public toSpeakText(value:number):string{
    return null; // TODO: should be the name of the piece
  }

  /**
   *
   */
  public static figurine(piece:number):string{
    switch(piece){
      case Pieces.BLACK_PAWN:		return '♟';
      case Pieces.WHITE_PAWN:		return '♙';
      case Pieces.BLACK_BISHOP:	return '♝';
      case Pieces.WHITE_BISHOP:	return '♗';
      case Pieces.BLACK_ROOK:		return '♜';
      case Pieces.WHITE_ROOK:		return '♖';
      case Pieces.BLACK_KNIGHT:	return '♞';
      case Pieces.WHITE_KNIGHT:	return '♘';
      case Pieces.BLACK_KING:		return '♚';
      case Pieces.WHITE_KING:		return '♔';
      case Pieces.BLACK_QUEEN:	return '♛';
      case Pieces.WHITE_QUEEN:	return '♕';
    }
    return '?';
  }

}
