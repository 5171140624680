import { XMath } from '../../core/XMath';
import { XRound } from '../../core/XRound';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { ElementCodes } from '../../elements/abstract/ElementCodes';
import { OperatorElement } from '../../elements/abstract/OperatorElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { SymbolElement } from '../../elements/abstract/SymbolElement';
import { TokenElement } from '../../elements/abstract/TokenElement';
import { WFraction } from '../../elements/tokens/WFraction';
import { WInfinity } from '../../elements/tokens/WInfinity';
import { WPolynomial } from '../../elements/tokens/WPolynomial';
import { WRadical } from '../../elements/tokens/WRadical';
import { WRational } from '../../elements/tokens/WRational';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';

/**
 * Division.
 */
export class Divide extends OperatorElement {

  private static _instance:Divide;

  public static getInstance():Divide{
    if(!Divide._instance){
      Divide._instance = new Divide();
    }
    return Divide._instance;
  }

  private static _instanceObelus:Divide;

  public static getInstanceObelus():Divide{
    if(!Divide._instanceObelus){
      Divide._instanceObelus = new Divide('÷');
    }
    return Divide._instanceObelus;
  }

  /**
   *
   */
  constructor(type:string = null){
    super();
    this.other = type || '/';
  }

  /**
   *
   */
  public toString():string{
    return this.other;
  }

  /**
   *
   */
  public get fractionLike():boolean{
    return this.other === '/' || this.other == null;
  }

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 2){
      return args.expectingArguments(2, 2);
    }

    // polynomials
    if(args.getPolynomial(0) && args.getPolynomial(1)){
      return this.polynomials(args.getPolynomial(0), args.getPolynomial(1), args.env);
    }
    if(args.getPolynomial(0) && args.getReal(1)){
      return this.polynomialR(args.getPolynomial(0), args.getReal(1), args.env);
    }

    // radicals
    if(args.getRadical(0) && args.getRadical(1)){
      return this.radicals(args.getRadical(0), args.getRadical(1), args.env);
    }
    if(args.getRadical(0) && args.getReal(1)){
      return this.radicalR(args.getRadical(0), args.getReal(1), args.env);
    }
    if(args.getReal(0) && args.getRadical(1)){
      return this.rRadical(args.getReal(0), args.getRadical(1), args.env);
    }

    // reals
    if(args.getReal(0) && args.getReal(1)){
      return this.reals(args.getReal(0), args.getReal(1), args.env);
    }

    return null;
  }

  /**
   *
   */
  public reals(a:RealElement, b:RealElement, env:Environment):TokenElement{
    const q:TokenElement = env.reals.divide(a, b, env.options.preserveRationals);
    const qR:WRational = WRational.parseElement(q);

    if(qR){
      this.wasCondensedWork =
        a.toNumber() === qR.numerator &&
        b.toNumber() === qR.denominator;
    }

    return q;
  }

  /**
   * Division by a monomial.
   */
  public polynomials(
      a:WPolynomial,
      b:WPolynomial,
      env:Environment):ContentElement{

    if(!env.options.simplifyAlgebraicFractions || b.numMonomials !== 1){
      return new WFraction(a, b);
    }

    // common symbols
    const vars:SymbolElement[] = SymbolElement.merge(a.symbols, b.symbols);

    // result powers
    const pa:number[] = [];
    const pb:number[] = [];

    // result coefficients
    const ca:RealElement[] = [];
    const cb:RealElement[] = [];

    let s:number; // symbol index
    let m:number; // monomial index
    const cp:number[] = []; // common monomial powers
    let pi:number; // power index based on symbols order
    let p:number; // power value

    //
    for(s = 0 ; s < vars.length ; s++){
      pi = b.symbolIndex(vars[s]);
      p = pi === -1 ? 0 : b.power(0, pi);
      cp.push(p);
    }

    // compute common powers
    for(m = 0 ; m < a.numMonomials ; m++){
      for(s = 0 ; s < vars.length ; s++){
        pi = a.symbolIndex(vars[s]);
        p = pi === -1 ? 0 : a.power(m, pi);
        cp[s] = Math.min(cp[s], p);
      }
    }

    const cdiv:number = this.coefsDivisor(a.coefs, b.coefs[0]);

    // simplify numerator powers
    for(m = 0 ; m < a.numMonomials ; m++){
      for(s = 0 ; s < vars.length ; s++){
        pi = a.symbolIndex(vars[s]);
        p = pi === -1 ? 0 : a.power(m, pi);
        pa.push(p - cp[s]);
      }
      ca.push(
        cdiv === 1 ?
          env.reals.divideR(a.coefs[m], b.coefs[0], env.options.preserveRationals) :
          env.reals.divideR(a.coefs[m], env.culture.createNumber(cdiv), env.options.preserveRationals));
    }

    // simplify denominator powers
    for(s = 0 ; s < vars.length ; s++){
      pi = b.symbolIndex(vars[s]);
      p = pi === -1 ? 0 : b.power(0, pi);
      pb.push(p - cp[s]);
    }

    cb.push(
      cdiv === 1 ?
        env.culture.createNumber(1) :
        env.reals.divideR(b.coefs[0], env.culture.createNumber(cdiv), env.options.preserveRationals));

    const a2:TokenElement = new WPolynomial(vars, ca, pa, a.formatter).normalize(env.reals);
    const b2:TokenElement = new WPolynomial(vars, cb, pb, b.formatter).normalize(env.reals);

    // Make sure something was simplified, otherwise it
    // will come back here and cause an infinite loop.
    if(!a.equalsTo(a2) || !b.equalsTo(b2)){
      if(b2 instanceof RealElement && (<RealElement>b2 ).toNumber() === 1 ) {
        return a2;
      }
      return new WFraction(a2, b2);
    }

    return new WFraction(a, b);
  }

  /**
   *
   */
  public polynomialR(a:WPolynomial, d:RealElement, env:Environment):TokenElement{
    let b:number = d.toNumber();

    if(b === 0){
      return WInfinity.POSITIVE;
    }
    if(b === 1){
      return a;
    }

    let p:WPolynomial = a.clone();
    let i:number;
    let coef:number;

    if(env.options.preventRationalCoefs){
      // Divide each monomial using the greatest common divisor of all monomial
      if(XMath.isInteger(b)){
        let dd:number = b;
        for(i = 0 ; i < p.numMonomials ; i++){
          coef = p.coefs[i].toNumber();
          if(XMath.isInteger(coef)){
            dd = XMath.gcd(dd, coef);
          }else{
            p = null;
            break;
          }
        }
        if(p){
          for(i = 0 ; i < p.numMonomials ; i++){
            coef = p.coefs[i].toNumber();
            p.coefs[i] = env.culture.createNumber(coef / dd);
          }
          b = XRound.safeRound(b / dd);
          if(b === 1){
            return p.normalize(env.reals);
          }
          return new WFraction(p.normalize(env.reals), env.culture.createNumber(b));
        }

      }else{
        p = null;
      }
    }else{
      // Divide each monomial independently.
      for(i = 0 ; i < p.numMonomials ; i++){
        coef = p.coefs[i].toNumber();
        if(coef % b === 0){
          p.coefs[i] = env.culture.createNumber(coef / b);
        }else if(XMath.isInteger(coef) && XMath.isInteger(b)){
          const newCoef:WRational = env.culture.createRational(coef, b);
          p.coefs[i] = newCoef.normalize(env.options.simplifyRationals);
        }else{
          p = null;
          break;
        }
      }
    }

    if(p){
      return p.normalize(env.reals);
    }

    // NOTE: p is null here, don't use p, use a instead
    // NOTE: if narrow return null, don't convert to number because Number(null) = 0
    // If we can compute a numeric value for the polynomial then make the division with this value
    const n:ContentElement = a.narrow();
    if(n instanceof RealElement){ // Case with PI
      const q:number = (<RealElement>n ).toNumber() / b;
      return env.culture.createNumber(q);
    }

    return null;
  }

  /**
   *
   */
  public radicals(
      a:WRadical,
      b:WRadical,
      env:Environment):ContentElement{

    let _a:WRadical = a.toReduced(env.reals) ? a.toReduced(env.reals) : a;
    let _b:WRadical = b.toReduced(env.reals) ? b.toReduced(env.reals) : b;

    const o:RealElement[] = env.reals.reduceFactors(_a.coefficient, _b.coefficient);

    if(o){
      _a = new WRadical(_a.base, _a.index, o[0], env.culture.formats.radicalFormatImpl);
      _b = new WRadical(_b.base, _b.index, o[1], env.culture.formats.radicalFormatImpl);
    }

    if(_a.isBaseOne || _b.isBaseOne){
      this.wasCondensedWork = true;
      this.hasPendingWork = true;
      this.newArguments1 = [];
      this.newArguments1.push(_a.isBaseOne ? _a.coefficient : _a);
      this.newArguments1.push(_b.isBaseOne ? _b.coefficient : _b);
      return null;
    }

    if(_b.index.isNaturalNumber() && _a.index.toNumber() === _b.index.toNumber()){
      this.hasPendingWork = true;
      const num:WRadical =
        new WRadical(
          env.reals.times(_a.base, env.reals.power(_b.base, env.culture.createNumber(_b.index.toNumber() - 1))),
          _a.index,
          _a.coefficient,
          a.formatter);

      const den:RealElement = env.reals.times(_b.coefficient, _b.base);

      this.newArguments1 = [];
      this.newArguments1.push(num);
      this.newArguments1.push(den);
      return null;
    }

    if(_a.equalsTo(a) && _b.equalsTo(b)){
      this.wasCondensedWork = true;
    }

    return new WFraction(_a, _b);
  }

  /**
   *
   */
  public radicalR(
      a:WRadical,
      bArg:RealElement,
      env:Environment):ContentElement{
    let b = bArg;
    let _a:WRadical = a.toReduced(env.reals) ? a.toReduced(env.reals) : a;
    const o:RealElement[] = env.reals.reduceFactors(_a.coefficient, b);
    if(o){
      _a = new WRadical(_a.base, _a.index, o[0], a.formatter);
      b = o[1];
    }

    if(b.toNumber() === 1){
      return _a;
    }
    if(b.toNumber() === 0){
      return WInfinity.POSITIVE;
    }
    if(b.toNumber() === -1){
      return new WRadical(_a.base, _a.index, _a.coefficient.toOpposite(), a.formatter);
    }

    if(_a.equalsTo(a)){
      this.wasCondensedWork = true;
    }

    return new WFraction(_a, b);
  }

  /**
   *
   */
  public rRadical(
      aArg:RealElement,
      b:WRadical,
      env:Environment):ContentElement{
    let a = aArg;

    if(a.toNumber() === 0){
      return env.culture.createNumber(0);
    }

    let _b:WRadical = b.toReduced(env.reals) ? b.toReduced(env.reals) : b;

    const o:RealElement[] = env.reals.reduceFactors(a, _b.coefficient);

    if(o){
      a = o[0];
      _b = new WRadical(_b.base, _b.index, o[1], b.formatter);
    }

    if(_b.index.isNaturalNumber()){
      this.hasPendingWork = true;
      this.newArguments1 = [];
      this.newArguments1.push(new WRadical(env.reals.power(_b.base, env.culture.createNumber(_b.index.toNumber() - 1)), _b.index, a, b.formatter));
      this.newArguments1.push(env.reals.times(_b.coefficient, _b.base));
      return null;
    }

    if(_b.equalsTo(b)){
      this.wasCondensedWork = true;
    }

    return new WFraction(a, _b);
  }

  /**
   *
   */
  private coefsDivisor(numCoefs:RealElement[], denomCoef:RealElement):number{
    if(!XMath.isInteger(denomCoef.toNumber())){
      return 1;
    }
    const negative:boolean = denomCoef.toNumber() < 0;
    let div:number = denomCoef.toNumber();
    for(let i:number = 0 ; i < numCoefs.length ; i++){
      const n:number = numCoefs[i].toNumber();
      if(!XMath.isInteger(n)){
        return 1;
      }
      div = XMath.gcd(div, n);
    }
    return negative ? -div : div;
  }

  /**
   *
   */
  public getElementCode():string{
    return ElementCodes.OP_DIVISION;
  }

}
