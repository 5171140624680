import { AuthTokenProvider } from './AuthTokenProvider';

class PublisherApiAuthService extends AuthTokenProvider {

  protected readonly onUnauthorizedCallbacks: Array<() => void> = [];

  protected get storageKey() {
    return 'PublisherApiAccessToken';
  }

  public addUnauthorizedRequestEventListener(callback: () => void) {
    this.onUnauthorizedCallbacks.push(callback);
  }

  public onUnauthorizedRequest(executeListeners = true) {
    this.clearToken();

    if (executeListeners) {
      this.onUnauthorizedCallbacks.forEach(callback => callback());
    }
  }
}

export const PublisherApiAuth = new PublisherApiAuthService();

