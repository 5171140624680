import { AbstractStep } from '../../AbstractStep';
import { Compartment } from '../../Compartment';
import { LongDivisionOperationEnglish } from '../../LongDivisionOperationEnglish';
import { DivisionModel } from '../../models/DivisionModel';
import { BaseNumberFormatter } from '../../../elements/formats/BaseNumberFormatter';
import { DigitPlaceValueFormatter } from '../../../elements/formats/numbers/DigitPlaceValueFormatter';

/**
 *
 */
export class DivEndEnglish extends AbstractStep {

  private model:DivisionModel;

  private endMode:string; // exact, rest, approximate, periodic

  private quotient:string;

  constructor(
      division:LongDivisionOperationEnglish,
      endMode:string){
    super(division);
    this.model = division.model;
    this.endMode = endMode;
    this.quotient = Compartment.stringifyRow(division.result);

    let resourceKey:string = null;
    if(endMode === 'approximate'){
      resourceKey = 'endLabel';
    }
    if(endMode === 'exact'){
      resourceKey = 'endExactLabel';
    }
    if(endMode === 'rest'){
      resourceKey = 'endRestLabel';
    }
    if(endMode === 'periodic'){
      resourceKey = 'endPeriodicLabel';
    }

    if(!resourceKey){
      throw new Error();
    }

    let quotientWithPeriod:string = this.model.quotientWithPeriod;
    quotientWithPeriod = quotientWithPeriod.replace('.', division.culture.numberFormatter.decimalSeparator);
    if(quotientWithPeriod.indexOf('(') !== -1){
      quotientWithPeriod = quotientWithPeriod.replace('(', '');
      quotientWithPeriod = quotientWithPeriod.replace(')', '');
      quotientWithPeriod += '...';
    }

    const digitPlaceValueFormatter:BaseNumberFormatter =
      new DigitPlaceValueFormatter(division.culture, false);

    const rawDescription =
      division.culture.getString(
        `LongDivisionEnglish.${resourceKey}`,
        division.culture.formatNumber(this.model.normalizedDividend),
        division.culture.formatNumber(this.model.normalizedDivisor),
        this.quotient,
        this.model.decimals,
        quotientWithPeriod,
        this.model.decimals >= 0 ? digitPlaceValueFormatter.toLocaleString(-this.model.decimals) : '');

    this.setDescription(division.culture.pluralize(rawDescription, this.model.decimals));
  }

  public next():AbstractStep {
    return null;
  }

}
