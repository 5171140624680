import React from 'react';
import styled from 'styled-components';
import { rafThrottle } from '../../utils/rafThrottle';

interface IResizableColumnProps {
  readonly width: number;
  readonly onChangeWidth: (width: number) => void;
  readonly anchorPosition: 'left' | 'right';
  readonly onDragStart?: (event: React.MouseEvent, containerBox: DOMRect) => void;
  readonly className?: string;
  readonly disableDrag?: boolean;
}

export class ResizableColumn extends React.Component<IResizableColumnProps> {
  private containerRef: React.RefObject<HTMLDivElement>;

  private containerBox: DOMRect;

  private throttledMouseMove: (event: MouseEvent) => void;

  constructor(props: IResizableColumnProps) {
    super(props);
    this.containerRef = React.createRef<HTMLDivElement>();
    this.throttledMouseMove = rafThrottle(this.onMouseMove);
  }

  public componentWillUnmount(): void {
    this.removeListeners();
  }

  public render(): React.ReactNode {
    const {
      children,
      width,
      anchorPosition,
      className,
      disableDrag = false,
    } = this.props;

    const dragAnchor = disableDrag ? null : (
      <DragAnchor
        anchorPosition={anchorPosition}
        onMouseDown={this.onMouseDown}
      />
    );

    return (
      <Container
        ref={this.containerRef}
        width={width}
        className={className}
      >
        {children}
        {dragAnchor}
      </Container>
    );
  }

  private onMouseDown = (event: React.MouseEvent) => {
    event.preventDefault();
    this.containerBox = this.containerRef.current.getBoundingClientRect();
    this.props.onDragStart?.(event, this.containerBox);
    document.addEventListener('mousemove', this.throttledMouseMove);
    document.addEventListener('mouseleave', this.removeListeners);
    document.addEventListener('mouseup', this.removeListeners);
    document.body.setAttribute('style', 'cursor:col-resize !important;');
  }

  private onMouseMove = (event: MouseEvent) => {
    event.preventDefault();
    let newWidth = 0;
    if (this.props.anchorPosition === 'right') {
      newWidth = event.clientX - this.containerBox.left - 5;
    } else {
      newWidth = this.containerBox.right - event.clientX - 5;
    }
    this.props.onChangeWidth(newWidth);
  }

  private removeListeners = () => {
    document.removeEventListener('mousemove', this.throttledMouseMove);
    document.removeEventListener('mouseleave', this.removeListeners);
    document.removeEventListener('mouseup', this.removeListeners);
    document.body.style.cursor = 'default';
  }
}

interface IContainer {
  width: number;
}

const Container = styled.div.attrs<IContainer>((props) => ({
  style: {
    width: `${props.width === -1 ? '100%' : props.width + 'px'}`,
  }
}))<IContainer>`
  position: relative;
`;

interface IDragAnchor {
  anchorPosition: 'left' | 'right';
}

const DragAnchor = styled.div<IDragAnchor>`
  position: absolute;
  top: 0;
  ${(props) => props.anchorPosition === 'right' ? 'right: -8px;' : 'left: -8px;'}
  cursor: col-resize;
  width: 8px;
  height: 100%;
  display: block;
  z-index: 10;
`;
