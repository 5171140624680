import { Point } from '../../js/geom/Point';

import { Match } from '../core/Match';
import { XString } from '../core/XString';
import { ContentElement } from '../elements/abstract/ContentElement';
import { Node } from '../elements/abstract/Node';
import { RealElement } from '../elements/abstract/RealElement';
import { WPower } from '../elements/tokens/WPower';
import { IWriter } from '../expr/conversion/writers/IWriter';
import { InputCapabilities } from './InputCapabilities';
import { KeyboardConfiguration } from './KeyboardConfiguration';
import { CommonError } from './CommonError';
import { BaseCorrector } from './BaseCorrector';

/**
 *
 */
export class CPower extends BaseCorrector {

  /**
   *
   */
  public parse(value:string):Node{
    const po:WPower = this.tryParse(value);
    if(po){
      const node:Node = new Node(po);
      node.userData = `${po.base.toNumber()}^${po.exponent.toNumber()}@1024`; // preserve power
      return node;
    }
    return null;

  }

  /**
   *
   */
  public correct(
      value:string,
      target:ContentElement,
      ...targets:any[]):boolean{

    const target2:WPower = <WPower>target ;
    const value2:WPower = this.tryParse(value);
    if(value2){
      return value2.equalsTo(target2);
    }

    if(this.missingParenthesisForNegativeNumber(value)){
      // no gentle reminder if parenthesis are not there with a negative number.
      return false;
    }

    this.raiseError(CommonError.POWERS_FORMAT);
    return false;
  }

  //
  private static PNx:string = '[\\d]*[\\.,]?[\\d]+'; // positive number

  private static NNx:string = '[-−][\\d]*[\\.,]?[\\d]+'; // negative number

  private static Nx:string = '[-−]?[\\d]*[\\.,]?[\\d]+'; // positive or negative number

  /**
   * Translate user-input into string that can be parsed by this corrector.
   */
  private translateInput(valueArg:string):string{
    let value = valueArg;
    if(!value){
      return value;
    }

    if(this.useLatex){
      value = this.sanitizeInput(value);
      value = value.replace(/\^\{\}/g, '^{1}');
      value = value.replace(/\^\{([^\}]+)\}/g, '<sup>$1</sup>');
      value = value.replace(/\^(.)/g, '<sup>$1</sup>');
    }

    value = value.replace(/\s/g, '');

    return value;
  }

  /**
   *
   */
  private missingParenthesisForNegativeNumber(valueArg:string):boolean{
    const value = this.translateInput(valueArg);

    const x1:RegExp =
      new RegExp(
        XString.substitute(
          '^({0})<sup>({1})</sup>$',
          CPower.NNx, CPower.Nx));

    return x1.test(value);
  }

  /**
   *
   */
  private tryParse(value:string):WPower{
    if(!value){
      return null;
    }

    const p:Point = this.parseString(this.translateInput(value));
    if(!p){
      return null;
    }

    return new WPower(
      this.env.culture.createNumber(p.x),
      this.env.culture.createNumber(p.y));
  }

  /**
   *
   */
  private parseString(value:string):Point{
    // negative base (must have parenthesis)
    const x1:RegExp =
      new RegExp(
        XString.substitute(
          '^\\(({0})\\)<sup>({1})</sup>$',
          CPower.NNx, CPower.Nx));

    // positive base
    const x2:RegExp =
      new RegExp(
        XString.substitute(
          '^({0})<sup>({1})</sup>$',
          CPower.PNx, CPower.Nx));

    let s:Match;

    let base:number;
    let exponent:number;

    if(x1.test(value)){
      s = Match.tryParse(x1.exec(value));
      base = this.numberParser.parseNumber(s.groups[1]);
      exponent = this.numberParser.parseNumber(s.groups[2]);
    }else if(x2.test(value)){
      s = Match.tryParse(x2.exec(value));
      base = this.numberParser.parseNumber(s.groups[1]);
      exponent = this.numberParser.parseNumber(s.groups[2]);
    }else if(!isNaN(this.numberParser.parseNumber(value))){
      base = this.numberParser.parseNumber(value);
      exponent = 1;
    }

    if(!isNaN(base) && !isNaN(exponent)){
      return new Point(base, exponent);
    }

    return null;
  }

  /**
   *
   */
  public writeTo(
      w:IWriter,
      target:ContentElement,
      ...targets:any[]):void{
    CPower.writePower(w, <WPower>target );
  }

  /**
   *
   */
  public static writePower(
      w:IWriter,
      value:WPower):void{

    const b:RealElement = value.base;
    if(b.toNumber() < 0){
      w.writeInfixOperator('(');
    }
    w.writeNumber(value.base.toNumber());
    if(b.toNumber() < 0){
      w.writeInfixOperator(')');
    }
    w.writeSuperscript(value.exponent.toNumber());
  }

  /**
   *
   */
  public get mathKeyboard():number{
    return KeyboardConfiguration.POWER;
  }

  /**
   *
   */
  public get inputCapabilities():InputCapabilities{
    const i:InputCapabilities = new InputCapabilities();
    i.superscript = true;
    return i;
  }

}
