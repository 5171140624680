import { Point } from '../../../js/geom/Point';

import { MathError } from '../../core/MathError';
import { IPrng } from '../../core/prng/IPrng';

/**
 *
 */
export class RandomPointGenerator {

  private left:number;

  private bottom:number;

  private right:number;

  private top:number;

  private middle:number;

  private center:number;

  private prng:IPrng;

  /**
   * minDimension: validates the bounding box
   * 	0: the bounding box can be a point,
   *  1: the bounding box can be a vertical line or horizontal line but not a point,
   *  2: the bounding box must not be a point nor a line.
   */
  constructor(
      p1:Point,
      p2:Point,
      minDimension:number,
      prng:IPrng) {

    this.prng = prng;

    this.left = Math.ceil(Math.min(p1.x, p2.x));
    this.bottom = Math.ceil(Math.min(p1.y, p2.y));

    this.right = Math.floor(Math.max(p1.x, p2.x));
    this.top = Math.floor(Math.max(p1.y, p2.y));

    this.middle = this.bottom + Math.ceil((this.top - this.bottom) / 2);
    this.center = this.left + Math.ceil((this.right - this.left) / 2);

    if(minDimension === 0){
      // no validation
    }else if(minDimension === 1){
      if(this.left === this.right && this.top === this.bottom){
        throw new MathError('Invalid bounding box');
      }
    }else if(minDimension > 1){
      if(this.left === this.right || this.top === this.bottom){
        throw new MathError('Invalid bounding box');
      }
    }

    this.right++; // because randomInt upper bound is not included
    this.top++;
  }

  /**
   * Returns a random integer point within the bounding box.
   */
  public next():Point{
    return new Point(
      this.prng.randomInt(this.left, this.right),
      this.prng.randomInt(this.bottom, this.top));
  }

  /**
   * Returns a random point in the top-left part of the bounding box.
   */
  public next1():Point{
    return new Point(
      this.prng.randomInt(this.left, this.center),
      this.prng.randomInt(this.middle, this.top));
  }

  /**
   * Returns a random point in the top-right part of the bounding box.
   */
  public next2():Point{
    return new Point(
      this.prng.randomInt(this.center, this.right),
      this.prng.randomInt(this.middle, this.top));
  }

  /**
   * Returns a random point in the bottom-right part of the bounding box.
   */
  public next3():Point{
    return new Point(
      this.prng.randomInt(this.center, this.right),
      this.prng.randomInt(this.bottom, this.middle));
  }

  /**
   * Returns a random point in the bottom-left part of the bounding box.
   */
  public next4():Point{
    return new Point(
      this.prng.randomInt(this.left, this.center),
      this.prng.randomInt(this.bottom, this.middle));
  }

}
