import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { MathError } from '../../core/MathError';
import { StringImporter } from '../../expr/conversion/input/StringImporter';

/**
 *
 */
export class EnvVariable extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement {
    if (args.length !== 2) {
      return args.expectingArguments(2, 2);
    }

    const name = args.getString(0);
    if(!name){
      throw new MathError('Expecting name');
    }

    const defaultValue = args.getContentElement(1);
    if(!defaultValue){
      throw new MathError('Expecting default value');
    }

    const envVar = args.env.extensions.getEnvVariable(name.getString());
    if (envVar !== null) {
      try{
        this.newValueNode = new StringImporter(envVar, null, args.env, false).expr.root;
        return null;
      }catch(e){
        // console.log(e);
      }
    }

    return defaultValue;
  }

}
