/**
 *
 */
export class RestrictPolicy {

  public static ALLOW:string = 'allow';

  public static DENY:string = 'deny';

}
