import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { ListElement } from '../../elements/abstract/ListElement';
import { WList } from '../../elements/tokens/WList';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Détecte le modèle utilisé pour créer la liste.
 */
export class Pattern extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 1){
      return args.expectingArguments(1, 1);
    }

    const values:WList = args.getReals(0);
    if(!values){
      return null;
    }

    if(values.count === 0){
      return args.env.culture.createEmptyList();
    }
    if(values.count === 1){
      return values;
    }

    return this.reals(args.getReals(0));
  }

  /**
   *
   */
  private reals(values:WList):ListElement{
    const o:number[] = values.toNumbersV();

    let v:boolean = true;
    for(let j:number = 1 ; j < o.length ; j++){
      if(o[0] !== o[j]){
        v = false;
        break;
      }
    }
    if(v){
      return values.sublist(0, 1);
    }

    for(let k:number = 2 ; k < values.count ; k++){
      v = true;
      for(let i:number = 0 ; i < o.length ; i++){
        if(o[i] !== o[i % k]){
          v = false;
          break;
        }
      }
      if(v){
        return values.sublist(0, k);
      }
    }

    return values;
  }

}
