import { rafThrottle } from '../../../utils/rafThrottle';

export type BreakpointCallback = (width: number) => void;
export type BreakpointElement = React.MutableRefObject<HTMLElement>;

class BreakpointService {

  private watchedElements = new Map<HTMLElement, BreakpointCallback>();

  private isRunning = false;

  constructor() {
    this.onWindowResize = rafThrottle(this.onWindowResize.bind(this));
  }

  public register(breakpoint: BreakpointElement, callback: BreakpointCallback): void {
    const container = breakpoint.current.parentElement;
    this.addToWatchedElements(container, callback);
    this.setElementSize(container, callback);
    if (this.watchedElements.size === 1) {
      this.addListener();
    }
  }

  public unregister(parentElement: HTMLElement): void {
    this.watchedElements.delete(parentElement);
    if (!this.watchedElements.size) {
      this.removeListener();
    }
  }

  private addToWatchedElements(container: HTMLElement, callback: BreakpointCallback): void {
    if (this.watchedElements.has(container)) {
      const previous = this.watchedElements.get(container);
      this.watchedElements.set(container, (w) => {
        previous(w);
        callback(w);
      });
      return;
    }

    this.watchedElements.set(container, callback);
  }

  private addListener(): void {
    window.addEventListener('resize', this.onWindowResize);
  }

  private removeListener(): void {
    window.removeEventListener('resize', this.onWindowResize);
    (this.onWindowResize as any).cancel();
  }

  private onWindowResize(): void {
    if (!this.isRunning) {
      this.isRunning = true;
      this.batchUpdates();
    }

    // This cancels the previous run if it wasn't finished
    // but could also allow updates to de-sync with the resize.
    this.isRunning = false;
  }

  private batchUpdates(): void {
    const dataset = Array.from(this.watchedElements.entries());
    dataset.every((breakpoint, index) => {
      setTimeout(
        () => this.setElementSize(breakpoint[0], breakpoint[1]),
        (index % 25) * 5
      );
      return this.isRunning;
    });
  }

  private async setElementSize(breakpoint: HTMLElement, callback: BreakpointCallback): Promise<void> {
    if (breakpoint.parentElement) {
      callback(breakpoint.parentElement.offsetWidth);
    }
  }
}

export default new BreakpointService();
