import { XSort } from '../../core/XSort';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { TokenElement } from '../../elements/abstract/TokenElement';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';
import { HtmlExporter } from '../../expr/conversion/output/HtmlExporter';
import { XString } from '../../core/XString';

export type StringSources = 'unknown' | 'input';

/**
 * Wrapper class for String primitive datatype.
 */
export class WString extends TokenElement {
  private _value: string;

  private readonly _source: StringSources;

  private _speakText: string;

  constructor(value: string, speakText: string = null, source: StringSources = 'unknown') {
    super();
    this._value = value;
    this._speakText = speakText;
    this._source = source;
  }

  public getString(): string {
    return this._value;
  }

  public toString(): string {
    return this._value;
  }

  public toText(strict: boolean): string {
    return this._value;
  }

  public getSource(): StringSources {
    return this._source;
  }

  public toSpeakText(): string {
    return this._speakText;
  }

  public substitute(value: string, speakText: string = null): void {
    this._value = value;
    this._speakText = speakText;
  }

  /**
   * If the string comes from user input, we encode all the string. If not, then
   * we encode only the characters that are not part of a valid html tag.
   * @param strict
   */
  public toEncodedText(strict: boolean): string {
    if (this._source === 'input') {
      return XString.escapeXml(this._value);
    }
    return HtmlExporter.sanitizeXml(this._value);
  }

  public equalsTo(value: ContentElement): boolean {
    if (value instanceof WString) {
      return this._value === value._value;
    }
    return false;
  }

  public getListItemCode(): string {
    return WString.getListItemCode();
  }

  public static getListItemCode(): string {
    return 'string';
  }

  public writeTo(exporter: IMarkupExporter = null): boolean {
    if (exporter) {
      exporter.writeText(this._value);
    }
    return true;
  }

  public hashCode(): string {
    return String(this._value);
  }

  public combineSources(otherString: WString): StringSources {
    return WString.getCombinedSource(this.getSource(), otherString.getSource());
  }

  public static getCombinedSource(source: StringSources, otherSource: StringSources): StringSources {
    if (source === 'input' || otherSource === 'input') {
      return 'input';
    }
    return 'unknown';
  }

  public static compare(a: WString, b: WString): number {
    return XSort.str(a.getString(), b.getString());
  }

  public getType(): string {
    return 'string';
  }
}
