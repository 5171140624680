import React from 'react';
import { isBoldMarkActive, toggleBoldMark } from '../../utils/characterFormatUtils';
import { ICustomEditor } from '../../models';
import { Button } from '../../../Button';
import BoldIcon from '../../../../../assets/bold.svg';
import { IClassDefinition } from '../../models/configuration';
import { Tooltip } from '../../../../utils/Tooltip/Tooltip';

interface IBoldControlProps {
  editor: ICustomEditor;
  label: string;
  classes: ReadonlyArray<IClassDefinition>;
}

export const BoldControl: React.FC<IBoldControlProps> = ({ editor, label, classes }) => {
  const onMouseDown = (event: React.MouseEvent) => {
    event.preventDefault();
    toggleBoldMark(editor, classes);
  };

  return (
    <Tooltip
      title={label}
    >
      <Button
        active={isBoldMarkActive(editor, classes)}
        onMouseDown={onMouseDown}
        aria-label={label}
        icon
      >
        <BoldIcon />
      </Button>
    </Tooltip>
  );
};
