import { TriangleAssert } from '../../../../funcs/asserts/geom/triangles/TriangleAssert';

/**
 * Est un triangle rectangle isocèle?
 */
export class IsIsorectangle extends TriangleAssert {

  /**
   *
   */
  protected assertTrue(
      angles:number[],
      edges:number[]):boolean{

    return 	(edges[0] === edges[1] || edges[1] === edges[2] || edges[2] === edges[0]) && 	// isosceles
        (angles[0] === 90 || angles[1] === 90 || angles[2] === 90); 					// right
  }

}
