import { ContentElement } from '../../elements/abstract/ContentElement';
import { RelationalElement } from '../../elements/abstract/RelationalElement';
import { TokenElement } from '../../elements/abstract/TokenElement';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';

/**
 *
 */
export class WRelation extends TokenElement {

  /**
   *
   */
  private _lhs:TokenElement;

  public get lhs():TokenElement{return this._lhs;}

  /**
   *
   */
  private _rel:RelationalElement;

  public get rel():RelationalElement{return this._rel;}

  /**
   *
   */
  private _rhs:TokenElement;

  public get rhs():TokenElement{return this._rhs;}

  /**
   *
   */
  constructor(
      lhs:TokenElement,
      rel:RelationalElement,
      rhs:TokenElement){
    super();
    this._lhs = lhs;
    this._rel = rel;
    this._rhs = rhs;
  }

  /**
   *
   */
  public writeTo(exporter:IMarkupExporter = null):boolean{
    if(exporter){
      exporter.writer.beginRow();
      this.lhs.writeTo(exporter);
      exporter.writeOperator(this.rel.toString());
      this.rhs.writeTo(exporter);
      exporter.writer.endRow();
    }
    return true;
  }

  /**
   * Returns true if both part are equals and also the relational operator.
   * Also accepts the case where the sides were reversed.
   */
  public equalsTo(value:ContentElement):boolean{
    if(value instanceof WRelation){
      const other:WRelation = <WRelation>value ;
      return (String(this.rel) === String(other.rel) &&
          this.lhs.equalsTo(other.lhs) &&
          this.rhs.equalsTo(other.rhs)) ||
          (String(this.rel) === String(other.rel.reverse) &&
          this.lhs.equalsTo(other.rhs) &&
          this.rhs.equalsTo(other.lhs));
    }
    return false;
  }

  public strictlyEqualsTo(value:ContentElement):boolean{
    if(value instanceof WRelation){
      throw new Error('Not implemented');
    }
    return false;
  }

  /**
   *
   */
  public getType():string{
    return 'relation';
  }

}
