import { XMath } from '../../../core/XMath';
import { ContentElement } from '../../../elements/abstract/ContentElement';
import { WPolynomial } from '../../../elements/tokens/WPolynomial';
import { WVariable } from '../../../elements/tokens/WVariable';
import { CultureInfo } from '../../../localization/CultureInfo';
import { AlternateForm } from '../../../expr/manipulation/alt/AlternateForm';

/**
 *
 */
export class PolynomialForm extends AlternateForm {

  /**
   *
   */
  constructor(culture:CultureInfo){
    super(culture);
  }

  /**
   *
   */
  public alt(value:ContentElement):string{
    if(value instanceof WPolynomial){
      const p:WPolynomial = <WPolynomial>value ;
      // b³ --> b ⋅ b ⋅ b
      if(p.numMonomials === 1){
        if(p.coefs[0].toNumber() === 1 && p.symbols.length === 1){
          const symbol:string = p.symbols[0].getSymbol();
          const symbols:any[] = [];
          for(let i = 0 ; i < p.power(0, 0) ; i++){
            symbols.push(symbol);
          }
          return symbols.join('⋅');
        }
      }

      const t:any[] = [];
      for(let i:number = 0 ; i < p.numMonomials ; i++){
        const coef:number = p.coefs[i].toNumber();
        if(p.sumPowers(i) === 0){
          t.push(coef);
        }else if(XMath.isInteger(coef)){
          // coefficient entier: 2a^2 -->a^2+a^2
          for(let g:number = 0 ; g < Math.abs(coef) ; g++){
            t.push(this.term(p, i));
          }
        }else{
          t.push(`${coef}\u2062${this.term(p, i)}`); // invisible times
        }
      }
      return t.join('+').split('+-').join('-');
    }
    if(value instanceof WVariable){
      return (<WVariable>value ).getSymbol();
    }

    return null;
  }

  private term(p:WPolynomial, m:number):string{
    const o:any[] = [];
    for(let k:number = 0 ; k < p.symbols.length ; k++){
      const power:number = p.power(m, k);
      if(power === 1){
        o.push(p.symbols[k].getSymbol());
      }else if(power > 1){
        o.push(`${p.symbols[k].getSymbol()}^${power}`);
      }
    }
    return o.join('\u2062'); // invisible times
  }

}
