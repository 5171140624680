import { NodeConstant } from '../../../elements/functions/tree/NodeConstant';
import { EmptyLeaf } from '../../../elements/functions/tree/EmptyLeaf';
import { AbstractNode } from '../../../elements/functions/tree/AbstractNode';

/**
 *
 */
export class NodeArithmetic extends AbstractNode {

  public static ADDITION:string = '+';

  public static SUBSTRACTION:string = '-';

  public static MULTIPLICATION:string = '∙';

  private _operation:string;

  constructor( operation:string ) {
    super();
    this._operation = operation;
  }

  public getToken():any[] {

    const leftValue:any[] = this.left.getToken();
    const rightValue:any[] = this.right.getToken();

    leftValue.push( this._operation );

    return leftValue.concat( rightValue );
  }

  public simplify():AbstractNode {
    const leftNode:AbstractNode = this.left.simplify();
    let rightNode:AbstractNode = this.right.simplify();
    let operator:string = this._operation;

    if( this._operation === NodeArithmetic.ADDITION || this._operation === NodeArithmetic.SUBSTRACTION ) {
      if( leftNode instanceof EmptyLeaf && rightNode instanceof EmptyLeaf ) {
        return leftNode;
      }

      if( rightNode instanceof EmptyLeaf ) {
        return leftNode;
      }

      if( rightNode instanceof NodeConstant ){
        const constant:NodeConstant = <NodeConstant>rightNode ;

        if( constant.toNumber() < 0 ) {

          operator = ( operator === NodeArithmetic.ADDITION ) ? NodeArithmetic.SUBSTRACTION : NodeArithmetic.ADDITION;
          rightNode = constant.toAbsoluteValue();
        }
      }

      if( leftNode instanceof EmptyLeaf && operator === NodeArithmetic.ADDITION ) {
        return rightNode;
      }

    } else if( this._operation === NodeArithmetic.MULTIPLICATION ) {

      if( leftNode instanceof EmptyLeaf ) {
        return rightNode;
      }

      if( rightNode instanceof EmptyLeaf ) {
        return leftNode;
      }

      if( leftNode.isNumber() && leftNode.toNumber() === 0 ) {
        return new EmptyLeaf();
      }

      if( rightNode.isNumber() && rightNode.toNumber() === 0 ) {
        return new EmptyLeaf();
      }
    }

    return ( new NodeArithmetic( operator  ) )
      .setLeft(leftNode)
      .setRight(rightNode);

  }
}
