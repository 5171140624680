import { XRound } from '../../core/XRound';
import { IPrng } from '../../core/prng/IPrng';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Compatibility
 */
export class RandomRelTR extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 0){
      return args.expectingArguments(0, 0);
    }
    return args.env.culture.listFactory.createFromArray(this.rand(args.prng));
  }

  /**
   * h, i, k, j, l, m
   */
  private rand(prng:IPrng):any[]{
    const o:any[] = [
      [12, 9, 15, 16, 20],
      [60, 25, 65, 144, 156],
      [120, 64, 136, 225, 255],
      [168, 49, 175, 576, 600],
      [360, 81, 369, 1600, 1640],
      [420, 144, 444, 1225, 1295],
      [420, 400, 580, 441, 609],
      [660, 121, 671, 3600, 3660],
      [1008, 256, 1040, 3969, 4095],
      [1092, 169, 1105, 7056, 7140],
      [1260, 784, 1484, 2025, 2385],
      [1848, 1089, 2145, 3136, 3640],
      [1980, 400, 2020, 9801, 9999],
      [2640, 2304, 3504, 3025, 4015],
      [2772, 1296, 3060, 5929, 6545],
      [3120, 1521, 3471, 6400, 7120]];

    const a:any[] = o[prng.randomIndex(o.length)];
    let d:number;
    if(prng.random() > 0.5){
      d = a[1];
      a[1] = a[3];
      a[3] = d;
      d = a[2];
      a[2] = a[4];
      a[4] = d;
    }

    a.push(a[1] + a[3]);

    let k:number = Math.max.apply(this, a);
    let div:number = k;
    k = prng.randomInt(1, Math.round(9999 / k));
    if(k === 0){
      k = 1;
    }
    div = k * div;
    if(div < 1000){
      div = 10;
    }else{
      div = 100;
    }
    for(let i:number = 0 ; i < a.length ; i++){
      a[i] = XRound.safeRound(a[i] * k / div);
    }

    return a;
  }

}
