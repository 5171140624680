import { runInAction } from 'mobx';
import { PagesStore } from '../models/store/PagesStore';
import { IPage } from '../models/IPage';
import { instantiate } from '../dependencyInjection/instantiate';

export function createPageCommand(pageInfo: IPage) {
  runInAction(() => {
    const store = instantiate(PagesStore);
    store.addPage(pageInfo);
  });
}
