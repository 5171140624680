import { StringNavigator } from '../../core/str/StringNavigator';
import { StringQuery } from '../../funcs/str/StringQuery';

/**
 *
 */
export class Vowels extends StringQuery {

  /**
   *
   */
  protected searchPattern():RegExp{
    return new RegExp(`[${StringNavigator.VOWELS}]`, 'gi');
  }

}
