import { FunctionElement } from '../../../elements/abstract/FunctionElement';
import { ArgumentsObject } from '../../../expr/ArgumentsObject';
import { ContentElement } from '../../../elements/abstract/ContentElement';
import { WSegment } from '../../../elements/tokens/WSegment';
import { Point } from '../../../../js/geom/Point';
import { WListOfSegments } from '../../../elements/tokens/WListOfSegments';

/**
 * À partir d'une matrice, retourne tous les côtés d'une figure
 * représentée par un nombre spécifié dans la matrice.
 *
 * Ex.
 */
export class ShapeEdges extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 2){
      return args.expectingArguments(2, 2);
    }

    const value = args.getMatrix(0);
    if(value == null){
      return null;
    }

    const shape = args.getReal(1);
    if(shape == null){
      return null;
    }

    const edges:WSegment[] = [];
    const n:Number = shape.toNumber();

    for(let r:number = 0 ; r < value.rows ; r++){
      for(let c:number = 0 ; c < value.columns ; c++){
        if(value.valueAt(r, c).toNumber() === n){

          // check over
          if(r === 0 || value.valueAt(r - 1, c).toNumber() !== n){
            edges.push(new WSegment(new Point(c, r), new Point(c + 1, r)));
          }

          // check under
          if(r === value.rows - 1 || value.valueAt(r + 1, c).toNumber() !== n){
            edges.push(new WSegment(new Point(c, r + 1), new Point(c + 1, r + 1)));
          }

          // check on the left
          if(c === 0 || value.valueAt(r, c - 1).toNumber() !== n){
            edges.push(new WSegment(new Point(c, r), new Point(c, r + 1)));
          }

          // check on the right
          if(c === value.columns - 1 || value.valueAt(r, c + 1).toNumber() !== n){
            edges.push(new WSegment(new Point(c + 1, r), new Point(c + 1, r + 1)));
          }
        }
      }
    }

    return new WListOfSegments(edges, args.env.culture.listFormatter);
  }

}
