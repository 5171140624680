import { Point } from '../../../js/geom/Point';
import { AbstractStep } from '../AbstractStep';
import { Compartment } from '../Compartment';
import { LongMultiplicationOperation } from '../LongMultiplicationOperation';

/**
 *
 */
export class MultMultiply extends AbstractStep {

  private digit1:number;

  private digit2:number;

  private carry:number;

  constructor(
      operation:LongMultiplicationOperation){
    super(operation);
    const p:Point = operation.multCursor;

    if(p.x === 0 && p.y === 0){
      operation.multSteps.push([]);
      operation.multCarries.push([]);
      operation.lastMultCarries.push(null);
      for(let i:number = 0 ; i < p.y ; i++){
        operation.lastMultStep.push(null);
      }
    }

    this.carry = operation.multCarried;

    const o1:Compartment = operation.operand1[(operation.operand1.length - 1) - p.x];
    const o2:Compartment = operation.operand2[(operation.operand2.length - 1) - p.y];

    this.digit1 = o1.value;
    this.digit2 = o2.value;

    const product:number = this.digit1 * this.digit2 + this.carry;

    this.source.push(o1);
    this.source.push(o2);

    const lastCarry:Compartment = operation.lastMultCarries[0];
    if(lastCarry){
      this.source.push(lastCarry);
    }

    let c:Compartment = Compartment.createDigit(product % 10);
    operation.lastMultStep.unshift(c);
    this.target.push(c);

    if(product > 9 && p.x === (operation.operand1.length - 1)){
      // Last digit of the first operand, we don't carry over, we write the product directly.
      c = Compartment.createDigit(Math.floor(product / 10));
      operation.lastMultStep.unshift(c);
      this.target.unshift(c);
      operation.multCarried = 0;
      operation.nextMult();
    }else if(product > 9){
      operation.multCarry = Math.floor(product / 10);
    }else{
      operation.multCarried = 0;
      operation.lastMultCarries.unshift(null);
      operation.nextMult();
    }

    const resourceKey =
      this.carry === 0 ?
        'multiplyLabel' :
        'multiplyCarryLabel';

    this.setDescription(
      operation.culture.getString(
        `LongMultiplicationOperation.${resourceKey}`,
        this.digit1,
        this.digit2,
        this.carry));
  }

}
