/**
 *
 */
export class OptionsProvider {

  /**
   * When a variable has no option defined,
   * we use this set of default options:
   *
   * -simplifyRationals (4)
   * -simplifyRadicals (32768)
   * -simplifyAlgebraicFractions (131072)
   */
  public static DEFAULT_FLAGS:number = 4 | 32768 | 131072;

  /**
   *
   */
  private static _instance:OptionsProvider;

  public static getDefaultInstance():OptionsProvider{
    if(!OptionsProvider._instance){
      OptionsProvider._instance = new OptionsProvider(OptionsProvider.DEFAULT_FLAGS, null);
    }
    return OptionsProvider._instance;
  }

  /**
   *
   */
  private _flags:number;

  /**
   *
   */
  private _piecewiseRange:string;

  /**
   *
   */
  constructor(flags:number, piecewiseRange:string){
    this._flags = flags;
    this._piecewiseRange = piecewiseRange;
  }

  public get flags():number{return this._flags;}

  /**
   *
   */
  public get preserveRationals():boolean{return (this._flags & 1) > 0;}

  public get intervalInequalities():boolean{return (this._flags & 2) > 0;}

  public get simplifyRationals():boolean{return (this._flags & 4) > 0;}

  public get preserveWords():boolean{return (this._flags & 8) > 0;}

  public get preserveVariables():boolean{return (this._flags & 16) > 0;}

  public get exclude1():boolean{return (this._flags & 32) > 0;}

  public get exclude0():boolean{return (this._flags & 64) > 0;}

  public get disableSetsAutoSort():boolean{return (this._flags & 128) > 0;}

  public get enableMultisets():boolean{return (this._flags & 256) > 0;}

  public get restrictDigitZero():boolean{return (this._flags & 512) > 0;}

  public get preservePowers():boolean{return (this._flags & 1024) > 0;}

  public get preserveRadicals():boolean{return (this._flags & 2048) > 0;}

  public get preventRationalCoefs():boolean{return (this._flags & 4096) > 0;}

  public get preserveLogs():boolean{return (this._flags & 8192) > 0;}

  public get preserveRelations():boolean{return (this._flags & 16384) > 0;}

  public get simplifyRadicals():boolean{return (this._flags & 32768) > 0;}

  public get extractMinus():boolean{return (this._flags & 65536) > 0;}

  public get simplifyAlgebraicFractions():boolean{return (this._flags & 131072) > 0;}

  public get piecewiseRange():string{return this._piecewiseRange;}

}
