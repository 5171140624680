/**
 *
 */
export class Combnk {

  /**
   *
   */
  private static bitprint(uArg:number):number[]{
    const s:number[] = [];
    let u = uArg;
    for(let n:number = 0 ; u > 0 ; ++n, u = u >> 1){
      if((u & 1) > 0){
        s.push(n);
      }
    }
    return s;
  }

  /**
   *
   */
  private static bitcount(uArg:number):number{
    let n:number;
    let u = uArg;
    for(n = 0 ; u > 0 ; u &= (u - 1)){
      n++;
    }
    return n;
  }

  /**
   *
   */
  public static comb(
      n:number,
      k:number):any[]{

    const s:any[] = [];

    for(let u:number = 0 ; u < 1 << n ; u++){
      if(Combnk.bitcount(u) === k){
        s.push(Combnk.bitprint(u));
      }
    }

    if(s.length !== Combnk.binomial(n, k)){
      throw new Error();
    }
    return s.sort(Combnk.sortComb);
  }

  /**
   *
   */
  private static sortComb(
      a:number[],
      b:number[]):number{

    for(let i:number = 0 ; i < Math.min(a.length, b.length) ; i++){
      if(a[i] < b[i]){
        return -1;
      }
      if(a[i] > b[i]){
        return 1;
      }
    }

    if(a.length < b.length){
      return -1;
    }
    if(a.length > b.length){
      return 1;
    }

    return 0;
  }

  /**
   * 	n
   * ( )
   *  k
   */
  public static binomial(
      nArg:number,
      k:number):number{
    let r:number = 1;
    let n = nArg;
    if(k > n){
      return 0;
    }
    for(let d:number = 1 ; d <= k ; d++){
      r *= n--;
      r /= d;
    }
    return r;
  }

}
