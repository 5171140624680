export const IMemberInfo = Symbol('IMemberInfo');

export enum MemberTitle {
  ADMIN = 'admin',
  AUTHOR = 'author',
  DESIGN = 'design',
  DESIGNER = 'designer',
  DEV_CONTENT = 'dev_content',
  DEV_PLATFORM = 'dev_platform',
  DEVELOPER = 'developer',
  EDITOR = 'editor',
  EXTERNAL_TESTER = 'external_tester',
  ILLUSTRATOR = 'illustrator',
  INTEGRATOR = 'integrator',
  MANAGER = 'manager',
  OTHER = 'other',
  PRODUCT_OWNER = 'po',
  SALES = 'sales',
  SUPPORT = 'support',
  TESTER = 'tester',
  TRANSLATOR = 'translator',
  GUEST = 'guest',
}

export interface IMemberInfo {
  id: number;
  internalId: string;
  name: string;
  firstName: string;
  lastName: string;
  email: string;
  avatar: string;
  initials: string;
  title: MemberTitle;
  isActive: boolean;
  isAdmin: boolean;
  isCurrent: boolean;
}
