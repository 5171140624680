import { XString } from '../core/XString';
import { Expression } from '../elements/abstract/Expression';
import { WExponential } from '../elements/tokens/WExponential';
import { WExpression } from '../elements/tokens/WExpression';
import { WFiniteSet } from '../elements/tokens/WFiniteSet';
import { WPolynomial } from '../elements/tokens/WPolynomial';
import { WRatio } from '../elements/tokens/WRatio';
import { WTimeOfDay } from '../elements/tokens/WTimeOfDay';
import { Environment } from '../expr/Environment';
import { CultureInfo } from '../localization/CultureInfo';
import {SymbolElement} from '../elements/abstract/SymbolElement';
import { ContentElement } from '../elements/abstract/ContentElement';

/**
 * https://docs.google.com/a/scolab.com/document/d/1aQ09iNH0t7tu4X7EFXrZu5ejzS67eeyW_969y8NFcX8/edit#
 */
export class KeyboardConfiguration {

  public static NONE:number = 0;

  public static STANDARD:number = 1;

  public static NUMERIC:number = 2;

  public static NUMERIC_EXP:number = 3;

  public static FRACTION:number = 4;

  public static RATIO:number = 5;

  public static POLYNOMIAL:number = 6;

  public static POLYNOMIAL_DIV:number = 6.1;

  public static POWER:number = 7;

  public static POWER_DIV:number = 7.1;

  public static TIME:number = 8;

  public static LINE:number = 9;

  public static FINITE_SET:number = 10;

  public static INTERVAL:number = 11;

  public static EXPRESSION:number = 12;

  public static DECIMAL_EXP:number = 13;

  public static INEQUALITY:number = 14;

  public static SET_BUILDER:number = 15;

  public static FACTORS:number = 16;

  public static TRANSFORM:number = 17;

  public static RADICAL:number = 18;

  public static RADICAL_DIV:number = 18.1;

  public static NEGATIVE_EXP:number = 19;

  public static FRACTION_EXP:number = 20;

  public static NUMBER_SET:number = 21;

  public static EXP_FRACTION:number = 22;

  public static LOGARITHM:number = 23;

  public static RELATION:number = 24;

  public static INFINITY:number = 25;

  public static EXPONENTIAL:number = 26;

  public static ELEMENTARY12:number = 27;

  //
  public culture:CultureInfo;

  // Display row of digits
  public digits:boolean;

  // Display two rows of letters
  public letters:boolean;

  // List of symbols to display, use array since symbols could be more than one char in length.
  public symbols:any[];

  // Split the symbols into two groups to display on both sides of the space button.
  // If -1, then leave all symbols on one row and put the space alone in the last row.
  public splitIndex:number;

  // Display exponent toggle mode button
  public exponent:boolean;

  /**
   * Returns whether that keyboard should enable
   * pasting numeric results obtained in the calculator.
   */
  public get pasteNumberEnabled():boolean{
    return 	this.pasteIntegerEnabled &&
        this.symbols.indexOf(this.culture.numberFormatter.decimalSeparator) !== -1;
   }

  public get pasteIntegerEnabled():boolean{
    return this.digits && this.symbols.indexOf('−') !== -1;
  }

  constructor(
      culture:CultureInfo,
      digits:boolean,
      letters:boolean,
      symbols:any[],
      splitIndex:number,
      exponent:boolean){

    this.culture = culture;
    this.digits = digits;
    this.letters = letters;
    this.symbols = symbols;
    this.splitIndex = splitIndex;
    this.exponent = exponent;
  }

  public static createNumericKeyboard(
      culture:CultureInfo,
      decimal:boolean = true,
      negative:boolean = true,
      fraction:boolean = false):KeyboardConfiguration{

    const s:any[] = [];
    if(negative){
      s.push('−');
    }
    if(fraction){
      s.push('/');
    }
    if(decimal){
      s.push(culture.numberFormatter.decimalSeparator);
    }
    return new KeyboardConfiguration(
      culture,
      true,
      false,
      s,
      0,
      false);
  }

  public static createUnivariantePolynomial(
      culture:CultureInfo,
      rational:boolean = false,
      varName:string = 'x'):KeyboardConfiguration{

    return new KeyboardConfiguration(
      culture,
      true,
      false,
      KeyboardConfiguration.symbolsList(`${varName}.,−+${(rational ? '/' : '')}`),
      3,
      false);
  }

  /**
   * Returns null for standard keyboard
   * Space and backspace are always visible
   */
  public static createConfiguration(
      type:number,
      expr:Expression,
      env:Environment):KeyboardConfiguration{

    let lettersList:string;
    let sep:string = ':';
    let numeric:boolean = false;
    let points:boolean = false;
    const divChar = type === KeyboardConfiguration.POLYNOMIAL_DIV || type === KeyboardConfiguration.POWER_DIV || type === KeyboardConfiguration.RADICAL_DIV ? '/' : '';

    switch(type){
      case KeyboardConfiguration.NONE:		return null;
      case KeyboardConfiguration.NUMERIC:
      case KeyboardConfiguration.NUMERIC_EXP:

        return new KeyboardConfiguration(
          env.culture,
          true,
          false,
          KeyboardConfiguration.symbolsList('+−×÷.,;()%'),
          4,
          type === KeyboardConfiguration.NUMERIC_EXP);

      case KeyboardConfiguration.DECIMAL_EXP:

        return new KeyboardConfiguration(
          env.culture,
          true,
          false,
          KeyboardConfiguration.symbolsList('−.,‾'),
          2,
          false);

      case KeyboardConfiguration.INFINITY:

        return new KeyboardConfiguration(
          env.culture,
          true,
          false,
          KeyboardConfiguration.symbolsList('+−∞.,'),
          3,
          false);

      case KeyboardConfiguration.EXPRESSION:

        if(expr){
          lettersList = KeyboardConfiguration.filterLowerLetters((<WExpression>expr.root.value ).rawExpression);
        }else{
          lettersList = '';
        }

        return new KeyboardConfiguration(
          env.culture,
          true,
          false,
          KeyboardConfiguration.symbolsList(`${lettersList}+−×÷=.,()`),
          5 + lettersList.length,
          !env.culture.configuration.expressionsKeyboardWithoutExponent);

      case KeyboardConfiguration.FRACTION:

        return new KeyboardConfiguration(env.culture, true, false, KeyboardConfiguration.symbolsList('−/'), 2, false);

      case KeyboardConfiguration.FRACTION_EXP:

        return new KeyboardConfiguration(env.culture, true, false, KeyboardConfiguration.symbolsList('−/^()'), 2, false);

      case KeyboardConfiguration.EXP_FRACTION:

        return new KeyboardConfiguration(env.culture, true, false, KeyboardConfiguration.symbolsList('−^/'), 2, false);

      case KeyboardConfiguration.POWER:
      case KeyboardConfiguration.POWER_DIV:

        return new KeyboardConfiguration(env.culture, true, false, KeyboardConfiguration.symbolsList(`−.,()${divChar}`), 3, true);

      case KeyboardConfiguration.POLYNOMIAL:
      case KeyboardConfiguration.POLYNOMIAL_DIV:
        if(expr){
          lettersList = '';
          expr.root.getFlattenedValues().forEach((value: ContentElement) => {
            if(value instanceof WPolynomial){
              const polynomial:WPolynomial = value as WPolynomial;
              if(polynomial.normalize(env.reals) instanceof WPolynomial){
                for(let i:number = 0 ; i < polynomial.symbols.length ; i++){
                  lettersList += polynomial.symbols[i].getSymbol();
                }
              }
            } else if (value instanceof SymbolElement) {
              lettersList += (value as SymbolElement).getSymbol();
            }
          });
        }

        if(lettersList && lettersList.length > 0){
          return new KeyboardConfiguration(
            env.culture,
            true,
            false,
            KeyboardConfiguration.symbolsList(`${lettersList}.,−+${divChar}`),
            lettersList.length,
            false);
        }
        return new KeyboardConfiguration(
          env.culture,
          true,
          true,
          KeyboardConfiguration.symbolsList(`.,−+π${divChar}`),
          2,
          false);

      case KeyboardConfiguration.FACTORS:

        return new KeyboardConfiguration(env.culture, true, true, KeyboardConfiguration.symbolsList('.,()−+/π'), 4, false);

      case KeyboardConfiguration.RATIO:
        sep = ':';
        if(expr){
          if(expr.root.value instanceof WRatio){
            const ratio:WRatio = <WRatio>expr.root.value ;
            sep = ratio.formatter.getSeparator();
            if(sep === '/'){
              sep = '∕';
            }
          }
        }

        return new KeyboardConfiguration(
          env.culture,
          true,
          false,
          KeyboardConfiguration.symbolsList(XString.substitute('−{0}.,', sep)),
          3,
          false);

      case KeyboardConfiguration.TIME:

        const minLabel = env.culture.getString('Time.minLabel');
        const timeKeyboard = ['h', minLabel, 's', ':'];

        let twelveHoursClock:boolean;

        if(expr){
          if(expr.root.value instanceof WTimeOfDay){
            const time:WTimeOfDay = <WTimeOfDay>expr.root.value ;
            twelveHoursClock = time.formatter.useTwelveHoursClock();
          }
        }else{
          twelveHoursClock = env.culture.configuration.twelveHoursClock;
        }

        if(twelveHoursClock){
          timeKeyboard.push('am', 'pm');
        }

        return new KeyboardConfiguration(env.culture, true, false, timeKeyboard, 4, false);

      case KeyboardConfiguration.LINE:		return new KeyboardConfiguration(env.culture, true, false, KeyboardConfiguration.symbolsList('xy−+/.,=<>≤≥'), 7, false);
      case KeyboardConfiguration.FINITE_SET:
        numeric = false;
        points = false;
        if(expr){
          if(expr.root.value instanceof WFiniteSet){
            const finiteSet:WFiniteSet = <WFiniteSet>expr.root.value ;
            numeric = finiteSet.isNumeric;
            points = finiteSet.isSetOfPoints;
          }
        }

        if(points){
          return new KeyboardConfiguration(
            env.culture,
            true,
            false,
            KeyboardConfiguration.symbolsList('−.,(){}∅'),
            3,
            false);
        }

        return new KeyboardConfiguration(
          env.culture,
          true,
          !numeric,
          KeyboardConfiguration.symbolsList('−.,{}∅'),
          3,
          false);

      case KeyboardConfiguration.INTERVAL:
      case KeyboardConfiguration.NUMBER_SET:

        const useSimplifiedIntervalKeyboard:boolean = env.culture.configuration.useSimplifiedIntervalKeyboard;
        const operations:string = useSimplifiedIntervalKeyboard ? '∞' : '∞∪∖';
        const sets:string = type === KeyboardConfiguration.NUMBER_SET ? 'ℕℚℝℤ' : (useSimplifiedIntervalKeyboard ? '' : 'ℝ');

        let fences = '[]';
        if (!useSimplifiedIntervalKeyboard) {
          fences = `{}${fences}`;
        }
        if(env.culture.acceptIntervalNotationWithParenthesis){
          fences = `${fences}()`;
        }

        return new KeyboardConfiguration(
          env.culture,
          true,
          false,
          KeyboardConfiguration.symbolsList(`−+.,${operations}${fences}${sets}`),
          operations.length + 4,
          false);

      case KeyboardConfiguration.INEQUALITY:
        return new KeyboardConfiguration(env.culture, true, true, KeyboardConfiguration.symbolsList('−+.,≤≥<>'), 4, false);
      case KeyboardConfiguration.RELATION:
        return new KeyboardConfiguration(env.culture, true, true, KeyboardConfiguration.symbolsList('−+.,=≤≥<>'), 4, false);
      case KeyboardConfiguration.SET_BUILDER:
        return new KeyboardConfiguration(env.culture, true, true, KeyboardConfiguration.symbolsList('{∈ℕℚℝℤ|−.,<>≤≥}'), -1, false);
      case KeyboardConfiguration.TRANSFORM:
        return new KeyboardConfiguration(env.culture, true, false, KeyboardConfiguration.symbolsList('(xy−+,.)'), 3, false);
      case KeyboardConfiguration.RADICAL:
      case KeyboardConfiguration.RADICAL_DIV:
        return new KeyboardConfiguration(env.culture, true, false, KeyboardConfiguration.symbolsList(`√∛∜−.,${divChar}`), 3, false);
      case KeyboardConfiguration.NEGATIVE_EXP:
        return new KeyboardConfiguration(env.culture, true, true, KeyboardConfiguration.symbolsList('−'), 0, true);
      case KeyboardConfiguration.LOGARITHM:
        return new KeyboardConfiguration(env.culture, true, false, ['log', 'ln', '(', ')'], 2, false);
      case KeyboardConfiguration.EXPONENTIAL:
        if(expr){
          const e:WExponential = <WExponential>expr.root.value ;
          return new KeyboardConfiguration(env.culture, true, false, KeyboardConfiguration.symbolsList(`−.,()${e.exponent.getSymbol()}`), 3, true);
        }
        return new KeyboardConfiguration(env.culture, true, true, KeyboardConfiguration.symbolsList('−.,()'), 3, true);

      case KeyboardConfiguration.ELEMENTARY12:
        return new KeyboardConfiguration(env.culture, true, false, [KeyboardConfiguration.symbolsList('+−<>=')], -1, false);
    }

    return null; // Standard keyboard
  }

  private static filterLowerLetters(value:string):string{
    const o:any[] = [];
    for(let i:number = 0 ; i < value.length ; i++){
      const c:string = value.charAt(i);
      if(c >= 'a' && c <= 'z'){
        o.push(c);
      }
    }
    return o.join('');
  }

  private static symbolsList(value:string):any[]{
    return value.split('');
  }

}
