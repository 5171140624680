// Doc: https://docs.wiris.com/en/mathtype/mathtype_web/features/custom-toolbar
//      https://www.wiris.net/demo/editor/docs/toolbar/

import { IntlShape } from 'react-intl';

const getLayoutTab = (intl: IntlShape) => `
  <tab name="${intl.formatMessage({ id: 'markupEditor.layoutTabTitle', defaultMessage: 'Layout' })}">
    <section layout="horizontal">
      <item ref="fraction" />
      <item ref="bevelledFraction" />
      <item ref="over" />
      <item ref="under" />
      <item ref="underover" />
      <item ref="nRoot" />
      <item ref="squareRoot" />
      <createButton
        icon='https://media.scolab.com/Publisher/VariableEditor/MarkupEditor/MathIcons/Subscript.png'
        title='subscript'
        offset='1'
      >
        <content>
           <msub><mrow/><mrow/></msub>
        </content>
      </createButton>
      <createButton
        icon='https://media.scolab.com/Publisher/VariableEditor/MarkupEditor/MathIcons/Superscript.png'
        title='superscript'
        offset='1'
      >
        <content>
           <msup><mrow/><mrow/></msup>
        </content>
      </createButton>
      <createButton
        icon='https://media.scolab.com/Publisher/VariableEditor/MarkupEditor/MathIcons/Subsuperscript.png'
        title='subsuperscript'
        offset='1'
      >
        <content>
           <msubsup><mrow/><mrow/><mrow/></msubsup>
        </content>
      </createButton>
      <createButton
        icon='https://media.scolab.com/Publisher/VariableEditor/MarkupEditor/MathIcons/Multiscript.png'
        title='multiscript'
        offset='2'
      >
        <content>
           <mmultiscripts><mrow/><mrow/><mrow/><mprescripts/><mrow/><mrow/></mmultiscripts>
        </content>
      </createButton>
      <item ref="barAccent" />
      <item ref="vectorAccent" />
      <createButton
        icon='https://media.scolab.com/Publisher/VariableEditor/MarkupEditor/MathIcons/LeftVectorAccent.png'
        title='leftVector'
        offset='3'
      >
        <content>
           <mover><mrow/><mo>&#x21bc;</mo></mover>
        </content>
      </createButton>
      <item ref="upCurlyBracket" />
      <item ref="upParenthesis" />
      <item ref="hatAccent" />
      <item ref="leftArrowWithUnderScript" />
      <item ref="rightArrowWithUnderScript" />
      <item ref="rightLeftArrowWithUnderScript" />
      <item ref="curlyBracket" />
      <item ref="angleBrackets" />
      <item ref="ceiling" />
      <item ref="floor" />
      <item ref="squareBracket" />
      <item ref="parenthesis" />
      <item ref="verticalBar" />
    </section>
  </tab>
`;

const getTableTab = (intl: IntlShape) => `
  <tab name="${intl.formatMessage({ id: 'markupEditor.tableTabTitle', defaultMessage: 'Table' })}">
    <section layout="horizontal" rows="1">
      <item ref="row" />
      <item ref="column" />
      <item ref="table" />
      <item ref="appendRow" />
      <item ref="prependRow" />
      <item ref="removeRow" />
      <item ref="appendColumn" />
      <item ref="prependColumn" />
      <item ref="removeColumn" />
    </section>
  </tab>
`;

const getCalculusTab = (intl: IntlShape) => `
  <tab name="${intl.formatMessage({ id: 'markupEditor.calculusTabTitle', defaultMessage: 'Calculus' })}">
    <section layout="horizontal" rows="1">
      <createButton
        icon='https://media.scolab.com/Publisher/VariableEditor/MarkupEditor/MathIcons/IntegralWithDifferential.png'
        title='Define integral with differential'
        offset='2'
      >
        <content>
           <mrow><msubsup><mo>&#x222b;</mo><mrow/><mrow/></msubsup><mo>d</mo></mrow>
        </content>
      </createButton>
      <createButton
        icon='https://media.scolab.com/Publisher/VariableEditor/MarkupEditor/MathIcons/SimpleIntegralWithDifferential.png'
        title='Define simple integral with differential'
        offset='1'
      >
        <content>
           <mrow><mo>&#x222b;</mo><mo>d</mo></mrow>
        </content>
      </createButton>
      <createButton
        icon='https://media.scolab.com/Publisher/VariableEditor/MarkupEditor/MathIcons/Derivative.png'
        title='Derivative'
        offset='2'
      >
        <content>
           <mfrac><mo>d</mo><mo>d</mo></mfrac>
        </content>
      </createButton>
      <createButton
        icon='https://media.scolab.com/Publisher/VariableEditor/MarkupEditor/MathIcons/PartialDerivative.png'
        title='Partial derivative'
        offset='2'
      >
        <content>
           <mfrac><mo>&#x2202;</mo><mo>&#x2202;</mo></mfrac>
        </content>
      </createButton>
      <createButton icon='https://media.scolab.com/Publisher/VariableEditor/MarkupEditor/MathIcons/Curl.png' title='Curl' offset='2'>
        <content>
           <mrow><mo>&#x2207;</mo><mo>×</mo></mrow>
        </content>
      </createButton>
      <createButton
        icon='https://media.scolab.com/Publisher/VariableEditor/MarkupEditor/MathIcons/Gradient.png'
        title='Gradient'
        offset='1'
      >
        <content>
           <mo>&#x2207;</mo>
        </content>
      </createButton>
      <createButton
        icon='https://media.scolab.com/Publisher/VariableEditor/MarkupEditor/MathIcons/Divergence.png'
        title='Divergence'
        offset='2'
      >
        <content>
           <mrow><mo>&#x2207;</mo><mo>&#x22c5;</mo></mrow>
        </content>
      </createButton>
      <item ref="limitUnder" />
      <item ref="productUnderover" />
      <item ref="productUnder" />
      <item ref="sumUnderover" />
      <item ref="sumUnder" />
    </section>
  </tab>
`;

const getTrigonometryTab = (intl: IntlShape) => `
  <tab name="${intl.formatMessage({ id: 'markupEditor.trigonometryTabTitle', defaultMessage: 'Trigonometry' })}">
    <section layout="horizontal" rows="1">
      <item ref="sinus" />
      <item ref="cosinus" />
      <item ref="tangent" />
      <item ref="arcsinus" />
      <item ref="arccosinus" />
      <item ref="arctangent" />
      <item ref="cosecant" />
      <item ref="secant" />
      <item ref="cotangent" />
    </section>
  </tab>
`;

const getSetsTab = (intl: IntlShape) => `
  <tab name="${intl.formatMessage({ id: 'markupEditor.setsTabTitle', defaultMessage: 'Sets' })}">
    <section layout="horizontal" rows="1">
      <item ref="&#8834;" />
      <item ref="&#8835;" />
      <createButton
        icon='https://media.scolab.com/Publisher/VariableEditor/MarkupEditor/MathIcons/NotSubsetOf.png'
        title='Not subset of'
        offset='1'
      >
        <content>
           <mo>&#x2284;</mo>
        </content>
      </createButton>
      <createButton
        icon='https://media.scolab.com/Publisher/VariableEditor/MarkupEditor/MathIcons/NotSupersetOf.png'
        title='Not superset of'
        offset='1'
      >
        <content>
           <mo>&#x2285;</mo>
        </content>
      </createButton>
      <item ref="&#8838;" />
      <item ref="&#8839;" />
      <createButton
        icon='https://media.scolab.com/Publisher/VariableEditor/MarkupEditor/MathIcons/NotSubsetOfOrEqualTo.png'
        title='Not subset of or equal to'
        offset='1'
      >
        <content>
           <mo>&#x2288;</mo>
        </content>
      </createButton>
      <createButton
        icon='https://media.scolab.com/Publisher/VariableEditor/MarkupEditor/MathIcons/NotSupersetOfOrEqualTo.png'
        title='Not superset of or equal to'
        offset='1'
      >
        <content>
           <mo>&#x2289;</mo>
        </content>
      </createButton>
      <item ref="&#8745;" />
      <item ref="&#8746;" />
      <item ref="&#8712;" />
      <item ref="&#8713;" />
      <item ref="&#8709;" />
      <item ref="\\" />
      <item ref="integers" />
      <item ref="reals" />
      <item ref="complexes" />
      <item ref="rationals" />
      <item ref="naturals" />
    </section>
  </tab>
`;

const getSymbolsTab = (intl: IntlShape) => `
  <tab name="${intl.formatMessage({ id: 'markupEditor.symbolsTabTitle', defaultMessage: 'Symbols' })}">
    <section layout="horizontal">
      <item ref="+" />
      <item ref="-" />
      <item ref="&#177;" />
      <item ref="&#247;" />
      <item ref="&#215;" />
      <item ref="&#183;" />
      <item ref="&#8704;" />
      <item ref="&#8707;" />
      <item ref="&#8743;" />
      <item ref="&#8744;" />
      <item ref="&#172;" />
      <item ref="&#176;" />
      <item ref="&#8592;" />
      <item ref="&#8594;" />
      <item ref="&#8596;" />
      <item ref="&#8593;" />
      <item ref="&#8595;" />
      <item ref="&#8597;" />
      <item ref="&#8598;" />
      <item ref="&#8599;" />
      <item ref="&#8601;" />
      <item ref="&#8600;" />
      <item ref="&#8660;" />
      <createButton
        icon='https://media.scolab.com/Publisher/VariableEditor/MarkupEditor/MathIcons/BottomLeftCorner.png'
        title='Bottom left corner'
        offset='1'
      >
        <content>
           <mo>&#x221f;</mo>
        </content>
      </createButton>
      <item ref="&#8736;" />
      <item ref="&#8852;" />
      <item ref="&#8734;" />
      <item ref="&#8741;" />
      <item ref="dollar" />
      <item ref="cent" />
      <item ref="pound" />
      <item ref="yen" />
      <item ref="euro" />
    </section>
  </tab>
`;

const getRelationTab = (intl: IntlShape) => `
  <tab name="${intl.formatMessage({ id: 'markupEditor.relationTabTitle', defaultMessage: 'Relation' })}">
    <section layout="horizontal" rows="1">
      <item ref="&lt;" />
      <item ref="&#8804;" />
      <item ref="&#8805;" />
      <item ref="&gt;" />
      <createButton
        icon='https://media.scolab.com/Publisher/VariableEditor/MarkupEditor/MathIcons/NotLessThan.png'
        title='Not less than'
        offset='1'
      >
        <content>
           <mo>&#x226e;</mo>
        </content>
      </createButton>
      <createButton
        icon='https://media.scolab.com/Publisher/VariableEditor/MarkupEditor/MathIcons/NotGreaterThan.png'
        title='Not greater than'
        offset='1'
      >
        <content>
           <mo>&#x226f;</mo>
        </content>
      </createButton>
      <item ref="=" />
      <item ref="&#8800;" />
      <item ref="&#8801;" />
      <item ref="&#8802;" />
      <item ref="&#8776;" />
      <item ref="&#8777;" />
      <item ref="&#8733;" />
    </section>
  </tab>
`;

const getGreekTab = (intl: IntlShape) => `
  <tab name="${intl.formatMessage({ id: 'markupEditor.greekTabTitle', defaultMessage: 'Greek' })}">
    <section layout="horizontal" rows="3">
      <item ref="&#945;" />
      <item ref="&#946;" />
      <item ref="&#947;" />
      <item ref="&#948;" />
      <item ref="&#949;" />
      <item ref="&#950;" />
      <item ref="&#951;" />
      <item ref="&#952;" />
      <item ref="&#977;" />
      <item ref="&#953;" />
      <item ref="&#954;" />
      <item ref="&#955;" />
      <item ref="&#956;" />
      <item ref="&#957;" />
      <item ref="&#958;" />
      <item ref="&#959;" />
      <item ref="&#960;" />
      <item ref="&#982;" />
      <item ref="&#961;" />
      <item ref="&#962;" />
      <item ref="&#963;" />
      <item ref="&#964;" />
      <item ref="&#965;" />
      <item ref="&#966;" />
      <item ref="&#981;" />
      <item ref="&#967;" />
      <item ref="&#968;" />
      <item ref="&#969;" />
    </section>
    <section layout="horizontal" rows="3">
      <item ref="&#913;" />
      <item ref="&#914;" />
      <item ref="&#915;" />
      <item ref="&#916;" />
      <item ref="&#917;" />
      <item ref="&#918;" />
      <item ref="&#919;" />
      <item ref="&#920;" />
      <item ref="&#921;" />
      <item ref="&#922;" />
      <item ref="&#923;" />
      <item ref="&#924;" />
      <item ref="&#925;" />
      <item ref="&#926;" />
      <item ref="&#927;" />
      <item ref="&#928;" />
      <item ref="&#929;" />
      <item ref="&#931;" />
      <item ref="&#932;" />
      <item ref="&#933;" />
      <item ref="&#934;" />
      <item ref="&#935;" />
      <item ref="&#936;" />
      <item ref="&#937;" />
    </section>
  </tab>
`;

/**
 * Missing from flash:
 * - bottom-right corner (enclose madruwb), MathType doesn't seem to visually support it
 */
const getEncloseTab = (intl: IntlShape) => `
  <tab name="${intl.formatMessage({ id: 'markupEditor.encloseTabTitle', defaultMessage: 'Enclose' })}">
    <section layout="horizontal">
      <item ref="encloseLongDivision" />
      <item ref="encloseActuarial" />
      <item ref="squareRoot" />
      <item ref="encloseLeft" />
      <item ref="encloseRight" />
      <item ref="encloseTop" />
      <item ref="encloseBottom" />
      <item ref="upDiagonalStrike" />
      <item ref="downDiagonalStrike" />
      <item ref="verticalStrike" />
      <item ref="horizontalStrike" />
      <item ref="encloseBox" />
      <item ref="encloseRoundedBox" />
      <item ref="encloseCircle" />
    </section>
  </tab>
`;

const getTextTab = (intl: IntlShape) => `
  <tab name="${intl.formatMessage({ id: 'markupEditor.textTabTitle', defaultMessage: 'Text' })}">
    <section layout="horizontal" rows="1">
      <item ref="alignLeft" />
      <item ref="alignCenter" />
      <item ref="alignRight" />
      <item ref="bold" />
      <item ref="italic" />
    </section>
  </tab>
`;

export const getToolbar = (intl: IntlShape) => `
  <toolbar removeLinks="true">
    <removeTab ref="contextual" />
    ${getLayoutTab(intl)}
    ${getTableTab(intl)}
    ${getCalculusTab(intl)}
    ${getTrigonometryTab(intl)}
    ${getSetsTab(intl)}
    ${getSymbolsTab(intl)}
    ${getRelationTab(intl)}
    ${getGreekTab(intl)}
    ${getEncloseTab(intl)}
    ${getTextTab(intl)}
  </toolbar>
`.trim();
