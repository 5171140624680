import { XGeom } from '../../../../core/XGeom';
import { ContentElement } from '../../../../elements/abstract/ContentElement';
import { FunctionElement } from '../../../../elements/abstract/FunctionElement';
import { WBoolean } from '../../../../elements/tokens/WBoolean';
import { WPolygon } from '../../../../elements/tokens/WPolygon';
import { ArgumentsObject } from '../../../../expr/ArgumentsObject';
import { IsTrapezoid } from '../../../../funcs/asserts/geom/quadrilaterals/IsTrapezoid';

/**
 * Est un trapèze rectangle?
 */
export class IsRectangleTrapezoid extends FunctionElement {

  public callReturnElement(args:ArgumentsObject):ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }

    if(args.getPolygon(0) ){
      return this.polygon(args.getPolygon(0));
    }
    return null;
  }

  /**
   *
   */
  private polygon(value:WPolygon):WBoolean{
    return WBoolean.parse(IsRectangleTrapezoid.check(value));

  }

  public static check(value:WPolygon):boolean{
    if(value.vertices.length !== 4){
      return false;
    }
    const a:number[] = XGeom.interiorAngles(value.vertices, false);

    return 	IsTrapezoid.check(value) &&
        (a[0] === 90 ||
        a[1] === 90 ||
        a[2] === 90 ||
        a[3] === 90);

  }

}
