import { ConstructorElement } from '../../elements/abstract/ConstructorElement';

/**
 * @() ... recall the current function or return a default value when stop condition is fulfilled.
 * @(stopCondition, defaultValue, ...arguments)
 *
 * For example, factorial (without error checking) could be implemented as λ(x, x * @(x < 2, 1, x-1))
 */
export class Recall extends ConstructorElement {
}
