import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WPolynomial } from '../../elements/tokens/WPolynomial';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Coéfficient.
 */
export class Coefficient extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 2){
      return args.expectingArguments(2, 2);
    }

    const zero:RealElement = args.env.culture.createNumber(0);

    if(args.getReal(0) && args.getReal(1)){
      return this.constant(args.getReal(0), args.getReal(1), zero);
    }
    if(args.getPolynomial(0) && args.getWholeNumber(1)){
      return this.exponent(args.getPolynomial(0), args.getWholeNumber(1), zero);
    }
    if(args.getPolynomial(0) && args.getPolynomial(1)){
      return this.exponents(args.getPolynomial(0), args.getPolynomial(1), zero);
    }

    return null;
  }

  /**
   *
   */
  private constant(value:RealElement, form:RealElement, zero:RealElement):RealElement{
    if(form.toNumber() === 0){
      return value;
    }
    return zero;
  }

  /**
   *
   */
  private exponent(
      polynomial:WPolynomial,
      form:RealElement,
      zero:RealElement):RealElement{

    if(form.toNumber() === 0){
      return polynomial.constant;
    }

    if(polynomial.symbols.length === 1){
      for(let m:number = 0 ; m < polynomial.numMonomials ; m++){
        if(polynomial.power(m, 0) === form.toNumber()){
          return polynomial.coefs[m];
        }
      }
    }

    return zero;
  }

  /**
   *
   */
  public exponents(
      polynomial:WPolynomial,
      form:WPolynomial,
      zero:RealElement):RealElement{

    if(form.numMonomials === 0){
      return null;
    }

    const sForm:string = this.term(form, 0);

    for(let m:number = 0 ; m < polynomial.numMonomials ; m++){
      const sMono:string = this.term(polynomial, m);
      if(sForm === sMono){
        return polynomial.coefs[m];
      }
    }

    return zero;
  }

  /**
   *
   */
  private term(
      polynomial:WPolynomial,
      monomial:number):string{

    const o:string[] = [];
    for(let s:number = 0 ; s < polynomial.symbols.length ; s++){
      const p:number = polynomial.power(monomial, s);
      if(p > 0){
        o.push(polynomial.symbols[s].getSymbol() + p);
      }
    }
    return o.join('');
  }

}
