import { IDictionary } from '../../../../js/utils/IDictionary';
import { RealElement } from '../../../elements/abstract/RealElement';
import { BaseQuadraticEquationFormatter } from '../../../elements/formats/BaseQuadraticEquationFormatter';
import { WQuadratic } from '../../../elements/tokens/WQuadratic';
import { MParam } from '../../../expr/conversion/models/MParam';
import { ParamTypes } from '../../../expr/conversion/models/ParamTypes';
import { CultureInfo } from '../../../localization/CultureInfo';

/**
 * Ax^2 + Bx + C
 */
export class StandardQuadraticFormatter extends BaseQuadraticEquationFormatter {

  constructor(culture:CultureInfo){
    super(culture);
  }

  public tokens(
      value:WQuadratic,
      display:boolean):any[]{

    const pA:MParam = new MParam(ParamTypes.REAL, 'A', value.A, this.culture.createNumber(1), this.culture.createNumber(-1));
    const pB:MParam = new MParam(ParamTypes.REAL, 'B', value.B, this.culture.createNumber(1), this.culture.createNumber(-1));
    const pC:MParam = new MParam(ParamTypes.REAL, 'C', value.C, this.culture.createNumber(0), null);

    if(display){
      const o:any[] = [];
      if(pA.value.toNumber() === -1){
        o.push('−');
      }else if(pA.value.toNumber() !== 1){
        o.push(pA);
      }
      o.push(value.xLabel, '²');
    }

    return [pA, value.xLabel, '²', '+', pB, value.xLabel, '+', pC];
  }

  public copy(
      value:WQuadratic,
      parameters:IDictionary):WQuadratic{
    const A:RealElement = parameters.hasOwnProperty('A') ? parameters.A : value.A;
    const B:RealElement = parameters.hasOwnProperty('B') ? parameters.B : value.B;
    const C:RealElement = parameters.hasOwnProperty('C') ? parameters.C : value.C;
    return new WQuadratic(A, B, C, this, null, null, null, value.yLabel, value.xLabel);
  }

}
