import { MConstruct } from '../../../expr/conversion/models/MConstruct';
import { NodeGroup } from '../../../elements/functions/tree/NodeGroup';
import { EmptyLeaf } from '../../../elements/functions/tree/EmptyLeaf';
import { AbstractNode } from '../../../elements/functions/tree/AbstractNode';

/**
 *
 */
export class NodeFunction extends AbstractNode {

  private _content:AbstractNode;

  private _functionName:string;

  constructor(functionName:string, content:AbstractNode ) {
    super();

    this._content = content;
    this._functionName = functionName;

    this._content.setParent( this );
  }

  public getToken():any[] {
    const group:MConstruct = new MConstruct(MConstruct.TYPE_FENCE, [this._content.getToken()]);
    return [ this._functionName, group ];
  }

  public simplify():AbstractNode {
    let nodeSimplified:AbstractNode = this._content.simplify();

    if( nodeSimplified instanceof EmptyLeaf ) {
      return nodeSimplified;
    }

    if( nodeSimplified instanceof NodeGroup ) {
      nodeSimplified = ( <NodeGroup>nodeSimplified  ).content;
    }

    return new NodeFunction( this._functionName, nodeSimplified );
  }
}
