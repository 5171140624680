import { Point } from '../../../../../js/geom/Point';
import { ContentElement } from '../../../../elements/abstract/ContentElement';
import { FunctionElement } from '../../../../elements/abstract/FunctionElement';
import { TPoints } from '../../../../elements/models/TPoints';
import { IGeomTransform } from '../../../../elements/models/transforms/IGeomTransform';
import { ReflectionImpl } from '../../../../elements/models/transforms/ReflectionImpl';
import { WBoolean } from '../../../../elements/tokens/WBoolean';
import { WLine } from '../../../../elements/tokens/WLine';
import { WPolygon } from '../../../../elements/tokens/WPolygon';
import { WSegment } from '../../../../elements/tokens/WSegment';
import { ArgumentsObject } from '../../../../expr/ArgumentsObject';
import { Environment } from '../../../../expr/Environment';

/**
 * Determine if a line or segment is a symmetry axis of the specified polygon.
 *
 * Vérifie si un segment est un axe de symétrie du polygone spécifié.
 * La longueur du segment n'est pas considérée.
 * Le segment doit simplement être au dessus d'un axe de symétrie.
 */
export class IsAxisOfSymmetry extends FunctionElement {

  /**
   *
   */
  private static PT_DIST_THRESHOLD:number = 0.0001;

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement {
    if (args.length !== 2) {
      return args.expectingArguments(2, 2);
    }

    const polygon:WPolygon = args.getPolygon(1);
    if(!polygon){
      return null;
    }

    const line:WLine = args.getLine(0);
    if(line){
      return WBoolean.parse(IsAxisOfSymmetry.check(polygon.vertices, TPoints.fromLine(line), args.env));
    }

    const segment:WSegment = args.getSegment(0);
    if(segment){
      return WBoolean.parse(IsAxisOfSymmetry.check(polygon.vertices, new TPoints(segment.a, segment.b), args.env));
    }

    return null;
  }

  /**
   *
   */
  public static check(
      poly:Point[],
      L:TPoints,
      env:Environment):boolean{

    const c:number = poly.length;
    if(c < 3){
      return false;
    }

    let a:Point[]; // points on one side that needs to be tested against the other side
    let b:Point[]; //

    // can't have more lines of symmetry than sides

    let i:number; //
    let j:number;
    let k:number;
    let l:number;
    let u:number;
    let v:number;

    for(i = 0 ; i < c ; i++){
      a = [];
      b = [];
      if(c % 2 === 0){
        j = Math.floor(i / 2);
        k = j + c / 2;
        if(i % 2 === 0){
          // CASE A point with point
          for(l = 1 ; l <= Math.ceil((c - 2) / 2) ; l++){
            u = j - l;
            if(u < 0){
              u += c;
            }
            v = j + l;
            if(v >= c){
              v -= c;
            }
            a.push(poly[u]);
            b.push(poly[v]);
          }
        }else{
          // CASE B mid-point with mid-point
          for(l = 0 ; l < c / 2 ; l++){
            u = j - l;
            if(u < 0){
              u += c;
            }
            v = j + 1 + l;
            if(v >= c){
              v -= c;
            }
            a.push(poly[u]);
            b.push(poly[v]);
          }
        }
      }else{
        // CASE C point with mid-point
        j = i + Math.floor(c / 2);
        if(j >= c){
          j -= c;
        }
        k = j + 1 === c ? 0 : j + 1;
        for(l = 0 ; l < Math.floor(c / 2) ; l++){
          a.push(poly[j - l < 0 ? j - l + c : j - l]);
          b.push(poly[k + l >= c ? k + l - c : k + l]);
        }
      }

      // Check that each point of b is a reflection of the corresponding point in a about the L axis
      let isReflection:boolean = true;
      const axis:WLine = WLine.parsePoints2(L.a, L.b, env.reals, env.culture.formats.lineFormatImpl);
      const transform:IGeomTransform = new ReflectionImpl(axis);
      for(l = 0 ; l < a.length ; l++){
        const z:Point = transform.transformPoint(a[l]);
        if(Point.distance(b[l], z) > IsAxisOfSymmetry.PT_DIST_THRESHOLD){
          isReflection = false;
          break;
        }
      }
      if(isReflection){
        return true;
      }
    }

    return false;
  }

}
