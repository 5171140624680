import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { CurrencyInfo } from '../../localization/CurrencyInfo';

/**
 * Pièces.
 */
export class Coins extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length > 1){
      return args.expectingArguments(0, 1);
    }

    let currency:CurrencyInfo = args.env.culture.currency;
    if(args.length > 0){
      if(args.getString(0)){
        currency = CurrencyInfo.parse(args.getString(0).getString());
      }else{
        return null;
      }
    }

    if(!currency){
      return null;
    }

    return args.env.culture.listFactory.createFromArray(currency.coins);
  }

}
