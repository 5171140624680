import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { WTable } from '../../elements/tokens/WTable';

/**
 * Retourne le nombre de colonnes d'une matrice.
 */
export class CountColumns extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 1){
      return args.expectingArguments(1, 1);
    }

    const matrix:WMatrix = args.getMatrix(0);
    if(matrix){
      return args.env.culture.createNumber(matrix.columns);
    }

    const table:WTable = args.getTable(0);
    if(table){
      return args.env.culture.createNumber(table.columns.length);
    }

    return null;
  }

}
