import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import styled from 'styled-components';
import { ITreeDataDescriptor } from './ITreeDataDescriptor';
import {TTreeDropAction, TreeContext, TTreeMoveAction} from './TreeContext';
import { TreeNode } from './TreeNode';
import { InsertButtonContainer } from './Tree.styles';

export interface ITreeProps {
  readonly showRootNode: boolean;
  readonly dataDescriptor: ITreeDataDescriptor<any>;
  readonly nodeRenderer: (node: any,
                          isOpen: boolean,
                          isSelected: boolean) => JSX.Element;

  // selection
  readonly selection: ReadonlyArray<string>;
  readonly onSelect?: (nodeId: string) => void;
  readonly onDoubleClick?: (nodeId: string) => void;

  // open/close nodes
  readonly onToggle?: (nodeId: string) => void;
  readonly opened: ReadonlyArray<string>;

  // drag and drop
  readonly dragEnabled?: boolean;
  readonly dropEnabled?: boolean;
  readonly onDropAction?: TTreeDropAction;
  readonly onMoveAction?: TTreeMoveAction;

  // hover
  readonly onMouseEnterNode?: (node: any) => void;
  readonly onMouseLeaveNode?: (node: any) => void;

  // insert nodes, one button per branch, insert as first child
  readonly insertNodeEnabled?: boolean;
  readonly insertButtonRenderer?: (parentNode: any, onClick: () => void) => JSX.Element;
  readonly onInsertChild?: (parentNode: any) => void;

  // checkbox
  readonly checkboxVisible?: boolean;
  readonly checked?: ReadonlyArray<string>;
  readonly onToggleChecked?: (nodeId: string) => void;

  // styles
  readonly className?: string;
  readonly indent?: number;
  readonly gap?: number;
  readonly verticalBars?: boolean;

  // tooltips
  readonly openTooltip?: React.ReactNode;
  readonly closeTooltip?: React.ReactNode;
}

export const TREE_ROOT_TEST_ID = 'TREE_ROOT_TEST_ID';

export const Tree: React.FC<ITreeProps> = (props) => {
  const {
    showRootNode,
    dataDescriptor,
    nodeRenderer,
    opened,
    selection,
    onSelect,
    onToggle,
    onDoubleClick,
    insertNodeEnabled,
    insertButtonRenderer,
    onInsertChild,
    checkboxVisible,
    checked,
    onToggleChecked,
    dragEnabled,
    dropEnabled,
    onDropAction,
    onMoveAction,
    className,
    indent = 24,
    gap = 16,
    verticalBars,
    onMouseEnterNode,
    onMouseLeaveNode,
    openTooltip = 'Open',
    closeTooltip = 'Close',
  } = props;
  if (!dataDescriptor) {
    return null;
  }
  let children = dataDescriptor.getChildren(null);
  let ancestors: ReadonlyArray<any> = [];
  let insertButton: JSX.Element = null;
  if (!showRootNode && children.length === 1) {
    const root = children[0];
    ancestors = [root];
    children = dataDescriptor.getChildren(root);
    insertButton =
      insertNodeEnabled
        ? (
          <InsertButtonContainer>
            {insertButtonRenderer(root, () => onInsertChild(root))}
          </InsertButtonContainer>
        )
        : null;
  }

  const Container =
    children.length === 0 || dataDescriptor.isHeader(children[0])
      ? CompactContainer
      : SpacedContainer;

  const nodes = children.map((child: any) => {
    const nodeId = dataDescriptor.getNodeId(child, ancestors);

    return (
      <TreeNode
        key={nodeId ?? uuidv4()}
        node={child}
        ancestors={ancestors}
        depth={0}
        indent={indent}
        gap={gap}
        verticalBars={verticalBars}
        openTooltip={openTooltip}
        closeTooltip={closeTooltip}
        onSelect={onSelect}
        onToggle={onToggle}
        onDoubleClick={onDoubleClick}
        onToggleChecked={onToggleChecked}
        onMouseEnterNode={onMouseEnterNode}
        onMouseLeaveNode={onMouseLeaveNode}
      />
    );
  });

  const isPartiallyChecked = (nodeId: string) => {
    return checked
      ? !checked.includes(nodeId) &&
      checked.some((otherNodeId) => {
        return otherNodeId.startsWith(nodeId);
      })
      : false;
  };

  return (
    <Container
      className={className}
      data-testid={TREE_ROOT_TEST_ID}
    >
      {insertButton}
      <TreeContext.Provider
        value={{
          dataDescriptor,
          nodeRenderer,
          insertNodeEnabled,
          insertButtonRenderer,
          onInsertChild,
          checkboxVisible,
          dragEnabled,
          dropEnabled,
          onDropAction,
          onMoveAction,
          isPartiallyChecked,
          isChecked: (nodeId: string) => nodeId && checked ? checked.includes(nodeId) : false,
          isOpen: (nodeId: string) => nodeId && opened ? opened.includes(nodeId) : false,
          isSelected: (nodeId: string) => nodeId && selection ? selection.includes(nodeId) : false,
        }}
      >
        {nodes}
      </TreeContext.Provider>
    </Container>
  );
};

const SpacedContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const CompactContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0px;
`;
