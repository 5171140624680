import { ContentElement } from '../../../../elements/abstract/ContentElement';
import { FunctionElement } from '../../../../elements/abstract/FunctionElement';
import { WBoolean } from '../../../../elements/tokens/WBoolean';
import { WLine } from '../../../../elements/tokens/WLine';
import { WPoint } from '../../../../elements/tokens/WPoint';
import { WSegment } from '../../../../elements/tokens/WSegment';
import { ArgumentsObject } from '../../../../expr/ArgumentsObject';

/**
 * Test de verticalité.
 */
export class IsVertical extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement {
    if (args.length < 1 || args.length > 2) {
      return args.expectingArguments(1, 2);
    }

    if(args.length === 1){
      if(args.getSegment(0) ){
        return this.segment(args.getSegment(0));
      }
      if(args.getLine(0) ){
        return this.line(args.getLine(0));
      }
    }else if(args.length === 2){
      if(args.getPoint(0) && args.getPoint(1)){
        return this.points(args.getPoint(0), args.getPoint(1));
      }
    }

    return null;
  }

  /**
   * Détermine si la droite passant par les deux points est verticale.
   */
  private points(a:WPoint, b:WPoint):WBoolean{
    return WBoolean.parse(a.equalsTo(b) ? false : a.x.toNumber() === b.x.toNumber());
  }

  /**
   *
   */
  private segment(value:WSegment):WBoolean{
    return WBoolean.parse(value.a.x === value.b.x);
  }

  /**
   *
   */
  private line(value:WLine):WBoolean{
    return WBoolean.parse(value.slope == null);
  }

}
