import { MathError } from '../../core/MathError';
import { AnonymousFunction } from '../../elements/abstract/AnonymousFunction';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { ListElement } from '../../elements/abstract/ListElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Evaluate } from '../../expr/manipulation/Evaluate';

/**
 * Map elements of a list by groups of n-elements.
 *
 * Example:
 * For list (0, 1, 2), Map-2 will map (0, 1) and then (1, 2).
 * The resulting list will have two elements.
 *
 * The items of the resulting list doesn't have to be of
 * the same type as the input list, but all items in the
 * resulting list must be of the same type.
 */
export class MapN extends FunctionElement {

  /**
   * If the anonymous function has one parameter,
   * the argument is the list of combined elements,
   * even for Map-1.
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 3){
      return args.expectingArguments(3, 3);
    }

    const list:ListElement = args.getList(0);
    const groupSize:RealElement = args.getReal(1);
    const mapFunction:AnonymousFunction = args.getAnonymousFunction(2);

    if(!list || !groupSize || !mapFunction){
      return null;
    }

    if(list.count === 0){
      return list;
    }

    const n:number = groupSize.toNumber();

    if(!groupSize.isNaturalNumber()){
      throw new MathError(`Map-${n} is not valid`);
    }

    if(n > list.count){
      throw new MathError(`Map-${n} must be lower or equal to the list length.`);
    }

    const items:ContentElement[] = [];
    const evaluator:Evaluate = new Evaluate(mapFunction, args.env);

    if(		evaluator.getArgumentsCount() !== 1 &&
        evaluator.getArgumentsCount() !== n){

      throw new MathError('');
    }

    for(let k:number = 0 ; k < list.count - (n - 1) ; k++){
      const o:ContentElement[] = [];
      for(let j:number = 0 ; j < n ; j++){
        o.push(list.getItemAt(k + j));
      }

      if(evaluator.getArgumentsCount() === 1){
        o.splice(0, o.length, args.env.culture.listFactory.createList(o));
      }

      const item:ContentElement = evaluator.evaluateN(o);
      if(!item){
        return null;
      }

      items.push(item);
    }

    return args.env.culture.listFactory.createList(items);
  }

}
