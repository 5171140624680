import { MathError } from '../../core/MathError';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Colle une matrice en dessous d'une matrice existante,
 * si les deux matrices ont le même nombre de colonnes.
 */
export class ConcatV extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 2){
      return args.expectingArguments(2, 2);
    }

    if (args.getMatrix(0) && args.getMatrix(1)) {
      return this.impl(args.getMatrix(0), args.getMatrix(1));
    }
    return null;
  }

  /**
   *
   */
  private impl(
      a:WMatrix,
      b:WMatrix):WMatrix{

    if(a.columns !==b.columns){
      throw new MathError('Matrices have different number of columns.');
    }

    return new WMatrix(
      a.values.concat(b.values),
      a.columns,
      a.formatter);
  }

}
