import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WTimeSpan } from '../../elements/tokens/WTimeSpan';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class Hours extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 1){
      return args.expectingArguments(1, 1);
    }

    if (args.getReal(0)) {
      return this.impl(args.getReal(0));
    }
    return null;
  }

  /**
   *
   */
  private impl(value:RealElement):WTimeSpan{
    if(!value.isWholeNumber()){
      return null;
    }
    return new WTimeSpan(value.toNumber() * 3600000);
  }

}
