import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { ListElement } from '../../elements/abstract/ListElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Nombre d'éléments d'une liste.
 */
export class Count extends FunctionElement {
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length === 0) {
      return args.expectingArguments(1, Number.POSITIVE_INFINITY);
    }

    if (args.length === 1) {
      const list = args.getList(0);
      if (list) {
        return args.env.culture.createNumber(list.count);
      }
    }

    if (args.length === 2) {
      const [strings, string] = [args.getStrings(0), args.getString(1)];
      if (strings && string) {
        return args.env.culture.createNumber(this.countInList(strings, string));
      }

      const [matrix, matrixReal] = [args.getMatrix(0), args.getReal(1)];
      if (matrix && matrixReal) {
        return args.env.culture.createNumber(this.countInReals(matrix.values, matrixReal));
      }

      const [points, point] = [args.getPoints(0), args.getPoint(1)];
      if (points && point) {
        return args.env.culture.createNumber(this.countInList(points, point));
      }

      const [reals, real] = [args.getReals(0), args.getReal(1)];
      if (reals && real) {
        return args.env.culture.createNumber(this.countInReals(reals.toReals(), real));
      }
    }

    const listOfList = args.toListOfList();
    if (listOfList) {
      return args.env.culture.createNumber(listOfList.count);
    }

    return null;
  }

  /**
   * Count how many times value appears in list.
   */
  private countInReals(list: RealElement[], value: RealElement): number {
    let count = 0;
    for (const item of list) {
      if (item.toNumber() === value.toNumber()) {
        count++;
      }
    }
    return count;
  }

  /**
   * Count how many times value appears in list.
   * Compte le nombre de fois que <i>value</i> se retrouve dans <i>list</i>.
   */
  private countInList(list: ListElement, value: ContentElement): number {
    let count = 0;
    for (let i = 0; i < list.count; i++) {
      if (list.getItemAt(i).equalsTo(value)) {
        count++;
      }
    }
    return count;
  }
}
