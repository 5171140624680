import { WHalfPlane } from '../../elements/tokens/WHalfPlane';
import { WLine } from '../../elements/tokens/WLine';

/**
 *
 */
export class LineParserResult {

  private line:WLine;

  private operator:string;

  /**
   *
   */
  constructor(
      line:WLine,
      operator:string){
    this.line = line;
    this.operator = operator;
  }

  /**
   *
   */
  public toLine():WLine{
    return this.operator === '=' ? this.line : null;
  }

  /**
   *
   */
  public toHalfPlane():WHalfPlane{
    if(!this.line){
      return null;
    }

    return 	this.operator === '<' ||
        this.operator === '>' ||
        this.operator === '≤' ||
        this.operator === '≥' ?
      new WHalfPlane(this.line, this.operator) :
      null;
  }

}
