/**
 *
 */
export class HLine {

  public visible:boolean = true;

  /**
   * Row under which we want the line to be drawn.
   */
  public row:number = 0;

}
