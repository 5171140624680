import { XMath } from '../core/XMath';
import { ContentElement } from '../elements/abstract/ContentElement';
import { Node } from '../elements/abstract/Node';
import { RealElement } from '../elements/abstract/RealElement';
import { FractionFormatter } from '../elements/formats/rationals/FractionFormatter';
import { FractionModel } from '../elements/models/FractionModel';
import { WRational } from '../elements/tokens/WRational';
import { IWriter } from '../expr/conversion/writers/IWriter';
import { InputCapabilities } from './InputCapabilities';
import { KeyboardConfiguration } from './KeyboardConfiguration';
import { CommonError } from './CommonError';
import { BaseCorrector } from './BaseCorrector';
import { BaseRationalFormatter } from '../elements/formats/BaseRationalFormatter';

/**
 *
 */
export class CFraction extends BaseCorrector {

  public parse(value:string):Node{
    const fraction:FractionModel = FractionModel.tryParse(this.sanitizeInput(value), this.useLatex);
    if(fraction){
      const r:WRational = this.parseRationalModel(fraction);
      const node:Node = new Node(r);
      node.userData = r.userData;
      return node;
    }
    return null;
  }

  public correct(
      value:string,
      target:ContentElement,
      ...targets:any[]):boolean{

    /*
    Input will return values in this format
    value : '1/3'
    value : '3+1/3'
    value : '3+341/334'
    value : '341/334'
    value : '34+1/334'
    value : '?34+1/334'
    */

    // The input enforce a correct format, it is virtually
    // impossible for the user to enter an invalid input
    // in the fraction mode.
    const fraction:FractionModel = FractionModel.tryParse(this.sanitizeInput(value), this.useLatex);

    // Si la bonne réponse n'est pas une fraction
    const r:WRational = CFraction.coerceTarget(target, this.env.culture.formats.rationalFormatImpl);
    if(!r){
      return false;
    }

    if(fraction){ // Est-ce que la réponse est une fraction valide? L'input utilisateur devrait renforcer l'entrée d'une fraction valide.

      // are fractions equivalent?
      if(XMath.safeEquals(fraction.toNumber(), r.toNumber())){ // L'élève a rentré la bonne valeur, voyons maintenant si les options de correction sont respectées

        // Si je rentre une fraction équivalente, je ne devrais jamais avoir d'erreur, seulement des gentle reminders

        if(this.options.expectExact){
          if(r.formatter instanceof FractionFormatter){
            const t:FractionModel = (<FractionFormatter>r.formatter ).fraction(r);
            if(t){
              if(	fraction.integer !== t.integer ||
                fraction.numerator !== t.numerator ||
                fraction.denominator !== t.denominator){

                this.raiseError(CommonError.RATIONAL_DIFF_FRACTION);
              }
            }
          }
        }

        if(this.options.expectReduced){
          if(XMath.gcd(fraction.numerator, fraction.denominator) !== 1 ||
            (fraction.integer > 0 && fraction.numerator > fraction.denominator)){ // 1 3/2 --> write it as 2 1/2 or 5/2, not 1 3/2

            this.raiseError(CommonError.RATIONAL_NOT_SIMPLIFIED);
          }
        }

        if(this.options.expectImproper){
          if(fraction.integer > 0){
            this.raiseError(CommonError.RATIONAL_NOT_IMPROPER);
          }
        }

        if(this.options.expectMixed){
          if(fraction.numerator >= fraction.denominator){
            this.raiseError(CommonError.RATIONAL_NOT_MIXED);
          }
        }

        if(this.options.expectSameDenominator){
          if(fraction.denominator !== r.denominator){
            this.raiseError(CommonError.RATIONAL_DIFF_DENOMINATOR, [r.denominator]);
          }
        }

        if(this.options.expectSameDenominator){
          if(fraction.numerator !== r.numerator){
            this.raiseError(CommonError.RATIONAL_DIFF_NUMERATOR, [r.numerator]);
          }
        }

        return true;
      }
    }else{
      this.raiseError(CommonError.RATIONAL_FORMAT_INVALID);
    }

    return false;
  }

  public writeTo(
      w:IWriter,
      target:ContentElement,
      ...targets:any[]):void{

    if(target instanceof WRational){
      const frac:WRational = <WRational>target ;
      const negative:boolean = frac.toNumber() < 0;
      let e:number = NaN;
      let n:number = Math.abs(frac.numerator);
      let d:number = Math.abs(frac.denominator);

      if(this.options.expectMixed){
        e = Math.floor(Math.abs(frac.toNumber()));
        if(e === 0){
          e = NaN;
        }
        n %= d;
      }

      if(this.options.expectReduced){
        const k:number = XMath.gcd(n, d);
        if(k !== 1){
          n /= k;
          d /= k;
        }
      }

      if(isNaN(e)){
        w.beginFraction();
        w.writeNumber(n * (negative ? -1 : 1));
        w.startParagraph();
        w.beginDenominator();
        w.writeNumber(d);
        w.endFraction();
      }else{
        w.writeNumber(e * (negative ? -1 : 1));
        if(!this.useLatex){
          w.writeInfixOperator('+');
        }
        w.beginFraction();
        w.writeNumber(n);
        w.startParagraph();
        w.beginDenominator();
        w.writeNumber(d);
        w.endFraction();
      }

    }else{
      w.writeNumber((<RealElement>target ).toNumber());
    }
  }

  public get mathKeyboard():number{
    return KeyboardConfiguration.FRACTION;
  }

  public get inputCapabilities():InputCapabilities{
    const i:InputCapabilities = super.inputWithSymbols(this.useLatex ? '/' : '+/');
    i.fraction = true;
    return i;
  }

  /**
   *
   */
  public static coerceTarget(target:ContentElement, format: BaseRationalFormatter):WRational{
    if(target instanceof WRational){
      return <WRational>target ;
    }

    const n:number = RealElement.parseDecimal(target);
    if(!isNaN(n)){
      if(XMath.isInteger(n)){
        return new WRational(n.valueOf(), 1, format);
      }
    }

    return null;
  }

}
