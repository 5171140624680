import styled from 'styled-components';

export const InsertButtonContainer = styled.div`
  width: 100%;
  height: 0;
  overflow: visible;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  transform: translateY(-14px);
  position: relative;
  z-index: 1;
`;

export const CheckboxContainer = styled.div`
  width: 0;
  height: 0;
  overflow: visible;
  transform: translateY(8px);
`;
