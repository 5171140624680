import { ContentElement } from '../../elements/abstract/ContentElement';
import { ListElement } from '../../elements/abstract/ListElement';
import { BaseListFormatter } from '../../elements/formats/BaseListFormatter';
import { StringSources, WString } from '../../elements/tokens/WString';

export class WListOfString extends ListElement {
  constructor(strings: ContentElement[], formatter: BaseListFormatter) {
    super(strings, formatter);
  }

  public useUnambiguousItemSeparator(): boolean {
    return true;
  }

  public getTypedItemAt(index: number): WString {
    return this.getItemAt(index) as WString;
  }

  public getValueAt(index: number): string {
    return this.getTypedItemAt(index).getString();
  }

  public toStrings(): string[] {
    const items: string[] = [];
    for (let i = 0; i < this.count; i++) {
      items.push(this.getValueAt(i));
    }
    return items;
  }

  public getSource(): StringSources {
    return this.items.some((s: WString) => s.getSource() === 'input') ? 'input' : 'unknown';
  }

  public acceptElement(element: ContentElement): boolean {
    return element.getListItemCode() === WString.getListItemCode();
  }

  protected createList(items: ContentElement[], formatter: BaseListFormatter): ListElement {
    return new WListOfString(items, formatter);
  }

  public toString(): string {
    return this.toStrings().join(', ');
  }

  public equalsTo(value: ContentElement): boolean {
    if (value instanceof WListOfString) {
      return this.unorderedEqualsTo(value);
    }
    return false;
  }

  public strictlyEqualsTo(value: ContentElement): boolean {
    if (value instanceof WListOfString) {
      return this.strictlyEqualsToImpl(value);
    }
    return false;
  }

  protected compareElements(a: ContentElement, b: ContentElement): number {
    return WString.compare(a as WString, b as WString);
  }

  public getType(): string {
    return 'strings';
  }
}
