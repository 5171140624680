import { Point } from '../../../js/geom/Point';

import { MathError } from '../../core/MathError';
import { AnonymousFunction } from '../../elements/abstract/AnonymousFunction';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { LambdaAdapter } from '../../elements/functions/adapters/LambdaAdapter';
import { PolylineAdapter } from '../../elements/functions/adapters/PolylineAdapter';
import { WFunctionGraph } from '../../elements/tokens/WFunctionGraph';
import { WListOfPoints } from '../../elements/tokens/WListOfPoints';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';
import { PlotLine } from '../../funcs/plotting/PlotLine';

/**
 *
 */
export class PlotPeriodic extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 1){
      return args.expectingArguments(1, 1);
    }
    if(args.getPoints(0)){
      return this.points(args.getPoints(0), args.env);
    }
    if(args.getAnonymousFunction(0)){
      return this.lambda(args.getAnonymousFunction(0), args.env);
    }
    return null;
  }

  /**
   * Crée une ligne brisée à partir de points. Les points doivent être ordonnés.
   * Le tracé qui relie chaque point doit être une fonction.
   * L'ordonnée du premier et du dernier point doit être la même.
   */
  private points(value:WListOfPoints, env:Environment):WFunctionGraph{
    const o:Point[] = value.toPoints();
    if(!PlotLine.validatePolylineFunction(o)){
      throw new MathError('Not a function');
    }

    if(o[0].y !== o[o.length - 1].y){
      throw new MathError('First and last mismatch');
    }

    return new WFunctionGraph(new PolylineAdapter(o, true, env.culture.intervalsFactory), null, null, null);
  }

  /**
   *
   */
  private lambda(value:AnonymousFunction, env:Environment):WFunctionGraph{
    if(value.range !== AnonymousFunction.REAL_NUMBERS){
      throw new MathError('Invalid');
    }

    return new WFunctionGraph(new LambdaAdapter(value, env, true), null, null, null);
  }

}
