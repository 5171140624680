import { Delegate } from '../../js/utils/Delegate';
import { XString } from '../core/XString';
import { ExpressionStats } from './utils/ExpressionStats';
import { ContentElement } from '../elements/abstract/ContentElement';
import { FunctionElement } from '../elements/abstract/FunctionElement';
import { Node } from '../elements/abstract/Node';
import { RealElement } from '../elements/abstract/RealElement';
import { TokenElement } from '../elements/abstract/TokenElement';
import { RestrictExpression } from '../elements/models/RestrictExpression';
import { WExpression } from '../elements/tokens/WExpression';
import { WPolynomial } from '../elements/tokens/WPolynomial';
import { IWriter } from '../expr/conversion/writers/IWriter';
import { Divide } from '../funcs/arithmetic/Divide';
import { Minus } from '../funcs/arithmetic/Minus';
import { Plus } from '../funcs/arithmetic/Plus';
import { Power } from '../funcs/arithmetic/Power';
import { Times } from '../funcs/arithmetic/Times';
import { InputCapabilities } from './InputCapabilities';
import { KeyboardConfiguration } from './KeyboardConfiguration';
import { CommonError } from './CommonError';
import { BaseCorrector } from './BaseCorrector';

/**
 *
 */
export class CExpression extends BaseCorrector {

  private restrict:RestrictExpression;

  /**
   *
   */
  constructor(restrict:RestrictExpression){
    super();
    this.restrict = restrict;
  }

  public parse(value:string):Node{
    const expr:WExpression = this.env.createExpression(this.translateInput(value));
    if(expr){
      const node:Node = new Node(expr);
      node.userData = 'BoxExpression("' + expr.rawExpression + '")';
      return node;
    }
    return null;
  }

  public correct(
      valueArg:string,
      target:ContentElement,
      ...targets:any[]):boolean{

    let value = valueArg;
    let i:number;

    const expr:WExpression = <WExpression>target ;

    // Check decimal separators up front
    this.checkDecimalSeparator(value);

    // Before converting to expression we must use the internal decimal separator
    value = this.translateInput(value);

    const value2:any[] = this.splitExpression(value);
    const target2:any[] = this.splitExpression(expr.rawExpression);

    if(!value2 || !target2){
      return false;
    }

    const normalized:string = this.normalizeSuperscript(value2[0]);
    const tn:number = this.env.expressions.toNumber(target2[0]);
    const r:RestrictExpression = expr.restrict;

    if(!isNaN(tn)){ // Numeric expression

      // Check that user input produce a number
      const n:number = this.env.expressions.toNumber(normalized, null, -1, true);

      if(isNaN(n)){
        this.raiseError(CommonError.NOT_A_NUM_EXPR);
      }

      if(!isNaN(value2[1])){
        // User wrote 10+3 = 14
        if(n !== value2[1]){
          return false;
        }
      }

      if(r == null){
        // 1. Compatibility correction
        // a. Check that both expressions are identical except for the parenthesis
        // b. Check that both expression produce the same value
        return this.normalizedExpression(normalized) === this.normalizedExpression(target2[0]) && n === tn;
      }

      let defined:boolean;
      let funcs:FunctionElement[];

      if(r.results){
        for(i = 0 ; i < r.results.length ; i++){
          const result:TokenElement = r.results[i];
          if(	(n !== RealElement.parseDecimal(result) && r.resultsPolicy === 'allow') ||
            (n === RealElement.parseDecimal(result) && r.resultsPolicy === 'deny')){
            return false;
          }
        }
      }

      if(r.operators){
        funcs = ExpressionStats.binayOperators(normalized, this.env);

        const symbols:string[] =
          r.operators.map(this.translateSymbols, this);

        for(i = 0 ; i < funcs.length ; i++){
          const func:FunctionElement = funcs[i];
          const symbol:string = this.functionSymbol(func);
          if(symbol == null){
            continue;
          }

          defined = symbols.indexOf(symbol) !== -1;

          if((!defined && r.operatorsPolicy === 'allow') ||
            (defined && r.operatorsPolicy === 'deny')){

            this.raiseError(CommonError.EXPR_RESTRICTED_OP, [this.operationLabel(this.operation(symbol))]);
          }
        }

        // Check parenthesis independently
        if(		value.indexOf('(') !== -1 ||
            value.indexOf(')') !== -1){

          // User make use of parenthesis

          // Check if the author specified the parenthesis as operators
          defined =
            r.operators.indexOf('(') !== -1 ||
            r.operators.indexOf(')') !== -1;

          if((!defined && r.operatorsPolicy === 'allow') ||
            (defined && r.operatorsPolicy === 'deny')){

            this.raiseError(CommonError.EXPR_RESTRICTED_OP, [this.operationLabel('par')]);
          }
        }
      }

      if(r.operations){
        funcs = ExpressionStats.binayOperators(normalized, this.env);
        let opErrorId:number = -1;
        let opCount:number = 0;
        if(r.operations.x === r.operations.y && funcs.length !== r.operations.x){
          opErrorId = CommonError.EXPR_DIFF_OPERATIONS;
          opCount = r.operations.x;
        }else if(funcs.length < r.operations.x){
          opErrorId = CommonError.EXPR_LOW_OPERATIONS;
          opCount = r.operations.x;
        }else if(funcs.length > r.operations.y){
          opErrorId = CommonError.EXPR_HIGH_OPERATIONS;
          opCount = r.operations.y;
        }

        if(opErrorId !== -1){
          this.raiseError(opErrorId, [opCount, opCount > 1 ? 's' : '']);
        }
      }

      if(r.terms){
        this.checkNumericTerms(r, normalized);
      }

      return true;
    }
    // Check for polynomial expression
    const tp:WPolynomial = this.env.expressions.toPolynomial(target2[0]);
    if(tp){
      const p:WPolynomial = this.env.expressions.toPolynomial(normalized);
      if(p){
        if(p.equalsTo(tp)){

          // Check restrictions
          if(r.terms){
            this.checkNumericTerms(r, normalized);
          }

          // If every check is ok then we got a good expression
          return true;

        }
      }
      return false;
    }
    // Could not correct that expression
    return false;
  }

  /**
   *
   */
  private checkNumericTerms(
      r:RestrictExpression,
      value:string):void{
    const terms:RealElement[] = ExpressionStats.numericTerms(value, this.env);
    const pool:TokenElement[] = r.terms.concat();
    for(let i:number = 0 ; i < terms.length ; i++){
      let validTerm:boolean = r.termsPolicy === 'deny'; // assume term is fine when set to deny
      let j:number = 0;
      while(j < pool.length){
        const a:number = RealElement.parseDecimal(pool[j]);
        const b:number = terms[i].toNumber();
        if(a === b){
          if(r.termsPolicy === 'allow'){
            if(!r.termsReuseEnabled){
              pool.splice(j, 1);
            }
            validTerm = true;
            break;
          }else if(r.termsPolicy === 'deny'){
            validTerm = false;
            break;
          }
        }
        j++;
      }
      if(!validTerm){
        this.raiseError(
          CommonError.EXPR_RESTRICTED_TERM,
          [this.env.culture.formatNumber(terms[i].toNumber())]);
      }
    }
  }

  /**
   * Convert user input into a valid string
   * representation for WExpression.
   */
  private translateInput(valueArg:string):string{
    let value = valueArg;
    if(!value){
      return value;
    }

    if(this.useLatex){
      value = this.sanitizeInput(value);
      value = value.replace(/\^\{\}/g, '');
      value = value.replace(/\^\{([^\}]+)\}/g, '<sup>$1</sup>');
      value = value.replace(/\^(.)/g, '<sup>$1</sup>');
    }

    return value.split(this.env.culture.numberFormatter.decimalSeparator).join('.');
  }

  /**
   * Returns the expression side of an equality if = is present, or the expression itself
   * 0: the expression side
   * 1: the result side, or NaN if no equal sign.
   */
  private splitExpression(valueArg:string):any[]{
    const value = valueArg.split(' ').join('');
    if(value.indexOf('=') === -1){
      return [value, Number.NaN];
    }
    const o:any[] = value.split('=');
    if(o.length !== 2){
      return null; // multiple '=' sign
    }
    const n0:number = this.numberParser.parseNumber(o[0]);
    const n1:number = this.numberParser.parseNumber(o[1]);
    if(!isNaN(n0) && isNaN(n1)){
      return [o[1], n0];
    }
    if(!isNaN(n1) && isNaN(n0)){
      return [o[0], n1];
    }
    // two numbers or two expressions
    return null;
  }

  private functionSymbol(func:FunctionElement):string{
    if(func instanceof Times){
      return '*';
    }
    if(func instanceof Plus){
      return '+';
    }
    if(func instanceof Divide){
      return '/';
    }
    if(func instanceof Minus){
      return '-';
    }
    if(func instanceof Power){
      return '^';
    }
    return null;
  }

  private translateSymbols(symbol:string, ..._:any[]):string{
    switch(symbol){
      case '(':
      case ')':
      case '+':
      case '^':
        return symbol;
      case '-':
      case '−':
        return '-';
      case '/':
      case '÷':
        return '/';
      case '*':
      case '×':
      case '⋅':
        return '*';
    }
    return null;
  }

  private operationLabel(op:string):string{
    return this.env.culture.getString(`Operations.${op}`);
  }

  private operation(op:string):string{
    if(op === '(' || op === ')'){
      return 'par';
    }
    if(op === '-' || op === '−'){
      return 'sub';
    }
    if(op === '/' || op === '÷'){
      return 'div';
    }
    if(op === '*' || op === '×' || op === '⋅'){
      return 'mult';
    }
    if(op === '+'){
      return 'add';
    }
    if(op === '^'){
      return 'exp';
    }
    return null;
  }

  public writeTo(
      w:IWriter,
      target:ContentElement,
      ...targets:any[]):void{

    const expr:WExpression = <WExpression>target ;
    w.writeRaw(expr.rawExpression.split('.').join(this.env.culture.numberFormatter.decimalSeparator));
  }

  private normalizedExpression(value:string):string{
    return this.withoutParenthesis(this.normalizeMinusSign(value));
  }

  public get mathKeyboard():number{
    return this.useElementaryKeyboard12 ?
      KeyboardConfiguration.ELEMENTARY12 :
      KeyboardConfiguration.EXPRESSION;
  }

  public get inputCapabilities():InputCapabilities{
    let i:InputCapabilities;
    if(this.restrict){
      if(this.restrict.operators){
        const o:string = this.restrict.operators.join('');
        i = super.inputWithSymbols(o);
        i.superscript = o.indexOf('^') !== -1;
        return i;
      }
    }

    i = super.inputWithSymbols('×÷+−=()');
    i.superscript = !this.env.culture.configuration.expressionsKeyboardWithoutExponent;
    return i;
  }

  /**
   * Digits: 0-9
   * Symbols: <>-+=
   */
  public get useElementaryKeyboard12(): boolean {
    if(!this.env.culture.configuration.useElementaryKeyboard12){
      return false;
    }
    if(this.origin instanceof WExpression){
      const s:String = this.origin.rawExpression;
      return s.split('').every((c: string) => {
        return XString.isSpaceLike(c) || ('<>+-−=0123456789').indexOf(c) !== -1;
      });
    }
    return false;
  }

  private withoutParenthesis(value:string):string{
    return value.split('(').join('').split(')').join('');
  }

  private normalizeMinusSign(value:string):string{
    return value.split('-').join('−'); // --> U+2212
  }

  private normalizeSuperscript(valueArg:string):string{
    // 12<sup>23</sup>+12<sup>2</sup> --> 12^23+12^2
    // 12<sup>1+2</sup>+12 --> 12^(1+2)+12

    const sup:string = '<sup>';
    const sup2:string = '</sup>';

    let value = valueArg.split(sup).join('\r<sup>');
    value = value.split(sup2).join('</sup>\r');

    value = value.split('\r').map(
        Delegate.partial(this.fn1,sup,sup2), this
      ).join('');

    return value;
  }

  private fn1(sup:string,sup2:string,a:string, ..._:any[]):string{
    if(XString.startsWith(a, sup) && XString.endsWith(a, sup2)){
      const c:string = a.substring(sup.length, a.length - sup2.length);
      if(!isNaN(Number(c))){
        return '^' + c;
      }
      return '^(' + c + ')';
    }
    return a;
  }

}
