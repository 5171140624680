import React from 'react';
import styled from 'styled-components';
import { ITheme } from '../../themes/ITheme';
import { ReadonlyContext } from '../../contexts/ReadonlyContext';

export interface ITopLabeledSelect extends React.SelectHTMLAttributes<HTMLSelectElement> {
  readonly label: React.ReactNode;
  readonly className?: string;
  readonly onBlur?: (event: React.FocusEvent<HTMLSelectElement>) => void;
  readonly onFocus?: (event: React.FocusEvent<HTMLSelectElement>) => void;
  readonly onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

export const TopLabeledSelect: React.FC<ITopLabeledSelect> = ({
  label,
  value,
  className,
  disabled,
  onBlur,
  onFocus,
  onChange,
  children,
  ...otherProps
}) => {
  const [hasFocus, setHasFocus] = React.useState(false);
  const isReadonly = React.useContext(ReadonlyContext);
  const isDisabled = disabled || isReadonly;
  const localOnFocus = React.useCallback((event: React.FocusEvent<HTMLSelectElement>) => {
    setHasFocus(true);
    if (onFocus) {
      onFocus(event);
    }
  },                                     [onFocus]);
  const localOnBlur = React.useCallback((event: React.FocusEvent<HTMLSelectElement>) => {
    setHasFocus(false);
    if (onBlur) {
      onBlur(event);
    }
  },                                    [onBlur]);
  return (
    <Container
      className={className}
      hasFocus={hasFocus}
    >
      <Label>
        {label}
      </Label>
      <Arrow
        hasFocus={hasFocus}
      />
      <div>
        <Select
          value={value}
          onBlur={localOnBlur}
          onFocus={localOnFocus}
          onChange={onChange}
          disabled={isDisabled}
          {...otherProps}
        >
          {children}
        </Select>
      </div>
    </Container>
  );
};

export const TopLabeledSelectItem = styled.option`
  background-color: ${(props) => props.theme.colorset.grey04};
  color: ${(props) => props.theme.colorset.grey11};
  padding: 8px;
  outline:none;
`;

interface IFocus {
  hasFocus: boolean;
}

const getBorderColor = (props: IFocus & { theme: ITheme }) => {
  if (props.hasFocus) {
    return props.theme.colorset.grey12;
  }
  return props.theme.colorset.grey09;
};

const Arrow = styled.div<IFocus>`
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 12px solid ${getBorderColor};
  pointer-events: none;
  transition: border-color 100ms linear;
`;

const Container = styled.div<IFocus>`
  display: inline-flex;
  position: relative;
  flex-direction: column;
  vertical-align: top;
  transition: border-color 100ms linear;
  border-radius: 4px 4px 0px 0px;
  border-bottom: 1px solid ${getBorderColor};
  background-color: ${(props) => props.theme.colorset.grey04};
  transition: border-color 100ms linear;

  &:hover {
    border-color: ${(props) => props.theme.colorset.grey12};

    ${Arrow} {
      border-top: 12px solid ${(props) => props.theme.colorset.grey12};
    }
  }
`;

const Label = styled.label`
  top: 0;
  left: 0;
  position: absolute;
  z-index: 1;
  pointer-events: none;
  transform: translate(12px, 6px) scale(0.7);
  transform-origin: top left;
  color: ${(props) => props.theme.colorset.grey10};
`;

const Select = styled.select`
  padding: 20px 6px 6px 12px;
  border: unset;
  outline: unset;
  background-color: transparent;
  appearance: none;
  color: ${(props) => props.theme.colorset.grey13};
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
`;
