import { Match } from '../../core/Match';
import { XString } from '../../core/XString';
import { ScriptFormat } from '../../core/str/ScriptFormat';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { NumberParser } from '../../elements/utils/NumberParser';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { MarkupImporter } from '../../expr/conversion/input/MarkupImporter';

/**
 * Extrait les nombres d'une chaîne de caractères.
 */
export class Numbers extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 1){
      return args.expectingArguments(1, 1);
    }

    if(args.getReals(0)){
      return args.getReals(0);
    }

    const thousandSeparator:string = args.env.culture.numberFormatter.thousandSeparator;
    let decimalSeparator:string = args.env.culture.numberFormatter.decimalSeparator;

    let source:string[] = [];
    if(args.getString(0)){
      source.push(args.getString(0).toString());
    }else if(args.getStrings(0)){
      source = args.getStrings(0).toStrings();
    }else if(args.getExpression(0)){
      decimalSeparator = '.';
      source.push(args.getExpression(0).rawExpression);
    }else if(args.getMarkup(0)){
      decimalSeparator = '.';
      const importer:MarkupImporter = new MarkupImporter(args.env);
      source.push(importer.getRawExpression(args.getMarkup(0).toElement()));
    }else{
      return null;
    }

    return args.env.culture.listFactory.createFromNumbers(
      this.searchNumbers(
        source,
        decimalSeparator,
        thousandSeparator,
        new NumberParser(args.env.culture)));
  }

  /**
   *
   */
  private searchNumbers(
      source:string[],
      decimalSeparator:string,
      thousandSeparator:string,
      parser:NumberParser):number[]{

    let o:number[] = [];
    for(let i:number = 0 ; i < source.length ; i++){
      o = o.concat(this.searchNumbersImpl(source[i], decimalSeparator, thousandSeparator, parser));
    }
    return o;
  }

  /**
   *
   */
  private searchNumbersImpl(
      sourceArg:string,
      decimalSeparator:string,
      thousandSeparator:string,
      parser:NumberParser):number[]{

    let source = sourceArg;
    const o:number[] = [];

    // 1. remove thousand separator
    source = source.split(thousandSeparator).join('');

    // 2. replace minus sign by hyphen
    source = source.split('−').join('-');

    // 3. replace sequences of superscript digits
    source = source.replace(new RegExp(XString.substitute('[{0}]+', ScriptFormat.supdigits), 'gi'), this.replaceSuperscriptRun);

    // 4. replace sequences of subscript digits
    source = source.replace(new RegExp(XString.substitute('[{0}]+', ScriptFormat.subdigits), 'gi'), this.replaceSubscriptRun);

    // 5. lookup numbers
    const r1:RegExp =
      new RegExp(
        XString.substitute(
          '[-]?(\\d+(\\{0}\\d*)?)|(\\{0}\\d+)',
          decimalSeparator),
        'gi');

    let z:Match = Match.tryParse(r1.exec(source));
    while(z){
      const n:number = parser.parseNumber(String(z.groups[0]));
      if(!isNaN(n)){
        o.push(n);
      }
      z = Match.tryParse(r1.exec(source));
    }

    return o;
  }

  /**
   *
   */
  private replaceSuperscriptRun(value:string, ...z:any[]):string{
    let s:string = '';
    for(let i:number = 0 ; i < value.length ; i++){
      s += String(ScriptFormat.supdigits.indexOf(value.charAt(i)));
    }
    return `(${s})`;
  }

  /**
   *
   */
  private replaceSubscriptRun(value:string, ...z:any[]):string{
    let s:string = '';
    for(let i:number = 0 ; i < value.length ; i++){
      s += String(ScriptFormat.subdigits.indexOf(value.charAt(i)));
    }
    return `(${s})`;
  }

}
