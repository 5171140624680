/**
 *
 */
export class TimeFormatUtil {

  public static hours12hourclock(h:number):number{
    if(h === 12){
      return 12;
    }
    if(h === 0){
      return 12;
    }
    return h % 12;
  }

  public static timePart(value:number):string{
    return (value < 10 ? '0' : '') + value;
  }

}
