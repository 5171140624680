import React, { useMemo } from 'react';
import Ajv from 'ajv';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Slate, Editable, RenderLeafProps, ReactEditor } from './TypedSlate';
import { shortcutListener } from './shortcuts/shortcutListener';
import { ICustomEditor, ICustomDescendant, ICustomRenderElementProps } from './models';
import { RichTextEditableWrapper } from './RichTextEditor.styles';
import richTextSchema from './richTextSchema.json';
import { LinkElement } from './elements/LinkElement/LinkElement';
import { TTSElement } from './elements/TTSElement/TTSElement';
import { ParagraphElement } from './elements/ParagraphElement/ParagraphElement';
import { ImageElement } from './elements/ImageElement/ImageElement';
import { MarkupElement } from './elements/MarkupElement/MarkupElement';
import { ListElement } from './elements/ListElement/ListElement';
import { ListItemElement } from './elements/ListItemElement/ListItemElement';
import { Leaf } from './elements/Leaf/Leaf';
import { IClassDefinition } from './models/configuration';
import { ReadonlyContext } from '../../../contexts/ReadonlyContext';
import { NodeEntry } from 'slate';
import { nonBreakingCharacterDecorator } from './decorators/NonBreakingCharacterDecorator';

export interface IRichTextEditorProps extends WrappedComponentProps {
  value: ICustomDescendant[];
  editor: ICustomEditor;
  onChange: (value: ICustomDescendant[]) => void;
  withValidation?: boolean;
  className?: string;
  autoFocus?: boolean;
  classes: ReadonlyArray<IClassDefinition>;
  textClassName: string;
  onFocus?: () => void;
  onBlur?: () => void;
  onContextMenu?: (event: React.MouseEvent) => void;
  innerRef?: React.RefObject<HTMLDivElement>;
  disabled?: boolean;
}

const richTextValidator = (new Ajv()).compile(richTextSchema);

class RichTextEditorComponent extends React.Component<IRichTextEditorProps> {
  public static contextType = ReadonlyContext;

  private previousValue: ICustomDescendant[];

  public render(): React.ReactNode {
    const {
      value,
      editor,
      className,
      autoFocus,
      onFocus,
      onBlur,
      onContextMenu,
      innerRef,
      disabled,
      classes,
      textClassName,
    } = this.props;
    const isReadonly = this.context;
    const isDisabled = disabled || isReadonly;

    if (!editor) {
      return null;
    }
    // We do not update the children's value if there are pending operations, as that would override the value returned by the onChange callback
    // This can be caused when a focus event is triggered in the middle of a change, and in turns triggers a new render.
    // This should be fixed in Slate 0.101.0: https://github.com/ianstormtaylor/slate/releases/tag/slate-react%400.101.0
    if (editor.operations.length === 0) {
      // Manually set editor.children since <Slate value={} /> is only used as an initial value
      // https://github.com/ianstormtaylor/slate/pull/4540
      editor.children = value;
    }

    return (
      <Slate
        editor={editor}
        value={value}
        onChange={this.onChange}
      >
        <RichTextEditableWrapper
          className={className}
          ref={innerRef}
          classes={classes}
          textClassName={textClassName}
        >
          <Editable
            className={textClassName}
            renderElement={this.renderElement}
            renderLeaf={this.renderLeaf}
            decorate={this.decorate}
            onKeyDown={this.onKeyDown}
            onPaste={this.onPaste}
            onCut={this.onCut}
            autoFocus={autoFocus}
            onFocus={onFocus}
            onBlur={onBlur}
            onContextMenu={onContextMenu}
            readOnly={isDisabled}
          />
        </RichTextEditableWrapper>
      </Slate>
    );
  }

  private onKeyDown = (event: React.KeyboardEvent) => {
    shortcutListener(event, this.props.editor, this.props.classes);
  }

  private onChange = (value: ICustomDescendant[]) => {
    const { withValidation, onChange } = this.props;
    if (withValidation) {
      const isValid = richTextValidator(value);
      if (isValid) {
        onChange(value);
        this.previousValue = value;
      } else {
        onChange(this.previousValue);
      }
    } else {
      onChange(value);
    }
  }

  private onPaste = (event: React.ClipboardEvent<HTMLDivElement>) => {
    const {
      editor,
      disabled,
    } = this.props;

    const isReadonly = this.context;
    const isDisabled = disabled || isReadonly;

    if (!isDisabled) {
      event.preventDefault();
      ReactEditor.insertData(editor, event.clipboardData);
    }
  }

  private onCut = (event: React.ClipboardEvent<HTMLDivElement>) => {
    event.stopPropagation();
  }

  private renderElement = (props: ICustomRenderElementProps): JSX.Element => {
    const { intl } = this.props;
    const resources = useMemo(
      () => {
        return {
          linkInputPlaceholder: intl.formatMessage({ id: 'richTextEditor.linkInputPlaceholder', defaultMessage: 'URL' }),
          ttsInputPlaceholder: intl.formatMessage({ id: 'richTextEditor.ttsInputPlaceholder', defaultMessage: 'Alt. pronunciation' }),
          imageSrcInputPlaceholder: intl.formatMessage({ id: 'richTextEditor.imageSrcInputPlaceholder', defaultMessage: 'URL' }),
          imageAltInputPlaceholder: intl.formatMessage({ id: 'richTextEditor.imageAltInputPlaceholder', defaultMessage: 'Alt. text' }),
        };
      },
      [intl.locale],
    );

    switch (props.element.type) {
      case 'link':
        return <LinkElement {...props} linkInputPlaceholder={resources.linkInputPlaceholder}/>;
      case 'tts':
        return <TTSElement {...props} ttsInputPlaceholder={resources.ttsInputPlaceholder}/>;
      case 'paragraph':
        return <ParagraphElement {...props} />;
      case 'image':
        return (
          <ImageElement
            {...props}
            srcInputPlaceholder={resources.imageSrcInputPlaceholder}
            altInputPlaceholder={resources.imageAltInputPlaceholder}
          />);
      case 'markup':
        return (
          <MarkupElement
            {...props}
          />);
      case 'list':
        return <ListElement {...props} />;
      case 'listItem':
        return <ListItemElement {...props} />;
      default:
        return <ParagraphElement {...props} />;
    }
  }

  private renderLeaf = (props: RenderLeafProps) => {
    return (
      <Leaf {...props} />
    );
  }

  private decorate = (entry: NodeEntry) => {
    return nonBreakingCharacterDecorator(entry);
  }

}

export const RichTextEditor = injectIntl(RichTextEditorComponent);
