import { XString } from '../core/XString';
import { ContentElement } from '../elements/abstract/ContentElement';
import { Node } from '../elements/abstract/Node';
import { FractionModel } from '../elements/models/FractionModel';
import { WPower } from '../elements/tokens/WPower';
import { WRational } from '../elements/tokens/WRational';
import { Environment } from '../expr/Environment';
import { IWriter } from '../expr/conversion/writers/IWriter';
import { KeyboardConfiguration } from './KeyboardConfiguration';
import { InputCapabilities } from './InputCapabilities';
import { CommonError } from './CommonError';
import { COptions } from './COptions';
import { CFraction } from './CFraction';
import { CNumber } from './CNumber';
import { BaseCorrector } from './BaseCorrector';

/**
 * base^(num/den)
 */
export class CExpFrac extends BaseCorrector {

  private cbase:CNumber;

  private cexp:CFraction;

  /**
   *
   */
  public configure(origin:ContentElement, options:COptions, env:Environment, useLatex:boolean):void{
    super.configure(origin, options, env, useLatex);
    this.cbase = new CNumber(null, null, true, false);
    this.cexp = new CFraction();
    super.configureOther(this.cexp);
    super.configureOther(this.cbase);
  }

  /**
   *
   */
  public parse(value:string):Node{
    const po:WPower = this.tryParse(value);
    if(po){
      const node:Node = new Node(po);
      // preserve power, preserve rationals
      node.userData = po.userData.replace('{', '(').replace('}', ')') + '@1025';
      return node;
    }
    return null;
  }

  /**
   *
   */
  public correct(
      value:string,
      target:ContentElement,
      ...targets:any[]):boolean{

    const value2:WPower = this.tryParse(value);
    const target2:WPower = <WPower>target ;

    if(!value2){
      // TODO: gentle reminder invalid format
      return false;
    }

    let base:string = value2.userData.split('^')[0];
    let exp:string = value2.userData.split('^')[1];

    base = XString.trimParenthesis(base);
    exp = XString.trimBraces(exp);

    try{
      return 	this.cbase.correct(base, target2.base) &&
          this.cexp.correct(exp, target2.exponent);
    }catch(e){
      if(e instanceof CommonError){
        return false;
      }
      throw e;
    }

  }

  /**
   *
   */
  private tryParse(valueArg:string):WPower{
    const value = this.sanitizeInput(valueArg);
    const parts:any[] = value.split('^');
    const baseEnclosed:boolean = XString.isEnclosed(parts[0]);

    parts[0] = XString.trimParenthesis(parts[0]);
    if(parts.length > 1){
      parts[1] = XString.trimBraces(parts[1]);
    }else{
      parts.push('1');
    }

    // 1^2/3 --> 1^(2/3)
    // 1
    const base = this.env.culture.createNumber(this.numberParser.parseNumber(parts[0]));
    if(!base){
      return null;
    }

    const exp = FractionModel.tryParse(parts[1], this.useLatex);
    if(!exp){
      return null;
    }

    const power:WPower = new WPower(base, this.parseRationalModel(exp));
    power.userData = (baseEnclosed ? '(' : '') + parts[0] + (baseEnclosed ? ')' : '') + '^{' + parts[1] + '}';
    return power;
  }

  /**
   *
   */
  public get inputCapabilities():InputCapabilities{
    const o:InputCapabilities = new InputCapabilities();
    o.exponentFraction = true;
    o.symbols = '−^/';
    return o;
  }

  /**
   *
   */
  public get mathKeyboard():number{
    return KeyboardConfiguration.EXP_FRACTION;
  }

  /**
   *
   */
  public writeTo(
      w:IWriter,
      target:ContentElement,
      ...targets:any[]):void{

    const po:WPower = <WPower>target ;

    w.writeNumber(po.base.toNumber());

    if(this.useLatex){
      w.beginExponent();
    }else{
      w.writeInfixOperator('^');
    }

    if(po.exponent instanceof WRational){
      w.beginFraction();
      w.writeNumber((<WRational>po.exponent ).numerator);
      w.startParagraph();
      w.beginDenominator();
      w.writeNumber((<WRational>po.exponent ).denominator);
      w.endFraction();
    }else{
      w.writeNumber(po.exponent.toNumber());
    }

    if(this.useLatex){
      w.endExponent();
    }
  }

}
