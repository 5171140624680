import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { ListElement } from '../../elements/abstract/ListElement';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';
import { ListOfListElement } from '../../elements/abstract/ListOfListElement';

/**
 * Replace one element starting from a specified index.
 * Returns the obtained list.
 */
export class ReplaceAt extends FunctionElement {
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 3) {
      return args.expectingArguments(3, 3);
    }

    const listOfList = args.getListOfList(0);
    if (listOfList) {
      const [list, index] = [args.getList(1), args.getReal(2)];
      if (list && index) {
        return this.listOfList(listOfList, list, index.toNumber(), args.env);
      }
    }

    const [list, newValues, indices] = [args.getList(0), args.getList(1), args.getReals(2)];
    if (list && newValues && indices) {
      return this.list(list, newValues, indices.toNumbersV(), args.env);
    }

    return null;
  }

  /**
   * Syntax: ReplaceAt(list, newValues, indices)
   */
  private list(list: ListElement, newValues: ListElement, indices: number[], env: Environment): ContentElement {
    if (list.count === 0 || newValues.count === 0 || indices.length === 0) {
      return list;
    }

    if (list.getListItemCode() !== newValues.getListItemCode()) {
      return list;
    }

    const items: ContentElement[] = [];
    for (let i: number = 0; i < list.count; i++) {
      const k = indices.indexOf(i);
      if (k === -1) {
        items.push(list.getItemAt(i));
      } else {
        items.push(newValues.getItemAt(k < newValues.count ? k : newValues.count - 1));
      }
    }

    return env.culture.listFactory.createList(items).applyFormat(list.formatter);
  }

  private listOfList(list: ListOfListElement, replacement: ListElement, index: number, env: Environment): ListOfListElement {
    if (list.count === 0 || replacement.count === 0 || index < 0 || index >= list.count) {
      return list;
    }

    if (list.getListItemCode() !== replacement.getListItemCode()) {
      return list;
    }

    const items: ContentElement[] = [];
    for (let i = 0; i < list.count; i++) {
      items.push(i === index ? replacement : list.getItemAt(i));
    }

    return env.culture.listFactory.createList(items).applyFormat(list.formatter) as ListOfListElement;
  }
}
