import { ContentElement } from '../../elements/abstract/ContentElement';
import { ListElement } from '../../elements/abstract/ListElement';
import { BaseListFormatter } from '../../elements/formats/BaseListFormatter';
import { WInterval } from '../../elements/tokens/WInterval';

/**
 *
 */
export class WListOfIntervals extends ListElement {

  constructor(
      intervals:ContentElement[],
      formatter:BaseListFormatter){
    super(intervals, formatter);
  }

  public getTypedItemAt(index:number):WInterval{
    return <WInterval>this.getItemAt(index) ;
  }

  public acceptElement(element:ContentElement):boolean{
    return element.getListItemCode() === 'interval';
  }

  public equalsTo(value:ContentElement):boolean{
    if(value instanceof WListOfIntervals){
      return this.unorderedEqualsTo(<WListOfIntervals>value);
    }
    return false;
  }

  public strictlyEqualsTo(value:ContentElement):boolean{
    if(value instanceof WListOfIntervals){
      return this.strictlyEqualsToImpl(value);
    }
    return false;
  }

  protected compareElements(a:ContentElement, b:ContentElement):number{
    return WInterval.compare(<WInterval>a , <WInterval>b );
  }

  protected createList(
      items:ContentElement[],
      formatter:BaseListFormatter):ListElement{
    return new WListOfIntervals(items, formatter);
  }

  public toIntervals():WInterval[]{
    const o:WInterval[] = [];
    for(let i:number = 0 ; i < this.count ; i++){
      o.push(this.getTypedItemAt(i));
    }
    return o;
  }

  public getType():string {
    return 'intervals';
  }

}
