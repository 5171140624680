export interface IFilterResult<TValue extends { filterValue: string }> {
  value: TValue;
  emphasis: {
    startIndex: number;
    length: number;
  };
}

export const filterValues = <TValue extends { filterValue: string }>(values: ReadonlyArray<TValue>, filter: string, excludeExactMatch?: boolean): ReadonlyArray<IFilterResult<TValue>> => {
  const normalizedFilterText = normalizeFilterValue(filter);
  const filterExactMatch = (value: string) => !excludeExactMatch || normalizedFilterText !== value;

  const startingWith = values.filter((value) => startsWith(value.filterValue, normalizedFilterText) && filterExactMatch(value.filterValue));
  const others = values
    .filter((value) => {
      return !startsWith(value.filterValue, normalizedFilterText) &&
        containsText(value.filterValue, normalizedFilterText) &&
        filterExactMatch(value.filterValue);
    });

  const combinedResults = startingWith.concat(others);
  return combinedResults.map((value) => {
    return {
      value,
      emphasis: {
        startIndex: value.filterValue.indexOf(normalizedFilterText),
        length: normalizedFilterText.length
      }
    };
  });
};

export const normalizeFilterValue = (value: string): string => {
  if (!value)return value;
  return value.normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .trim()
    .replace(/\s+/g, ' ');
};

export const sanitizeFilter = (filter: string) => {
  return filter.replace(',', '');
};

const startsWith = (tag: string, normalizedFilter: string) => {
  return tag.startsWith(normalizedFilter);
};

const containsText = (tag: string, normalizedFilter: string) => {
  return tag.includes(normalizedFilter);
};
