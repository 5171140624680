import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { RealElement } from '../../elements/abstract/RealElement';

/**
 *
 */
export class SkipRows extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 2){
      return args.expectingArguments(2, 2);
    }

    const matrix:WMatrix = args.getMatrix(0);
    if(!matrix){
      return null;
    }
    if(matrix.columns === 0){
      return matrix;
    }

    const n:RealElement = args.getWholeNumber(1);
    if(!n){
      return null;
    }

    const rows = n.toNumber();

    if(rows === 0){
      return matrix;
    }
    if(rows >= matrix.rows){
      return args.env.culture.createMatrix([], 0);
    }

    return new WMatrix(matrix.values.slice(rows * matrix.columns), matrix.columns, matrix.formatter);
  }

}
