import React from 'react';
import { createPortal } from 'react-dom';
import { defaultDropAnimation, DragOverlay } from '@dnd-kit/core';

export interface IDragOverlayWrapperProps {
  draggedElement: React.ReactNode;
  animated: boolean;
}

export const DragOverlayWrapper: React.FC<IDragOverlayWrapperProps> = (props) => {

  const dropAnimation =
    props.animated
      ? defaultDropAnimation
      : {
        duration: 0,
        dragSourceOpacity: 1,
        easing: null,
      };

  return createPortal(
    <DragOverlay
      adjustScale={false}
      dropAnimation={dropAnimation}
    >
      {props.draggedElement}
    </DragOverlay>,
    document.body
  );

};
