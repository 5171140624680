import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';

/**
 * Soustraction de matrices.
 */
export class SubtractMatrices extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 2){
      return args.expectingArguments(2, 2);
    }
    if(args.getMatrix(0) && args.getMatrix(1)){
      return this.subtract(args.getMatrix(0), args.getMatrix(1), args.env);
    }
    return null;
  }

  /**
   *
   */
  private subtract(a:WMatrix, b:WMatrix, env:Environment):WMatrix{
    if(a.columns !== b.columns || a.rows !== b.rows){
      return null;
    }
    const c:RealElement[] = [];

    for(let i:number = 0 ; i < a.values.length ; i++){
      c.push(env.reals.subtract(a.values[i], b.values[i]));
    }

    return new WMatrix(c, a.columns, a.formatter);
  }

}
