import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { TokenElement } from '../../elements/abstract/TokenElement';
import { WFiniteSet } from '../../elements/tokens/WFiniteSet';
import { WList } from '../../elements/tokens/WList';
import { WRange } from '../../elements/tokens/WRange';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';
import { ListElement } from '../../elements/abstract/ListElement';

/**
 * Retourne un sous-ensemble d'un ensemble donné, les éléments retournés sont uniques.
 * Tirage sans remise
 */
export class Sample extends FunctionElement {

  /**
   * Retourne le nombre passé en paramètre. (rétrocompatibilité)
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length < 1 || args.length > 2){
      return args.expectingArguments(1, 2);
    }

    if(args.length === 1){
      if(args.getFiniteSet(0)){
        return this.set1(args.getFiniteSet(0), args.env);
      }
      if(args.getRange(0)){
        return this.range1(args.getRange(0), args.env);
      }
      // if(args.getReal(0))return args.getReal(0);
      if(args.getReals(0)){
        return this.list1(args.getReals(0), args.env);
      }
    }else if(args.length === 2){
      const length:RealElement = args.getWholeNumber(1);
      if(args.getFiniteSet(0)){
        return this.subset(args.getFiniteSet(0), length, args.env);
      }
      if(args.getRange(0)){
        return this.subrange(args.getRange(0), length, args.env);
      }
      if(args.getReals(0)){
        return this.sublist(args.getReals(0), length, args.env);
      }
    }

    return null;
  }

  /**
   *
   */
  private list1(a:WList, env:Environment):RealElement{
    return this.sublist(a, env.culture.createNumber(1), env).getTypedItemAt(0);
  }

  /**
   *
   */
  private sublist(a:WList, b:RealElement, env:Environment):WList{
    if(!b.isWholeNumber()){
      return null;
    }
    const e:TokenElement[] = [];
    for(let i:number = 0 ; i < a.count ; i++){
      e.push(a.getTypedItemAt(i));
    }
    const hat:WFiniteSet = env.createNormalizedSet(e);
    const s:WFiniteSet = this.subset(hat, b, env);
    return s ? s.toList() : null;
  }

  /**
   *
   */
  private set1(a:WFiniteSet, env:Environment):TokenElement{
    return this.subset(a, env.culture.createNumber(1), env).getElementAt(0);
  }

  /**
   *
   */
  private subset(a:WFiniteSet, b:RealElement, env:Environment):WFiniteSet{
    if(!b.isWholeNumber()){
      return null;
    }

    const indices:any[] = [];
    for(let i:number = 0 ; i < a.cardinal ; i++){
      indices.push(i);
    }

    const r:TokenElement[] = [];

    let k:number = 0;
    let index:number;
    while(k < b.toNumber() && indices.length > 0){
      index = env.prng.randomIndex(indices.length);
      r.push(a.getElementAt(indices[index]));
      indices.splice(index, 1);
      k++;
    }

    return env.createNormalizedSet(r);
  }

  /**
   *
   */
  private range1(a:WRange, env:Environment):RealElement{
    return this.subrange(a, env.culture.createNumber(1), env).getItemAt(0) as RealElement;
  }

  /**
   *
   */
  private subrange(a:WRange, bR:RealElement, env:Environment):ListElement{
    if(!bR.isNaturalNumber()){
      return null;
    }

    const b:number = bR.toNumber();

    const r:ContentElement[] = [];
    let i:number;

    const cardinal:number = a.getCardinal();
    const indices:number[] = [];

    if(cardinal <= b){
      // No random, all items from the range are included.
      for(i = 0 ; i < cardinal ; i++){
        indices.push(i);
      }
      while(indices.length > 0){
        i = env.prng.randomIndex(indices.length);
        r.push(a.itemAt(indices[i]));
        indices.splice(i, 1);
      }
    }else{
      do{
        const index = env.prng.randomIndex(cardinal);
        if(indices.indexOf(index) === -1){
          r.push(a.itemAt(index));
          indices.push(index);
        }
      }while(r.length < b);
    }

    return env.culture.listFactory.createList(r);
  }

}
