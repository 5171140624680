import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { SymbolElement } from '../../elements/abstract/SymbolElement';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Degré d'un polynôme.
 */
export class PolynomialDegree extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length < 1 || args.length > 2){
      return args.expectingArguments(1, 2);
    }

    let degree:number = NaN;

    if(args.length === 1){
      if(args.getReal(0)){
        degree = 0;
      }else if(args.getSymbol(0)){
        degree = 1;
      }else if(args.getPolynomial(0)){
        degree = args.getPolynomial(0).degree;
      }
    }else if(args.length === 2){
      const symbol:SymbolElement = args.getSymbol(1);
      if(!symbol){
        return null;
      }

      if(args.getReal(0)){
        degree = 0;
      }else if(args.getSymbol(0)){
        degree = args.getSymbol(0).equalsTo(symbol) ? 1 : 0;
      }else if(args.getPolynomial(0)){
        degree = args.getPolynomial(0).getVariableDegree(symbol);
      }
    }

    if(isNaN(degree)){
      return null;
    }

    return args.env.culture.createNumber(degree);
  }

}
