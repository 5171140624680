export abstract class AuthTokenProvider {

  protected abstract get storageKey(): string;

  public hasToken() {
    return this.getToken() !== null;
  }

  public getToken() {
    return window.localStorage.getItem(this.storageKey);
  }

  public setToken(token: string) {
    window.localStorage.setItem(this.storageKey, token);
  }

  public clearToken() {
    window.localStorage.removeItem(this.storageKey);
  }
}
