import { Point } from '../../../js/geom/Point';

import { XGeom } from '../../core/XGeom';
import { XMath } from '../../core/XMath';
import { XSort } from '../../core/XSort';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { TokenElement } from '../../elements/abstract/TokenElement';
import { SegmentsUtil } from '../../elements/utils/SegmentsUtil';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';
import { WSegment } from '../../elements/tokens/WSegment';

/**
 *
 */
export class WPolygon extends TokenElement {

  protected _vertices:Point[];

  public verticeAt(index:number):Point{return this._vertices[index].clone();}

  public get vertices():Point[]{return this._vertices.concat();}

  constructor(vertices:Point[]){
    super();
    this._vertices = vertices;
  }

  public get perimeter():number{
    return SegmentsUtil.polylineLength(this._vertices, true);
  }

  public get segments():number{return this._vertices.length;}

  public segmentAt(index:number):WSegment{
    const a:number = index;
    let b:number = index + 1;
    if(b >= this.vertices.length){
      b = 0;
    }
    return new WSegment(this.verticeAt(a), this.verticeAt(b));
  }

  public get isConvex():boolean {
    if(this.vertices.length === 3){
      return true;
    }
    return !this.isConcave && !this.isCrossed;
  }

  /**
   * http://stackoverflow.com/questions/471962/how-do-determine-if-a-polygon-is-complex-convex-nonconvex
   */
  public get isConcave():boolean {
    if(this.vertices.length === 3){
      return false;
    }

    if(this.vertices.length === 4){
      const a:Point = this.vertices[0];
      const b:Point = this.vertices[1];
      const c:Point = this.vertices[2];
      const d:Point = this.vertices[3];
      return 	XGeom.triangleContainsPoint(b, c, d, a) ||
          XGeom.triangleContainsPoint(a, c, d, b) ||
          XGeom.triangleContainsPoint(a, b, d, c) ||
          XGeom.triangleContainsPoint(a, b, c, d);
    }

    const v:Point[] = this.vertices;
    const z:number = WPolygon.zcrossproduct(v[0], v[1], v[2]);
    for(let i:number = 1 ; i < v.length - 1 ; i++){
      const ia:number = i;
      const ib:number = i + 1;
      const ic:number = (i === v.length - 2) ? 0 : i + 2;
      const z2:number = WPolygon.zcrossproduct(v[ia], v[ib], v[ic]);
      if(z < 0 && z2 >= 0 || z >= 0 && z2 < 0){
        return true;
      }
    }
    return false;
  }

  /**
   *
   */
  public get isCrossed():boolean {
    if(this.vertices.length === 3){
      return false;
    }
    if(this.vertices.length === 4){
      const a:Point = this.vertices[0];
      const b:Point = this.vertices[1];
      const c:Point = this.vertices[2];
      const d:Point = this.vertices[3];
      const ia:Point = XGeom.segmentsIntersection( a, b, c, d);
      const ib:Point = XGeom.segmentsIntersection( a, d, b, c);
      return ia != null || ib != null;
    }
    return SegmentsUtil.selfIntersects( this.vertices, true);
  }

  /**
   *
   */
  public get isRegular():boolean{
    if(!this.isConvex){
      return false;
    }

    const a:number[] = XGeom.interiorAngles(this.vertices, false);
    const m:number[] = XGeom.edges(this.vertices);

    for(let i:number = 1 ; i < a.length ; i++){
      if(!XMath.safeEquals(a[i], a[0])){
        return false;
      }
      if(!XMath.safeEquals(m[i], m[0])){
        return false;
      }
    }

    return true;
  }

  /**
   *
   */
  private static zcrossproduct(
      a:Point,
      b:Point,
      c:Point):number{

    const dx1:number = b.x - a.x;
    const dy1:number = b.y - a.y;
    const dx2:number = c.x - b.x;
    const dy2:number = c.y - b.y;
    return dx1 * dy2 - dy1 * dx2;
  }

  /**
   *
   */
  public equalsTo(value:ContentElement):boolean{
    if(value instanceof WPolygon){
      return SegmentsUtil.equals(this._vertices, (<WPolygon>value )._vertices);
    }
    return false;
  }

  /**
   * Returns every intersection point at the specified y sorted by x's
   */
  public scanline(y:number):number[]{
    let o:number[] = [];

    for(let i:number = 0 ; i < this._vertices.length ; i++){
      const a:Point = this._vertices[i];
      const b:Point = this._vertices[i + 1 >= this._vertices.length ? 0 : i + 1];

      const y1:number = Math.max(a.y, b.y);
      const y2:number = Math.min(a.y, b.y);

      // Check intersection
      if(y <= y1 && y >= y2){
        // Intersection is ignored for an edge if it's equals the minimum y
        if(y === y2){
          continue;
        }else if(a.x === b.x){
          // Vertical line
          o.push(a.x);
        }else{
          o.push(XGeom.segmentsIntersection(a, b, new Point(a.x, y), new Point(b.x, y)).x);
        }
      }

    }

    o = o.sort(XSort.numeric);

    // Should always return an even number of intersection points
    return o;
  }

  /**
   * Check if a point is inside a polygon or lies on the edges
   */
  public containsPoint(pt:Point):boolean{
    let c:boolean = false;
    const poly:Point[] = this._vertices;

    let i:number;
    const l:number = poly.length;
    let j:number = poly.length - 1;

    // 1. Check inside ( http://alienryderflex.com/polygon/ )
    for(i = -1 ; ++i < l; j = i){
      if(((poly[i].y <= pt.y && pt.y < poly[j].y) || (poly[j].y <= pt.y && pt.y < poly[i].y)) &&
        (pt.x < (poly[j].x - poly[i].x) * (pt.y - poly[i].y) / (poly[j].y - poly[i].y) + poly[i].x)){

        c = !c;
      }
    }

    // If the first check succeed, it means there's an intersection.
    if(c){
      return c;
    }

    // Since the first check can fail when points are on the edges because of rounding error,
    // if there's no intersection found, we will check every edge explicitly.

    for(let k:number = 0 ; k < this.segments ; k++){
      if(this.segmentAt(k).containsPoint(pt)){
        return true;
      }
    }

    return false;
  }

  public writeTo(exporter:IMarkupExporter = null):boolean{
    if(exporter){
      exporter.writer.appendText(`[${String(this.vertices.length)}-gon]`);
    }
    return true;
  }

  /**
   *
   * @param vertices
   */
  public static validatePolygon(vertices: Point[]): boolean {
    if (!vertices) {
      return false;
    }
    if (vertices.some((v) => !v)) {
      return false;
    }
    if (vertices.length < 3) {
      return false;
    }

    for (let i = 0 ; i < vertices.length ; i++) {
      let j = i + 1;
      if (j >= vertices.length) {
        j = 0;
      }
      let k = j + 1;
      if(k >= vertices.length) {
        k = 0;
      }
      if (XGeom.colinear(vertices[i], vertices[j], vertices[k])) {
        return false;
      }
    }

    return true;
  }

  /**
   *
   * @param vertices
   */
  public static tryParsePolygon(vertices: Point[]):WPolygon{
    return WPolygon.validatePolygon(vertices) ?
      new WPolygon(vertices.concat()) :
      null;
  }

  /**
   *
   */
  public getType():string{
    return 'polygon';
  }

}
