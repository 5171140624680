import { MmlWriter } from '../../core/mml/MmlWriter';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';
import { IMarkupFactory } from '../../elements/markers/IMarkupFactory';
import { WList } from '../../elements/tokens/WList';
import { WListOfString } from '../../elements/tokens/WListOfString';
import { CultureInfo } from '../../localization/CultureInfo';

/**
 *
 */
export class VTableImpl implements IMarkupFactory {

  private col1:WListOfString;

  private col2:WList;

  constructor(col1:WListOfString, col2:WList) {

    this.col1 = col1;
    this.col2 = col2;
  }

  public get culture():CultureInfo{
    return this.col1.formatter.culture;
  }

  public writeTo(exporter:IMarkupExporter):void{
    const w:MmlWriter = exporter.writer;
    w.beginTable();
    w.frame = 'solid';
    w.rowlines = 'solid';
    w.columnlines = 'solid';

    for(let r:number = 0 ; r < Math.max(this.col1.count, this.col2.count) ; r++){
      w.beginTr();

      // col1
      w.beginTd();
      if(r < this.col1.count){
        w.appendText(this.col1.getValueAt(r));
      }
      w.endTd();

      // col2
      w.beginTd();
      if(r < this.col2.count){
        w.appendNumber(this.culture.formatNumber(this.col2.getValueAt(r)));
      }
      w.endTd();

      w.endTr();
    }

    w.endTable();
  }

}
