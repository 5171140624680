import React from 'react';
import styled from 'styled-components';
import { Tooltip } from '../../utils/Tooltip';

export interface IGeneratedValuePreviewProps {
  readonly preview: React.ReactNode;
  readonly className?: string;
}

export const GeneratedValuePreview: React.FC<IGeneratedValuePreviewProps> = (props) => {

  const {
    preview,
    className,
  } = props;

  return (
    <Tooltip
      title={preview}
      enterDelay={100}
    >
      <Container
        className={className}
      >
        {preview}
      </Container>
    </Tooltip>
  );
}

const Container = styled.div`
`;
