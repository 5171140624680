import { RelationalElement } from '../../elements/abstract/RelationalElement';
import { LessThan } from '../../funcs/relational/LessThan';

/**
 *
 */
export class GreaterThan extends RelationalElement {

  /**
   *
   */
  constructor(){
    super('>');
  }

  /**
   *
   */
  protected hasNumberComparer():boolean{
    return true;
  }

  /**
   *
   */
  protected compareNumbers(na:number, nb:number):boolean{
    return na > nb;
  }

  /**
   *
   */
  public get reverse():RelationalElement{
    return new LessThan();
  }

}
