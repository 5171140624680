import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { IDictionary } from '../../../js/utils/IDictionary';

export class ProcessInput extends FunctionElement {

  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 2){
      return args.expectingArguments(2, 2);
    }

    const input = args.getInput(0);
    if(!input){
      return null;
    }

    const parameters = args.getDictionary(1);
    if(!parameters){
      return null;
    }

    const _parameters: IDictionary = {};
    parameters.keys.forEach((key: string) => {
      _parameters[key] = parameters.item2(key);
    });

    return input.adapter.copy(_parameters);
  }

}
