import { ContentElement } from '../../../elements/abstract/ContentElement';
import { FunctionElement } from '../../../elements/abstract/FunctionElement';
import { WListOfSegments } from '../../../elements/tokens/WListOfSegments';
import { WPolygon } from '../../../elements/tokens/WPolygon';
import { WPolyline } from '../../../elements/tokens/WPolyline';
import { WSegment } from '../../../elements/tokens/WSegment';
import { ArgumentsObject } from '../../../expr/ArgumentsObject';

/**
 * Rassemble tous les segments en une liste de segments.
 */
export class Segments extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    args.expectingArguments(0, Number.POSITIVE_INFINITY);

    let o:ContentElement[] = [];

    for(let i:number = 0 ; i < args.length ; i++){
      if(args.getSegment(i)){
        o.push(args.getSegment(i));
      }else if(args.getSegments(i)){
        o = o.concat(args.getSegments(i).toSegments());
      }else if(args.getPolygon(i)){
        const polygon:WPolygon = args.getPolygon(i);
        for(let j:number = 0 ; j < polygon.segments ; j++){
          o.push(new WSegment(
            polygon.verticeAt(j),
            polygon.verticeAt(j === polygon.segments - 1 ? 0 : j + 1)));
        }
      }else if(args.getPolyline(i)){
        const polyline:WPolyline = args.getPolyline(i);
        for(let k:number = 0 ; k < polyline.segments ; k++){
          o.push(new WSegment(
            polyline.verticeAt(k),
            polyline.verticeAt(k + 1)));
        }
      }else{
        return null;
      }
    }

    return new WListOfSegments(o, args.env.culture.listFormatter);
  }

}
