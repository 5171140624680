import { IDictionary } from '../../js/utils/IDictionary';

import { Match } from '../core/Match';
import { ContentElement } from '../elements/abstract/ContentElement';
import { Node } from '../elements/abstract/Node';
import { RealElement } from '../elements/abstract/RealElement';
import { SymbolElement } from '../elements/abstract/SymbolElement';
import { WPolynomial } from '../elements/tokens/WPolynomial';
import { IWriter } from '../expr/conversion/writers/IWriter';
import { OldWriterFacade } from '../expr/conversion/writers/OldWriterFacade';
import { InputCapabilities } from './InputCapabilities';
import { KeyboardConfiguration } from './KeyboardConfiguration';
import { CommonError } from './CommonError';
import { BaseCorrector } from './BaseCorrector';
import { XString } from '../core/XString';

/**
 *
 */
export class CPolynomial extends BaseCorrector {

  /**
   * Regex expression for matching monomial with the sign before.
   */
  private static MONOMIAL_COEF:string = '[\\d]*[\\.,]?[\\d]*';

  private static MONOMIAL_REGEX:string = '[\\+−]?('+CPolynomial.MONOMIAL_COEF+')?([a-zπ]?[a-zπ\\d]+)';

  private hasPi:boolean;

  private hasNegativeCoef:boolean;

  private hasMultMonom:boolean;

  constructor(
      hasPi:boolean,
      hasNegativeCoef:boolean,
      hasMultMonom:boolean){
    super();
    this.hasPi = hasPi;
    this.hasNegativeCoef = hasNegativeCoef;
    this.hasMultMonom = hasMultMonom;
  }

  /**
   *
   * @param value
   * @returns {any}
   */
  public parse(value:string):Node{
    const value2:string = this.translateInput(value).replace(/,/g, '.');
    try{
      const v:ContentElement = this.tryParse(value2);
      if(v){
        const node:Node = new Node(v);
        node.userData = value2;
        return node;
      }
    }catch(e){
      // If value2 is an invalid expression then parse should return null.
    }
    return null;
  }

  public correct(
      valueArg:string,
      targetArg:ContentElement,
      ...targets:any[]):boolean{

    // could be a SymbolElement, Monomial or Polynomial
    let value = this.translateInput(valueArg);
    let target = targetArg;

    if(value.length === 0){
      this.raiseError(CommonError.NOT_A_POLYNOMIAL);
    }

    if(value.charAt(0) === '+'){
      if(this.env.culture.configuration.acceptLeadingPlus){
        value = value.substring(1);
      }else{
        this.raiseError(CommonError.POLY_PLUS_IN_FRONT);
      }
    }

    if(this.env.culture.configuration.acceptPlusMinus){
      value = value.replace(/\+−/g, '−');
    }

    const simplifyErrorRgx:RegExp = /\+\+|\+−|−\+|−−/;

    if(simplifyErrorRgx.test(value)){
      this.raiseError(CommonError.POLY_NOT_SIMPLIFIED);
    }

    let isNegativeGroup:boolean = false;
    const negativeGroupRgx: RegExp = /^[-−]\(.*\)$/;
    if(negativeGroupRgx.test(value)){
      value = value.substring(2, value.length - 1);
      isNegativeGroup = true;
    }

    const mo:any[] = []; // liste de monômes
    const r:RegExp = new RegExp(CPolynomial.MONOMIAL_REGEX, 'g');
    let ri:Match = Match.tryParse(r.exec(value));
    let p:number = 0;
    let m:string;
    while(ri){
      if(ri.index !== p){
        this.raiseError(CommonError.NOT_A_POLYNOMIAL);
      }

      m = String(ri.groups[0]);
      mo.push(m);

      p += m.length;
      ri = Match.tryParse(r.exec(value));
    }
    if(p < value.length){
      this.raiseError(CommonError.NOT_A_POLYNOMIAL);
    }

    const terms:any[] = [];
    let constant:boolean = false;
    for(let i:number = 0 ; i < mo.length ; i++){
      m = mo[i];

      if(m.charAt(0) === '+' || m.charAt(0) === '−'){
        m = m.substring(1);
      }

      let coefLength:number = 0;
      const co:Match = Match.tryParse(new RegExp(CPolynomial.MONOMIAL_COEF).exec(m));
      if(co){
        if(co.index === 0){
          coefLength = String(co.groups[0]).length;
        }
      }

      if(coefLength > 0){ // il y a un coefficient
        this.checkDecimalSeparator(m.substring(0, coefLength)); // vérification du séparateur décimal de tous les coefficients
        const coef:number = Number(m.substring(0, coefLength).replace(',', '.'));
        m = m.substring(coefLength);
        if(m.length > 0){
          if(coef === 0){
            this.raiseError(CommonError.POLY_COEF_0);
          }
          if(coef === 1){
            this.raiseError(CommonError.POLY_COEF_1); // inclut aussi le -1 puisqu'on la retiré juste avant
          }
        }
      }

      if(m.length > 0){
        const symbols:string[] = [];
        const powers:IDictionary = {};
        const varexs:RegExp = new RegExp('([a-zπ])([\\d]*)', 'g');
        let varex:Match = Match.tryParse(varexs.exec(m));

        while(varex){
          const symbol:string = String(varex.groups[1]);
          let power:number = Number(varex.groups[2]);

          if(power === 1){
            this.raiseError(CommonError.POLY_POWER1);
          }

          if(isNaN(power)){
            power = 1;
          }

          if(powers[symbol] !== undefined){
            this.raiseError(CommonError.MONO_DUPL_SYMBOL);
          }

          powers[symbol] = power;
          symbols.push(symbol);

          varex = Match.tryParse(varexs.exec(m));
        }

        let term:string = '';

        symbols.sort();
        for(let k:number = 0 ; k < symbols.length ; k++){
          term += symbols[k] + powers[symbols[k]];
        }

        if(terms.indexOf(term) !== -1){
          this.raiseError(CommonError.POLY_SIMILAR_TERMS);
        }

        terms.push(term);

      }else{
        // monome constant
        if(constant){
          this.raiseError(CommonError.POLY_SIMILAR_TERMS);
        }
        constant = true;
      }
    }

    if(isNegativeGroup){
      value = `-(${value})`;
    }

    // Validation phase complete, now need to compare the solution
    let cvalue:ContentElement = this.tryParse(value);

    cvalue = this.corcePolynomial(cvalue);
    target = this.corcePolynomial(target);

    // Could happen if the user enter something that is not a polynomial, maybe a number
    return cvalue == null ? false : cvalue.equalsTo(target);
  }

  /**
   *
   */
  private translateInput(valueArg:string):string{
    let value = valueArg;
    if(this.useLatex){
      value = this.sanitizeInput(value);
      value = value.replace(/\^\{(\d+)\}/g, '$1');
      value = value.replace(/\^/g, '');
    }
    value = value.replace(/ /g, '');
    value = value.toLowerCase();
    value = XString.trimEnclosingParenthesis(value);
    return value;
  }

  /**
   *
   */
  private tryParse(value:string):ContentElement{
    return this.env.expressions.toContentElement(value.replace(/,/g, '.'));
  }

  private corcePolynomial(value:ContentElement):WPolynomial{
    if(value instanceof SymbolElement){
      return <WPolynomial>(<SymbolElement>value ).widen() ;
    }

    if(value instanceof WPolynomial){
      return <WPolynomial>value ;
    }

    return null;
  }

  public writeTo(w:IWriter, target:ContentElement, ...targets:any[]):void{
    CPolynomial.writePolynomialOrSymbolOrReal(w, target);
  }

  public static writePolynomialOrSymbolOrReal(w:IWriter, value:ContentElement):void{
    if(value instanceof SymbolElement){
      w.writeSymbol((<SymbolElement>value ).getSymbol());
    }else if(value instanceof RealElement){
      w.writeNumber((<RealElement>value ).toNumber());
    }else if(value instanceof WPolynomial){
      CPolynomial.writePolynomial(w, <WPolynomial>value );
    }
  }

  public static writePolynomial(w:IWriter, p:WPolynomial):void{
    p.flush(new OldWriterFacade(w));
  }

  public get mathKeyboard():number{return KeyboardConfiguration.POLYNOMIAL;}

  public get inputCapabilities():InputCapabilities{
    const symbols:any[] = [];
    if(this.hasPi){
      symbols.push('π');
    }
    if(this.hasNegativeCoef){
      symbols.push('−');
    }
    if(this.hasMultMonom){
      symbols.push('+');
    }

    const i:InputCapabilities = super.inputWithSymbols(symbols.join(''));
    i.polynomial = true;
    i.superscript = true;
    return i;
  }

}
