import { CSSProperties } from 'react';
import { merge } from 'lodash';
import { ColorSet } from '../../../themes/ColorSet';

export interface IInputTheme {
  default: {
    color: CSSProperties['color'];
    placeholderColor: CSSProperties['color'];
    iconColor: CSSProperties['color'];
    iconHoverColor: CSSProperties['color'];
    border: CSSProperties['border'];
    backgroundColor: CSSProperties['backgroundColor'];
    error: {
      color: CSSProperties['color'];
      border: CSSProperties['border'];
    },
    focus: {
      border: CSSProperties['border'];
    },
    disabled: {
      color: CSSProperties['color'];
      border: CSSProperties['border'];
      backgroundColor: CSSProperties['backgroundColor'];
    }
  };
}

export const darkInputTheme: IInputTheme = {
  default: {
    color: ColorSet.white,
    placeholderColor: ColorSet.grey500,
    iconColor: ColorSet.grey500,
    iconHoverColor: ColorSet.white,
    backgroundColor: ColorSet.penumbra,
    border: `1px solid ${ColorSet.penumbra}`,
    error: {
      color: ColorSet.alert500,
      border: `1px solid ${ColorSet.alert500}`,
    },
    focus: {
      border: `1px solid ${ColorSet.primary500}`,
    },
    disabled: {
      color: ColorSet.grey500,
      border: `1px solid ${ColorSet.grey800}`,
      backgroundColor: ColorSet.grey900,
    }
  }
};

export const darkInputTheme2: IInputTheme = merge({}, darkInputTheme, {
  default: {
    backgroundColor: ColorSet.grey900,
    border: `1px solid ${ColorSet.grey900}`,
    disabled: {
      color: ColorSet.grey500,
      border: `1px solid ${ColorSet.grey900}`,
      backgroundColor: ColorSet.grey900,
    }
  },
});

export const lightInputTheme: IInputTheme = {
  default: {
    color: ColorSet.penumbra,
    placeholderColor: ColorSet.grey500,
    iconColor: ColorSet.grey500,
    iconHoverColor: ColorSet.penumbra,
    backgroundColor: ColorSet.white,
    border: `1px solid ${ColorSet.grey100}`,
    error: {
      color: ColorSet.alert400,
      border: `1px solid ${ColorSet.alert400}`,
    },
    focus: {
      border: `1px solid ${ColorSet.primary500}`,
    },
    disabled: {
      color: ColorSet.grey400,
      border: `1px solid ${ColorSet.grey100}`,
      backgroundColor: ColorSet.white,
    }
  }
};
