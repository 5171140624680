import { AbstractSuit } from '../../funcs/cards/AbstractSuit';

/**
 *
 */
export class Diamonds extends AbstractSuit {

  /**
   *
   */
  constructor(){
    super(27);
  }

}
