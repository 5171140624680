import React from 'react';
import styled from 'styled-components';
import { TAB_BUTTON_CLASS_NAME } from './theme';

export interface ITabsProps {
  value: number;
  onChange: (newValue: number) => void;
  className?: string;
}

export const Tabs: React.FC<ITabsProps> = ({ children, className, value, onChange }) => {
  const items = React.Children.map(children, (child, index) => {
    return (
      <ItemWrapper
        className={TAB_BUTTON_CLASS_NAME}
        isSelected={index === value}
        onMouseDown={() => onChange(index)}
        data-testid={`tab-item-${index}`}
      >
        {index === value ? <SelectionIndicator/> : null}
        {child}
      </ItemWrapper>
    );
  });

  return (
    <Container
      className={className}
    >
      {items}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
`;

interface IItemWrapper {
  readonly isSelected: boolean;
}

const ItemWrapper = styled.div<IItemWrapper>`
  width: calc(100% - 24px);
  position: relative;
  display: flex;
  flex-direction: row;
  color: ${props => props.theme.colorset.grey13};
  cursor: pointer;
  font: ${(props) => props.theme.typeset.body2Regular};
  font-size: 14px;
  line-height: 20px;
  padding: 6px 12px;

  &:hover {
    background-color: ${props => props.theme.colorset.grey05};
  }
`;

const SelectionIndicator = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 4px;
  background: ${props => props.theme.colorset.primary500};
  border-radius: 0px 4px 4px 0px;
`;
