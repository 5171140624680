import { Node } from '../../../elements/abstract/Node';
import { RealElement } from '../../../elements/abstract/RealElement';
import { BaseRadicalFormatter } from '../../../elements/formats/BaseRadicalFormatter';
import { WList } from '../../../elements/tokens/WList';
import { WRadical } from '../../../elements/tokens/WRadical';
import { WRational } from '../../../elements/tokens/WRational';
import { Environment } from '../../../expr/Environment';
import { TokensImporter } from '../../../expr/conversion/input/TokensImporter';
import { Skeleton } from '../../../expr/manipulation/Skeleton';
import { AbstractRule } from '../../../expr/manipulation/rules/AbstractRule';
import { Divide } from '../../../funcs/arithmetic/Divide';

/**
 *
 */
export class RadicalRationalBase extends AbstractRule {

  /**
   *
   */
  constructor() {

    super(false, false);
  }

  /**
   *
   */
  public applyNode(node:Node, stateMode:number, env:Environment):Node{
    const s:string = Skeleton.createSkeleton(node, null, 2);

    let baseR:WRational = null;
    let indexR:RealElement = null;

    if(s === '√(r)'){
      if(!env.options.preserveRadicals){
        return null;
      }
      baseR = <WRational>node.childs[1].value ;
      indexR = env.culture.createNumber(2);
    }else if(s === 'ⁿ√((r,n))'){
      if(!env.options.preserveRadicals){
        return null;
      }
      baseR = <WRational>(<WList>node.childs[1].value ).getItemAt(0) ;
      indexR = <RealElement>(<WList>node.childs[1].value ).getItemAt(1) ;
      indexR = indexR.isNaturalNumber() ? indexR : null;
    }

    if(!baseR){
      return null;
    }
    if(!indexR){
      return null;
    }

    const coef1:RealElement = env.culture.createNumber(1);
    const format:BaseRadicalFormatter = env.culture.formats.radicalFormatImpl;

    return TokensImporter.importTokens(
      [
        new WRadical(env.culture.createNumber(baseR.numerator), indexR, coef1, format),
        Divide.getInstance(),
        new WRadical(env.culture.createNumber(baseR.denominator), indexR, coef1, format)
      ], env);
  }

}
