import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WExpression } from '../../elements/tokens/WExpression';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class HideParenthesis extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 1){
      return args.expectingArguments(1, 1);
    }

    const expression:WExpression = args.getExpression(0);
    if(!expression){
      return null;
    }

    let value:string = expression.rawExpression;
    value = value.split('(').join('').split(')').join('');

    return args.env.createExpression(value);
  }

}
