import { Point } from '../../../../js/geom/Point';

import { ContentElement } from '../../../elements/abstract/ContentElement';
import { FunctionElement } from '../../../elements/abstract/FunctionElement';
import { WBoolean } from '../../../elements/tokens/WBoolean';
import { WLine } from '../../../elements/tokens/WLine';
import { WListOfPoints } from '../../../elements/tokens/WListOfPoints';
import { WListOfSegments } from '../../../elements/tokens/WListOfSegments';
import { WPoint } from '../../../elements/tokens/WPoint';
import { WPolygon } from '../../../elements/tokens/WPolygon';
import { WSegment } from '../../../elements/tokens/WSegment';
import { ArgumentsObject } from '../../../expr/ArgumentsObject';

/**
 * Sont coïncident?
 * L'ordre de définition des points n'est pas pris en compte.
 */
export class AreCoincident extends FunctionElement {

  public callReturnElement(args:ArgumentsObject):ContentElement {
    if (args.length !== 2) {
      return args.expectingArguments(2, 2);
    }
    if(args.getPoints(0) && args.getPoints(1)){
      return this.coincidentPointsL(args.getPoints(0), args.getPoints(1));
    }
    if(args.getSegments(0) && args.getSegments(1)){
      return this.coincidentSegmentsL(args.getSegments(0), args.getSegments(1));
    }
    if(args.getLine(0) && args.getLine(1)){
      return this.coincidentLines(args.getLine(0), args.getLine(1));
    }
    if(args.getPolygon(0) && args.getPolygon(1)){
      return this.coincidentPolygons(args.getPolygon(0), args.getPolygon(1));
    }
    return null;
  }

  /**
   * p()
   */
  private coincidentPoints(a:WPoint, b:WPoint):WBoolean{
    return WBoolean.parse(a.equalsTo(b));
  }

  /**
   * l()
   */
  private coincidentLines(a:WLine, b:WLine):WBoolean{
    return WBoolean.parse(a.equalsTo(b));
  }

  /**
   * s()
   */
  private coincidentSegments(a:WSegment, b:WSegment):WBoolean{
    const test:boolean =
      (a.a.equals(b.a) && a.b.equals(b.b)) ||
      (a.a.equals(b.b) && a.b.equals(b.a));

    return WBoolean.parse(test);
  }

  /**
   * lp()
   */
  private coincidentPointsL(
      a:WListOfPoints,
      b:WListOfPoints):WBoolean{
    return WBoolean.parse(a.unorderedEqualsTo(b));
  }

  /**
   * ls()
   */
  private coincidentSegmentsL(
      a:WListOfSegments,
      b:WListOfSegments):WBoolean{
    return WBoolean.parse(a.unorderedEqualsTo(b));
  }

  /**
   * f()
   */
  private coincidentPolygons(a:WPolygon, b:WPolygon):WBoolean{
    return WBoolean.parse(AreCoincident.check(a.vertices, b.vertices));
  }

  /**
   *
   */
  public static check(
      a:Point[],
      b:Point[]):boolean{

    if(a.length === b.length){

      let offset:number = 0;

      // Find a point in b that is equals to the first point of a
      for(let i:number = 0 ; i < b.length ; i++){
        if(a[0].equals(b[i])){
          offset = i;
        }
      }

      let ia:number;
      let ib:number;

      const numPoints:number = a.length;

      // Check points in the defined order
      let reverse:boolean = false; // If we find that two points are not equals, then break and turn on reverse flag.
      for(ia = 0 ; ia < numPoints ; ia++){
        ib = (ia + offset) % numPoints;
        if(!a[ia].equals(b[ib])){
          reverse = true;
          break;
        }
      }

      // Check points in the reverse order
      if(reverse){
        for(ia = 0 ; ia < numPoints ; ia++){
          ib = (numPoints - ia + offset) % numPoints;
          if(!a[ia].equals(b[ib])){
            return false;
          }
        }
      }

      return true;
    }

    return false;
  }

}
