import { Constants } from '../../core/Constants';
import { RealElement } from '../../elements/abstract/RealElement';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';

/**
 * Represents and angle measured in radians.
 */
export class WAngle extends RealElement {

  private _value:number;

  private _unit:string; // degree, radian

  constructor(value:number, unit:string) {
    super();
    this._value = value;
    this._unit = unit;
  }

  public get unit():string{
    return this._unit;
  }

  public toNumber():number{
    if(this._unit === 'degree'){
      return Constants.DEG_TO_RAD * this._value;
    }
    return this._value;
  }

  public toText(strict:boolean):string{
    return null;
  }

  public writeTo(exporter:IMarkupExporter = null):boolean{
    if(exporter){
      exporter.writeNumber(this._value);
    }
    return true;
  }

  public toAbsoluteValue():RealElement{
    return new WAngle(Math.abs(this._value), this._unit);
  }

  public toOpposite():RealElement{
    return new WAngle(-(this._value), this._unit);
  }

  public getType():string {
    return 'angle';
  }

}
