import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class NumberMachine extends FunctionElement {

  private mapNumber: (n: number) => number;

  constructor(mapNumber: (n: number) => number){
    super();
    this.mapNumber = mapNumber;
  }

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 1){
      return args.expectingArguments(1, 1);
    }

    if (args.getReal(0)) {
      return args.env.culture.createNumber(this.mapNumber(args.getReal(0).toNumber()));
    }

    return null;
  }

}
