import { Point } from '../../../js/geom/Point';
import { XMath } from '../../core/XMath';
import { XString } from '../../core/XString';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { TokenElement } from '../../elements/abstract/TokenElement';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';

/**
 *
 */
export class WBoundVector extends TokenElement {

  private _a:Point;

  private _b:Point;

  public get a():Point{return this._a.clone();}

  public get b():Point{return this._b.clone();}

  constructor(a:Point, b:Point){
    super();
    this._a = a;
    this._b = b;
  }

  public equalsTo(value:ContentElement):boolean{
    if(value instanceof WBoundVector){
      return  XMath.safeEquals(this.magnitude, (<WBoundVector>value ).magnitude) &&
          XMath.safeEquals(this.direction, (<WBoundVector>value ).direction);
    }
    return false;
  }

  public strictlyEqualsTo(value: ContentElement): boolean {
    if(value instanceof WBoundVector){
      return this.a.equals(value.a) &&
             this.b.equals(value.b);
    }
    return false;
  }

  public writeTo(exporter:IMarkupExporter = null):boolean{
    if(exporter){
      exporter.writer.appendText(this.toString());
    }
    return true;
  }

  public toString():string{
    return XString.substitute(
      '[BoundVector({0}; {1})]',
      this.a.toString(),
      this.b.toString());
  }

  public get dx():number{return this.b.x - this.a.x;}

  public get dy():number{return this.b.y - this.a.y;}

  public get magnitude():number{return Point.distance(new Point(0, 0), new Point(this.dx, this.dy));}

  public get direction():number{return Math.atan2(this.dy, this.dx);}

  /**
   *
   */
  public getType():string {
    return 'boundVector';
  }

}
