import { XMath } from '../../../core/XMath';
import { XString } from '../../../core/XString';
import { MmlWriter } from '../../../core/mml/MmlWriter';
import { RealElement } from '../../../elements/abstract/RealElement';
import { BaseIntervalFormatter } from '../../../elements/formats/BaseIntervalFormatter';
import { IMarkupExporter } from '../../../elements/markers/IMarkupExporter';
import { IntervalClosure } from '../../../elements/models/IntervalClosure';
import { WInfinity } from '../../../elements/tokens/WInfinity';
import { CultureInfo } from '../../../localization/CultureInfo';

export class IntervalFormatter extends BaseIntervalFormatter {

  constructor(culture:CultureInfo){
    super(culture);
  }

  public toLocaleString(
      lBound:RealElement | null,
      rBound:RealElement | null,
      closure:IntervalClosure,
      strict:boolean):string{

    let a:string = lBound ? lBound.toText(strict) : WInfinity.NEGATIVE.toText(strict);
    let b:string = rBound ? rBound.toText(strict) : WInfinity.POSITIVE.toText(strict);

    if(!strict){
      if(!a){
        a = lBound ? lBound.toText(false) : WInfinity.NEGATIVE.toText(false);
      }
      if(!b){
        b = rBound ? rBound.toText(false) : WInfinity.POSITIVE.toText(false);
      }

      // When strict off, if the bounds are real but doesn't return
      // string representation then write the numeric representation.
      if(!a){
        a = lBound ? this.culture.formatNumber(lBound.toNumber()) : String(Number.NEGATIVE_INFINITY);
      }
      if(!b){
        b = rBound ? this.culture.formatNumber(rBound.toNumber()) : String(Number.POSITIVE_INFINITY);
      }
    }

    if(!a || !b){
      return null;
    }

    if(this.isSingleton(lBound, rBound)){
      return '{' + a + '}';
    }

    const separator:string = this.culture.listFormatter.outputSeparator(a + b);

    return XString.substitute('{0}{3}{2} {4}{1}',
      this.getLeftFence(lBound, closure),
      this.getRightFence(rBound, closure),
      separator,
      a,
      b);
  }

  public writeTo(
      exporter:IMarkupExporter,
      lBound:RealElement | null,
      rBound:RealElement | null,
      closure:IntervalClosure):boolean{

    if(exporter){
      const w:MmlWriter = exporter.writer;
      w.beginFenced();
      w.addCommaCheck();

      if(this.isSingleton(lBound, rBound)){
        w.open = '{';
        w.close = '}';
        lBound.writeTo(exporter);
      }else{
        w.open = this.getLeftFence(lBound, closure);
        w.close = this.getRightFence(rBound, closure);
        if(lBound){
          lBound.writeTo(exporter);
        }else{
          WInfinity.NEGATIVE.writeTo(exporter);
        }
        if(rBound){
          rBound.writeTo(exporter);
        }else{
          WInfinity.POSITIVE.writeTo(exporter);
        }
      }

      w.separators = exporter.listSeparator(w.checkComma());
      w.endFenced();
    }
    return true;
  }

  private isSingleton(
      lBound:RealElement,
      rBound:RealElement):boolean{

    return lBound && rBound && XMath.safeEquals(lBound.toNumber(), rBound.toNumber());
  }

  public getLeftFence(lBound:RealElement | null, closure: IntervalClosure): string {
    if(lBound === null && this.useNotationWithoutFenceWhenInfinity) {
      return '';
    }
    return closure.getLeftFence(this.culture.configuration.intervalsNotation);
  }

  public getRightFence(rBound: RealElement | null, closure: IntervalClosure): string {
    if(rBound === null && this.useNotationWithoutFenceWhenInfinity) {
      return '';
    }
    return closure.getRightFence(this.culture.configuration.intervalsNotation);
  }

  private get useNotationWithoutFenceWhenInfinity(): boolean {
    return this.culture.configuration.intervalsNotation === 'bracketsAndParenthesesExceptInfinity' ||
      this.culture.configuration.intervalsNotation === 'bracketsAndReversedBracketsExceptInfinity';
  }

}
