import { Attributes } from '../../../elements/abstract/Attributes';
import { ContentElement } from '../../../elements/abstract/ContentElement';
import { ElementCodes } from '../../../elements/abstract/ElementCodes';
import { FunctionElement } from '../../../elements/abstract/FunctionElement';
import { Node } from '../../../elements/abstract/Node';
import { TokenElement } from '../../../elements/abstract/TokenElement';
import { Apply } from '../../../elements/constructs/Apply';
import { Declare } from '../../../elements/constructs/Declare';
import { Interval } from '../../../elements/constructs/Interval';
import { Lambda } from '../../../elements/constructs/Lambda';
import { List } from '../../../elements/constructs/List';
import { Piece } from '../../../elements/constructs/Piece';
import { Root } from '../../../elements/constructs/Root';
import { Set } from '../../../elements/constructs/Set';
import { IntervalClosure } from '../../../elements/models/IntervalClosure';
import { WId } from '../../../elements/tokens/WId';
import { Environment } from '../../../expr/Environment';
import { GroupClose } from '../../../expr/conversion/input/tempTokens/GroupClose';
import { GroupOpen } from '../../../expr/conversion/input/tempTokens/GroupOpen';
import { LeftBracket } from '../../../expr/conversion/input/tempTokens/LeftBracket';
import { QuestionMark } from '../../../expr/conversion/input/tempTokens/QuestionMark';
import { RightBracket } from '../../../expr/conversion/input/tempTokens/RightBracket';
import { Separator } from '../../../expr/conversion/input/tempTokens/Separator';
import { SetClose } from '../../../expr/conversion/input/tempTokens/SetClose';
import { SetOpen } from '../../../expr/conversion/input/tempTokens/SetOpen';
import { Minus } from '../../../funcs/arithmetic/Minus';
import { Sqrt } from '../../../funcs/arithmetic/Sqrt';
import { Times } from '../../../funcs/arithmetic/Times';
import { ArcCos } from '../../../funcs/trigonometry/ArcCos';
import { ArcSin } from '../../../funcs/trigonometry/ArcSin';
import { ArcTan } from '../../../funcs/trigonometry/ArcTan';
import { Cos } from '../../../funcs/trigonometry/Cos';
import { Degrees } from '../../../funcs/trigonometry/Degrees';
import { Radians } from '../../../funcs/trigonometry/Radians';
import { Sin } from '../../../funcs/trigonometry/Sin';
import { Tan } from '../../../funcs/trigonometry/Tan';
import { Recall } from '../../../elements/constructs/Recall';

/**
 * Build the expression tree.
 */
export class TreeWorker {

  public tokens:any[];

  private env:Environment;

  /**
   * At this point, the tokens array should contains only ContentElement objects.
   * May also contains some temporary tokens that could not be interpreted in the previous parser.
   * At the end of this phase, the tokens should contains only one Node instance,
   * representing the root of the expression tree.
   */
  constructor(
      tokens:any[],
      env:Environment) {

    this.tokens = tokens;
    this.env = env;
    this.makeTree();
    this.validate();
  }

  private validate():void{
    if(this.tokens.length === 1){
      const node:Node = this.leaf(this.tokens[0]);
      this.tokens[0] = node;

    }
  }

  private makeTree():void{
    while(true){
      let bracketIndex:number = -1;
      let beginIndex:number = 0; // At this point, parenthesis consistence has been verified
      let endIndex:number = this.tokens.length;
      let isExplicitGroup:boolean = false;
      let groupDepth:number = 0;

      // Find the innermost group
      for(let i:number = 0 ; i < this.tokens.length ; i++){
        if(this.isBracket(this.tokens[i])){
          if(bracketIndex === -1){
            bracketIndex = i;
            groupDepth++;
          }else{
            isExplicitGroup = true;
            beginIndex = bracketIndex;
            endIndex = i + 1;
            bracketIndex = -1;
            break;
          }
        }else if(this.isGroupOpening(this.tokens[i])){
          isExplicitGroup = true;
          beginIndex = i;
          groupDepth++;
          bracketIndex = -1;
        }else if(this.isGroupClosing(this.tokens[i])){
          endIndex = i + 1;
          break;
        }
      }

      // Determine the type of grouping
      let groupType:number; // 0: parenthesis, 1: set, 2: interval
      if(isExplicitGroup){
        const o:any = this.tokens[beginIndex];
        const w:any = this.tokens[endIndex - 1];
        if(o instanceof GroupOpen){
          groupType = 0;
        }
        if(o instanceof SetOpen){
          groupType = 1;
        }
        if(o instanceof LeftBracket && w instanceof RightBracket){
          groupType = 2; // []
        }
        if(o instanceof LeftBracket && w instanceof LeftBracket){
          groupType = 3; // [[
        }
        if(o instanceof RightBracket && w instanceof RightBracket){
          groupType = 4; // ]]
        }
        if(o instanceof RightBracket && w instanceof LeftBracket){
          groupType = 5; // ][
        }
      }else{
        groupType = 0;
      }

      const isParenthesisGroup:boolean = groupType === 0;
      const isAuthoringGroup:boolean = isExplicitGroup && isParenthesisGroup;

      // Try to reduce the group to a single element
      let group:any[] = this.tokens.slice(beginIndex, endIndex);
      group = this.collapseGroup(group, isAuthoringGroup, groupDepth);

      if(isParenthesisGroup){ // additional manipulation depending on the type of grouping
        this.collapseParenthesisGroup(group);
      }else{
        this.collapseSetOrInterval(group, groupType);
      }

      const offset:number = group.length === 0 ? -1 : 0;

      let p:any[] = [beginIndex, endIndex - beginIndex + offset];
      p = p.concat(group);
      this.tokens.splice.apply(this.tokens, p);

      if(beginIndex > 0 && isParenthesisGroup){ // need token before group
        this.beforeGroupCollapse(beginIndex, group.length === 0);
      }

      if(this.tokens.length === 1){
        // only one token left, it is the root node
        break;
      }else if(group.length === 0){
        continue;
      }else if(group.length === 1){
        // the subgroup parsing has gone well, we continue to the next group
        group[0].isExplicitGroup = isAuthoringGroup;
        continue;
      }

      break;
    }
  }

  private intervalClosure(
      groupType:number):IntervalClosure{

    switch(groupType){
      case 2: return IntervalClosure.CLOSED;
      case 3: return IntervalClosure.CLOSED_OPEN;
      case 4: return IntervalClosure.OPEN_CLOSED;
      case 5: return IntervalClosure.OPEN;
    }
    return null;
  }

  private collapseParenthesisGroup(
      groupTokens:any[]):void{

    if(groupTokens.length > 1){
      this.tryGroupConstruct(groupTokens, 0);
    }else if(groupTokens.length === 0){
      groupTokens[0] = new Node(new List()); // Empty list
    }
  }

  private collapseSetOrInterval(
      groupTokens:any[],
      groupType:number):void{

    if(groupTokens.length > 1){
      this.tryGroupConstruct(groupTokens, groupType);
    }else if(groupTokens.length === 1){
      // Build a set with one element or a singleton
      groupTokens[0] = new Node(groupType === 1 ? new Set() : new Interval(this.intervalClosure(groupType)), this.leaf(groupTokens[0]));
    }else if(groupTokens.length === 0){
      // Build an empty set
      // TODO: is it valid to build an empty interval?
      groupTokens[0] = new Node(groupType === 1 ? new Set() : new Interval(this.intervalClosure(groupType)));
    }
  }

  /**
   * This construct should not work if two consecutives tokens are not separator tokens.
   * Extra separators are simply ignored.
   * The resulting list construct should have at least two elements.
   */
  private tryGroupConstruct(
      group:any[],
      groupType:number):void{

    let i:number;
    const elements:any[] = [];

    let separatorRequired:boolean = false;
    for(i = 0 ; i < group.length ; i++){
      if(separatorRequired){
        if(!(group[i] instanceof Separator)){
          return; // failed, a separator is needed between each ContentElement
        }
        separatorRequired = false;
      }else if(!(group[i] instanceof Separator)){
          separatorRequired = true;
          elements.push(group[i]);
        }
    }

    let node:Node;

    switch(groupType){
      case 0:
        if(elements.length > 1){
          node = new Node(new List()); // we build a list when there is at least two elements
        }
        break;
      case 1:
        if(elements.length > 0){
          node = new Node(new Set()); // we build a set when the fences are braces {} and when there is at leaset one element
        }
        break;
      case 2:
      case 3:
      case 4:
      case 5:
        // TODO: is it valid to build an empty interval?
        node = new Node(new Interval(this.intervalClosure(groupType)));
        break;
    }

    if(node){
      for(i = 0 ; i < elements.length ; i++){
        node.appendChild(this.leaf(elements[i]));
      }
      group.splice(0, group.length, node);
    }
  }

  /**
   * If the token before a group is a function(sin, cos, ...),
   * a number, a symbol then it is considered as an insivible times
   */
  private beforeGroupCollapse(
      index:number,
      emptyGroup:boolean):void{

    // index is the position of the argument on which to apply the function
    const previous:any = this.tokens[index - 1];
    let substitute:Node = null;

    if(previous instanceof FunctionElement){
      const func:FunctionElement = <FunctionElement>previous ;
      if(func instanceof Sqrt){
        substitute = new Node(new Apply(), this.leaf(func), this.leaf(this.tokens[index]));
      }else if((func.getAttributes() & Attributes.FUNCTION_MODEL) > 0){

        if(emptyGroup){
          substitute = new Node(new Apply(), this.leaf(func));
        }else{
          if(!this.env.useRadians){
            if(func instanceof Sin || func instanceof Cos || func instanceof Tan){
              // input must be normalized
              substitute =
                new Node(
                  new Apply(),
                  this.leaf(func),
                  new Node(
                    new Apply(),
                    new Node(new Radians()),
                    this.leaf(this.tokens[index])));
            }else if(func instanceof ArcSin || func instanceof ArcCos || func instanceof ArcTan){
              // output must be normalized
              substitute =
                new Node(
                  new Apply(),
                  new Node(new Degrees()),
                  new Node(
                    new Apply(),
                    this.leaf(func),
                    this.leaf(this.tokens[index])));
            }
          }

          if(!substitute){
            substitute = new Node(new Apply(), this.leaf(func), this.leaf(this.tokens[index]));
          }

        }
      }
    }else if(previous instanceof WId){
      substitute =
        new Node(
          new Declare(),
          this.leaf(previous),
          this.leaf(this.tokens[index]));
    }else if(previous instanceof TokenElement){
      substitute =
        new Node(
          new Apply(),
          this.leaf(Times.getInstance()),
          this.leaf(previous),
          this.leaf(this.tokens[index]));
    }else if(previous instanceof Lambda) {
      substitute = new Node((<Lambda>previous ), this.leaf(this.tokens[index]));
    }else if(previous instanceof Recall){
      substitute =
        new Node(
          new Apply(),
          this.leaf(previous),
          this.leaf(this.tokens[index]));
    }else if(previous instanceof Root){
      substitute = new Node((<Root>previous ), this.leaf(this.tokens[index]));
    }

    if(substitute){
      this.tokens.splice(index - 1, 2, substitute);
    }
  }

  private isGroupOpening(value:any):boolean{
    return 	value instanceof GroupOpen ||
        value instanceof SetOpen;
  }

  private isBracket(value:any):boolean{
    return 	value instanceof LeftBracket ||
        value instanceof RightBracket;
  }

  private isGroupClosing(value:any):boolean{
    return 	value instanceof GroupClose ||
        value instanceof SetClose;
  }

  /**
   * authoringGroup: this is a group created using the parenthesis by the author
   */
  private collapseGroup(
      group:any[],
      authoringGroup:boolean,
      groupDepth:number):any[]{

    // Remove group start and end markers
    if( this.isGroupOpening(group[0]) || this.isBracket(group[0])){
      group.splice(0, 1);
    }

    if( this.isGroupClosing(group[group.length - 1]) || this.isBracket(group[group.length - 1])){
      group.splice(group.length - 1, 1);
    }

    while(true){

      // (1, 2, 4)_0 --> 1
      if(this.collapseFirstOccurence1(group, 17, ElementCodes.OP_ITEM)){
        continue;
      }

      // 1^2
      const po:number = this.find(group, ElementCodes.OP_POWER);
      if(po !== -1){
        if(this.collapseBinary(group, po, 15)){
          continue;
        }
      }

      // -1
      let um:number = group.length;
      while(true){
        um = this.reverseFind(group, ElementCodes.OP_SUBTRACTION, um);
        if(um !== -1){
          this.collapseUnaryMinus(group, um, 14);
          um--;
          continue;
        }
        break;
      }

      // invisible times
      if(this.collapseInvisibleTimes(group, 13)){
        continue;
      }

      // 1/2
      let ra:number = 0;
      while(true){
        ra = this.find(group, ElementCodes.OP_DIVISION, ra);
        if(ra !== -1){
          if(group[ra].other === '/'){
            this.collapseBinary(group, ra, 12);
          }else{
            ra++;
          }
          continue;
        }
        break;
      }

      // multiplicative 1÷2 ou 1*2
      if(this.collapseFirstOccurenceN(group, 11, [ElementCodes.OP_MULTIPLICATION, ElementCodes.OP_DIVISION, ElementCodes.OP_QUOTIENT])){
        continue;
      }

      // additive
      if(this.collapseFirstOccurenceN(group, 10, [ElementCodes.OP_ADDITION, ElementCodes.OP_SUBTRACTION])){
        continue;
      }

      // ratios
      if(this.collapseFirstOccurence1(group, 9, ElementCodes.OP_COLON)){
        continue;
      }

      // relational
      if(this.collapseFirstRelational(group, 8)){
        continue;
      }

      // logical
      if(this.collapseFirstOccurence1(group, 7, ElementCodes.OP_AND)){
        continue;
      }
      if(this.collapseFirstOccurence1(group, 6, ElementCodes.OP_OR)){
        continue;
      }

      // set operations
      if(this.collapseFirstOccurence1(group, 5, ElementCodes.OP_SETS_INTERSECTION)){
        continue;
      }
      if(this.collapseFirstOccurenceN(group, 4, [ElementCodes.OP_SETS_UNION, ElementCodes.OP_SETS_DIFFERENCE, ElementCodes.OP_SETS_SYMMETRIC_DIFFERENCE])){
        continue;
      }

      // set logical
      if(this.collapseFirstOccurenceN(group, 3, [ElementCodes.OP_ELEMENT_OF, ElementCodes.OP_NOT_ELEMENT_OF])){
        continue;
      }

      // 1..10 --> {1, 2, 3, 4, 5, 6, 7, 8, 9, 10}
      if(this.collapseFirstOccurence1(group, 2, ElementCodes.OP_CONSECUTIVE_INTEGERS)){
        continue;
      }

      // polynomial operations
      if(this.collapseFirstOccurence1(group, 1, ElementCodes.OP_COMPOSITION)){
        continue;
      }

      // ?
      if(this.collapseConditional(group)){
        continue;
      }

      break; // always break if the pass has not been handled by a case
    }

    if(group.length === 1){
      const node:Node = this.leaf(group[0]);
      node.isExplicitGroup = authoringGroup;
      node.groupDepth = groupDepth;
      group[0] = node;
    }

    return group;
  }

  private collapseConditional(
      group:any[]):boolean{

    let i:number = -1;

    for(let k:number = 0 ; k < group.length ; k++){
      if(group[k] instanceof QuestionMark){
        i = k;
        break;
      }
    }

    if(i !== -1){
      if(i === 0){
        return false;
      }
      if(i === group.length - 1){
        return false;
      }

      const node:Node =
        new Node(
          new Piece(),
          this.leaf(group[i + 1]), // value
          this.leaf(group[i - 1])); // condition

      group.splice(i - 1, 3, node);
      return true;
    }

    return false;
  }

  private collapseInvisibleTimes(
      group:any[],
      priority:number):boolean{

    let i:number = this.find(group, ElementCodes.OP_MULTIPLICATION, 0);
    while(i !== -1){
      const op:Times = group[i];
      if(op.isInvisibleTimes){
        this.collapseBinary(group, i, priority);
        return true;
      }
      i = this.find(group, ElementCodes.OP_MULTIPLICATION, i + 1);
    }
    return false;
  }

  private collapseFirstRelational(
      group:any[],
      priority:number):boolean{

    return this.collapseFirstOccurenceN(
      group,
      priority,
      [
        ElementCodes.OP_EQUAL,
        ElementCodes.OP_NOT_EQUAL,
        ElementCodes.OP_ALMOST_EQUAL,
        ElementCodes.OP_LESS_THAN,
        ElementCodes.OP_LESS_THAN_OR_EQUAL,
        ElementCodes.OP_GREATER_THAN,
        ElementCodes.OP_GREATER_THAN_OR_EQUAL
      ]);
  }

  private collapseFirstOccurence1(
      group:any[],
      priority:number,
      searchCode:string):boolean{

    return this.collapseFirstOccurenceN(group, priority, [searchCode]);
  }

  private collapseFirstOccurenceN(
      group:any[],
      priority:number,
      searchCodes:any[]):boolean{

    let index:number = Number.MAX_SAFE_INTEGER;
    for(let i:number = 0 ; i < searchCodes.length ; i++){
      const k:number = this.find(group, searchCodes[i]);
      if(k !== -1){
        index = Math.min(index, k);
      }
    }

    if(index !== Number.MAX_SAFE_INTEGER){
      return this.collapseBinary(group, index, priority);
    }

    return false;
  }

  private collapseUnaryMinus(
      group:any[],
      index:number,
      priority:number):boolean{ // returns true if the operation succeed

    let left:boolean = false;
    let right:boolean = false;

    // Assertion, to verify
    // If left does not produce a result but right does then its a unary minus
    if(index === 0){
      left = true;
    }
    if(group[index - 1] instanceof FunctionElement){
      left = true;
    }
    if(group[index - 1] instanceof Separator){
      left = true;
    }
    if(group[index - 1] instanceof QuestionMark){
      left = true;
    }
    if(!(group[index + 1] instanceof FunctionElement)){
      right = true;
    }

    if(left && right){
      const node:Node = new Node(new Apply(), this.leaf(group[index]), this.leaf(group[index + 1]));
      node.priority = priority;
      group.splice(index, 2, node);
      return true;
    }

    return false;
  }

  private collapseBinary(
      group:any[],
      index:number,
      priority:number):boolean{ // index >= 1

    if(index > 0 && index < group.length){
      let removeCount:number;
      const node:Node =
        new Node(new Apply(),
          this.leaf(group[index]),
          this.leaf(group[index - 1]));

      node.priority = priority;

      if(group[index+1] instanceof Minus){ // 3+-2
        removeCount = 4;
        const minus:Node =
          new Node(new Apply(),
            this.leaf(group[index+1]),
            this.leaf(group[index+2]));
        node.appendChild(minus);
      }else{ // 3+2
        removeCount = 3;
        node.appendChild(this.leaf(group[index + 1]));
      }

      group.splice(index - 1, removeCount, node);

      return true;
    }
    throw new Error('Error1');
  }

  // ***************************************************************************
  private find(
      group:any[],
      searchCode:string,
      beginIndex:number = 0):number{

    for(let j:number = beginIndex ; j < group.length ; j++){
      if(group[j] instanceof ContentElement){
        const item:ContentElement = <ContentElement>group[j] ;
        if(item.getElementCode() === searchCode){
          return j;
        }
      }
    }

    return -1;
  }

  private reverseFind(
      group:any[],
      searchCode:string,
      beginIndex:number = -1):number{

    for(let j:number = beginIndex ; j >= 0 ; j--){
      if(group[j] instanceof ContentElement){
        const item:ContentElement = <ContentElement>group[j] ;
        if(item.getElementCode() === searchCode){
          return j;
        }
      }
    }

    return -1;
  }

  /**
   * Wraps a ContentElement item into a
   * Node if it is not currently a node.
   */
  private leaf(value:any):Node{
    if(value instanceof Node){
      return <Node>value ;
    }
    if(value instanceof ContentElement){
      const element:ContentElement = <ContentElement>value ;
      const node:Node = new Node(element);
      if(element.tempClass){
        node.className = element.tempClass;
        element.tempClass = null;
      }
      return node;
    }
    throw new Error('Error2');
  }

}
