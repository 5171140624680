import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import {
  ContextMenuItem,
  EllipsisButton,
  TextArea,
  Input,
  DropdownAnchorContainer,
  DropdownSelectedItemWrapper,
  Checkbox,
  Button,
  XCircleIcon,
  ConfirmationModal,
  DROPDOWN_CONTENT_CLASSNAME,
} from '@scolab/publisher-ui-kit';
import { FormattedMessage } from 'react-intl';
import { IContentTag } from '../../../../models/IContentTag';
import { SubjectSelector } from '../../../subject/SubjectSelector';
import { NAME_WIDTH, SUBJECT_WIDTH } from '../constants';

interface IContentTagListItemProps {
  contentTag: IContentTag;
  isChecked?: boolean;
  saveChangesImmediately?: boolean;
  dataTestId?: string;
  onChangeNameFr?: (contentTag: IContentTag, newName: string) => void;
  onChangeDescription?: (contentTag: IContentTag, newDescription: string) => void;
  onChangeSubject?: (contentTag: IContentTag, newSubjectId: number) => void;
  onDelete?: (contentTag: IContentTag) => void;
  onStartMerge?: () => void;
  onCheck?: (contentTag: IContentTag, isChecked: boolean) => void;
  onRemove?: (contentTag: IContentTag) => void;
}

interface IIContentTagListItemState {
  isEllipsisOpen: boolean;
  isDeletionModalOpen: boolean;
  localNameFr?: string;
  localDescription?: string;
}

@observer
export class ContentTagListItem extends React.Component<IContentTagListItemProps, IIContentTagListItemState> {

  constructor(props: IContentTagListItemProps) {
    super(props);
    this.state = {
      isEllipsisOpen: false,
      isDeletionModalOpen: false,
      localNameFr: null,
      localDescription: null,
    };
  }

  public render(): React.ReactNode {
    const {
      contentTag,
      isChecked,
      dataTestId,
      saveChangesImmediately,
      onChangeNameFr,
      onChangeDescription,
      onChangeSubject,
      onDelete,
      onStartMerge,
      onCheck,
      onRemove,
    } = this.props;
    const {
      isEllipsisOpen,
      isDeletionModalOpen,
    } = this.state;

    return (
      <Container
        data-testid={dataTestId}
      >
        {onCheck ? (
          <Checkbox
            checked={isChecked}
            onChange={this.localOnCheck}
          />
        ) : null}
        <StyledSubjectSelector
          hideLabel
          preventDynamicDirection
          selectedSubjectId={contentTag.subjectId}
          onChange={this.localOnChangeSubject}
          disabled={!onChangeSubject}
        />
        <StyledInput
          hasError={!this.nameFr}
          value={this.nameFr}
          onChange={this.localOnChangeNameFr}
          onBlur={saveChangesImmediately ? null : this.onBlurSaveNameFr}
          data-testid='NameFrInput'
          disabled={!onChangeNameFr}
        />
        <StyledTextArea
          value={this.description}
          onChange={this.localOnChangeDescription}
          onBlur={saveChangesImmediately ? null : this.onBlurSaveDescription}
          disabled={!onChangeDescription}
        />
        {(onDelete && onStartMerge) ? (
          <StyledEllipsisButton
            isOpen={isEllipsisOpen}
            onSetIsOpen={this.setIsEllipsisOpen}
            horizontalAlignment="left"
          >
            <ContextMenuItem
              label={<FormattedMessage id='common.delete' />}
              onClick={this.onOpenDeletionModal}
            />
            <ContextMenuItem
              label={<FormattedMessage id='contentTags.startMergingTags' />}
              onClick={onStartMerge}
            />
          </StyledEllipsisButton>
        ) : null}
        {onRemove ? (
          <Button
            icon
            onClick={this.localOnRemove}
            data-testid='RemoveListItem'
          >
            <XCircleIcon />
          </Button>
        ) : null}
        {isDeletionModalOpen ? (
          <ConfirmationModal
            isOpen
            onConfirm={this.localOnDelete}
            onCancel={this.onCloseDeletionModal}
            details={<FormattedMessage id='contentTags.irreversibleOperation' />}
            cancelLabel={<FormattedMessage id='common.cancel' />}
            confirmLabel={<FormattedMessage id='common.delete' />}
          >
            <FormattedMessage id='contentTags.confirmDeletion' />
          </ConfirmationModal>
        ) : null}
      </Container>
    );
  }

  private get nameFr(): string {
    return this.state.localNameFr ?? this.props.contentTag.name.fr ?? '';
  }

  private get description(): string {
    return this.state.localDescription ?? this.props.contentTag.description ?? '';
  }

  private localOnChangeDescription = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const {
      contentTag,
      onChangeDescription,
      saveChangesImmediately,
    } = this.props;

    if (saveChangesImmediately) {
      onChangeDescription(contentTag, event.target.value);
    } else {
      this.setState({
        localDescription: event.target.value,
      });
    }
  }

  private onBlurSaveDescription = () => {
    const {
      contentTag,
      onChangeDescription,
    } = this.props;
    if (this.description !== contentTag.description) {
      onChangeDescription(contentTag, this.description);
      this.setState({
        localDescription: null,
      });
    }
  }

  private localOnChangeNameFr = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      contentTag,
      onChangeNameFr,
      saveChangesImmediately,
    } = this.props;

    if (saveChangesImmediately) {
      onChangeNameFr(contentTag, event.target.value);
    } else {
      this.setState({
        localNameFr: event.target.value,
      });
    }
  }

  private onBlurSaveNameFr = () => {
    const {
      contentTag,
      onChangeNameFr,
    } = this.props;
    if (this.nameFr && this.nameFr !== contentTag.name.fr) {
      onChangeNameFr(contentTag, this.nameFr);
      this.setState({
        localNameFr: null,
      });
    }
  }

  private localOnChangeSubject = (subjectId: number) => {
    const {
      contentTag,
      onChangeSubject,
    } = this.props;
    if (subjectId !== contentTag.subjectId) {
      onChangeSubject(contentTag, subjectId);
    }
  }

  private localOnDelete = () => {
    const {
      contentTag,
      onDelete,
    } = this.props;
    onDelete(contentTag);
  }

  private localOnRemove = (event: React.MouseEvent) => {
    const {
      contentTag,
      onRemove,
    } = this.props;
    onRemove(contentTag);
  }

  private localOnCheck = (isChecked: boolean) => {
    const {
      contentTag,
      onCheck,
    } = this.props;
    onCheck(contentTag, isChecked);
  }

  private setIsEllipsisOpen = (isOpen: boolean) => {
    this.setState({
      isEllipsisOpen: isOpen,
    });
  }

  private onOpenDeletionModal = () => {
    this.setState({
      isDeletionModalOpen: true,
    });
  }

  private onCloseDeletionModal = () => {
    this.setState({
      isDeletionModalOpen: false,
      isEllipsisOpen: false,
    });
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  margin: 4px 0;
`;

const StyledInput = styled(Input)`
  width: ${NAME_WIDTH}px;
  align-self: stretch;
  align-items: stretch;
  height: unset;
  min-height: 32px;
  box-sizing: border-box;
`;

const StyledTextArea = styled(TextArea)`
  flex: 1;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`;

const StyledSubjectSelector = styled(SubjectSelector)`
  width: ${SUBJECT_WIDTH}px;
  align-self: stretch;

  ${DropdownAnchorContainer} {
    align-self: stretch;
  }

  ${DropdownSelectedItemWrapper} {
    height: 54px;
  }

  .${DROPDOWN_CONTENT_CLASSNAME} {
    min-height: 83px;
  }
`

const StyledEllipsisButton = styled(EllipsisButton)`
  svg {
    height: 24px;
    width: 24px;
  }
`;
