import { MathError } from '../../core/MathError';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WBoolean } from '../../elements/tokens/WBoolean';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Evaluate } from '../../expr/manipulation/Evaluate';

/**
 * Applique une fonction à un nombre initial tant que la condition de fin
 * n'est pas rencontrée. Le dernier nombre est retourné comme résultat.
 */
export class While extends FunctionElement {

  /**
   *
   */
  private static SAFE_BREAK:number = 999;

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 3){
      return args.expectingArguments(3, 3);
    }

    if(args.getAnonymousFunction(0) && args.getReal(1) && args.getAnonymousFunction(2)) {
      return this.doWhile(
        new Evaluate(args.getAnonymousFunction(0), args.env),
        args.getReal(1),
        new Evaluate(args.getAnonymousFunction(2), args.env));
    }
    return null;
  }

  /**
   *
   */
  private doWhile(
      map:Evaluate,
      seedArg:RealElement,
      condition:Evaluate):RealElement{

    let seed = seedArg;
    let k:number = 0;
    let test:WBoolean = WBoolean.parseElement(condition.evaluate1(seed));
    if(!test){
      return null;
    }

    while(test.toBoolean()){
      k++;
      if(k > While.SAFE_BREAK){
        throw new MathError('Possible infinite loop');
      }

      seed = RealElement.parseElement(map.evaluate1(seed));
      if(!seed){
        return null;
      }

      test = WBoolean.parseElement(condition.evaluate1(seed));
      if(!test){
        return null;
      }
    }

    return seed;
  }

}
