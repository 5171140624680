import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';
import { Point } from '../../../js/geom/Point';

/**
 *
 */
export class ShapesPattern extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 1){
      return args.expectingArguments(1, 1);
    }

    if (args.getMatrix(0)) {
      return this.transform(args.getMatrix(0), args.env);
    }
    return null;
  }

  /**
   *
   */
  private transform(
      value:WMatrix,
      env:Environment):WMatrix{

    let i:number;
    const o:RealElement[] = [];
    for(i = 0 ; i < value.rows * value.columns ; i++){
      o.push(null);
    }

    let s:number = 0;
    for(let r:number = 0 ; r < value.rows ; r++){
      for(let c:number = 0 ; c < value.columns ; c++){
        const j:number = r * value.columns + c;

        if(o[j] != null){
          continue;
        }

        let n:RealElement = value.valueAt(r, c);
        if(n.toNumber() === 0){
          o[j] = n;
        }else{
          s++;
          o[j] = env.culture.createNumber(s);

          const z:Point[] = [];

          if(c > 0){
            z.push(new Point(0, -1));
          }
          if(r > 0){
            z.push(new Point(-1, 0));
          }
          if(c < value.columns - 1){
            z.push(new Point(0, 1));
          }
          if(r < value.rows - 1){
            z.push(new Point(1, 0));
          }

          while(z.length > 0){
            const p:Point = z.pop();
            const k:number = (r + p.x) * value.columns + (c + p.y);
            if(o[k] != null){
              continue;
            }

            n = value.valueAt(r + p.x, c + p.y);
            if(n.toNumber() !== 0){
              o[k] = o[j];

              if(c + p.y > 0){
                z.push(p.add(new Point(0, -1)));
              }
              if(r + p.x > 0){
                z.push(p.add(new Point(-1, 0)));
              }
              if(c + p.y < value.columns - 1){
                z.push(p.add(new Point(0, 1)));
              }
              if(r + p.x < value.rows - 1){
                z.push(p.add(new Point(1, 0)));
              }
            }
          }
        }
      }
    }

    return new WMatrix(o, value.columns, value.formatter);
  }

}
