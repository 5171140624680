import { Point } from '../../../js/geom/Point';

/**
 *
 */
export class SegmentModel {

  public a:Point;

  public b:Point;

  /**
   *
   */
  constructor(
      a:Point,
      b:Point){

    this.a = a;
    this.b = b;
  }

}
