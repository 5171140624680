import { XRound } from './XRound';
import { XNumber } from './XNumber';

/**
 * Math class extension. Math functions defined to work
 * on actionscript privmitive datatypes.
 */
export class XMath {

  /**
   * The result of an addition can never have more decimals
   * than the maximum of decimals in it's operands.
   */
  public static safeAdd(a:number, b:number):number{
    const p:number = XNumber.decimals(a, b);
    const r:number = a + b;

    if(XNumber.decimals(r) > p){
      return XNumber.truncate(XRound.halfAway(r, p + 1), p);
    }

    // (216.87334683448714 + 49) - 216.87334683448714 --> 49.00000000000003
    /*
    if((a + b) - a !== b) {
    }
    */

    return r;
  }

  /**
   * The result of a subtraction can never have more decimals
   * than the maximum of decimals in it's operands.
   */
  public static safeSubtract(a:number, b:number):number{
    const p:number = XNumber.decimals(a, b);
    const r:number = a - b;

    if(XNumber.decimals(r) > p){
      return XNumber.truncate(XRound.halfAway(r, p + 1), p);
    }

    return r;
  }

  /**
   * The result of a multiplication can never have more decimals
   * than the sum of the decimals in it's operands.
   */
  public static safeTimes(
      a:number,
      b:number):number{

    const p:number = XNumber.decimals(a) + XNumber.decimals(b);
    const r:number = a * b;
    if(XNumber.decimals(r) > p){
      const c:number = XRound.halfAway(r, p + 1);
      const d:number = XNumber.truncate(c, p);
      return d;
    }

    return r;
  }

  /**
   * The number of decimals in the result of a division can not be predicted,
   * so we try multiple methods to divide the two numbers and return
   * the first one that has less decimals than XRound.ROUND_NO_PRECISION_ERROR.
   *
   * If none of the method returns an adequate number then we use the default
   * division operator.
   */
  public static safeDiv(
      a:number,
      b:number):number{

    const div1:number = a / b;
    if(XNumber.decimals(div1) <= 10){
      return div1;
    }

    const div2:number = XMath.safeTimes(a, 1 / b);
    if(XNumber.decimals(div2) <= 10){
      return div2;
    }

    const p:number = XNumber.decimals(a, b);
    const div3:number = Math.round(a * (10 ** p)) / Math.round(b * (10 ** p));
    if(XNumber.decimals(div3) <= 10){
      return div3;
    }

    return div1;
  }

  /**
   *
   */
  public static safePow(base:number, exponent:number):number{
    let val:number = base ** exponent;

    if(isNaN(val)){
      return val;
    }

    const pVal:number = XNumber.decimals(val);
    const pBase = XNumber.decimals(base);

    let val2: number;
    let pVal2: number;

    if(XMath.isInteger(exponent)){
      if(exponent > 1){
        // for integer exponent, check precision of the output.
        const p1:number = pBase * exponent; // expected precision
        if(pVal > p1){
          val = XNumber.truncate(XRound.halfAway(val, p1 + 1), p1);
        }
      }else if(base === 10 && exponent < 0){
        // 10^-4 --> 0.00009999999999999999 dans Chrome
        val = Number(val.toFixed(Math.abs(exponent)));
      }
    }
    else if(exponent === 0.5){
      val2 = Math.sqrt(base);
      pVal2 = XNumber.decimals(val2);

      if(pVal > pVal2){
        val = val2;
      }
    } else if(exponent === 1 / 3){
      // This case and the previous case (0.5) could be generalized to a couple more nth-roots.
      // 	474.552^(1/3)
      // Looking for CubeRoot(base), n*n*n = base
      pVal2 = Math.ceil(pBase / 3);
      val2 = XNumber.truncate(XRound.halfAway(val, pVal2 + 1), pVal2);
      if(XMath.safeTimes(XMath.safeTimes(val2, val2), val2) === base){
        val = val2;
      }
    }

    return val;
  }

  /**
   * Fix problems like :
   * 2+sqrt(2)-sqrt(2)
   * 3+sqrt(2)-sqrt(2)
   *
   * @param value
   */
  public static safeSqrt(value: number): number {
    return Number(Math.sqrt(value).toFixed(15));
  }

  /**
   *
   */
  public static safeTan(value: number): number {
    return XRound.safeRound2(Math.tan(value));
    /*
    if(value === Math.PI / 4){
      return 1;
    } else if (value === -(Math.PI / 4)) {
      return -1;
    }
    return Math.tan(value);
    */
  }

  /**
   *
   */
  public static safeSin(value: number): number {
    return XRound.safeRound2(Math.sin(value));
    /*
    if(value === Math.PI * 5 / 6 || value === Math.PI / 6){
      return 0.5;
    }else if(value === -(Math.PI * 5 / 6) || value === -(Math.PI / 6)){
      return -0.5;
    }
    return Math.sin(value);
    */
  }

  /**
   *
   */
  public static safeCos(value: number): number {
    return XRound.safeRound2(Math.cos(value));
    /*
    if(value === Math.PI / 3 || value === -(Math.PI / 3)){
      return 0.5;
    }else if(value === Math.PI * 2 / 3 || value === -(Math.PI * 2 / 3)){
      return -0.5;
    }
    return Math.cos(value);
    */
  }

  /**
   *
   */
  public static safeRepeatedAddition(
      startValue:number,
      stepValue:number,
      repeat:number):number{

    const p:number = XNumber.decimals(startValue, stepValue);
    const r:number = startValue + stepValue * repeat;

    if(XNumber.decimals(r) > p){
      return XNumber.truncate(XRound.halfAway(r, p + 1), p);
    }

    return r;
  }

  /**
   *
   */
  public static safeEquals(
      a:number,
      b:number):boolean{

    return XRound.safeRound(a) === XRound.safeRound(b);
  }

  /**
   *
   */
  public static gcd(aArg:number, bArg:number):number{
    let a = Math.abs(aArg);
    let b = Math.abs(bArg);

    if(a === 0 || b === 0){
      return Math.max(a, b);
    }

    let t:number;
    if(a < b){
      t = a;
      a = b;
      b = t;
    }
    while(b !== 0){
      t = a % b;
      a = b;
      b = t;
    }
    return a;
  }

  /**
   *
   */
  public static lcm(aArg:number, bArg:number):number{
    const a = Math.abs(aArg);
    const b = Math.abs(bArg);
    return (a * b) / XMath.gcd(a, b);
  }

  /**
   *
   */
  public static divisors(a:number):any[]{
    const d:any[] = [];
    if(a >= 1){
      d.push(1);
      if(a >= 2){
        for(let i:number = 2 ; i <= a ; i++){
          if(a % i === 0){
            d.push(i);
          }
        }
      }
    }
    return d;
  }

  /**
   *
   */
  public static isInteger(
      value:number):boolean{

    if(isNaN(value)){
      return false;
    }
    return (value % 1 === 0);
  }

  /**
   *
   */
  public static isPerfectSquare(
      value:number):boolean{

    const s:number = Math.sqrt(value);
    return !isNaN(s) && XMath.isInteger(s);
  }

  /**
   *
   */
  public static isNaturalOddNumber(
      value:number):boolean{

    return value >= 0 && XMath.isInteger(value) && value % 2 === 1;
  }

  /**
   * Returns the largest perfect square from the specified number factors.
   * Will always return a number unless the specified number is 0.
   */
  public static largestPerfectSquareFactor(value:number):number{
    const d:any[] = XMath.divisors(value);
    for(let i:number = d.length - 1 ; i >= 0 ; i--){
      if(XMath.isInteger(Math.sqrt(d[i]))){
        return d[i];
      }
    }
    return -1;
  }

  /**
   * Returns the decimal expansion of a rational number in bracket notation
   * See http://en.wikipedia.org/wiki/Repeating_decimal
   * 7/12 --> 0.58(3)
   */
  public static longDiv(dividend:number, divisor:number):string{
    if(divisor === 0){
      throw new Error();
    }
    if(dividend === 0){
      return '0';
    }

    const integer:number = Math.floor(dividend / divisor);
    let remainder:number = dividend % divisor;

    if(remainder === 0){
      return integer.toString();
    }

    const decimals:any[] = [];
    const remainders:any[] = [];

    remainders.push(remainder);

    let repeating:boolean = false;

    do{
      remainder *= 10;
      const decimal:number = Math.floor(remainder / divisor);
      const ri:number = remainders.indexOf(remainder);

      if(ri !== -1){
        if(ri === 0){
          decimals.push(0);
        }
        repeating = true;
        break;
      }else{
        remainders.push(remainder);
        decimals.push(decimal);
        remainder %= divisor;
        if(remainder === 0){
          break;
        }
      }
    }while(true);

    if(repeating){
      const k:number = remainders.length - remainders.indexOf(remainder);
      decimals.splice(decimals.length - k, 0, '(');
      decimals.push(')');
    }

    return `${integer.toString()}.${decimals.join('')}`;
  }

  /**
   *
   */
  public static factorial(n:number):number{
    if(n === 0){
      return 1;
    }
    if(n === 1){
      return n;
    }
    return n * XMath.factorial(n - 1);
  }

  /**
   * Log with custom base.
   */
  public static logX(value:number, base:number):number {
    return XRound.safeRound(Math.log(value) / Math.log(base));
  }

}
