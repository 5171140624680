import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WList } from '../../elements/tokens/WList';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Ajoute des valeurs aux extrémités d'une liste ou d'une matrice.
 */
export class Pad extends FunctionElement {

  private left:boolean;

  private right:boolean;

  private top:boolean;

  private bottom:boolean;

  /**
   *
   */
  constructor(
      left:boolean,
      right:boolean,
      top:boolean,
      bottom:boolean){
    super();
    this.left = left;
    this.right = right;
    this.top = top;
    this.bottom = bottom;
  }

  /**
   * Ajoute un zéro au début et/ou à la fin de la liste.
   * Ajoute un zéro sur un ou tous les côtés de la matrice.
   * Ajoute un ou des zéros au début et/ou à la fin de la liste.
   * Ajoute un ou des zéros sur un ou tous les côtés de la matrice.
   * Ajoute une valeur spécifiée un certain nombre de fois au début et/ou à la fin de la liste.
   * Ajoute une valeur spécifiée un certain nombre de fois sur un ou tous les côtés de la matrice.
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length < 1 || args.length > 3){
      return args.expectingArguments(1, 3);
    }

    // matrix, repeat, fill
    // list, repeat, fill

    let repeat:number = 1;
    if(args.length > 1){
      if(args.getWholeNumber(1)){
        repeat = args.getWholeNumber(1).toNumber();
      }else{
        return null;
      }
    }

    const fill:RealElement =
      args.length > 2 ?
        args.getReal(2) :
        args.env.culture.createNumber(0);

    const list:WList = args.getReals(0);
    const matrix:WMatrix = args.getMatrix(0);

    if(list && repeat && fill){
      return this.padList(list, fill, this.left, this.right, repeat);
    }
    if(matrix && repeat && fill){
      return this.padMatrix(matrix, fill, this.left, this.right, this.top, this.bottom, repeat);
    }

    return null;
  }

  /**
   *
   */
  private padList(
      value:WList,
      fill:RealElement,
      left:boolean,
      right:boolean,
      repeat:number):WList{

    const o:ContentElement[] = value.items.concat();
    for(let i:number = 0 ; i < repeat ; i++){
      if(left){
        o.unshift(fill);
      }
      if(right){
        o.push(fill);
      }
    }
    return new WList(o, value.formatter2);
  }

  /**
   *
   */
  private padMatrix(
      value:WMatrix,
      fill:RealElement,
      left:boolean,
      right:boolean,
      top:boolean,
      bottom:boolean,
      repeat:number):WMatrix{

    const o:RealElement[] = value.values.concat();
    let columns:number = value.columns;
    let rows:number = value.rows;

    let r:number;
    let k:number;

    if(left){
      for(r = rows - 1 ; r >= 0 ; r--){
        for(k = 0 ; k < repeat ; k++){
          o.splice(r * columns, 0, fill);
        }
      }
      columns += repeat;
    }

    if(right){
      for(r = rows - 1 ; r >= 0 ; r--){
        for(k = 0 ; k < repeat ; k++){
          o.splice(r * columns + columns, 0, fill);
        }
      }
      columns += repeat;
    }

    if(top){
      for(k = 0 ; k < columns * repeat ; k++){
        o.unshift(fill);
      }
      rows+= repeat;
    }

    if(bottom){
      for(k = 0 ; k < columns * repeat ; k++){
        o.push(fill);
      }
      rows += repeat;
    }

    return new WMatrix(o, columns, value.formatter);
  }

}
