import { Point } from '../../../js/geom/Point';
import { IDictionary } from '../../../js/utils/IDictionary';
import { MathError } from '../../core/MathError';
import { XAngles } from '../../core/XAngles';
import { XGeom } from '../../core/XGeom';
import { IPrng } from '../../core/prng/IPrng';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { RandomPointGenerator } from '../../elements/factories/RandomPointGenerator';
import { WPoint } from '../../elements/tokens/WPoint';
import { WPolygon } from '../../elements/tokens/WPolygon';
import { SegmentsUtil } from '../../elements/utils/SegmentsUtil';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { RandomQuadrilateral } from '../../funcs/ctor/RandomQuadrilateral';
import { RandomTriangle } from '../../funcs/ctor/RandomTriangle';

/**
 *
 */
export class RandomPolygon extends FunctionElement {

  private static MIN_ANGLE:number = 20;

  private static MAX_ANGLE:number = 340;

  private static MAX_ATTEMPTS_INITIAL_TRIANGLE:number = 5;

  private static MAX_ATTEMPTS_CREATE_NON_REJECTED_POINT:number = 10;

  private static MAX_ATTEMPTS_INSERT_POINT:number = 50;

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 3){
      return args.expectingArguments(3, 3);
    }
    if (args.getPoint(0) && args.getPoint(1) && args.getReal(2)) {
      return this.polygon(args.getPoint(0),args.getPoint(1), args.getReal(2), args.prng);
    }
    return null;
  }

  /**
   *
   */
  private polygon(a:WPoint, b:WPoint, sides:RealElement, prng:IPrng):WPolygon{
    if(!sides.isWholeNumber()){
      return null;
    }

    const n:number = sides.toNumber();

    if(n < 3){
      throw new MathError('Invalid number of sides');
    }
    if(n > 9){
      throw new MathError('Number of sides is limited to 9');
    }

    let v:Point[];

    if(n === 3){
      v = RandomTriangle.createTriangle(a.toPoint(), b.toPoint(), prng);
      return v ? new WPolygon(v) : null;
    }
    if(n === 4){
      v = RandomQuadrilateral.createQuadrilateral(a.toPoint(), b.toPoint(), prng);
      return v ? new WPolygon(v) : null;
    }

    // 1. Generate a triangle
    // 2. Generate a point,
    // 	a. Insert it between the two points forming the closest segment
    //  b. Check that it forms a valid polygon, if it does not, store that point and redo step 2

    const rpg:RandomPointGenerator = new RandomPointGenerator(a.toPoint(), b.toPoint(), 2, prng);
    let triangleAttempts:number = 0;
    let pointsAttempts:number = 0;
    let insertAttempts:number = 0;

    do{
      triangleAttempts++;
      v = RandomTriangle.createTriangle(a.toPoint(), b.toPoint(), prng);
      if(!v){
        continue;
      }

      let rejectedMap:IDictionary = {};

      pointsAttempts = 0;
      insertAttempts = 0;

      while(v.length < n && insertAttempts < RandomPolygon.MAX_ATTEMPTS_INSERT_POINT){
        let p:Point = null;
        pointsAttempts = 0;

        do{
          p = rpg.next();
          pointsAttempts++;
        }while(rejectedMap.hasOwnProperty(p.toString()) && pointsAttempts < RandomPolygon.MAX_ATTEMPTS_CREATE_NON_REJECTED_POINT);

        if(rejectedMap.hasOwnProperty(p.toString())){
          insertAttempts++;
          continue;
        }

        const s:number = RandomPolygon.closestSegment(v, p);
        v.splice(s, 0, p);
        if(!RandomPolygon.validateNonColinear(v)){
          v.splice(s, 1);
          rejectedMap[p.toString()] = true;
          insertAttempts++;
        }else if(!RandomPolygon.validateAngles(v)){
          v.splice(s, 1);
          rejectedMap[p.toString()] = true;
          insertAttempts++;
        }else if(SegmentsUtil.selfIntersects(v, true)){
          v.splice(s, 1);
          rejectedMap[p.toString()] = true;
          insertAttempts++;
        }else{
          // Accept point
          rejectedMap = {};
          insertAttempts = 0;
        }
      }

      if(v.length === n){
        break; // polygon is valid
      }
    }while(triangleAttempts < RandomPolygon.MAX_ATTEMPTS_INITIAL_TRIANGLE);

    const polygon:WPolygon = v ? (v.length === n ? new WPolygon(v) : null) : null;
    if(!polygon){
      throw new MathError('Could not generate a polygon [1]');
    }
    return polygon;
  }

  /**
   * Returns the segment that is closest to p.
   */
  private static closestSegment(
      v:Point[],
      p:Point):number{

    let k:number = -1;
    let d:number;
    let i: number = 0;

    for(i = 0 ; i < v.length ; i++){
      const a:Point = v[i];
      const b:Point = v[i === v.length - 1 ? 0 : i + 1];
      const c:Point = Point.interpolate(a, b, 0.5);

      if(k === -1){
        k = i;
      }else if(Point.distance(p, c) < d){
        k = i;
      }

      if(k === i){
        d = Point.distance(p, c);
      }
    }

    return i;
  }

  /**
   *
   */
  private static validateAngles(v:Point[]):boolean{
    const angles:number[] = XAngles.interior(v);
    if(!angles){
      return false;
    }
    for(let i:number = 0 ; i < angles.length ; i++){
      const angle:number = angles[i];
      if(angle < RandomPolygon.MIN_ANGLE || angle > RandomPolygon.MAX_ANGLE){
        return false;
      }
    }
    return true;
  }

  /**
   *
   */
  private static validateNonColinear(v:Point[]):boolean{
    if(!v){
      return false;
    }
    if(v.length < 3){
      return false;
    }
    for(let p0:number = 0 ; p0 < v.length ; p0++){
      let p1:number = p0+1;
      let p2:number = p0+2;
      if(p1 >= v.length){
        p1 -= v.length;
      }
      if(p2 >= v.length){
        p2 -= v.length;
      }
      if(XGeom.colinear(v[p0], v[p1], v[p2])){
        return false;
      }
    }
    return true;
  }

}
