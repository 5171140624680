import { IDictionary } from '../../../../js/utils/IDictionary';

import { RealElement } from '../../../elements/abstract/RealElement';
import { Zeros } from '../../../elements/functions/Zeros';
import { AbstractNode } from '../../../elements/functions/tree/AbstractNode';
import { EmptyLeaf } from '../../../elements/functions/tree/EmptyLeaf';
import { NodeArithmetic } from '../../../elements/functions/tree/NodeArithmetic';
import { NodeCoefficient } from '../../../elements/functions/tree/NodeCoefficient';
import { NodeConstant } from '../../../elements/functions/tree/NodeConstant';
import { NodeGroup } from '../../../elements/functions/tree/NodeGroup';
import { NodeVariable } from '../../../elements/functions/tree/NodeVariable';
import { CultureInfo } from '../../../localization/CultureInfo';
import { IFunctionForm } from '../../../elements/functions/models/IFunctionForm';
import { AbstractFunctionForm } from '../../../elements/functions/models/AbstractFunctionForm';

/**
 * 7. fonction valeur absolue (forme canonique) f(x) = A |B(x - H)| + K
 */
export class TAbs extends AbstractFunctionForm {

  /**
   *
   */
  constructor(
      culture:CultureInfo,
      A:RealElement,
      B:RealElement,
      H:RealElement,
      K:RealElement){

    super(culture, A, B, null, null, null, null, H, K);
  }

  /**
   *
   */
  public map(value:number):number{
    return this.nA * Math.abs(this.nB * (value - this.nH)) + this.nK;
  }

  /**
   *
   */
  public get continuous():number{
    return 1;
  }

  /**
   *
   */
  public getZeros():Zeros{
    const z:Zeros = new Zeros();
    z.list = [];

    const nK0:boolean = this.nK === 0;
    if(nK0){
      z.list.push(this.nH);
    }else if(this.nK < 0 && this.nA > 0 || this.nK > 0 && this.nA < 0){
      z.list.push(this.nK / (this.nA * this.nB) + this.nH, -this.nK / (this.nA * this.nB) + this.nH);
    }
    return z;
  }

  /**
   *
   */
  public getRawTokens(parameters:IDictionary, varName:string):any[]{
    const o:any[] = [varName];
    if(parameters.hasOwnProperty('H')){
      o.unshift('(');
      o.push('−', this.getH(), ')');
    }
    if(parameters.hasOwnProperty('B')){
      o.unshift(this.getB());
    }
    o.unshift('|');
    o.push('|');
    if(parameters.hasOwnProperty('A')){
      o.unshift(this.getA());
    }
    if(parameters.hasOwnProperty('K')){
      o.push('+', this.getK());
    }
    return o;
  }

  public getSimplifyTokens(parameters:IDictionary, varName:string):any[] {
    const root:AbstractNode = ( new NodeArithmetic( NodeArithmetic.ADDITION ) )
      .setLeft(
        new NodeCoefficient( new NodeConstant( this.getA() ),
          new NodeGroup(
            new NodeCoefficient( new NodeConstant( this.getB() ),
              new NodeGroup( ( new NodeArithmetic( NodeArithmetic.SUBSTRACTION ) )
                .setLeft( new NodeVariable( varName ) )
                .setRight( new NodeConstant( this.getH() ) )
                , '(', ')' )
            )
            , '|', '|', true
          )
        )
      )
      .setRight(new NodeConstant(this.getK()));

    let simplified:AbstractNode =  root.simplify();

    if( simplified instanceof EmptyLeaf ) {
      simplified = new NodeConstant( this.getK() );
    }

    return simplified.getToken();
  }

  /**
   *
   */
  public copy(parameters:IDictionary):IFunctionForm{
    return new TAbs(
      this.culture,
      parameters.hasOwnProperty('A') ? parameters.A : this.A,
      parameters.hasOwnProperty('B') ? parameters.B : this.B,
      parameters.hasOwnProperty('H') ? parameters.H : this.H,
      parameters.hasOwnProperty('K') ? parameters.K : this.K);
  }

}
