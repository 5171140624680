import { CSSProperties } from 'react';
import {ColorSet, setHexColorTransparency} from '../../../themes/ColorSet';
import { TreeviewVariant } from './Treeview';

export interface ITreeviewTheme {
  color: CSSProperties['color'];
  backgroundColor: CSSProperties['backgroundColor'];
  open: {
    backgroundColor: CSSProperties['backgroundColor'];
  };
  hover: {
    backgroundColor: CSSProperties['backgroundColor'];
  };
  selected: {
    [TreeviewVariant.header]: {
      backgroundColor: CSSProperties['backgroundColor'];
    },
    [TreeviewVariant.listItem]: {
      border: CSSProperties['border'];
      backgroundColor: CSSProperties['backgroundColor'];
      color: CSSProperties['color'];
    },
    [TreeviewVariant.folder]: {
      backgroundColor: CSSProperties['backgroundColor'];
    }
  };
}

export const darkTreeviewTheme: ITreeviewTheme = {
  color: ColorSet.white,
  backgroundColor: ColorSet.grey900,
  open: {
    backgroundColor: ColorSet.grey800,
  },
  hover: {
    backgroundColor: ColorSet.grey700,
  },
  selected: {
    [TreeviewVariant.header]: {
      backgroundColor: ColorSet.grey800,
    },
    [TreeviewVariant.listItem]: {
      border: `1px solid ${ColorSet.primary500}`,
      backgroundColor: setHexColorTransparency(ColorSet.primary500, 0.2),
      color: ColorSet.white,
    },
    [TreeviewVariant.folder]: {
      backgroundColor: ColorSet.grey800,
    }
  },
};

export const lightTreeviewTheme: ITreeviewTheme = {
  color: ColorSet.grey900,
  backgroundColor: ColorSet.white,
  open: {
    backgroundColor: ColorSet.grey025,
  },
  hover: {
    backgroundColor: ColorSet.grey050,
  },
  selected: {
    [TreeviewVariant.header]: {
      backgroundColor: ColorSet.grey025,
    },
    [TreeviewVariant.listItem]: {
      border: `1px solid ${ColorSet.primary500}`,
      backgroundColor: ColorSet.primary050,
      color: ColorSet.primary700,
    },
    [TreeviewVariant.folder]: {
      backgroundColor: ColorSet.grey025,
    }
  },
};
