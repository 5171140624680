import { ContentElement } from '../../elements/abstract/ContentElement';
import { ListElement } from '../../elements/abstract/ListElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { BaseListFormatter } from '../../elements/formats/BaseListFormatter';
import { BaseListNFormatter } from '../../elements/formats/BaseListNFormatter';
import { WPoint } from '../../elements/tokens/WPoint';

/**
 * Wrapper class for an Array of numbers.
 */
export class WList extends ListElement {
  public get formatter2(): BaseListNFormatter {
    return this.getFormat() as BaseListNFormatter;
  }

  constructor(reals: ContentElement[], formatter: BaseListNFormatter) {
    super(reals, formatter);
  }

  public isNumeric(): boolean {
    return true;
  }

  public useUnambiguousItemSeparator(): boolean {
    return true;
  }

  public acceptElement(element: ContentElement): boolean {
    return element.getListItemCode() === RealElement.getListItemCode();
  }

  public getTypedItemAt(index: number): RealElement {
    return this.getItemAt(index) as RealElement;
  }

  public getValueAt(index: number): number {
    return this.getTypedItemAt(index).toNumber();
  }

  public toNumbersV(): number[] {
    const items: number[] = [];
    for (let i = 0; i < this.count; i++) {
      items.push(this.getValueAt(i));
    }
    return items;
  }

  public toNumbers(): any[] {
    const items: any[] = [];
    for (let i = 0; i < this.count; i++) {
      items.push(this.getValueAt(i));
    }
    return items;
  }

  public toReals(): RealElement[] {
    const items: RealElement[] = [];
    for (let i = 0; i < this.count; i++) {
      items.push(this.getTypedItemAt(i));
    }
    return items;
  }

  public map(callback: Function): WList {
    const items: ContentElement[] = [];
    for (let i = 0; i < this.count; i++) {
      items.push(callback(this.items[i]));
    }
    return new WList(items, this.formatter2);
  }

  /**
   * Return a point object if it contains two values
   * Return a number if it contains one value
   * Return the list as a list of numbers if the list contains more than two elements
   */
  public narrow(): ContentElement {
    if (this.count === 2) {
      return new WPoint(
        this.getTypedItemAt(0),
        this.getTypedItemAt(1),
        this.formatter.culture.formats.pointFormatImpl);
    }
    return super.narrow();
  }

  public equalsTo(value: ContentElement): boolean {
    if (value instanceof WList) {
      return super.equalsToImpl(value, false);
    }
    return false;
  }

  public strictlyEqualsTo(value: ContentElement): boolean {
    if (value instanceof WList) {
      return super.strictlyEqualsToImpl(value);
    }
    return false;
  }

  protected compareElements(a: ContentElement, b: ContentElement): number {
    return RealElement.compare(a as RealElement, b as RealElement);
  }

  protected createList(items: ContentElement[], formatter: BaseListFormatter): ListElement {
    return new WList(items, formatter as BaseListNFormatter);
  }

  public toString(): string {
    return this.toNumbersV().join(', ');
  }

  public static createFromReals(value: RealElement[], formatter: BaseListNFormatter): WList {
    if (value == null) {
      return null;
    }

    const items: ContentElement[] = [];
    for (let i = 0; i < value.length; i++) {
      items.push(value[i]);
    }

    return new WList(items, formatter);
  }

  public getType(): string {
    return 'reals';
  }
}
