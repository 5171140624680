import { IHistoryEvent } from '../../models/history/IHistoryEvent';
import { HistoryEventEntityType } from '../../models/history/HistoryEventEntityType';
import { api } from '../../utils/fetch';

export const getEntityHistory = async (entityType: HistoryEventEntityType,
                                       entityId: number | null,
                                       entityGuid: string | null) => {

  const data = new URLSearchParams();
  data.append('entityType', entityType.toString());

  if (entityId) {
    data.append('entityId', entityId.toString());
  }

  if (entityGuid) {
    data.append('entityGuid', entityGuid);
  }

  const response = await api<ReadonlyArray<IHistoryEvent>>('/Publisher/History/GetEntityHistory', { method: 'post', body: data });
  const historyEvents = await response.json();

  return historyEvents.map((historyEvent) => {
    return {
      ...historyEvent,
      tempTimeStamp: new Date(historyEvent.timeStamp),
    }
  });
};
