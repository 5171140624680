import { ICustomEditor, ICustomElement, IPlugin } from '../models';

export const withMarkup: IPlugin = (editor: ICustomEditor) => {
  const { isVoid, isInline, markableVoid } = editor;

  editor.isInline = (element: ICustomElement) => {
    return element.type === 'markup' ? true : isInline(element);
  };

  editor.isVoid = (element: ICustomElement) => {
    return element.type === 'markup' ? true : isVoid(element);
  };

  editor.markableVoid = (element: ICustomElement) => {
    return element.type === 'markup' ? true : markableVoid(element);
  };

  return editor;
};
