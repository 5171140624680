import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { FractionFormatter } from '../../elements/formats/rationals/FractionFormatter';
import { WRational } from '../../elements/tokens/WRational';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Crée une fraction mixte ou bien transforme
 * une fraction en nombre fractionnaire.
 */
export class MixedFraction extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length < 1 || args.length > 2){
      return args.expectingArguments(1, 2);
    }

    if(args.length === 1){
      if(args.getRational(0)){
        return new WRational(
          args.getRational(0).numerator,
          args.getRational(0).denominator,
          FractionFormatter.getMixedNotation(args.env.culture));
      }
      if(args.getReal(0)){
        return args.getReal(0);
      }
      if(args.getTokenElement(0)){
        return args.getTokenElement(0);
      }
    }

    if(args.length === 2){
      if(		args.getInteger(0) &&
          args.getInteger(1) &&
          args.getInteger(1).toNumber() !== 0){

        return new WRational(
          args.getInteger(0).toNumber(),
          args.getInteger(1).toNumber(),
          FractionFormatter.getMixedNotation(args.env.culture));
      }
    }

    return null;
  }

}
