import { IDictionary } from '../../../../js/utils/IDictionary';

import { XNumber } from '../../../core/XNumber';
import { ContentElement } from '../../../elements/abstract/ContentElement';
import { RealElement } from '../../../elements/abstract/RealElement';
import { CultureInfo } from '../../../localization/CultureInfo';
import { AlternateForm } from '../../../expr/manipulation/alt/AlternateForm';

/**
 * 36 --> 2 x 2 x 3 x 3 --> 2^2  x 3^2
 */
export class FactorInteger extends AlternateForm {

  public static DEFAULT_FORM:number = 0;

  public static EXPONENTIAL_FORM:number = 1;

  private _form:number;

  public get form():number{return this._form;}

  constructor(culture:CultureInfo, form:number){
    super(culture);
    this._form = form;
  }

  public alt(value:ContentElement):string{
    if(!(value instanceof RealElement)){
      return null;
    }
    const n:number = (<RealElement>value ).toNumber();
    if(isNaN(n)){
      return null; // a number?
    }
    if(n < 2){
      return null; // minimum 2?
    }
    if(Math.floor(n) !== n){
      return null; // integer?
    }

    let tokens:any[];

    switch(this.form){
      case FactorInteger.EXPONENTIAL_FORM:
        const powers:any[] = FactorInteger.powers(n);
        tokens = powers.map(this.formatPowerToken, this);
        break;
      case FactorInteger.DEFAULT_FORM:
        tokens = XNumber.factors(n);
        break;
    }

    if(tokens){
      return tokens.join('×');
    }

    return null;
  }

  private formatPowerToken(power:any[], ..._:any[]):string{
    if(power[1] === 1){
      return power[0]; // base only
    }
    return `${power[0]}^${power[1]}`;
  }

  /**
   *
   */
  public static powers(n:number):any[]{
    const factors:any[] = XNumber.factors(n);
    const result:any[] = [];
    const temp:IDictionary = {};
    let max:number = 0;
    for(let i:number = 0 ; i < factors.length ; i++){
      const factor:number = factors[i];
      if(!temp.hasOwnProperty(String(factor))){
        temp[String(factor)] = 0;
      }
      temp[String(factor)]++;
      max = Math.max(max, factor);
    }
    for(let k:number = 2 ; k <= max ; k++){
      if(temp[k] !== undefined){
        result.push([k, temp[k]]);
      }
    }
    return result;
  }

  /**
   * Returns the resulting integer from the provided factorization.
   * @powers array of [base, exponent]
   */
  public static integer(powers:any[]):number{
    let n:number = 1;
    for(let i:number = 0 ; i < powers.length ; i++){
      n *= (powers[i][0] ** powers[i][1]);
    }
    return n;
  }

}
