import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { ContentElement } from '../../elements/abstract/ContentElement';

export class TaxRateTotal extends FunctionElement {

  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 0){
      return args.expectingArguments(0, 0);
    }
    return args.env.culture.createNumber(args.env.culture.configuration.taxRateTotal);
  }

}
