import { AbstractStep } from '../AbstractStep';
import { Compartment } from '../Compartment';
import { HLine } from '../HLine';
import { LongMultiplicationOperation } from '../LongMultiplicationOperation';

/**
 *
 */
export class MultAddInit extends AbstractStep {

  constructor(operation:LongMultiplicationOperation) {

    super(operation);
    operation.addSign = Compartment.createOperator('+');
    operation.addLine = new HLine();
    this.related.push(operation.addSign);
    this.lines.push(operation.addLine);

    let cols:number = 0;
    for(let i:number = 0 ; i < operation.multSteps.length ; i++){
      const step:Compartment[] = operation.multSteps[i];
      cols = Math.max(cols, step.length);
    }
    operation.addColumns = cols;
    operation.sumCarries.push(null); // insert the first carry, there's never a carry over the rightmost column

    this.setDescription(operation.culture.getString('LongMultiplicationOperation.initAddLabel'));
  }

}
