import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { processKeyEvent } from './Keys/utils';
import { IKeyboard, KeyboardManager } from '../../keyboard';

export const useKeyboard = <TEvent extends React.FocusEvent>(onFocus: (event: TEvent) => void, onBlur: (event: TEvent) => void, keyboard: IKeyboard) => {
  const { current: keyboardId } = React.useRef(uuidv4());
  const inputRef = React.useRef(null);
  const [hasFocus, setHasFocus] = React.useState(false);
  const wrappedOnFocus = React.useCallback((event: TEvent) => {
    setHasFocus(true);
    if (onFocus) {
      onFocus(event);
    }
  },                                       [onFocus]);
  const wrappedOnBlur = React.useCallback((event: TEvent) => {
    setHasFocus(false);
    KeyboardManager.getInstance().hideKeyboard(keyboardId);
    if (onBlur) {
      onBlur(event);
    }
  },                                      [onBlur, keyboardId]);

  const onClickKeyboardIcon = React.useCallback(() => {
    const onKeyPress = (key: string) => processKeyEvent(inputRef.current, key);
    KeyboardManager.getInstance().showKeyboard(keyboardId, keyboard, onKeyPress);
    inputRef.current.focus();
  },                                            [inputRef]);
  return { inputRef, hasFocus, wrappedOnFocus, wrappedOnBlur, onClickKeyboardIcon };
};
