export const ColorSet = {
  white: '#FFFFFF',
  black: '#000000',
  grey025: '#F2F2F5',
  grey050: '#E9ECF2',
  grey100: '#CED5DF',
  grey400: '#818C99',
  grey500: '#6B747D',
  grey700: '#51575E',
  grey800: '#42474D',
  grey900: '#303438',
  penumbra: '#1C1F21',
  darkness: '#111213',

  primary050: '#EAF6FF',
  primary075: '#D7EDFF',
  primary500: '#0086EC',
  primary700: '#0068B8',

  alert400: '#FF4E5F',
  alert500: '#DA4453',
  warning: '#FF7700',

  brand: '#72D200',

  book: '#0086EC',
  topic: '#FF45A2',
  chapter: '#00B57F',
  element: '#FBC02A',
  standard: '#7E57C2',

  bookDark: '#0068B8',
  topicDark: '#E9157F',
  chapterDark: '#008059',
  elementDark: '#C28D05',

  french: '#0086EC',
  frenchDark: '#0068B8',
  mathematics: '#7E57C2',
  mathematicsDark: '#634498',

  // math-core syntax highlight
  syntaxExpression: '#FFFFFF',
  syntaxFunctions: '#84CAFF',
  syntaxVariables: '#FFDC83',
  syntaxText: '#FFAFD7',

  // variable usage indicators
  indicatorText: '#FF86C2',
  indicatorTranslation: '#FFA95F',
  indicatorBinding: '#84CAFF',
  indicatorValidation: '#42DFB0',
};

export const NO_DESIGN = 'fuchsia';
export const NO_DESIGN_TEXT = 'lime';

/**
 * hexColor: #FFFFFF
 * alpha: [0-1]
 * @param hexColor
 * @param alpha
 */
export const setHexColorTransparency = (hexColor: string, alpha: number = 1) => {
  if (!hexColor || !/^#[a-fA-F0-9]{6}$/.test(hexColor)) {
    throw new Error('Invalid HEX color');
  }
  if (isNaN(alpha) || alpha < 0 || alpha > 1) {
    throw new Error('Invalid transparency');
  }
  const hexAlpha = Math.floor(alpha * 255).toString(16);
  return `${hexColor}${hexAlpha.length === 1 ? '0' + hexAlpha : hexAlpha}`;
};
