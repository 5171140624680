import { MathError } from '../../core/MathError';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { WTimeOfDay } from '../../elements/tokens/WTimeOfDay';
import { TimeFormatUtil } from '../../elements/formats/time/TimeFormatUtil';

/**
 * Retourne l'heure affichée après formattage en nombre.
 */
export class HoursPart extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 1){
      return args.expectingArguments(1, 1);
    }

    const hours:RealElement = args.getWholeNumber(0);
    if(hours !== null){
      if(hours.toNumber() > 23){
        throw new MathError('Hours must be between 0 and 23.');
      }
      return args.env.culture.createNumber(
        args.env.culture.configuration.twelveHoursClock ?
          TimeFormatUtil.hours12hourclock(hours.toNumber()) :
          hours.toNumber());
    }

    const time:WTimeOfDay = args.getTimeOfDay(0);
    if(time !== null){
      return args.env.culture.createNumber(
        time.formatter.useTwelveHoursClock() ?
          TimeFormatUtil.hours12hourclock(time.hours) :
          time.hours);
    }

    return null;
  }

}
