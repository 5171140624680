import React from 'react';
import { observer } from 'mobx-react';
import { TagInstance, Tooltip } from '@scolab/publisher-ui-kit';
import { instantiate } from '../../dependencyInjection/instantiate';
import { VariantMediator } from '../../mediators/VariantMediator';

interface IVariantTagProps {
  name: string;
  onRemove?: (name: string) => void;
}

@observer
export class VariantTag extends React.Component<IVariantTagProps> {
  private variantMediator: VariantMediator;

  constructor(props: IVariantTagProps) {
    super(props);
    this.variantMediator = instantiate(VariantMediator);
  }

  public render(): React.ReactNode {
    const {
      name,
      onRemove,
    } = this.props;
    const description = this.variantMediator.getVariantMetadata(name)?.description;
    const tooltip = description || name;
    return (
      <TagInstance
        key={name}
        id={name}
        action={onRemove ? 'remove' : 'none'}
        size="small"
        variant={onRemove ? 'filled' : 'outlined'}
        onAction={onRemove}
      >
        <Tooltip
          title={tooltip}
        >
          <span>{name}</span>
        </Tooltip>
      </TagInstance>
    );
  }
}
