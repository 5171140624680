import { MathError } from '../../core/MathError';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WString } from '../../elements/tokens/WString';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Constructeur d'erreur. Cette fonction est généralement utilisée à
 * l'interne pour informer l'auteur d'une erreur.
 */
export class Err extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 1){
      return args.expectingArguments(1, 1);
    }
    if(args.getString(0)) {
      return this.mathError(args.getString(0));
    }
    throw new MathError('no valid arguments object given, expected a string');
  }

  /**
   *
   */
  private mathError(message:WString):ContentElement{
    throw new MathError(message.getString());
  }

}
