import { IPrng } from '../../core/prng/IPrng';

/**
 *
 */
export class Mixer {

  private prng:IPrng;

  /**
   *
   */
  constructor(prng:IPrng){
    this.prng = prng;
  }

  /**
   *
   */
  public mix(length:number):number[]{
    const hat:number[] = [];
    const temp:number[] = [];

    for(let i:number = 0 ; i < length ; i++){
      hat.push(i);
    }

    while(hat.length > 0){
      const k:number = this.prng.randomIndex(hat.length);
      temp.push(hat[k]);
      hat.splice(k, 1);
    }

    return temp;
  }

}
