import { HttpTransportType, HubConnection, HubConnectionBuilder } from '@microsoft/signalr';

type CallbackKind = 'start' | 'close';

export class CollaborationHub {
  private readonly hubUrl: string;

  private hub: HubConnection;

  private connectionRetryCount: number;

  private maxConnectionRetry = 4;

  private connectionRetryDelay = 3000;

  private callbacks: Map<CallbackKind, () => void> = new Map<CallbackKind, () => void>();

  constructor(hubUrl: string) {
    this.hubUrl = hubUrl;

    if (this.isValidConnection) {
      this.hub = this.configure();
    }
  }

  get isValidConnection(): boolean {
    return Boolean(this.hubUrl);
  }

  addHandler<T>(methodName: string, callback: (message: T) => void) {
    if (!this.isValidConnection) {
      return;
    }

    this.hub.on(methodName, callback);
  }

  addEvent(eventName: CallbackKind, callback: () => void) {
    this.callbacks.set(eventName, callback);
  }

  async send(name: string, payload: {}) {
    if (!this.isValidConnection) {
      return;
    }

    try {
      if (this.hub.state === 'Connected') {
        await this.hub.invoke(name, payload);
      }
    } catch (e) {
      window.console.error(e);
    }
  }

  async start(): Promise<void> {
    if (!this.isValidConnection) {
      return;
    }

    try {
      if (this.hub.state === 'Disconnected') {
        await this.hub.start();
        this.connectionRetryCount = 0;
        this.callback('start');
      } else if (this.hub.state === 'Disconnecting') {
        return this.scheduleRetryConnection();
      }
    } catch (e) {
      this.scheduleRetryConnection();
    }
  }

  private configure() {
    const hub = new HubConnectionBuilder()
      .withUrl(`${this.hubUrl}/PageContentHub`, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets
      })
      .build();

    hub.onclose((error) => {
      this.callback('close');
      this.scheduleRetryConnection();
    });

    return hub;
  }

  private scheduleRetryConnection() {
    const handler = async () => {
      this.connectionRetryCount++;
      if (this.connectionRetryCount <= this.maxConnectionRetry) {
        await this.start();
      }
    };

    setTimeout(handler, this.connectionRetryDelay);
  }

  private callback(eventName: CallbackKind) {
    const callback = this.callbacks.get(eventName);
    if (callback) {
      callback();
    }
  }
}
