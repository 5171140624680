import { XMath } from '../../../core/XMath';
import { BaseNumberFormatter } from '../../../elements/formats/BaseNumberFormatter';
import { CultureInfo } from '../../../localization/CultureInfo';

/**
 *
 */
export class PolygonNameFormatter extends BaseNumberFormatter {

  /**
   *
   */
  constructor(culture:CultureInfo){
    super(culture);
  }

  /**
   *
   */
  public toLocaleString(value:number):string{
    if(!this.willFormat(value)){
      return this.culture.formatNumber(value);
    }
    if(value <= 12){
      return this.culture.getString(`Polygons.${value.toString()}`);
    }
    return this.culture.getString('Polygons.ngon', value);
  }

  /**
   *
   */
  public toSpeakText(value:number):string{
    return this.willFormat(value) ?
        null :
        this.culture.numberFormatter.toSpeakText(value);
  }

  /**
   *
   */
  public willFormat(value:number):boolean{
    return XMath.isInteger(value) && value >= 3;
  }

}
