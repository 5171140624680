import { IContextModel } from '@scolab/content-model';
import { InlineContext } from '../../../expr/InlineContext';

export class RandomEasyLine extends InlineContext {

  /**
   *
   */
  constructor(){
    super('RandomEasyLine', [], 'points');
  }

  /**
   *
   */
  public getContextModel():IContextModel{
    const o:IContextModel = {
      variables: [{
        value: 'Random(-8, 3)',
        id: 'xa'
      }, {
        value: 'Random(xa+4, 8)',
        id: 'xb'
      }, {
        value: 'Random(-8, 3)',
        id: 'yu'
      }, {
        value: 'Random(yu+4, 8)',
        id: 'yw',
        validation: 'Not(IsInteger((yw-yu)/(xb-xa)))'
      }, {
        value: 'Random(0, 1)',
        id: 'i'
      }, {
        value: 'if(i=0, yu, yw)',
        id: 'ya'
      }, {
        value: 'if(i=1, yu, yw)',
        id: 'yb'
      }, {
        value: '(xa, ya), (xb, yb)',
        id: 'points'
      }
      ]
    };

    return o;
  }

}
