import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { WTable } from '../../elements/tokens/WTable';

/**
 * Calcule le nombre de lignes d'une matrice.
 */
export class CountRows extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 1){
      return args.expectingArguments(1, 1);
    }

    const matrix:WMatrix = args.getMatrix(0);
    if(matrix){
      return args.env.culture.createNumber(matrix.rows);
    }

    const table:WTable = args.getTable(0);
    if(table){
      const nbRows = Math.max(...table.columns.map((col) => col.count));
      return args.env.culture.createNumber(nbRows);
    }

    return null;
  }

}
