import React from 'react';
import styled from 'styled-components';

export class PageNotFound extends React.Component {
  public render(): JSX.Element {
    return (
      <Box data-testid="error-404">
        <Title>Page not Found</Title>
        <Message>The requested URI cannot be resolved.</Message>
      </Box>
    );
  }
}

const Box = styled.div`
  left: 50%;
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 450px;
`;

const Title = styled.h2`
  color: ${(props) => props.theme.colorset.penumbra};
  font: ${(props) => props.theme.typeset.h2Headline};
`;

const Message = styled.p`
  color: ${(props) => props.theme.colorset.grey800};
  font: ${(props) => props.theme.typeset.body1Regular};
`;
