import { IDictionary } from '../../js/utils/IDictionary';

import { XObject } from '../core/XObject';
import { IPrng } from '../core/prng/IPrng';
import { BaseNumberFormatter } from '../elements/formats/BaseNumberFormatter';
import { WVariable } from '../elements/tokens/WVariable';

/**
 *
 */
export class SymbolsFactory {

  /**
   *
   */
  private formatter:BaseNumberFormatter;

  /**
   *
   */
  constructor(formatter:BaseNumberFormatter){
    this.formatter = formatter;
  }

  /**
   * randomizeSymbols can be applied once only
   */
  private locked:boolean = false;

  private variables:IDictionary = {};

  public create(name:string):WVariable{
    if(!this.variables.hasOwnProperty(name)){
      this.variables[name] = new WVariable(name, this.formatter);
    }
    return this.variables[name];
  }

  /**
   * Options de dynamisation des noms de variables
   * 0: laisser inchangées les noms de variables
   * 1: interchanger les noms de variables
   * 2: changer les noms de variables
   */
  public randomizeSymbols(method:number, prng:IPrng):void{
    if(this.locked){
      return;
    }
    this.locked = true;
    const names:string[] = XObject.getProps(this.variables);
    names.sort();

    let name:string;
    let i:number;

    if(method === 2){ // Changer tous les noms de variables pour une autre lettre de l'alphabet
      let events:number = 0;

      // **************** [a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t, u, v, w, x, y, z]
      const probs:any[] = [8, 8, 8, 5, 2, 0, 2, 1, 0, 1, 1, 0, 0, 6, 0, 5, 1, 4, 1, 3, 1, 1, 1, 6, 6, 2];

      for(i = 0 ; i < probs.length ; i++){
        if(probs[i] > 0){
          events++;
        }
      }

      if(names.length <= events){ // Étant donné qu'il y a des probabilités à 0, il faut vérifier que toutes les lettres
        for(i = 0 ; i < names.length ; i++){
          name = names[i];
          let j:number;
          let t:number = 0;
          for(j = 0 ; j < probs.length ; j++){
            t += probs[j]; // Contient la somme des probabilités de sorte qu'on puisse générer une valeur aléatoire avec laquelle on pourra aller chercher une nouvelle lettre
          }
          t = Math.round(prng.random() * t); // NOTE: t contient maintenant un nombre entre 1 et la probabilité maximale
          let k:number = 0;
          for(j = 0 ; j < 26 ; j++){
            if(probs[j] > 0){
              k += probs[j];
              if(k >= t){
                probs[j] = 0; // annule la probabilité de cette lettre de sorte qu'on ne puisse plus la réutiliser
                (<WVariable>this.variables[name] ).substitute(String.fromCharCode(97 + j)); // 97 --> a
                break;
              }
            }
          }
        }
      } // else we don't randmize the variables names
    }else if(method === 1){ // Interchanger tous les noms de variables
      const names2:any[] = names.concat();
      for(i = 0 ; i < names2.length ; i++){
        name = names2[i];
        const g:number = prng.randomIndex(names.length);
        (<WVariable>this.variables[name] ).substitute(names[g]);
        names.splice(g, 1);
      }
    }
  }

}
