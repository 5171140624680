import { FunctionElement } from '../../../src/nm2/elements/abstract/FunctionElement';
import { ArgumentsObject } from '../../../src/nm2/expr/ArgumentsObject';
import { Environment } from '../../../src/nm2/expr/Environment';
import { Prng } from '../../../src/nm2/core/prng/Prng';
import { FormatProvider } from '../../../src/nm2/elements/factories/FormatProvider';
import { OptionsProvider } from '../../../src/nm2/elements/factories/OptionsProvider';
import { CultureInfo } from '../../../src/nm2/localization/CultureInfo';
import { IExtensionsMethods } from '../../../src/nm2/expr/IExtensionsMethods';
import { ContentElement } from '../elements/abstract/ContentElement';
import { WPolyline } from '../elements/tokens/WPolyline';
import { Point } from '../../js/geom/Point';
import { WPolygon } from '../elements/tokens/WPolygon';
import { WSegment } from '../elements/tokens/WSegment';
import { WNumber } from '../elements/tokens/WNumber';
import { WTimeSpan } from '../elements/tokens/WTimeSpan';
import { WTimeOfDay } from '../elements/tokens/WTimeOfDay';
import { GraphBuilder } from '../elements/factories/GraphBuilder';
import { WString } from '../elements/tokens/WString';
import { WBoolean } from '../elements/tokens/WBoolean';
import { WPolynomial } from '../elements/tokens/WPolynomial';
import { SymbolElement } from '../elements/abstract/SymbolElement';
import { WQuadratic } from '../elements/tokens/WQuadratic';
import { WFormat } from '../elements/tokens/WFormat';
import { WFormula } from '../elements/tokens/WFormula';
import { InlineContext } from './InlineContext';
import { WLine } from '../elements/tokens/WLine';
import { XNumber } from '../core/XNumber';
import { MathError } from '../core/MathError';
import { WRange } from '../elements/tokens/WRange';
import { NameFactory } from '../elements/factories/NameFactory';
import { WTreeDiagram } from '../elements/tokens/WTreeDiagram';
import { TDDiagram } from '../elements/models/tree/TDDiagram';
import { WTable } from '../elements/tokens/WTable';
import { WFunctionGraph } from '../elements/tokens/WFunctionGraph';
import { LambdaAdapter } from '../elements/functions/adapters/LambdaAdapter';
import { WPi } from '../elements/tokens/WPi';
import { WRadical } from '../elements/tokens/WRadical';
import { WVariable } from '../elements/tokens/WVariable';
import { WNotANumber } from '../elements/tokens/WNotANumber';

export class FunctionVisitor {

  private _fn: FunctionElement;

  private _culture: CultureInfo;

  private _env: Environment;

  constructor(fn:FunctionElement, culture: CultureInfo){
    this._fn = fn;
    this._culture = culture;
    this._env = this.getEnvironment();
  }

  public getSignatures():ReadonlyArray<ReadonlyArray<string>>{
    let killSwitch = 0;
    let _minArguments:number = Number.NaN;
    let _maxArguments:number = Number.NaN;

    let currentSignature: string[];
    const tempSignatures: any[] = [[]];
    const invalidSignatures: any[] = [[]];
    const signatures: any[] = [];
    const signaturesWithErrors: any[] = [];

    const trace = {
      requiredArguments: (minArguments: number, maxArguments: number) => {
        if(isNaN(_minArguments) || isNaN(_maxArguments)){
          _minArguments = minArguments;
          _maxArguments = maxArguments;
          if(!isNaN(_minArguments) && !isNaN(_maxArguments)){
            for(let n: number = Math.max(1, _minArguments) ; n <= (_maxArguments === Number.POSITIVE_INFINITY ? _minArguments : _maxArguments) ; n++){
              const s = [];
              for(let i:number = 0 ; i < n ; i++){
                s.push(null);
              }
              tempSignatures.push(s);
            }
          }
        }
      },
      requiredElementType: (index: number, expectedType: string) => {
        // console.log(index, expectedType);
        const newSignature = currentSignature.concat();
        newSignature[index] = expectedType;
        if(   !tempSignatures.some((s) => s.join(',') === newSignature.join(',')) &&
              !invalidSignatures.some((s) => s.join(',') === newSignature.join(',')) &&
              !signatures.some((s) => s.join(',') === newSignature.join(',')) &&
              !signaturesWithErrors.some((s) => s.join(',') === newSignature.join(','))
           ){
          tempSignatures.push(newSignature);
        }
      },
    };

    while(tempSignatures.length > 0 && killSwitch < 999){
      currentSignature = tempSignatures.pop();
      // console.log('currentSignature', currentSignature);
      const _args = this.getArgumentsList(currentSignature);
      const args:ArgumentsObject =
        new ArgumentsObject(
          _args,
          this._env,
          true,
          trace);

      // console.log("call with", _args.map((a) => a.getType()));
      try{
        const result = this._fn.callReturnElement(args);
        if(result == null) {
          invalidSignatures.push(currentSignature);
        }else if(_maxArguments === Number.POSITIVE_INFINITY){
            signatures.push(currentSignature.concat('...'));
          }else{
            signatures.push(currentSignature);
          }
      }catch(e){
        if(e instanceof MathError){
          signaturesWithErrors.push(currentSignature.concat('{err}'));
        }else{
          throw e;
        }
      }

      killSwitch++;
    }
    return signatures.concat(signaturesWithErrors);
  }

  private getEnvironment():Environment{
    const extensions: IExtensionsMethods = {
      latexEnabled: false,
      latexToMarkup: (value: string) => value,
      getEnvVariable: (name: string) => null,
    };
    return new Environment(
      this._culture,
      new Prng(1),
      new Prng(1),
      false,
      new FormatProvider(this._culture),
      new OptionsProvider(0, null),
      NameFactory.createFromResources(this._culture, new Prng(1)),
      extensions);
  }

  private getArgumentsList(signature: ReadonlyArray<string>): ContentElement[]{
    const dn1:WNumber = this._env.culture.createNumber(0.1);
    const nm1:WNumber = this._env.culture.createNumber(-1);
    const n0:WNumber = this._env.culture.createNumber(0);
    const n1:WNumber = this._env.culture.createNumber(1);
    const n2:WNumber = this._env.culture.createNumber(2);
    const p00:Point = new Point(0, 0);
    const p01:Point = new Point(0, 1);
    const p10:Point = new Point(1, 0);
    const p11:Point = new Point(1, 1);

    const p1:WPolynomial =
      new WPolynomial(
        [new SymbolElement('x', this._env.culture.formats.numberFormatImpl)],
        [n1, n1],
        [1, 0],
        this._env.culture.formats.numberFormatImpl);

    const p2:WPolynomial =
      new WPolynomial(
        [new SymbolElement('x', this._env.culture.formats.numberFormatImpl)],
        [n1, n1],
        [2, 0],
        this._env.culture.formats.numberFormatImpl);

    return signature.map((type: string) => {
      switch(type){
        case null:
          return null;
          // return new WNull();
        case 'contentElement':
          return dn1;
        case 'token':
          return dn1;
        case 'real':
          return dn1;
        case 'integer':
          return nm1;
        case 'whole':
          return n0;
        case 'natural':
          return n1;
        case 'constant':
          return new WPi(this._env.culture.createNumber(Math.PI));
        case 'nan':
          return new WNotANumber();
        case 'radical':
          return new WRadical(n2, n2, n1, this._env.culture.formats.radicalFormatImpl);
        case 'list':
        case 'listn':
        case 'nonEmptyList':
          return this._env.culture.listFactory.createFromArray([0, 1]);
        case 'matrix':
          return this._env.culture.createMatrix([0, 1], 2);
        case 'polyline':
          return new WPolyline([p00, p11]);
        case 'polygon':
          return new WPolygon([p00, p10, p01]);
        case 'line':
          return WLine.parsePolynomial(p1, this._env.culture.formats.lineFormatImpl);
        case 'segment':
          return new WSegment(p00, p10);
        case 'segments':
          return this._env.culture.listFactory.createList([new WSegment(p00, p10), new WSegment(p10, p01)]);
        case 'point':
          return this._env.culture.parsePoint(p00);
        case 'points':
          return this._env.culture.listFactory.createList([this._env.culture.parsePoint(p00), this._env.culture.parsePoint(p11)]);
        case 'timeSpan':
          return new WTimeSpan(1000);
        case 'timeOfDay':
          return new WTimeOfDay(1, 0, 0, false, this._env.culture.formats.timeFormatImpl);
        case 'graph':
          const graphBuilder = new GraphBuilder();
          graphBuilder.addEdge(p01, true, 1);
          return graphBuilder.toGraph();
        case 'expression':
          return this._env.createExpression('x+1');
        case 'string':
          return new WString('allo');
        case 'strings':
          return this._env.culture.listFactory.createList([new WString('allo'), new WString('hello')]);
        case 'finiteSet':
          return this._env.createNormalizedSet([n0, n1]);
        case 'boolean':
          return WBoolean.TRUE;
        case 'polynomial':
          return p1;
        case 'symbol':
          return new WVariable('x', this._env.culture.formats.numberFormatImpl);
        case 'quadratic':
          return WQuadratic.parsePolynomial(p2, this._env.culture.formats.quadraticFormatImpl);
        case 'format':
          return new WFormat(this._env.culture.formats.numberFormatImpl);
        case 'formula':
          return new WFormula(new InlineContext('allo', [], 'a', {variables: [{id: 'a', value: '1'}]}), 1);
        case 'range':
          return new WRange(0, 1, 1, this._env.culture.formats.numberFormatImpl);
        case 'treeDiagram':
          return new WTreeDiagram(TDDiagram.empty(), null);
        case 'table':
          return new WTable([this._env.culture.listFactory.createFromArray([0, 1])]);
        case 'anonymousFunction1':
          return this._env.expressions.toFunction('λ(x, x)');
        case 'anonymousFunction2':
          return this._env.expressions.toFunction('λ(x, y, x * y)');
        case 'functionGraph':
          return new WFunctionGraph(new LambdaAdapter(this._env.expressions.toFunction('λ(x, x)'), this._env), null, '=', 'y');
        default:
          // `matrix_${rows}_${columns}`
          if(/^matrix_\d+_\d+$/.test(type)){
            const rows: number = Number(type.split('_')[1]);
            const columns: number = Number(type.split('_')[2]);
            return this._env.culture.createMatrix(XNumber.repeat(0, rows * columns), columns);
          }
          if(/^string_.+$/.test(type)){
            return new WString(type.substring(type.indexOf('_') + 1));
          }
      }
      throw new Error();
    });
  }

}
