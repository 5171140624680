import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Return the item at the specified position
 * item(list, 1) return the first element of the list.
 *
 * Extrait l'élément d'une liste à la position spécifiée.
 * La position 1 correspond au premier élément de la liste.
 */
export class Item extends FunctionElement {
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 2) {
      return args.expectingArguments(2, 2);
    }

    const ordinal = args.getNaturalNumber(1)?.toNumber() ?? null;
    if (!ordinal) {
      return null;
    }

    const point = args.getPoint(0);
    if (point && ordinal <= 2) {
      return ordinal === 1 ? point.x : point.y;
    }

    const list = args.getList(0);
    if (list && ordinal <= list.count) {
      return list.getItemAt(Math.round(ordinal - 1));
    }

    return null;
  }
}
