/**
 *
 */
export class StemLeafRowModel {

  public stem:number;

  public leaves:number[];

  public leaves2:number[];

  public clone():StemLeafRowModel{
    const copy:StemLeafRowModel = new StemLeafRowModel();
    copy.stem = this.stem;
    copy.leaves = this.leaves.concat();
    if(this.leaves2){
      copy.leaves2 = this.leaves2.concat();
    }
    return copy;
  }

}
