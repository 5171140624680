import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { ListElement } from '../../elements/abstract/ListElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WFiniteSet } from '../../elements/tokens/WFiniteSet';
import { WRange } from '../../elements/tokens/WRange';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';

/**
 * Tirage avec remise, les éléments peuvent donc être dupliqués
 */
export class Draw extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length < 1 || args.length > 2){
      return args.expectingArguments(1, 2);
    }

    if(args.length === 1){
      if(args.getFiniteSet(0)){
        return this.finite(args.getFiniteSet(0), args.env);
      }
      if(args.getRange(0)){
        return this.range(args.getRange(0), args.env);
      }
      if(args.getList(0)){
        return this.list(args.getList(0), args.env);
      }
    }else if(args.length === 2){
      const length:RealElement = args.getWholeNumber(1);
      if(!length){
        return null;
      }
      if(args.getFiniteSet(0)){
        return this.finiteN(args.getFiniteSet(0), length, args.env);
      }
      if(args.getRange(0)){
        return this.rangeN(args.getRange(0), length, args.env);
      }
      if(args.getList(0)){
        return this.listN(args.getList(0), length, args.env);
      }
    }

    return null;
  }

  /**
   * Retourne un des éléments de la liste.
   */
  private list(value:ListElement, env:Environment):ContentElement{
    if(value.count === 0){
      return null;
    }
    return value.getItemAt(env.prng.randomIndex(value.count));
  }

  /**
   * Retourne plusieurs éléments d'une liste.
   * (avec remise)
   */
  private listN(value:ListElement, length:RealElement, env:Environment):ListElement{
    if(!length.isWholeNumber()){
      return null;
    }
    if(value.count === 0){
      return null;
    }

    const o:number[] = [];
    for(let i:number = 0 ; i < length.toNumber() ; i++){
      o.push(env.prng.randomIndex(value.count));
    }
    return value.transform(o);
  }

  /**
   * Retourne un nombre de la plage de nombres.
   */
  private range(a:WRange, env:Environment):RealElement{
    return a.itemAt(env.prng.randomIndex(a.getCardinal()));
  }

  /**
   * Retourne plusieurs nombres d'une plage de nombres.
   */
  private rangeN(a:WRange, b:RealElement, env:Environment):ListElement{
    if(!b.isWholeNumber()){
      return null;
    }
    const r:ContentElement[] = [];
    for(let i:number = 0 ; i < b.toNumber() ; i++){
      r.push(a.itemAt(env.prng.randomIndex(a.getCardinal())));
    }
    return env.culture.listFactory.createList(r);
  }

  /**
   * Retourne un des éléments de l'ensemble.
   */
  private finite(a:WFiniteSet, env:Environment):ContentElement{
    if(a.cardinal > 0){
      return a.getElementAt(env.prng.randomIndex(a.cardinal));
    }
    return null;
  }

  /**
   *
   */
  private finiteN(a:WFiniteSet, b:RealElement, env:Environment):ListElement{
    if(!b.isWholeNumber()){
      return null;
    }
    const o:ContentElement[] = [];
    for(let i:number = 0 ; i < b.toNumber() ; i++){
      o.push(a.getElementAt(env.prng.randomIndex(a.cardinal)));
    }
    if(o.length === 0){
      return env.culture.createEmptyList();
    }
    return env.culture.listFactory.createList(o);
  }

}
