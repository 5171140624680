import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { SetElement } from '../../elements/abstract/SetElement';
import { IntervalsFactory } from '../../elements/factories/IntervalsFactory';
import { IntervalClosure } from '../../elements/models/IntervalClosure';
import { WLine } from '../../elements/tokens/WLine';
import { WQuadratic } from '../../elements/tokens/WQuadratic';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class PositiveIntervals extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 1){
      return args.expectingArguments(1, 1);
    }

    const intervalsFactory:IntervalsFactory = args.env.culture.intervalsFactory;

    const line:WLine = args.getLineOrPoly(0);
    if(line){
      return PositiveIntervals.parseLine(line, false, intervalsFactory);
    }

    const quadratic:WQuadratic = args.getQuadraticOrPoly(0);
    if(quadratic){
      return PositiveIntervals.parseQuadratic(quadratic, false, intervalsFactory);
    }

    return null;
  }

  /**
   *
   */
  public static parseLine(
      value:WLine,
      strict:boolean,
      intervalsFactory:IntervalsFactory):SetElement{

    if(!value.slope){
      return null; // vertical line
    }

    const m:number = value.slope.toNumber();
    const b:number = value.yintercept.toNumber();

    if(m === 0){
      return b < 0 ? intervalsFactory.createReals() : intervalsFactory.createEmpty();
    }

    const x:number = value.xintercept.toNumber();

    const closure:IntervalClosure =
      strict ?
        IntervalClosure.OPEN :
        (m > 0 ? IntervalClosure.CLOSED_OPEN : IntervalClosure.OPEN_CLOSED);

    return intervalsFactory.createIntervalN(
      closure,
      m > 0 ? x : Number.NEGATIVE_INFINITY,
      m > 0 ? Number.POSITIVE_INFINITY : x);
  }

  /**
   *
   */
  public static parseQuadratic(
      value:WQuadratic,
      strict:boolean,
      intervalsFactory:IntervalsFactory):SetElement{

    const cup:boolean = value.A.toNumber() > 0;

    if(value.noxintercept){
      return cup ?
        intervalsFactory.createReals() :
        intervalsFactory.createEmpty();
    }

    const xi:number[] = value.xintercept;

    if(xi.length === 1){
      if(cup){
        return strict ?
          intervalsFactory.createExclude(xi[0]) :
          intervalsFactory.createReals();
      }
      return strict ?
        intervalsFactory.createEmpty() :
        intervalsFactory.createSingleton(xi[0]);
    }

    if(xi.length === 2){
      if(cup){
        return intervalsFactory.createHole(xi[0], xi[1], strict);
      }
      return intervalsFactory.createSimple(xi[0], xi[1], strict);
    }

    return null;
  }

}
