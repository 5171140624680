import esb from 'elastic-builder';

import {
  IDateModel,
  ISelectItemModel
} from '@scolab/publisher-ui-kit';
import { api } from './fetch';

export enum QueryOperator {
  'none',
  'fieldExist',
  'queryString'
}

export interface IQueryTerms {
  name: string,
  values: ReadonlyArray<any>,
  operator: QueryOperator,
  nested?: boolean
}

export type SearchFilter = {
  index: string,
  terms: Array<IQueryTerms>
};

export type DocSource = {
  readonly id: number;
  readonly name: string;
  readonly titleEn: string;
  readonly titleFr: string;
  readonly creationPhase: string;
  readonly visible: boolean;
  readonly fullTranslation: ReadonlyArray<string>;
  readonly partialTranslation: ReadonlyArray<string>;
  readonly emptyTranslation: ReadonlyArray<string>;
  readonly pages: ReadonlyArray<PageSource>;
};

export type PageSource = {
  readonly pageContent: PageContent;
};

export type PageContent = {
  readonly tagsFr: ReadonlyArray<string>;
  readonly tagsEn: ReadonlyArray<string>;
};

export type Hit = {
  _index: string;
  _id: string;
  _source: DocSource;
};

export type SearchResults = {
  hits: ReadonlyArray<Hit>;
  hitsCount: number;
};

const searchQueryIndexes = {
  activities: 'activities',
  activitiesInstance: 'book.toc.leaves',
  pages: 'pages',
  books: 'books',
};

const searchQueryFields = new Map<string, Array<string>>([
  [
    searchQueryIndexes.activities, [
      'id^10',
      'name.en',
      'name.fr',
    ]
  ],
  [
    searchQueryIndexes.activitiesInstance, [
      'id^10',
      'displayId^5',
      'titleEn',
      'titleFr'
    ]
  ],
  [
    searchQueryIndexes.pages, [
      'id^10',
      'name',
    ]
  ],
  [
    searchQueryIndexes.books, [
      'id^10',
      'displayId^5',
      'titleEn',
      'titleFr',
    ]
  ],
]);

/**
 * Map<nested path, nested fields>
 */
const nestedSearchQueryFields = new Map<string, Array<string>>([
  [
    'pages', [
      'pages.pageGuid'
    ]
  ],
  [
    'texts', [
      'texts.textEn',
      'texts.textFr'
    ],
  ],
  [
    'pages.pageContent.texts', [
      'pages.pageContent.texts.textEn',
      'pages.pageContent.texts.textFr'
    ]
  ],

]);

/**
 * Map<Content type, nested paths>
 */
const nestedSearchQueryPath = new Map<string, Array<string>>([
  [
    searchQueryIndexes.activities, [
      'pages',
      'pages.pageContent.texts'
    ]
  ],
  [
    searchQueryIndexes.pages, ['texts']
  ]
]);

export class SearchUtils {
  public static async getESFilterItems(selector: any): Promise<ReadonlyArray<ISelectItemModel>> {
    // Populate field using selector

    const data = new URLSearchParams();
    data.append('index', selector.index);
    data.append('query', JSON.stringify(selector.query));

    const options: RequestInit = {
      method: 'post',
      body: data,
    };

    const response = await api('/Publisher/Workbench/Search', options);
    const dataJson = await response.json();
    return dataJson.aggregations.Type.buckets.map((option: { key: string, doc_count: number }): ISelectItemModel => {
      return { label: option.key, value: option.key, parent: null, selectable: true };
    });
  }

  public static buildQuery(text: string,
                           selectorIndex: string,
                           searchFilters: SearchFilter,
                           pageSize: number, from: number): Object {

    const queryStringQuery = text ? [
      esb.queryStringQuery(text)
        .fields(searchQueryFields.get(selectorIndex))
        .lenient(true)
        .minimumShouldMatch(1)
    ] : [esb.matchAllQuery()];

    if (text && nestedSearchQueryPath.get(selectorIndex)) {
      const paths = nestedSearchQueryPath.get(selectorIndex);
      paths.forEach((path) => {
        queryStringQuery.push(
          esb.nestedQuery()
            .path(path)
            .ignoreUnmapped(true)
            .query(
              esb.boolQuery()
                .should(esb.queryStringQuery(text)
                  .fields(nestedSearchQueryFields.get(path))
                  .lenient(true))
            )
        );
      });
    }

    const req = esb.requestBodySearch()
      .query(
        esb.boolQuery()
          .should(queryStringQuery)
      )
      .postFilter(SearchUtils.postFilterTerms(searchFilters))
      .trackTotalHits(true)
      .size(pageSize)
      .source(
        [
          'name',
          'id',
          'titleFr',
          'titleEn',
          'creationPhase',
          'visible',
          'fullTranslation',
          'partialTranslation',
          'emptyTranslation',
          'pages.pageContent.tagsFr',
          'pages.pageContent.tagsEn',
        ]
      )  // Need to be specific to the type of document returned
      .from(from);
    // esb.prettyPrint(req);
    return req.toJSON();
  }

  public static postFilterTerms(searchFilters: SearchFilter): esb.Query {
    const postFilterTerms: esb.Query[] = [];
    const postFilterNestedQuery = [];

    for (const index in searchFilters.terms) {
      const term = searchFilters.terms[index];
      const path = term.name.split('.');
      let nestedPath = term.name;
      if ((path.length > 1 && term.nested === undefined) || term.nested) {
        nestedPath = path[0];
        const nestedQueryConditions: esb.Query[] = [];

        switch (term.operator) {
          case QueryOperator.queryString:
            term.values.forEach((element) => {
              nestedQueryConditions.push(esb.queryStringQuery(element));
            });
            break;
          case QueryOperator.none:
            term.values.forEach((element) => {
              nestedQueryConditions.push(esb.matchQuery(term.name, element));
            });
            break;
        }

        postFilterNestedQuery.push(
          esb.nestedQuery()
            .path(nestedPath)
            .ignoreUnmapped(true)
            .query(
              esb.boolQuery()
                .should(nestedQueryConditions)
            ));
      } else {
        const groupMatch: esb.Query[] = [];

        term.values.forEach((element) => {
          if (term.name.startsWith('dates')) {
            const dateModel = element as IDateModel;

            if (dateModel.startDate) {
              switch (dateModel.operator) {
                case 'before':
                  postFilterTerms.push(esb.rangeQuery(dateModel.dateField).lte(dateModel.startDate));
                  break;
                case 'after':
                  postFilterTerms.push(esb.rangeQuery(dateModel.dateField).gte(dateModel.startDate));
                  break;
                case 'between':
                  postFilterTerms.push(esb.rangeQuery(dateModel.dateField).gte(dateModel.startDate));
                  postFilterTerms.push(esb.rangeQuery(dateModel.dateField).lte(dateModel.endDate));
                  break;
                case 'exact':
                  postFilterTerms.push(esb.termQuery(dateModel.dateField, dateModel.startDate));
                  break;
              }
            }
          } else {
            switch (term.operator) {
              case QueryOperator.fieldExist:
                const combinedQuery: esb.Query[] = [];
                if ((element as any).must) {
                  combinedQuery.push(esb.boolQuery().must(esb.existsQuery(term.name)));
                }
                if ((element as any).mustNot) {
                  combinedQuery.push(esb.boolQuery().mustNot(esb.existsQuery(term.name)));
                }

                groupMatch.push(esb.boolQuery().should(combinedQuery));
                break;
              case QueryOperator.queryString:
                let queryStringQuery = esb.queryStringQuery(element);
                const defaultFieldName = term.name;
                if (defaultFieldName) {
                  queryStringQuery = queryStringQuery.defaultField(defaultFieldName);
                }
                groupMatch.push(queryStringQuery);
                break;
              case QueryOperator.none:
                groupMatch.push(esb.matchQuery(term.name, element));
                break;
            }
          }
        });
        postFilterTerms.push(esb.boolQuery().should(groupMatch));
      }
    }

    return esb.boolQuery()
      .must(esb.boolQuery().must(postFilterTerms))
      .must(postFilterNestedQuery);
  }
}
