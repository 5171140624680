import { MathError } from '../../core/MathError';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WList } from '../../elements/tokens/WList';
import { WStemLeafDiagram } from '../../elements/tokens/WStemLeafDiagram';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { StemLeafAttributes } from '../../funcs/stemLeaf/model/StemLeafAttributes';
import { CultureInfo } from '../../localization/CultureInfo';

/**
 *
 */
export class StemLeafTable extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length < 1 || args.length > 2){
      return args.expectingArguments(1, 2);
    }

    const values1:WList = args.getReals(0);
    if(!values1){
      return null;
    }
    this.checkValues(values1.toNumbersV());

    let values2:WList = null;
    if(args.length > 1){
      values2 = args.getReals(1);
      if(!values2){
        return null;
      }
      this.checkValues(values2.toNumbersV());
    }

    return new WStemLeafDiagram(values1, values2, this.getDefaultSettings(args.env.culture));
  }

  /**
   *
   */
  private getDefaultSettings(culture:CultureInfo):StemLeafAttributes{
    const settings:StemLeafAttributes = new StemLeafAttributes();
    settings.stemLabel = culture.getString('StemLeaf.stemLabel');
    settings.leavesLabel = culture.getString('StemLeaf.leavesLabel');
    settings.countLabel = culture.getString('StemLeaf.countLabel');
    return settings;
  }

  /**
   *
   */
  private checkValues(
      values:number[]):void{

    if(values.length === 0){
      throw new MathError('Data set cannot be empty');
    }

    for(let i:number = 0 ; i < values.length ; i++){
      const n:number = values[i];
      if(n < 0){
        throw new MathError('Data set cannot contain negative numbers');
      }
    }
  }

}
