import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { TDDiagram } from '../../elements/models/tree/TDDiagram';
import { TDNode } from '../../elements/models/tree/TDNode';
import { TDState } from '../../elements/models/tree/TDState';
import { WListOfString } from '../../elements/tokens/WListOfString';
import { WTreeDiagram } from '../../elements/tokens/WTreeDiagram';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class ExpandTree extends FunctionElement {

  /**
   * Specify whether we pick (with replace) or withdraw (without replace).
   */
  private replaceObjectAfter:boolean;

  /**
   *
   */
  constructor(replaceObjectAfter:boolean){
    super();
    this.replaceObjectAfter = replaceObjectAfter;
  }

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length < 1 || args.length > 3){
      return args.expectingArguments(1, 3);
    }

    const tree:WTreeDiagram = args.getTreeDiagram(0);
    if(!tree){
      return null;
    }

    if(args.length === 1){
      return this.leafs(tree);
    }
    if(args.length === 2 && args.getStrings(1)){
      return this.paths(tree, args.getStrings(1));
    }
    if(args.length === 3 && args.getStrings(1) && args.getStrings(2)){
      return this.objects(tree, args.getStrings(1), args.getStrings(2));
    }

    return null;
  }

  /**
   * Expand all available objects on all leafs.
   */
  private leafs(
      value:WTreeDiagram):WTreeDiagram{
    const diagram:TDDiagram = value.model.clone();
    this.expand(
      diagram.root,
      null,
      null,
      this.replaceObjectAfter);
    return new WTreeDiagram(diagram, value.displayMode);
  }

  /**
   * Expand all available object for the leafs of the specified path.
   */
  private paths(
      value:WTreeDiagram,
      path:WListOfString):WTreeDiagram{

    const diagram:TDDiagram = value.model.clone();
    this.expand(
      diagram.root,
      path.toStrings(),
      null,
      this.replaceObjectAfter);
    return new WTreeDiagram(diagram, value.displayMode);
  }

  /**
   *
   */
  private objects(
      value:WTreeDiagram,
      path:WListOfString,
      objects:WListOfString):WTreeDiagram{

    const diagram:TDDiagram = value.model.clone();
    this.expand(
      diagram.root,
      path.toStrings(),
      objects.toStrings(),
      this.replaceObjectAfter);
    return new WTreeDiagram(diagram, value.displayMode);
  }

  /**
   * If path is null, then we expand all leafs accessible from the root.
   * If objects is null, then we expand all available objects on the path.
   */
  private expand(
      root:TDNode,
      path:string[],
      objects:string[],
      replace:boolean):void{

    const nodes:TDNode[] = path ? root.search(path) : root.leafs;
    for(let i:number = 0 ; i < nodes.length ; i++){
      const node:TDNode = nodes[i];
      const objectsList:string[] = objects || node.objects;
      for(let j:number = 0 ; j < objectsList.length ; j++){
        const label:string = objectsList[j];
        if(!node.hasObject(label)){
          continue;
        }
        const child:TDNode = new TDNode();
        child.parent = node;
        child.value = node.getObject(label);
        child.state = replace ? node.state : ExpandTree.decrementObject(node.state, label);
        node.children.push(child);
      }
    }
  }

  /**
   * Do not remove the object from the list.
   */
  private static decrementObject(
      state:TDState[],
      label:string):TDState[]{

    const state2:TDState[] = state.concat();
    for(let i:number = 0 ; i < state2.length ; i++){
      if(state2[i].object.label === label){
        state2[i] =
          new TDState(
            state2[i].object,
            state2[i].count - 1);
        break;
      }
    }

    return state2;
  }

}
