import { Primes } from '../../core/sets/Primes';
import { NumberMachine } from '../../funcs/numbers/NumberMachine';

/**
 * Return the smallest composite number greater than the specified number.
 */
export class CompositeAfter extends NumberMachine {

  constructor(){
    super((nArg: number) => {
      let n = Math.max(nArg, 0);
      n = Math.ceil(n);
      do{
        n++;
      }while(n === 1 || Primes.getInstance().elementOf(n));
      return n;
    });
  }

}
