import React from 'react';
import styled from 'styled-components';
import { ITopLabeledInput, TopLabeledInput } from '../TopLabeledInput';
import { ReadonlyContext } from '../../../contexts/ReadonlyContext';
import { IKeyboard } from '../../keyboard';
import { useKeyboard } from '../Keyboards/useKeyboard';
import KeyboardIcon from '../../../assets/keyboard.svg';

export interface ITopLabeledInputWithKeyboard extends ITopLabeledInput {
  keyboard: IKeyboard;
}

export const TopLabeledInputWithKeyboard: React.FC<ITopLabeledInputWithKeyboard> = (props) => {
  const {
    keyboard,
    onFocus,
    onBlur,
    disabled,
    ...otherProps
  } = props;
  const {
    inputRef,
    hasFocus,
    wrappedOnFocus,
    wrappedOnBlur,
    onClickKeyboardIcon,
  } = useKeyboard(onFocus, onBlur, keyboard);
  const isReadonly = React.useContext(ReadonlyContext);
  const isDisabled = disabled || isReadonly;

  const icon = !isDisabled && (
    <StyledKeyboardIcon
      hasFocus={hasFocus}
      onClick={onClickKeyboardIcon}
    />
  );

  return (
    <StyledTopLabeledInput
      ref={inputRef}
      onFocus={wrappedOnFocus}
      onBlur={wrappedOnBlur}
      disabled={disabled}
      {...otherProps}
    >
      {icon}
    </StyledTopLabeledInput>
  );
}

interface IStyledKeyboardIcon {
  hasFocus: boolean;
}

const StyledKeyboardIcon = styled(KeyboardIcon)<IStyledKeyboardIcon>`
  width: 24px;
  height: 24px;
  margin-right: 4px;
  cursor: pointer;
  flex-shrink: 0;
  color: ${(props) => props.theme.input.default.iconColor};
  visibility: ${(props) => props.hasFocus ? 'visible' : 'hidden'};
`;

const StyledTopLabeledInput = styled(TopLabeledInput)`
  &:hover {
    ${StyledKeyboardIcon} {
      visibility: visible;
    }
  }
`;
