import { Combnk } from '../../core/probs/Combnk';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WList } from '../../elements/tokens/WList';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';

/**
 * C(n, r)
 * n choose r
 */
export class Combinations extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 2){
      return args.expectingArguments(2, 2);
    }

    const n:WList = args.getReals(0);
    if(!n){
      return null;
    }

    const k:RealElement = args.getNaturalNumber(1);
    if(!k){
      return null;
    }

    return this.enum(n, k.toNumber(), args.env);
  }

  /**
   *
   */
  private enum(n:WList, k:number, env:Environment):WMatrix{
    if(n.count === 0){
      return null;
    }
    if(k > n.count){
      return null;
    }

    const o:RealElement[] = [];

    const combs:any[] = Combnk.comb(n.count, k);

    for(let i:number = 0 ; i < combs.length ; i++){
      for(let j:number = 0 ; j < combs[i].length ; j++){
        o.push(n.getTypedItemAt(combs[i][j]));
      }
    }

    return new WMatrix(o, k, env.culture.formats.matrixFormatImpl);
  }

}
