import {createIntl} from 'react-intl';
import { v4 as uuidv4 } from 'uuid';
import {locales} from '../autoGenerate/locale';
import {
  cancelHistoryLogEvent,
  cancelHistoryLogEvents,
  postHistoryLogEvent,
  postHistoryLogEvents
} from '../api/history/historyLogEvent';
import {IHistoryEventData} from '../models/history/IHistoryEventData';
import {ClientApplicationModel} from '../models/ClientApplicationModel';
import {instantiate} from '../dependencyInjection/instantiate';
import {HistoryStore} from '../models/store/HistoryStore';
import {UserSessionStore} from '../models/store/UserSessionStore';
import {IHistoryEvent} from '../models/history/IHistoryEvent';
import {HistoryEventTypes} from '../models/history/HistoryEventTypes';

const destructiveHistoryEvents: ReadonlyArray<HistoryEventTypes> = [
  HistoryEventTypes.CurriculumDeleted,
  HistoryEventTypes.CourseDeleted,
  HistoryEventTypes.StandardDeleted,
  HistoryEventTypes.BookToCTopicRemoved,
  HistoryEventTypes.BookToCChapterRemoved,
  HistoryEventTypes.BookToCElementRemoved,
  HistoryEventTypes.ActivityDeleted,
  HistoryEventTypes.ActivityPageRemoved,
  HistoryEventTypes.LibraryBookRemoved
];

export const historyLogEvent = async (eventData: IHistoryEventData,
                                      undoCallback: () => Promise<void> | null = null,
                                      delayedPersistCallback: () => Promise<void> | null = null): Promise<string> => {

  const historyStore = instantiate(HistoryStore);
  await historyStore.finalizeNotification();

  const correlationId = eventData.correlationId ?? uuidv4();
  const description = getHistoryEventDescription(eventData);

  const userSessionStore = instantiate(UserSessionStore);
  const timeStamp = new Date();

  const historyEvent: IHistoryEvent = {
    ...eventData,
    correlationId,
    authorId: userSessionStore.memberInfo?.internalId,
    timeStamp: timeStamp.toISOString(),
    tempTimeStamp: timeStamp,
  };

  historyStore.recordHistoryEvent(historyEvent);
  historyStore.setNotification({
    delayedPersistCallback,
    historyEvents: [historyEvent],
    level: destructiveHistoryEvents.includes(eventData.eventType) ? 'destructive' : 'info',
    undoCallback:
      undoCallback ? async () => {
        historyStore.cancelNotification();
        historyStore.removeHistoryEvent(historyEvent);
        await Promise.all([
          cancelHistoryLogEvent(historyEvent, description),
          undoCallback()
        ]);
      } : null
  });

  await postHistoryLogEvent({ ...eventData, correlationId }, description);
  return description;
}

export const historyLogEvents = async (eventsData: ReadonlyArray<IHistoryEventData>,
                                       undoCallback: () => Promise<void> | null = null,
                                       delayedPersistCallback: () => Promise<void> | null = null): Promise<ReadonlyArray<string>> => {

  const historyStore = instantiate(HistoryStore);
  await historyStore.finalizeNotification();

  const correlationId = uuidv4();
  const descriptions = eventsData.map((eventData) => getHistoryEventDescription(eventData));

  await postHistoryLogEvents(
    eventsData.map((eventData) => {
      return {
        ...eventData,
        correlationId: eventData.correlationId ?? correlationId
      };
    }),
    descriptions);

  const userSessionStore = instantiate(UserSessionStore);
  const timeStamp = new Date();

  const historyEvents = eventsData.map((eventData) => {
    return {
      ...eventData,
      correlationId: eventData.correlationId ?? correlationId,
      authorId: userSessionStore.memberInfo?.internalId,
      timeStamp: timeStamp.toISOString(),
      tempTimeStamp: timeStamp,
    };
  });

  historyStore.recordHistoryEvents(historyEvents);

  const level =
    eventsData.some((eventData) => destructiveHistoryEvents.includes(eventData.eventType))
      ? 'destructive'
      : 'info';

  historyStore.setNotification({
    historyEvents,
    level,
    delayedPersistCallback,
    undoCallback:
      undoCallback ? async () => {
        historyStore.cancelNotification();
        historyStore.removeHistoryEvents(historyEvents);
        await Promise.all([
          cancelHistoryLogEvents(historyEvents, descriptions),
          undoCallback()
        ]);
      } : null
  });

  return descriptions;
}

export const getHistoryEventDescription = (eventData: IHistoryEventData) => {
  const locale = ClientApplicationModel.getInstance().clientApplicationLang === 'fr' ? 'fr-CA' : 'en-CA';
  const intl = createIntl({
    locale,
    messages: locale === 'fr-CA' ? locales.fr_CA : locales.en_CA,
  });

  const description =
    intl.formatMessage(
      { id: `historyLogEventMessages.${eventData.eventType}`},
      {
        entity1Id: `\`${eventData.entity1?.entityId}\``,
        entity1Guid: `\`${eventData.entity1?.entityGuid}\``,
        entity1Description: `"${eventData.entity1?.entityDescription}"`,

        entity2Id: `\`${eventData.entity2?.entityId}\``,
        entity2Guid: `\`${eventData.entity2?.entityGuid}\``,
        entity2Description: `"${eventData.entity2?.entityDescription}"`,

        entity3Id: `\`${eventData.entity3?.entityId}\``,
        entity3Guid: `\`${eventData.entity3?.entityGuid}\``,
        entity3Description: `"${eventData.entity3?.entityDescription}"`,

        entity4Id: `\`${eventData.entity4?.entityId}\``,
        entity4Guid: `\`${eventData.entity4?.entityGuid}\``,
        entity4Description: `"${eventData.entity4?.entityDescription}"`,

        oldValue: eventData.oldValue,
        newValue: eventData.newValue,
        targetLang: eventData.targetLang,
        correlationId: eventData.correlationId,
      });

  return description;
};
