import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { TPoly } from '../../elements/functions/models/TPoly';
import { WFunction } from '../../elements/tokens/WFunction';
import { WPolynomial } from '../../elements/tokens/WPolynomial';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';

/**
 *
 */
export class PolynomialFunction extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length < 1 || args.length > 4){
      return args.expectingArguments(1, 4);
    }

    if(args.length === 1){
      if(args.getPolynomial(0)) {
        return this.polynomial(args.getPolynomial(0), args.env);
      }
    }else if(args.length === 4){
      if(args.getReal(0) && args.getReal(1) && args.getReal(2) && args.getReal(3)) {
        return this.cano(args.getReal(0), args.getReal(1), args.getReal(2), args.getReal(3), args.env);
      }
    }

    return null;
  }

  /**
   *
   */
  private cano(
      degreeR:RealElement,
      A:RealElement,
      H:RealElement,
      K:RealElement,
      env:Environment):WFunction{

    const degree:number = degreeR.toNumber();

    if(degree !== 1 && degree !== 2){
      return null;
    }

    return new WFunction(
      new TPoly(
        env.culture,
        degree === 1 ?
          TPoly.POLY1HK :
          TPoly.POLY2HK,
        A,
        null,
        null,
        null,
        H,
        K));
  }

  /**
   *
   */
  private polynomial(
      value:WPolynomial,
      env:Environment):WFunction{

    if(value.symbols.length !== 1){
      return null;
    }
    if(value.degree > 3){
      return null;
    }

    switch(value.degree){
      case 1:
        return new WFunction(
          new TPoly(
            env.culture,
            TPoly.POLY1,
            this.coef(value, 1, env),
            this.coef(value, 0, env)));
      case 2:
        return new WFunction(
          new TPoly(
            env.culture,
            TPoly.POLY2,
            this.coef(value, 2, env),
            this.coef(value, 1, env),
            this.coef(value, 0, env)));
      case 3:
        return new WFunction(
          new TPoly(
            env.culture,
            TPoly.POLY3,
            this.coef(value, 3, env),
            this.coef(value, 2, env),
            this.coef(value, 1, env),
            this.coef(value, 0, env)));
      default:
        return null;
    }
  }

  /**
   *
   */
  private coef(poly:WPolynomial, power:number, env:Environment):RealElement{
    for(let i:number = poly.numMonomials - 1 ; i >= 0 ; i--){
      if(poly.sumPowers(i) === power){
        return poly.coefs[i];
      }
    }
    return env.culture.createNumber(0);
  }

}
