import { ContentElement } from '../elements/abstract/ContentElement';
import { ListElement } from '../elements/abstract/ListElement';
import { Node } from '../elements/abstract/Node';
import { IWriter } from '../expr/conversion/writers/IWriter';
import { InputCapabilities } from './InputCapabilities';
import { CommonError } from './CommonError';
import { BaseCorrector } from './BaseCorrector';
import { Environment } from '../expr/Environment';
import { COptions } from './COptions';

/**
 *
 */
export class CItemCompare extends BaseCorrector {

  private itemCorrector:BaseCorrector;

  /**
   *
   */
  constructor(
      itemCorrector:BaseCorrector){
    super();
    this.itemCorrector = itemCorrector;
  }

  /**
   *
   */
  public configure(origin:ContentElement, options:COptions, env:Environment, useLatex:boolean):void{
    super.configure(origin, options, env, useLatex);
    super.configureOther(this.itemCorrector);
  }

  public parse(value:string):Node{
    return this.itemCorrector.parse(value);
  }

  public correct(
      value:string,
      target:ContentElement,
      ...targets:any[]):boolean{

    const list:ListElement = <ListElement>target ;
    // Returns true if one of the element pass the test.
    // Do not let all gentle reminders be dispatched during the loop.
    let itemWithError:ContentElement;
    for(let i:number = 0 ; i < list.count ; i++){
      try{
        if(this.itemCorrector.correct(value, list.getItemAt(i))){
          return true;
        }
      }catch(e){
        if(e instanceof CommonError){
          itemWithError = list.getItemAt(i);
        }else{
          throw e;
        }
      }
    }

    // If all tests failed and there's a gentle reminder, use that gentle reminder.
    if(itemWithError){
      return this.itemCorrector.correct(value, itemWithError);
    }

    return false;
  }

  public get inputCapabilities():InputCapabilities{
    return this.itemCorrector.inputCapabilities;
  }

  public get mathKeyboard():number{
    return this.itemCorrector.mathKeyboard;
  }

  public writeTo(
      w:IWriter,
      target:ContentElement,
      ...targets:any[]):void{

    // targets not used for lists
    this.itemCorrector.writeTo(w, (<ListElement>target ).getItemAt(0));
  }

}
