import { MConstruct } from '../../../expr/conversion/models/MConstruct';
import { EmptyLeaf } from '../../../elements/functions/tree/EmptyLeaf';
import { AbstractNode } from '../../../elements/functions/tree/AbstractNode';

/**
 *
 */
export class NodeFraction extends AbstractNode {

  private _numerator:AbstractNode;

  private _denominator:AbstractNode;

  constructor( numerator:AbstractNode, denominator:AbstractNode ) {

    super();

    this._numerator = numerator;
    this._denominator = denominator;

    this._numerator.setParent( this );
    this._denominator.setParent( this );
  }

  public isFraction():boolean {
    return true;
  }

  public getToken():any[] {

    return [new MConstruct(MConstruct.TYPE_FRAC, [this._numerator.getToken(), this._denominator.getToken()])];
  }

  public simplify():AbstractNode {
    const num:AbstractNode = this._numerator.simplify();
    const den:AbstractNode = this._denominator.simplify();

    if( den instanceof EmptyLeaf ) {
      return num;
    }

    if( num instanceof EmptyLeaf ){
      return num;
    }

    if( den.isNumber() && den.toNumber() === 1) {
      return num;
    }

    return new NodeFraction( num, den );
  }

}
