import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WListOfString } from '../../elements/tokens/WListOfString';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { BattleshipBoardImpl } from '../../funcs/battleship/BattleshipBoardImpl';

/**
 *
 */
export class BattleshipPlace extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length < 1 || args.length > 2){
      return args.expectingArguments(1, 2);
    }

    const battleship:BattleshipBoardImpl = new BattleshipBoardImpl(args.env.culture);

    const grid:WMatrix = args.length === 1 ? battleship.createEmpty() : args.getMatrixWithSize(0, 10, 10);
    const notations:WListOfString = args.length === 1 ? args.getStrings(0) : args.getStrings(1);

    if(grid && notations){
      return battleship.place(grid, notations.toStrings());
    }

    return null;
  }

}
