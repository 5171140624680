import { ContentElement } from '../../elements/abstract/ContentElement';
import { WVariable } from '../../elements/tokens/WVariable';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';

/**
 * Variable that is bound to an anonymous function argument.
 */
export class BoundVariable extends ContentElement {

  private ci:WVariable;

  /**
   *
   */
  constructor(ci:WVariable){
    super();
    this.ci	= ci;
  }

  /**
   *
   */
  public getVariable():WVariable{
    return this.ci;
  }

  /**
   *
   */
  public writeTo(exporter:IMarkupExporter = null):boolean{
    if(exporter){
      this.ci.writeTo(exporter);
    }
    return true;
  }

}
