import { IPageCopy } from '../IPageCopy';

export const FETCH_PAGE_CONTENT = 'fetch-page-content';

export interface IFetchPageContentMessage {
  type: typeof FETCH_PAGE_CONTENT;
  payload: {
    pageGuid: string;
    pageCopy: IPageCopy;
    previewId: string;
  };
}
