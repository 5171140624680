import { XRound } from '../../core/XRound';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WInfinity } from '../../elements/tokens/WInfinity';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { XMath } from '../../core/XMath';

/**
 *
 */
export class Tan extends FunctionElement {

  /**
   * http://fr.wikipedia.org/wiki/Identit%C3%A9_trigonom%C3%A9trique
   * tan(x) = sin(x) / cos(x)
   */
  public callReturnElement(args:ArgumentsObject):ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }

    const angleRadians:RealElement = args.getReal(0);
    if(XRound.safeRound(Math.cos(angleRadians.toNumber())) === 0){
      return WInfinity.POSITIVE;
    }
    return args.env.culture.createNumber(XMath.safeTan(angleRadians.toNumber()));
  }

}
