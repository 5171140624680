import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { TableFormatter } from '../../elements/formats/matrices/TableFormatter';
import { WListOfString } from '../../elements/tokens/WListOfString';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';

/**
 * Apply table styling to a matrix. Add titles and totals also.
 * Applique une mise en forme de type tableau à une matrice de nombres.
 */
export class MatrixTable extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 3){
      return args.expectingArguments(3, 3);
    }

    if(args.getMatrix(0) && args.getStrings(1) && args.getStrings(2)){
      return this.applyFormat(args.getMatrix(0), args.getStrings(1), args.getStrings(2), args.env);
    }

    return null;
  }

  /**
   *
   */
  private applyFormat(
      value:WMatrix,
      rowTitles:WListOfString,
      columnTitles:WListOfString,
      env:Environment):WMatrix{

    return <WMatrix>value.applyFormat(
      new TableFormatter(
        env.culture,
        rowTitles.toStrings(),
        columnTitles.toStrings())) ;
  }

}
