import { AbstractStep } from '../AbstractStep';
import { Compartment } from '../Compartment';
import { LongSubtractionOperation } from '../LongSubtractionOperation';

/**
 *
 */
export class SubtractReset extends AbstractStep {

  constructor(operation:LongSubtractionOperation) {

    super(operation);
    const c:Compartment = Compartment.createCarry(1);
    c.text = '−1';
    this.target.push(c);
    operation.reset[operation.columnIndex(operation.position + 1)] = c;
    this.setDescription(operation.culture.getString('LongSubtractionOperation.resetLabel'));
  }

}
