import { RealElement } from '../../elements/abstract/RealElement';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';
import { IntervalClosure } from '../../elements/models/IntervalClosure';
import { CultureInfo } from '../../localization/CultureInfo';
import { AbstractFormatter } from '../../elements/formats/AbstractFormatter';

export class BaseIntervalFormatter extends AbstractFormatter {

  constructor(culture:CultureInfo){
    super(culture);
  }

  public toLocaleString(
      lBound:RealElement | null,
      rBound:RealElement | null,
      closure:IntervalClosure,
      strict:boolean):string{
    throw new Error();
  }

  public writeTo(
      exporter:IMarkupExporter,
      lBound:RealElement | null,
      rBound:RealElement | null,
      closure:IntervalClosure):boolean{
    throw new Error();
  }

  public getLeftFence(
      lBound:RealElement | null,
      closure: IntervalClosure): string {
    throw new Error();
  }

  public getRightFence(
      rBound: RealElement | null,
      closure: IntervalClosure): string {
    throw new Error();
  }

}
