import { MmlWriter } from '../../core/mml/MmlWriter';
import { DigitPlaceValueFormatter } from '../../elements/formats/numbers/DigitPlaceValueFormatter';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';
import { IMarkupFactory } from '../../elements/markers/IMarkupFactory';
import { CultureInfo } from '../../localization/CultureInfo';

/**
 *
 */
export class DigitsTableImpl implements IMarkupFactory {

  private values:number[];

  private operator:string;

  private headers:string;

  private _culture:CultureInfo;

  public get culture():CultureInfo{return this._culture;}

  constructor(
      values:number[],
      operator:string,
      headers:string,
      culture:CultureInfo){
    this.values = values;
    this.operator = operator;
    this.headers = headers;
    this._culture = culture;
  }

  public writeTo(exporter:IMarkupExporter):void{
    const w:MmlWriter = exporter.writer;
    let c:number;
    let k:number;
    let j:number;

    const integerParts:string[] = [];
    const decimalParts:string[] = [];

    let powerMax:number = 0;
    let powerMin:number = 0;

    let integerPart:string;
    let decimalPart:string;

    for(let i:number = 0 ; i < this.values.length ; i++){
      const n:number = this.values[i];
      const s:string = n.toString();
      if(s.indexOf('.') === -1){
        powerMax = Math.max(powerMax, s.length - 1);
        integerParts.push(s);
        decimalParts.push('');
      }else{
        integerPart = s.split('.')[0];
        decimalPart = s.split('.')[1];
        powerMax = Math.max(powerMax, integerPart.length - 1);
        powerMin = Math.min(powerMin, -decimalPart.length);
        integerParts.push(integerPart);
        decimalParts.push(decimalPart);
      }
    }

    w.beginTable();
    w.frame = 'solid';
    w.rowlines = 'solid';
    w.columnlines = 'solid';

    // no decimal part
    if(powerMin === 0){
      w.equalcolumns = true;
    }

    if(this.headers.indexOf('i') !== -1){
      this.writeIntegerDecimalHeader(w, powerMin, powerMax);
    }
    if(this.headers.indexOf('g') !== -1){
      this.writeGroupsHeader(w, powerMin, powerMax);
    }
    if(this.headers.indexOf('p') !== -1){
      this.writePositionsHeader(w, powerMin, powerMax);
    }

    for(k = 0 ; k < this.values.length ; k++){
      w.beginTr();

      integerPart = integerParts[k];
      decimalPart = decimalParts[k];

      if(this.operator != null){
        w.beginTd();
        if(k === this.values.length - 2){
          w.appendOperator(this.operator);
        }
        w.endTd();
      }

      for(j = 0 ; j < (powerMax + 1) - integerPart.length ; j++){
        w.beginTd();
        w.endTd();
      }

      for(c = 0 ; c < integerPart.length ; c++){
        w.beginTd();
        w.appendNumber(integerPart.charAt(c));
        w.endTd();
      }

      if(powerMin < 0){
        w.beginTd();
        w.appendOperator(this.culture.numberFormatter.decimalSeparator);
        w.endTd();

        if(decimalPart.length > 0){
          for(c = 0 ; c < decimalPart.length ; c++){
            w.beginTd();
            w.appendNumber(decimalPart.charAt(c));
            w.endTd();
          }
          for(c = 0 ; c < Math.abs(powerMin) - decimalPart.length ; c++){
            w.beginTd();
            w.appendNumber('0');
            w.endTd();
          }
        }else{
          for(c = 0 ; c < Math.abs(powerMin) ; c++){
            w.beginTd();
            w.endTd();
          }
        }
      }

      w.endTr();
    }

    w.endTable();
  }

  /**
   *
   */
  private writeIntegerDecimalHeader(w:MmlWriter, powerMin:number, powerMax:number):void{
    w.beginTr();

    if(this.operator != null){
      // add empty cell
      w.beginTd();
      w.endTd();
    }

    // Integer/decimal part header
    const integerHeaderText = this.culture.getString('MathML.digitsTableIntegerHeaderLabel');
    w.beginTd();
    w.columnspan = powerMax + 1;
    w.appendText(this.culture.formatTitle(integerHeaderText));
    w.endTd();

    if(powerMin < 0){
      w.beginTd();
      w.appendOperator(this.culture.numberFormatter.decimalSeparator);
      w.endTd();

      const decimalHeaderText = this.culture.getString('MathML.digitsTableDecimalHeaderLabel');
      w.beginTd();
      w.columnspan = Math.abs(powerMin);
      w.appendText(this.culture.formatTitle(decimalHeaderText));
      w.endTd();
    }

    w.endTr();
  }

  /**
   *
   */
  private writeGroupsHeader(w:MmlWriter, powerMin:number, powerMax:number):void{
    w.beginTr();

    if(this.operator != null){
      // add empty cell
      w.beginTd();
      w.endTd();
    }

    const groupMax:number = Math.floor((Math.floor(powerMax) / 3));
    const digitPlaceFormat:DigitPlaceValueFormatter = new DigitPlaceValueFormatter(this.culture, true);
    for(let i:number = groupMax ; i >= 0 ; i--){
      const groupName = this.culture.getString('MathML.digitsTableGroupHeaderFormat', digitPlaceFormat.toLocaleString(i * 3));
      w.beginTd();

      if(i === groupMax){
        if((powerMax + 1) % 3 !== 0){
          w.columnspan = (powerMax + 1) % 3;
        }else{
          w.columnspan = 3;
        }
      }else{
        w.columnspan = 3;
      }

      w.appendText(this.culture.formatTitle(groupName));
      w.endTd();
    }

    if(powerMin < 0){
      w.beginTd();
      w.appendOperator(this.culture.numberFormatter.decimalSeparator);
      w.endTd();

      w.beginTd();
      w.columnspan = Math.abs(powerMin);
      w.endTd();
    }

    w.endTr();
  }

  /**
   *
   */
  private writePositionsHeader(w:MmlWriter, powerMin:number, powerMax:number):void{
    w.beginTr();

    if(this.operator != null){
      // add empty cell
      w.beginTd();
      w.endTd();
    }

    const digitPlaceFormat:DigitPlaceValueFormatter = new DigitPlaceValueFormatter(this.culture, true);

    // Positions header
    for(let i:number = powerMax ; i >= powerMin ; i--){
      if(i === -1){
        w.beginTd();
        w.appendOperator(this.culture.numberFormatter.decimalSeparator);
        w.endTd();
      }

      w.beginTd();
      if(i >= 0){
        w.appendText(this.culture.formatTitle(digitPlaceFormat.toLocaleString(i % 3)));
      }else{
        w.appendText(this.culture.formatTitle(digitPlaceFormat.toLocaleString(i)));
      }
      w.endTd();
    }

    w.endTr();
  }

}
