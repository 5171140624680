import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WListOfPoints } from '../../elements/tokens/WListOfPoints';
import { WListOfSegments } from '../../elements/tokens/WListOfSegments';
import { WPoint } from '../../elements/tokens/WPoint';
import { WPolyline } from '../../elements/tokens/WPolyline';
import { WSegment } from '../../elements/tokens/WSegment';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Constructeur de segment.
 *
 * Construct a segment object. If the two end points are coincident then it
 * returns null, it could simply return a point, but not sure if it's the good approach.
 */
export class Segment extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length < 1 || args.length > 2){
      return args.expectingArguments(1, 2);
    }

    if(args.length === 1){
      if(args.getPoints(0)) {
        return this.points(args.getPoints(0));
      }
      if(args.getSegments(0)) {
        return this.segments(args.getSegments(0));
      }
      if(args.getPolyline(0)) {
        return this.polyline(args.getPolyline(0));
      }
    }else if(args.length === 2){
      if(args.getPoint(0) && args.getPoint(1)) {
        return this.point(args.getPoint(0), args.getPoint(1));
      }
    }

    return null;
  }

  /**
   *
   */
  private point(
      a:WPoint,
      b:WPoint):WSegment{
    return WSegment.tryPrase(a.toPoint(), b.toPoint());
  }

  /**
   *
   */
  private points(
      value:WListOfPoints):WSegment{

    if(value.count === 2){
      return WSegment.tryPrase(
          value.getValueAt(0),
          value.getValueAt(1));
    }

    return null;
  }

  /**
   *
   */
  private segments(
      value:WListOfSegments):WSegment{

    if(value.count === 1){
      return value.getTypedItemAt(0);
    }

    return null;
  }

  /**
   *
   */
  private polyline(
      value:WPolyline):WSegment{

    if(value.vertices.length === 2){
      return new WSegment(
        value.vertices[0],
        value.vertices[1]);
    }

    return null;
  }

}
