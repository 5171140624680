import { AbstractStep } from '../AbstractStep';
import { Compartment } from '../Compartment';
import { LongMultiplicationOperation } from '../LongMultiplicationOperation';

/**
 *
 */
export class MultAddCarry extends AbstractStep {

  private carry:number;

  constructor(operation:LongMultiplicationOperation) {

    super(operation);
    this.carry = operation.addCarry;
    const c:Compartment = Compartment.createCarry(this.carry);
    operation.sumCarries.unshift(c);
    operation.addCarried = operation.addCarry;
    operation.addCarry = 0;
    operation.addPosition++;
    this.target.push(c);

    this.setDescription(operation.culture.getString('LongMultiplicationOperation.addCarryLabel', this.carry));
  }

}
