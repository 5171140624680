import { TriangleAssert } from '../../../../funcs/asserts/geom/triangles/TriangleAssert';

/**
 * Est un triangle rectangle?
 */
export class IsRight extends TriangleAssert {

  /**
   *
   */
  protected assertTrue(
      angles:number[],
      edges:number[]):boolean{

    return 	angles[0] === 90 ||
        angles[1] === 90 ||
        angles[2] === 90;
  }

}
