/**
 *
 */
export class FacadesManager {

  public static facades:any[] =
    [{id: 'periodic_function_sin',
      name: 'Periodic Function (Sin)',
      args: [
        {id: 'start_x', name: 'Start X'},
        {id: 'start_y', name: 'Start Y'},
        {id: 'halfway_y', name: 'Halfway Period Y'},
        {id: 'period_length', name: 'Period Length'}],
      format: 'SetPeriod(FunctionABHK("sin", ({2} - {1}) / 2, 2 * Decimal(pi) / {3}, {0} + {3} / 4, Min({1}, {2}) + Abs({2} - {1}) / 2), [{0}, {0} + {3}])'},

      {id: 'periodic_function_abs',
        name: 'Periodic Function (Abs)',
        args: [
          {id: 'start_x', name: 'Start X'},
          {id: 'start_y', name: 'Start Y'},
          {id: 'halfway_y', name: 'Halfway Period Y'},
          {id: 'period_length', name: 'Period Length'}],
        format: 'SetPeriod(FunctionABHK("abs", ({1} - {2}) / ({3} / 2), 1, {0} + {3} / 2, {2}), [{0}, {0} + {3}])'},

      {id: 'step_function',
        name: 'Step Function',
        args: [	{id: 'poly', name: 'Polynomial Function'},
            {id: 'length', name: 'Step Length'}],
        format: 'SetPeriod(FunctionABHK("floor", Coefficient({0}, 1), 1 / {1}, 0, Coefficient({0}, 0)), [0, ∞[)'}
    ];

  public static lookup(idref:string):Object{
    for(let i:number = 0 ; i < FacadesManager.facades.length ; i++){
      const facade:any = FacadesManager.facades[i];
      if(facade.id === idref){
        return facade;
      }
    }
    return null;
  }

}
