import { XNumber } from '../../../core/XNumber';
import { BaseNumberFormatter } from '../../../elements/formats/BaseNumberFormatter';
import { CultureInfo } from '../../../localization/CultureInfo';

/**
 *
 */
export class WriteNumberBaseFormatter extends BaseNumberFormatter {

  private minValue:number;

  private maxValue:number;

  private maxDecimals:number;

  /**
   *
   */
  constructor(
      culture:CultureInfo,
      minValue:number,
      maxValue:number,
      maxDecimals:number) {

    super(culture);
    this.minValue = minValue;
    this.maxValue = maxValue;
    this.maxDecimals = maxDecimals;
  }

  /**
   *
   */
  public willFormat(value:number):boolean{
    if(value < this.minValue){
      return false;
    }
    if(value > this.maxValue){
      return false;
    }
    if(XNumber.decimals(value) > this.maxDecimals){
      return false;
    }
    return true;
  }

}
