import { TokenElement } from '../../elements/abstract/TokenElement';
import { AbstractFormatter } from '../../elements/formats/AbstractFormatter';
import { BaseIntervalFormatter } from '../../elements/formats/BaseIntervalFormatter';
import { BaseLinearEquationFormatter } from '../../elements/formats/BaseLinearEquationFormatter';
import { BaseListFormatter } from '../../elements/formats/BaseListFormatter';
import { BaseListNFormatter } from '../../elements/formats/BaseListNFormatter';
import { BaseMatrixFormatter } from '../../elements/formats/BaseMatrixFormatter';
import { BaseNumberFormatter } from '../../elements/formats/BaseNumberFormatter';
import { BasePointFormatter } from '../../elements/formats/BasePointFormatter';
import { BaseQuadraticEquationFormatter } from '../../elements/formats/BaseQuadraticEquationFormatter';
import { BaseRadicalFormatter } from '../../elements/formats/BaseRadicalFormatter';
import { BaseRatioFormatter } from '../../elements/formats/BaseRatioFormatter';
import { BaseRationalFormatter } from '../../elements/formats/BaseRationalFormatter';
import { BaseSetFormatter } from '../../elements/formats/BaseSetFormatter';
import { BaseTimeFormatter } from '../../elements/formats/BaseTimeFormatter';
import { IFormats } from '../../elements/formats/IFormats';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';

/**
 *
 */
export class WFormat extends TokenElement implements IFormats {

  private _formatter:AbstractFormatter;

  public get formatter():AbstractFormatter{return this._formatter;}

  constructor(
      formatter:AbstractFormatter){
    super();
    this._formatter = formatter;
  }

  public get numberFormatImpl():BaseNumberFormatter{return this.formatter instanceof BaseNumberFormatter ? <BaseNumberFormatter>this.formatter  : null;}

  public get pointFormatImpl():BasePointFormatter{return this.formatter instanceof BasePointFormatter ? <BasePointFormatter>this.formatter  : null;}

  public get listNFormatImpl():BaseListNFormatter{return this.formatter instanceof BaseListNFormatter ? <BaseListNFormatter>this.formatter  : null;}

  public get listFormatImpl():BaseListFormatter{return this.formatter instanceof BaseListFormatter ? <BaseListFormatter>this.formatter  : null;}

  public get rationalFormatImpl():BaseRationalFormatter{return this.formatter instanceof BaseRationalFormatter ? <BaseRationalFormatter>this.formatter  : null;}

  public get matrixFormatImpl():BaseMatrixFormatter{return this.formatter instanceof BaseMatrixFormatter ? <BaseMatrixFormatter>this.formatter  : null;}

  public get radicalFormatImpl():BaseRadicalFormatter{return this.formatter instanceof BaseRadicalFormatter ? <BaseRadicalFormatter>this.formatter  : null;}

  public get ratioFormatImpl():BaseRatioFormatter{return this.formatter instanceof BaseRatioFormatter ? <BaseRatioFormatter>this.formatter  : null;}

  public get setFormatImpl():BaseSetFormatter{return this.formatter instanceof BaseSetFormatter ? <BaseSetFormatter>this.formatter  : null;}

  public get timeFormatImpl():BaseTimeFormatter{return this.formatter instanceof BaseTimeFormatter ? <BaseTimeFormatter>this.formatter  : null;}

  public get lineFormatImpl():BaseLinearEquationFormatter{return this.formatter instanceof BaseLinearEquationFormatter ? <BaseLinearEquationFormatter>this.formatter  : null;}

  public get quadraticFormatImpl():BaseQuadraticEquationFormatter{return this.formatter instanceof BaseQuadraticEquationFormatter ? <BaseQuadraticEquationFormatter>this.formatter  : null;}

  public get intervalFormatImpl():BaseIntervalFormatter{return this.formatter instanceof BaseIntervalFormatter ? <BaseIntervalFormatter>this.formatter  : null;}

  /**
   *
   */
  public writeTo(exporter:IMarkupExporter = null):boolean{
    if(exporter){
      let formatType:string = 'Format';
      if(this.numberFormatImpl){
        formatType = 'NumberFormat';
      }
      if(this.listNFormatImpl){
        formatType = 'ListNFormat';
      }
      if(this.listFormatImpl){
        formatType = 'ListFormat';
      }
      if(this.rationalFormatImpl){
        formatType = 'RationalFormat';
      }
      if(this.matrixFormatImpl){
        formatType = 'MatrixFormat';
      }
      if(this.radicalFormatImpl){
        formatType = 'RadicalFormat';
      }
      if(this.ratioFormatImpl){
        formatType = 'RatioFormat';
      }
      if(this.setFormatImpl){
        formatType = 'SetFormat';
      }
      if(this.timeFormatImpl){
        formatType = 'TimeFormat';
      }
      if(this.lineFormatImpl){
        formatType = 'LineFormat';
      }
      if(this.quadraticFormatImpl){
        formatType = 'QuadraticFormat';
      }
      if(this.intervalFormatImpl){
        formatType = 'IntervalFormat';
      }
      exporter.writer.appendText(`[${formatType}]`);
    }
    return true;
  }

  /**
   *
   */
  public getType():string{
    return 'format';
  }

}
