import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { CardShortNameFormatter } from '../../elements/formats/cards/CardShortNameFormatter';
import { WList } from '../../elements/tokens/WList';
import { WNumber } from '../../elements/tokens/WNumber';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class AbstractSuit extends FunctionElement {

  private startIndex:number;

  /**
   *
   */
  constructor(startIndex:number){
    super();
    this.startIndex = startIndex;
  }

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 0){
      return args.expectingArguments(0, 0);
    }
    const format:CardShortNameFormatter = new CardShortNameFormatter(args.env.culture);
    const o:ContentElement[] = [];
    for(let i:number = this.startIndex ; i < this.startIndex + 13 ; i++){
      o.push(new WNumber(i, 1, false, format));
    }
    return new WList(o, args.env.culture.listFormatter);
  }

}
