import { ContentElement } from '../../../elements/abstract/ContentElement';
import { FunctionElement } from '../../../elements/abstract/FunctionElement';
import { WPolygon } from '../../../elements/tokens/WPolygon';
import { WString } from '../../../elements/tokens/WString';
import { ArgumentsObject } from '../../../expr/ArgumentsObject';
import { IsArrowHead } from '../../../funcs/asserts/geom/quadrilaterals/IsArrowHead';
import { IsIsoscelesTrapezoid } from '../../../funcs/asserts/geom/quadrilaterals/IsIsoscelesTrapezoid';
import { IsKite } from '../../../funcs/asserts/geom/quadrilaterals/IsKite';
import { IsParallelogram } from '../../../funcs/asserts/geom/quadrilaterals/IsParallelogram';
import { IsRectangle } from '../../../funcs/asserts/geom/quadrilaterals/IsRectangle';
import { IsRectangleTrapezoid } from '../../../funcs/asserts/geom/quadrilaterals/IsRectangleTrapezoid';
import { IsRhombus } from '../../../funcs/asserts/geom/quadrilaterals/IsRhombus';
import { IsTrapezoid } from '../../../funcs/asserts/geom/quadrilaterals/IsTrapezoid';

/**
 *
 */
export class QuadrilateralName extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length < 1 || args.length > 2){
      return args.expectingArguments(1, 2);
    }

    const polygon:WPolygon = args.getPolygon(0);
    if(!polygon){
      return null;
    }
    if(polygon.vertices.length !== 4){
      return null;
    }

    let anyName:WString = null;
    if(args.length > 1){
      anyName = args.getString(1);
      if(!anyName){
        return null;
      }
    }

    const type:string = this.quadType(polygon);
    if(type === 'quadrilateral'){
      return anyName;
    }

    return new WString(args.env.culture.getString(`QuadrilateralNames.${type}`));
  }

  /**
   * crossed=croisé
   * square=carré
   * rectangle=rectangle
   * rhombus=losange
   * trapezoid=trapezoid
   * rectTrapezoid=trapèze rectangle
   * parallelogram=parallélogramme
   * isoTrapezoid=trapèze isocèle
   * kite=cerf-volant
   * deltoid=deltoïde
   * quadrilateral=quelconque
   */
  private quadType(value:WPolygon):string{
    if(value.isCrossed){
      return 'crossed';
    }

    if(value.isConvex){
      if(value.isRegular){
        return 'square';
      }
      if(IsRectangle.check(value)){
        return 'rectangle';
      }
      if(IsRhombus.check(value)){
        return 'rhombus';
      }
      if(IsTrapezoid.check(value)){
        if(IsRectangleTrapezoid.check(value)){
          return 'rectTrapezoid';
        }
        if(IsParallelogram.check(value)){
          return 'parallelogram';
        }
        if(IsIsoscelesTrapezoid.check(value)){
          return 'isoTrapezoid';
        }
        return 'trapezoid';
      }
      if(IsKite.check(value)){
        return 'kite';
      }
    }else if(IsArrowHead.check(value)){
      return 'deltoid';
    }

    return 'quadrilateral';
  }

}
