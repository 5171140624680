import { Point } from '../../../js/geom/Point';

import { MathError } from '../../core/MathError';
import { XSort } from '../../core/XSort';
import { AnonymousFunction } from '../../elements/abstract/AnonymousFunction';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';
import { Evaluate } from '../../expr/manipulation/Evaluate';

/**
 * Ordonne les colonnes d'une matrice.
 */
export class SortColumns extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 2){
      return args.expectingArguments(2, 2);
    }

    if(args.getMatrix(0)){
      if(args.getWholeNumber(1)){
        return this.rowIndex(args.getMatrix(0), args.getWholeNumber(1));
      }
      if(args.getAnonymousFunction(1)){
        return this.rowValue(args.getMatrix(0), args.getAnonymousFunction(1), args.env);
      }
    }

    return null;
  }

  /**
   * Ordonne les colonnes en utilisant la ligne spécifiée comme valeur des colonnes.
   *
   * Si la valeur à la ligne spécifiée est égale pour deux colonnes, on ordonne selon
   * les autres lignes en partant à la ligne 0.
   */
  private rowIndex(
      value:WMatrix,
      rowIndex:RealElement):WMatrix{

    if(!rowIndex.isWholeNumber()){
      return null;
    }

    const r:number = rowIndex.toNumber();

    if(r >= value.rows){
      throw new MathError('Row out of range.');
    }

    const o:Point[] = [];
    for(let c:number = 0 ; c < value.columns ; c++){
      o.push(new Point(c, value.valueAt(r, c).toNumber()));
    }

    return this.sortImpl(value, o);
  }

  /**
   * Ordonne les colonnes selon un calcul sur les lignes de chaque colonne.
   * La fonction de calcul doit avoir un paramètre qui sera une liste de nombres.
   *
   * Si la valeur à la ligne spécifiée est égale pour deux colonnes, on ordonne selon
   * les autres lignes en partant à la ligne 0.
   */
  private rowValue(
      value:WMatrix,
      calc:AnonymousFunction,
      env:Environment):WMatrix{

    const evaluator:Evaluate = new Evaluate(calc, env);
    const o:Point[] = [];
    for(let c:number = 0 ; c < value.columns ; c++){
      const rows:ContentElement[] = [];
      for(let r:number = 0 ; r < value.rows ; r++){
        rows.push(value.valueAt(r, c));
      }
      const n:RealElement = RealElement.parseElement(evaluator.evaluate1(env.culture.listFactory.createList(rows)));
      if(!n){
        return null;
      }
      o.push(new Point(c, n.toNumber()));
    }

    return this.sortImpl(value, o);
  }

  /**
   * values:
   *  p.x: col index,
   *  p.y: col value
   */
  private sortImpl(
      value:WMatrix,
      values:Point[]):WMatrix{

    let r:number;
    let c:number;

    values.sort(XSort.yxcoordinate);

    const z:RealElement[] = [];

    for(r = 0 ; r < value.rows ; r++){
      for(c = 0 ; c < value.columns ; c++){
        z.push(value.valueAt(r, values[c].x));
      }
    }

    return new WMatrix(
      z,
      value.columns,
      value.formatter);
  }

}
