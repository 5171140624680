import { ClientApplicationModel } from '../../models/ClientApplicationModel';
import { api } from '../../utils/fetch';

export interface IPageContentTryAcquireLockResponse {
  pageLock: {
    pageGuid: string;
    status: 'acquired' | 'failed' | 'requireMigration';
    lockedBy: string;
  };
  headVersion: number; // 0 when non-converted xml page folder, otherwise return the latest version of the page.
}

export const pageContentTryAcquireLock = async (pageGuid: string,
                                                clientApplication: ClientApplicationModel): Promise<IPageContentTryAcquireLockResponse> => {

  const response = await api<IPageContentTryAcquireLockResponse>(`/Publisher/PageContent/TryAcquireLock?pageGuid=${pageGuid}&${clientApplication.params}`);
  return response.json();
};
