import { XRound } from '../../core/XRound';
import { BaseNumberFormatter } from '../../elements/formats/BaseNumberFormatter';
import { NumberBaseFormatter } from '../../elements/formats/numbers/NumberBaseFormatter';
import { CurrencyFormatter } from '../../elements/formats/numbers/CurrencyFormatter';
import { PercentFormatter } from '../../elements/formats/numbers/PercentFormatter';
import { CultureInfo } from '../../localization/CultureInfo';
import { WNumber } from '../../elements/tokens/WNumber';
import { TokenElement } from '../../elements/abstract/TokenElement';
import { WNotANumber } from '../../elements/tokens/WNotANumber';
import { WInfinity } from '../../elements/tokens/WInfinity';

export class NumberParser {

  /**
   *
   */
  private culture:CultureInfo;

  /**
   *
   */
  constructor(culture:CultureInfo){
    this.culture = culture;
  }

  /**
   *
   */
  public parseNumberWithFormat(value:string): TokenElement {
    const n = this.parseNumber(value, null, true);
    if(isNaN(n)){
      return WNotANumber.getInstance();
    }
    if(n === Number.POSITIVE_INFINITY){
      return WInfinity.POSITIVE;
    }
    if(n === Number.NEGATIVE_INFINITY){
      return WInfinity.NEGATIVE;
    }

    if(value.charAt(value.length - 1) === '%'){
      return new WNumber(n, 1, false, new PercentFormatter(this.culture));
    }

    switch(this.culture.numberFormatter.currencySymbolPlacement){
      case 'before':
        if(value.charAt(0) === this.culture.currency.unitSymbol){
          return new WNumber(n, 1, false, new CurrencyFormatter(this.culture));
        }
      case 'after':
        if(value.charAt(value.length - 1) === this.culture.currency.unitSymbol){
          return new WNumber(n, 1, false, new CurrencyFormatter(this.culture));
        }
    }

    return this.culture.createNumber(n);
  }

  /**
   * Remplacer le séparateur décimal par .
   * Remplacer le séparateur de millier par rien
   * Remplacer le signe moins par le tiret
   */
  public parseNumber(
      valueArg:string,
      formatter:BaseNumberFormatter = null,
      autoDetectFormatArg:boolean = false):number{

    let value = this.sanitizeNumber(valueArg);
    let autoDetectFormat = autoDetectFormatArg;

    if(value === '+∞'){
      return Number.POSITIVE_INFINITY;
    }
    if(value === '-∞'){
      return Number.NEGATIVE_INFINITY;
    }
    if(value === '∞'){
      return Number.POSITIVE_INFINITY;
    }

    if(formatter instanceof NumberBaseFormatter){
      return parseInt(
        value,
        (<NumberBaseFormatter>formatter ).base);
    }

    let factor:number = 1;

    if((formatter instanceof PercentFormatter) || autoDetectFormat) {
      if (value.charAt(value.length - 1) === '%') {
        value = value.substring(0, value.length - 1);
        factor = 0.01;
        autoDetectFormat = false;
      }
    }

    if((formatter instanceof CurrencyFormatter) || autoDetectFormat){
      switch(this.culture.numberFormatter.currencySymbolPlacement){
        case 'before':
          if(value.charAt(0) === this.culture.currency.unitSymbol){
            value = value.substring(1);
          }
          break;
        case 'after':
          if(value.charAt(value.length - 1) === this.culture.currency.unitSymbol){
            value = value.substring(0, value.length - 1);
          }
          break;
      }
    }

    // Number(?) can accepts strings like "1.0E3" and convert it to 1000 in that case.
    // We don't want to let the user enter in that format since it's a Flash specific format.
    if(value.toLowerCase().indexOf('e') !== -1){
      return NaN;
    }

    const n:number = Number(value);
    if(isNaN(n)){
      return n;
    }
    return XRound.safeRound(n * factor);
  }

  /**
   * Parse a number with the following exceptions after sanitizing the string:
   * 	a) "." is parsed as 0
   *  b) "-." is parsed as 0
   *
   * In the case of "-.", the client code looses the information
   * about negative value, which it must handle.
   *
   * In case of a good answer after validating with the 0,
   * the client should call validateZeroBeforeDecimalSep which could
   * throw an error asking the user to write at least one digit before
   * the decimal separator.
   */
  public parseNumber2(valueArg:string):number{
    const value = this.sanitizeNumber(valueArg);

    if(value === '.'){
      return 0;
    }
    if(value === '-.'){
      return 0;
    }

    // otherwise, if we write -.6, parseNumber will
    // evaluate to -0.6 which is correct.
    return this.parseNumber(value);
  }

  /**
   *
   */
  public sanitizeNumber(valueArg:string):string{
    let value = valueArg.replace(/\u2212/g, '-');
    value = value.replace(/ /g, '');
    value = value.replace(new RegExp(this.culture.numberFormatter.thousandSeparator, 'g'), '');
    if(this.culture.numberFormatter.decimalSeparator === ','){
      value = value.replace(/,/g, '.');
    }
    return value;
  }

}
