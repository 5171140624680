import { IContextModel } from '@scolab/content-model';
import { InlineContext } from '../../../expr/InlineContext';

export class Subsets extends InlineContext {

  constructor() {

    super('Subsets', (<string[]>['a', 'b']), null);
  }

  public getContextModel():IContextModel{
    const o:IContextModel = {
      variables: [{
        type: 'texts',
        id: 'subsetsFr',
        texts: {
          useList: true,
          values: [
            {
              value: 'pair'
            }, {
              value: 'multiple de {0}'
            }, {
              value: 'inférieur à {0}'
            }, {
              value: 'supérieur à {0}'
            }, {
              value: 'impair'
            }
          ]
        }
      }, {
        type: 'texts',
        id: 'subsetsEn',
        texts: {
          useList: true,
          values: [
            {
              value: 'even'
            }, {
              value: 'multiple of {0}'
            }, {
              value: 'less than {0}'
            }, {
              value: 'greater than {0}'
            }, {
              value: 'odd'
            }
          ]
        }
      }, {
        value: 'if(isfr(), subsetsFr, subsetsEn)',
        id: 'subsets'
      }, {
        type: 'texts',
        id: 'complementsFr',
        texts: {
          useList: true,
          values: [
            {
              value: 'impair'
            }, {
              value: '<b>non</b> multiple de {0}'
            }, {
              value: 'supérieur ou égal à {0}'
            }, {
              value: 'inférieur ou égal à {0}'
            }, {
              value: 'pair'
            }
          ]
        }
      }, {
        type: 'texts',
        id: 'complementsEn',
        texts: {
          useList: true,
          values: [
            {
              value: 'odd'
            }, {
              value: '<b>not</b> a multiple of {0}'
            }, {
              value: 'greater or equal to {0}'
            }, {
              value: 'less or equal than {0}'
            }, {
              value: 'even'
            }
          ]
        }
      }, {
        value: 'if(isfr(), complementsFr, complementsEn)',
        id: 'complements'
      }, {
        type: 'function',
        piecewise: {
          inputArguments: 'z',
          pieces: [{
            value: 'λ(x, Mod(x, 2) = 0)',
            condition: 'a=0'
          }, {
            value: 'λ(x, Mod(x, b) = 0)',
            condition: 'a=1'
          }, {
            value: 'λ(x, x < b)',
            condition: 'a=2'
          }, {
            value: 'λ(x, x > b)',
            condition: 'a=3'
          }, {
            value: 'λ(x, Not(Mod(x, 2)=0))',
            condition: 'a=4'
          }
          ]
        },
        id: 'filters'
      }, {
        value: 'Print(subsets_a, b)',
        id: 'subset'
      }, {
        value: 'Print(complements_a, b)',
        id: 'complement'
      }, {
        value: 'filters(a)',
        id: 'filter'
      }
      ]
    };

    return o;
  }

}
