import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { ListElement } from '../../elements/abstract/ListElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Retourne une liste qui contient un certains nombre
 * d'éléments à partir du début de la liste.
 */
export class Take extends FunctionElement {
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 2) {
      return args.expectingArguments(2, 2);
    }

    const [list, real] = [args.getList(0), args.getReal(1)];
    if (list && real) {
      return this.list(list, real);
    }

    return null;
  }

  private list(list: ListElement, number: RealElement): ListElement {
    if (!number.isWholeNumber()) {
      return null;
    }
    return list.transform(this.indices(Math.min(number.toNumber(), list.count)));
  }

  private indices(length: number): number[] {
    return Array.from({ length }, (_, i) => i);
  }
}
