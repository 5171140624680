import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class If extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 3){
      return args.expectingArguments(3, 3);
    }
    if(args.getBoolean(0) && args.getContentElement(1) && args.getContentElement(2)){
      return args.getBoolean(0).toBoolean() ?
        args.getContentElement(1) :
        args.getContentElement(2);
    }
    return null;
  }

}
