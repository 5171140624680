import React from 'react';
import styled from 'styled-components';
import { preventAndStopPropagation } from '../../../utils/mouse';
import { InputClearButton } from '../Input';
import { ReadonlyContext } from '../../../contexts/ReadonlyContext';
import {
  BaseSyntaxHighlightInput,
  IBaseSyntaxHighlightInputProps,
} from './BaseSyntaxHighlightInput';

export interface ISyntaxHighlightInputProps extends IBaseSyntaxHighlightInputProps {
  readonly onClear?: () => void;
  readonly hasError?: boolean;
  readonly onMouseDown?: (event: React.MouseEvent) => void;
}

export const SyntaxHighlightInput: React.FC<ISyntaxHighlightInputProps> = (props) => {
  const {
    value,
    onChange,
    onClear,
    className,
    disabled,
    hasError,
    nextHighlight,
    placeholder,
    autocompleteSuggestions,
    onMouseDown,
  } = props;

  const [hasFocus, setHasFocus] = React.useState(false);
  const isReadonly = React.useContext(ReadonlyContext);
  const isDisabled = disabled || isReadonly;

  const onFocus = React.useCallback((event: React.FocusEvent<HTMLElement>) => {
    setHasFocus(true);
  }, [setHasFocus]);
  const onBlur = React.useCallback((event: React.FocusEvent<HTMLElement>) => {
    setHasFocus(false);
  }, [setHasFocus]);

  const clearButton =
    !isDisabled && onClear
      ? (
        <InputClearButton
          onClear={onClear}
        />
      ) : null;

  return (
    <BorderContainer
      className={className}
      disabled={isDisabled}
      hasFocus={hasFocus}
      hasError={hasError}
      onClick={preventAndStopPropagation}
      onMouseDown={onMouseDown}
    >
      <StyledBaseSyntaxHighlightInput
        value={value}
        onChange={onChange}
        disabled={disabled}
        placeholder={placeholder}
        nextHighlight={nextHighlight}
        onFocus={onFocus}
        onBlur={onBlur}
        autocompleteSuggestions={autocompleteSuggestions}
      />
      {clearButton}
    </BorderContainer>
  );
};

interface IError {
  hasError: boolean;
}

interface IContainer extends IError {
  disabled: boolean;
  hasFocus: boolean;
}

const StyledBaseSyntaxHighlightInput = styled(BaseSyntaxHighlightInput)`
  padding: 8px;
`;

const BorderContainer = styled.div<IContainer>`
  display: inline-flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  gap: 0;
  color: ${(props) => (props.disabled ? props.theme.input.default.disabled : props.theme.input.default).color};
  border: ${(props) =>
    (props.disabled ?
      props.theme.input.default.disabled :
      (props.hasError ? props.theme.input.default.error :
        (props.hasFocus ? props.theme.input.default.focus : props.theme.input.default))).border};
  background-color: ${(props) => (props.disabled ? props.theme.input.default.disabled : props.theme.input.default).backgroundColor};
  border-radius: 4px;
  outline: unset;
  min-width: 0px;

  ${(props) => props.disabled && `
    pointer-events: none;
  `}

  &:hover {
    border: ${(props) =>
    (props.disabled ? props.theme.input.default.disabled :
      (props.hasError ? props.theme.input.default.error :
        props.theme.input.default.focus)).border};
  }
`;
