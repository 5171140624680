import { IPageContent } from '@scolab/content-model';
import { IPageCopy } from '../IPageCopy';

export const PAGE_CONTENT_UPDATE = 'page-content-update';

export interface IPageContentUpdateMessage {
  type: typeof PAGE_CONTENT_UPDATE;
  payload: {
    pageGuid: string;
    pageCopy: IPageCopy;
    pageContent: IPageContent;
    previewId: string;
  };
}
