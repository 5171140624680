import { IContextModel, IVariableModel } from '@scolab/content-model';
import { IMathCoreApiInstance } from '../IMathCoreApiInstance';
import { ContextLevels } from './ContextLevels';
import { VariableWrapper } from './VariableWrapper';
import { IPrngWrapper } from '../models/IPrngWrapper';
import { IUserInputCorrector } from '../models/IUserInputCorrector';
import { ICurrencyInfo } from '../models/ICurrencyInfo';
import { IEvalXY } from '../models/IEvalXY';
import { ICultureInfo } from '../models/ICultureInfo';
import { ITriangleModel } from '../models/ITriangleModel';
import { ContextWithErrors } from './ContextWithErrors';
import { IOptionsBuilder } from '../IOptionsBuilder';
import { OptionsBuilder } from './OptionsBuilder';
import { IMathCoreApiInstanceDump } from '../IMathCoreApiInstanceDump';
import { CultureInfo } from '../../nm2/localization/CultureInfo';
import { Context, ContextDiffType, VariableValidationStatus } from '../../nm2/expr/Context';
import { ExpressionsUtil } from '../../nm2/expr/ExpressionsUtil';
import { Variable } from '../../nm2/expr/Variable';
import { ILocaleConfiguration } from '../../nm2/localization/ILocaleConfiguration';
import { TriangleModel } from '../../nm2/elements/models/TriangleModel';
import { FormProvider } from '../../nm2/elements/factories/FormProvider';
import { FormatProvider } from '../../nm2/elements/factories/FormatProvider';
import { CurrencyInfo } from '../../nm2/localization/CurrencyInfo';
import { LatexWriter } from '../../nm2/expr/conversion/writers/LatexWriter';
import { KeyboardConfiguration } from '../../nm2/correction/KeyboardConfiguration';
import { NumberOrPercent } from '../../nm2/correction/NumberOrPercent';
import { WString } from '../../nm2/elements/tokens/WString';
import { ContentElement } from '../../nm2/elements/abstract/ContentElement';
import { Expression } from '../../nm2/elements/abstract/Expression';
import { CFactory } from '../../nm2/correction/CFactory';
import { COptions } from '../../nm2/correction/COptions';
import { IEval2 } from '../../nm2/expr/manipulation/optimized/IEval2';
import { ListElement } from '../../nm2/elements/abstract/ListElement';
import { Environment } from '../../nm2/expr/Environment';
import { FunctionCompiler } from '../../nm2/expr/manipulation/optimized/FunctionCompiler';
import { Prng } from '../../nm2/core/prng/Prng';
import { IExtensionsMethods } from '../../nm2/expr/IExtensionsMethods';
import { Node } from '../../nm2/elements/abstract/Node';

export class MathCoreApiImpl implements IMathCoreApiInstance {

  private readonly culture: CultureInfo;
  private readonly desiredSeed: number;
  private readonly variablesModel: IContextModel;
  private readonly activeValuesModel: IContextModel;
  private readonly lateValuesModel: IContextModel;
  private readonly workingContexts: IWorkingContexts;
  private liveValues: IValuesDictionary;
  private _variablesContext: Context;
  private _liveContext: ContextWithErrors;
  private _activeContext: Context;
  private _lateContext: Context;
  private _captureContext: Context;

  /**
   * When live context is invalidated, keep a copy of the old
   * context in order to recycle some already evaluated expression.
   */
  private _liveContextOld: Context;

  private liveValuesVersion: number = 0;

  /**
   * If strict contexts access is on, then accessing a context
   * that is not ready will result in an error.
   */
  private strictContextsAccess: boolean;

  /**
   * Provides additional functionalities to math-core.
   */
  private extensions: IExtensionsMethods;

  /**
   * Indicates whether the live values were initialized,
   * allowing to make sure that expressions involving live,
   * active or late values can be safely evaluated.
   *
   * @type {boolean}
   */
  private liveValuesAvailable: boolean = false;

  public constructor(localeConfiguration: ILocaleConfiguration,
                     desiredSeed: number,
                     variablesModel: IContextModel,
                     activeValuesModel: IContextModel,
                     lateValuesModel: IContextModel,
                     latexToMarkup: (latex:string) => string,
                     getEnvVariable: (name:string) => string,
                     strictContextsAccess: boolean) {
    this.culture = new CultureInfo(localeConfiguration);
    this.desiredSeed = desiredSeed;
    this.variablesModel = variablesModel;
    this.activeValuesModel = activeValuesModel;
    this.lateValuesModel = lateValuesModel;
    this.strictContextsAccess = strictContextsAccess;

    this.extensions = {
      latexEnabled: latexToMarkup('') != null,
      latexToMarkup: (latex: string) => {
        return latexToMarkup(latex);
      },
      getEnvVariable: (name:string) => {
        return getEnvVariable(name);
      }
    };

    this.liveValues = {};
    this.workingContexts = {};
    this._variablesContext = null;
    this._liveContext = null;
    this._activeContext = null;
    this._lateContext = null;
    this._liveContextOld = null;
  }

  public generateDiff(otherContext: Context, diffVariables: ReadonlyArray<string>): void {
    if (this._variablesContext !== null) {
      throw new Error('Cannot generate different context because it was already generated');
    }
    this._variablesContext = this.generateVariables(otherContext, diffVariables);
  }

  public getVariablesContext(): Context {
    this.validateContext(ContextLevels.Variables);
    if (this._variablesContext === null) {
      this._variablesContext = this.generateVariables(null, null);
    }
    return this._variablesContext;
  }

  public getLiveContext(): ContextWithErrors {
    this.validateContext(ContextLevels.Live);
    if (this._liveContext === null) {
      if (this.liveValues === null || Object.keys(this.liveValues).length === 0) {
        return new ContextWithErrors(this.getVariablesContext());
      }

      const liveContext: ContextWithErrors =
          new ContextWithErrors(
              new Context(
                null,
                this.getVariablesContext(),
                this.culture,
                null,
                false,
                false,
                this.extensions));

      Object.keys(this.liveValues).forEach((varName: string) => {
        const { value, workingContextId, options } = this.liveValues[varName];
        const recycledValue = this.recycleLiveValue(varName, value, workingContextId);
        if (recycledValue !== null) {
          liveContext.catchError(
              () => liveContext.context.addDeclarationFromElement(varName, recycledValue),
              () => undefined);
        } else {
          liveContext.catchError(() =>
              {
                const liveValue = this.evaluateExpression(value, workingContextId, isNaN(Number(options)) ? -1 : options).getValue();
                liveContext.context.addDeclarationFromElement(
                  varName,
                  liveValue);
              },
              () => liveContext.context.addDeclarationFromString(varName, '') // Add empty variable for the specified name.
          );
        }
      });

      this._liveContext = liveContext;
      this._liveContextOld = null;
    }
    return this._liveContext;
  }

  public getLiveContextVersion(): number {
    return this.liveValuesVersion;
  }

  public getActiveContext(): Context {
    this.validateContext(ContextLevels.Active);
    if (this._activeContext === null) {
      if (this.activeValuesModel === null) {
        return this.getLiveContext().context;
      }
      this._activeContext =
        Context.generate(
          null,
          this.activeValuesModel,
          this.culture,
          this.extensions,
          true,
          this.getLiveContext().context,
          null,
          false);
    }
    return this._activeContext;
  }

  public getLateContext(): Context {
    this.validateContext(ContextLevels.Late);
    if (this._lateContext === null) {
      if (this.lateValuesModel === null) {
        return this.getActiveContext();
      }
      this._lateContext =
        Context.generate(
          null,
          this.lateValuesModel,
          this.culture,
          this.extensions,
          true,
          this.getActiveContext(),
          null,
          false);
    }
    return this._lateContext;
  }

  public getActualSeed(): number {
    return this.getVariablesContext().prng.initialSeed;
  }

  /**
   * -bumpSeed:
   *   Use last letter to determine the actual bump.
   *   Could be more sophisticated, but this should do the job.
   *   Don't use first letter since legacy id's were generated
   *   sequentially like "apygkn", "apygko" so first letter could
   *   be constant for all id's in a page.
   */
  public getNewPrng(bumpSeed:string, maxBumps: number = 10): IPrngWrapper {
    const prng = new Prng(this.getActualSeed());
    if(bumpSeed && bumpSeed.length > 0){
      let k: number = 0;
      while(k < bumpSeed.charCodeAt(bumpSeed.length - 1) % (maxBumps + 1)){
        prng.random(); // consume one seed.
        k++;
      }
    }
    return {
      random: () => prng.random()
    };
  }

  public getVarNames(contextLevel: ContextLevels | string): ReadonlyArray<string> {
    const context = this.getContextByLevel(contextLevel);
    if(!context)return [];
    return context.ids;
  }

  public getVarDependencies(varName: string, contextLevel: ContextLevels | string): ReadonlyArray<string>{
    const context = this.getContextByLevel(contextLevel);
    if(!context)return [];
    const variable = context.getDeclaration(varName, true);
    if(!variable)return [];
    return variable.expression.dependencies.variables;
  }

  public getDeclarationDepth(varName: string, contextLevel: ContextLevels | string): number {
    this.validateContext(contextLevel);
    return this.getContextByLevel(contextLevel).getDeclarationDepth(varName);
  }

  public isDefined(varName: string, contextLevel: ContextLevels | string): boolean {
    this.validateContext(contextLevel);
    return this.getContextByLevel(contextLevel).getDeclaration(varName, false) !== null;
  }

  public getText(varName: string, contextLevel: ContextLevels): string {
    this.validateContext(contextLevel);
    return this.evaluateVariable(varName, contextLevel).getLabel();
  }

  public evaluateBoolean(expression: string, contextLevel: ContextLevels | string = 'None', options: number = -1): boolean {
    this.validateContext(contextLevel);
    const evaluator: ExpressionsUtil = new ExpressionsUtil(this.culture, this.extensions);
    return evaluator.toBoolean(expression, this.getContextByLevel(contextLevel), false, options);
  }

  public evaluateExpression(expression: string, contextLevel: ContextLevels | string = 'None', options: number = -1): VariableWrapper {
    this.validateContext(contextLevel);
    const evaluator: ExpressionsUtil = new ExpressionsUtil(this.culture, this.extensions);
    const context: Context = this.getContextByLevel(contextLevel);
    return new VariableWrapper(
      context !== null ?
        context.getEnvironment() :
        this.getVariablesContext().getEnvironment(),
      null,
      evaluator.toContentElement(expression, context, options));
  }

  public evaluateVariable(varName: string, contextLevel: ContextLevels | string): VariableWrapper {
    this.validateContext(contextLevel);
    const context: Context = this.getContextByLevel(contextLevel);
    const variable: Variable = context.getDeclaration(varName, false);
    return new VariableWrapper(variable !== null ? variable.env : context.getEnvironment(), variable, null);
  }

  /**
   * Creates an object that has an evaluation function f(x, y) => number.
   *
   * Variables x and y are allowed in the expression.
   *
   * @param expression
   */
  public createXYEvaluator(expression: string): IEvalXY {
    const expressionsUtil: ExpressionsUtil = new ExpressionsUtil(this.culture, this.extensions);
    const Fxy = expressionsUtil.toFunction(`λ(x, y, (${expression}))`);

    if(!Fxy)return null;

    const cFxy: IEval2 = FunctionCompiler.compileTwoArgumentsFunction(Fxy);

    if(!cFxy)return null;

    return {
      eval: (x: number, y: number) => cFxy.eval2(x, y)
    };
  }

  /**
   * knownMeasures: set unknown measures to NaN.
   */
  public resolveTriangle(knownMeasures: ITriangleModel): ITriangleModel {
    return TriangleModel.parse(
      knownMeasures.a,
      knownMeasures.b,
      knownMeasures.c,
      knownMeasures.A,
      knownMeasures.B,
      knownMeasures.C,
    );
  }

  public updateValue(name: string, value: string, workingContextId: string, options: number = -1, groupId: string = null): void {
    this.invalidateLiveContext();
    const newValues: IValuesDictionary = {};
    newValues[name] = { value, workingContextId, options, groupId };
    Object.assign(this.liveValues, newValues);
  }

  public removeValue(name: string): void {
    this.invalidateLiveContext();
    if (this.liveValues.hasOwnProperty(name)) {
      const newValues: IValuesDictionary = { ...this.liveValues };
      delete newValues[name];
      this.liveValues = newValues;
    }
  }

  /**
   * Captured values are evaluated against the Active values (including object's live values
   * and Active values defined in the editor) and are exposed as extra Active values after.
   *
   * The only way that captured values are updated is by making subsequent call to captureValue.
   * @param name
   */
  public captureValue(name: string): void {
    if (!this._captureContext) {
      this._captureContext = new Context(null, null, this.culture, null, false, false, this.extensions);
    }
    this._captureContext.addDeclarationFromElement(name, this.evaluateExpression(name, ContextLevels.Active).getElement());
  }

  public getValueNamesByGroup(groupId: string): ReadonlyArray<string> {
    return Object.keys(this.liveValues).filter((name: string) => this.liveValues[name].groupId === groupId);
  }

  public setLiveValuesAvailable(): void {
    this.liveValuesAvailable = true;
  }

  public createWorkingContext(id: string = null): string {
    const contextId: string =
      id || `context-${(Math.floor(Math.random() * 0xFFFF)).toString(16)}`;
    this.workingContexts[contextId] = {
      context: new Context(null, this.getVariablesContext(), this.culture, null, false, false, this.extensions),
      declarations: [],
    };
    return contextId;
  }

  public hasWorkingContext(id: string): boolean {
    return this.workingContexts.hasOwnProperty(id);
  }

  /**
   * Add a new variable to a working context.
   *
   * It's useful to cache the result of an evaluation.
   *
   * Call to createWorkingContext must be done before calling this function.
   *
   * @param workingContextId
   * @param name
   * @param value
   * @param options
   */
  public addDeclarationToContext(workingContextId: string, name: string, value: string, options: number): void {
    if (!this.workingContexts.hasOwnProperty(workingContextId)) {
      throw new Error(`Working context (${workingContextId}) doesn't exist`);
    }
    const workingContext = this.workingContexts[workingContextId];
    workingContext.context.addDeclarationFromString(name, value, options);
    workingContext.declarations = workingContext.declarations.concat({name, value, options});
  }

  public getCorrectorForVariable(varName: string, options: COptions | null, contextLevel: ContextLevels): IUserInputCorrector {
    this.validateContext(contextLevel);
    const varW: VariableWrapper = this.evaluateVariable(varName, contextLevel);
    const variable = varW.getVariable();

    return this.getCorrectorImpl(
      variable.result,
      options,
      variable.env,
      variable.form,
      variable.format,
      options ? this.parseTolerance(options.plusMinus) : null);
  }

  public getCorrectorForExpression(expression: string, options: COptions | null, contextLevel: ContextLevels): IUserInputCorrector {
    this.validateContext(contextLevel);
    const varW: VariableWrapper = this.evaluateExpression(expression, contextLevel);

    return this.getCorrectorImpl(
      new Expression(new Node(varW.getValue())),
      options,
      varW.getEnvironment(),
      null,
      null,
      options ? this.parseTolerance(options.plusMinus) : null);
  }

  public getCurrencyInfo(name: 'cad' | 'usd' | 'auto'): ICurrencyInfo {
    if (name.toLowerCase() === 'cad') {
      return CurrencyInfo.CAD;
    }
    if (name.toLowerCase() === 'usd') {
      return CurrencyInfo.USD;
    }
    return (this.culture.currency);
  }

  public getCultureInfo(): ICultureInfo {
    return this.culture;
  }

  public getOptionsBuilder(): IOptionsBuilder {
    return new OptionsBuilder(0);
  }

  public getDump(): IMathCoreApiInstanceDump {
    const liveVariables: IVariableModel[] = [];

    Object.keys(this.liveValues).forEach((varName: string) => {
      const { value, options } = this.liveValues[varName];
      liveVariables.push({
        id: varName,
        value,
        options: options ? {flags: options} : null,
      });
    });

    const debugLiveValues: IContextModel = {
      radians: false,
      randomSymbols: 0,
      variables: liveVariables,
    };

    const envVariablesNames = this.extensions.getEnvVariable(null).split(',');
    const envVariables: {[key:string]: string} = {};
    envVariablesNames.forEach((name: string) => {
      if(name === '')return;
      envVariables[name] = this.extensions.getEnvVariable(name);
    });

    return {
      'seed': this.getActualSeed(),
      'localeConfiguration': {
        ...this.culture.configuration
      },
      'context': {
        'variablesModel': this.variablesModel,
        'debugLiveValues': debugLiveValues,
        'activeValuesModel': this.activeValuesModel,
        'lateValuesModel': this.lateValuesModel,
        ...this.getWorkingContextsDump(),
      },
      'envVariables': envVariables
    };
  }

  private getWorkingContextsDump(): {[key: string]: IContextModel} {
    const workingContextsDump: any = {};
    Object.keys(this.workingContexts).forEach((workingContextId: string) => {
      const { declarations } = this.workingContexts[workingContextId];
      const workingContextModel: IContextModel = {
        radians: false,
        randomSymbols: 0,
        variables: declarations.map((declaration) => {
          const {name, value, options} = declaration;
          return {
            id: name,
            value,
            options: options ? {flags: options} : null,
          };
        }),
      };
      workingContextsDump[workingContextId] = workingContextModel;
    });
    return workingContextsDump;
  }

  private invalidateLiveContext(): void {
    this.liveValuesVersion++;
    if (this._liveContext) {
      this._liveContextOld = this._liveContext.context;
    }
    this._liveContext = null;
    this._activeContext = null;
    this._lateContext = null;
  }

  private getCorrectorImpl(expression: Expression,
                           options: COptions | null,
                           environment: Environment,
                           form?: FormProvider,
                           format?: FormatProvider,
                           plusMinus?: NumberOrPercent): IUserInputCorrector {

    const corrector =
      CFactory.corrector(
        expression,
        options,
        environment,
        form,
        format,
        plusMinus,
        true);

    let target0: ContentElement = null;
    let target1: ContentElement = null;
    let acceptEmpty: boolean = false;

    if (expression.root.isLeaf) {
      target0 = expression.root.value;
      acceptEmpty = ((target0 instanceof WString) && (target0 as WString).getString() === '') ||
        ((target0 instanceof ListElement) && (target0 as ListElement).count === 0);
    } else {
      target0 = expression.root.childs[1].value;
      target1 = expression.root.childs[2].value;
    }

    return {
      isValid: corrector !== null && corrector !== undefined,
      acceptEmpty,
      getUserInputChecker: () => (userInput: string): boolean => {
        if(!corrector)return false;
        return target1 != null ?
          corrector.correct(userInput, target0, target1) :
          corrector.correct(userInput, target0);
      },
      getUserInputParser: () => (userInput: string): string => {
        if(!corrector)return null;
        if(userInput === null || userInput === undefined){
          return null;
        }
        const node = corrector.parse(userInput);
        return node ? node.userData : null;
      },
      getExampleMarkup: () => {
        if(!corrector)return null;
        const writer = new LatexWriter(environment.culture);
        if(target0 !== null){
          if (target1 !== null) {
            corrector.writeTo(writer, target0, target1);
          }else{
            corrector.writeTo(writer, target0);
          }
        }else{
          return null;
        }
        return writer.content;
      },
      getInputCapabilities: () => {
        if(!corrector)return null;
        return corrector.inputCapabilities;
      },
      getKeyboardConfiguration: () => {
        if(!corrector)return null;

        const keyboardConfiguration =
          KeyboardConfiguration.createConfiguration(
            corrector.mathKeyboard,
            expression,
            environment);

        if(!keyboardConfiguration){
          return null;
        }

        return {
          digits: keyboardConfiguration.digits,
          letters: keyboardConfiguration.letters,
          symbols: keyboardConfiguration.symbols.concat(),
          splitIndex: keyboardConfiguration.splitIndex,
          exponent: keyboardConfiguration.exponent
        };
      },
      useElementaryKeyboard12: corrector ? corrector.useElementaryKeyboard12 : false
    };
  }

  private generateVariables(diffContext: Context, diffVariables: ReadonlyArray<string>): Context {
    return generateVariablesWithDiff(
      diffContext,
      diffVariables,
      this.variablesModel,
      this.culture,
      this.extensions,
      this.desiredSeed,
      false,
      false,
      false,
    );
  }

  private getContextByLevel(contextLevel: ContextLevels | string): Context {
    switch (contextLevel) {
      case ContextLevels.Variables:
        return this.getVariablesContext();
      case ContextLevels.Live:
        return this.getLiveContext().context;
      case ContextLevels.Active:
        return this.getActiveContext();
      case ContextLevels.Late:
        return this.getLateContext();
      case ContextLevels.Captured:
        return this._captureContext;
      default:
        if (this.workingContexts.hasOwnProperty(contextLevel)) {
          return this.workingContexts[contextLevel].context;
        }
    }
    return null;
  }

  private parseTolerance(value: string): NumberOrPercent {
    const t = NumberOrPercent.tryParse(value);
    if (t !== null) {
      return t;
    }
    const n = this.evaluateExpression(value, ContextLevels.Variables).getNumber();
    if (n !== null) {
      return new NumberOrPercent(n.value, false);
    }
    return null;
  }

  private validateContext(contextLevel: ContextLevels | string): void {
    if (!this.strictContextsAccess) {
      return;
    }
    if (this.liveValuesAvailable) {
      return;
    }
    // working contexts are based on variables, so only the live, active
    // and late are invalid if live values are not defined.
    if (contextLevel !== ContextLevels.Live &&
      contextLevel !== ContextLevels.Active &&
      contextLevel !== ContextLevels.Late) {
      return;
    }
    throw new Error(`Context ${contextLevel} not ready for expression evaluation.`);
  }

  /**
   * Try to reuse a previously evaluated expression.
   *
   * - Do not attempt to recycle value if it's based on another
   *   context since the underlying context could have changed.
   */
  private recycleLiveValue(name: string, value: string, workingContextId: string): ContentElement {
    if (workingContextId) return null;
    if(this._liveContextOld === null)return null;
    const oldVariable = this._liveContextOld.getDeclaration(name, true);
    if(oldVariable === null)return null;
    const oldValue = oldVariable.toLeaf(false);
    if(oldValue === null)return null;
    return oldValue.userData === value ? oldValue : null;
  }

}

export const generateVariablesWithDiff = (diffContext: Context,
                                          diffVariables: ReadonlyArray<string> | null,
                                          variablesModel: IContextModel,
                                          culture: CultureInfo,
                                          extensions: IExtensionsMethods,
                                          desiredSeed: number,
                                          debug: boolean,
                                          withSourceMap: boolean,
                                          persistWarnings: boolean): Context => {
  let context: Context = null;
  let attempts: number = 1;
  let nextSeed: number = desiredSeed;

  if (nextSeed < 1 || nextSeed % 1 !== 0) {
    nextSeed = 1 + Math.floor(Math.random() * 0xFFFFFFFE);
  }

  const seedGenerator: Prng = new Prng(nextSeed);
  const maxAttempts: number = 10;

  if (variablesModel === null) {
    return new Context(null, null, culture, new Prng(nextSeed), false, debug, extensions);
  }

  const variablesWithExplicitChecks = (variablesModel.variables ?? [])
    .filter((variable) => variable.forceDifferentResultOnNewSeed)
    .map((variable) => variable.id.toString());
  const variablesThatHaveToBeDifferent = (diffVariables ?? []).concat(variablesWithExplicitChecks);

  let diffResult = null;
  let hadValidationWarning = false;
  let persistedVariableValidationStatus = {};
  do {
    context = Context.generate(null, variablesModel, culture, extensions, true, null, new Prng(nextSeed), debug, withSourceMap);

    diffResult = Context.diff(context, diffContext, variablesThatHaveToBeDifferent);
    attempts = attempts + 1;
    nextSeed = seedGenerator.randomSeed();
    hadValidationWarning = hadValidationWarning || context.hasValidationWarning;
    persistedVariableValidationStatus = persistValidationStatus(persistedVariableValidationStatus, context.getVariablesValidationStatus());
  } while (attempts < maxAttempts &&
            (context.hasValidationWarning ||
             diffResult.type === ContextDiffType.nodiff ||
             diffResult.type === ContextDiffType.failedExplicitVariableDiffCheck));

  if (diffResult.type === ContextDiffType.failedExplicitVariableDiffCheck) {
    context.setVariablesThatFailedDiffCheck(diffResult.failedExplicitCheckIds);
  }

  if (persistWarnings) {
    context.setVariablesValidationStatus(persistedVariableValidationStatus);
    if (hadValidationWarning) {
      context.setValidationWarning(true);
    }
  }

  return context;
};

type TValidationStatus = Record<string, VariableValidationStatus | null>;
const persistValidationStatus = (oldStatus: TValidationStatus, newStatus: TValidationStatus): TValidationStatus => {
  const persistedStatus = { ...oldStatus };
  Object.keys(newStatus).forEach((variableId) => {
    if (getStatusPriority(newStatus[variableId]) > getStatusPriority(persistedStatus[variableId])) {
      persistedStatus[variableId] = newStatus[variableId];
    }
  });
  return persistedStatus;
};

const getStatusPriority = (status: VariableValidationStatus): number => {
  switch (status) {
    case VariableValidationStatus.skipped:
      return 1;
    case VariableValidationStatus.error:
      return 2;
    case VariableValidationStatus.error2:
      return 3;
    default:
      return 0;
  }
};

interface IValuesDictionary {
  [key: string]: IValueEntry;
}

interface IValueEntry {
  value: string;
  workingContextId: string;
  options: number;
  groupId: string;
}

interface IDeclaration {
  name: string;
  value: string;
  options: number;
}

interface IWorkingContexts {
  [key: string]: {
    declarations: ReadonlyArray<IDeclaration>,
    context: Context
  };
}
