import { ClientApplicationModel } from '../../models/ClientApplicationModel';
import { api } from '../../utils/fetch';

export interface IPageContentRenameVersionResponse {
}

export const pageContentRenameVersion = async (pageGuid: string,
                                               version: number,
                                               name: string,
                                               clientApplication: ClientApplicationModel): Promise<IPageContentRenameVersionResponse> => {

  const data = new FormData();
  data.append('pageGuid', pageGuid);
  data.append('version', String(version));
  data.append('name', name);

  const response = await api(`/Publisher/PageContent/RenameVersion?${clientApplication.params}`, {
    method: 'POST',
    body: data,
  });
  return response.ok;
};
