import { AnonymousFunction } from '../../elements/abstract/AnonymousFunction';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WList } from '../../elements/tokens/WList';
import { WMarkup } from '../../elements/tokens/WMarkup';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { XYTableImpl } from '../../funcs/tables/XYTableImpl';

/**
 * Tableau de valeurs.
 */
export class XYTable extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 2){
      return args.expectingArguments(2, 2);
    }

    let x:WList = args.getReals(0);
    let mapFunction:AnonymousFunction = args.getAnonymousFunction(0);

    if(x && mapFunction){
      return new WMarkup(new XYTableImpl(x, mapFunction, args.env));
    }

    x = args.getReals(1);
    mapFunction = args.getAnonymousFunction(0);

    if(x && mapFunction){
      return new WMarkup(new XYTableImpl(x, mapFunction, args.env));
    }

    return null;
  }

}
