import React from 'react';
import styled from 'styled-components';
import { ICustomRenderElementProps, ListStyleTypes } from '../../models';
import { IListElement } from '../../models/elements';
import { INDENT_SPACING } from '../../constants/style';
import { convertListStyleTypeToCSS, isOrderedList } from '../../utils';

export const ListElement: React.FC<ICustomRenderElementProps<IListElement>> = (props) => {
  const { element } = props;
  const { indent, listStyleType } = element;
  const Component = isOrderedList(listStyleType) ? Ol : Ul;
  return (
    <Component
      indent={indent}
      listStyleType={listStyleType}
    >
      {props.children}
    </Component>
  );
};

interface IList {
  indent: number;
  listStyleType: ListStyleTypes;
}

const getStyle = (props: IList) => {
  return `
    margin-left: ${props.indent * INDENT_SPACING}px;
    list-style-type: ${convertListStyleTypeToCSS(props.listStyleType)};
    margin-block-start: 1px;
    margin-block-end: 1px;
  `;
};

const Ul = styled.ul<IList>`
  ${getStyle};
`;

const Ol = styled.ol<IList>`
  ${getStyle}
`;
