import { XMath } from '../../../core/XMath';
import { AbstractStep } from '../../AbstractStep';
import { Compartment } from '../../Compartment';
import { FrameBorders } from '../../FrameBorders';
import { LongDivisionOperationEuclidienne } from '../../LongDivisionOperationEuclidienne';
import { DivisionModel } from '../../models/DivisionModel';
import { DivRest } from './DivRest';
import { DivEnd } from './DivEnd';
import { DivQuotient } from './DivQuotient';
import { DivWriteNewDividend } from './DivWriteNewDividend';

/**
 *
 */
export class DivInit extends AbstractStep {

  private model:DivisionModel;

  private division:LongDivisionOperationEuclidienne;

  constructor(division:LongDivisionOperationEuclidienne){
    super(division);
    this.model = division.model;
    this.division = division;

    let i:number;
    let c:Compartment;

    let digits:any[];

    if( this.model.hasDecimals ) {

      for(i = 0 ; i < division.rawDivisor.length ; i++){
        this.source.push(division.rawDivisor[i]);
      }

      for(i = 0 ; i < division.rawDividend.length ; i++){
        this.source.push(division.rawDividend[i]);
      }

      c = Compartment.createChar('×');
      division.normalize.push(c);
      this.source2.push( c );

      const p10:number = XMath.logX(this.model.normalizedFactor, 10);
      for(i = 1 ; i <= p10 ; i++){
        this.select.push(String(10 ** i));
      }

      digits = String(this.model.normalizedFactor).split('');
      for(i = 0 ; i < digits.length ; i++){
        c = Compartment.createDigit(digits[i]);
        this.target.push(c);
        division.normalize.push(c);
      }

      const enterPowerOfTen:string = division.culture.getString('LongDivisionEuclidienne.enterPowerOfTen');

      this.setDescription(
        division.culture.getString(
          'LongDivisionEuclidienne.commonFactor',
          division.culture.formatNumber(this.model.originalDividend),
          division.culture.formatNumber(this.model.originalDivisor),
          division.culture.formatNumber(this.model.normalizedDividend),
          division.culture.formatNumber(this.model.normalizedDivisor)) +
        (this.model.interactive ? `\n\n${enterPowerOfTen}` : ''));
    }else{
      // 1. Dividend
      const o:Compartment[] = [];
      o.push(null); // give space to the first minus sign in case the first multiply aligns at the left
      digits = String(this.model.normalizedDividend).split('');
      for(i = 0 ; i < digits.length ; i++){
        if(digits[i] === '.') {
          c = division.decimalSeparator();
        } else {
          c = Compartment.createDigit(digits[i]);
        }

        o.push(c);
        this.related.push( c );
      }
      division.steps.push(o);

      // 2. Divisor
      digits = String(this.model.normalizedDivisor).split('');
      for(i = 0 ; i < digits.length ; i++){
        c = Compartment.createDigit(digits[i]);
        c.frame = new FrameBorders();
        c.frame.bottom = true;
        c.frame.left = i === 0;
        division.divisor.push(c);
        this.related.push( c );
      }

      this.setDescription(
        division.culture.getString(
          'LongDivisionEuclidienne.initLabel',
          division.culture.formatNumber(this.model.originalDividend),
          division.culture.formatNumber(this.model.originalDivisor),
          division.culture.formatNumber(this.model.normalizedDividend),
          division.culture.formatNumber(this.model.normalizedDivisor)));
    }

  }

  public next():AbstractStep {
    if(this.model.hasDecimals){
      return new DivWriteNewDividend(this.division);
    }

    while(this.division.getRestToUInt() < this.model.normalizedDivisor && this.division.dividend.hasDigitLeft()){
      if( this.division.dividend.isSeparator() ) {
        break;
      }
      this.division.rest.push(this.division.dividend.getNextDigit());
      this.division.dividend.shift();
    }

    const _rest = this.division.getRestToUInt();

    if(_rest > this.model.normalizedDivisor
      || ( this.model.decimals === -1 && this.division.rest.length > 0 ) ){
      return new DivQuotient(this.division, _rest, this.model.normalizedDivisor);
    }
    if( ( this.division.rest.length === 0 || _rest === 0 ) ) {
      return new DivEnd(this.division, 'exact' );
    }
    if(this.model.decimals === -1 && this.division.rest.length > 0 && this.division.steps.length !== 1 ){
      return new DivRest(this.division);
    }
    return new DivQuotient(this.division, _rest, this.model.normalizedDivisor);
  }

}
