import { MathError } from '../../core/MathError';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WBoolean } from '../../elements/tokens/WBoolean';
import { WList } from '../../elements/tokens/WList';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class IsSquareShape extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length < 1 || args.length > 2){
      return args.expectingArguments(1, 2);
    }

    if(args.length === 1){
      if (args.getMatrix(0)) {
        return this.matrix(args.getMatrix(0));
      }
    }else if(args.length === 2){
      if (args.getReals(0) && args.getReal(1)) {
        return this.list(args.getReals(0), args.getReal(1));
      }
    }

    return null;
  }

  /**
   *
   */
  private list(value:WList, cols:RealElement):WBoolean{
    if(!cols.isWholeNumber()){
      return null;
    }
    return IsSquareShape.parse(value.toReals(), cols.toNumber());
  }

  /**
   *
   */
  private matrix(value:WMatrix):WBoolean{
    return IsSquareShape.parse(value.values, value.columns);
  }

  private static parse(
      values:RealElement[],
      cols:number):WBoolean{

    if(cols === 0){
      throw new MathError('Invalid matrix');
    }

    let lc:number = Number.MAX_SAFE_INTEGER; // left column
    let rc:number = Number.MIN_SAFE_INTEGER; // right column
    let tr:number = Number.MAX_SAFE_INTEGER; // top row
    let br:number = Number.MIN_SAFE_INTEGER; // bottom row

    let blank:boolean = true;

    let i:number;
    let c:number;
    let r:number;

    for(i = 0 ; i < values.length ; i++){
      c = i % cols;
      r = Math.floor(i / cols);

      if(values[i].toNumber() !== 0){
        lc = Math.min(lc, c);
        rc = Math.max(rc, c);
        tr = Math.min(tr, r);
        br = Math.max(br, r);
        blank = false;
      }
    }

    if(!blank){
      if(rc - lc === br - tr){ // Column span and row span are the same
        // Check that every cell in the bounds are enabled and every cell out of bounds are disabled
        for(i = 0 ; i < values.length ; i++){
          c = i % cols;
          r = Math.floor(i / cols);

          const inBounds:boolean =
            c >= lc &&
            c <= rc &&
            r >= tr &&
            r <= br;

          const enabled:boolean = values[i].toNumber() !== 0;

          if(inBounds && !enabled || !inBounds && enabled){
            return WBoolean.FALSE;
          }
        }
        return WBoolean.TRUE;
      }
    }

    return WBoolean.FALSE;
  }

}
