import styled from 'styled-components';
import { FormModal, HorizontalSeparator } from '@scolab/publisher-ui-kit';

export const StyledFormModal = styled(FormModal)`
  height: 678px;
  width: 960px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1;
`;

export const SpacedRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
`;

export const Separator = styled(HorizontalSeparator)`
  width: calc(100% + 32px);
  margin: 0 -16px;
`;
