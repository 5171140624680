import React from 'react';
import styled from 'styled-components';
import { ICustomEditor } from '../../models';
import { getActiveListType, isUnorderedListActive, removeList, toggleList } from '../../utils';
import { IListItem, ListControl } from '../../components/ListControl/ListControl';
import { ListStyleTypes } from '../../models/elements';
import BulletListIcon from '../../../../../assets/bulletList.svg';
import { Tooltip } from '../../../../utils/Tooltip/Tooltip';

interface IUnorderedListControlProps {
  editor: ICustomEditor;
  label: string;
  listStyleTypeNoneLabel: string;
}

export const UnorderedListControl: React.FC<IUnorderedListControlProps> = (props) => {
  const { editor, label, listStyleTypeNoneLabel } = props;
  const isActive = isUnorderedListActive(editor);
  const onMouseDown = (event: React.MouseEvent) => {
    event.preventDefault();
    if (isActive) {
      removeList(editor);
    } else {
      toggleList(editor, 'disc');
    }
  };
  const onClickItem = (listStyleType: ListStyleTypes) => {
    toggleList(editor, listStyleType);
  };

  const Items: ReadonlyArray<IListItem> = [
    {
      Icon: listStyleTypeNoneLabel,
      value: 'none',
    },
    {
      Icon: '■',
      value: 'square',
    },
    {
      Icon: '○',
      value: 'circle',
    },
    {
      Icon: '●',
      value: 'disc',
    },
  ];

  const value = getActiveListType(editor);

  const icon = (
    <Tooltip title={label}>
      <IconContainer>
        <BulletListIcon />
      </IconContainer>
    </Tooltip>
  );

  return (
    <ListControl
      Icon={icon}
      isActive={isActive}
      onClickIcon={onMouseDown}
      items={Items}
      onClickItem={onClickItem}
      value={value}
      aria-label={label}
    />
  );
};

const IconContainer = styled.div`
  line-height: 0;
`;
