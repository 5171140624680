import { LowercasePipe } from './LowercasePipe';
import { WarningPipe } from './WarningPipe';
import { ReplacePipe } from './ReplacePipe';
import { StringCompare } from './StringCompare';

/**
 * 10 × 10 × 10
 */
export class ProductOfFactors extends StringCompare {

  constructor() {

    super('×');

    this.sourcePipe.setNext(
      new ReplacePipe(' ', '', 'gi')).setNext(
        new WarningPipe('^[0-9]+(×[0-9]+)+$', 'gi', 'For example, enter: 10 × 10 × 10')).setNext(
          new LowercasePipe());

    this.targetPipe.setNext(
      new ReplacePipe(' ', '', 'gi')).setNext(
        new LowercasePipe());
  }

}
