import { MathError } from '../../core/MathError';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WMarkup } from '../../elements/tokens/WMarkup';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { LongPowerImpl } from '../../funcs/arithmetic/LongPowerImpl';

/**
 * https://www.scolab.com/Public/Jing/JamP/2013-05-17_1133.png
 */
export class LongPower extends FunctionElement {

  /**
   * We must put an limit or else it will freeze the application.
   */
  private static MAX_EXPONENT:number = 10;

  public callReturnElement(args:ArgumentsObject):ContentElement {
    if (args.length !== 2) {
      return args.expectingArguments(2, 2);
    }

    const base:RealElement = args.getWholeNumber(0);
    if(!base){
      return null;
    }

    const exponent:RealElement = args.getWholeNumber(1);
    if(!exponent){
      return null;
    }

    if(exponent.toNumber() > LongPower.MAX_EXPONENT){
      throw new MathError(`Exponent cannot be greater than ${LongPower.MAX_EXPONENT}`);
    }

    return new WMarkup(new LongPowerImpl(base.toNumber(), exponent.toNumber(), args.env.culture));
  }

}
