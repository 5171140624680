import { IPrng } from './IPrng';

/**
 * Provides common random functions using a seeded random system.
 * Can be used through static interface or via instantiation.
 */
export class Prng implements IPrng {

  private _initialSeed:number = 0;

  private _currentSeed:number = 0;

  private _count:number = 0;

  private _logs:string[] = [];

  /**
   *
   */
  constructor(seed:number){
    if (seed < 1 || seed % 1 !== 0) {
      throw new Error(`Invalid seed [${seed}]`);
    }
    this._initialSeed = this._currentSeed = seed;
    this._logs.push(`Initial seed: ${seed}`);
  }

  /**
   * Returns the generated numbers counter.
   */
  public get count():number{return this._count;}

  /**
   * Returns the initial seed.
   */
  public get initialSeed():number{return this._initialSeed;}

  /**
   * Returns the current seed.
   */
  public get currentSeed():number{return this._currentSeed;}

  /**
   * Returns generation history for this random number generator.
   */
  public get logs():string[]{return this._logs;}

  /**
   * Returns the next random number between 0-1 exclusive.
   */
  public random():number{
    return this.next();
  }

  /**
   * Returns the next random integer in the range [min, max).
   */
  public randomInt(
      minInclusive:number,
      maxExclusive:number):number{

    return minInclusive + Math.floor(this.next() * (maxExclusive - minInclusive));
  }

  /**
   * Returns the next random number in the range [min, max).
   */
  public randomNumber(minInclusive:number, maxInclusive:number):number{
    return minInclusive + this.next() * (maxInclusive - minInclusive);
  }

  /**
   * Returns a random index for an Array of the specified length.
   */
  public randomIndex(length:number):number{
    return Math.floor(this.next() * length);
  }

  /**
   * Generate a new random seed.
   */
  public randomSeed():number{
    return Math.max(1, Math.floor(this.next() * 0xFFFFFF));
  }

  /**
   *
   */
  private next():number{
    this._currentSeed = Math.round(this._currentSeed * 16807) % 2147483647;
    const value:number = this._currentSeed / 0x7FFFFFFF + 0.000000000233;
    this._logs.push(`${this.count}: ${this._currentSeed} --> ${value}`);
    this._count++;
    return value;
  }

}
