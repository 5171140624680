import { Point } from '../../../../js/geom/Point';

import { ContentElement } from '../../../elements/abstract/ContentElement';
import { FunctionElement } from '../../../elements/abstract/FunctionElement';
import { RealElement } from '../../../elements/abstract/RealElement';
import { WBoundVector } from '../../../elements/tokens/WBoundVector';
import { WPoint } from '../../../elements/tokens/WPoint';
import { WPolygon } from '../../../elements/tokens/WPolygon';
import { WPolyline } from '../../../elements/tokens/WPolyline';
import { WSegment } from '../../../elements/tokens/WSegment';
import { ArgumentsObject } from '../../../expr/ArgumentsObject';

/**
 * Extract vertex parts of figures(segment, polygons).
 * Retourne l'index-ième sommet d'une figure géométrique.
 */
export class Vertex extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 2){
      return args.expectingArguments(2, 2);
    }

    const index:RealElement = args.getReal(1);
    if(!index){
      return null;
    }

    let v:Point = null;

    if (args.getPoint(0)) {
      v = this.pointReal(args.getPoint(0), index);
    }else if (args.getSegment(0)) {
      v = this.segmentReal(args.getSegment(0), index);
    }else if (args.getBoundVector(0)) {
      v = this.boundVectorReal(args.getBoundVector(0), index);
    }else if (args.getPolygon(0)) {
      v = this.polygonReal(args.getPolygon(0), index);
    }else if (args.getPolyline(0)) {
      v = this.polylineReal(args.getPolyline(0), index);
    }

    return v ? args.env.culture.parsePoint(v) : null;
  }

  /**
   *
   */
  private pointReal(value:WPoint, i:RealElement):Point{
    if(i.toNumber() === 0){
      return value.toPoint();
    }
    return null;
  }

  /**
   *
   */
  private segmentReal(value:WSegment, i:RealElement):Point{
    if(i.toNumber() === 0){
      return value.a;
    }
    if(i.toNumber() === 1){
      return value.b;
    }
    return null;
  }

  /**
   *
   */
  private boundVectorReal(value:WBoundVector, i:RealElement):Point{
    if(i.toNumber() === 0){
      return value.a;
    }
    if(i.toNumber() === 1){
      return value.b;
    }
    return null;
  }

  /**
   *
   */
  private polygonReal(value:WPolygon, i:RealElement):Point{
    if(i.toNumber() < value.vertices.length){
      return value.verticeAt(i.toNumber());
    }
    return null;
  }

  /**
   *
   */
  private polylineReal(value:WPolyline, i:RealElement):Point{
    if(i.toNumber() < value.vertices.length){
      return value.verticeAt(i.toNumber());
    }
    return null;
  }

}
