import { ScriptFormat } from '../../../core/str/ScriptFormat';
import { BaseNumberFormatter } from '../../../elements/formats/BaseNumberFormatter';
import { CultureInfo } from '../../../localization/CultureInfo';

/**
 * Format number in base n.
 */
export class NumberBaseFormatter extends BaseNumberFormatter {

  private _base:number;

  public get base():number{return this._base;}

  /**
   *
   */
  constructor(
      culture:CultureInfo,
      base:number = 10){
    super(culture);
    this._base = base;
  }

  /**
   *
   */
  public toLocaleString(value:number):string{
    if(!this.willFormat(value)){
      return this.culture.formatNumber(value);
    }

    return	(value < 0 ? this.culture.numberFormatter.negativeSign : '') +
        Math.abs(value).toString(this.base).toUpperCase() +
        ScriptFormat.subscript(this.base);
  }

  /**
   *
   */
  public toSpeakText(value:number):string{
    if(!this.willFormat(value)){
      return this.culture.numberFormatter.toSpeakText(value);
    }

    return this.culture.getString(
      'MathSpeak.baseFormat',
      value.toString(this.base),
      this.base);
  }

  /**
   *
   */
  public willFormat(value:number):boolean{
    return Math.floor(value) === value;
  }

}
