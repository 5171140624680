import { AbstractSuit } from '../../funcs/cards/AbstractSuit';

/**
 *
 */
export class Clubs extends AbstractSuit {

  /**
   *
   */
  constructor(){
    super(40);
  }

}
