import { MathError } from '../../core/MathError';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';

/**
 *
 */
export class MagicSquare extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 1){
      return args.expectingArguments(1, 1);
    }

    const size:RealElement = args.getWholeNumber(0);
    if(!size){
      return null;
    }

    return this.createMagicSquare(size.toNumber(), args.env);
  }

  /**
   *
   */
  private createMagicSquare(size:number, env:Environment):WMatrix{
    if(size % 2 === 0){
      throw new MathError('Order must be odd');
    }

    const values:RealElement[] = [];

    values.length = size * size;

    let r:number = 0;
    let c:number = Math.floor(size / 2);

    values[r * size + c] = env.culture.createNumber(1);

    let nr:number;
    let nc:number;

    for(let i:number = 2 ; i <= size * size ; i++){
      nr = r;
      nc = c;

      r = r - 1 < 0 ? size - 1 : r - 1;
      c = c + 1 >= size ? 0 : c + 1;

      if(values[r * size + c]){
        r = nr + 1;
        c = nc;
      }

      values[r * size + c] = env.culture.createNumber(i);
    }

    return new WMatrix(values, size, env.culture.formats.matrixFormatImpl);
  }

  /**
   *
   */
  public static validate(m:WMatrix):boolean{
    const cols:number = m.columns;
    const rows:number = m.rows;

    if(cols !== rows){
      return false;
    }

    let c:number;
    let r:number;
    let i:number;

    // Sums of the rows, sums of the lines, sums of the diagonals
    const s:number[] = [];

    for(i = 0 ; i < cols + rows + 2 ; i++){
      s.push(0);
    }

    for(c = 0 ; c < cols ; c++){
      for(r = 0 ; r < rows ; r++){
        const n:number = m.valueAt(r, c).toNumber();
        s[r] += n;
        s[c + rows] += n;
        if(c === r){
          s[rows + cols] += n;
        }
        if(c === ((rows - 1) - r)){
          s[rows + cols + 1] += n;
        }
      }
    }

    for(i = 1 ; i < s.length ; i++){
      if(s[0] !== s[i]){
        return false;
      }
    }

    return true;
  }

}
