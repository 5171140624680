import { Node } from '../../../elements/abstract/Node';
import { Environment } from '../../../expr/Environment';
import { ConstructWorker } from '../../../expr/conversion/input/ConstructWorker';
import { NormalizationWorker } from '../../../expr/conversion/input/NormalizationWorker';
import { TreeWorker } from '../../../expr/conversion/input/TreeWorker';

/**
 *
 */
export class TokensImporter {

  /**
   *
   */
  public static importTokens(tokens:any[], env:Environment):Node{
    const tree:TreeWorker = new TreeWorker(tokens, env);
    if(tree.tokens.length === 1){
      const node:Node = tree.tokens[0];
      NormalizationWorker.normalize(node);
      ConstructWorker.lambdaConstructs(node, {});
      tree.tokens = null;
      return node;
    }
    tree.tokens = null;
    return null;
  }

}
