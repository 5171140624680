import { MathError } from '../../core/MathError';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { TLog } from '../../elements/functions/models/TLog';
import { WFunction } from '../../elements/tokens/WFunction';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class LogFunction extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 5){
      return args.expectingArguments(5, 5);
    }

    const A:RealElement = args.getReal(0);
    const B:RealElement = args.getReal(1);
    const C:RealElement = args.getReal(2);
    const H:RealElement = args.getReal(3);
    const K:RealElement = args.getReal(4);

    if(A && B && C && H && K){
      if(C.toNumber() <= 0 || C.toNumber() === 1){
        throw new MathError('Invalid base for log');
      }

      return new WFunction(new TLog(args.env.culture, A, B, C, H, K));
    }

    return null;
  }

}
