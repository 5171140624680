import { IPageContent } from '@scolab/content-model';
import { ClientApplicationModel } from '../../models/ClientApplicationModel';
import { api } from '../../utils/fetch';

export interface IPageContentCreatePageContentResponse {
  pageGuid: string;
  pageLock: {
    pageGuid: string,
    status: 'acquired' | 'failed' | 'requireMigration',
    lockedBy: string,
  };
  pageVersion: {
    copyType: 'publishedCopy' | 'draftCopy',
    version: number | null,
    dateModified: string,
    contentFormat: 'json'
  };
  pageContent: IPageContent;
}

export const pageContentCreatePageContent = async (pageContent: IPageContent,
                                                   originVersion: number | null,
                                                   clientApplication: ClientApplicationModel): Promise<IPageContentCreatePageContentResponse> => {

  const data = new FormData();
  data.append('pageContent', JSON.stringify(pageContent));
  if (!isNaN(originVersion)) {
    data.append('originVersion', String(originVersion));
  }

  const response = await api<IPageContentCreatePageContentResponse>(`/Publisher/PageContent/CreatePageContent?${clientApplication.params}`, {
    method: 'POST',
    body: data,
  });
  return response.json();
};
