/* eslint-disable no-restricted-imports */
import { setInjector as setMmlpxInjector } from 'mmlpx/esm/core/dependency-inject/instantiate';
import { default as MmlpxInjector } from 'mmlpx/esm/core/dependency-inject/Injector';
import { PreloadManager } from './Store';
/* eslint-enable no-restricted-imports */

export const resetInjector = () => {
  setMmlpxInjector(MmlpxInjector.newInstance());
  PreloadManager.resetPreloads();
};
