/**
 * http://en.wikipedia.org/wiki/Formulae_for_generating_Pythagorean_triples
 * http://www.hbmeyer.de/pythagen.htm
 */
export class PytTriple {

  /**
   *
   */
  public static seed:PytTriple = new PytTriple(3, 4, 5);

  private _a:number;

  private _b:number;

  private _c:number;

  /**
   *
   */
  public get a():number{return this._a;}

  public get b():number{return this._b;}

  public get c():number{return this._c;}

  /**
   *
   */
  constructor(a:number, b:number, c:number) {

    this._a = a;
    this._b = b;
    this._c = c;
    if(!PytTriple.validateTriple(a, b, c)){
      throw new Error();
    }
  }

  /**
   *
   */
  private _childs:PytTriple[];

  /**
   *
   */
  public get childs():PytTriple[]{
    if(!this._childs){

      this._childs = [];

      this._childs.push(new PytTriple(
        this.a - 2 * this.b + 2 * this.c,
        2 * this.a - this.b + 2 * this.c,
        2 * this.a - 2 * this.b + 3 * this.c));

      this._childs.push(new PytTriple(
        this.a + 2 * this.b + 2 * this.c,
        2 * this.a + this.b + 2 * this.c,
        2 * this.a + 2 * this.b + 3 * this.c));

      this._childs.push(new PytTriple(
        -this.a + 2 * this.b + 2 * this.c,
        -2 * this.a + this.b + 2 * this.c,
        -2 * this.a + 2 * this.b + 3 * this.c));

    }
    return this._childs;
  }

  /**
   *
   */
  public static validateTriple(a:number, b:number, c:number):boolean{
    return (a ** 2) + (b ** 2) === (c ** 2);
  }

  /**
   *
   */
  public static fill(
      max_c:number,
      triple:PytTriple,
      temp:PytTriple[]):void{
    if(triple.c < max_c){
      temp.push(triple);
      PytTriple.fill(max_c, triple.childs[0], temp);
      PytTriple.fill(max_c, triple.childs[1], temp);
      PytTriple.fill(max_c, triple.childs[2], temp);
    }
  }

}
