import { RelationalElement } from '../../elements/abstract/RelationalElement';

/**
 *
 */
export class Equal extends RelationalElement {

  /**
   *
   */
  constructor(){
    super('=');
  }

  /**
   *
   */
  protected hasNumberComparer():boolean{
    return true;
  }

  /**
   *
   */
  protected compareNumbers(na:number, nb:number):boolean{
    return na === nb;
  }

}
