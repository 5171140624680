import { Editor, Element, Node, Path, Range, Transforms } from '../TypedSlate';
import { IParagraphElement } from '../models/elements';
import { ICustomEditor } from '../models/editor';
import { clearFormat } from './clearFormatUtils';

/**
 * @param editor
 * @param value
 */
export const setClassName = (editor: ICustomEditor, value: string): void => {
  const paragraphs = Array.from(Editor.nodes<IParagraphElement>(editor, {
    mode: 'all',
    match: (n) => {
      return !Editor.isEditor(n) && Element.isElement(n) && n.type === 'paragraph';
    },
  }));
  if (paragraphs.length === 0) {
    return;
  }

  const pathStart = paragraphs[0][1];
  const pathEnd = paragraphs[paragraphs.length - 1][1];
  const affectedRange: Range = {
    anchor: {
      path: pathStart,
      offset: 0,
    },
    focus: {
      path: pathEnd,
      offset: 1,
    },
  };
  // Clear previous format so that the new preset overrides any character styles.
  clearFormat(editor, affectedRange);

  const newNodeValue: Partial<IParagraphElement> = {
    className: value,
  };
  Transforms.setNodes(
    editor,
    newNodeValue,
    {
      at: affectedRange,
      match: (node: Node, path: Path) => {
        return !Editor.isEditor(node) && Element.isElement(node) && node.type === 'paragraph';
      },
    });
};

/**
 * @param editor
 * @param value
 */
export const commonClassName = (editor: ICustomEditor, range?: Range): string => {
  if (!editor.selection) return null;
  const paragraphs = Editor.nodes<IParagraphElement>(editor, {
    mode: 'all',
    at: range,
    match: (n) => {
      return !Editor.isEditor(n) && Element.isElement(n) && n.type === 'paragraph';
    },
  });

  let tempClassName: string = null;
  for (const paragraph of paragraphs) {
    const className = paragraph[0].className;
    if (tempClassName === null) {
      tempClassName = className;
    }
    if (className !== tempClassName) {
      return null;
    }
  }

  return tempClassName;
};
