import React from 'react';
import styled from 'styled-components';

interface IListItemProps {
  index: number;
  onSelect: (event: React.MouseEvent<HTMLDivElement>, index: number) => void;
  onContextMenu: (event: React.MouseEvent<HTMLDivElement>, index: number) => void;
}

export const ListItem: React.FC<IListItemProps> = (props) => {
  const {
    index,
    onSelect,
    onContextMenu,
    children,
  } = props;
  return (
    <ItemWrapper
      onClick={(event: React.MouseEvent<HTMLDivElement>) => onSelect(event, index)}
      onContextMenu={(event: React.MouseEvent<HTMLDivElement>) => onContextMenu(event, index)}
    >
      {children}
    </ItemWrapper>
  );
};

const ItemWrapper = styled.div`
  width: 100%;
  height: 100%;
`;
