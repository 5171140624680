import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { TokenElement } from '../../elements/abstract/TokenElement';
import { WFormat } from '../../elements/tokens/WFormat';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Formatter } from '../../expr/manipulation/Formatter';

/**
 *
 */
export class CopyFormat extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 2){
      return args.expectingArguments(2, 2);
    }
    if (args.getContentElement(0) && args.getContentElement(1)) {
      return this.copy(args.getContentElement(0), args.getContentElement(1));
    }
    return null;
  }

  /**
   *
   */
  private copy(
      source:ContentElement,
      target:ContentElement):ContentElement{

    if(source instanceof TokenElement){
      const format:WFormat = new WFormat((<TokenElement>source ).getFormat());
      const formatter:Formatter = new Formatter(format);
      return formatter.applyFormat(target);
    }

    return target;
  }

}
