import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { TFloor } from '../../elements/functions/models/TFloor';
import { WFunction } from '../../elements/tokens/WFunction';
import { WInterval } from '../../elements/tokens/WInterval';
import { FunctionStyles } from '../../elements/functions/adapters/FunctionStyles';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class SetPeriod extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 2){
      return args.expectingArguments(2, 2);
    }

    if(args.getFunction(0) && args.getInterval(1)){
      return this.period(args.getFunction(0), args.getInterval(1));
    }

    return null;
  }

  /**
   *
   */
  private period(
      func:WFunction,
      period:WInterval):WFunction{

    if(func.form instanceof TFloor){
      // Can't set period on floor function.
      return func;
    }

    return new WFunction(
      func.form,
      period,
      FunctionStyles.PERIODIC);
  }

}
