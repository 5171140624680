import { ResponseErrors } from '../models/ResponseErrors';
import { PublisherApiAuth } from '../services/PublisherApiAuth';
import { EnvironmentType, getEnvironment } from './environment';
import { TetrisApiAuth } from '../services/TetrisApiAuth';

/**
 * Specialises the native Response object in order to cast the response to a JSON object seamlessly.
 */
interface FetchResponse<T> extends Response {
  json(): Promise<T>;
}


/**
 * Represents an authenticated REST call to the Publisher API backend. This wrapper around the fetch() API adds authorization
 * management and type casting.
 * There is automated error handling on the response object. All calls are expected to be JSON call that return
 * a structure, anything else will throw an error.
 * @param uri
 * @param init
 * @throws Error
 */
export const api = async <T = Record<string, any>>(uri: string, init: RequestInit = {}): Promise<FetchResponse<T>> => {
  const headers = new Headers(init.headers || {});

  const publisherToken = PublisherApiAuth.getToken();
  if (publisherToken) {
    headers.set('Authorization', `Bearer ${publisherToken}`);
  } else {
    // This will enable basic support for token identifications
    // coming from the Workbook/Tetris, but it is not expected to
    // be used for anything else than the console app.
    const tetrisToken = TetrisApiAuth.getToken();
    if (tetrisToken) {
      headers.set('Authorization', tetrisToken.startsWith('Bearer ') ? tetrisToken : `Bearer ${tetrisToken}`);
    }
  }

  if (!headers.has('Access-Control-Max-Age')) {
    headers.set('Access-Control-Max-Age', '3600');
  }

  if (!headers.has('X-Requested-With')) {
    headers.set('X-Requested-With', 'XMLHttpRequest');
  }

  const domain = getEnvironment() === EnvironmentType.production ? ScolabConfiguration.API_PRODUCTION : ScolabConfiguration.API_QA;
  const response = await remoteCall<T>(`${domain}${uri}`, {
    ...init,
    headers,
  });

  if (response.status === 401) {
    PublisherApiAuth.onUnauthorizedRequest();
    // The JS will likely break trying to consume whatever is
    // being returned here, but throwing is too aggressive and
    // prevents a redirection by the UI.
    return response;
  }

  // When near to the token's expiration, calls to the backend will return a new refreshed token
  // that is meant to replace the current one.
  const updatedToken = response.headers.get('Set-Authorization');
  if (updatedToken) {
    PublisherApiAuth.setToken(updatedToken.replace('Bearer ', ''));
  }

  return response;
};

/**
 * Represents an authenticated REST call to the Media API backend. This wrapper around the fetch() API adds type
 * casting.
 * There is automated error handling on the response object. All calls are expected to be JSON call that return
 * a structure, anything else will throw an error.
 * @param uri
 * @param init
 * @throws Error
 */
export const media = async <T = Record<string, any>>(uri: string, init: RequestInit = {}): Promise<FetchResponse<T>> => {
  return remoteCall<T>(`${ScolabConfiguration.MEDIA_API}${uri}`, init);
}

const remoteCall = async <T>(url: string, configuration: RequestInit): Promise<Response> => {
  try {
    const response = await fetch(url, configuration) as FetchResponse<T>;
    await validateResponse(response, url);
    return response;
  } catch (e) {
    return new Response(null, { status: 500, statusText: e.message });
  }
}

const validateResponse = async (response: Response, url: string) => {
  // Catches all server side errors except for 401s which can be handled by our frontend UI.
  if (!response.ok && response.status !== 401) {
    throw new Error(`${ResponseErrors.STATUS_NOT_OK} - ${response.statusText} - ${url}`);
  }

  const responseContentType = response.headers.get('content-type');
  if (responseContentType === 'text/html; charset=utf-8' || responseContentType === 'application/json; charset=utf-8') {
    if (!response.body) {
      throw new Error(ResponseErrors.EMPTY);
    }
  }
}
