import { Point } from '../../../js/geom/Point';

import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WList } from '../../elements/tokens/WList';
import { WListOfPoints } from '../../elements/tokens/WListOfPoints';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';
import { WListOfList } from '../../elements/tokens/WListOfList';
import { ListElement } from '../../elements/abstract/ListElement';
import { ListOfListElement } from '../../elements/abstract/ListOfListElement';

/**
 * Différences entre deux listes.
 */
export class Diff extends FunctionElement {
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 2) {
      return args.expectingArguments(2, 2);
    }

    const lists: Array<ListOfListElement | WListOfList> = [args.getListOfList(0), args.getListOfList(1)];
    if (lists[0] instanceof WListOfList && lists[1] instanceof WListOfList) {
      return this.listOfLists(lists[0], lists[1], args.env);
    }

    const reals = [args.getReals(0), args.getReals(1)];
    if (reals[0] && reals[1]) {
      return this.listsN(reals[0], reals[1], args.env);
    }

    const matrices = [args.getMatrix(0), args.getMatrix(1)];
    if (matrices[0] && matrices[1]) {
      return this.matrices(matrices[0], matrices[1], args.env);
    }

    const points = [args.getPoints(0), args.getPoints(1)];
    if (points[0] && points[1]) {
      return this.points(points[0], points[1], args.env);
    }

    return null;
  }

  private listsN(a: WList, b: WList, env: Environment): WList {
    const reals: ContentElement[] = [];
    const zero = env.culture.createNumber(0);
    for (let i = 0; i < Math.max(a.count, b.count); i++) {
      const ai = i < a.count ? a.getTypedItemAt(i) : zero;
      const bi = i < b.count ? b.getTypedItemAt(i) : zero;
      reals.push(env.reals.subtract(bi, ai));
    }
    return new WList(reals, a.formatter2);
  }

  private matrices(a: WMatrix, b: WMatrix, env: Environment): WMatrix {
    const values: RealElement[] = [];
    const zero = env.culture.createNumber(0);
    for (let r = 0; r < Math.max(a.rows, b.rows); r++) {
      for (let c = 0; c < Math.max(a.columns, b.columns); c++) {
        const ai = r < a.rows && c < a.columns ? a.valueAt(r, c) : zero;
        const bi = r < b.rows && c < b.columns ? b.valueAt(r, c) : zero;
        values.push(env.reals.subtract(bi, ai));
      }
    }
    return new WMatrix(values, Math.max(a.columns, b.columns), a.formatter);
  }

  private points(a: WListOfPoints, b: WListOfPoints, env: Environment): WListOfPoints {
    const points: ContentElement[] = [];
    const zero = new Point(0, 0);
    for (let i = 0; i < Math.max(a.count, b.count); i++) {
      const ai = i < a.count ? a.getValueAt(i) : zero;
      const bi = i < b.count ? b.getValueAt(i) : zero;
      points.push(env.culture.parsePoint(bi.subtract(ai)));
    }
    return new WListOfPoints(points, a.formatter);
  }

  private listOfLists(a: WListOfList, b: WListOfList, env: Environment): WListOfList {
    return new WListOfList(this.listItems(a, b, env), a.formatter);
  }

  private listItems(a: ListElement, b: ListElement, env: Environment): ContentElement[] {
    const zero = env.culture.createNumber(0);
    const items: ContentElement[] = [];
    for (let i = 0; i < Math.max(a.count, b.count); i++) {
      const ai = i < a.count ? a.getItemAt(i) : null;
      const bi = i < b.count ? b.getItemAt(i) : null;
      if (ai instanceof ListElement && bi instanceof ListElement) {
        items.concat(this.listItems(ai, bi, env));
      } else if (ai instanceof RealElement && bi instanceof RealElement) {
        items.push(env.reals.subtract(bi ?? zero, ai ?? zero));
      }
    }
    return items;
  }
}
