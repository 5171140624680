import React from 'react';
import { MmlWriter, MmlXmlProxy, Variable } from '@scolab/math-core-api';
import { IMathCoreId } from '@scolab/content-model';
import { MathLabel } from '@scolab/publisher-ui-kit';
import { observer } from 'mobx-react';
import { inject } from '../../dependencyInjection/inject';
import { ValuePreviewMediator } from '../../mediators/ValuePreviewMediator';
import { ContextModelTypes } from '../../models/ContextModelTypes';
import { PublisherContext, PublisherContexts } from '../../contexts/PublisherContext';

export interface IValuePreviewProps {
  readonly variableId: IMathCoreId;
  readonly contextType: ContextModelTypes;
}

@observer
export class ValuePreview extends React.Component<IValuePreviewProps> {
  static contextType = PublisherContext;

  public context!: React.ContextType<typeof PublisherContext>;

  @inject(ValuePreviewMediator)
  private mediator: ValuePreviewMediator;

  public render(): JSX.Element {
    const wasSuccessfullyInterpreted = this.generatedVariable?.result?.root?.isLeaf;
    if (!wasSuccessfullyInterpreted) {
      return null;
    }
    if (this.generatedVariable.toText()) {
      return <span>{this.generatedVariable.toText()}</span>;
    }
    if (this.generatedVariable.toHtml(true)) {
      const markup = { __html: this.generatedVariable.toHtml(true) };
      return <div dangerouslySetInnerHTML={markup}/>;
    }
    const writer = new MmlWriter(new MmlXmlProxy());
    this.generatedVariable.writeTo(writer);
    // We need to force MathJax v2 in the activity preview environment to be compatible with the workbook
    const forceMathJaxV2 = this.context.environment === PublisherContexts.activityPreview;
    return (
      <MathLabel
        forceMathJaxV2={forceMathJaxV2}
        math={writer.content.outerHTML}
      />
    );

  }

  private get generatedVariable(): Variable {
    return this.mediator.getGeneratedVariable(this.props.contextType, this.props.variableId);
  }
}
