import { AnonymousFunction } from '../../elements/abstract/AnonymousFunction';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { ListElement } from '../../elements/abstract/ListElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Evaluate } from '../../expr/manipulation/Evaluate';

/**
 * Tableau croisé.
 */
export class CrossMap extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 3){
      return args.expectingArguments(3, 3);
    }

    const rows:ListElement = args.getNonEmptyList(0);
    const columns:ListElement = args.getNonEmptyList(1);
    const mapFunction:AnonymousFunction = args.getAnonymousFunction(2, 2);

    if(!rows || !columns || !mapFunction){
      return null;
    }

    const evaluator:Evaluate = new Evaluate(mapFunction, args.env);

    const o:RealElement[] = [];

    for(let r:number = 0 ; r < rows.count ; r++){
      for(let c:number = 0 ; c < columns.count ; c++){
        const n:RealElement = RealElement.parseElement(evaluator.evaluate2(rows.getItemAt(r), columns.getItemAt(c)));
        if(!n){
          return null;
        }
        o.push(n);
      }
    }

    return new WMatrix(o, columns.count, args.env.culture.formats.matrixFormatImpl);
  }

}
