import { Point } from '../../../../js/geom/Point';

import { Delegate } from '../../../../js/utils/Delegate';

import { XMath } from '../../../core/XMath';
import { ContentElement } from '../../../elements/abstract/ContentElement';
import { FunctionElement } from '../../../elements/abstract/FunctionElement';
import { WBoolean } from '../../../elements/tokens/WBoolean';
import { WLine } from '../../../elements/tokens/WLine';
import { WListOfPoints } from '../../../elements/tokens/WListOfPoints';
import { WPoint } from '../../../elements/tokens/WPoint';
import { WPolygon } from '../../../elements/tokens/WPolygon';
import { WSegment } from '../../../elements/tokens/WSegment';
import { ArgumentsObject } from '../../../expr/ArgumentsObject';
import { AreCoincident } from '../../../funcs/asserts/geom/AreCoincident';

/**
 * Returns true if a figure can be obtained by a
 * simple translation of another figure.
 *
 * If the two figures overlaps, it is considered
 * a translation for the purpose of this function.
 *
 * Order of the points is important
 * but can be rotated.
 */
export class IsTranslated extends FunctionElement {

  public callReturnElement(args:ArgumentsObject):ContentElement {
    if (args.length !== 2) {
      return args.expectingArguments(2, 2);
    }

    if(args.getPoint(0) && args.getPoint(1)){
      return this.points(args.getPoint(0), args.getPoint(1));
    }
    if(args.getLine(0) && args.getLine(1)){
      return this.lines(args.getLine(0), args.getLine(1));
    }
    if(args.getSegment(0) && args.getSegment(1)){
      return this.segments(args.getSegment(0), args.getSegment(1));
    }
    if(args.getPoints(0) && args.getPoints(1)){
      return this.lpoints(args.getPoints(0), args.getPoints(1));
    }
    if(args.getPolygon(0) && args.getPolygon(1)){
      return this.polygons(args.getPolygon(0), args.getPolygon(1));
    }

    return null;
  }

  /**
   * A point can always be obtained by translating another point.
   */
  private points(a:WPoint, b:WPoint):WBoolean{
    return WBoolean.TRUE;
  }

  /**
   * Check that the slope is equal.
   */
  private lines(a:WLine, b:WLine):WBoolean{
    let test:boolean = false;

    if(!a.slope && !b.slope){
      test = true; // both verticals
    }else if(a.slope && b.slope){
      test = XMath.safeEquals(a.slope.toNumber(), b.slope.toNumber());
    }

    return WBoolean.parse(test);
  }

  /**
   *
   */
  private segments(a:WSegment, b:WSegment):WBoolean{
    const va:Point[] = [];
    const vb:Point[] = [];
    va.push(a.a, a.b);
    vb.push(b.a, b.b);
    return WBoolean.parse(this.check(va, vb));
  }

  /**
   *
   */
  private polygons(a:WPolygon, b:WPolygon):WBoolean{
    return WBoolean.parse(this.check(a.vertices, b.vertices));
  }

  /**
   *
   */
  private lpoints(a:WListOfPoints, b:WListOfPoints):WBoolean{
    return WBoolean.parse(this.check(a.toPoints(), b.toPoints()));
  }

  /**
   *
   */
  private check(
      a:Point[],
      b:Point[]):boolean{

    if(a.length !== b.length){
      return false;
    }

    return AreCoincident.check(this.align00(a), this.align00(b));
  }

  /**
   * Translate the shape so that no point is negative and at least one
   * point touch the x-axis and one point touch the y-axis.
   */
  private align00(points:Point[]):Point[]{
    if(points.length === 0){
      return points;
    }

    let dx:number = Number.MAX_VALUE;
    let dy:number = Number.MAX_VALUE;

    for(let i:number = 0 ; i < points.length ; i++){
      const point:Point = points[i];
      dx = Math.min(dx, point.x);
      dy = Math.min(dy, point.y);
    }

    const offset:Point = new Point(-dx, -dy);

    return points.map(
      Delegate.partial(this.addOffset, offset), null);
  }

  private addOffset(offset:Point, p:Point, ..._:any[]):Point{
    return p.add(offset);
  }

}
