import { Point } from '../../../../js/geom/Point';
import { IDictionary } from '../../../../js/utils/IDictionary';

import { XSort } from '../../../core/XSort';
import { XString } from '../../../core/XString';
import { ContentElement } from '../../../elements/abstract/ContentElement';
import { RealElement } from '../../../elements/abstract/RealElement';
import { BaseListNFormatter } from '../../../elements/formats/BaseListNFormatter';
import { BaseNumberFormatter } from '../../../elements/formats/BaseNumberFormatter';
import { IMarkupExporter } from '../../../elements/markers/IMarkupExporter';
import { WNumber } from '../../../elements/tokens/WNumber';
import { CultureInfo } from '../../../localization/CultureInfo';

/**
 * 10, 10, 10, 5, 5, 1 --> (3 × 10) + (2 × 5) + (1 × 1)
 */
export class TallyFormatter extends BaseListNFormatter {

  /**
   *
   */
  constructor(culture:CultureInfo){
    super(culture);
  }

  /**
   *
   */
  public writeTo(
      exporter:IMarkupExporter,
      items:ContentElement[],
      noParenthesis:boolean,
      useUnambiguousItemSeparator:boolean):boolean{
    return false;
  }

  public toText(
      items:ContentElement[],
      strict:boolean,
      noParenthesis:boolean,
      useUnambiguousItemSeparator:boolean):string{

    const pairs:Point[] = this.group(items);
    let keyFormat:BaseNumberFormatter = this.numberFormat(items);
    if(!keyFormat){
      keyFormat = this.culture.numberFormatter;
    }

    const s:any[] = [];
    for(let i:number = 0 ; i < pairs.length ; i++){
      const pair:Point = pairs[i];
      s.push(XString.substitute(
        '({0} × {1})',
        this.culture.formatNumber(pair.x),
        keyFormat.toLocaleString(pair.y)));
    }

    return s.join(' + ');
  }

  /**
   * (x, y) --> (count, key)
   */
  public group(values:ContentElement[]):Point[]{
    const tally:IDictionary = {};
    let keys:any[] = [];
    let i:number;

    for(i = 0 ; i < values.length ; i++){
      const n:number = (<RealElement>values[i] ).toNumber();
      const ki:string = n.toString();
      if(!tally.hasOwnProperty(ki)){
        keys.push(n);
        tally[ki] = 0;
      }
      tally[ki]++;
    }

    keys = keys.sort(XSort.numeric);
    keys = keys.reverse();

    const items:Point[] = [];
    for(i = 0 ; i < keys.length ; i++){
      const key:number = keys[i];
      items.push(new Point(Number(tally[String(key)]), key));
    }

    return items;
  }

  /**
   * Returns the first number format defined on that list.
   */
  private numberFormat(values:ContentElement[]):BaseNumberFormatter{
    for(let i:number = 0 ; i < values.length ; i++){
      if(values[i] instanceof WNumber){
        return (<WNumber>values[i] ).formatter;
      }
    }
    return null;
  }

}
