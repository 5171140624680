import { ContentElement } from '../../elements/abstract/ContentElement';
import { ElementCodes } from '../../elements/abstract/ElementCodes';
import { OperatorElement } from '../../elements/abstract/OperatorElement';
import { SetElement } from '../../elements/abstract/SetElement';
import { TokensList } from '../../elements/factories/TokensList';
import { WComplement } from '../../elements/tokens/WComplement';
import { WFiniteSet } from '../../elements/tokens/WFiniteSet';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';

/**
 * ∖
 * U+2216
 * Différence d'ensembles
 */
export class Difference extends OperatorElement {

  /**
   *
   */
  public toString():string{return '∖';}

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 2){
      return args.expectingArguments(2, 2);
    }

    const A:SetElement = args.getSet(0);
    const B:SetElement = args.getSet(1);

    if(A && B){
      return this.difference(A, B, args.env);
    }

    return null;
  }

  /**
   *
   */
  private difference(
      B:SetElement,
      A:SetElement,
      env:Environment):SetElement{

    const fb:WFiniteSet = WFiniteSet.parseElement(B);
    const fa:WFiniteSet = WFiniteSet.parseElement(A);

    if(fb && fa){
      const diff:TokensList = fb.toTokens().getDifference(fa.toTokens());
      return env.createNormalizedSet(diff.getItems());
    }

    return new WComplement(B, A);
  }

  /**
   *
   */
  public getElementCode():string{
    return ElementCodes.OP_SETS_DIFFERENCE;
  }

}
