import { StringPipe } from './StringPipe';

export class ReplacePipe extends StringPipe {

  private oldValue:string;

  private newValue:string;

  private options:string;

  /**
   *
   */
  constructor(
      oldValue:string,
      newValue:string,
      options:string) {

    super();
    this.oldValue = oldValue;
    this.newValue = newValue;
    this.options = options;
  }

  public process(value:string):string{
    const pattern:RegExp =
      new RegExp(
        String(this.oldValue),
        String(this.options));

    return super.process(value.replace(pattern, this.newValue));
  }

}
