import React from 'react';
import { setRef } from './setRef';

/**
 * Combine many refs into a single ref
 * Taken from https://github.com/mui/material-ui/blob/d3ef60158ba066779102fba775dda6765e2cc0f5/packages/mui-utils/src/useForkRef/useForkRef.ts
 */
export function useForkRef<Instance>(
  ...refs: Array<React.Ref<Instance> | undefined>
): React.RefCallback<Instance> | null {
  /**
   * This will create a new function if the refs passed to this hook change and are all defined.
   * This means react will call the old forkRef with `null` and the new forkRef
   * with the ref. Cleanup naturally emerges from this behavior.
   */
  return React.useMemo(() => {
    if (refs.every((ref) => ref == null)) {
      return null;
    }

    return (instance) => {
      refs.forEach((ref) => {
        setRef(ref, instance);
      });
    };
  }, refs);
}

