import { Point } from '../../../../../js/geom/Point';

import { XAngles } from '../../../../core/XAngles';
import { XGeom } from '../../../../core/XGeom';
import { XMath } from '../../../../core/XMath';
import { ContentElement } from '../../../../elements/abstract/ContentElement';
import { FunctionElement } from '../../../../elements/abstract/FunctionElement';
import { WBoolean } from '../../../../elements/tokens/WBoolean';
import { WPolygon } from '../../../../elements/tokens/WPolygon';
import { WSegment } from '../../../../elements/tokens/WSegment';
import { ArgumentsObject } from '../../../../expr/ArgumentsObject';

/**
 * Est un trapèze isocèle?
 */
export class IsIsoscelesTrapezoid extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }

    const polygon:WPolygon = args.getPolygon(0);
    if(!polygon){
      return null;
    }

    return WBoolean.parse(IsIsoscelesTrapezoid.check(polygon));
  }

  /**
   *
   */
  public static check(value:WPolygon):boolean{
    if(value.vertices.length !== 4){
      return false;
    }

    const v:Point[] = value.vertices;

    const s0:WSegment = value.segmentAt(0);
    const s1:WSegment = value.segmentAt(1);
    const s2:WSegment = value.segmentAt(2);
    const s3:WSegment = value.segmentAt(3);

    const angles:number[] = XAngles.interior(v);
    const edges:number[] = XGeom.edges(v);

    if(		s1.isParallelTo(s3)  &&
        XMath.safeEquals(edges[0], edges[2]) &&
        XMath.safeEquals(angles[1], angles[2]) &&
        XMath.safeEquals(angles[0], angles[3])){

      return true;
    }

    if(		s0.isParallelTo(s2) &&
        XMath.safeEquals(edges[1], edges[3]) &&
        XMath.safeEquals(angles[0], angles[1]) &&
        XMath.safeEquals(angles[2], angles[3])){

      return true;
    }

    return false;
  }

}
