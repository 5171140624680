import { CultureInfo } from '../../localization/CultureInfo';
import { BaseListFormatter } from '../../elements/formats/BaseListFormatter';

/**
 * Formatter for a list of numbers.
 */
export class BaseListNFormatter extends BaseListFormatter {

  /**
   *
   */
  constructor(culture:CultureInfo){
    super(culture);
  }

}
