import { MathError } from '../../core/MathError';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { RealsImpl } from '../../elements/utils/RealsImpl';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class QuotientRow extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 1){
      return args.expectingArguments(1, 1);
    }
    if(args.getMatrix(0)){
      return this.quotient(args.getMatrix(0), args.env.reals);
    }
    return null;
  }

  /**
   *
   */
  private quotient( m:WMatrix, reals:RealsImpl ):WMatrix {
    if( m.rows < 2 ) {
      throw new MathError( 'QuotientRow need 2 or more row.' );
    }

    const result:RealElement[] = m.values.concat();

    for( let c:number = 0; c < m.columns; c++ ) {
      let quotient:RealElement = null;

      for( let r:number = 0; r < m.rows; r++ ) {
        if( r === 0 ) {
          quotient = m.valueAt( r, c );
        } else {
          quotient = reals.divideR(quotient, m.valueAt( r, c ), false);
        }
      }

      result.push(quotient);
    }

    return new WMatrix(result, m.columns, m.formatter);
  }

}
