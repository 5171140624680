import { MathError } from '../../core/MathError';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WList } from '../../elements/tokens/WList';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Ligne d'une matrice.
 */
export class Row extends FunctionElement {

  /**
   * Retourne l'index-ième ligne d'une matrice.
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 2){
      return args.expectingArguments(2, 2);
    }

    const matrix:WMatrix = args.getMatrix(0);
    if(!matrix){
      return null;
    }

    const rowIndex:RealElement = args.getWholeNumber(1);
    if(!rowIndex){
      return null;
    }

    if(rowIndex.toNumber() > matrix.rows){
      throw new MathError('Row index out of range');
    }

    return WList.createFromReals(
      matrix.getRow(rowIndex.toNumber()),
      args.env.culture.listFormatter);
  }

}
