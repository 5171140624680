import { CSSProperties } from 'react';
import { ColorSet } from '../../../themes/ColorSet';

export interface IPopoverTheme {
  color: CSSProperties['color'];
  background: CSSProperties['background'];
  border?: CSSProperties['border'];
}

export const darkPopoverTheme: IPopoverTheme = {
  color: ColorSet.white,
  background: ColorSet.darkness,
};

export const lightPopoverTheme: IPopoverTheme = {
  color: ColorSet.darkness,
  background: `linear-gradient(326.5deg, ${ColorSet.grey025} 0%, ${ColorSet.white} 100%);`,
  border: `1px solid ${ColorSet.grey100}`,
};
