import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Named CastInterval to avoid name collision with construct Interval.
 * Function named "interval" in registry.
 */
export class CastInterval extends FunctionElement {

  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 1){
      return args.expectingArguments(1, 1);
    }

    if(args.getInterval(0)){
      return args.getInterval(0);
    }
    if(args.getNumbersSet(0)){
      return args.getNumbersSet(0).narrow();
    }
    return null;
  }

}
