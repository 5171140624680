import { Point } from '../../../../js/geom/Point';
import { MathError } from '../../../core/MathError';
import { IEval } from '../../../elements/effofeks/IEval';
import { WInterval } from '../../../elements/tokens/WInterval';
import { IFunctionAttributes } from '../../../elements/markers/IFunctionAttributes';
import { IFunctionAdapter } from '../../../elements/functions/adapters/IFunctionAdapter';

/**
 *
 */
export class EvaluableAdapter implements IFunctionAdapter {

  private _evaluable:IEval;

  constructor(evaluable:IEval){
    this._evaluable = evaluable;
  }

  public get continuous():number{return -1;}

  public get limit():Point{return null;}

  public previous(value:number):number{return value;}

  public next(value:number):number{return value;}

  public map(value:number):number{return this._evaluable.evalX(value);}

  public get type():string{return null;}

  public get constantPiece():WInterval{return null;}

  public get period():WInterval{return null;}

  public piecesInRange(value:WInterval):WInterval[]{return null;}

  public getHashCode(): string {
    return this._evaluable.getUnderlyingElement().toText(false);
  }

  public get attributes():IFunctionAttributes{throw new MathError('Not implemented');}

}
