import { Match } from '../../core/Match';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { ListElement } from '../../elements/abstract/ListElement';
import { WString } from '../../elements/tokens/WString';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';

/**
 *
 */
export class StringQuery extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 1){
      return args.expectingArguments(1, 1);
    }

    const str:WString = args.getString(0);

    if(str){
      return this.search(str.getString(), this.searchPattern(), args.env);
    }

    return null;
  }

  /**
   *
   */
  protected searchPattern():RegExp{
    throw new Error('Must inherit');
  }

  /**
   *
   */
  private search(
      s:string,
      x:RegExp,
      env:Environment):ListElement{
    const o:any[] = [];
    let z:Match = Match.tryParse(x.exec(s));
    while(z){
      o.push(String(z.groups[0]));
      z = Match.tryParse(x.exec(s));
    }

    return env.culture.listFactory.createFromArray(o, 'string');
  }

}
