import { AbstractFormatter } from '../../elements/formats/AbstractFormatter';
import { CardSuitFormatter } from '../../elements/formats/cards/CardSuitFormatter';
import { AbstractApplyFormat } from '../../funcs/formats/AbstractApplyFormat';
import { CultureInfo } from '../../localization/CultureInfo';

/**
 *
 */
export class CardSuit extends AbstractApplyFormat {

  /**
   *
   */
  protected getFormat(culture:CultureInfo):AbstractFormatter{
    return new CardSuitFormatter(culture);
  }

}
