import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import {
  Button,
  ExpandableSearchInput,
  FormModal,
  HorizontalSeparator,
  SortOrder,
  PlusIcon
} from '@scolab/publisher-ui-kit';
import { FormattedMessage } from 'react-intl';
import { computed } from 'mobx';
import { instantiate } from '../../../dependencyInjection/instantiate';
import { VariantMediator } from '../../../mediators/VariantMediator';
import { SortButton, SortCriteria } from '../../SortButton';
import { IVariant } from '../../../models/IVariant';
import { alphabeticSort, dateSort } from '../utils';
import { VariantCreationModal } from './VariantCreationModal';
import { VariantList } from './VariantList';

export interface IVariantManagerModalProps {
  isOpen: boolean;
  onClose: () => void;
}

interface IVariantManagerModalState {
  searchFilter: string;
  sortCriteria: SortCriteria;
  sortOrder: SortOrder;
  isVariantCreationModalOpen: boolean;
}

@observer
export class VariantManagerModal extends React.Component<IVariantManagerModalProps, IVariantManagerModalState> {
  private variantMediator: VariantMediator;

  private createButtonRef: React.RefObject<HTMLDivElement>;

  constructor(props: IVariantManagerModalProps) {
    super(props);
    this.state = {
      sortCriteria: SortCriteria.alphabetic,
      sortOrder: SortOrder.ascending,
      searchFilter: '',
      isVariantCreationModalOpen: false,
    };
    this.createButtonRef = React.createRef<HTMLDivElement>();
    this.variantMediator = instantiate(VariantMediator);
  }

  public render(): React.ReactNode {
    const {
      isOpen,
    } = this.props;
    const {
      searchFilter,
      sortCriteria,
      sortOrder,
      isVariantCreationModalOpen,
    } = this.state;
    return (
      <>
        <StyledFormModal
          isOpen={isOpen}
          title={<FormattedMessage id='variants.variantManagerModal.title' />}
          cancelLabel={<FormattedMessage id='variants.close' />}
          onClose={this.onClose}
        >
          <Container>
            <Separator />
            <SpacedRow>
              <Button
                primary
                onClick={this.onOpenVariantCreationModal}
                ref={this.createButtonRef}
              >
                <PlusIcon />
                <FormattedMessage id='variants.variant' />
              </Button>
              <Row>
                <StyledExpandableSearchInput
                  value={searchFilter}
                  onChange={this.onChangeSearchFilter}
                  tooltip={<FormattedMessage id='common.search' />}
                />
                <SortButton
                  criteria={sortCriteria}
                  order={sortOrder}
                  onChangeCriteria={this.onChangeSortCriteria}
                  onChangeOrder={this.onChangeSortOrder}
                />
              </Row>
            </SpacedRow>
            <Separator />
            <VariantList
              variantList={this.sortedVariants}
              changeDescription={this.variantMediator.changeDescription}
              toggleObsolete={this.variantMediator.toggleObsolete}
            />
          </Container>
        </StyledFormModal>
        <VariantCreationModal
          isOpen={isVariantCreationModalOpen}
          existingNames={this.allVariantsNames}
          initialName={searchFilter}
          onClose={this.onCloseVariantCreationModal}
          onSave={this.onCreateVariant}
          anchorEl={this.createButtonRef.current}
        />
      </>
    );
  }

  private onCreateVariant = (variant: IVariant) => {
    this.variantMediator.createVariant(variant);
    this.onCloseVariantCreationModal();
  }

  private onCloseVariantCreationModal = () => {
    this.setState({
      isVariantCreationModalOpen: false,
    });
  }

  private onOpenVariantCreationModal = () => {
    this.setState({
      isVariantCreationModalOpen: true,
    });
  }

  private onChangeSearchFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      searchFilter: event.target.value,
    });
  }

  private onChangeSortOrder = (sortOrder: SortOrder) => {
    this.setState({
      sortOrder,
    });
  }

  private onChangeSortCriteria = (sortCriteria: SortCriteria) => {
    this.setState({
      sortCriteria,
    });
  }

  private onClose = () => {
    this.setState({
      sortCriteria: SortCriteria.alphabetic,
      sortOrder: SortOrder.ascending,
      searchFilter: '',
      isVariantCreationModalOpen: false,
    });
    this.props.onClose();
  }

  private get filteredVariants(): ReadonlyArray<IVariant> {
    const { searchFilter } = this.state;
    const lowerSearch = searchFilter.toLowerCase();
    return this.variantMediator.variants.filter(
      (variant) => variant.name.toLowerCase().includes(lowerSearch) ||
        variant.metadata.description?.toLowerCase()?.includes(lowerSearch)
    );
  }

  private get sortedVariants(): ReadonlyArray<IVariant> {
    const { sortOrder, sortCriteria } = this.state;
    const sortFn = sortCriteria === SortCriteria.alphabetic ? alphabeticSort : dateSort;
    const sortedList = this.filteredVariants.concat().sort(sortFn);
    if (sortOrder === SortOrder.descending) {
      sortedList.reverse();
    }
    return sortedList;
  }

  @computed
  private get allVariantsNames(): ReadonlyArray<string> {
    return this.variantMediator.variants.map((variant) => variant.name);
  }
}

const StyledFormModal = styled(FormModal)`
  height: 678px;
  width: 696px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  justify-content: flex-end;
`;

const SpacedRow = styled(Row)`
  justify-content: space-between;
`;

const Separator = styled(HorizontalSeparator)`
  width: calc(100% + 32px);
  margin: 0 -16px;
`;

const StyledExpandableSearchInput = styled(ExpandableSearchInput)`
  min-width: 450px;
`;
