import { XObject } from '../XObject';

/**
 *
 */
export class Diacritics {

  /**
   * prop --> letter with a diacritical mark
   * value --> base letter
   */
  private static diacriticsMap:any = Diacritics.convertMap();

  /**
   * For every base letter(s), we have a list of diacritical marks that base letter(s) can have.
   * We need to have the opposite, for every diacritical letter, we need to have it's base letter(s).
   */
  private static convertMap():any{
    const diacritics:any = {
      A: 	[0x0041, 0x24B6, 0xFF21, 0x00C0, 0x00C1, 0x00C2, 0x1EA6, 0x1EA4, 0x1EAA, 0x1EA8, 0x00C3, 0x0100, 0x0102, 0x1EB0, 0x1EAE, 0x1EB4, 0x1EB2, 0x0226, 0x01E0, 0x00C4, 0x01DE, 0x1EA2, 0x00C5, 0x01FA, 0x01CD, 0x0200, 0x0202, 0x1EA0, 0x1EAC, 0x1EB6, 0x1E00, 0x0104, 0x023A, 0x2C6F],
      AA: [0xA732],
      AE: [0x00C6, 0x01FC, 0x01E2],
      AO: [0xA734],
      AU: [0xA736],
      AV: [0xA738, 0xA73A],
      AY: [0xA73C],
      B: 	[0x0042, 0x24B7, 0xFF22, 0x1E02, 0x1E04, 0x1E06, 0x0243, 0x0182, 0x0181],
      C: 	[0x0043, 0x24B8, 0xFF23, 0x0106, 0x0108, 0x010A, 0x010C, 0x00C7, 0x1E08, 0x0187, 0x023B, 0xA73E],
      D: 	[0x0044, 0x24B9, 0xFF24, 0x1E0A, 0x010E, 0x1E0C, 0x1E10, 0x1E12, 0x1E0E, 0x0110, 0x018B, 0x018A, 0x0189, 0xA779],
      DZ: [0x01F1, 0x01C4],
      Dz: [0x01F2, 0x01C5],
      E: 	[0x0045, 0x24BA, 0xFF25, 0x00C8, 0x00C9, 0x00CA, 0x1EC0, 0x1EBE, 0x1EC4, 0x1EC2, 0x1EBC, 0x0112, 0x1E14, 0x1E16, 0x0114, 0x0116, 0x00CB, 0x1EBA, 0x011A, 0x0204, 0x0206, 0x1EB8, 0x1EC6, 0x0228, 0x1E1C, 0x0118, 0x1E18, 0x1E1A, 0x0190, 0x018E],
      F: 	[0x0046, 0x24BB, 0xFF26, 0x1E1E, 0x0191, 0xA77B],
      G: 	[0x0047, 0x24BC, 0xFF27, 0x01F4, 0x011C, 0x1E20, 0x011E, 0x0120, 0x01E6, 0x0122, 0x01E4, 0x0193, 0xA7A0, 0xA77D, 0xA77E],
      H: 	[0x0048, 0x24BD, 0xFF28, 0x0124, 0x1E22, 0x1E26, 0x021E, 0x1E24, 0x1E28, 0x1E2A, 0x0126, 0x2C67, 0x2C75, 0xA78D],
      I: 	[0x0049, 0x24BE, 0xFF29, 0x00CC, 0x00CD, 0x00CE, 0x0128, 0x012A, 0x012C, 0x0130, 0x00CF, 0x1E2E, 0x1EC8, 0x01CF, 0x0208, 0x020A, 0x1ECA, 0x012E, 0x1E2C, 0x0197],
      J: 	[0x004A, 0x24BF, 0xFF2A, 0x0134, 0x0248],
      K: 	[0x004B, 0x24C0, 0xFF2B, 0x1E30, 0x01E8, 0x1E32, 0x0136, 0x1E34, 0x0198, 0x2C69, 0xA740, 0xA742, 0xA744, 0xA7A2],
      L: 	[0x004C, 0x24C1, 0xFF2C, 0x013F, 0x0139, 0x013D, 0x1E36, 0x1E38, 0x013B, 0x1E3C, 0x1E3A, 0x0141, 0x023D, 0x2C62, 0x2C60, 0xA748, 0xA746, 0xA780],
      LJ: [0x01C7],
      Lj: [0x01C8],
      M: 	[0x004D, 0x24C2, 0xFF2D, 0x1E3E, 0x1E40, 0x1E42, 0x2C6E, 0x019C],
      N: 	[0x004E, 0x24C3, 0xFF2E, 0x01F8, 0x0143, 0x00D1, 0x1E44, 0x0147, 0x1E46, 0x0145, 0x1E4A, 0x1E48, 0x0220, 0x019D, 0xA790, 0xA7A4],
      NJ: [0x01CA],
      Nj: [0x01CB],
      O: 	[0x004F, 0x24C4, 0xFF2F, 0x00D2, 0x00D3, 0x00D4, 0x1ED2, 0x1ED0, 0x1ED6, 0x1ED4, 0x00D5, 0x1E4C, 0x022C, 0x1E4E, 0x014C, 0x1E50, 0x1E52, 0x014E, 0x022E, 0x0230, 0x00D6, 0x022A, 0x1ECE, 0x0150, 0x01D1, 0x020C, 0x020E, 0x01A0, 0x1EDC, 0x1EDA, 0x1EE0, 0x1EDE, 0x1EE2, 0x1ECC, 0x1ED8, 0x01EA, 0x01EC, 0x00D8, 0x01FE, 0x0186, 0x019F, 0xA74A, 0xA74C],
      OI: [0x01A2],
      OO: [0xA74E],
      OU: [0x0222],
      OE: [0x008C, 0x0152],
      oe: [0x009C, 0x0153],
      P: 	[0x0050, 0x24C5, 0xFF30, 0x1E54, 0x1E56, 0x01A4, 0x2C63, 0xA750, 0xA752, 0xA754],
      Q: 	[0x0051, 0x24C6, 0xFF31, 0xA756, 0xA758, 0x024A],
      R: 	[0x0052, 0x24C7, 0xFF32, 0x0154, 0x1E58, 0x0158, 0x0210, 0x0212, 0x1E5A, 0x1E5C, 0x0156, 0x1E5E, 0x024C, 0x2C64, 0xA75A, 0xA7A6, 0xA782],
      S: 	[0x0053, 0x24C8, 0xFF33, 0x1E9E, 0x015A, 0x1E64, 0x015C, 0x1E60, 0x0160, 0x1E66, 0x1E62, 0x1E68, 0x0218, 0x015E, 0x2C7E, 0xA7A8, 0xA784],
      T: 	[0x0054, 0x24C9, 0xFF34, 0x1E6A, 0x0164, 0x1E6C, 0x021A, 0x0162, 0x1E70, 0x1E6E, 0x0166, 0x01AC, 0x01AE, 0x023E, 0xA786],
      TZ: [0xA728],
      U: 	[0x0055, 0x24CA, 0xFF35, 0x00D9, 0x00DA, 0x00DB, 0x0168, 0x1E78, 0x016A, 0x1E7A, 0x016C, 0x00DC, 0x01DB, 0x01D7, 0x01D5, 0x01D9, 0x1EE6, 0x016E, 0x0170, 0x01D3, 0x0214, 0x0216, 0x01AF, 0x1EEA, 0x1EE8, 0x1EEE, 0x1EEC, 0x1EF0, 0x1EE4, 0x1E72, 0x0172, 0x1E76, 0x1E74, 0x0244],
      V: 	[0x0056, 0x24CB, 0xFF36, 0x1E7C, 0x1E7E, 0x01B2, 0xA75E, 0x0245],
      VY: [0xA760],
      W: 	[0x0057, 0x24CC, 0xFF37, 0x1E80, 0x1E82, 0x0174, 0x1E86, 0x1E84, 0x1E88, 0x2C72],
      X: 	[0x0058, 0x24CD, 0xFF38, 0x1E8A, 0x1E8C],
      Y: 	[0x0059, 0x24CE, 0xFF39, 0x1EF2, 0x00DD, 0x0176, 0x1EF8, 0x0232, 0x1E8E, 0x0178, 0x1EF6, 0x1EF4, 0x01B3, 0x024E, 0x1EFE],
      Z: 	[0x005A, 0x24CF, 0xFF3A, 0x0179, 0x1E90, 0x017B, 0x017D, 0x1E92, 0x1E94, 0x01B5, 0x0224, 0x2C7F, 0x2C6B, 0xA762],
      a: 	[0x0061, 0x24D0, 0xFF41, 0x1E9A, 0x00E0, 0x00E1, 0x00E2, 0x1EA7, 0x1EA5, 0x1EAB, 0x1EA9, 0x00E3, 0x0101, 0x0103, 0x1EB1, 0x1EAF, 0x1EB5, 0x1EB3, 0x0227, 0x01E1, 0x00E4, 0x01DF, 0x1EA3, 0x00E5, 0x01FB, 0x01CE, 0x0201, 0x0203, 0x1EA1, 0x1EAD, 0x1EB7, 0x1E01, 0x0105, 0x2C65, 0x0250],
      aa: [0xA733],
      ae: [0x00E6, 0x01FD, 0x01E3],
      ao: [0xA735],
      au: [0xA737],
      av: [0xA739, 0xA73B],
      ay: [0xA73D],
      b: 	[0x0062, 0x24D1, 0xFF42, 0x1E03, 0x1E05, 0x1E07, 0x0180, 0x0183, 0x0253],
      c: 	[0x0063, 0x24D2, 0xFF43, 0x0107, 0x0109, 0x010B, 0x010D, 0x00E7, 0x1E09, 0x0188, 0x023C, 0xA73F, 0x2184],
      d: 	[0x0064, 0x24D3, 0xFF44, 0x1E0B, 0x010F, 0x1E0D, 0x1E11, 0x1E13, 0x1E0F, 0x0111, 0x018C, 0x0256, 0x0257, 0xA77A],
      dz: [0x01F3, 0x01C6],
      e: 	[0x0065, 0x24D4, 0xFF45, 0x00E8, 0x00E9, 0x00EA, 0x1EC1, 0x1EBF, 0x1EC5, 0x1EC3, 0x1EBD, 0x0113, 0x1E15, 0x1E17, 0x0115, 0x0117, 0x00EB, 0x1EBB, 0x011B, 0x0205, 0x0207, 0x1EB9, 0x1EC7, 0x0229, 0x1E1D, 0x0119, 0x1E19, 0x1E1B, 0x0247, 0x025B, 0x01DD],
      f: 	[0x0066, 0x24D5, 0xFF46, 0x1E1F, 0x0192, 0xA77C],
      g: 	[0x0067, 0x24D6, 0xFF47, 0x01F5, 0x011D, 0x1E21, 0x011F, 0x0121, 0x01E7, 0x0123, 0x01E5, 0x0260, 0xA7A1, 0x1D79, 0xA77F],
      h: 	[0x0068, 0x24D7, 0xFF48, 0x0125, 0x1E23, 0x1E27, 0x021F, 0x1E25, 0x1E29, 0x1E2B, 0x1E96, 0x0127, 0x2C68, 0x2C76, 0x0265],
      hv: [0x0195],
      i: 	[0x0069, 0x24D8, 0xFF49, 0x00EC, 0x00ED, 0x00EE, 0x0129, 0x012B, 0x012D, 0x00EF, 0x1E2F, 0x1EC9, 0x01D0, 0x0209, 0x020B, 0x1ECB, 0x012F, 0x1E2D, 0x0268, 0x0131],
      j: 	[0x006A, 0x24D9, 0xFF4A, 0x0135, 0x01F0, 0x0249],
      k: 	[0x006B, 0x24DA, 0xFF4B, 0x1E31, 0x01E9, 0x1E33, 0x0137, 0x1E35, 0x0199, 0x2C6A, 0xA741, 0xA743, 0xA745, 0xA7A3],
      l: 	[0x006C, 0x24DB, 0xFF4C, 0x0140, 0x013A, 0x013E, 0x1E37, 0x1E39, 0x013C, 0x1E3D, 0x1E3B, 0x017F, 0x0142, 0x019A, 0x026B, 0x2C61, 0xA749, 0xA781, 0xA747],
      lj: [0x01C9],
      m: 	[0x006D, 0x24DC, 0xFF4D, 0x1E3F, 0x1E41, 0x1E43, 0x0271, 0x026F],
      n: 	[0x006E, 0x24DD, 0xFF4E, 0x01F9, 0x0144, 0x00F1, 0x1E45, 0x0148, 0x1E47, 0x0146, 0x1E4B, 0x1E49, 0x019E, 0x0272, 0x0149, 0xA791, 0xA7A5],
      nj: [0x01CC],
      o: 	[0x006F, 0x24DE, 0xFF4F, 0x00F2, 0x00F3, 0x00F4, 0x1ED3, 0x1ED1, 0x1ED7, 0x1ED5, 0x00F5, 0x1E4D, 0x022D, 0x1E4F, 0x014D, 0x1E51, 0x1E53, 0x014F, 0x022F, 0x0231, 0x00F6, 0x022B, 0x1ECF, 0x0151, 0x01D2, 0x020D, 0x020F, 0x01A1, 0x1EDD, 0x1EDB, 0x1EE1, 0x1EDF, 0x1EE3, 0x1ECD, 0x1ED9, 0x01EB, 0x01ED, 0x00F8, 0x01FF, 0x0254, 0xA74B, 0xA74D, 0x0275],
      oi: [0x01A3],
      ou: [0x0223],
      oo: [0xA74F],
      p: 	[0x0070, 0x24DF, 0xFF50, 0x1E55, 0x1E57, 0x01A5, 0x1D7D, 0xA751, 0xA753, 0xA755],
      q: 	[0x0071, 0x24E0, 0xFF51, 0x024B, 0xA757, 0xA759],
      r: 	[0x0072, 0x24E1, 0xFF52, 0x0155, 0x1E59, 0x0159, 0x0211, 0x0213, 0x1E5B, 0x1E5D, 0x0157, 0x1E5F, 0x024D, 0x027D, 0xA75B, 0xA7A7, 0xA783],
      s: 	[0x0073, 0x24E2, 0xFF53, 0x00DF, 0x015B, 0x1E65, 0x015D, 0x1E61, 0x0161, 0x1E67, 0x1E63, 0x1E69, 0x0219, 0x015F, 0x023F, 0xA7A9, 0xA785, 0x1E9B],
      t: 	[0x0074, 0x24E3, 0xFF54, 0x1E6B, 0x1E97, 0x0165, 0x1E6D, 0x021B, 0x0163, 0x1E71, 0x1E6F, 0x0167, 0x01AD, 0x0288, 0x2C66, 0xA787],
      tz: [0xA729],
      u: 	[0x0075, 0x24E4, 0xFF55, 0x00F9, 0x00FA, 0x00FB, 0x0169, 0x1E79, 0x016B, 0x1E7B, 0x016D, 0x00FC, 0x01DC, 0x01D8, 0x01D6, 0x01DA, 0x1EE7, 0x016F, 0x0171, 0x01D4, 0x0215, 0x0217, 0x01B0, 0x1EEB, 0x1EE9, 0x1EEF, 0x1EED, 0x1EF1, 0x1EE5, 0x1E73, 0x0173, 0x1E77, 0x1E75, 0x0289],
      v: 	[0x0076, 0x24E5, 0xFF56, 0x1E7D, 0x1E7F, 0x028B, 0xA75F, 0x028C],
      vy: [0xA761],
      w: 	[0x0077, 0x24E6, 0xFF57, 0x1E81, 0x1E83, 0x0175, 0x1E87, 0x1E85, 0x1E98, 0x1E89, 0x2C73],
      x: 	[0x0078, 0x24E7, 0xFF58, 0x1E8B, 0x1E8D],
      y: 	[0x0079, 0x24E8, 0xFF59, 0x1EF3, 0x00FD, 0x0177, 0x1EF9, 0x0233, 0x1E8F, 0x00FF, 0x1EF7, 0x1E99, 0x1EF5, 0x01B4, 0x024F, 0x1EFF],
      z: 	[0x007A, 0x24E9, 0xFF5A, 0x017A, 0x1E91, 0x017C, 0x017E, 0x1E93, 0x1E95, 0x01B6, 0x0225, 0x0240, 0x2C6C, 0xA763]};

    const o:any = {};

    const baseLetters:any[] = XObject.getProps(diacritics);
    for(let i:number = 0 ; i < baseLetters.length ; i++){
      const baseLetter:string = baseLetters[i];
      for(let j:number = 0 ; j < diacritics[baseLetter].length ; j++){
        o[String.fromCharCode(diacritics[baseLetter][j])] = baseLetter;
      }
    }
    return o;
  }

  /**
   * Replace letters with diacritical marks for their base letters.
   */
  public static remove(str:string):string{
    // Could be improved to exclude more characters
    return str.replace(/[^a-zA-Z0-9]/g, Diacritics.replaceChar);
  }

  /**
   *
   */
  private static replaceChar(chr:string, offset:number, str:string):string{
    if(Diacritics.diacriticsMap.hasOwnProperty(chr)){
      return Diacritics.diacriticsMap[chr];
    }
    return chr;
  }

}
