import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { VertexQuadraticFormatter } from '../../elements/formats/quad/VertexQuadraticFormatter';
import { WQuadratic } from '../../elements/tokens/WQuadratic';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * A(x - H)^2 + K
 */
export class VertexForm extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 1){
      return args.expectingArguments(1, 1);
    }

    const quadratic:WQuadratic = args.getQuadraticOrPoly(0);
    if(quadratic){
      return quadratic.applyFormat(new VertexQuadraticFormatter(args.env.culture));
    }

    return null;
  }

}
