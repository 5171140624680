import { ContentElement } from '../../../elements/abstract/ContentElement';
import { FunctionElement } from '../../../elements/abstract/FunctionElement';
import { WBoolean } from '../../../elements/tokens/WBoolean';
import { WHalfPlane } from '../../../elements/tokens/WHalfPlane';
import { WLine } from '../../../elements/tokens/WLine';
import { WPoint } from '../../../elements/tokens/WPoint';
import { ArgumentsObject } from '../../../expr/ArgumentsObject';

/**
 *
 */
export class TestPoint extends FunctionElement {

  public callReturnElement(args:ArgumentsObject):ContentElement {
    if (args.length !== 2) {
      return args.expectingArguments(2, 2);
    }

    if(args.getHalfPlane(0) && args.getPoint(1)){
      return this.halfPlanePoint(args.getHalfPlane(0), args.getPoint(1));
    }
    return null;
  }

  /**
   * h()
   */
  private halfPlanePoint(
      halfPlane:WHalfPlane,
      p:WPoint):WBoolean{

    return WBoolean.parse(TestPoint.test(halfPlane, p));
  }

  /**
   *
   */
  public static test(
      halfPlane:WHalfPlane,
      p:WPoint):boolean{
    const line:WLine = halfPlane.line;
    const x:number = p.x.toNumber();
    const y:number = p.y.toNumber();

    if(!line.slope){

      switch(halfPlane.op){
        case '<': return x < line.xintercept.toNumber();
        case '>': return x > line.xintercept.toNumber();
        case '≤': return x <= line.xintercept.toNumber();
        case '≥': return x >= line.xintercept.toNumber();
      }
    }

    const a:number = line.slope.toNumber();
    const b:number = line.yintercept.toNumber();

    switch(halfPlane.op){
      case '<': return y < a * x + b;
      case '>': return y > a * x + b;
      case '≤': return y <= a * x + b;
      case '≥': return y >= a * x + b;
    }

    return false;
  }

}
