import { XMath } from '../../core/XMath';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { ListOfListElement } from '../../elements/abstract/ListOfListElement';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Extraire des éléments.
 */
export class Items extends FunctionElement {
  /**
   * Extrait les éléments d'une liste aux indices spécifiés.
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 2) {
      return args.expectingArguments(2, 2);
    }

    const list = args.getList(0);
    const indices = args.getReals(1);

    if (list && indices) {
      const validIndices: number[] = [];
      for (let i = 0; i < indices.count; i++) {
        const index = indices.getValueAt(i);
        if (!XMath.isInteger(index) || index < 0) {
          continue;
        }
        validIndices.push(index);
      }
      const transformed = list.transform(validIndices);
      if (transformed instanceof ListOfListElement && transformed.count === 1) {
        return transformed.getItemAt(0);
      }
      return transformed;
    }

    return null;
  }
}
