import { RealElement } from '../../../elements/abstract/RealElement';
import { MConstruct } from '../../../expr/conversion/models/MConstruct';
import { MParam } from '../../../expr/conversion/models/MParam';

/**
 *
 */
export class TokensHash {

  private tokens:any[];

  /**
   *
   */
  constructor(tokens:any[]){
    this.tokens = tokens;
  }

  /**
   *
   */
  public getHashCode():string{
    const output: string[] = [];
    this.writeTokens(output, this.tokens);
    return output.join('');
  }

  /**
   *
   * @param output
   * @param tokens
   */
  private writeTokens(
    output:string[],
    tokens:any[]):void{

    output.push('(');

    for(let i:number = 0 ; i < tokens.length ; i++){
      let o:Object = tokens[i];

      if(o === '-'){
        o = '−';
      }
      if(o instanceof MParam){
        this.writeParamValue(output, <MParam>o );
      }else if(o instanceof MConstruct){
        const c:MConstruct = <MConstruct>o ;

        if(c.type === MConstruct.TYPE_FRAC){
          output.push('frac{');
        }
        if(c.type === MConstruct.TYPE_SUP){
          output.push('sup{');
        }
        if(c.type === MConstruct.TYPE_SUB){
          output.push('sub{');
        }
        if(c.type === MConstruct.TYPE_SQRT){
          output.push('sqrt{');
        }
        if(c.type === MConstruct.TYPE_FENCE){
          output.push(c.open);
        }

        for(let j:number = 0 ; j < c.args.length ; j++){
          const argument:any[] = c.args[j];
          this.writeTokens(output, argument);
        }

        if(c.type === MConstruct.TYPE_FRAC){
          output.push('}');
        }
        if(c.type === MConstruct.TYPE_SUP){
          output.push('}');
        }
        if(c.type === MConstruct.TYPE_SUB){
          output.push('}');
        }
        if(c.type === MConstruct.TYPE_SQRT){
          output.push('}');
        }
        if(c.type === MConstruct.TYPE_FENCE){
          output.push(c.close);
        }

      }else if(o instanceof RealElement){
        output.push((o as RealElement).toText(false));
      }else if(!isNaN(Number(o))){
        output.push(String(o));
      }else if(String(o).charAt(0) >= 'a' && String(o).charAt(0) <= 'z'){
        output.push(String(o));
      }else if(o === '²' || o === '³'){
        output.push(String(o));
      }else if(o === '−' || o === '+'){
        let op:string = String(o);
        const nextToken:Object =
          (i < tokens.length - 1) ?
            tokens[i + 1] :
            null;

        if(nextToken){
          if(!isNaN(Number(nextToken))){
            const n:number = Number(nextToken);
            if(n < 0){
              tokens[i + 1] = Math.abs(n);
              op = op === '+' ? '−' : '+';
            }
          }
        }

        output.push(op);
      }else{
        output.push(String(o));
      }
    }

    output.push(')');
  }

  /**
   *
   */
  private writeParamName(output:string[], param:MParam):void{
    output.push(param.name);
  }

  /**
   *
   */
  private writeParamValue(output:string[], param:MParam):void{
    if(param.value === param.emptyValue){
      // don't show anything
      output.push(' ');
    }else if(param.value === param.minusValue){
      // show minus sign only
      output.push('−');
    }else if(param.value){
      output.push(param.value.toText(false));
    }else{
      output.push('NaN');
    }
  }

}
