import { IActivityRef } from '../models/IActivityRef';
import { IPageInstance } from '../models/IPageInstance';
import { api } from '../utils/fetch';

export interface IGetPagesSequenceDraftResult {
  activity: IActivityRef;
  pages: ReadonlyArray<IPageInstance>;
}

export const getPagesSequenceDraft = async (activityId: string): Promise<IGetPagesSequenceDraftResult> => {
  const response = await api<IGetPagesSequenceDraftResult>(`/Publisher/PagesSequence/Get?activityId=${activityId}`);
  return response.json();
};
