import { Point } from '../../../js/geom/Point';

import { MathError } from '../../core/MathError';
import { XSort } from '../../core/XSort';
import { AnonymousFunction } from '../../elements/abstract/AnonymousFunction';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';
import { Evaluate } from '../../expr/manipulation/Evaluate';

/**
 * Ordonne les lignes d'une matrice.
 */
export class SortRows extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 2){
      return args.expectingArguments(2, 2);
    }

    if(args.getMatrix(0)){
      if(args.getWholeNumber(1)){
        return this.colIndex(args.getMatrix(0), args.getWholeNumber(1));
      }
      if(args.getAnonymousFunction(1)){
        return this.colValue(args.getMatrix(0), args.getAnonymousFunction(1), args.env);
      }
    }

    return null;
  }

  /**
   * Ordonne les lignes en utilisant la colonne spécifiée comme valeur des lignes.
   *
   * Si la valeur à la colonne spécifiée est égale pour deux lignes, on ordonne selon
   * les autres colonnes en partant à la colonne 0.
   */
  private colIndex(
      value:WMatrix,
      colIndex:RealElement):WMatrix{

    if(!colIndex.isWholeNumber()){
      return null;
    }

    const c:number = colIndex.toNumber();

    if(c >= value.columns){
      throw new MathError('Column out of range.');
    }

    const o:Point[] = [];
    for(let r:number = 0 ; r < value.rows ; r++){
      o.push(new Point(r, value.valueAt(r, c).toNumber()));
    }
    return this.sortImpl(value, o);
  }

  /**
   * Ordonne les lignes selon un calcul sur les colonnes de chaque ligne.
   * La fonction de calcul doit avoir un paramètre qui sera une liste de nombres.
   *
   * Si la valeur à la colonne spécifiée est égale pour deux lignes, on ordonne selon
   * les autres colonnes en partant à la colonne 0.
   */
  private colValue(
      value:WMatrix,
      calc:AnonymousFunction,
      env:Environment):WMatrix{

    const evaluator:Evaluate = new Evaluate(calc, env);
    const o:Point[] = [];
    for(let r:number = 0 ; r < value.rows ; r++){
      const cols:ContentElement[] = [];
      for(let c:number = 0 ; c < value.columns ; c++){
        cols.push(value.valueAt(r, c));
      }
      const n:RealElement = RealElement.parseElement(evaluator.evaluate1(env.culture.listFactory.createList(cols)));
      if(!n){
        return null;
      }
      o.push(new Point(r, n.toNumber()));
    }

    return this.sortImpl(value, o);
  }

  /**
   * values:
   * 	p.x: row index,
   * 	p.y: row value
   */
  private sortImpl(
      value:WMatrix,
      values:Point[]):WMatrix{

    let r:number;
    let c:number;

    values.sort(XSort.yxcoordinate);

    const z:RealElement[] = [];

    for(r = 0 ; r < value.rows ; r++){
      for(c = 0 ; c < value.columns ; c++){
        z.push(value.valueAt(values[r].x, c));
      }
    }

    return new WMatrix(
      z,
      value.columns,
      value.formatter);
  }

}
