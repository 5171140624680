import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, PencilIcon, DisabledButtonTooltip } from '@scolab/publisher-ui-kit';
import { ContentTagManagerModal } from './ContentTagManager/ContentTagManager';
import { LocaleProvider } from '../LocaleProvider';
import { locales } from '../../autoGenerate/locale';
import { useMediator } from '../../dependencyInjection/useMediator';
import { AccessAndRightsMediator } from '../../mediators/AccessAndRightsMediator';

interface IShowContentTagManagerModalButton {
  className?: string;
}

export const ShowContentTagManagerModalButton: React.FC<IShowContentTagManagerModalButton> = (props) => {
  const { className } = props;
  const [isOpen, setIsOpen] = React.useState(false);
  const onToggle = () => setIsOpen((prevState) => !prevState);
  const { canManageContentTags } = useMediator(AccessAndRightsMediator);

  return (
    <LocaleProvider locales={locales}>
      <DisabledButtonTooltip
        disabled={!canManageContentTags}
        intlKey='contentTags.cannotManageTags'
      >
        <Button
          onClick={onToggle}
          className={className}
          disabled={!canManageContentTags}
        >
          <PencilIcon />
          <FormattedMessage id="contentTags.manageTags" />
        </Button>
      </DisabledButtonTooltip>
      <ContentTagManagerModal
        isOpen={isOpen}
        onClose={onToggle}
      />
    </LocaleProvider>
  )
};
