/*
 * The data contained in this file is meant to be used by the dev server
 * when the actual data is otherwise unavailable (for example when authentication is required but not available)
 * Should only be used in conditional blocks to not get included in normal builds
 * if (process.env.WEBPACK_DEV_SERVER) {
 *   // here
 * }
 */

type DataListViewModel = {
  readonly Domain: string;
  readonly Key: string;
  readonly Items: readonly DataListViewModelItem[];
}

type DataListViewModelItem = {
  readonly Domain: string;
  readonly Key: string;
  readonly Data: string;
}

export const allVariantsData: DataListViewModel = {
  'Domain': 'variants',
  'Key': null,
  'Items': [
    {
      'Domain': 'variants',
      'Key': '.',
      'Data': '{"isObsolete": true, "modificatorId": 5933435, "modificationDateISO": "2022-04-29T16:21:23.404Z"}',
    },
    {
      'Domain': 'variants',
      'Key': 'おはよう',
      'Data': '{"creatorId": 1848540, "isObsolete": false, "description": "はじめまして", "modificatorId": 1848540, "creationDateISO": "2022-04-18T20:11:15.336Z", "modificationDateISO": "2022-04-19T20:06:44.24Z"}',
    },
    {
      'Domain': 'variants',
      'Key': '0test1',
      'Data': '{"creatorId": 729644, "description": "", "creationDateISO": "2022-04-23T18:37:22.211Z"}',
    },
    {
      'Domain': 'variants',
      'Key': '10',
      'Data': '{"creatorId": 2553543, "description": "", "creationDateISO": "2022-08-31T14:40:40.423Z"}',
    },
    {
      'Domain': 'variants',
      'Key': '11Eleves',
      'Data': '{"description": "test234", "modificatorId": 729644, "modificationDateISO": "2022-05-03T13:14:29.363Z"}',
    },
    {
      'Domain': 'variants',
      'Key': '30',
      'Data': '{"isObsolete": true, "modificatorId": 5933435, "modificationDateISO": "2022-04-27T19:20:47.491Z"}',
    },
    {
      'Domain': 'variants',
      'Key': '#+a',
      'Data': '{"isObsolete": true, "description": "asdf\\nasdf\\na\\na", "modificatorId": 729644, "modificationDateISO": "2022-09-19T18:16:06.012Z"}',
    },
    {
      'Domain': 'variants',
      'Key': '#+a 2',
      'Data': '{"creatorId": 729644, "isObsolete": true, "description": "test", "modificatorId": 1848540, "creationDateISO": "2022-04-08T15:45:14.385Z", "modificationDateISO": "2022-04-19T20:06:22.189Z"}',
    },
    {
      'Domain': 'variants',
      'Key': 'autoScrollAfterGoodAnswerOff',
      'Data': '{"description": "description", "modificatorId": 729644, "modificationDateISO": "2022-05-03T13:07:51.109Z"}',
    },
    {
      'Domain': 'variants',
      'Key': 'autoScrollAfterGoodAnswerOn',
      'Data': '{"description": "description", "modificatorId": 729644, "modificationDateISO": "2022-05-03T20:48:46.416Z"}',
    },
    {
      'Domain': 'variants',
      'Key': 'calculatorToolboxOff',
      'Data': '{"description": "Descriptino for calc toolbox off ", "modificatorId": 729644, "modificationDateISO": "2022-04-11T19:26:02.303Z"}',
    },
    {
      'Domain': 'variants',
      'Key': 'ehoo',
      'Data': '{"creatorId": 2553543, "description": "test pour voir la longueur de la descriiptoin qui pou osiukd oiuklejr oiaj lkja; a ; p jlkdfoiue r osiduf oisjdfls ufdou sdfljsodfiu sldkjf s df\\n", "creationDateISO": "2022-05-04T18:17:48.19Z"}',
    },
    {
      'Domain': 'variants',
      'Key': 'initialCheckAnswerDisabled',
      'Data': '{"description": "test description", "modificatorId": 729644, "modificationDateISO": "2022-05-03T20:49:01.461Z"}',
    },
    {
      'Domain': 'variants',
      'Key': 'jjjj',
      'Data': '{"creatorId": 5933435, "description": "", "creationDateISO": "2022-04-28T13:42:21.236Z"}',
    },
    {
      'Domain': 'variants',
      'Key': 'nbVII',
      'Data': '{"isObsolete": false, "modificatorId": 2553543, "modificationDateISO": "2022-05-04T18:16:25.223Z"}',
    },
    {
      'Domain': 'variants',
      'Key': 'nouvelle variante',
      'Data': '{"creatorId": 729644, "description": "", "creationDateISO": "2022-04-29T15:16:03.279Z"}',
    },
    {
      'Domain': 'variants',
      'Key': '🔥 oh yeah no forbidden charactersss 😈',
      'Data': '{"creatorId": 1848540, "isObsolete": false, "description": "I mean, pourquoi pas 😉", "modificatorId": 1848540, "creationDateISO": "2022-04-18T20:09:39.429Z", "modificationDateISO": "2022-04-19T20:06:40.808Z"}',
    },
    {
      'Domain': 'variants',
      'Key': 'py',
      'Data': '{"creatorId": 852821, "description": "jkbjk", "creationDateISO": "2022-05-04T16:52:50.961Z"}',
    },
    {
      'Domain': 'variants',
      'Key': 'sophie',
      'Data': '{"creatorId": 852821, "description": "", "creationDateISO": "2022-05-04T16:53:54.731Z"}',
    },
    {
      'Domain': 'variants',
      'Key': 'SophieTest',
      'Data': '{"creatorId": 7079420, "description": "Ceci est un test pour valider si une nouvelle variante créée est cherchable à partible de la recherche.", "creationDateISO": "2022-05-09T19:02:52.813Z"}',
    },
    {
      'Domain': 'variants',
      'Key': 'Sophietests 1111111111',
      'Data': '{"creatorId": 7079420, "description": "", "creationDateISO": "2022-05-09T19:03:58.465Z"}',
    },
    {
      'Domain': 'variants',
      'Key': 'test',
      'Data': '{"isObsolete": true, "modificatorId": 5933435, "modificationDateISO": "2022-04-27T19:02:14.44Z"}',
    },
    {
      'Domain': 'variants',
      'Key': 'test1',
      'Data': '{"creatorId": 5933435, "description": "testqa", "creationDateISO": "2022-04-27T17:00:15.171Z"}',
    },
    {
      'Domain': 'variants',
      'Key': 'test11',
      'Data': '{"creatorId": 5933435, "description": "test à deleter ", "creationDateISO": "2022-04-29T14:11:06.688Z"}',
    },
    {
      'Domain': 'variants',
      'Key': 'test2',
      'Data': '{"creatorId": 729644, "description": "descrption", "creationDateISO": "2022-05-03T13:08:24.186Z"}',
    },
    {
      'Domain': 'variants',
      'Key': 'test222',
      'Data': '{"creatorId": 729644, "description": "test", "creationDateISO": "2022-05-04T14:59:14.085Z"}',
    },
    {
      'Domain': 'variants',
      'Key': 'test3',
      'Data': '{"creatorId": 729644, "description": "", "creationDateISO": "2022-05-03T13:09:56.325Z"}',
    },
    {
      'Domain': 'variants',
      'Key': 'TESTAujourd\u0027hui',
      'Data': '{"creatorId": 7079420, "description": "", "creationDateISO": "2022-05-09T19:03:24.838Z"}',
    },
    {
      'Domain': 'variants',
      'Key': 'test creation',
      'Data': '{"creatorId": 729644, "description": "test description", "creationDateISO": "2022-04-07T20:52:48.244Z"}',
    },
    {
      'Domain': 'variants',
      'Key': 'test creation #2',
      'Data': '{"creatorId": 729644, "description": "une longue description une longue description une longue description une longue description une longue description une longue description une longue description une longue description une longue description une longue description", "creationDateISO": "2022-04-07T21:22:12.475Z"}',
    },
    {
      'Domain': 'variants',
      'Key': 'testddd',
      'Data': '{"creatorId": 729644, "description": "", "creationDateISO": "2022-05-04T15:05:04.113Z"}',
    },
    {
      'Domain': 'variants',
      'Key': 'TestHugo',
      'Data': '{"creatorId": 7595706, "description": "Test pour recherche", "creationDateISO": "2022-09-28T16:33:24.118Z"}',
    },
    {
      'Domain': 'variants',
      'Key': 'variante test',
      'Data': '{"creatorId": 729644, "description": "test", "creationDateISO": "2022-05-04T15:03:56.661Z"}',
    },
    {
      'Domain': 'variants',
      'Key': '#x#',
      'Data': '{"isObsolete": false, "description": "asdfassfdfsdfsdfg", "modificatorId": 6391589, "modificationDateISO": "2022-09-29T16:54:43.828Z"}',
    },
    {
      'Domain': 'variants',
      'Key': 'yt.é.m..é;;;;',
      'Data': '{"creatorId": 5933435, "description": "", "creationDateISO": "2022-04-29T16:52:04.709Z"}',
    },
  ],
};

export const allVariants: readonly string[] = [
  '.',
  '10-table',
  'no0or1',
  'calculatorToolboxOff',
  '1-table',
  '2param',
  '2-table',
  'fullrange',
  'data_4',
  'rangeI',
  'rangeII',
  'rangeIII',
  'term-1',
  'term-2',
  'term-3',
  'fullRange',
  '33486',
  '3param',
  '3-table',
  '45',
  '4param',
  '4-table',
  '5-table',
  '6-table',
  '7-table',
  '8-table',
  'exclude-3',
  '9-table',
  'a',
  'ab',
  'metric',
  'gamblingOFF',
  'abNoExp',
  'abNoNeg',
  'CAD',
  'abfrIII',
  'mbIII',
  'abfrIV',
  'abfrIX',
  'abfrV',
  'celsius',
  'abfr',
  'abfrVI',
  'abfrVII',
  'abfrVIII',
  'ah',
  'aire',
  'ajout',
  'aNeg',
  'ANeg',
  'BPos',
  'autoScrollAfterGoodAnswerOff',
  'b',
  'bigRange',
  'bk',
  'bookVariant',
  'btnOFF',
  'calculatorOff',
  'cc',
  'us',
  'imperial',
  'USD',
  'cc4',
  'ccIII',
  'cell',
  'celsius|gamblingOFF|abfr|aben',
  'celsius|gamblingOFF|mbfr|mben',
  'celsius|nbfr|nben',
  'celsius|onfr|onen',
  'celsius|qcfr|qcen',
  'chat',
  'Complete to Unlock Challenge 1 (Bronze)',
  'Complete to Unlock Challenge 1 (Gold)',
  'Complete to Unlock Challenge 2 (Gold)',
  'Complete to Unlock Challenge 2 (Silver)',
  'Complete to Unlock Challenge 3 (Gold)',
  'cos',
  'coteA',
  'data_1',
  'draw1',
  'draw2',
  'range1',
  'data_2',
  'data_3',
  'introOFF',
  'data_5',
  'decimeter',
  'decreasing',
  'max',
  'min',
  'data_6',
  'deuxParam',
  'divfact',
  'dixieme',
  'dollars',
  'txt1',
  'elementaryKeyboard12',
  'lexiconOFF',
  'Finances publiques',
  'grandeIntervalle',
  'hard',
  'hidePageTitle',
  'showFooterNextPageButton',
  'showResetPageButtonOnQuestionChanged',
  'hk',
  'II',
  'image',
  'fahrenheit',
  'cc6',
  'CC5',
  'CC',
  'metricUs',
  '1-digit',
  'teksIII',
  'buzzmath',
  'impropreMixte',
  'increasing',
  'data_7',
  'index1',
  'initialCheckAnswerHidden',
  'lexiconOff',
  'lexiconToolboxOff',
  'lexiconLinksOff',
  'initialSet1',
  'initialSet2',
  'intro',
  'introOff',
  'range2',
  'introOn',
  'jdmon',
  'autoScrollAfterGoodAnswerOn',
  'initialCheckAnswerDisabled',
  'textToSpeechToolboxOff',
  'textToSpeechInContext',
  'ccI',
  'ccII',
  'withExchange',
  'withoutExchange',
  'teksI',
  'teks',
  'teksII',
  'acelsius',
  'qc',
  'noExchange',
  'abIII',
  'poncIII',
  'under1000',
  'on',
  'onI',
  'onII',
  'mbfr',
  'mb',
  'onfr',
  'onIII',
  'onfrIII',
  'nbfr',
  'nb',
  'nbIII',
  'lexiconOn',
  'max100',
  'max100000',
  'mbIV',
  'mbIX',
  'mbV',
  'mbVI',
  'mbVII',
  'mbVIII',
  'netmath',
  'netmaths',
  'decimal_#.#',
  'decimal_#.##',
  'nb_#',
  'nb_0-100',
  'nb_0-1000',
  'nb_0-100000',
  'nb_####',
  'voc_dilatationON',
  'abIV',
  'qcfr',
  'isometrieON',
  'mvIX',
  'mbNoExp',
  'mbNoNeg',
  'NB',
  'nb5',
  'nbIV',
  'onfrV',
  'onfrVI',
  'onfrVII',
  'onfrVIII',
  'CA',
  'onIX',
  'onVIII',
  'onVI',
  'onVII',
  'onfrIV',
  'onIV',
  'onV',
  'qcen',
  'qcpIII',
  'qcpIV',
  'montant1',
  'nbIX',
  'nbV',
  'nbVI',
  'nbVII',
  'nbVIII',
  'nc5',
  'newSituationAfterRetry',
  'newSituationAfterSuccess',
  'noHint',
  'noImages',
  'showNbWalkers',
  'noIntro',
  'ON',
  'onXI',
  'paramABHK',
  'sin',
  'ordC',
  'ordL',
  'ordLXXX',
  'ordXXX',
  'p3',
  'page 4',
  'page a',
  'page10',
  'pageA',
  'pageB',
  'pageC',
  'pageD',
  'pageE',
  'pageII',
  'pageIV',
  'pageV',
  'paramK',
  'penToolboxOff',
  'penToolboxOn',
  'periode',
  'pictoUnite',
  'plusDeDeux',
  'plusGrand',
  'qcpV',
  'randomSituation',
  'range',
  'range0-12',
  'q#!=#',
  '#x#',
  'elem1',
  'range1000',
  'range2-10',
  'a2-10',
  'introON',
  'range2-100',
  'a0-10',
  '#+a',
  'range2-20',
  'b1-20',
  'q0-15',
  'range3',
  'range4',
  'range5',
  'range60-99',
  'rangeA',
  'rangeB',
  'rangeC',
  'rangeGrand',
  'rate',
  'secondPart',
  'showHyperlink',
  'showLink',
  'simplifiable',
  'situation2NonSel',
  'situation3',
  'soust',
  'summer',
  'table2',
  'table4',
  'table8',
  'takingScreenshot',
  'test',
  'thirdPart',
  'tickV',
  'tickValHaut',
  'tickX',
  'tickXX',
  'tickXXV',
  'twoTable',
  'typeA',
  'typeB',
  'typeC',
  'under10',
  'under25',
  'unitMeasure2-3',
  'v',
  'varianteAAA',
  'variantsOff',
  'withExchangeVar',
  '00A8775BFF4872453B685EFB3762406A25431768',
  '11Eleves',
  'random',
  'data_8',
  'data_9',
  'showHints',
  '30',
  'mbfrIX',
  'abVII',
  'retrait',
  'am',
  'ansA',
  'ansB',
  'c',
  'ca',
  'casUn',
  'casDeux',
  'CC4',
  'cennes',
  'sans1cenne',
  'negatif',
  'easy',
  'firstGrade',
  'secondGrade',
  'fr',
  'gamblinOFF',
  'honhonBaguette',
  'imeprial',
  'mobile',
  'voc_casseau',
  'voc_barquette',
  'nb_positive',
  'unit_cm',
  'nbfrV',
  'nb_0-50',
  'Ont6',
  'P3',
  'pageI',
  'pageIII',
  'QCS4',
  'withExchance',
  'q1',
  'threeTable',
  'fourTable',
  'fiveTable',
  'sixTable',
  'sevenTable',
  'eightTable',
  'nineTable',
  'tenTable',
  'rangeIV',
  'smallRange',
  'sub',
  'var2'
];
