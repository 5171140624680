import { Point } from '../../../../js/geom/Point';
import { XRound } from '../../../core/XRound';
import { IGeomTransform } from '../../../elements/models/transforms/IGeomTransform';

/**
 *
 */
export class RotationImpl implements IGeomTransform {

  private center:Point;

  private angle:number;

  private useRadians:boolean;

  /**
   *
   */
  constructor(center:Point, angle:number, useRadians:boolean) {

    this.center = center;
    this.angle = angle;
    this.useRadians = useRadians;
  }

  /**
   *
   */
  public transformPoint(valueArg:Point):Point{
    const angle:number =
      this.useRadians ?
        this.angle :
        this.angle * Math.PI / 180;

    let value = valueArg.subtract(this.center);

    value =
      new Point(
        value.x * Math.cos(angle) - value.y * Math.sin(angle),
        value.x * Math.sin(angle) + value.y * Math.cos(angle));

    value =
      new Point(
        XRound.safeRound(value.x),
        XRound.safeRound(value.y));

    return value.add(this.center);
  }

}
