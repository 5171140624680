import { XMath } from '../../core/XMath';
import { Attributes } from '../../elements/abstract/Attributes';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { ElementCodes } from '../../elements/abstract/ElementCodes';
import { OperatorElement } from '../../elements/abstract/OperatorElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WPolynomial } from '../../elements/tokens/WPolynomial';
import { WRadical } from '../../elements/tokens/WRadical';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';

/**
 * Addition.
 */
export class Plus extends OperatorElement {

  private static _instance:Plus;

  public static getInstance():Plus{
    if(!Plus._instance){
      Plus._instance = new Plus();
    }
    return Plus._instance;
  }

  public static INVISIBLE_PLUS:string = String.fromCharCode(0x2064);

  /**
   *
   */
  public getAttributes():number{
    return super.getAttributes() | Attributes.COMMUTATIVE;
  }

  /**
   *
   */
  public toString():string{
    return '+';
  }

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 2){
      return args.expectingArguments(2, 2);
    }

    // polynomials
    if(args.getPolynomial(0) && args.getPolynomial(1)){
      return this.polynomials(args.getPolynomial(0), args.getPolynomial(1), args.env);
    }
    if(args.getPolynomial(0) && args.getReal(1)){
      return this.polynomialR(args.getPolynomial(0), args.getReal(1), args.env);
    }
    if(args.getPolynomial(1) && args.getReal(0)){
      return this.polynomialR(args.getPolynomial(1), args.getReal(0), args.env);
    }

    // radicals
    if(args.getRadical(0) && args.getRadical(1)){
      return this.radicals(args.getRadical(0), args.getRadical(1), args.env);
    }
    if(args.getRadical(0) && args.getPolynomial(1)){
      return this.radicalR(args.getRadical(0), args.getReal(1), args.env);
    }
    if(args.getRadical(1) && args.getPolynomial(0)){
      return this.radicalR(args.getRadical(1), args.getReal(0), args.env);
    }

    // reals
    if(args.getReal(0) && args.getReal(1)){
      return args.env.reals.add(args.getReal(0), args.getReal(1));
    }

    return null;
  }

  /**
   *
   */
  public polynomials(a:WPolynomial, b:WPolynomial, env:Environment):ContentElement{
    const c:WPolynomial = a.add(b);

    // the normalization process actually perform the addition of similar terms
    const r:ContentElement = c.normalize(env.reals);

    if(r instanceof WPolynomial){
      if(a.numMonomials + b.numMonomials === (<WPolynomial>r ).numMonomials){
        this.wasCondensedWork = true;
      }
    }

    return r;
  }

  /**
   *
   */
  public radicals(a:WRadical, b:WRadical, env:Environment):ContentElement{
    if(WRadical.similar(a, b)){
      const c:RealElement = env.reals.add(a.coefficient, b.coefficient);

      if(c.toNumber() === 0){
        return c;
      }

      return new WRadical(
        a.base,
        a.index,
        c,
        a.formatter);
    }

    return env.culture.createNumber(XMath.safeAdd(a.toDecimal(), b.toDecimal()));
  }

  /**
   *
   */
  public polynomialR(aArg:WPolynomial, b:RealElement, env:Environment):ContentElement{
    let a:WPolynomial = aArg;
    if(!a.hasConstant){
      this.wasCondensedWork = true;
    }
    a = env.polynomials.addR(a, b);
    return a.normalize(env.reals);
  }

  /**
   *
   */
  public radicalR(a:WRadical, b:RealElement, env:Environment):RealElement{
    return env.culture.createNumber(XMath.safeAdd(a.toDecimal(), b.toNumber()));
  }

  /**
   *
   */
  public getElementCode():string{
    return ElementCodes.OP_ADDITION;
  }

}
