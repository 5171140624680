import { Attributes } from '../abstract/Attributes';
import { FunctionElement } from '../abstract/FunctionElement';

/**
 *
 */
export class OperatorElement extends FunctionElement {

  /**
   * Indicates whether the function model
   * attribute must be preserved.
   */
  public functionModel:boolean = false;

  /**
   *
   */
  public getAttributes():number{
    if(this.functionModel){
      return super.getAttributes();
    }
    // Remove function model flag.
    return super.getAttributes() & ~Attributes.FUNCTION_MODEL;
  }

}
