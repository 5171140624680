import { MathError } from '../../core/MathError';
import { XMath } from '../../core/XMath';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { TDDiagram } from '../../elements/models/tree/TDDiagram';
import { TDNode } from '../../elements/models/tree/TDNode';
import { TDObject } from '../../elements/models/tree/TDObject';
import { TDState } from '../../elements/models/tree/TDState';
import { WList } from '../../elements/tokens/WList';
import { WListOfString } from '../../elements/tokens/WListOfString';
import { WTreeDiagram } from '../../elements/tokens/WTreeDiagram';
import { NumberParser } from '../../elements/utils/NumberParser';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';
import { TextExporter } from '../../expr/conversion/output/TextExporter';

/**
 * Constructor function for a tree diagram.
 *
 * First parameter is the label/value, the second parameter if present is the frequencies.
 */
export class TreeDiagram extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length < 1 || args.length > 3){
      return args.expectingArguments(1, 3);
    }

    const numberParser:NumberParser = new NumberParser(args.env.culture);

    if(args.length === 1){
      if(args.getReals(0)){
        return this.values(args.getReals(0), numberParser, args.env);
      }
      if(args.getStrings(0)){
        return this.labels(args.getStrings(0), numberParser);
      }
    }else if(args.length === 2){
      if(args.getReals(0) && args.getReals(1)){
        return this.valuesf(args.getReals(0), args.getReals(1), numberParser, args.env);
      }
      if(args.getStrings(0) && args.getReals(1)){
        return this.labelsf(args.getStrings(0), args.getReals(1), numberParser);
      }
    }else if(args.length === 3){
      if(args.getStrings(0) && args.getReals(1) && args.getReals(2)){
        return this.labelsvf(args.getStrings(0), args.getReals(1), args.getReals(2), numberParser);
      }
    }

    return null;
  }

  /**
   * Create a tree diagram with the list of objects,
   * assuming a frequency of 1 for every object.
   */
  private labels(labels:WListOfString, numberParser:NumberParser):WTreeDiagram{
    // labels + values(implicit?) + freq 1
    return this.create(
      labels.toStrings(),
      this.autoValues(labels, numberParser),
      [],
      numberParser);
  }

  /**
   *
   */
  private labelsf(labels:WListOfString, freq:WList, numberParser:NumberParser):WTreeDiagram{
    // labels + values(implicit?) + freq
    return this.create(
      labels.toStrings(),
      this.autoValues(labels, numberParser),
      freq.toNumbersV(),
      numberParser);
  }

  /**
   *
   */
  private values(
      values:WList,
      numberParser:NumberParser,
      env:Environment):WTreeDiagram{

    // labels(implicit) + values + freq 1
    return this.create(
      this.autoLabels(values, env),
      values.toNumbersV(),
      [],
      numberParser);
  }

  /**
   *
   */
  private valuesf(
      values:WList,
      freq:WList,
      numberParser:NumberParser,
      env:Environment):WTreeDiagram{

    // labels(implicit) + values + freq
    return this.create(
      this.autoLabels(values, env),
      values.toNumbersV(),
      freq.toNumbersV(),
      numberParser);
  }

  /**
   *
   */
  private labelsvf(
      labels:WListOfString,
      values:WList,
      freq:WList,
      numberParser:NumberParser):WTreeDiagram{

    // labels + values + freq
    return this.create(
      labels.toStrings(),
      values.toNumbersV(),
      freq.toNumbersV(),
      numberParser);
  }

  /**
   *
   */
  private autoLabels(values:WList, env:Environment):string[]{
    const o:string[] = [];
    for(let i:number = 0 ; i < values.count ; i++){
      o.push(TextExporter.toString(values.getItemAt(i), env.format));
    }
    return o;
  }

  /**
   *
   */
  private autoValues(labels:WListOfString, numberParser:NumberParser):number[]{
    const o:number[] = [];
    for(let i:number = 0 ; i < labels.count ; i++){
      o.push(numberParser.parseNumber(labels.getValueAt(i)));
    }
    return o;
  }

  /**
   *
   */
  private create(
      labels:string[],
      values:number[],
      freq:number[],
      numberParser:NumberParser):WTreeDiagram{

    let i:number;
    let label:string;
    let value:number;
    let count:number;

    const diagram:TDDiagram = new TDDiagram();
    diagram.isAlwaysValid = true;
    diagram.root = new TDNode();
    diagram.root.state = [];

    for(i = 0 ; i < labels.length ; i++){
      label = labels[i];
      if(label === TDNode.ANY_LEAFS){
        throw new MathError(`${TDNode.ANY_LEAFS} can't be used for a node label.`);
      }
      if(label === TDNode.ANY_NODE){
        throw new MathError(`${TDNode.ANY_NODE} can't be used for a node label.`);
      }
    }

    const n:number = Math.max(labels.length, values.length, freq.length);

    for(i = 0 ; i < n ; i++){
      label = i < labels.length ? labels[i] : String(i);
      value = i < values.length ? values[i] : numberParser.parseNumber(label);
      count = i < freq.length ? freq[i] : 1;

      if(!XMath.isInteger(count)){
        throw new MathError('Frequencies must be integers');
      }

      diagram.root.state.push(new TDState(new TDObject(label, value), count));
    }
    return new WTreeDiagram(diagram, null);
  }

}
