/**
 * Defines the different built-in levels at which expressions can be evaluated.
 */
export enum ContextLevels {
  None = 'None',
  Variables = 'Variables',
  Live = 'Live',
  Active = 'Active',
  Late = 'Late',
  Captured = 'Captured',
}
