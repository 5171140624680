import React from 'react';
import { ISortableListContext, SortableListContext } from './SortableListContext';
import { isModMouseEvent } from '../../../utils/mouse';

export interface ISortableListContainerProps {
  readonly selectedItemsIndexes?: ReadonlyArray<number>;
  readonly onChangeSelectedItem?: (selectedItemIndex: number) => void;
  readonly onChangeSelectedItems?: (selectedItemsIndexes: ReadonlyArray<number>) => void;
  readonly dropTargetIndex: number;
  readonly setDropTargetIndex: (index: number) => void;
  readonly onDrop: (dropTargetIndex: number) => void;
  readonly onDragStart?: (dragSourceIndex: number) => void;
  readonly className?: string;
  readonly disabled?: boolean;
}

export class SortableListContainer extends React.PureComponent<ISortableListContainerProps> {
  private providerValue: ISortableListContext;

  constructor(props: ISortableListContainerProps) {
    super(props);
    this.providerValue = {
      onChangeDropTarget: this.onChangeDropTarget,
      onSelectItem: this.onSelectItem,
      afterSelectItem: this.afterSelectItem,
      onDragStart: this.onDragStart,
      onDragEnd: this.onDragEnd,
    };
  }

  public render(): JSX.Element {
    const {
      className,
      children,
    } = this.props;
    return (
      <div
        className={className}
        data-testid='SortableListContainer'
      >
        <SortableListContext.Provider
          value={this.providerValue}
        >
          {children}
        </SortableListContext.Provider>
      </div>
    );
  }

  private onChangeDropTarget = (targetIndex: number) => {
    const {
      setDropTargetIndex,
    } = this.props;
    setDropTargetIndex(targetIndex);
  }

  private onSelectItem = (itemIndex: number, event: React.MouseEvent) => {
    if (this.props.onChangeSelectedItems) {
      const {
        selectedItemsIndexes,
        onChangeSelectedItems,
      } = this.props;
      const isItemSelected = selectedItemsIndexes.includes(itemIndex);
      if (isModMouseEvent(event.nativeEvent)) {
        if (isItemSelected) {
          onChangeSelectedItems(selectedItemsIndexes.filter((index) => index !== itemIndex));
        } else {
          onChangeSelectedItems(selectedItemsIndexes.concat(itemIndex));
        }
      } else if (event.shiftKey && selectedItemsIndexes.length >= 1) {
        document.getSelection().removeAllRanges();
        const lastTargetIndex = selectedItemsIndexes[selectedItemsIndexes.length - 1];
        const min = Math.min(itemIndex, lastTargetIndex);
        const max = Math.max(itemIndex, lastTargetIndex);
        const newSelectedItemsIndexes = selectedItemsIndexes.concat();
        for (let i = min; i <= max; i += 1) {
          if (!selectedItemsIndexes.includes(i)) {
            newSelectedItemsIndexes.push(i);
          }
        }
        onChangeSelectedItems(newSelectedItemsIndexes);
      } else if (!isItemSelected || selectedItemsIndexes.length > 1) {
        onChangeSelectedItems([itemIndex]);
      }
    } else {
      const {
        onChangeSelectedItem,
      } = this.props;
      onChangeSelectedItem(itemIndex);
    }
  }

  private afterSelectItem = (itemIndex: number, event: React.MouseEvent) => {
    if (this.props.onChangeSelectedItems) {
      const {
        selectedItemsIndexes,
        onChangeSelectedItems,
      } = this.props;
      const hasPressedSpecialKey = event.ctrlKey || event.metaKey || event.shiftKey;
      const hasManyItemsAlreadySelected = selectedItemsIndexes.length > 1;
      const isItemSelected = selectedItemsIndexes.includes(itemIndex);
      if (!hasPressedSpecialKey && hasManyItemsAlreadySelected && isItemSelected) {
        onChangeSelectedItems([itemIndex]);
      }
    }
  }

  private onDragStart = (itemIndex: number) => {
    const {
      onDragStart,
      setDropTargetIndex,
    } = this.props;
    if(onDragStart){
      onDragStart(itemIndex);
    }
    setDropTargetIndex(itemIndex);
  }

  private onDragEnd = () => {
    const {
      onDrop,
      dropTargetIndex,
      setDropTargetIndex,
    } = this.props;
    onDrop(dropTargetIndex);
    setDropTargetIndex(null);
  }
}
