import { ClientApplicationModel } from '../../models/ClientApplicationModel';
import { api } from '../../utils/fetch';

export const mergeContentTags = async (sourceContentTagIds: ReadonlyArray<number>, targetContentTagId: number): Promise<boolean> => {
  const clientApplication = ClientApplicationModel.getInstance();
  const sourceContentTagIdParameter = sourceContentTagIds.map((id) => `sourceContentTagId=${id}`).join('&')

  const response = await api(`/Publisher/ContentTags/Merge?${sourceContentTagIdParameter}&targetContentTagId=${targetContentTagId}&${clientApplication.params}`);
  return response.ok;
};
