import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Dénominateur.
 * Always return a positive value.
 */
export class Denominator extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 1){
      return args.expectingArguments(1, 1);
    }

    if(args.getRational(0)){
      return args.env.culture.createNumber(Math.abs(args.getRational(0).denominator));
    }
    if(args.getReal(0)){
      return args.env.culture.createNumber(1);
    }
    if(args.getPolynomial(0)){
      return args.env.culture.createNumber(1);
    }
    if(args.getFraction(0)){
      return args.getFraction(0).denominator;
    }

    return null;
  }

}
