import { MmlWriter } from '../../../core/mml/MmlWriter';
import { RealElement } from '../../../elements/abstract/RealElement';
import { BaseMatrixFormatter } from '../../../elements/formats/BaseMatrixFormatter';
import { IMarkupExporter } from '../../../elements/markers/IMarkupExporter';
import { CultureInfo } from '../../../localization/CultureInfo';
import { PatternToken } from '../../../elements/formats/matrices/PatternToken';
import { RowPartPattern } from '../../../elements/formats/matrices/RowPartPattern';

/**
 *
 */
export class AdvancedMatrixFormatter extends BaseMatrixFormatter {

  /**
   * | is the column separator
   * {0} is an example of a placeholder for the column 0 value from the matrix.
   * @ is replaced by the row number starting at 1.
   */
  private rowFormat:string;

  constructor(
      culture:CultureInfo,
      rowFormat:string){
    super(culture);
    this.rowFormat = rowFormat;
  }

  public writeTo(exporter:IMarkupExporter, values:RealElement[], columns:number):boolean {

    const w:MmlWriter = exporter.writer;

    const columnsFormat:any[] =
      this.rowFormat.split('|').map(
        this.parseRowPattern, this);

    // @. {0}|+|{1}|=|{2}

    const rows:number = values.length / columns;

    w.beginTable();
    for(let r:number = 0 ; r < rows ; r++){
      w.beginTr();
      for(let c:number = 0 ; c < columnsFormat.length ; c++){
        const pattern:RowPartPattern = columnsFormat[c];
        w.beginTd();
        for(let i:number = 0 ; i < pattern.tokens.length ; i++){
          const part:PatternToken = pattern.tokens[i];
          if(!part){
            continue;
          }
          part.writeTo(exporter, values, columns, r);
        }
        w.endTd();
      }
      w.endTr();
    }
    w.endTable();
    return true;
  }

  private parseRowPattern(str:string, ..._:any[]):RowPartPattern{
    return RowPartPattern.parse(str);
  }

}
