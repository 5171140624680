import React from 'react';
import { observer } from 'mobx-react';
import { PublisherApiAuth, inject } from '@scolab/common-editor';
import { Spinner } from '@scolab/publisher-ui-kit';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import LoginAppMediator from '../mediators/LoginAppMediator';
import { linkTo, Routes } from '../config/routes';

@observer
class ProtectedComponentComponent extends React.Component<RouteComponentProps> {

  @inject(LoginAppMediator)
  private mediator: LoginAppMediator;

  public async componentDidMount() {
    const { isAuthenticated } = this.mediator;

    // When a token is present, but a user is still unauthenticated, it's means the token should
    // be validated.
    if (PublisherApiAuth.hasToken() && !isAuthenticated) {
      await this.mediator.startSessionFromToken();
    }
  }

  public render(): React.ReactNode {
    const { isAuthenticated } = this.mediator;
    const { children, history } = this.props;

    // Show the route's contents if the user is authenticated.
    if (isAuthenticated) {
      return children;
    }

    // Give a moment for the JWT to be validated before redirecting to the
    // login page. This state will handle the period when the JWT is being
    // validated.
    if (PublisherApiAuth.hasToken()) {
      return (<Spinner />);
    }

    // At this point, anything else assumes a failure.
    // Forward using pure javascript because of possible competing React Router instances
    // with their own prefixes.
    window.location.assign(history.createHref({
      pathname: `${linkTo(Routes.Login)}/`,
      search: `Cause=ProtectedComponent&ReturnUrl=${encodeURIComponent(`${window.location}`)}`,
    }));

    return (<Spinner />);
  }
}


export const ProtectedComponent = withRouter(ProtectedComponentComponent);
