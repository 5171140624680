import { AbstractStep } from '../../AbstractStep';
import { Compartment } from '../../Compartment';
import { LongDivisionOperationEuclidienne } from '../../LongDivisionOperationEuclidienne';
import { DivisionModel } from '../../models/DivisionModel';
import { DivQuotient } from './DivQuotient';
import { DivAddSeparator } from './DivAddSeparator';

/**
 *
 */
export class DivNewDecimal extends AbstractStep {

  private model:DivisionModel;

  private division:LongDivisionOperationEuclidienne;

  private insertedSeperator:boolean = false;

  constructor(
      division:LongDivisionOperationEuclidienne,
      consecutive:boolean){
    super(division);
    this.division = division;
    this.model = division.model;

    let zero:Compartment;

    if(division.decimals === 0){
      this.insertedSeperator = true;
    }

    if(consecutive){
      zero = Compartment.createDigit(0);
      this.target.push(zero);
      division.result.push(zero);
    }

    zero = Compartment.createDigit(0);
    this.target.push(zero);

    division.lastStep.push(zero);
    division.rest.push(0);
    division.decimals++;

    this.setDescription(division.culture.getString('LongDivisionEuclidienne.newDecimal'));
  }

  public next():AbstractStep {
    if( this.insertedSeperator ) {
      return new DivAddSeparator(this.division);
    }
    const _rest:number = Number(this.division.rest.join(''));
    return new DivQuotient(this.division, _rest, this.model.normalizedDivisor);
  }

}
