/* eslint-disable no-restricted-imports */
import { modelNameSymbol, modelTypeSymbol, storeSymbol } from 'mmlpx/esm/core/dependency-inject/meta';
import { observe } from 'mobx';
import { getInjector } from 'mmlpx/esm/core/dependency-inject/instantiate';
import { Scope } from 'mmlpx/esm/core/dependency-inject/Injector';
/* eslint-enable no-restricted-imports */

export interface IPreloadInfo {
  getHost: () => any,
  key: string,
  getStoreName: (keyValue: string) => string
}

type Constructor = { new(): any };

/**
 * @param identifier: Each Store is stored in a Singleton array with the identifier as key
 * @param preload | preloadInfo
 */
export function Store (identifier: string, preload?: boolean): (target: Constructor) => void; // Static store
export function Store (identifier: () => string, preloadInfo?: IPreloadInfo): (target: Constructor) => void; // Dynamic store
export function Store (identifier: string | (() => string), preloadInfo?: boolean | IPreloadInfo) {
  return (target: Constructor) => {
    target[modelTypeSymbol] = storeSymbol;
    Object.defineProperty(target, modelNameSymbol, {
      get() {
        if (typeof identifier === 'function') {
          return identifier();
        }
        return identifier;
      }
    });

    if (preloadInfo) {
      PreloadManager.registerPreload(
        target,
        isDynamicPreload(preloadInfo) ? preloadInfo : identifier as string,
      );
    }
  }
}

/**
 * MobX crashes if an observable value is modified inside a @computed.
 * MMLPX stores all the stores inside an observable map for it's undo/redo system,
 * and each store is added to this map on demand, which cause mobx to crash when a store
 * is accessed for the first time inside a @computed function.
 *
 * Preloading the stores before they are accessed inside a @computed is necessary until we
 * stop using MMLPX's onSnapshot utility. It's the usage of genReactiveInjector() inside
 * this utility that creates the observable map of stores.
 *
 * This class is meant to be used internally by the dependencyInjection helpers and not be exposed outside.
 */
export class PreloadManager {
  private static preloadedStores: Map<Constructor, { preloadInfo: string | IPreloadInfo; disposer?: void | (() => void) }> = new Map();

  public static registerPreload(target: Constructor, preloadInfo: string | IPreloadInfo): void {
    const disposer = this.startPreloading(target, preloadInfo);
    this.preloadedStores.set(
      target,
      {
        preloadInfo,
        disposer,
      }
    );
  }

  public static resetPreloads(): void {
    this.preloadedStores.forEach((value, key) => {
      if (value.disposer) {
        value.disposer();
      }
      value.disposer = this.startPreloading(key, value.preloadInfo);
    })
  }

  private static startPreloading(target: Constructor, preloadInfo: string | IPreloadInfo): void | (() => void) {
    if (isDynamicPreload(preloadInfo)) {
      return observe(
        preloadInfo.getHost(),
        preloadInfo.key,
        (change) => {
          const storeName = preloadInfo.getStoreName(change.newValue);
          this.preloadStore(target, storeName);
        },
        true
      );
    }

    this.preloadStore(target, preloadInfo);
  }

  private static preloadStore(target: Constructor, storeName: string) {
    const injector = getInjector();
    injector.get(target, {
      name: storeName,
      scope: 'singleton' as Scope.Singleton,
    });
  }
}

const isDynamicPreload = (preloadInfo: string | boolean | IPreloadInfo): preloadInfo is IPreloadInfo => {
  return typeof preloadInfo === 'object'
}
