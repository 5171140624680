import { Point } from '../../../js/geom/Point';

import { Delegate } from '../../../js/utils/Delegate';

import { XMath } from '../../core/XMath';
import { Attributes } from '../../elements/abstract/Attributes';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { ElementCodes } from '../../elements/abstract/ElementCodes';
import { RealElement } from '../../elements/abstract/RealElement';
import { TokenElement } from '../../elements/abstract/TokenElement';
import { AbstractFormatter } from '../../elements/formats/AbstractFormatter';
import { BaseRadicalFormatter } from '../../elements/formats/BaseRadicalFormatter';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';
import { RadicalModel } from '../../elements/models/RadicalModel';
import { RealsImpl } from '../../elements/utils/RealsImpl';
import { FactorInteger } from '../../expr/manipulation/alt/FactorInteger';

/**
 *
 */
export class WRadical extends TokenElement {

  private _base:RealElement;

  public get base():RealElement{return this._base;}

  private _index:RealElement;

  public get index():RealElement{return this._index;}

  private _coefficient:RealElement;

  public get coefficient():RealElement{return this._coefficient;}

  private _formatter:BaseRadicalFormatter;

  public get formatter():BaseRadicalFormatter{return this._formatter;}

  constructor(
      base:RealElement,
      index:RealElement, // default 2
      coefficient:RealElement, // default 1
      formatter:BaseRadicalFormatter){
    super();
    if(!formatter){
      throw new Error('Formatter required');
    }
    this._base = base;
    this._index = index;
    this._coefficient = coefficient;
    this._formatter = formatter;
  }

  public getAttributes():number{
    if(this.coefficient.toNumber() === 1){
      return super.getAttributes();
    }
    return super.getAttributes() | Attributes.COMPLEX_CONTENT;
  }

  public applyFormat(formatter:AbstractFormatter):ContentElement{
    if(formatter instanceof BaseRadicalFormatter){
      return new WRadical(
        this.base,
        this.index,
        this.coefficient,
        <BaseRadicalFormatter>formatter );
    }
    return this;
  }

  public getFormat():AbstractFormatter{
    return this._formatter;
  }

  public toDecimal():number{
    const c:number = this.coefficient.toNumber();
    const b:number = this.base.toNumber();
    const i:number = this.index.toNumber();

    if(b < 0){
      if(XMath.isNaturalOddNumber(i)){
        return -XMath.safeTimes(c, XMath.safePow(Math.abs(b), 1 / i));
      }
        // Would be an imaginary number
      return NaN;

    }

    return XMath.safeTimes(c, XMath.safePow(b, 1 / i));
  }

  /**
   * Returns null if cannot be reduced.
   * Try to reduce the base by using the
   * largest perfect square that can divide it.
   */
  public toReduced(realsImpl:RealsImpl):WRadical{
    const z:Point = this.factorizeBase;
    if(!z){
      return null;
    }
    if(z.x === 1){
      return null; // Can't be factorized.
    }

    return new WRadical(
      this.formatter.culture.createNumber(z.y),
      this.index,
      realsImpl.times(this.coefficient, this.formatter.culture.createNumber(z.x)),
      this.formatter);
  }

  /**
   *
   */
  public toModel():RadicalModel{
    return new RadicalModel(this.base, this.index, this.coefficient);
  }

  public get isSquareRoot():boolean{
    return this.index.toNumber() === 2;
  }

  public get isCubeRoot():boolean{
    return this.index.toNumber() === 3;
  }

  public get isFourthRoot():boolean{
    return this.index.toNumber() === 4;
  }

  public get isBasePerfectSquare():boolean{
    return XMath.isPerfectSquare(this.base.toNumber());
  }

  public get isBaseOne():boolean{
    return this.base.toNumber() === 1;
  }

  /**
   * Factorize the base by extracting as much prime factors
   * from the base as possible.
   *
   * Returns {x, y} where x is a value by which we need to
   * multiply the coefficient in order to have the same value
   * and y is the new base.
   *
   * Never returns null.
   *
   * If x is 1, it means that factorization is impossible.
   */
  public get factorizeBase():Point{
    const none:Point = new Point(1, NaN);

    if(!this.index.isNaturalNumber()){
      return none;
    }
    const index:number = this.index.toNumber();

    const base:number = this.base.toNumber();
    if(!XMath.isInteger(base)){
      return none;
    }
    if(base < 2){
      return none;
    }

    const factors:any[] = FactorInteger.powers(base); /* of [base, power] */

    const coefPowers:any[] =
      factors.map(
        Delegate.partial(this.factorizeBaseForCoef, index));

    const basePowers:any[] =
      factors.map(
        Delegate.partial(this.factorizeBaseForBasePower, index));

    return new Point(
      FactorInteger.integer(coefPowers),
      FactorInteger.integer(basePowers));
  }

  private factorizeBaseForCoef(index:number, o:any[], ..._:any[]):any[]{
    return [o[0], Math.floor(o[1] / index)];
  }

  private factorizeBaseForBasePower(index:number, o:any[], ..._:any[]):any[]{
    return [o[0], o[1] % index];
  }

  public equalsTo(value:ContentElement):boolean{
    if(value instanceof WRadical){
      return 	XMath.safeEquals(this.index.toNumber(), (<WRadical>value ).index.toNumber()) &&
          XMath.safeEquals(this.base.toNumber(), (<WRadical>value ).base.toNumber()) &&
          XMath.safeEquals(this.coefficient.toNumber(), (<WRadical>value ).coefficient.toNumber());
    }
    return false;
  }

  public writeTo(exporter:IMarkupExporter = null):boolean{
    if(exporter){
      this.formatter.writeTo(exporter, this.toModel());
    }
    return true;
  }

  public static similar(
      a:WRadical,
      b:WRadical):boolean{

    return 	a.index.toNumber() === b.index.toNumber() &&
        a.base.toNumber() === b.base.toNumber();
  }

  public getElementCode():string{
    return ElementCodes.TOKEN_RADICAL;
  }

  public static parseElement(element:ContentElement):WRadical{
    return element instanceof WRadical ? <WRadical>element  : null;
  }

  public getType():string{
    return 'radical';
  }

}
