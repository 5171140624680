import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WList } from '../../elements/tokens/WList';
import { WListOfPoints } from '../../elements/tokens/WListOfPoints';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { WPoint } from '../../elements/tokens/WPoint';
import { WRange } from '../../elements/tokens/WRange';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';
import { Evaluate } from '../../expr/manipulation/Evaluate';
import { MapImpl } from '../../funcs/collections/MapImpl';
import { ListElement } from '../../elements/abstract/ListElement';
import { ListOfListElement } from '../../elements/abstract/ListOfListElement';
import { WListOfList } from '../../elements/tokens/WListOfList';

/**
 * Retourne une liste qui contient le résultat de l'application
 * de la fonction de mappage sur chaque élément d'une liste.
 */
export class Map extends FunctionElement {
  /**
   * Result Capacity: if the resulting array would have
   * more than RESULT_CAPACITY items, the function is not
   * executed.
   */
  public static RESULT_CAPACITY: number = 100;

  private mapper: MapImpl;

  private evaluator: Evaluate;

  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 2 && args.length !== 3) {
      return args.expectingArguments(2, 3);
    }

    const mapFunction = args.getAnonymousFunction(args.length - 1);
    if (!mapFunction) {
      return null;
    }

    this.mapper = new MapImpl(mapFunction, args.env);
    this.evaluator = new Evaluate(mapFunction, args.env);

    if (args.length === 3) {
      const listOfLists: Array<ListOfListElement | WListOfList> = [args.getListOfList(0), args.getListOfList(1)];
      if (listOfLists[0] instanceof WListOfList && listOfLists[1] instanceof WListOfList) {
        return this.listOfList2(listOfLists[0], listOfLists[1], args.env);
      }

      const reals = [args.getReals(0), args.getReals(1)];
      if (reals[0] && reals[1]) {
        return this.list2(reals[0], reals[1], args.env);
      }

      const matrices = [args.getMatrix(0), args.getMatrix(1)];
      if (matrices[0] && matrices[1]) {
        return this.matrix2(matrices[0], matrices[1]);
      }
    }

    if (args.length === 2) {
      const listOfList = args.getListOfList(0);
      if (listOfList) {
        return this.listOfList1(listOfList, args.env);
      }

      const reals = args.getReals(0);
      if (reals) {
        return this.list1(reals, args.env);
      }

      const matrix = args.getMatrix(0);
      if (matrix) {
        return this.matrix1(matrix);
      }

      const points = args.getPoints(0);
      if (points) {
        return this.points1(points);
      }

      const range = args.getRange(0);
      if (range) {
        return this.range1(range, args.env);
      }
    }

    return null;
  }

  /**
   * Maps every element of the list using the function
   * and return a new list consisting of mapped values
   */
  private list1(a: ListElement, env: Environment): ListElement {
    return env.culture.listFactory.createList(this.mapper.map(a.items));
  }

  private list2(a: ListElement, b: ListElement, env: Environment): ListElement {
    return env.culture.listFactory.createList(this.mapper.map(a.items, b.items));
  }

  private listOfList1(a: ListOfListElement, env: Environment): ListOfListElement {
    const items: ContentElement[] = [];
    for (const item of a.items) {
      if (item instanceof ListOfListElement) {
        items.push(this.listOfList1(item, env));
      } else if (item instanceof ListElement) {
        items.push(this.list1(item, env));
      } else {
        items.push(this.evaluator.evaluate1(item));
      }
    }
    return env.culture.listFactory.createList(items) as ListOfListElement;
  }

  private listOfList2(a: ListOfListElement, b: ListOfListElement, env: Environment): ListOfListElement {
    const items: ContentElement[] = [];
    for (let i = 0; i < a.count; i++){
      const [itemA, itemB] = [a.getItemAt(i), b.getItemAt(i)];
      if (itemA instanceof ListOfListElement && itemB instanceof ListOfListElement) {
        items.push(this.listOfList2(itemA, itemB, env));
      } else if (itemA instanceof ListElement && itemB instanceof ListElement) {
        items.push(this.list2(itemA, itemB, env));
      } else {
        items.push(this.evaluator.evaluate2(itemA, itemB));
      }
    }
    return env.culture.listFactory.createList(items) as ListOfListElement;
  }

  private matrix1(a: WMatrix): WMatrix {
    const elements = this.mapper.map(a.values);
    return elements ? new WMatrix(elements, a.columns, a.formatter) : null;
  }

  private matrix2(a: WMatrix, b: WMatrix): WMatrix {
    if (a.columns !== b.columns || a.rows !== b.rows) {
      return null;
    }
    const elements = this.mapper.map(a.values, b.values);
    return elements ? new WMatrix(elements, a.columns, a.formatter) : null;
  }

  private points1(values: WListOfPoints): WListOfPoints {
    const points: ContentElement[] = [];
    for (let i = 0; i < values.count; i++) {
      const point = values.getTypedItemAt(i);
      const result = this.evaluator.evaluate1(point);
      if (result instanceof WPoint) {
        points.push(result);
      } else if (result && result.narrow() instanceof WPoint) {
        points.push(result.narrow());
      } else {
        return null;
      }
    }
    return new WListOfPoints(points, values.formatter);
  }

  /**
   * La liste finale ne peut contenir plus de 100 éléments.
   */
  private range1(values: WRange, env: Environment): WList {
    if (values.getCardinal() > Map.RESULT_CAPACITY) {
      return null;
    }
    const elements: RealElement[] = [];
    for (let i = 0; i < values.getCardinal(); i++) {
      elements.push(env.culture.createNumber(values.valueAt(i)));
    }
    return WList.createFromReals(this.mapper.map(elements), env.culture.listFormatter);
  }
}
