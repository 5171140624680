import React from 'react';
import styled from 'styled-components';
import { Editor, RangeRef } from '../../TypedSlate';
import { insertLink } from '../../utils/linkUtils';
import { PopoverIconButton } from '../../components/PopoverIconButton';
import LinkIcon from '../../../../../assets/link.svg';
import AddIcon from '../../../../../assets/add.svg';
import { Button } from '../../../Button';
import { PopupInput } from '../../components/Input';
import { Tooltip } from '../../../../utils/Tooltip/Tooltip';
import { ICustomEditor } from '../../models/editor';

export interface IAddLinkButtonProps {
  editor: ICustomEditor;
  label: string;
  inputPlaceholder: string;
}

interface IAddLinkButtonState {
  url: string;
  rangeRef: RangeRef;
  open: boolean;
}

export class AddLinkButton extends React.Component<IAddLinkButtonProps, IAddLinkButtonState> {
  constructor(props: IAddLinkButtonProps) {
    super(props);
    this.state = {
      url: '',
      rangeRef: null,
      open: false,
    };
  }

  public render(): React.ReactNode {
    const { label, inputPlaceholder } = this.props;
    const { url, open } = this.state;

    const button = (
      <Tooltip
        title={label}
      >
        <Button
          icon
        >
          <LinkIcon/>
        </Button>
      </Tooltip>
    );

    return (
      <PopoverIconButton
        open={open}
        onOpen={this.onOpen}
        onClose={this.onClose}
        ButtonContent={button}
        aria-label={label}
      >
        <SizedPopupInput
          value={url}
          placeholder={inputPlaceholder}
          onChange={this.onChangeHref}
        />
        <Button
          onClick={this.onCreateLink}
          icon
        >
          <AddIcon/>
        </Button>
      </PopoverIconButton>
    );
  }

  private onClose = () => {
    const { rangeRef } = this.state;
    rangeRef.unref();
    this.setState({
      url: '',
      open: false,
      rangeRef: null,
    });
  }

  private onOpen = () => {
    const { editor } = this.props;
    const rangeRef = Editor.rangeRef(editor, editor.selection);
    this.setState({
      rangeRef,
      url: '',
      open: true,
    });
  }

  private onCreateLink = () => {
    const { editor } = this.props;
    const { url, rangeRef } = this.state;
    insertLink(editor, url, rangeRef);
    this.onClose();
  }

  private onChangeHref = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      url: event.target.value,
    });
  }
}

const SizedPopupInput = styled(PopupInput)`
  width: 184px;
`;
