import { observable, action, runInAction } from 'mobx';
import { Store } from '../../dependencyInjection/Store';

@Store('ActivitySelectionsStore.ts/ActivitySelectionsStore')
export class ActivitySelectionsStore {

  @observable
  private selectedPageGuid: string;

  @observable
  private selectedPageId: number;

  constructor() {
    runInAction(() => {
      this.selectedPageGuid = null;
      this.selectedPageId = null;
    });
  }

  @action.bound
  public clear(): void {
    this.selectedPageGuid = null;
    this.selectedPageId = null;
  }

  @action.bound
  public setSelectedPageId(pageId: number) {
    this.selectedPageId = pageId;
  }

  @action.bound
  public setSelectedPageGuid(pageGuid: string) {
    this.selectedPageGuid = pageGuid;
  }

  public get currentPageRefGuid(): string {
    return this.selectedPageGuid;
  }

  public get currentPageId(): number {
    return this.selectedPageId;
  }
}
