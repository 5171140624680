import { XRound } from '../../../core/XRound';
import { XSort } from '../../../core/XSort';
import { StemLeafRowModel } from '../../../funcs/stemLeaf/model/StemLeafRowModel';

/**
 *
 */
export class StemLeafModel {

  public rows:StemLeafRowModel[] = [];

  public dual:boolean;

  /**
   * Returns a copy of this model with the stems but not the leaves.
   */
  public empty():StemLeafModel{
    const copy:StemLeafModel = this.clone();
    for(let i:number = 0 ; i < copy.rows.length ; i++){
      const row:StemLeafRowModel = copy.rows[i];
      row.leaves = [];
      if(row.leaves2){
        row.leaves2 = [];
      }
    }
    return copy;
  }

  /**
   * Returns an identical copy of this model.
   */
  public clone():StemLeafModel{
    const copy:StemLeafModel = new StemLeafModel();
    for(let i:number = 0 ; i < this.rows.length ; i++){
      const row:StemLeafRowModel = this.rows[i];
      copy.rows.push(row.clone());
    }
    return copy;
  }

  /**
   * Number of leaves in the model.
   */
  public get frequency():number{
    let c:number = 0;
    for(let i:number = 0 ; i < this.rows.length ; i++){
      const row:StemLeafRowModel = this.rows[i];
      c += row.leaves.length;
      if(row.leaves2){
        c += row.leaves2.length;
      }
    }
    return c;
  }

  /**
   *
   */
  public static parse(
      values1:number[],
      values2:number[],
      showEmptyStems:boolean):StemLeafModel{

    const data:StemLeafModel = new StemLeafModel();
    data.dual = values2 !== null;

    let i:number;
    let j:number;

    let n:number;
    const stems:any = {};
    const stems2:any = {};
    let stem:number;
    let leaf:number;

    let sExponent:number =
      StemLeafModel.stemExponent(values1);

    if(data.dual){
      sExponent = Math.min(sExponent, StemLeafModel.stemExponent(values2));
    }

    for(i = 0 ; i < values1.length ; i++){
      n = values1[i];
      n = XRound.halfAway(n, 1);
      stem = StemLeafModel.getStem(n, sExponent);
      leaf = StemLeafModel.getLeaf(n, sExponent);

      if(!stems[stem]){
        stems[stem] = [];
      }
      stems[stem].push(leaf);
    }

    if(data.dual){
      for(i = 0 ; i < values2.length ; i++){
        n = values2[i];
        n = XRound.halfAway(n, 1);
        stem = StemLeafModel.getStem(n, sExponent);
        leaf = StemLeafModel.getLeaf(n, sExponent);

        if(!stems2[stem]){
          stems2[stem] = [];
        }
        stems2[stem].push(leaf);
      }
    }

    let stemList:number[] = [];
    Object.keys(stems).forEach((o) => stemList.push(Number(o)));

    if(data.dual){
      Object.keys(stems2).forEach((o) => {
        if(stemList.indexOf(Number(o)) === -1){
          stemList.push(Number(o));
        }
      });
    }

    stemList = stemList.sort(XSort.numeric);
    if(showEmptyStems){
      const minStem:number = stemList[0];
      const maxStem:number = stemList[stemList.length - 1];
      const stepStem:number = 1;
      stemList = [];
      for(stem = minStem ; stem <= maxStem ; stem += stepStem){
        stemList.push(stem);
      }
    }

    for(i = 0 ; i < stemList.length ; i++){
      stem = stemList[i];
      const row:StemLeafRowModel = new StemLeafRowModel();
      row.stem = stem;
      row.leaves = [];
      if(data.dual){
        row.leaves2 = [];
      }
      data.rows.push(row);

      let leaves:any[] = stems[stem] !== undefined ? stems[stem] : [];
      let leaves2:any[] = stems2[stem] !== undefined ? stems2[stem] : [];

      leaves = leaves.sort(XSort.numeric);
      leaves2 = leaves2.sort(XSort.numeric);
      leaves2 = leaves2.reverse();

      if(data.dual){
        for(j = 0 ; j < leaves2.length ; j++){
          row.leaves2.push(leaves2[j]);
        }
      }
      for(j = 0 ; j < leaves.length ; j++){
        row.leaves.push(leaves[j]);
      }
    }

    return data;
  }

  /**
   *
   */
  private static stemExponent(values:number[]):number{
    for(let i:number = 0 ; i < values.length ; i++){
      const value:number = values[i];
      if(Math.round(value) !== value){
        return 0;
      }
    }
    return 1;
  }

  /**
   *
   */
  private static getStem(
      value:number,
      stemExponent:number):number{

    const negative:boolean = value < 0;
    return Math.floor(Math.abs(value) / (10 ** stemExponent)) * (negative ? -1 : 1);
  }

  /**
   *
   */
  private static getLeaf(
      value:number,
      stemExponent:number):number{

    return StemLeafModel.digitAt(value, stemExponent - 1);
  }

  /**
   *
   */
  private static digitAt(
      n:number,
      p:number):number{

    return Math.floor(XRound.safeRound(Math.abs(n) / (10 ** p))) % 10;
  }

}
