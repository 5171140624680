import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Coefficient binomial. Combinaison de k parmi n.
 */
export class Binomial extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 2){
      return args.expectingArguments(2, 2);
    }

    const k:RealElement = args.getWholeNumber(0);
    if(!k){
      return null;
    }

    const n:RealElement = args.getWholeNumber(1);
    if(!n){
      return null;
    }

    return args.env.culture.createNumber(this.compute(k.toNumber(), n.toNumber()));
  }

  /**
   *
   */
  private compute(k:number, n:number):number{
    let c:number = 1;
    let i:number;
    for (i = n - k + 1; i <= n; i++){
      c *= i;
    }
    for (i = 1; i <= k; i++){
      c /= i;
    }
    return c;
  }

}
