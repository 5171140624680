import { FrameBorders } from './FrameBorders';

/**
 *
 */
export class Compartment {

  private static createEnabled:boolean = false;

  constructor(){
    if(!Compartment.createEnabled){
      throw new Error('Use the constructor functions.');
    }
  }

  /**
   * Type of compartment.
   */
  public type:number;

  public static DIGIT:number = 1;

  public static DECIMAL_SEPARATOR:number = 2;

  public static CARRY:number = 3;

  public static REGROUP:number = 4;

  public static OPERATOR:number = 5;

  public static CHAR:number = 6;

  public static BLANK_SPACE:number = 7;

  public static CROSSOUT:number = 8;

  public static BORROW:number = 9;

  /**
   * Position in the grid.
   */
  public column:number = 0;

  public row:number = 0;

  /**
   * Defines a border around the compartment
   */
  public frame:FrameBorders;

  /**
   * Content
   */
  public value:number = 0;

  public text:string;

  /**
   *
   */
  public faded:boolean = false;

  public fade():Compartment{
    const copy:Compartment = this.clone();
    copy.faded = true;
    return copy;
  }

  public clone():Compartment{
    const c:Compartment = Compartment.create();
    c.type = this.type;
    c.text = this.text;
    c.value = this.value;
    c.faded = this.faded;
    c.row = this.row;
    c.column = this.column;
    c.frame = this.frame ? this.frame.clone() : null;
    return c;
  }

  /**
   * null|normal|source|target
   *
   * null: invisible
   * source: involved in a step
   * target: product of a step
   * normal: visible, without a particular style
   */
  public state:string; //

  public static SOURCE:string = 'source';

  public static TARGET:string = 'target';

  public static NORMAL:string = 'normal';

  private static create():Compartment{
    Compartment.createEnabled = true;
    const c:Compartment = new Compartment();
    Compartment.createEnabled = false;
    return c;
  }

  public static createDigit(
      value:number):Compartment{
    const c:Compartment = Compartment.create();
    c.type = Compartment.DIGIT;
    c.value = value;
    c.text = String(value);
    return c;
  }

  public static createInvisibleZero():Compartment{
    const c:Compartment = Compartment.create();
    c.type = Compartment.DIGIT;
    c.value = 0;
    c.text = '';
    return c;
  }

  public static createRegroup(
      value:number):Compartment{
    const c:Compartment = Compartment.create();
    c.type = Compartment.REGROUP;
    c.value = value;
    c.text = String(value);
    return c;
  }

  public static createBorrow(
      value:number):Compartment{
    const c:Compartment = Compartment.create();
    c.type = Compartment.BORROW;
    c.value = 10 + value;
    c.text = '1';
    return c;
  }

  public static createDecimalSeparator(decimalSeparator:string):Compartment{
    const c:Compartment = Compartment.create();
    c.type = Compartment.DECIMAL_SEPARATOR;
    c.text = decimalSeparator;
    return c;
  }

  public static createOperator(value:string):Compartment{
    const c:Compartment = Compartment.create();
    c.type = Compartment.OPERATOR;
    c.text = value;
    return c;
  }

  public static createCarry(value:number):Compartment{
    const c:Compartment = Compartment.create();
    c.type = Compartment.CARRY;
    c.value = value;
    c.text = String(value);
    return c;
  }

  public static createChar(char:string):Compartment{
    const c:Compartment = Compartment.create();
    c.type = Compartment.CHAR;
    c.text = String(char);
    return c;
  }

  public static createSpace():Compartment{
    const c:Compartment = Compartment.create();
    c.type = Compartment.BLANK_SPACE;
    c.text = '\u00A0';
    return c;
  }

  public static createCrossout():Compartment{
    const c:Compartment = Compartment.create();
    c.type = Compartment.CROSSOUT;
    c.text = '';
    return c;
  }

  /**
   *
   */
  public static stringifyRow(row:Compartment[]):string{
    const o:any[] = [];
    for(let i:number = 0 ; i < row.length ; i++){
      const c:Compartment = row[i];
      if(c){
        o.push(c.text);
      }
    }
    return o.join('');
  }

}
