/**
 *
 */
export class VertexData {

  /**
   *
   */
  public label:string;

  /**
   *
   */
  public clone():VertexData{
    const v:VertexData = new VertexData();
    v.label = this.label;
    return v;
  }

}
