import { MathError } from '../../core/MathError';
import { Primes } from '../../core/sets/Primes';
import { NumberMachine } from '../../funcs/numbers/NumberMachine';

/**
 * Return the highest composite number smaller than the specified number.
 */
export class CompositeBefore extends NumberMachine {

  constructor(){
    super((nArg: number): number => {
      let n = nArg;
      if(n <= 4){
        throw new MathError('No composite number before 4');
      }
      n = Math.ceil(n);
      do{
        n--;
      }while(Primes.getInstance().elementOf(n));
      return n;
    });
  }

}
