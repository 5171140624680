import { AbstractStep } from '../../AbstractStep';
import { Compartment } from '../../Compartment';
import { LongDivisionOperationEuclidienne } from '../../LongDivisionOperationEuclidienne';
import { DivisionModel } from '../../models/DivisionModel';
import { DivMultiply } from './DivMultiply';

/**
 *
 */
export class DivQuotient extends AbstractStep {

  private rest:number;

  private divisor:number;

  private division:LongDivisionOperationEuclidienne;

  private model:DivisionModel;

  private quotient:number;

  constructor(
      division:LongDivisionOperationEuclidienne,
      rest:number,
      divisor:number){
    super(division);
    this.rest = rest;
    this.divisor = divisor;
    this.division = division;
    this.model = division.model;

    this.quotient = divisor === 0 ? 0 : Math.floor(rest / divisor);
    const result:Compartment = Compartment.createDigit(this.quotient);
    division.result.push(result);
    this.target.push(result);

    this.setDescription(division.culture.getString('LongDivisionEuclidienne.quotient', rest, divisor));
  }

  public next():AbstractStep {
    return new DivMultiply(this.division, this.quotient, this.model.normalizedDivisor);
  }

}
