import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { TokenElement } from '../../elements/abstract/TokenElement';
import { AbstractFormatter } from '../../elements/formats/AbstractFormatter';
import { WFormat } from '../../elements/tokens/WFormat';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class ExtractFormat extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 1){
      return args.expectingArguments(1, 1);
    }
    if (args.getContentElement(0)) {
      return this.extract(args.getContentElement(0));
    }
    return null;
  }

  /**
   *
   */
  private extract(value:ContentElement):WFormat{
    if(value instanceof TokenElement){
      const formatter:AbstractFormatter = (<TokenElement>value ).getFormat();
      if(formatter != null){
        return new WFormat(formatter);
      }
    }
    return null;
  }

}
