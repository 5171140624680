import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WEulerConstant } from '../../elements/tokens/WEulerConstant';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { LogN } from '../../funcs/arithmetic/LogN';

/**
 * Natural logarithm.
 */
export class Ln extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 1){
      return args.expectingArguments(1, 1);
    }
    if(args.getReal(0)){
      return LogN.parse(
        args.getReal(0),
        new WEulerConstant(args.env.culture.createNumber(Math.E)),
        false,
        args.env);
    }
    return null;
  }

}
