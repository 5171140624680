import { XString } from '../../../core/XString';
import { Expression } from '../../../elements/abstract/Expression';
import { Node } from '../../../elements/abstract/Node';
import { WNull } from '../../../elements/tokens/WNull';
import { Environment } from '../../../expr/Environment';
import { IVariablesResolver } from '../../../expr/IVariablesResolver';
import { FunctionsRegistry } from '../../../funcs/FunctionsRegistry';
import { OperatorsRegistry } from '../../../funcs/OperatorsRegistry';
import { TokensImporter } from '../../../expr/conversion/input/TokensImporter';
import { RowWorker } from '../../../expr/conversion/input/RowWorker';
import { InvalidExpressionError } from '../../../expr/InvalidExpressionError';
import { SourceMap } from '../../../expr/conversion/input/SourceMap';
import { IExpressionDependencies } from '../../../expr/IExpressionDependencies';

/**
 * Interpret mathematical expression written in text into the internal model.
 */
export class StringImporter {

  /**
   *
   */
  constructor(
      value:string,
      variablesResolver:IVariablesResolver,
      env:Environment,
      withSourceMap:boolean,
      withValidation:boolean = true){

    if(XString.trim(value) === ''){
      this._expr = new Expression(new Node(WNull.getInstance()));
    }else{
      let node: Node = null;
      let sourceMap: SourceMap = null;
      let dependencies: IExpressionDependencies = null;

      try{
        const tokenizer:RowWorker =
          new RowWorker(
            value,
            variablesResolver,
            FunctionsRegistry.getInstance(),
            OperatorsRegistry.getInstance(),
            env,
            withSourceMap,
            withValidation);

        sourceMap = withSourceMap ? tokenizer.getSourceMap() : null;
        dependencies = tokenizer.getDependencies();

        if(tokenizer.getTokens().length > 0){
          node = TokensImporter.importTokens(tokenizer.getTokens(), env);
        }
        if (node) {
          this._expr = new Expression(node, sourceMap, dependencies);
        } else if (!withValidation) {
          this._expr = new Expression(null, sourceMap, dependencies);
        }
      }catch(e){
        if (withValidation) {
          throw new InvalidExpressionError(`Invalid expression "${value}"`, e);
        } else {
          this._expr = new Expression(null, sourceMap, dependencies);
        }
      }
    }
  }

  private readonly _expr:Expression;

  public get expr():Expression{
    return this._expr;
  }

}
