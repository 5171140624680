import React from 'react';
import styled from 'styled-components';
import { preventAndStopPropagation } from '../../../utils/mouse';
import { InputClearButton } from '../Input';
import { ReadonlyContext } from '../../../contexts/ReadonlyContext';
import { GeneratedValuePreview } from './GeneratedValuePreview';
import {
  BaseSyntaxHighlightInput,
  IBaseSyntaxHighlightInputProps,
} from './BaseSyntaxHighlightInput';

export interface ITopLabeledSyntaxHighlightInputProps extends IBaseSyntaxHighlightInputProps {
  readonly label: React.ReactNode;
  readonly preview?: React.ReactNode;
  readonly onClear?: () => void;
  readonly hasError?: boolean;
}

export const TopLabeledSyntaxHighlightInput: React.FC<ITopLabeledSyntaxHighlightInputProps> = (props) => {
  const {
    label,
    value,
    preview,
    onChange,
    onClear,
    className,
    disabled,
    hasError,
    nextHighlight,
    placeholder,
  } = props;

  const [hasFocus, setHasFocus] = React.useState(false);
  const isReadonly = React.useContext(ReadonlyContext);
  const isDisabled = disabled || isReadonly;

  const onFocus = (event: React.FocusEvent<HTMLElement>) => {
    setHasFocus(true);
  };
  const onBlur = (event: React.FocusEvent<HTMLElement>) => {
    setHasFocus(false);
  };

  const clearButton =
    !isDisabled && onClear
      ? (
        <InputClearButton
          onClear={onClear}
        />
      ) : null;

  return (
    <BorderContainer
      className={className}
      disabled={isDisabled}
      hasFocus={hasFocus}
      hasError={hasError}
      onClick={preventAndStopPropagation}
    >
      <Label
        hasError={hasError}
        minimizeLabel={hasFocus || value !== '' || (value === '' && Boolean(placeholder))}
      >
        {label}
      </Label>
      <Placeholder/>
      <StyledBaseSyntaxHighlightInput
        value={value}
        onChange={onChange}
        disabled={disabled}
        placeholder={placeholder}
        nextHighlight={nextHighlight}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      <Preview
        preview={preview}
      />
      {clearButton}
    </BorderContainer>
  );
};

interface IError {
  hasError: boolean;
}

interface IContainer extends IError {
  disabled: boolean;
  hasFocus: boolean;
}

interface ILabel extends IError {
  readonly minimizeLabel: boolean;
}

const StyledBaseSyntaxHighlightInput = styled(BaseSyntaxHighlightInput)`
  padding: 17px 6px 1px 8px;
`;

const Placeholder = styled.span`
  &:after {
    content: '\u200B';
  }
`;

const Preview = styled(GeneratedValuePreview)`
  max-width: 50%;
  max-height: 36px;
  padding-right: 4px;
  overflow: hidden;
  font-family: Roboto Mono, monospace;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  align-self: flex-end;
`;

const Label = styled.label<ILabel>`
  top: 0;
  left: 0;
  position: absolute;
  z-index: 1;
  pointer-events: none;
  transform: ${(props) => props.minimizeLabel ? 'translate(8px, 4px) scale(0.7)' : 'translate(8px, 11px) scale(1)'};
  transform-origin: top left;
  color: ${(props) => props.hasError ? props.theme.colorset.error2 : props.theme.colorset.grey07};
  font-weight: 600;
  transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
`;

const BorderContainer = styled.div<IContainer>`
  display: inline-flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  gap: 0;
  color: ${(props) => (props.disabled ? props.theme.input.default.disabled : props.theme.input.default).color};
  border: ${(props) =>
    (props.disabled ?
      props.theme.input.default.disabled :
      (props.hasError ? props.theme.input.default.error :
        (props.hasFocus ? props.theme.input.default.focus : props.theme.input.default))).border};
  background-color: ${(props) => (props.disabled ? props.theme.input.default.disabled : props.theme.input.default).backgroundColor};
  border-radius: 4px;
  outline: unset;
  min-width: 0px;

  ${(props) => props.disabled && `
    pointer-events: none;
  `}

  &:hover {
    border: ${(props) =>
    (props.disabled ? props.theme.input.default.disabled :
      (props.hasError ? props.theme.input.default.error :
        props.theme.input.default.focus)).border};
  }
`;
