import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WMarkup } from '../../elements/tokens/WMarkup';
import { WString } from '../../elements/tokens/WString';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class SpeakAs extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length < 1 || args.length > 2){
      return args.expectingArguments(1, 2);
    }

    if(args.length === 1){
      if (args.getString(0)) {
        return args.getString(0).toSpeakText() ?
          new WString(args.getString(0).toSpeakText()) :
          args.getString(0);
      }
      if (args.getMarkup(0)) {
        return args.getMarkup(0).toSpeakText() ?
          new WString(args.getMarkup(0).toSpeakText()) :
          args.getMarkup(0);
      }
    }else if(args.length === 2){
      if (args.getString(0) && args.getString(1)) {
        return this.str(args.getString(0), args.getString(1));
      }
      if (args.getMarkup(0) && args.getString(1)) {
        return this.markup(args.getMarkup(0), args.getString(1));
      }
    }

    return null;
  }

  /**
   *
   */
  private str(value:WString, speakAs:WString):WString{
    return new WString(value.getString(), speakAs.getString(), value.getSource());
  }

  /**
   *
   */
  private markup(value:WMarkup, speakAs:WString):WMarkup{
    return new WMarkup(value.factory, speakAs.getString());
  }

}
