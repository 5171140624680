import { Constants } from '../../core/Constants';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { XRound } from '../../core/XRound';

/**
 * Take an angle value expressed in radians and
 * convert it to an environment angle value.
 */
export class EnvAngle extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 1){
      return args.expectingArguments(1, 1);
    }
    if(args.getReal(0)){
      return args.env.useRadians ?
        args.getReal(0) :
        args.env.culture.createNumber(XRound.safeRound2(args.getReal(0).toNumber() * Constants.RAG_TO_DEG));
    }
    return null;
  }

}
