import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WGraph } from '../../elements/tokens/WGraph';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Number of vertices in a graph.
 */
export class Order extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 1){
      return args.expectingArguments(1, 1);
    }

    const graph:WGraph = args.getGraph(0);
    if(!graph){
      return null;
    }

    return args.env.culture.createNumber(graph.vertices.length);
  }

}
