import { Point } from '../../../js/geom/Point';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WPoint } from '../../elements/tokens/WPoint';
import { WPolygon } from '../../elements/tokens/WPolygon';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Constructeur de rectangle
 */
export class CreateRectangle extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length < 2 || args.length > 3){
      return args.expectingArguments(2, 3);
    }

    if(args.length === 2){
      if(args.getReal(0) && args.getReal(1)) {
        return this.widthHeight(args.getReal(0), args.getReal(1));
      }
      if(args.getPoint(0) && args.getPoint(1)) {
        return this.cornerTopLeftCornerTopRight(args.getPoint(0), args.getPoint(1));
      }
    }else if(args.length === 3){
      if(args.getPoint(0) && args.getReal(1) && args.getReal(2)) {
        return this.ordinalWidthHeight(args.getPoint(0), args.getReal(1), args.getReal(2));
      }
    }

    return null;
  }

  /**
   * width-height
   */
  private widthHeight(
      w:RealElement,
      h:RealElement):WPolygon{

    return CreateRectangle.parseCorners(
      new Point(0, 0),
      new Point(w.toNumber(), h.toNumber()));
  }

  /**
   *
   */
  private ordinalWidthHeight(
      o:WPoint,
      w:RealElement,
      h:RealElement):WPolygon{

    return CreateRectangle.parseCorners(
      o.toPoint(),
      o.toPoint().add(new Point(w.toNumber(), h.toNumber())));
  }

  /**
   *
   */
  private cornerTopLeftCornerTopRight(
      a:WPoint,
      b:WPoint):WPolygon{

    return CreateRectangle.parseCorners(a.toPoint(), b.toPoint());
  }

  /**
   *
   */
  public static parseCorners(
      a:Point,
      b:Point):WPolygon{

    if(a.equals(b)){
      return null;
    }

    const x1:number = Math.min(a.x, b.x);
    const y1:number = Math.min(a.y, b.y);
    const x2:number = Math.max(a.x, b.x);
    const y2:number = Math.max(a.y, b.y);

    const v:Point[] = [];
    v.push(new Point(x1, y1));
    v.push(new Point(x2, y1));
    v.push(new Point(x2, y2));
    v.push(new Point(x1, y2));
    return new WPolygon(v);
  }

}
