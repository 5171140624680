import { XMath } from '../../core/XMath';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WFactorial } from '../../elements/tokens/WFactorial';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';

/**
 * Return the product of all the positive integers lesser and equals to a number.
 */
export class Factorial extends FunctionElement {

  /**
   * Avoid long calculation that would cause Flash to hang.
   */
  private static SAFETY_THRESHOLD:number = 50;

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 1){
      return args.expectingArguments(1, 1);
    }
    if(args.getInteger(0)){
      return this.integer(args.getInteger(0), args.env);
    }
    return null;
  }

  /**
   *
   */
  public integer(a:RealElement, env:Environment):ContentElement{
    if(!a.isWholeNumber()){
      return null;
    }

    const n:number = a.toNumber();

    if(n > Factorial.SAFETY_THRESHOLD){
      return new WFactorial(n);
    }

    const f:number = XMath.factorial(n);
    return f.toString().toLowerCase().indexOf('e') === -1 ?
        env.culture.createNumber(f) :
        new WFactorial(n);
  }

}
