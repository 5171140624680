import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Wallet } from '../../elements/models/Wallet';
import { XStatistics } from '../../core/XStatistics';

/**
 * Returns an amount of money made of available denominations
 * that would cover more than the specified amount to pay.
 *
 * The amount should not be composed of any denomination that when
 * removed, would lead to an amount equal to the requested amount.
 * For example, if we want to pay 100, we should not give two 100$.
 * It should instead try to give 110 by combining 50, 20, 20, 20.
 */
export class PayAmount extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length < 1 || args.length > 3){
      return args.expectingArguments(1, 3);
    }

    const currency = args.env.culture.currency;

    const amountToCover = args.getNumber(0);
    if (amountToCover === null || amountToCover.toNumber() <= 0) {
      return null;
    }

    const denominations =
      (args.length > 1 && args.getReals(1))
        ? args.getReals(1).toNumbersV()
        : currency.denominations;

    const available =
      (args.length > 2 && args.getReals(2))
        ? args.getReals(2).toNumbersV()
        : null;

    const wallet = new Wallet(currency, denominations, available);
    const tendered = wallet.expandGreater(amountToCover.toNumber());

    if(!tendered){
      return null;
    }

    return args.env.culture.createNumber(XStatistics.sum(tendered));
  }

}
