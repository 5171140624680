import { allVariants } from './devServerFallbacks';
import { api } from '../utils/fetch';

export const getAllVariants = async (): Promise<ReadonlyArray<string>> => {
  const response = await api<ReadonlyArray<string>>('/Publisher/Variants/GetAllVariants');
  return response.json();
};

export const getLocalVariants = (): ReadonlyArray<string> => {
  return allVariants;
}
