import { HistoryEventEntityType, IHistoryEventEntity } from './history';

export interface IStandard {
  readonly id: number;
  displayId: string;
  referenceUrl: string; // url
  description: string; // html
  notes: string; // text
  indent: number; // 1-5
  subjectId: number;
}

export const standardEntity = (standard: IStandard): IHistoryEventEntity => {
  return {
    entityType: HistoryEventEntityType.StandardItem,
    entityId: standard.id,
    entityDescription: standard.displayId,
  };
};
