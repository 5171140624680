/**
 *
 */
export class CurrencyInfo {

  public static names = ['USD', 'CAD'];

  public static parse(name:string):CurrencyInfo{
    if(name.toUpperCase() === 'USD'){
      return CurrencyInfo.USD;
    }
    if(name.toUpperCase() === 'CAD'){
      return CurrencyInfo.CAD;
    }
    return null;
  }

  public static CAD:CurrencyInfo =
    new CurrencyInfo(
      'CAD',
      '$',
      '¢',
      [0.01, 	0.05, 0.1, 0.25, 1, 2, 5, 10, 20, 50, 100],
      [0.05, 0.1, 0.25, 1, 2, 5, 10, 20, 50, 100],
      1,
      0.01,
      5,
      2,
      '5-cents-rule');

  public static USD:CurrencyInfo =
    new CurrencyInfo(
      'USD',
      '$',
      '¢',
      [0.01, 0.05, 0.1, 0.25, 1, 2, 5, 10, 20, 50, 100],
      [0.01, 0.05, 0.1, 0.25, 1, 2, 5, 10, 20, 50, 100],
      1,
      0.01,
      1,
      2,
      'precision');

  public name:string;

  public unitSymbol: string;

  public fractionSymbol: string;

  public denominations:number[];

  public denominations2:number[];

  public unit:number;

  public fraction:number;

  public paperThreshold:number;

  public precision:number;

  public rounding: 'precision' | '5-cents-rule';

  constructor(
      name:string,
      unitSymbol: string,
      fractionSymbol: string,
      denominations:any[],
      denominations2:any[],
      unit:number,
      fraction:number,
      paperThreshold:number,
      precision:number,
      rounding: 'precision' | '5-cents-rule'){

    this.name = name;
    this.unitSymbol = unitSymbol;
    this.fractionSymbol = fractionSymbol;
    this.denominations = denominations;
    this.denominations2 = denominations2;
    this.unit = unit;
    this.fraction = fraction;
    this.paperThreshold = paperThreshold;
    this.precision = precision;
    this.rounding = rounding;
  }

  public get bills():any[]{
    return this.denominations.slice(this.denominations.indexOf(this.paperThreshold));
  }

  public get coins():any[]{
    return this.denominations.slice(0, this.denominations.indexOf(this.paperThreshold));
  }

  public equalsTo(otherCurrency:CurrencyInfo):boolean{
    return this.name === otherCurrency.name;
  }

}
