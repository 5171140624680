import { GenericStoreUndoManager } from '../../../frameworks/GenericStoreUndoManager';
import { getPageBoundStoreNameGetter } from '../../../dependencyInjection/utils';
import { Store } from '../../../dependencyInjection/Store';
import { instantiate } from '../../../dependencyInjection/instantiate';
import {
  ContextModelStore,
  getContextModelStoreName
} from '../ContextModelStore';
import { generateContextsCommand } from '../../../command/GenerateContextsCommand';

export const getContextModelStoreUndoManagerName =
  getPageBoundStoreNameGetter('ContextModelStoreUndoManager');

@Store(getContextModelStoreUndoManagerName)
export class ContextModelStoreUndoManager extends GenericStoreUndoManager {
  constructor() {
    const currentStoreName = getContextModelStoreName();
    const handleContextModelStoreUndoRedoOperation = () => {
      generateContextsCommand(instantiate(ContextModelStore).variablesEditor);
    }
    super(currentStoreName, null, null, handleContextModelStoreUndoRedoOperation);
    this.initialize(ContextModelStore);
  }
}
