// THIS IS AN AUTOGENERATED FILE.DO NOT EDIT THIS FILE DIRECTLY.
// Localization file

import { combineLocale } from '../utils/localisation';
import { ILocales } from '../models/ILocales';

import alignmentAndStandard_en_CA from '../../locale/en_CA/alignmentAndStandard.json';
import common_en_CA from '../../locale/en_CA/common.json';
import contentTags_en_CA from '../../locale/en_CA/contentTags.json';
import curriculums_en_CA from '../../locale/en_CA/curriculums.json';
import historyLogEventDescription_en_CA from '../../locale/en_CA/historyLogEventDescription.json';
import historyLogEventMessages_en_CA from '../../locale/en_CA/historyLogEventMessages.json';
import localeSelector_en_CA from '../../locale/en_CA/localeSelector.json';
import subject_en_CA from '../../locale/en_CA/subject.json';
import themeSelector_en_CA from '../../locale/en_CA/themeSelector.json';
import userSettings_en_CA from '../../locale/en_CA/userSettings.json';
import variants_en_CA from '../../locale/en_CA/variants.json';
export const en_CA = combineLocale({
  alignmentAndStandard_en_CA,
  common_en_CA,
  contentTags_en_CA,
  curriculums_en_CA,
  historyLogEventDescription_en_CA,
  historyLogEventMessages_en_CA,
  localeSelector_en_CA,
  subject_en_CA,
  themeSelector_en_CA,
  userSettings_en_CA,
  variants_en_CA,
});

import alignmentAndStandard_en_US from '../../locale/en_US/alignmentAndStandard.json';
import common_en_US from '../../locale/en_US/common.json';
import contentTags_en_US from '../../locale/en_US/contentTags.json';
import curriculums_en_US from '../../locale/en_US/curriculums.json';
import historyLogEventDescription_en_US from '../../locale/en_US/historyLogEventDescription.json';
import historyLogEventMessages_en_US from '../../locale/en_US/historyLogEventMessages.json';
import localeSelector_en_US from '../../locale/en_US/localeSelector.json';
import subject_en_US from '../../locale/en_US/subject.json';
import themeSelector_en_US from '../../locale/en_US/themeSelector.json';
import userSettings_en_US from '../../locale/en_US/userSettings.json';
import variants_en_US from '../../locale/en_US/variants.json';
export const en_US = combineLocale({
  alignmentAndStandard_en_US,
  common_en_US,
  contentTags_en_US,
  curriculums_en_US,
  historyLogEventDescription_en_US,
  historyLogEventMessages_en_US,
  localeSelector_en_US,
  subject_en_US,
  themeSelector_en_US,
  userSettings_en_US,
  variants_en_US,
});

import alignmentAndStandard_fr_CA from '../../locale/fr_CA/alignmentAndStandard.json';
import common_fr_CA from '../../locale/fr_CA/common.json';
import contentTags_fr_CA from '../../locale/fr_CA/contentTags.json';
import curriculums_fr_CA from '../../locale/fr_CA/curriculums.json';
import historyLogEventDescription_fr_CA from '../../locale/fr_CA/historyLogEventDescription.json';
import historyLogEventMessages_fr_CA from '../../locale/fr_CA/historyLogEventMessages.json';
import localeSelector_fr_CA from '../../locale/fr_CA/localeSelector.json';
import subject_fr_CA from '../../locale/fr_CA/subject.json';
import themeSelector_fr_CA from '../../locale/fr_CA/themeSelector.json';
import userSettings_fr_CA from '../../locale/fr_CA/userSettings.json';
import variants_fr_CA from '../../locale/fr_CA/variants.json';
export const fr_CA = combineLocale({
  alignmentAndStandard_fr_CA,
  common_fr_CA,
  contentTags_fr_CA,
  curriculums_fr_CA,
  historyLogEventDescription_fr_CA,
  historyLogEventMessages_fr_CA,
  localeSelector_fr_CA,
  subject_fr_CA,
  themeSelector_fr_CA,
  userSettings_fr_CA,
  variants_fr_CA,
});

export const locales: ILocales = {
  en_CA,
  en_US,
  fr_CA,
};
