import { NodeEntry, Text } from 'slate';
import { NON_BREAKING_CHARACTERS } from '../utils/textUtils';
import { IDecoratedRange } from '../models';

export const nonBreakingCharacterDecorator = (entry: NodeEntry): IDecoratedRange[] => {
  const [node, path] = entry;
  if (!Text.isText(node) || node.text === '') {
    return [];
  }
  return findAllNonBreakingCharacterIndexes(node.text).map(index => ({
    anchor: {
      path,
      offset: index,
    },
    focus: {
      path,
      offset: index + 1,
    },
    isNonBreakingCharacter: true,
  }));
};

const findAllNonBreakingCharacterIndexes = (text: string): number[] => {
  const indexes: number[] = [];
  for (let i = 0; i < text.length; i++) {
    if (NON_BREAKING_CHARACTERS.includes(text[i])) {
      indexes.push(i);
    }
  }
  return indexes;
};
