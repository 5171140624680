import { CultureInfo } from '../../../localization/CultureInfo';
import { IWriter } from '../../../expr/conversion/writers/IWriter';

export class LatexWriter implements IWriter {

  private culture:CultureInfo;

  private _content:string[] = [];

  private textMode: number = 0;

  /**
   *
   */
  constructor(culture:CultureInfo){
    this.culture = culture;
  }

  public get content():any{
    return this._content.join('');
  }

  public writeNumber(value:number):void{
    this._content.push(this.culture.formatNumber(value));
  }

  public writeSymbol(value:string):void{
    this._content.push(value);
  }

  public writeSuperscript(value:number):void{
    this._content.push(`^{${this.culture.formatNumber(value)}}`);
  }

  public writeSubscript(value:number):void{
    this._content.push(`_{${this.culture.formatNumber(value)}}`);
  }

  public writeSuperscriptSymbol(value:string):void{
    this._content.push(`^{${value}}`);
  }

  public writeSubscriptSymbol(value:string):void{
    this._content.push(`_{${value}}`);
  }

  public writeOperator(value:string, form:string):void{
    this._content.push(value);
  }

  public writeInfixOperator(value:string):void{
    this._content.push(value);
  }

  public writeRaw(valueArg:string):void{
    let value = valueArg;
    if(this.textMode === 0){
      // Reserved Latex characters
      value = value.replace(/[\&\%\$\#\_\{\}\~\^\\~]/g, '\\$&');
      // Spaces
      value = value.replace(/\s/g, '\\$&');
      this._content.push(value);
    }else{
      this._content.push(value);
    }
  }

  public startParagraph():void{
    // do nothing, this call was for legacy format.
  }

  public beginOverline():void{
    this._content.push('\\overline{');
  }

  public endOverline():void{
    this._content.push('}');
  }

  public beginFraction():void{
    this._content.push('\\frac{');
  }

  public beginDenominator():void{
    this._content.push('}{');
  }

  public endFraction():void{
    this._content.push('}');
  }

  public beginExponent():void{
    this._content.push('^{');
  }

  public endExponent():void{
    this._content.push('}');
  }

  public openParenthesis():void{
    this._content.push('\\left(');
  }

  public closeParenthesis():void{
    this._content.push('\\right)');
  }

  public openBrace():void{
    this._content.push('\\left\\{');
  }

  public closeBrace():void{
    this._content.push('\\right\\}');
  }

  public openBracket():void{
    this._content.push('\\left[');
  }

  public closeBracket():void{
    this._content.push('\\right]');
  }

  public beginText():void{
    this.textMode++;
    this._content.push('\\text{');
  }

  public endText():void{
    this.textMode--;
    this._content.push('}');
  }

  public beginSqrt():void{
    this._content.push('\\sqrt{');
  }

  public endSqrt():void{
    this._content.push('}');
  }

  public beginRoot():void{
    this._content.push('\\sqrt[');
  }

  public endIndex():void{
    this._content.push(']{');
  }

  public endRoot():void{
    this._content.push('}');
  }

}
