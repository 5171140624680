import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { ListElement } from '../../elements/abstract/ListElement';
import { WList } from '../../elements/tokens/WList';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';

/**
 * Convertit une liste de nombres en une autre liste dont la somme des éléments est 1.
 * La proportion est conservée.
 */
export class ProbabilityVector extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 1){
      return args.expectingArguments(1, 1);
    }
    if(args.getReals(0)){
      return this.normalize(args.getReals(0), args.env);
    }
    return null;
  }

  /**
   *
   */
  private normalize(value:WList, env:Environment):ListElement{
    if(value.count === 0){
      return null;
    }
    const temp:ContentElement[] = [];
    let sum:number = 0;

    let i:number;
    let n:number;

    for(i = 0 ; i < value.count ; i++){
      n = value.getValueAt(i);
      if(n < 0){
        return null;
      }
      sum += n;
    }

    if(sum === 0){
      return null;
    }

    for(i = 0 ; i < value.count ; i++){
      n = value.getValueAt(i);
      temp.push(env.culture.createRational(n, sum));
    }

    return env.culture.listFactory.createList(temp);
  }

}
