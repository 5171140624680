import { CSSProperties } from 'react';
import { merge } from 'lodash';
import { ColorSet } from '../../../themes/ColorSet';

export interface ICheckboxTheme {
  default: {
    backgroundColor: CSSProperties['backgroundColor'];
    border?: CSSProperties['border'];
    color: CSSProperties['color'];
    opacity?: null;
    check: {
      color: CSSProperties['color'];
      backgroundColor: CSSProperties['backgroundColor'];
      border?: CSSProperties['border'];
    };
  };
  disabled: {
    backgroundColor: CSSProperties['backgroundColor'];
    opacity: CSSProperties['opacity'];
    border?: CSSProperties['border'];
    color: CSSProperties['color'];
    check: {
      color: CSSProperties['color'];
      backgroundColor: CSSProperties['backgroundColor'];
      border?: CSSProperties['border'];
    };
  };
}

export const darkCheckboxTheme: ICheckboxTheme = {
  default: {
    backgroundColor: ColorSet.penumbra,
    color: ColorSet.white,
    check: {
      color: ColorSet.white,
      backgroundColor: ColorSet.primary500,
    },
  },
  disabled: {
    backgroundColor: ColorSet.penumbra,
    opacity: 0.4,
    color: ColorSet.white,
    check: {
      color: ColorSet.white,
      backgroundColor: ColorSet.grey500,
    },
  },
};

export const darkCheckboxTheme2: ICheckboxTheme = merge({}, darkCheckboxTheme, {
  default: {
    backgroundColor: ColorSet.grey900,
  },
  disabled: {
    backgroundColor: ColorSet.grey900,
  },
});

export const lightCheckboxTheme: ICheckboxTheme = {
  default: {
    backgroundColor: ColorSet.white,
    border: `1px solid ${ColorSet.grey100}`,
    color: ColorSet.penumbra,
    check: {
      color: ColorSet.white,
      backgroundColor: ColorSet.primary500,
      border: `1px solid ${ColorSet.primary500}`,
    },
  },
  disabled: {
    backgroundColor: ColorSet.white,
    border: `1px solid ${ColorSet.grey100}`,
    opacity: 0.5,
    color: ColorSet.penumbra,
    check: {
      color: ColorSet.white,
      backgroundColor: ColorSet.grey400,
    },
  },
};
