import { IDictionary } from '../../../../js/utils/IDictionary';

import { XMath } from '../../../core/XMath';
import { RealElement } from '../../../elements/abstract/RealElement';
import { Zeros } from '../../../elements/functions/Zeros';
import { AbstractNode } from '../../../elements/functions/tree/AbstractNode';
import { EmptyLeaf } from '../../../elements/functions/tree/EmptyLeaf';
import { NodeArithmetic } from '../../../elements/functions/tree/NodeArithmetic';
import { NodeCoefficient } from '../../../elements/functions/tree/NodeCoefficient';
import { NodeConstant } from '../../../elements/functions/tree/NodeConstant';
import { NodeGroup } from '../../../elements/functions/tree/NodeGroup';
import { NodeVariable } from '../../../elements/functions/tree/NodeVariable';
import { IntervalClosure } from '../../../elements/models/IntervalClosure';
import { WInterval } from '../../../elements/tokens/WInterval';
import { WRational } from '../../../elements/tokens/WRational';
import { RealsImpl } from '../../../elements/utils/RealsImpl';
import { CultureInfo } from '../../../localization/CultureInfo';
import { IFunctionForm } from '../../../elements/functions/models/IFunctionForm';
import { AbstractFunctionForm } from '../../../elements/functions/models/AbstractFunctionForm';

/**
 * 8. fonction partie entière (forme canonique)  f(x) = A [B(x - H)] + K
 */
export class TFloor extends AbstractFunctionForm {

  constructor(
      culture:CultureInfo,
      A:RealElement,
      B:RealElement,
      H:RealElement,
      K:RealElement){

    super(culture, A, B, null, null, null, null, H, K);
  }

  public getRawTokens(parameters:IDictionary, varName:string):any[]{
    const o:any[] = [varName];
    if(parameters.hasOwnProperty('H')){
      o.unshift('(');
      o.push('−', this.getH(), ')');
    }
    if(parameters.hasOwnProperty('B')){
      o.unshift(this.getB());
    }
    o.unshift('\u230A');
    o.push('\u230B');
    if(parameters.hasOwnProperty('A')){
      o.unshift(this.getA());
    }
    if(parameters.hasOwnProperty('K')){
      o.push('+', this.getK());
    }
    return o;
  }

  public getSimplifyTokens(parameters:IDictionary, varName:string):any[] {
    const root:AbstractNode = ( new NodeArithmetic( NodeArithmetic.ADDITION ) )
      .setLeft(
        new NodeCoefficient( new NodeConstant( this.getA() ),
          new NodeGroup(
            new NodeCoefficient( new NodeConstant( this.getB() ),
              new NodeGroup( ( new NodeArithmetic( NodeArithmetic.SUBSTRACTION ) )
                .setLeft( new NodeVariable( varName ) )
                .setRight( new NodeConstant( this.getH() ) )
                , '(', ')' )
            )
            , '\u230A', '\u230B', true
          )
        )
      )
      .setRight(new NodeConstant(this.getK()));

    let simplified:AbstractNode =  root.simplify();

    if( simplified instanceof EmptyLeaf ) {
      simplified = new NodeConstant( this.getK() );
    }

    return simplified.getToken();
  }

  public map(value:number):number{
    return this.nA * Math.floor(this.nB * (value - this.nH)) + this.nK;
  }

  public getZeros():Zeros{
    const z:Zeros = new Zeros();
    if(XMath.isInteger(-this.nK / this.nA)){
      const n1:number = -this.nK / (this.nA * this.nB) + this.nH;
      const n2:number = (-this.nK + this.nA)/(this.nA * this.nB) + this.nH;
      if((this.nA > 0 && this.nB > 0) || (this.nA < 0 && this.nB > 0)){
        z.interval = this.culture.intervalsFactory.createIntervalN(IntervalClosure.CLOSED_OPEN, n1, n2);
      }else if((this.nA > 0  && this.nB < 0) || (this.nA < 0 && this.nB < 0)){
        z.interval = this.culture.intervalsFactory.createIntervalN(IntervalClosure.OPEN_CLOSED, n2, n1);
      }else{
        return null;
      }
    }else{
      z.list = [];
    }
    return z;
  }

  public copy(parameters:IDictionary):IFunctionForm{
    return new TFloor(
      this.culture,
      parameters.hasOwnProperty('A') ? parameters.A : this.A,
      parameters.hasOwnProperty('B') ? parameters.B : this.B,
      parameters.hasOwnProperty('H') ? parameters.H : this.H,
      parameters.hasOwnProperty('K') ? parameters.K : this.K);
  }

  public get constantPiece():WInterval{
    let iB:RealElement; // Math.abs(1 / B)
    if(this.H instanceof WRational){
      const r:WRational = <WRational>this.H ;
      iB = new WRational(Math.abs(r.denominator), Math.abs(r.numerator), r.formatter);
    }else{
      iB = this.culture.createNumber(Math.abs(1 / this.nB));
    }

    if(iB === null){
      return null;
    }

    const reals:RealsImpl = new RealsImpl(this.culture, true);

    return new WInterval(
        this.nB > 0 ?
          IntervalClosure.CLOSED_OPEN :
          IntervalClosure.OPEN_CLOSED,
        this.H,
        reals.add(this.H, iB),
        this.culture.formats.intervalFormatImpl);
  }

}
