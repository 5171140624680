import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WList } from '../../elements/tokens/WList';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Search a matrix for elements. Keep all element in this list
 * and change others values to 0.
 */
export class Mask extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 2){
      return args.expectingArguments(2, 2);
    }

    const matrix:WMatrix = args.getMatrix(0);
    if(!matrix){
      return null;
    }

    const preserve:WList = args.getReals(1);
    if(!preserve){
      return null;
    }

    return this.applyMask(matrix, preserve.toNumbersV(), args.env.culture.createNumber(0));
  }

  /**
   *
   */
  private applyMask(m:WMatrix, preserve:number[], replacement:RealElement):WMatrix {
    const result:RealElement[] = [];
    for (let i:number = 0 ; i < m.count ; i++ ) {
      const item:RealElement = m.values[i];
      if( preserve.indexOf( item.toNumber() ) !== -1 ) {
        result.push(item);
      } else {
        result.push(replacement);
      }
    }
    return new WMatrix(result, m.columns, m.formatter);
  }

}
