import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';
import { CultureInfo } from '../../localization/CultureInfo';
import { AbstractFormatter } from '../../elements/formats/AbstractFormatter';

/**
 *
 */
export class BaseRatioFormatter extends AbstractFormatter {

  private _separator:string;

  constructor(
      culture:CultureInfo,
      separator:string){
    super(culture);
    this._separator = separator;
  }

  public getSeparator():string{
    return this._separator ? this._separator : ':';
  }

  public useSpaces():boolean{
    return this.culture.configuration.spacedRatioFormat;
  }

  public toLocaleString(r:number, s:number):string{
    throw new Error();
  }

  public toSpeakText(r:number, s:number):string{
    throw new Error();
  }

  public writeTo(exporter:IMarkupExporter, r:number, s:number):void{
    throw new Error();
  }

}
