import { Primes } from '../../core/sets/Primes';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WBoolean } from '../../elements/tokens/WBoolean';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Est premier?
 */
export class IsPrime extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 1){
      return args.expectingArguments(1, 1);
    }
    if(args.getReal(0)){
      return WBoolean.parse(Primes.getInstance().elementOf(args.getReal(0).toNumber()));
    }
    return null;
  }

}
