export enum EnvironmentType {
  production,
  staging,
  development,
  test,
}

/**
 * Gets the active environment for the application based
 * on the active URL. This is mostly useful when differentiating
 * between production and QA.
 */
export const getEnvironment = (): EnvironmentType => {
  switch (ScolabConfiguration.BUILD_ENVIRONMENT) {
    case 'development': return EnvironmentType.development;
    case 'test': return EnvironmentType.test;
  }

  const hostname = window.location.hostname;

  if (/((.*\.?)qa\.scolab\.io)/.test(hostname)) {
    return EnvironmentType.staging;
  }

  if (/((.*\.?)0.0.0.0|(.*\.?)localhost|(^127\.)|(^10\.)|(^172\.1[6-9]\.)|(^172\.2[0-9]\.)|(^172\.3[0-1]\.)|(^192\.168\.))/.test(hostname)) {
    if (window.location.port === '3701') {
      return EnvironmentType.development;
    }
  }

  return EnvironmentType.production;
};
