export const Routes = {
  Login: [
    '/login',
  ],
  LoginCallback: [
    '/login/callback',
  ],
};

export const linkTo = (routes: string[]) => {
  return routes[0].replace('\/*', '');
};
