import { BaseNumberFormatter } from '../formats/BaseNumberFormatter';
import { IMarkupExporter } from '../markers/IMarkupExporter';
import { WNumber } from '../tokens/WNumber';
import { WPolynomial } from '../tokens/WPolynomial';
import { ElementCodes } from '../abstract/ElementCodes';
import { RealElement } from '../abstract/RealElement';
import { ContentElement } from '../abstract/ContentElement';
import { TokenElement } from '../abstract/TokenElement';

/**
 *
 */
export class SymbolElement extends TokenElement {

  /**
   *
   */
  private _previousSymbol:string;

  private _symbol:string;

  public getSymbol():string{return this._symbol;}

  public setSymbol(value:string):void{
    this._previousSymbol = (this._previousSymbol)?this._previousSymbol+'->'+this._symbol:this._symbol+'';
    this._symbol = value;
  }

  private _formatter:BaseNumberFormatter;

  /**
   *
   */
  constructor(symbol:string, formatter:BaseNumberFormatter){
    super();
    this._symbol = symbol;
    this._formatter = formatter;
  }

  /**
   *
   */
  public equalsTo(value:ContentElement):boolean{
    if(value instanceof SymbolElement){
      return (<SymbolElement>value ).getSymbol() === this.getSymbol();
    }
    return false;
  }

  /**
   *
   */
  public writeTo(exporter:IMarkupExporter = null):boolean{
    if(exporter){
      exporter.writeIdentifier(this.getSymbol());
    }
    return true;
  }

  /**
   *
   */
  public toText(strict:boolean):string{
    if(!strict){
      return this.getSymbol();
    }
    return null;
  }

  /**
   *
   */
  public isConstant():boolean{
    return false;
  }

  /**
   *
   */
  public widen():ContentElement{
    const symbols:SymbolElement[] = [];
    const coefs:RealElement[] = [];
    const powers:number[] = [];
    symbols.push(this);
    coefs.push(new WNumber(1, 1, false, this._formatter));
    powers.push(1);
    return new WPolynomial(symbols, coefs, powers, this._formatter);
  }

  /**
   * Constants must appear before variables,
   * otherwise use alphabetical sort.
   */
  public static compare(
      a:SymbolElement,
      b:SymbolElement):number{

    if(a.isConstant() && !b.isConstant()){
      return -1;
    }
    if(!a.isConstant() && b.isConstant()){
      return 1;
    }

    if(a.getSymbol() < b.getSymbol()){
      return -1;
    }
    if(a.getSymbol() > b.getSymbol()){
      return 1;
    }

    return 0;
  }

  /**
   *
   */
  public toString():string {
    return this._symbol;
  }

  /**
   *
   */
  public getElementCode():string{
    return ElementCodes.TOKEN_SYMBOL;
  }

  /**
   *
   */
  public getType():string {
    return 'symbolElement';
  }

  /**
   * Merge two list of symbols and return a new list with representing the union of both lists.
   */
  public static merge(
      a:SymbolElement[],
      b:SymbolElement[]):SymbolElement[]{

    let c:SymbolElement[] = a.concat(b);
    c = c.sort(SymbolElement.compare);

    let i:number = 0;
    while(i < c.length - 1){
      if(c[i].equalsTo(c[i+1])){
        c.splice(i + 1, 1);
      }else{
        i++;
      }
    }

    return c;
  }

  /**
   * Returns a map of where "target" symbols are found inside "source".
   *
   * If a symbol in target is not found in source, then use -1.
   *
   * For example:
   * if source contains "a" and "b" and target contains "b", "x" and "a" then returns [1, -1, 0]
   */
  public static mapping(
      source:SymbolElement[],
      target:SymbolElement[]):number[]{

    const m:number[] = [];

    for(let t:number = 0 ; t < target.length ; t++){
      let found:boolean = false;
      for(let s:number = 0 ; s < source.length ; s++){
        if(source[s].equalsTo(target[t])){
          m.push(s);
          found = true;
          break;
        }
      }
      if(!found){
        m.push(-1);
      }
    }

    return m;
  }

}
