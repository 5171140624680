import { ContentElement } from '../../../elements/abstract/ContentElement';
import { ArgumentsObject } from '../../../expr/ArgumentsObject';
import { BaseTransform } from '../../../funcs/geom/transforms/BaseTransform';
import { OrthogonalProjectionImpl } from '../../../elements/models/transforms/OrthogonalProjectionImpl';

/**
 *
 */
export class OrthogonalProjection extends BaseTransform {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 2){
      return args.expectingArguments(2, 2);
    }
    if(args.getLine(1)){
      return super.transform(args, new OrthogonalProjectionImpl(args.getLine(1)));
    }
    return null;
  }

}
