import { ArrayCollection } from '../../js/collections/ArrayCollection';

import { IOperation } from '../../js/undo/IOperation';

import { CultureInfo } from '../localization/CultureInfo';
import { Compartment } from './Compartment';
import { AbstractStep } from './AbstractStep';

/**
 *
 */
export class ElementaryOperation {

  /**
   *
   */
  private _culture:CultureInfo;

  public get culture():CultureInfo{
    return this._culture;
  }

  /**
   *
   */
  constructor(culture:CultureInfo){
    this._culture = culture;
  }

  /**
   * Horizontal lines going from left to right.
   */
  public lines:ArrayCollection;

  /**
   *
   */
  public compartments:ArrayCollection;

  /**
   *
   */
  public operations:ArrayCollection = new ArrayCollection();

  /**
   * Indicates if this operation has an error.
   */
  public error:boolean;

  protected get lastOperation():AbstractStep{
    if(this.operations.length === 0){
      return null;
    }
    return <AbstractStep>this.operations.getItemAt(this.operations.length - 1) ;
  }

  protected init():void{
    let step:AbstractStep;
    do{
      step = this.next();
      if(step){
        this.operations.addItem(step);
      }
    }while(step);
    this.finalize();
    this.objects();
    this.validate();
  }

  /**
   *
   */
  public undoAll():void{
    for(let i:number = 0 ; i < this.operations.length ; i++){
      const op:IOperation = <IOperation>this.operations.getItemAt(i) ;
      op.performUndo();
    }
  }

  /**
   *
   */
  public redoAt(index:number):void{
    for(let i:number = 0 ; i <= index ; i++){
      if(i < this.operations.length){
        (<IOperation>this.operations.getItemAt(i) ).performRedo();
      }
    }
  }

  /**
   *
   */
  public highlightAt(index:number):void{
    const step:AbstractStep = this.getStep(index);
    if(step){
      step.highlightSource();
      step.highlightTarget();
    }
  }

  /**
   *
   */
  public getStep(index:number):AbstractStep{
    if(index < this.operations.length && index !== -1){
      return <AbstractStep>this.operations.getItemAt(index) ;
    }
    return null;
  }

  /**
   * Returns the next step.
   */
  protected next():AbstractStep{
    return null;
  }

  /**
   * Base class must inherit this function and do the layout of
   * compartments and lines by setting row and column information.
   */
  protected finalize():void{
    return undefined;
  }

  /**
   * Makes the inventory of all compartments and
   * lines created for this operation.
   */
  private objects():void{
    this.compartments = new ArrayCollection();
    this.lines = new ArrayCollection();

    for(let i:number = 0 ; i < this.operations.length ; i++){
      const operation:AbstractStep = <AbstractStep>this.operations.getItemAt(i) ;
      operation.listCompartments(this.compartments);
      operation.listLines(this.lines);
    }
  }

  /**
   * Base class must inherit this function and
   * validate that the last line correspond to the
   * actual result of the operation.
   * MUST set the value of "error" attribute.
   */
  protected validate():void{
    return undefined;
  }

  /**
   * Returns true if that row has a least one valid compartment (non null).
   */
  protected hasCompartments(
      row:Compartment[]):boolean{
    if(!row){
      return false;
    }
    return row.some(
      this.compartmentNotNull);
  }

  private compartmentNotNull(c:Compartment, ..._:any[]):boolean{
    return c != null;
  }

  /**
   * Layout a row horizontally starting from offset location.
   * Returns 1 if that row is not empty. Otherwise returns 0.
   */
  protected layoutRow(
      row:Compartment[],
      rowIndex:number,
      colIndex:number):number{

    for(let i:number = 0 ; i < row.length ; i++){
      const c:Compartment = row[i];
      if(c){
        c.column = colIndex + i;
        c.row = rowIndex;
      }
    }

    return this.hasCompartments(row) ? 1 : 0;
  }

  /**
   *
   */
  public decimalSeparator():Compartment{
    return Compartment.createDecimalSeparator(this.culture.numberFormatter.decimalSeparator);
  }

  /**
   * Returns the numerical value of a row.
   */
  public numberRowStr(row:Compartment[]):string{
    let s:string = Compartment.stringifyRow(row);
    s = s.split(',').join('.');
    s = s.split('−').join('-');
    return s;
  }

}
