import { MmlWriter } from '../../../core/mml/MmlWriter';
import { BaseRationalFormatter } from '../../../elements/formats/BaseRationalFormatter';
import { IMarkupExporter } from '../../../elements/markers/IMarkupExporter';
import { FractionModel } from '../../../elements/models/FractionModel';
import { CultureInfo } from '../../../localization/CultureInfo';

/**
 *
 */
export class RationalPercentFormatter extends BaseRationalFormatter {

  /**
   *
   */
  constructor(culture:CultureInfo){
    super(culture);
  }

  /**
   *
   */
  public toLocaleString(numerator:number, denominator:number):string{
    const f:FractionModel = this.percentFraction(numerator, denominator);

    if(f.numerator === 0){
      return `${this.culture.formatNumber(f.signedInteger)}%`;
    }
    return this.culture.formatNumber(f.signedInteger) +
        '\u00A0' +
        String(f.numerator) +
        '/' +
        String(f.denominator) +
        '%';

  }

  /**
   *
   */
  public writeTo(exporter:IMarkupExporter, numerator:number, denominator:number):boolean{
    const f:FractionModel = this.percentFraction(numerator, denominator);

    const writer:MmlWriter = exporter.writer;

    if(f.numerator === 0){
      writer.appendNumber(`${this.culture.formatNumber(f.signedInteger)}%`);
    }else{
      writer.beginRow();
      writer.appendNumber(this.culture.formatNumber(f.signedInteger));
      writer.beginFraction();
      writer.appendNumber(String(f.numerator));
      writer.appendNumber(String(f.denominator));
      writer.endFraction();
      writer.appendOperator('%');
      writer.endRow();
    }

    return true;
  }

  /**
   *
   */
  private percentFraction(numerator:number, denominator:number):FractionModel{
    const v:number = numerator / denominator;
    let n:number = Math.abs(numerator);
    const d:number = Math.abs(denominator);

    n *= 100;

    const e:number = Math.floor(n / d);

    n %= d;

    return new FractionModel(v < 0, e, n, d);
  }

  /**
   *
   */
  public equals(other:BaseRationalFormatter):boolean{
    return other instanceof RationalPercentFormatter;
  }

}
