import React from 'react';
import styled from 'styled-components';
import { COLOR_CIRCLE_SIZE, ColorCircle } from './ColorCircle';
import { IPresetColorGroup } from './models';
import { ColorSet } from '../../../themes/ColorSet';

interface IColorPresetsProps {
  colorPresets: ReadonlyArray<IPresetColorGroup>;
  onClickPreset: (value: string) => void;
  availableWidth: number;
}

export const ColorPresets: React.FC<IColorPresetsProps> = React.memo((props) => {
  const {
    colorPresets,
    onClickPreset,
    availableWidth,
  } = props;
  const presetGroups = colorPresets.map((presetGroup, presetGroupIndex) => {
    const height = (presetGroup.values.length * COLOR_CIRCLE_SIZE) + ((presetGroup.values.length - 1) * GAP_SIZE);
    const longestColorGroup = presetGroup.values.reduce((acc, colorGroup) => Math.max(colorGroup.length, acc), 0);
    const width = (longestColorGroup * COLOR_CIRCLE_SIZE) + ((longestColorGroup - 1) * GAP_SIZE);
    const canFitHorizontally = availableWidth >= height;
    const isHorizontal = canFitHorizontally && height > width;
    const colorGroups = presetGroup.values.map((colorGroup, colorGroupIndex) => {
      const colors = colorGroup.map((color) => {
        return (
          <ColorCircle
            onClick={onClickPreset}
            color={color.value}
            key={color.value ?? 'transparent'}
          />
        );
      });
      return (
        <ColorGroup
          key={colorGroupIndex}
          isHorizontal={isHorizontal}
        >
          {colors}
        </ColorGroup>
      );
    });
    return (
      <React.Fragment
        key={presetGroupIndex}
      >
        <GroupNameLabel>
          {presetGroup.Label}
        </GroupNameLabel>
        <ColorGroupsContainer
          isHorizontal={isHorizontal}
        >
          {colorGroups}
        </ColorGroupsContainer>
      </React.Fragment>
    );
  });
  return (
    <Container>
      {presetGroups}
    </Container>
  );
});

const GAP_SIZE = 4;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${GAP_SIZE}px;
`;

interface IRotatable {
  isHorizontal: boolean;
}

export const ColorGroup = styled.div<IRotatable>`
  display: flex;
  flex-direction: ${(props) => props.isHorizontal ? 'column' : 'row'};
  flex-wrap: wrap;
  gap: ${GAP_SIZE}px;
`;

const ColorGroupsContainer = styled.div<IRotatable>`
  display: flex;
  flex-direction: ${(props) => props.isHorizontal ? 'row' : 'column'};
  flex-wrap: wrap;
  gap: ${GAP_SIZE}px;
`;

const GroupNameLabel = styled.div`
  text-transform: uppercase;
  color: ${ColorSet.grey500};
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  margin-top: 4px;
`;
