import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { ColorSet, ContextMenuItem, EllipsisButton, TextArea, themedColor } from '@scolab/publisher-ui-kit';
import { FormattedMessage } from 'react-intl';
import { IVariant } from '../../../models/IVariant';

interface IVariantListItemProps {
  variant: IVariant;
  onChangeDescription: (variantData: IVariant, newDescription: string) => void;
  onToggleObsolete: (variantData: IVariant) => void;
}

interface IIVariantListItemState {
  isEllipsisOpen: boolean;
}

@observer
export class VariantListItem extends React.Component<IVariantListItemProps, IIVariantListItemState> {

  constructor(props: IVariantListItemProps) {
    super(props);
    this.state = {
      isEllipsisOpen: false,
    };
  }

  public render(): React.ReactNode {
    const {
      variant,
    } = this.props;
    const {
      isEllipsisOpen,
    } = this.state;

    return (
      <Container>
        <NameLabel
          disabled={variant.metadata.isObsolete}
        >
          {variant.name}
          {variant.metadata.isObsolete && (
            <FormattedMessage id="variants.variantManagerModal.obsolete" />
          )}
        </NameLabel>
        <StyledTextArea
          value={variant.metadata.description}
          onChange={this.localOnChangeDescription}
          disabled={variant.metadata.isObsolete}
        />
        <StyledEllipsisButton
          isOpen={isEllipsisOpen}
          onSetIsOpen={this.setIsEllipsisOpen}
          horizontalAlignment="left"
        >
          <ContextMenuItem
            label={<FormattedMessage
              id={`variants.variantManagerModal.${variant.metadata.isObsolete ? 'unmarkObsolete' : 'markObsolete'}`}
            />}
            onClick={this.localOnToggleObsolete}
          />
        </StyledEllipsisButton>
      </Container>
    );
  }

  private localOnChangeDescription = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const {
      variant,
      onChangeDescription,
    } = this.props;
    onChangeDescription(variant, event.target.value);
  }

  private localOnToggleObsolete = (event: React.MouseEvent) => {
    const {
      variant,
      onToggleObsolete,
    } = this.props;
    onToggleObsolete(variant);
  }

  private setIsEllipsisOpen = (isOpen: boolean) => {
    this.setState({
      isEllipsisOpen: isOpen,
    });
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  margin: 4px 0;
`;

interface INameLabel {
  disabled: boolean;
}

const NameLabel = styled.div<INameLabel>`
  width: 208px;
  border: 1px solid ${themedColor({ dark: ColorSet.grey800, light: ColorSet.grey100 })};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 6px 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  align-self: stretch;
  overflow: hidden;
  text-overflow: ellipsis;
  ${(props) => props.disabled && `color: ${props.theme.input.default.disabled.color}`}
`;

const StyledTextArea = styled(TextArea)`
  flex: 1;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`;

const StyledEllipsisButton = styled(EllipsisButton)`
  svg {
    height: 24px;
    width: 24px;
  }
`;
