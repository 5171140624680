import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WList } from '../../elements/tokens/WList';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { WPoint } from '../../elements/tokens/WPoint';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';

/**
 * Constructeur de liste de points.
 */
export class Points extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length > 2){
      return args.expectingArguments(0, 2);
    }

    let items:ContentElement[];

    if(args.length === 0){
      items = [];
    }else if(args.length === 1){
      if(args.getPoints(0)){
        return args.getPoints(0);
      }
      if(args.getMatrix(0)){
        items = this.matrix(args.getMatrix(0), args.env);
      }else if(args.getSegment(0)){
        const segment = args.getSegment(0);
        items = [
          args.env.culture.parsePoint(segment.a),
          args.env.culture.parsePoint(segment.b)
        ];
      }else if(args.getPolygon(0)){
        const polygon = args.getPolygon(0);
        items = polygon.vertices.map((v) => args.env.culture.parsePoint(v));
      }else if(args.getPolyline(0)){
        const polyline = args.getPolyline(0);
        items = polyline.vertices.map((v) => args.env.culture.parsePoint(v));
      }else if(args.getBoundVector(0)){
        const boundVector = args.getBoundVector(0);
        items = [
          args.env.culture.parsePoint(boundVector.a),
          args.env.culture.parsePoint(boundVector.b)
        ];
      }
    }else if(args.length === 2){
      if(args.getReal(0) && args.getReal(1)){
        items = [];
        items.push(new WPoint(args.getReal(0), args.getReal(1), args.env.culture.formats.pointFormatImpl));
      }else if(args.getReals(0) && args.getReals(1)){
        items = this.xsys(args.getReals(0), args.getReals(1), args.env);
      }
    }

    if(!items){
      return null;
    }

    return args.env.culture.listFactory.createList(items, 'point');
  }

  /**
   *
   */
  private matrix(value:WMatrix, env:Environment):ContentElement[]{
    const o:ContentElement[] = [];

    let p:WPoint;

    if(value.columns === 2){
      for(let r:number = 0 ; r < value.rows ; r++){
        p = new WPoint(
          value.valueAt(r, 0),
          value.valueAt(r, 1),
          env.culture.formats.pointFormatImpl);
        o.push(p);
      }
    }else if(value.rows === 2){
      for(let c:number = 0 ; c < value.columns ; c++){
        p = new WPoint(
          value.valueAt(0, c),
          value.valueAt(1, c),
          env.culture.formats.pointFormatImpl);
        o.push(p);
      }
    }

    if(o.length === 0){
      return null;
    }

    return o;
  }

  /**
   *
   */
  private xsys(x:WList, y:WList, env:Environment):ContentElement[]{
    if(x.count === 0 || y.count === 0){
      return null;
    }
    if(x.count !== y.count){
      return null;
    }

    const o:ContentElement[] = [];

    for(let i:number = 0 ; i < x.count ; i++){
      o.push(new WPoint(x.getTypedItemAt(i), y.getTypedItemAt(i), env.culture.formats.pointFormatImpl));
    }

    return o;
  }

}
