/* eslint-disable no-restricted-imports */
import { getInjector } from 'mmlpx/esm/core/dependency-inject/instantiate';
import { modelTypeSymbol, modelNameSymbol, storeSymbol } from 'mmlpx/esm/core/dependency-inject/meta';
import { Scope } from 'mmlpx/esm/core/dependency-inject/Injector';
/* eslint-enable no-restricted-imports */

export class Injector {
  public static get<T>(InjectedClass: { new(): T }, name?: string): T {
    const injector = getInjector();
    return injector.get(InjectedClass, {
      name: name || InjectedClass[modelNameSymbol],
      scope: InjectedClass[modelTypeSymbol] === storeSymbol ?
        'singleton' as Scope.Singleton:
        'prototype' as Scope.Prototype,
    });
  }

  public static has(name: string): boolean {
    const injector = getInjector();
    const storeContainer = injector._getContainer();
    return Boolean(storeContainer.get(name));
  }
}
