import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WFunction } from '../../elements/tokens/WFunction';
import { WString } from '../../elements/tokens/WString';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { WQuadratic } from '../../elements/tokens/WQuadratic';
import { WLine } from '../../elements/tokens/WLine';

/**
 *
 */
export class SetVarName extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 2){
      return args.expectingArguments(2, 2);
    }

    const varName = args.getString(1);
    if(!varName){
      return null;
    }

    const f = args.getFunction(0);
    if(f){
      return this.name(f, varName);
    }

    const l = args.getLine(0);
    if(l){
      return new WLine(l.A, l.B, l.C, l.formatter, l.precision, l.p0, l.p1, l.yLabel, varName.getString());
    }

    const q = args.getQuadratic(0);
    if(q){
      return new WQuadratic(q.A, q.B, q.C, q.formatter, q.p0, q.p1, q.p2, q.yLabel, varName.getString());
    }

    return null;
  }

  /**
   *
   */
  private name(value:WFunction, varName:WString):WFunction{
    return new WFunction(value.form, value.part, value.style, varName.getString());
  }

}
