import { XMath } from '../../core/XMath';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { TokenElement } from '../../elements/abstract/TokenElement';
import { AbstractFormatter } from '../../elements/formats/AbstractFormatter';
import { BaseMatrixFormatter } from '../../elements/formats/BaseMatrixFormatter';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';

/**
 * row-wise matrix.
 */
export class WMatrix extends TokenElement {

  private _values:RealElement[];

  public get values():RealElement[]{return this._values;}

  private _columns:number;

  public get columns():number{return this._columns;}

  public get rows():number{return this.columns === 0 ? 0 : this.values.length / this.columns;}

  public valueAt(
      row:number,
      column:number):RealElement{
    return this.values[row * this.columns + column];
  }

  private _formatter:BaseMatrixFormatter;

  public get formatter():BaseMatrixFormatter{return this._formatter;}

  constructor(
      values:RealElement[],
      columns:number,
      formatter:BaseMatrixFormatter){
    super();
    if(!formatter){
      throw new Error('Formatter required');
    }
    this._values = values;
    this._columns = columns;
    this._formatter = formatter;
  }

  public get count():number{
    return this.rows * this.columns;
  }

  /**
   * Returns the item index inside values vector, or -1 if the
   * specified row or col is outside the bounds of this matrix.
   */
  public getItemIndex(row:number, col:number):number{
    return 	row >= 0 &&
        col >= 0 &&
        row < this.rows &&
        col < this.columns ?
          row * this.columns + col :
          -1;
  }

  public getRow(index:number):RealElement[] {
    const start:number = XMath.safeTimes(index, this.columns);
    const end:number = start + this.columns;
    return this.values.slice(start, end);
  }

  public getCol(index:number):RealElement[] {
    const items:RealElement[] = [];
    for( let i:number = 0; i < this.rows; i++ ) {
      items.push( this.valueAt( i, index ) );
    }
    return items;
  }

  public applyFormat(formatter:AbstractFormatter):ContentElement{
    if(formatter instanceof BaseMatrixFormatter){
      return new WMatrix(
        this.values,
        this.columns,
        <BaseMatrixFormatter>formatter );
    }
    return this;
  }

  public getFormat():AbstractFormatter{
    return this._formatter;
  }

  public trim(
      spaceLike:RealElement):WMatrix{

    const o:RealElement[] = [];

    if(this.columns === 0 || this.rows === 0){
      return this;
    }

    if(this.columns === 1 && this.rows === 1){
      if(this.valueAt(0, 0).toNumber() !== spaceLike.toNumber()){
        return new WMatrix(o, 0, this.formatter);
      }
      return this;

    }

    let left:number = this.columns - 1;
    let right:number = 0;
    let top:number = this.rows - 1;
    let bottom:number = 0;

    let r:number;
    let c:number;

    for(r = 0 ; r < this.rows ; r++){
      for(c = 0 ; c < this.columns ; c++){
        if(this.valueAt(r, c).toNumber() !== spaceLike.toNumber()){
          left = Math.min(left, c);
          right = Math.max(right, c);
          top = Math.min(top, r);
          bottom = Math.max(bottom, r);
        }
      }
    }

    for(r = top ; r <= bottom ; r++){
      for(c = left ; c <= right ; c++){
        o.push(this.valueAt(r, c));
      }
    }

    if(o.length === 0){
      return new WMatrix(o, 0, this.formatter);
    }

    return new WMatrix(o, (right - left) + 1, this.formatter);
  }

  public equalsTo(value:ContentElement):boolean{
    if(!(value instanceof WMatrix)){
      return false;
    }

    const matrix:WMatrix = <WMatrix>value ;

    if(matrix.rows !== this.rows ||
        matrix.columns !== this.columns){
      return false;
    }

    for(let r:number = 0 ; r < this.rows ; r++){
      for(let c:number = 0 ; c < this.columns ; c++){
        if(this.valueAt(r, c).toNumber() !== matrix.valueAt(r, c).toNumber()){
          return false;
        }
      }
    }

    return true;
  }

  public map(callback:Function):WMatrix{
    const o:RealElement[] = [];
    for(let i:number = 0 ; i < this.values.length ; i++){
      o.push(callback(this.values[i]));
    }
    return new WMatrix(o, this.columns, this.formatter);
  }

  public writeTo(exporter:IMarkupExporter = null):boolean{
    if(exporter){
      this.formatter.writeTo(exporter, this.values, this.columns);
    }
    return true;
  }

  /**
   *
   */
  public getType():string{
    return 'matrix';
  }

}
