import { ShowShortProbs } from '../../funcs/ptree/ShowShortProbs';

/**
 *
 */
export class ShowLongProbs extends ShowShortProbs {

  /**
   *
   */
  constructor(){
    super('long');
  }

}
