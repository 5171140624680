import React from 'react';
import styled from 'styled-components';
import { themedColor } from '../../../themes/utils';
import { NestedContainer } from '../../surfaces/NestedContainer';
import { POPUP_ZINDEX } from '../../../themes/zIndex';
import { rafThrottle } from '../../../utils/rafThrottle';

export type TipAlignment =
  'bottom-center' |
  'bottom-right' |
  'bottom-left' |
  'top-center' |
  'top-right' |
  'top-left' |
  'right-center' |
  'left-center';

export interface IBasePopupProps {
  readonly anchorEl: Element | null;
  readonly tipAlignment: TipAlignment;
  readonly horizontalOffset?: number;
  readonly verticaLOffset?: number;
  readonly className?: string;
  readonly onMouseDown?: (event: React.MouseEvent) => void;
}

export const BasePopup: React.FC<IBasePopupProps> = ({ children, ...props }) => {
  const {
    tipAlignment,
    anchorEl,
    verticaLOffset = 0,
    horizontalOffset = 0,
    className,
    onMouseDown,
  } = props;

  const [popupPosition, setPopupPosition] =
    React.useState(() => getPopupPosition(anchorEl, tipAlignment, verticaLOffset, horizontalOffset));
  const popupRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    if (open) {
      const scrollListener = rafThrottle((event: Event) => {
        if ((event.target as Element)?.contains(anchorEl)) {
          setPopupPosition(getPopupPosition(anchorEl, tipAlignment, verticaLOffset, horizontalOffset));
        }
      });
      window.addEventListener('scroll', scrollListener, true);
      return () => {
        scrollListener.cancel();
        window.removeEventListener('scroll', scrollListener, true)
      };
    }
  }, [open, anchorEl, tipAlignment, verticaLOffset, horizontalOffset]);

  const Component = getContentWrapperWithTip(tipAlignment);

  return (
    <Component
      ref={popupRef}
      left={popupPosition.left}
      top={popupPosition.top}
      transform={popupPosition.transform}
      onMouseDown={onMouseDown}
      className={className}
    >
      <NestedContainer>
        {children}
      </NestedContainer>
    </Component>
  );
};

const getPopupPosition = (anchorEl: Element,
                          tipAlignment: TipAlignment,
                          verticaLOffset: number,
                          horizontalOffset: number) => {
  const anchorRect = anchorEl.getBoundingClientRect();
  let left = 0;
  let top = 0;
  let transform = '';
  switch (tipAlignment) {
    case 'top-center': {
      left = anchorRect.left + anchorRect.width / 2;
      top = anchorRect.bottom + TIP_HEIGHT;
      transform = 'translateX(-50%)';
      break;
    }
    case 'top-left': {
      left = anchorRect.left;
      top = anchorRect.bottom + TIP_HEIGHT;
      break;
    }
    case 'top-right': {
      left = anchorRect.right;
      top = anchorRect.bottom + TIP_HEIGHT;
      transform = 'translateX(-100%)';
      break;
    }
    case 'bottom-center': {
      left = anchorRect.left + anchorRect.width / 2;
      top = anchorRect.top - TIP_HEIGHT;
      transform = 'translateX(-50%) translateY(-100%)';
      break;
    }
    case 'bottom-right': {
      left = anchorRect.right;
      top = anchorRect.top - TIP_HEIGHT;
      transform = 'translateX(-100%) translateY(-100%)';
      break;
    }
    case 'right-center': {
      left = anchorRect.left - TIP_HEIGHT;
      top = anchorRect.top + anchorRect.height / 2;
      transform = 'translateX(-100%) translateY(-50%)';
      break;
    }
    case 'bottom-left': {
      left = anchorRect.left;
      top = anchorRect.top - TIP_HEIGHT;
      transform = 'translateY(-100%)';
      break;
    }
    case 'left-center': {
      left = anchorRect.right + TIP_HEIGHT;
      top = anchorRect.top + anchorRect.height / 2;
      transform = 'translateY(-50%)';
      break;
    }
  }

  left += horizontalOffset;
  top += verticaLOffset;

  return {
    left,
    top,
    transform,
  };
};

export const DARK_POPOVER_TIP = 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAyNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICA8cGF0aCBkPSJNMCAwTDI0IDQuMTk2MjllLTA2TDE1LjIgMTEuNzMzM0MxMy42IDEzLjg2NjcgMTAuNCAxMy44NjY3IDguOCAxMS43MzMzTDAgMFoiIGZpbGw9IiMxMTEyMTMiLz4KPC9zdmc+Cg==';
export const LIGHT_POPOVER_TIP = 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAyNCAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzI5NDlfNDY3ODEpIj4KPHBhdGggZD0iTTAgMEwyNCA0LjE5NjI5ZS0wNkwxNS4yIDExLjczMzNDMTMuNiAxMy44NjY3IDEwLjQgMTMuODY2NyA4LjggMTEuNzMzM0wwIDBaIiBmaWxsPSIjQ0VENURGIi8+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMjIuNzUwMSAzLjk3Nzc1ZS0wNkwxNC40IDExLjEzMzRDMTMuMiAxMi43MzM0IDEwLjggMTIuNzMzNCA5LjYgMTEuMTMzNEwxLjI0OTk1IDIuMTg1NDhlLTA3TDIyLjc1MDEgMy45Nzc3NWUtMDZaIiBmaWxsPSJ3aGl0ZSIvPgo8L2c+CjxkZWZzPgo8Y2xpcFBhdGggaWQ9ImNsaXAwXzI5NDlfNDY3ODEiPgo8cmVjdCB3aWR0aD0iMjQiIGhlaWdodD0iMTYiIGZpbGw9IndoaXRlIi8+CjwvY2xpcFBhdGg+CjwvZGVmcz4KPC9zdmc+Cg==';
const TIP_HEIGHT = 14;

interface IBaseContentWrapperWithTipProps {
  left: number;
  top: number;
  transform: string;
}

export const BaseContentWrapperWithTip = styled.div<IBaseContentWrapperWithTipProps>`
  background: ${(props) => props.theme.popover.background};
  border: ${(props) => props.theme.popover.border};
  border-radius: 4px;
  color: ${(props) => props.theme.popover.color};
  left: ${props => props.left}px;
  margin-bottom: ${TIP_HEIGHT}px;
  overflow: visible;
  position: fixed;
  top: ${props => props.top}px;
  transform: ${props => props.transform};
  z-index: ${POPUP_ZINDEX};

  &::after {
    background-image: url("${themedColor({ dark: DARK_POPOVER_TIP, light: LIGHT_POPOVER_TIP })}");
    content: "";
    display: inline-block;
    height: ${TIP_HEIGHT}px;
    position: absolute;
    width: 24px;
  }
`;

const BottomCenter = styled(BaseContentWrapperWithTip)`
  &::after {
    bottom: -${TIP_HEIGHT}px;
    left: calc(50% - 12px);
  }
`;

const BottomRight = styled(BaseContentWrapperWithTip)`
  &::after {
    bottom: -${TIP_HEIGHT}px;
    right: 16px;
  }
`;

const BottomLeft = styled(BaseContentWrapperWithTip)`
  &::after {
    bottom: -${TIP_HEIGHT}px;
    left: 16px;
  }
`;

const TopCenter = styled(BaseContentWrapperWithTip)`
  &::after {
    left: calc(50% - 12px);
    top: -${TIP_HEIGHT}px;
    transform: rotate(-180deg);
  }
`;

const TopLeft = styled(BaseContentWrapperWithTip)`
  &::after {
    left: 16px;
    top: -${TIP_HEIGHT}px;
    transform: rotate(-180deg);
  }
`;

const TopRight = styled(BaseContentWrapperWithTip)`
  &::after {
    right: 16px;
    top: -${TIP_HEIGHT}px;
    transform: rotate(-180deg);
  }
`;

const RightCenter = styled(BaseContentWrapperWithTip)`
  &::after {
    right: -19px;
    top: calc(50% - 8px);
    transform: rotate(-90deg);
  }
`;

const LeftCenter = styled(BaseContentWrapperWithTip)`
  &::after {
    left: -19px;
    top: calc(50% - 8px);
    transform: rotate(90deg);
  }
`;

const getContentWrapperWithTip = (tipAlignment: TipAlignment) => {
  switch (tipAlignment) {
    case 'bottom-center': return BottomCenter;
    case 'bottom-right': return BottomRight;
    case 'bottom-left': return BottomLeft;
    case 'right-center': return RightCenter;
    case 'left-center': return LeftCenter;
    case 'top-center': return TopCenter;
    case 'top-left': return TopLeft;
    case 'top-right': return TopRight;
  }
};
