import React from 'react';
import styled from 'styled-components';
import { InputClearButton } from './Input';
import { ReadonlyContext } from '../../contexts/ReadonlyContext';

export interface ITopLabeledInput extends React.InputHTMLAttributes<HTMLInputElement> {
  readonly label: React.ReactNode;
  readonly className?: string;
  readonly hasError?: boolean;
  readonly useLocalValue?: boolean; // Slate's change event is asynchronous, which makes the cursor jump to the end of the input when typing on a physical keyboard
  readonly onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  readonly onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  readonly onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  readonly onEnter?: () => void;
  readonly onClear?: () => void;
}

const TopLabeledInputComponent: React.ForwardRefRenderFunction<HTMLInputElement, ITopLabeledInput> = (props, ref) => {
  const {
    label,
    value,
    className,
    hasError,
    disabled,
    onBlur,
    onFocus,
    onChange,
    onEnter,
    onClear,
    maxLength,
    type = 'text',
    inputMode= 'text',
    pattern= null,
    children,
    useLocalValue,
    ...otherProps
  } = props;
  const [, forceUpdate] = React.useReducer(o => !o, true);
  const localValue = React.useRef<string>(null);
  const [hasFocus, setHasFocus] = React.useState(false);
  const isReadonly = React.useContext(ReadonlyContext);
  const isDisabled = disabled || isReadonly;

  const localOnKeyUp =
    React.useCallback(
      (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' && onEnter) {
          onEnter();
        }
      },
      [onEnter]);
  const localOnFocus =
    React.useCallback(
      (event: React.FocusEvent<HTMLInputElement>) => {
        setHasFocus(true);
        if (onFocus) {
          onFocus(event);
        }
      },
      [onFocus]);
  const localOnBlur =
    React.useCallback(
      (event: React.FocusEvent<HTMLInputElement>) => {
        setHasFocus(false);
        if (onBlur) {
          onBlur(event);
        }
      },
      [onBlur]);
  const localOnChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (useLocalValue) {
        localValue.current = event.target.value;
        forceUpdate();
      }
      if (onChange) {
        onChange(event);
      }
    },
    [onChange, useLocalValue]);

  let usedValue = value;
  if (useLocalValue && localValue.current !== null) {
    usedValue = localValue.current;
    localValue.current = null;
  }

  const hasContent = value !== null && value !== undefined && value !== '';

  const clearButton =
    onClear && hasContent && !isDisabled
      ? (
        <InputClearButton
          onClear={onClear}
        />
      ) : null;

  return (
    <Container
      className={className}
      hasError={hasError}
      disabled={isDisabled}
      hasFocus={hasFocus}
    >
      <Label
        hasError={hasError}
        minimizeLabel={hasFocus || hasContent}
      >
        {label}
      </Label>
      <Input
        ref={ref}
        type={type}
        inputMode={inputMode}
        pattern={pattern}
        maxLength={maxLength}
        value={usedValue ?? ''}
        onChange={localOnChange}
        onKeyUp={localOnKeyUp}
        onBlur={localOnBlur}
        onFocus={localOnFocus}
        disabled={isDisabled}
        {...otherProps}
      />
      {children}
      {clearButton}
    </Container>
  );
};

export const TopLabeledInput = React.forwardRef(TopLabeledInputComponent);

interface IError {
  hasError: boolean;
}

interface IContainer extends IError {
  disabled: boolean;
  hasFocus: boolean;
}

interface ILabel extends IError {
  readonly minimizeLabel: boolean;
}

const Container = styled.div<IContainer>`
  display: inline-flex;
  box-sizing: border-box;
  position: relative;
  flex-direction: row;
  align-items: center;
  transition: border-color 100ms linear;
  border-radius: 4px;

  color: ${(props) => props.theme.input.default.color};
  border: ${(props) => props.theme.input.default.border};
  background-color: ${(props) => props.theme.input.default.backgroundColor};

  ${(props) => props.hasFocus && `
    border: ${props.theme.input.default.focus.border};
  `}

  ${(props) => props.disabled && `
    background-color: ${props.theme.input.default.disabled.backgroundColor};
    border: ${props.theme.input.default.disabled.border};
    color: ${props.theme.input.default.disabled.color};
    pointer-events: none;
  `}

  ${(props) => props.hasError && `
    border: ${props.theme.input.default.error.border};
  `}

  &:hover {
    border: ${(props) => (props.hasError ? props.theme.input.default.error : props.theme.input.default.focus).border};
  }
`;

const Label = styled.label<ILabel>`
  top: 0;
  left: 0;
  position: absolute;
  z-index: 1;
  pointer-events: none;
  transform: ${(props) => props.minimizeLabel ? 'translate(8px, 4px) scale(0.7)' : 'translate(8px, 11px) scale(1)'};
  transform-origin: top left;
  color: ${(props) => props.hasError ? props.theme.colorset.error2 : props.theme.colorset.grey400};
  font-size: 14px;
  font-weight: 600;
  font-family: ${(props) => props.theme.fontFamily};
  transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
`;

const Input = styled.input`
  padding: 17px 6px 1px 8px;
  border: unset;
  outline: unset;
  background-color: transparent;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: ${(props) => props.theme.fontFamily};
  color: ${(props) =>
    (props.disabled ?
      props.theme.input.default.disabled :
      props.theme.input.default).color};
  width: 100%;
  box-sizing: border-box;
`;
