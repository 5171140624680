import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Retourne le dernier élément d'une liste.
 */
export class Last extends FunctionElement {
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length > 1) {
      return args.getContentElement(args.length - 1);
    }

    const point = args.getPoint(0);
    if (point) {
      return point.y;
    }

    const real = args.getReal(0);
    if (real) {
      return real;
    }

    const list = args.getNonEmptyList(0);
    if (list) {
      return list.getItemAt(list.count - 1);
    }

    return null;
  }
}
