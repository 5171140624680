import { MathError } from '../../core/MathError';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WList } from '../../elements/tokens/WList';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { WTable } from '../../elements/tokens/WTable';

/**
 * Colonne d'une matrice.
 */
export class Column extends FunctionElement {

  /**
   * Retourne l'index-ième colonne d'une matrice.
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 2){
      return args.expectingArguments(2, 2);
    }

    const matrix:WMatrix = args.getMatrix(0);
    const table:WTable = args.getTable(0);
    if(!matrix && !table){
      return null;
    }

    const colIndex:RealElement = args.getWholeNumber(1);
    if(!colIndex){
      return null;
    }

    if(colIndex.toNumber() > (matrix?.columns ?? table?.columns.length)){
      throw new MathError('Column index out of range');
    }

    return matrix ?
      WList.createFromReals(
        matrix.getCol(colIndex.toNumber()),
        args.env.culture.listFormatter) :
      table.columns[colIndex.toNumber()];
  }

}
