import { computed } from 'mobx';
import { historyLogEvent } from '../command/HistoryLogEventCommand';
import { HistoryEventEntityType, HistoryEventTypes } from '../models/history';
import { ActivityPagesDependentsStore } from '../models/store/ActivityPagesDependentsStore';
import { inject } from '../dependencyInjection/inject';
import { ViewModel } from '../dependencyInjection/ViewModel';
import { AlignmentQuality, IStandardAlignment, standardAlignmentEntities } from '../models/IStandardAlignment';
import { ActivitySelectionsStore } from '../models/store/ActivitySelectionsStore';
import { StringUtils } from '../utils/StringUtils';
import { RemoveActivityAlignmentCommand } from '../command/RemoveActivityAlignmentCommand';
import { AddActivityPagesAlignmentsCommand } from '../command/AddActivityPagesAlignmentsCommand';
import { ChangeActivityAlignmentQualityCommand } from '../command/ChangeActivityAlignmentQualityCommand';
import { RemoveActivityPagesAlignmentsCommand } from '../command/RemoveActivityPagesAlignmentsCommand';
import { RemovePageAlignmentCommand } from '../command/RemovePageAlignmentCommand';
import { ChangePageAlignmentQualityCommand } from '../command/ChangePageAlignmentQualityCommand';
import { updateActivityPagesDependentsCommand } from '../frameworks/commands/UpdateActivityPagesDependentsCommand';

@ViewModel
export class StandardsAndAlignmentsMediator {

  @inject(ActivitySelectionsStore)
  private activitySelectionsStore: ActivitySelectionsStore;

  @inject(ActivityPagesDependentsStore)
  private activityPagesDependentsStore: ActivityPagesDependentsStore;

  @computed
  public get currentActivityId(): number {
    return this.activityPagesDependentsStore.currentActivityId;
  }

  public setCurrentActivityId = (id: number): void => {
    this.activityPagesDependentsStore.setCurrentActivity(id);
    updateActivityPagesDependentsCommand(id);
  }

  @computed
  public get pageAlignments(): ReadonlyArray<IStandardAlignment> {
    const pagesStandardAlignmentsMap = this.activityPagesDependentsStore.pagesStandardAlignmentsMap;
    const pageAlignments: ReadonlyArray<IStandardAlignment> = pagesStandardAlignmentsMap.has(this.currentPageGuid)
      ? pagesStandardAlignmentsMap.get(this.currentPageGuid)
      : [];
    return pageAlignments.concat().sort(alignmentSorter);
  }

  @computed
  public get activityAlignments(): ReadonlyArray<IStandardAlignment> {
    const activityStandardAlignmentsMap = this.activityPagesDependentsStore.activityStandardAlignmentsMap;
    const activityAlignments: ReadonlyArray<IStandardAlignment> = activityStandardAlignmentsMap.has(this.currentActivityId?.toString())
      ? activityStandardAlignmentsMap.get(this.currentActivityId.toString())
      : [];
    return activityAlignments.concat().sort(alignmentSorter);
  }

  private getPageAlignment(id: number): IStandardAlignment {
    return this.pageAlignments.find((pageAlignment) => pageAlignment.standardItem.entryId === id);
  }

  private getActivityAlignment(id: number): IStandardAlignment {
    return this.activityAlignments.find((activityAlignment) => activityAlignment.standardItem.entryId === id);
  }

  public get currentPageGuid(): string {
    return this.activitySelectionsStore.currentPageRefGuid;
  }

  public removeFromActivity = async (id: number): Promise<void> => {
    await Promise.all([
      RemoveActivityAlignmentCommand(this.currentActivityId, id),
      historyLogEvent({
        ...standardAlignmentEntities(this.getActivityAlignment(id)),
        entity4: {
          entityType: HistoryEventEntityType.Activity,
          entityId: this.currentActivityId,
        },
        eventType: HistoryEventTypes.StandardActivityAlignmentAdded,
      })
    ]);
  }

  public removeFromPage = async (id: number): Promise<void> => {
    await Promise.all([
      RemovePageAlignmentCommand(this.currentPageGuid, id, this.currentActivityId),
      historyLogEvent({
        ...standardAlignmentEntities(this.getPageAlignment(id)),
        entity4: {
          entityType: HistoryEventEntityType.Page,
          entityGuid: this.currentPageGuid,
        },
        eventType: HistoryEventTypes.StandardPageAlignmentRemoved,
      })
    ]);
  }

  public setActivityAlignmentStrength = async (id: number, quality: AlignmentQuality): Promise<void> => {
    await Promise.all([
      ChangeActivityAlignmentQualityCommand(this.currentActivityId, id, quality),
      historyLogEvent({
        ...standardAlignmentEntities(this.getActivityAlignment(id)),
        entity4: {
          entityType: HistoryEventEntityType.Activity,
          entityId: this.currentActivityId,
        },
        eventType: HistoryEventTypes.StandardPageAlignmentQualityChanged,
        oldValue: String(quality === AlignmentQuality.strong ? AlignmentQuality.partial : AlignmentQuality.strong),
        newValue: String(quality)
      })
    ]);
  }

  public setPageAlignmentStrength = async (id: number, quality: AlignmentQuality): Promise<void> => {
    await Promise.all([
      ChangePageAlignmentQualityCommand(this.currentPageGuid, id, quality, this.currentActivityId),
      historyLogEvent({
        ...standardAlignmentEntities(this.getPageAlignment(id)),
        entity4: {
          entityType: HistoryEventEntityType.Page,
          entityGuid: this.currentPageGuid,
        },
        eventType: HistoryEventTypes.StandardPageAlignmentQualityChanged,
        oldValue: String(quality === AlignmentQuality.strong ? AlignmentQuality.weak : AlignmentQuality.strong),
        newValue: String(quality)
      })
    ]);
  }

  public setToAllPages = async (id: number, quality: number): Promise<void> => {
    // TODO: history events, either determine in front-end which page has the alignment, or generate the history event from the backend.
    await Promise.all([
      AddActivityPagesAlignmentsCommand(this.currentActivityId, id, quality)
    ]);
  }

  public removeFromAllPages = async (id: number): Promise<void> => {
    // TODO: history events, either determine in front-end which page has the alignment, or generate the history event from the backend.
    await Promise.all([
      RemoveActivityPagesAlignmentsCommand(this.currentActivityId, id)
    ]);
  }

}

const alignmentSorter = (alignmentA: IStandardAlignment, alignmentB: IStandardAlignment) =>
  StringUtils.compare(alignmentA.standardItem.entryDisplayId, alignmentB.standardItem.entryDisplayId);
