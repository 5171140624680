import { ClientApplicationModel } from '../models/ClientApplicationModel';
import { DatadogRUM } from '../utils/DatadogRUM';
import { IMemberInfo } from '../models/IMemberInfo';
import { instantiate } from '../dependencyInjection/instantiate';
import { UserSessionStore } from '../models/store/UserSessionStore';
import { CollaborationStore } from '../models/store/CollaborationStore';

export const userLogInCommand = (memberInfo: IMemberInfo) => {
  const userSessionStore = instantiate(UserSessionStore);
  const collaborationStore = instantiate(CollaborationStore);

  userSessionStore.setMemberInfo(memberInfo);
  userSessionStore.setAuthenticationFlag(true);
  collaborationStore.configureSelf({
    userId: memberInfo.internalId,
    windowId: ClientApplicationModel.getInstance().editionWindow
  });

  // Set user information for datadog RUM
  DatadogRUM.setUser(
    memberInfo.id.toString(),
    memberInfo.internalId,
    memberInfo.name,
    memberInfo.email,
    memberInfo.title
  );
};
