import { Node } from '../../../elements/abstract/Node';
import { TokenElement } from '../../../elements/abstract/TokenElement';
import { Apply } from '../../../elements/constructs/Apply';
import { WFactors } from '../../../elements/tokens/WFactors';
import { Times } from '../../../funcs/arithmetic/Times';
import { IFactorizeStepDecorator } from '../../../funcs/factorization/utils/IFactorizeStepDecorator';

/**
 *
 */
export class FactorizeStepSimplified implements IFactorizeStepDecorator {

  private _coef:TokenElement;

  constructor( coef:TokenElement ) {

    this._coef = coef;
  }

  public decorate(e:any):any {

    if( e instanceof Node ) {
      (<Node>e ).isExplicitGroup = true;
      const groupNode:Node = new Node( new Apply()
          , new Node( new Times() )
          , new Node( this._coef )
          , e);
      return groupNode;
    }
    if( e instanceof WFactors ) {
      const factors:TokenElement[] = ( <WFactors>e  ).toFactors();
      factors.unshift( this._coef );
      return new WFactors( factors );
    }
    if( e instanceof TokenElement ) {
      return new WFactors( (<TokenElement[]>[ this._coef, e ] ) );
    }

    return e;
  }
}
