import { Point } from '../../../js/geom/Point';
import { XNumber } from '../../core/XNumber';
import { XRound } from '../../core/XRound';

/**
 *
 */
export class SubtractionModel {

  public operand1:number;

  public operand2:number;

  public padDecimals:boolean;

  public method:string;

  private _integerLength:number;

  private _decimalLength:number;

  constructor(
      operand1:number,
      operand2:number,
      padDecimals:boolean,
      method:string){

    this.operand1 = XRound.safeRound(operand1);
    this.operand2 = XRound.safeRound(operand2);
    this.padDecimals = padDecimals;
    this.method = method;
    this.normalize();
  }

  /**
   * Make sure operand 1 and 2 are positive numbers.
   */
  private normalize():void{
    this.operand1 = Math.abs(this.operand1);
    this.operand2 = Math.abs(this.operand2);

    const o:number[] = [];
    o.push(this.operand1, this.operand2);
    const l:Point = XNumber.partsLength(o);
    this._integerLength = l.x;
    this._decimalLength = l.y;
  }

  /**
   * Return the length of the integer part.
   */
  public get integerLength():number{
    return this._integerLength;
  }

  /**
   * Return the length of the decimal part.
   */
  public get decimalLength():number{
    return this._decimalLength;
  }

  /**
   * Returns the result of the subtraction operation.
   */
  public get difference():number{
    return XRound.safeRound(this.operand1 - this.operand2);
  }

}
