import styled from 'styled-components';

export const TabContainer = styled.div`
  width: 100%;
`;

export const Tabs = styled.div`
  display: flex;
`;

interface ITab {
  readonly selected: boolean;
}

export const SingleTab = styled.div`
  background-color: ${(props) => props.theme.tabView.backgroundColor};
  border: 4px solid ${(props) => props.theme.tabView.backgroundColor};
  border-top: 4px solid ${(props) => props.theme.tabView.backgroundColor};
  border-bottom: 4px solid ${(props) => props.theme.tabView.backgroundColor};
  color: ${(props) => props.theme.tabView.color};
  font-family: 'Open Sans', 'Noto Sans Math', 'Noto Sans', sans-serif;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  flex-grow: 1;
  height: 36px;
  line-height: 36px;
  text-align: left;
  text-transform: uppercase;
  padding-left: 16px;
  padding-right: 16px;
`;

export const Tab = styled(SingleTab)<ITab>`
  background-color: ${(props) => props.selected ? props.theme.tabView.selected.backgroundColor : props.theme.tabView.backgroundColor};
  border: 4px solid ${(props) => props.selected ? props.theme.tabView.selected.borderColor : props.theme.tabView.borderColor};
  border-bottom: 4px solid ${(props) => props.selected ? props.theme.tabView.selected.borderBottom : props.theme.tabView.borderColor};
  padding-left: 0;
  padding-right: 0;
  text-align: center;
  &:hover{
    background-color: ${(props) => props.selected ? props.theme.tabView.selected.backgroundColor : props.theme.tabView.hover.backgroundColor};
    border: 4px solid ${(props) => props.selected ? props.theme.tabView.selected.borderColor : props.theme.tabView.hover.borderColor};
  }
`;
