import { AbstractStep } from '../../AbstractStep';
import { Compartment } from '../../Compartment';
import { LongDivisionOperationEnglish } from '../../LongDivisionOperationEnglish';
import { DivisionModel } from '../../models/DivisionModel';
import { DivRestEnglish } from './DivRestEnglish';
import { DivEndEnglish } from './DivEndEnglish';
import { DivQuotientEnglish } from './DivQuotientEnglish';

/**
 *
 */
export class DivWriteNewDivisorEnglish extends AbstractStep {

  private division:LongDivisionOperationEnglish;

  private get model():DivisionModel{
    return this.division.model;
  }

  constructor(division:LongDivisionOperationEnglish) {

    super(division);
    this.division = division;

    let i:number;
    let c:Compartment;

    // 2. Divisor
    const digits: any[] = String(this.model.normalizedDivisor).split('');
    for(i = 0 ; i < digits.length ; i++){
      c = Compartment.createDigit(digits[i]);
      division.divisor.push(c);
      this.target.push( c );
    }

    this.setDescription(division.culture.getString('LongDivisionEnglish.writeNewDivisor'));
  }

  public next():AbstractStep {
    const _rest:number = Number(this.division.rest.join(''));

    if(_rest > this.model.normalizedDivisor
      || ( this.model.decimals === -1 && this.division.rest.length > 0 )
      || ( this.division.steps.length === 1 )){

      return new DivQuotientEnglish(this.division, _rest, this.model.normalizedDivisor);
    }
    if( ( this.division.rest.length === 0 || _rest === 0 ) ) {
      return new DivEndEnglish(this.division, 'exact');
    }
    if(this.model.decimals === -1 && this.division.rest.length > 0 && this.division.steps.length !== 1 ){
      return new DivRestEnglish(this.division);
    }
    return new DivQuotientEnglish(this.division, _rest, this.model.normalizedDivisor);
  }

  public toString():string {
    return 'DivWriteNewDivisor';
  }

}
