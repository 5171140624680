import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { ListElement } from '../../elements/abstract/ListElement';
import { WFiniteSet } from '../../elements/tokens/WFiniteSet';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { WPoint } from '../../elements/tokens/WPoint';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

export class Flatten extends FunctionElement {
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length === 0) {
      return args.expectingArguments(0, Number.POSITIVE_INFINITY);
    }

    const elements = this.makeFlat(args);
    if (!elements) {
      return null;
    }

    if (elements.length === 0) {
      // If we try to flatten empty lists, we need to return an
      // empty list of the same type as the first list arg.
      for (let i = 0; i < args.length; i++) {
        const list = args.getList(i);
        if (list) {
          return list.transform([]);
        }
      }
    }

    return args.env.culture.listFactory.createList(elements);
  }

  private makeFlat(args: ArgumentsObject): ContentElement[] {
    const elements: ContentElement[] = [];
    for (let i = 0; i < args.length; i++) {
      elements.push(...this.flatten(args.getContentElement(i)));
    }
    return elements;
  }

  private flatten(element: ContentElement): ContentElement[] {
    if (element instanceof ListElement) {
      const elements: ContentElement[] = [];
      for (const item of element.items) {
        elements.push(...this.flatten(item));
      }
      return elements;
    }
    if (element instanceof WPoint) {
      return [element.x, element.y];
    }
    if (element instanceof WMatrix) {
      return element.values;
    }
    if (element instanceof WFiniteSet) {
      const elements: ContentElement[] = [];
      for (let i = 0; i < element.cardinal; i++) {
        elements.push(...this.flatten(element.getElementAt(i)));
      }
      return elements;
    }
    return [element];
  }
}
