export enum HistoryEventEntityType {
  Curriculum = 'curriculum',
  Course = 'course',
  StandardItem = 'standardItem',
  Library = 'library',
  Book = 'book',
  BookToCRoot = 'bookToCRoot',
  BookToCTopic = 'bookToCTopic',
  BookToCChapter = 'bookToCChapter',
  BookToCElement = 'bookToCElement',
  Activity = 'activity',
  PageInstance = 'pageInstance',
  Page = 'page',
  ContentTag = 'contentTag',
}
