import { RealElement } from '../../elements/abstract/RealElement';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';

/**
 * Special placeholder for lists or matrix
 * to specify that a value is missing.
 */
export class WEmpty extends RealElement {

  private static _instance:WEmpty;

  public static getInstance():WEmpty{
    if(!WEmpty._instance){
      WEmpty._instance = new WEmpty();
    }
    return WEmpty._instance;
  }

  /**
   *
   */
  public toNumber():number{
    return 0;
  }

  /**
   *
   */
  public toText(strict:boolean):string{
    return '□';
  }

  /**
   *
   */
  public writeTo(exporter:IMarkupExporter = null):boolean{
    if(exporter){
      exporter.writer.appendNumber('□');
    }
    return true;
  }

  /**
   *
   */
  public toAbsoluteValue():RealElement{
    return this;
  }

  /**
   *
   */
  public toOpposite():RealElement{
    return this;
  }

  /**
   *
   */
  public getType():string {
    return 'empty';
  }

}
