import { XString } from '../../../core/XString';
import { BaseNumberFormatter } from '../../../elements/formats/BaseNumberFormatter';
import { CultureInfo } from '../../../localization/CultureInfo';
import { CurrencyInfo } from '../../../localization/CurrencyInfo';
import { XMath } from '../../../core/XMath';
import { LocaleNumberFormatter } from '../../../elements/formats/numbers/LocaleNumberFormatter';

/**
 *
 */
export class DenominationFormatter extends BaseNumberFormatter {

  /**
   *
   */
  constructor(culture:CultureInfo){
    super(culture);
  }

  /**
   *
   */
  public toLocaleString(value:number):string{
    if(!this.willFormat(value)){
      return this.culture.formatNumber(value);
    }

    const c:CurrencyInfo = this.culture.currency;
    const f:LocaleNumberFormatter = this.culture.numberFormatter;

    const unit:number = c.unit;
    const fraction:number = c.fraction;

    if(value >= unit){
      return XString.substitute(
        f.currencyFormat,
        this.culture.formatNumber(value),
        this.culture.currency.unitSymbol);
    }

    const k:number = XMath.safeDiv(unit, fraction);
    return XString.substitute(
      f.centsFormat,
      this.culture.formatNumber(XMath.safeTimes(value, k)),
      this.culture.currency.fractionSymbol);
  }

  /**
   *
   */
  public toSpeakText(value:number):string{
    return this.willFormat(value) ?
          null :
          this.culture.numberFormatter.toSpeakText(value);
  }

  /**
   *
   */
  public willFormat(value:number):boolean{
    return this.culture.currency.denominations.indexOf(value) !== -1;
  }

}
