import { runInAction } from 'mobx';
import { ActivityPagesDependentsStore } from '../../models/store/ActivityPagesDependentsStore';
import { getAlignments } from '../../requests/AlignmentsRequests';
import { instantiate } from '../../dependencyInjection/instantiate';

export const updateActivityAlignmentsCommand = (activityId: number) => {
  runInAction(async () => {
    const response = await getAlignments(activityId);
    const activityPagesDependentsStore = instantiate(ActivityPagesDependentsStore);
    activityPagesDependentsStore.setPagesStandardAlignments(response.pagesAlignments);
    activityPagesDependentsStore.setActivityStandardAlignments(response.activityAlignments);
  });
}
