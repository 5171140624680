import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';

/**
 *
 */
export class RandomPath extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 2){
      return args.expectingArguments(2, 2);
    }

    const rows:RealElement = args.getWholeNumber(0);
    if(!rows){
      return null;
    }

    const cols:RealElement = args.getWholeNumber(1);
    if(!cols){
      return null;
    }

    return this.rand(rows.toNumber(), cols.toNumber(), args.env);
  }

  /**
   * Returns a matrix in which there's a way to go from top-left corner to bottom-right
   * corner by only moving to adjacent tiles with value 1. Cells with value 0 are not part
   * of a valid path.
   *
   * Put ones on every rows with an even index. In every rows with an odd index,
   * generate one cell that acts as a door between two rows with event index.
   *
   * This algorith generates paths with dead ends.
   */
  private rand(
      rows:number,
      cols:number,
      env:Environment):WMatrix{

    const i:RealElement = env.culture.createNumber(1);
    const o:RealElement = env.culture.createNumber(0);

    const n:RealElement[] = [];

    for(let r:number = 0 ; r < rows ; r++){
      const d:number = env.prng.randomIndex(cols); // door
      for(let c:number = 0 ; c < cols ; c++){
        if(r % 2 === 0 || c === d || (r === 0 && c === 0) || (r === rows - 1 && c === cols -1)){
          n.push(i);
        }else{
          n.push(o);
        }
      }
    }

    return new WMatrix(n, cols, env.culture.formats.matrixFormatImpl);
  }

}
