import { MathError } from '../../core/MathError';
import { RomanNumerals } from '../../core/num/RomanNumerals';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WString } from '../../elements/tokens/WString';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Analyseur de nombre romain.
 * Retourne la valeur numérique d'un nombre romain.
 */
export class FromRoman extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 1){
      return args.expectingArguments(1, 1);
    }

    if (args.getString(0)) {
      return args.env.culture.parseNumber(this.getNumber(args.getString(0)));
    }
    return null;
  }

  /**
   *
   */
  private getNumber(roman:WString):number{
    const n:number = (new RomanNumerals()).fromRoman(roman.getString());
    if(isNaN(n)){
      throw new MathError('RomanNumberInvalid');
    }
    return n;
  }

}
