import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';

/**
 * Pour chaque cellule remplie, compte combien il y de cellules non remplies
 * parmi les cellules aux 4 points cardinaux par rapport à la cellule active.
 */
export class EdgesPattern extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 1){
      return args.expectingArguments(1, 1);
    }

    if (args.getMatrix(0)) {
      return this.transform(args.getMatrix(0), args.env);
    }

    return null;
  }

  /**
   *
   */
  private transform(value:WMatrix, env:Environment):WMatrix{
    const o:number[] = [];

    for(let r:number = 0 ; r < value.rows ; r++){
      for(let c:number = 0 ; c < value.columns ; c++){
        let k:number = 0;
        if(value.valueAt(r, c).toNumber() !== 0){
          if(c > 0){
            // check left
            k += value.valueAt(r, c - 1).toNumber() === 0 ? 1 : 0;
          }else{
            k++;
          }

          if(c < value.columns - 1){
            // check right
            k += value.valueAt(r, c + 1).toNumber() === 0 ? 1 : 0;
          }else{
            k++;
          }

          if(r > 0){
            // check over
            k += value.valueAt(r - 1, c).toNumber() === 0 ? 1 : 0;
          }else{
            k++;
          }

          if(r < value.rows - 1){
            // check under
            k += value.valueAt(r + 1, c).toNumber() === 0 ? 1 : 0;
          }else{
            k++;
          }
        }

        o.push(k);
      }
    }

    return env.culture.createMatrix(o, value.columns);
  }

}
