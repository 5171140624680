import React from 'react';
import styled from 'styled-components';
import { InputLabel } from '@material-ui/core';
import { DateError, IDateModel } from './DateSelectModel';
import { DateChildren, IDateChildrenIndexState } from './DateChildren';
import { Dropdown, DROPDOWN_CONTENT_CLASSNAME } from '../utils/Dropdown';

export interface IDateSelectProps {
  readonly label: React.ReactNode;
  readonly onChange: (value: IDateModel) => void;
  readonly onDateError: (error: DateError) => void;
  readonly dateOptions: ReadonlyArray<{ field: string, label: string }>;
  readonly operators: ReadonlyArray<{ value: string, label: string, showSecondaryDate: boolean }>;
  readonly dates: IDateModel;
  readonly errorMessage?: string;
}

export const DateSelect: React.FC<IDateSelectProps> = (props) => {
  const [open, setOpen] = React.useState(false);
  const [dateLabel, setDateLabel] = React.useState<string>('');
  const [lastIndexSelection, setLastIndexSelection] = React.useState<IDateChildrenIndexState>({
    indexDateOption: 0,
    indexOperator: 0,
  });

  React.useEffect(() => {
    if (!props.dates) {
      setDateLabel('');
      setLastIndexSelection({
        indexDateOption: 0,
        indexOperator: 0,
      });
    }
  },              [props.dates]);

  const onChange = (indexDateOption: number, indexOperator: number, dates: ReadonlyArray<string>) => {
    // Check date interval validity - Start date inferior than end date
    const validInterval = isValidDateInterval(dates);

    const localDateLabel = currentDate(dates[0], dates[1], indexDateOption, indexOperator);
    setDateLabel(localDateLabel);

    setLastIndexSelection({
      indexDateOption,
      indexOperator,
    });

    props.onChange(
      {
        dateField: props.dateOptions[indexDateOption].field,
        operator: props.operators[indexOperator].value,
        startDate: dates[0],
        endDate: dates.length > 1 ? dates[1] : '',
        valid: localDateLabel !== '' && validInterval,
        dateLabel: localDateLabel,
      },
    );
  };

  const isValidDateInterval = (dates: readonly string[]) => {
    let validInterval = true;
    if (dates.length > 1) {
      validInterval = false;
      const d1 = Date.parse(dates[0]);
      const d2 = Date.parse(dates[1]);

      if (d2 && d1) {
        validInterval = d1 < d2;
        if (!validInterval) {
          props.onDateError(DateError.INVALID_INTERVAL);
        } else {
          props.onDateError(DateError.NONE);
        }
      } else {
        props.onDateError(DateError.NONE);
      }
    }
    return validInterval;
  };

  const currentDate = (
    mainDate: string,
    secondaryDate: string,
    indexDateOption: number,
    indexOperator: number) => {
    if (mainDate === '') {
      return '';
    }

    const dateOption = props.dateOptions[indexDateOption];
    const operator = props.operators[indexOperator];

    if (operator.value === 'between') {
      if (secondaryDate === '') {
        return '';
      }
      return `${dateOption.label} ${operator.label.toLowerCase()}: ${mainDate} / ${secondaryDate}`;

    }

    return `${dateOption.label} ${operator.label.toLowerCase()}: ${mainDate}`;
  };

  const errorMessage = props.errorMessage
    ? (
      <ErrorMessage>
        {props.errorMessage}
      </ErrorMessage>
    )
    : null;

  const label = (
    <LabelSection>
      <StyledInputLabel
        $empty={dateLabel === ''}
        $hasError={props.errorMessage !== null}
      >
        {props.label}
      </StyledInputLabel>
      <SelectedSection>
        {dateLabel}
      </SelectedSection>
    </LabelSection>
  );

  return (
    <span>
      <StyledDropdown
        open={open}
        onChangeOpen={setOpen}
        SelectedComponent={label}
        hasError={props.errorMessage !== null}
      >
        <DateChildren
          dates={props.dates}
          onSaveSelected={onChange}
          typeDateValues={props.dateOptions}
          operatorValues={props.operators}
          selectedIndex={lastIndexSelection}
        />
      </StyledDropdown>
      {errorMessage}
    </span>
  );
};

const StyledInputLabel = styled(InputLabel) <{ $empty: boolean, $hasError: boolean }>`
  font: ${(props) => props.theme.typeset.body2SemiBold};
  cursor: inherit;
  font-size: ${(props) => props.$empty ? 14 : 12}px;
  color: ${(props) => props.$hasError ? props.theme.colorset.error2 : props.theme.colorset.grey400} !important;
  height: 14px;

  ${(props) => {
    if (props.$empty) {
      return 'margin: 10px 0px 14px 8px;';
    }
    return 'margin: 4px 0px 4px 8px;';

  }}
`;

const LabelSection = styled.div`
  overflow: hidden;
  max-width: 100%;
`;

const SelectedSection = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  margin: -5px 0px 4px 8px;
  font: ${(props) => props.theme.typeset.body2SemiBold};
  color: ${(props) => props.theme.colorset.penumbra};
`;

const StyledDropdown = styled(Dropdown)`
  font-family: ${(props) => props.theme.fontFamily};
  width: 100%;
  box-sizing: border-box;

  .${DROPDOWN_CONTENT_CLASSNAME} {
    overflow: unset;
  }
`;

const ErrorMessage = styled.span`
  font-size: 14px;
  padding-left: 8px;
  color: ${(props) => props.theme.colorset.error2};
`;
