import { IDictionary } from '../../../../js/utils/IDictionary';

import { RealElement } from '../../../elements/abstract/RealElement';
import { Zeros } from '../../../elements/functions/Zeros';
import { AbstractNode } from '../../../elements/functions/tree/AbstractNode';
import { EmptyLeaf } from '../../../elements/functions/tree/EmptyLeaf';
import { NodeArithmetic } from '../../../elements/functions/tree/NodeArithmetic';
import { NodeCoefficient } from '../../../elements/functions/tree/NodeCoefficient';
import { NodeConstant } from '../../../elements/functions/tree/NodeConstant';
import { NodeExponent } from '../../../elements/functions/tree/NodeExponent';
import { NodeGroup } from '../../../elements/functions/tree/NodeGroup';
import { NodeVariable } from '../../../elements/functions/tree/NodeVariable';
import { MConstruct } from '../../../expr/conversion/models/MConstruct';
import { MParam } from '../../../expr/conversion/models/MParam';
import { CultureInfo } from '../../../localization/CultureInfo';
import { IFunctionForm } from '../../../elements/functions/models/IFunctionForm';
import { AbstractFunctionForm } from '../../../elements/functions/models/AbstractFunctionForm';

/**
 * 4.	fonction exponentielle (forme canonique transformée) f(x) = A*C^(B(x - H)) + K
 */
export class TExp extends AbstractFunctionForm {

  protected getA():MParam{return super.getA().clearMinus();}

  protected getC():MParam{return super.getC().clearMinus();}

  constructor(
      culture:CultureInfo,
      A:RealElement,
      B:RealElement,
      C:RealElement,
      H:RealElement,
      K:RealElement){

    super(culture, A, B, C, null, null, null, H, K);
  }

  public map(value:number):number{
    return this.nA * (this.nC ** this.nB * (value - this.nH)) + this.nK;
  }

  public get continuous():number{
    return 1;
  }

  public getZeros():Zeros{
    const z:Zeros = new Zeros();
    z.list = [];
    const nA:number = this.nA;
    if(		(nA > 0 && this.nB > 0 && this.nK >= 0) ||
        (nA > 0 && this.nB < 0 && this.nK >= 0) ||
        (nA < 0 && this.nB > 0 && this.nK <= 0) ||
        (nA < 0 && this.nB < 0 && this.nK <= 0)){
      // No zeros
    }else{
      z.list.push((Math.log(-this.nK / nA) / Math.log(this.nC)) / this.nB + this.nH);
    }
    return z;
  }

  public getRawTokens(parameters:IDictionary, varName:string):any[]{
    let o:any[] = [varName];
    if(parameters.hasOwnProperty('H')){
      o.unshift('(');
      o.push('−', this.getH(), ')');
    }
    if(parameters.hasOwnProperty('B')){
      o.unshift(this.getB());
    }
    o = [new MConstruct(MConstruct.TYPE_SUP, [['(', this.getC(), ')'], o])]; // C always visible
    if(parameters.hasOwnProperty('A')){
      o.unshift(this.getA(), '∙');
    }
    if(parameters.hasOwnProperty('K')){
      o.push('+', this.getK());
    }

    return o;
  }

  /**
   * 4.	fonction exponentielle (forme canonique transformée) f(x) = A*C^(B(x - H)) + K
   */
  public getSimplifyTokens(parameters:IDictionary, varName:string):any[] {
    const root:AbstractNode = ( new NodeArithmetic( NodeArithmetic.ADDITION ) )
      .setLeft(
        ( new NodeArithmetic( NodeArithmetic.MULTIPLICATION ) )
          .setLeft(
            new NodeConstant( this.getA() )
          )
          .setRight(
            new NodeExponent(
              new NodeConstant( this.getC() ),
              new NodeCoefficient(
                new NodeConstant( this.getB() ),
                new NodeGroup(
                  new NodeArithmetic( NodeArithmetic.SUBSTRACTION )
                    .setLeft( new NodeVariable( varName ) )
                    .setRight( new NodeConstant( this.getH() ) )
                  , '(', ')'
                )
              ), this.culture.numberFormatter
            )
          )
      )
      .setRight(new NodeConstant(this.getK()));

    let simplified:AbstractNode =  root.simplify();

    if( simplified instanceof EmptyLeaf ) {
      simplified = new NodeConstant( this.getK() );
    }

    return simplified.getToken();
  }

  public copy(parameters:IDictionary):IFunctionForm{
    return new TExp(
      this.culture,
      parameters.hasOwnProperty('A') ? parameters.A : this.A,
      parameters.hasOwnProperty('B') ? parameters.B : this.B,
      parameters.hasOwnProperty('C') ? parameters.C : this.C,
      parameters.hasOwnProperty('H') ? parameters.H : this.H,
      parameters.hasOwnProperty('K') ? parameters.K : this.K);
  }

}
