import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { TRational } from '../../elements/functions/models/TRational';
import { WFunction } from '../../elements/tokens/WFunction';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class RationalFunction extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 4){
      return args.expectingArguments(4, 4);
    }

    const A:RealElement = args.getReal(0);
    const B:RealElement = args.getReal(1);
    const C:RealElement = args.getReal(2);
    const D:RealElement = args.getReal(3);

    if(A && B && C && D){
      return new WFunction(new TRational(args.env.culture, A, B, C, D, null, null));
    }

    return null;
  }

}
