import { IPrng } from '../../../core/prng/IPrng';
import { RealElement } from '../../../elements/abstract/RealElement';
import { ISampler } from '../../../elements/markers/ISampler';

/**
 *
 */
export class QProbabilityDistribution implements ISampler {

  private modalities:RealElement[];

  private probabilityVector:number[];

  /**
   *
   */
  constructor(
      modalities:RealElement[],
      probabilityVector:number[]){
    this.modalities = modalities;
    this.probabilityVector = probabilityVector;
  }

  /**
   *
   */
  public sample(prng:IPrng):RealElement{
    const m:number = prng.random();
    let t:number = 0;
    for(let i:number = 0 ; i < this.modalities.length ; i++){
      t += this.probabilityVector[i];
      if(m <= t){
        return this.modalities[i];
      }
    }
    return null;
  }

}
