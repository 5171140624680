import { MmlWriter } from '../../core/mml/MmlWriter';
import { SetElement } from '../../elements/abstract/SetElement';
import { SymbolElement } from '../../elements/abstract/SymbolElement';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';
import { WComplement } from '../../elements/tokens/WComplement';
import { WInterval } from '../../elements/tokens/WInterval';
import { WNumberSet } from '../../elements/tokens/WNumberSet';

/**
 * Set-builder notation
 */
export class WSetBuilder extends SetElement {

  private _symbol:SymbolElement;

  public get symbol():SymbolElement{return this._symbol;}

  private _numbers:WNumberSet;

  public get numbers():WNumberSet{return this._numbers;}

  private _condition:SetElement;

  public get condition():SetElement{return this._condition;}

  constructor(
      symbol:SymbolElement,
      numbers:WNumberSet,
      condition:SetElement){
    super();
    this._symbol = symbol;
    this._numbers = numbers;
    this._condition = condition;
  }

  public writeTo(exporter:IMarkupExporter = null):boolean{
    if(exporter){
      const variable:string = this.symbol.getSymbol();

      const w:MmlWriter = exporter.writer;
      w.beginFenced('{', '}');
      w.separators = ''; // no separator
      exporter.writeIdentifier(variable);
      w.appendOperator('∈');
      this.numbers.writeTo(exporter);

      if(this.condition instanceof WInterval){

        const interval:WInterval = <WInterval>this.condition ;
        if(interval.lBound == null && interval.rBound == null){
          // Nothing else to write.
        }else if(interval.lBound == null){
          w.appendOperator('|');
          exporter.writeIdentifier(variable);
          w.appendOperator(interval.closure.upper ? '≤' : '<');
          interval.rBound.writeTo(exporter);
        }else if(interval.rBound == null){
          w.appendOperator('|');
          exporter.writeIdentifier(variable);
          w.appendOperator(interval.closure.lower ? '≥' : '>');
          interval.lBound.writeTo(exporter);
        }else{
          w.appendOperator('|');
          interval.lBound.writeTo(exporter);
          w.appendOperator(interval.closure.lower ? '≤' : '<');
          exporter.writeIdentifier(variable);
          w.appendOperator(interval.closure.upper ? '≤' : '<');
          interval.rBound.writeTo(exporter);
        }

      }else if(this.condition instanceof WComplement){

        const complement:WComplement = <WComplement>this.condition ;

        if(		complement.B instanceof WNumberSet &&
            (<WNumberSet>complement.B ).toSymbol() === 'ℝ' &&
            complement.A instanceof WInterval &&
            (<WInterval>complement.A ).isFinite){

          const excluded:WInterval = <WInterval>complement.A ;
          w.appendOperator('|');
          excluded.lBound.writeTo(exporter);
          w.appendOperator(excluded.closure.lower ? '>' : '≥');
          exporter.writeIdentifier(variable);
          w.appendOperator(excluded.closure.upper ? '>' : '≥');
          excluded.rBound.writeTo(exporter);
        }

      }else{
        w.appendOperator('|');
        exporter.writeIdentifier(variable);
        w.appendOperator('∈');
        this.condition.writeTo(exporter);
      }

      w.endFenced();
    }

    return true;
  }

  public getType():string {
    return 'setBuilder';
  }
}
