import React from 'react';
import styled from 'styled-components';

interface IErrorBoundaryState {
  readonly error?: Error;
  readonly errorInfo?: React.ErrorInfo;
}

export default class ErrorBoundary extends React.Component<{}, IErrorBoundaryState> {

  constructor(props: {}) {
    super(props);
    this.state = {};
  }

  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    this.setState({
      error,
      errorInfo,
    });
  }


  public render(): React.ReactNode {
    const { children} = this.props;
    const { error } = this.state;

    if (error) {
      return this.renderErrorPage();
    }

    return children;
  }

  private renderErrorPage(): React.ReactNode {
    const { error, errorInfo } = this.state;
    return (
      <ErrorDiv
        data-class-name="ErrorBoundary"
      >
        <ErrorTitle>{error ? `${error}` : 'Uncaught Error'}</ErrorTitle>
        <ErrorSubtitle>Stacktrace</ErrorSubtitle>
        <StacktraceDiv>
          {errorInfo && errorInfo.componentStack}
        </StacktraceDiv>
      </ErrorDiv>
    );
  }
}

const ErrorDiv = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  color: #FF0000;
  background: #FFFFFF;
  box-shadow: 0 5px 10px rgba(0, 0, 0, .4);
  width: 800px;
  height: 500px;
  border-radius: 5px;
  padding: 5px;
  overflow: auto;
`;

const ErrorTitle = styled.h2`
  font-family: sans-serif;
  font-size: 23px;
  margin: 10px 0 10px 10px;
`;

const ErrorSubtitle = styled.h2`
  font-family: sans-serif;
  font-size: 16px;
  margin: 10px 0 10px 10px;
`;

const StacktraceDiv = styled.div`
  white-space: pre-line;
  font-family: monospace;
  opacity: .8;
  font-size: 13px;
  line-height: 1.3;
  padding: 10px;
  height: 100%;
  max-height: 350px;
  overflow: auto;
  border: 1px solid #dedede;
  background-color: #fefefe;
  border-radius: 5px;
`;
