import { Node } from '../../../elements/abstract/Node';
import { WPolynomial } from '../../../elements/tokens/WPolynomial';
import { Environment } from '../../../expr/Environment';
import { TokensImporter } from '../../../expr/conversion/input/TokensImporter';
import { Skeleton } from '../../../expr/manipulation/Skeleton';
import { Plus } from '../../../funcs/arithmetic/Plus';
import { AbstractRule } from '../../../expr/manipulation/rules/AbstractRule';

/**
 * C - N(M1 + M2) --> C + -N*M1 + -N*M2
 * x - 1(x + 1) --> x - x - 1  instead of x - (x + 1)
 *
 * test
 * 2(3y+4)-5(6y+7)
 */
export class NegativeDistributivity extends AbstractRule {

  constructor(){
    super(true, true);
  }

  public applyNode(node:Node, stateMode:number, env:Environment):Node{
    if(node.isSimple){
      const s:string = Skeleton.createSkeleton(node, '?(@,?)');
      if(s === '-(@,p)'){
        const p:WPolynomial = <WPolynomial>node.childs[2].value ;
        if(p.numMonomials > 1){
          return TokensImporter.importTokens(
            [node.childs[1].value,
             Plus.getInstance(),
             p.toOpposite()],
            env);
        }
      }
    }
    return null;
  }

}
