import { ClientApplicationModel } from '../../models/ClientApplicationModel';
import { IPageContentRevision } from '../../models/IPageContentRevision';
import { api } from '../../utils/fetch';

export interface ILegacyPageContentVersion {
  contentFormat: 'xml' | 'json';
  copyType: 'compatibilityCopy' | 'previewCopy' | 'publishedCopy' | 'removedWorkingCopy' | 'draftCopy';
  dateModified: string; // date string
  version: number;
}

export interface IPageContentVersionsResponse {
  pageGuid: string;  // guid
  revisions: ReadonlyArray<IPageContentRevision>;
  versions: ReadonlyArray<ILegacyPageContentVersion>;
}

export const pageContentVersions = async (pageGuid: string,
                                          clientApplication: ClientApplicationModel): Promise<IPageContentVersionsResponse> => {
  const response = await api<IPageContentVersionsResponse>(`/Publisher/PageContent/Versions?pageGuid=${pageGuid}&${clientApplication.params}`);
  return response.json();
};
