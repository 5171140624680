import { MathError } from '../../core/MathError';
import { ElementaryOperation } from '../../elementary/ElementaryOperation';
import { LongDivisionOperationEnglish } from '../../elementary/LongDivisionOperationEnglish';
import { LongDivisionOperationEuclidienne } from '../../elementary/LongDivisionOperationEuclidienne';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WLongOperation } from '../../elements/tokens/WLongOperation';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Division longue.
 */
export class LongDivision extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length < 2 || args.length > 5){
      return args.expectingArguments(2, 5);
    }

    const dividend:RealElement = args.getReal(0);
    if(!dividend){
      return null;
    }

    const divisor:RealElement = args.getReal(1);
    if(!divisor){
      return null;
    }

    let decimals:number = -1;
    let method = args.env.culture.longDivisionImplementation;
    let interactive: boolean = false;

    if(args.length > 2){
      if(args.getInteger(2)){
        decimals = args.getInteger(2).toNumber();
      }else{
        return null;
      }

      if(args.length > 3){
        if(args.getString(3)){
          method = args.getString(3).toString();
          if (method === 'auto') {
            method = args.env.culture.longDivisionImplementation;
          }
          if (method !== 'euclidienne' && method !== 'english') {
            return null;
          }
        }else{
          return null;
        }

        if(args.length > 4){
          if(args.getBoolean(4)){
            interactive = args.getBoolean(4).toBoolean();
          }else{
            return null;
          }
        }
      }
    }

    if(decimals < -2){
      throw new MathError('Decimals can be -2 (auto), -1 (rest) or a positive-integer representing the number of desired decimals.');
    }

    if(decimals === -1 && (!dividend.isInteger() || !divisor.isInteger())){
      throw new MathError('When decimals is -1 (rest), integer operands are required.');
    }

    const division:ElementaryOperation =
      method === 'euclidienne' ?
        LongDivisionOperationEuclidienne.createOperation(
          dividend.toNumber(),
          divisor.toNumber(),
          decimals,
          args.env.culture,
          interactive) :
        LongDivisionOperationEnglish.createOperation(
          dividend.toNumber(),
          divisor.toNumber(),
          decimals,
          args.env.culture,
          interactive);

    return new WLongOperation(division);
  }

}
