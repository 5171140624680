import { CSSProperties } from 'react';
import { ColorSet, setHexColorTransparency } from '../../../themes/ColorSet';

export interface ISelectItemTheme {
  color: CSSProperties['color'];
  hover: {
    backgroundColor: CSSProperties['backgroundColor'];
  };
  selected: {
    color: CSSProperties['color'];
    backgroundColor: CSSProperties['backgroundColor'];
    border: CSSProperties['border'];
    hover: {
      backgroundColor: CSSProperties['backgroundColor'];
    };
  };
}

export const darkSelectItemTheme: ISelectItemTheme = {
  color: ColorSet.white,
  hover: {
    backgroundColor: ColorSet.grey800,
  },
  selected: {
    color: ColorSet.white,
    backgroundColor: setHexColorTransparency(ColorSet.primary500, 0.2),
    border: `1px solid ${ColorSet.primary500}`,
    hover: {
      backgroundColor: setHexColorTransparency(ColorSet.primary500, 0.5),
    }
  }
};

export const lightSelectItemTheme: ISelectItemTheme = {
  color: ColorSet.grey900,
  hover: {
    backgroundColor: ColorSet.grey050,
  },
  selected: {
    color: ColorSet.primary700,
    backgroundColor: setHexColorTransparency(ColorSet.primary500, 0.1),
    border: `1px solid ${ColorSet.primary500}`,
    hover: {
      backgroundColor: setHexColorTransparency(ColorSet.primary500, 0.5),
    }
  }
};
