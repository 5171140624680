import { MathError } from '../../core/MathError';
import { AnonymousFunction } from '../../elements/abstract/AnonymousFunction';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { ListElement } from '../../elements/abstract/ListElement';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Evaluate } from '../../expr/manipulation/Evaluate';

/**
 *
 */
export class MapTable extends FunctionElement {

  private static err_inconsistent_columns:string = 'Columns must have the\nsame number of rows';

  private static err_invalid_columns:string = 'Columns must be lists';

  private static err_empty_columns:string = 'Can\'t map empty columns';

  private static err_invalid_items:string = 'Result of mapping is not an item\nthat can go in a list';

  private static err_inconsistent_items:string = 'Items must have the same type';

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length < 2){
      return args.expectingArguments(2, Number.POSITIVE_INFINITY);
    }

    const mapFunction:AnonymousFunction = args.getAnonymousFunction(0);
    if(!mapFunction){
      return null;
    }

    const evaluator:Evaluate = new Evaluate(mapFunction, args.env);

    const columns:ListElement[] = [];

    // 1. Check that all columns have the same number of rows
    let rows:number = -1;
    for(let i:number = 1 ; i < args.length ; i++){
      const column:ListElement = args.getList(i);
      if(!column){
        throw new MathError(MapTable.err_invalid_columns);
      }
      if(rows === -1){
        rows = column.count;
      }else if(rows !== column.count){
        throw new MathError(MapTable.err_inconsistent_columns);
      }
      columns.push(column);
    }

    if(rows === 0){
      throw new MathError(MapTable.err_empty_columns);
    }

    let baseItemCode:string;

    const items:ContentElement[] = [];
    for(let row:number = 0 ; row < rows ; row++){
      const args2:ContentElement[] = [];
      for(let j:number = 0 ; j < columns.length ; j++){
        args2.push(columns[j].getItemAt(row));
      }

      const result:ContentElement = evaluator.evaluateN(args2);
      if(result.getListItemCode() == null){
        throw new MathError(MapTable.err_invalid_items);
      }

      if(result.getListItemCode() === baseItemCode || baseItemCode == null){
        baseItemCode = result.getListItemCode();
        items.push(result);
      }else{
        throw new MathError(MapTable.err_inconsistent_items);
      }
    }

    return args.env.culture.listFactory.createList(items);
  }

}
