import { Point } from '../../../../js/geom/Point';
import { IDictionary } from '../../../../js/utils/IDictionary';

import { RealElement } from '../../../elements/abstract/RealElement';
import { Zeros } from '../../../elements/functions/Zeros';
import { AbstractNode } from '../../../elements/functions/tree/AbstractNode';
import { EmptyLeaf } from '../../../elements/functions/tree/EmptyLeaf';
import { NodeArithmetic } from '../../../elements/functions/tree/NodeArithmetic';
import { NodeCoefficient } from '../../../elements/functions/tree/NodeCoefficient';
import { NodeConstant } from '../../../elements/functions/tree/NodeConstant';
import { NodeGroup } from '../../../elements/functions/tree/NodeGroup';
import { NodeSqrt } from '../../../elements/functions/tree/NodeSqrt';
import { NodeVariable } from '../../../elements/functions/tree/NodeVariable';
import { MConstruct } from '../../../expr/conversion/models/MConstruct';
import { CultureInfo } from '../../../localization/CultureInfo';
import { IFunctionForm } from '../../../elements/functions/models/IFunctionForm';
import { AbstractFunctionForm } from '../../../elements/functions/models/AbstractFunctionForm';

/**
 * 3.1 = fonction radical (forme canonique)  f(x) = A√(B(x - H)) + K
 */
export class TSqrt extends AbstractFunctionForm {

  constructor(
      culture:CultureInfo,
      A:RealElement,
      B:RealElement,
      H:RealElement,
      K:RealElement){

    super(culture, A, B, null, null, null, null, H, K);
  }

  public map(value:number):number{
    return this.nA * Math.sqrt(this.nB * (value - this.nH)) + this.nK;
  }

  public get continuous():number{
    return 0;
  }

  public get limit():Point{
    return new Point(this.nH, 0);
  }

  /**
   *
   */
  public getZeros():Zeros{
    const z:Zeros = new Zeros();
    z.list = [];
    if(-this.nK / this.nA >= 0){
      z.list.push((this.nK * this.nK) / (this.nA * this.nA * this.nB) + this.nH);
    }else{
      // No zeros, list must be an empty vector.
    }
    return z;
  }

  public getRawTokens(parameters:IDictionary, varName:string):any[]{
    let o:any[] = [varName];
    if(parameters.hasOwnProperty('H')){
      o.unshift('(');
      o.push('−', this.getH(), ')');
    }
    if(parameters.hasOwnProperty('B')){
      o.unshift(this.getB());
    }
    o = [new MConstruct(MConstruct.TYPE_SQRT, [o])];
    if(parameters.hasOwnProperty('A')){
      o.unshift(this.getA());
    }
    if(parameters.hasOwnProperty('K')){
      o.push('+', this.getK());
    }
    return o;
  }

  /**
   * 3.1 = fonction radical (forme canonique)  f(x) = A√(B(x - H)) + K
   */
  public getSimplifyTokens(parameters:IDictionary, varName:string):any[] {

    const root:AbstractNode = ( new NodeArithmetic( NodeArithmetic.ADDITION ) )
      .setLeft(
        new NodeCoefficient( new NodeConstant( this.getA() ),
          new NodeSqrt(
            ( new NodeCoefficient( new NodeConstant( this.getB() ),
              ( new NodeGroup(
                ( new NodeArithmetic( NodeArithmetic.SUBSTRACTION ) )
                  .setLeft(new NodeVariable( varName ))
                  .setRight(new NodeConstant( this.getH() ))
              , '(', ')' ) )
            ) )
          )
        )
      )
      .setRight(new NodeConstant(this.getK()));

    let simplified:AbstractNode =  root.simplify();

    if( simplified instanceof EmptyLeaf ) {
      simplified = new NodeConstant( this.getK() );
    }

    return simplified.getToken();
  }

  public copy(parameters:IDictionary):IFunctionForm{
    return new TSqrt(
      this.culture,
      parameters.hasOwnProperty('A') ? parameters.A : this.A,
      parameters.hasOwnProperty('B') ? parameters.B : this.B,
      parameters.hasOwnProperty('H') ? parameters.H : this.H,
      parameters.hasOwnProperty('K') ? parameters.K : this.K);
  }

}
