import { AbstractStep } from '../AbstractStep';
import { Compartment } from '../Compartment';
import { LongSubtractionOperation } from '../LongSubtractionOperation';
import { DigitPlaceValueFormatter } from '../../elements/formats/numbers/DigitPlaceValueFormatter';

/**
 *
 */
export class SubtractColumnDiff extends AbstractStep {

  private pos:number;

  private n1:number;

  private n2:number;

  private n3:number;

  private d:number;

  constructor(operation:LongSubtractionOperation){
    super(operation);
    this.pos = operation.position;

    const o0:Compartment = operation.operandAt(operation.position, -1); // borrow or regroup
    const o1:Compartment = operation.operandAt(operation.position, 0);
    const o2:Compartment = operation.operandAt(operation.position, 1);
    const o3:Compartment = operation.operandAt(operation.position, 2);

    this.n1 = (o0 ? o0.value : (o1 ? o1.value : 0)); // operand 1
    this.n2 = o2 ? o2.value : 0; // operand 2
    this.n3 = o3 ? o3.value : 0; // reset

    if(o0){
      this.source.push(o0);
    }
    if(o1){
      this.source.push(o1);
    }
    if(o2){
      this.source.push(o2);
    }
    if(o3){
      this.source.push(o3);
    }

    this.d = this.n1 - (this.n2 + this.n3);

    const c:Compartment = Compartment.createDigit(this.d);
    this.target.push(c);
    operation.result.unshift(c);
    operation.position++;

    this.setDescription(
      operation.culture.getString(
        'LongSubtractionOperation.subtractColumnLabel',
        this.n1,
        this.n2,
        this.n3,
        (new DigitPlaceValueFormatter(operation.culture, true)).toLocaleString(this.pos)));
  }

  public static accept(operation:LongSubtractionOperation):boolean{
    const o0:Compartment = operation.operandAt(operation.position, -1);
    const o1:Compartment = operation.operandAt(operation.position, 0);
    const o2:Compartment = operation.operandAt(operation.position, 1);
    const o3:Compartment = operation.operandAt(operation.position, 2);
    const n1:number = (o0 ? o0.value : (o1 ? o1.value : 0));
    const n2:number = o2 ? o2.value : 0;
    const n3:number = o3 ? o3.value : 0;
    const d:number = n1 - (n2 + n3);
    return d >= 0;
  }

}
