import { ILocalizedModel } from './ILocalizedModel';

export const IContentObjectMetadata = Symbol('IContentObjectMetadata');
export interface IContentObjectMetadata {
  name?: ILocalizedModel<string>;
  group?: ILocalizedModel<string>;
  tags?: ILocalizedModel<string>;
  description?: ILocalizedModel<string>;
  help?: ILocalizedModel<string>;
  icon?: string | null;
  screenshot?: string | null;
  subgroup?: ILocalizedModel<string>; // obsolete
  color?: number; // obsolete
}
