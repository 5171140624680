import { SetElement } from '../../elements/abstract/SetElement';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';

/**
 *
 */
export class WIntersection extends SetElement {

  private _parts:SetElement[];

  public get parts():SetElement[]{return this._parts;}

  /**
   *
   */
  constructor(parts:SetElement[]){
    super();
    this._parts = parts;
  }

  /**
   *
   */
  public writeTo(exporter:IMarkupExporter = null):boolean{
    if(exporter){
      exporter.writer.beginRow();
      for(let i:number = 0 ; i < this.parts.length; i++){
        if(i > 0){
          exporter.writer.appendOperator('∩');
        }
        this.parts[i].writeTo(exporter);
      }
      exporter.writer.endRow();
    }
    return true;
  }

  public getType():string {
    return 'intersection';
  }
}
