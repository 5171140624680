import { Point } from '../../../js/geom/Point';
import { XTypes } from '../../core/XTypes';
import { ContentElement } from '../abstract/ContentElement';
import { RealElement } from '../abstract/RealElement';
import { ListElement } from '../abstract/ListElement';
import { WList } from '../tokens/WList';
import { WListOfIntervals } from '../tokens/WListOfIntervals';
import { WListOfPoints } from '../tokens/WListOfPoints';
import { WListOfSegments } from '../tokens/WListOfSegments';
import { WListOfString } from '../tokens/WListOfString';
import { WListOfList } from '../tokens/WListOfList';
import { WListOfListOfString } from '../tokens/WListOfListOfString';
import { WNumber } from '../tokens/WNumber';
import { WString } from '../tokens/WString';
import { CultureInfo } from '../../localization/CultureInfo';

export class ListFactory {
  private culture: CultureInfo;

  constructor(culture: CultureInfo) {
    this.culture = culture;
  }

  public createFromNumbers(numbers: number[]): WList {
    if (numbers == null) {
      return null;
    }

    const items: ContentElement[] = [];
    for (const number of numbers) {
      items.push(new WNumber(number, 1, false, this.culture.numberFormatter));
    }
    return new WList(items, this.culture.listFormatter);
  }

  public createFromStrings(strings: string[]): WListOfString {
    if (strings == null) {
      return null;
    }

    const items: ContentElement[] = [];
    for (const string of strings) {
      items.push(new WString(string, null));
    }
    return new WListOfString(items, this.culture.listFormatter);
  }

  public createFromPoints(points: Point[]): WListOfPoints {
    if (points == null) {
      return null;
    }

    const items: ContentElement[] = [];
    for (const point of points) {
      items.push(this.culture.parsePoint(point));
    }
    return new WListOfPoints(items, this.culture.listFormatter);
  }

  /**
   * Returns the first valid listItem code
   *
   * @param items
   * @private
   */
  private getFirstLeafListItemCode(items: ContentElement[]): string {
    if (!items) {
      return null;
    }

    for (const item of items) {
      if (item instanceof ListElement) {
        if (item.items.length !== 0) {
          return this.getFirstLeafListItemCode(item.items);
        }
        continue;
      }

      return item.getListItemCode();
    }
  }

  /**
   * Validate that the items match the provided list item code.
   * Returns a tuple [valid: boolean, listOfList: boolean]
   */
  private validateItems(items: ContentElement[], listItemCode: string): [boolean, boolean] {
    let listOfList = false;
    for (const item of items) {
      const code = item.getListItemCode();
      if (listItemCode === code) {
        continue;
      }
      if (item instanceof ListElement) {
        listOfList = true;
        if (this.validateItems(item.items, listItemCode)[0]) {
          continue;
        }
      }
      return [false, listOfList];
    }
    return [true, listOfList];
  }

  /**
   * Create the appropriate list for the provided items
   * @param items
   * @param typicalItemCode if true, allows the creation of an empty list for that type of item
   */
  public createList(items: ContentElement[], typicalItemCode: string = null): ListElement {
    if (items == null) {
      return null;
    }

    const firstLeafItemCode = this.getFirstLeafListItemCode(items);

    if (typicalItemCode !== null && items.length > 0 && typicalItemCode !== firstLeafItemCode) {
      throw new Error('Items doesn\'t match typical item code');
    }

    let listOfList = false;

    if (items.length > 1) {
      const validation = this.validateItems(items, firstLeafItemCode);
      if (!validation[0]) {
        return null;
      }
      listOfList = validation[1];
    }

    if (listOfList) {
      switch (firstLeafItemCode) {
        case RealElement.getListItemCode():
          return new WListOfList(items, this.culture.listFormatter);
        case WString.getListItemCode():
          return new WListOfListOfString(items, this.culture.listFormatter);
        default:
          if (typicalItemCode === null) {
            return new WListOfList(items, this.culture.listFormatter);
          }
      }
    }

    switch (firstLeafItemCode || typicalItemCode) {
      case RealElement.getListItemCode():
        return new WList(items, this.culture.listFormatter);
      case 'interval':
        return new WListOfIntervals(items, this.culture.listFormatter);
      case 'point':
        return new WListOfPoints(items, this.culture.listFormatter);
      case 'segment':
        return new WListOfSegments(items, this.culture.listFormatter);
      case WString.getListItemCode():
        return new WListOfString(items, this.culture.listFormatter);
    }

    return null;
  }

  public createFromAny(value: any, typicalItemCode: string = null): ListElement {
    if (value == null) {
      return null;
    }
    const item = this.parseListItem(value);
    if (item) {
      return this.createList([item], typicalItemCode);
    }
    return null;
  }

  public createFromArray(values: any[], typicalItemCode: string = null): ListElement {
    if (!values) {
      return null;
    }
    const items: ContentElement[] = [];
    for (let i = 0; i < values.length; i++) {
      const item = this.parseListItem(values[i]);
      if (!item || items.length > 0 && item.getListItemCode() !== items[0].getListItemCode()) {
        return null;
      }
      items.push(item);
    }
    return this.createList(items, typicalItemCode);
  }

  private parseListItem(value: unknown): ContentElement {
    if (value instanceof ContentElement) {
      if (value.getListItemCode()) {
        return value;
      }
      if (value.narrow() && value.narrow().getListItemCode()) {
        return value.narrow();
      }
      if (value.widen() && value.widen().getListItemCode()) {
        return value.widen();
      }
      return null;
    }
    if (value instanceof Point) {
      return this.culture.parsePoint(value);
    }
    if (XTypes.isString(value)) {
      return new WString(String(value));
    }
    if (!isNaN(Number(String(value)))) {
      return new WNumber(Number(String(value)), 1, false, this.culture.numberFormatter);
    }
    return null;
  }
}
