/**
 *
 */
export class HitPositionModel {

  public x:number;

  public y:number;

  constructor(x:number, y:number) {

    this.x = x;
    this.y = y;
  }

}
