import React from 'react';
import styled from 'styled-components';
import { ReadonlyContext } from '../../contexts/ReadonlyContext/index';

export interface ITextAreaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  readonly autoHeight?: boolean;
}

const setAutoHeight = (textArea: HTMLTextAreaElement) => {
  const borderSize: number = 1;
  const minHeight = textArea.style.minHeight;
  textArea.style.minHeight = 'unset';
  textArea.style.height = '1px';
  textArea.style.height = `${textArea.scrollHeight + (borderSize * 2)}px`;
  textArea.style.minHeight = minHeight;
};

const TextAreaComponent: React.ForwardRefRenderFunction<HTMLTextAreaElement, ITextAreaProps> =
  (props, ref) => {
    const { autoHeight, onChange, disabled, ...otherProps } = props;
    const textAreaRef = React.useRef<HTMLTextAreaElement>(null);
    const isReadonly = React.useContext(ReadonlyContext);
    const isDisabled = disabled || isReadonly;

    const [resizeObserver] =
    React.useState(new ResizeObserver(() => {
      if (autoHeight) {
        setAutoHeight(textAreaRef.current);
      }
    }));

    React.useEffect(() => {
      if (autoHeight && textAreaRef.current) {
        setAutoHeight(textAreaRef.current);
      }
    },              [otherProps.value, autoHeight]);

    const onRef = (element: HTMLTextAreaElement) => {
      textAreaRef.current = element;

      resizeObserver.disconnect();
      if (element) {
        resizeObserver.observe(element);
      }

      if (ref) {
        if (typeof ref === 'function') {
          ref(element);
        } else {
          ref.current = element;
        }
      }
    };

    return (
      <StyledTextArea
        ref={onRef}
        onChange={onChange}
        disabled={isDisabled}
        {...otherProps}
      />
    );
  };

export const TextArea = React.memo(React.forwardRef(TextAreaComponent));

const ThemedTextArea = styled.textarea((props) => {
  const {
    error,
    focus,
    disabled,
    ...otherProps
  } = props.theme.input.default;
  return {
    ...otherProps,
    ':disabled' : disabled,
    ':focus' : focus,
  }
})

const StyledTextArea = styled(ThemedTextArea)`
  border-radius: 4px;
  padding: 6px 8px;
  outline: unset;
  resize: none;
  box-sizing: border-box;
  font: ${(props) => props.theme.typeset.body2SemiBold};

  &::placeholder {
    color: ${(props) => props.theme.input.default.placeholderColor};
    opacity: 1;
  }
`;
