import { ContentElement } from '../elements/abstract/ContentElement';
import { Node } from '../elements/abstract/Node';
import { LineParser } from '../elements/factories/LineParser';
import { BaseLinearEquationFormatter } from '../elements/formats/BaseLinearEquationFormatter';
import { WLine } from '../elements/tokens/WLine';
import { IWriter } from '../expr/conversion/writers/IWriter';
import { MathWriter } from '../expr/conversion/writers/MathWriter';
import { OldWriterFacade } from '../expr/conversion/writers/OldWriterFacade';
import { InputCapabilities } from './InputCapabilities';
import { KeyboardConfiguration } from './KeyboardConfiguration';
import { CommonError } from './CommonError';
import { BaseCorrector } from './BaseCorrector';

/**
 *
 */
export class CLine extends BaseCorrector {

  private yLabel:string;

  private xLabel:string;

  constructor(yLabel: string, xLabel: string){
    super();
    this.yLabel = yLabel;
    this.xLabel = xLabel;
  }

  public parse(value:string):Node{
    const parser:LineParser = new LineParser(this.env, this.yLabel, this.xLabel);
    const line:WLine = parser.parseEquation(this.translateInput(value));
    if(line){
      const node:Node = new Node(line);
      const A:string = String(line.A.toNumber());
      const B:string = String(line.B.toNumber());
      const C:string = String(line.C.toNumber());
      node.userData = 'Line('+ A + ', ' + B + ', ' + C + ')';
      if (this.xLabel !== 'x') {
        node.userData = `SetVarName(${node.userData}, "${this.xLabel}")`;
      }
      return node;
    }

    return null;
  }

  public correct(
      valueArg:string,
      target:ContentElement,
      ...targets:any[]):boolean{

    const value = this.translateInput(valueArg);

    const line:WLine = <WLine>target ;
    const { yLabel, xLabel } = line;

    if( value.indexOf(yLabel.toUpperCase()) !== -1 ||
        value.indexOf(xLabel.toUpperCase()) !== -1){
      this.raiseError(CommonError.LOWERCASE_VARIABLES);
    }

    const formatter:BaseLinearEquationFormatter = line.formatter; // need to make a copy of the formatter because formatter is lost when doing tryParse

    const parser:LineParser = new LineParser(this.env, yLabel, xLabel);
    const target2:WLine = parser.parseEquation(formatter.toLocaleString(line, '=')); // Apply precision
    const value2:WLine = parser.parseEquation(value);

    if(target2 && value2){
      if(target2.equalsTo(value2)){
        if(this.options.acceptEquivalent){
          return true;
        }
        if(formatter.validateFormat(value, yLabel, xLabel)){
          return true;
        }
        this.raiseError(formatter.formatError('='), ['=']);
      }
    }else{
      this.raiseError(formatter.formatError('='), ['=']);
    }

    return false;
  }

  public get mathKeyboard():number{
    return KeyboardConfiguration.LINE;
  }

  public get inputCapabilities():InputCapabilities{
    return super.inputWithSymbols('=/');
  }

  public writeTo(
      w:IWriter,
      target:ContentElement,
      ...targets:any[]):void{
    const line:WLine = <WLine>target ;
    line.formatter.flushTo(new MathWriter(new OldWriterFacade(w)), line, '=');
  }

  private translateInput(valueArg:string):string{
    let value = valueArg;
    if(this.useLatex){
      value = this.sanitizeInput(value);
      value = value.replace(/(\\frac\{)([^\}]+)(\}\{)([^\}]+)(\})/g, '$2/$4');
    }
    return value;
  }

}
