import { XMath } from '../../core/XMath';
import { MmlWriter } from '../../core/mml/MmlWriter';
import { Attributes } from '../../elements/abstract/Attributes';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { ElementCodes } from '../../elements/abstract/ElementCodes';
import { RealElement } from '../../elements/abstract/RealElement';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';
import { WRational } from '../../elements/tokens/WRational';

/**
 *
 */
export class WPower extends RealElement {

  private _base:RealElement;

  public get base():RealElement{return this._base;}

  private _exponent:RealElement;

  public get exponent():RealElement{return this._exponent;}

  constructor(base:RealElement, exponent:RealElement) {

    super();
    this._base = base;
    this._exponent = exponent;
  }

  public toNumber():number{
    return XMath.safePow(
      this.base.toNumber(),
      this.exponent.toNumber());
  }

  public toText(strict:boolean):string{
    return null;
  }

  public equalsTo(value:ContentElement):boolean{
    if(value instanceof WPower){
      return 	XMath.safeEquals(this.base.toNumber(), (<WPower>value ).base.toNumber()) &&
          XMath.safeEquals(this.exponent.toNumber(), (<WPower>value ).exponent.toNumber());
    }
    return false;
  }

  public getAttributes():number{
    return super.getAttributes() | Attributes.COMPLEX_CONTENT;
  }

  public writeTo(exporter:IMarkupExporter = null):boolean{
    if(exporter){
      const w:MmlWriter = exporter.writer;
      w.beginSup();
      if(this.base instanceof WRational || this.base.toNumber() < 0){
        w.beginFenced();
        this.base.writeTo(exporter);
        w.endFenced();
      }else{
        this.base.writeTo(exporter);
      }
      this.exponent.writeTo(exporter);
      w.endSup();
    }
    return true;
  }

  public toAbsoluteValue():RealElement {
    return new WPower(this.base.toAbsoluteValue(), this.exponent);
  }

  public toOpposite():RealElement {
    return new WPower(this.base.toOpposite(), this.exponent);
  }

  public getElementCode():string{
    return ElementCodes.TOKEN_POWER;
  }

  public getType():string {
    return 'power';
  }

}
