import { Point } from '../../../../js/geom/Point';

import { ContentElement } from '../../../elements/abstract/ContentElement';
import { FunctionElement } from '../../../elements/abstract/FunctionElement';
import { WListOfPoints } from '../../../elements/tokens/WListOfPoints';
import { WListOfSegments } from '../../../elements/tokens/WListOfSegments';
import { WPoint } from '../../../elements/tokens/WPoint';
import { WSegment } from '../../../elements/tokens/WSegment';
import { ArgumentsObject } from '../../../expr/ArgumentsObject';
import { Environment } from '../../../expr/Environment';

/**
 * Retourne le y d'un ou plusieurs couples (x, y).
 */
export class Ordinate extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 1){
      return args.expectingArguments(1, 1);
    }

    if (args.getPoint(0)) {
      return args.getPoint(0).y;
    }

    let ys:ContentElement[];

    if(args.getPoints(0)){
      ys = this.fromPoints(args.getPoints(0), args.env);
    }else if(args.getSegments(0)){
      ys = this.fromSegments(args.getSegments(0), args.env);
    }else if(args.getPolygon(0)){
      ys = this.fromVertices(args.getPolygon(0).vertices, args.env);
    }else if(args.getPolyline(0)){
      ys = this.fromVertices(args.getPolyline(0).vertices, args.env);
    }

    if(!ys){
      return null;
    }

    return args.env.culture.listFactory.createList(ys, 'real');
  }

  /**
   *
   */
  private fromPoints(value:WListOfPoints, env:Environment):ContentElement[]{
    const o:ContentElement[] = [];
    for(let i:number = 0 ; i < value.count ; i++){
      const p:WPoint = value.getTypedItemAt(i);
      o.push(p.y);
    }
    return o;
  }

  /**
   *
   */
  private fromSegments(value:WListOfSegments, env:Environment):ContentElement[]{
    const o:ContentElement[] = [];
    for(let i:number = 0 ; i < value.count ; i++){
      const s:WSegment = value.getTypedItemAt(i);
      o.push(env.culture.createNumber(s.a.y));
      o.push(env.culture.createNumber(s.b.y));
    }
    return o;
  }

  /**
   *
   */
  private fromVertices(value:Point[], env:Environment):ContentElement[]{
    const o:ContentElement[] = [];
    for(let i:number = 0 ; i < value.length ; i++){
      o.push(env.culture.createNumber(value[i].y));
    }
    return o;
  }

}
