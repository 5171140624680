import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WBoolean } from '../../elements/tokens/WBoolean';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class IsLang extends FunctionElement {

  private lang:string;

  /**
   *
   */
  constructor(lang:string){
    super();
    this.lang = lang;
  }

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    const envLang = args.env.culture.languageCode;
    if(this.lang){
      if(args.length > 0){
        return args.expectingArguments(0, 0);
      }
      return WBoolean.parse(envLang === this.lang);
    }

    if(args.length !== 1){
      return args.expectingArguments(1, 1);
    }

    if(args.getString(0)){
      return WBoolean.parse(envLang === this.validateLang(args.getString(0).getString()));
    }

    return null;
  }

  /**
   *
   */
  private validateLang(value:string):string{
    if(!value){
      return null;
    }
    if(value.length !== 2){
      return null;
    }
    return value.toLowerCase();
  }

}
