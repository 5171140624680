import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { ListElement } from '../../elements/abstract/ListElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { TokenElement } from '../../elements/abstract/TokenElement';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Search in a list and return the
 * index at which the element or
 * elements were found.
 *
 * Always return a list, is empty
 * if no occurence were found.
 *
 * This function is meant to replace
 * legacy function Find which returned
 * ordinal positions instead of indices.
 */
export class Locate extends FunctionElement {
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 2) {
      return args.expectingArguments(2, 2);
    }

    const list = args.getList(0);
    const element = args.getTokenElement(1);
    if (list && element) {
      const indices = this.findIndices(list, element);
      if (indices.length === 1) {
        return args.env.culture.createNumber(indices[0]);
      }
      return args.env.culture.listFactory.createFromNumbers(indices);
    }

    return null;
  }

  /**
   * Search one element inside a list.
   */
  private findIndices(list: ListElement, item: TokenElement): number[] {
    const indices: number[] = [];

    for (let i = 0; i < list.count; i++) {
      if (Locate.check(list.getItemAt(i), item)) {
        indices.push(i);
      }
    }

    return indices;
  }

  /**
   * Indicates whether 'a' equals 'b' or
   * 'a' is found in 'b' if 'b' is a list.
   */
  private static check(a: ContentElement, b: ContentElement): boolean {
    if (a instanceof ListElement) {
      return a.equalsTo(b);
    }
    if (b instanceof ListElement) {
      for (let k = 0; k < b.count; k++) {
        if (Locate.check(a, b.getItemAt(k))) {
          return true;
        }
      }
      return false;
    }
    const na = RealElement.parseElement(a);
    const nb = RealElement.parseElement(b);
    if (na && nb) {
      return na.toNumber() === nb.toNumber();
    }
    return a.equalsTo(b);
  }
}
