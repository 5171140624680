import { TokenElement } from '../../elements/abstract/TokenElement';

/**
 *
 */
export class WNull extends TokenElement {

  private static _instance:WNull;

  public static getInstance():WNull{
    if(!WNull._instance){
      WNull._instance = new WNull();
    }
    return WNull._instance;
  }

  /**
   *
   */
  public toText(strict:boolean):string{
    return '';
  }

  /**
   *
   */
  public getType():string {
    return 'null';
  }

}
