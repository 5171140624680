import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WStemLeafDiagram } from '../../elements/tokens/WStemLeafDiagram';
import { WString } from '../../elements/tokens/WString';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { StemLeafAttributes } from '../../funcs/stemLeaf/model/StemLeafAttributes';

/**
 *
 */
export class SetLeavesLabel extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 2){
      return args.expectingArguments(2, 2);
    }

    if (args.getStemLeafDiagram(0) && args.getString(1)) {
      return this.enable(args.getStemLeafDiagram(0), args.getString(1));
    }
    return null;
  }

  /**
   *
   */
  private enable(diagram:WStemLeafDiagram, leavesLabel:WString):WStemLeafDiagram{
    const o:StemLeafAttributes = diagram.settings.clone();
    o.leavesLabel = leavesLabel.getString();
    return new WStemLeafDiagram(diagram.values1, diagram.values2, o);
  }

}
