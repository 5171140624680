import { MathError } from '../../core/MathError';
import { XStatistics } from '../../core/XStatistics';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WList } from '../../elements/tokens/WList';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class Median extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length > 1){
      return args.expectingArguments(0, 1);
    }

    let m:number = NaN;

    if(args.length === 0){
      m = 0;
    }else if(args.length === 1){
      if(args.getReal(0)){
        return args.getReal(0);
      }
      if(args.getReals(0)){
        m = this.list(args.getReals(0));
      }else if(args.getMatrix(0)){
        m = this.groupedData(args.getMatrix(0));
      }
    }

    return isNaN(m) ? null : args.env.culture.parseNumber(m);
  }

  /**
   * Calcule la médiane d'une liste de valeurs.
   */
  private list(values:WList):number{
    return XStatistics.median(values.toNumbersV());
  }

  /**
   * Calcule la médiane d'un tableau de données groupées.
   */
  private groupedData(values:WMatrix):number{
    if(values.columns < 2){
      throw new MathError('Invalid grouped data');
    }
    let r:number;
    // xi, fi, ...
    let t:number = 0; // population
    for(r = 0 ; r < values.rows ; r++){
      t += values.valueAt(r, 1).toNumber();
    }
    let a:number;
    let b:number;
    if(t % 2 === 0){
      a = Math.floor(t / 2);
      b = a + 1;
    }else{
      a = b = Math.floor(t / 2) + 1;
    }
    let c:number = 0;
    let na:number;
    let nb:number;
    for(r = 0 ; r < values.rows ; r++){
      if(a > c){
        na = values.valueAt(r, 0).toNumber();
      }
      if(b > c){
        nb = values.valueAt(r, 0).toNumber();
      }
      c += values.valueAt(r, 1).toNumber();
    }

    return (na + nb) / 2;
  }

}
