import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { WPoint } from '../../elements/tokens/WPoint';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * 1) Redimensionne une matrice à la taille spécifiée. Si on agrandit la matrice,
 * la valeur de remplissage 0 est utilisée pour les nouvelles cellules.
 * size.x: number of lines
 * size.y: number of columns
 *
 * 2) Redimensionne une matrice à la taille spécifiée. Si on agrandit la matrice,
 * la valeur de remplissage est utilisée pour les nouvelles cellules.
 * size.x: number of lines
 * size.y: number of columns
 */
export class ResizeMatrix extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length < 2 || args.length > 3){
      return args.expectingArguments(2, 3);
    }

    const matrix:WMatrix = args.getMatrix(0);
    const newSize:WPoint = args.getPoint(1);

    let filler:RealElement;
    if(args.length > 2){
      filler = args.getReal(2);
    }else{
      filler = args.env.culture.createNumber(0);
    }

    if(matrix && newSize && filler){
      return this.resize(matrix, newSize, filler);
    }

    return null;
  }

  /**
   *
   */
  private resize(
      source:WMatrix,
      newSize:WPoint,
      filler:RealElement):WMatrix{

    const o:RealElement[] = [];

    for(let r:number = 0 ; r < newSize.x.toNumber() ; r++){
      for(let c:number = 0 ; c < newSize.y.toNumber() ; c++){
        o.push(
          r < source.rows && c < source.columns ?
          source.valueAt(r, c) :
          filler);
      }
    }

    return new WMatrix(o, newSize.y.toNumber(), source.formatter);
  }

}
