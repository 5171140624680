/**
 *
 */
export class AbstractNode {

  private _parent:AbstractNode;

  private _left:AbstractNode; // = new EmptyLeaf();

  private _right:AbstractNode; // = new EmptyLeaf();

  public get left():AbstractNode {
    return this._left;
  }

  public get right():AbstractNode {
    return this._right;
  }

  public get parent():AbstractNode {
    return this._parent;
  }

  public get isRoot():boolean {
    return this.parent == null;
  }

  public get isLeaf():boolean {
    return this._left == null && this._right == null;
  }

  public setLeft(value:AbstractNode):AbstractNode {
    this._left = value;
    value.setParent(this);

    return this;
  }

  public setRight(value:AbstractNode):AbstractNode {
    this._right = value;
    value.setParent(this);

    return this;
  }

  public setParent(value:AbstractNode):AbstractNode {
    this._parent = value;

    return this;
  }

  public getToken():any[] {
    throw Error( 'Abstact class need to override getToken.' );
  }

  public simplify():AbstractNode {
    throw Error( 'Abstact class need to override simplify.' );
  }

  public isNumber():boolean{
    return false;
  }

  public isFraction():boolean{
    return false;
  }

  public toNumber():number{
    return NaN;
  }

}
