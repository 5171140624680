import { MmlWriter } from '../../core/mml/MmlWriter';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';
import { IMarkupFactory } from '../../elements/markers/IMarkupFactory';
import { CultureInfo } from '../../localization/CultureInfo';

/**
 *
 */
export class CompareNumbersImpl implements IMarkupFactory {

  private a:number;

  private b:number;

  private _culture:CultureInfo;

  public get culture():CultureInfo{return this._culture;}

  constructor(a:number, b:number, culture:CultureInfo) {

    this.a = a;
    this.b = b;
    this._culture = culture;
  }

  public writeTo(exporter:IMarkupExporter):void{
    const w:MmlWriter = exporter.writer;

    const na:any[] = this.a.toString().split('.');
    const nb:any[] = this.b.toString().split('.');

    const ia:any[] = na[0].split('');
    const ib:any[] = nb[0].split('');

    const da:any[] = na.length > 1 ? na[1].split('') : [];
    const db:any[] = nb.length > 1 ? nb[1].split('') : [];

    while(ia.length !== ib.length){
      (ia.length < ib.length ? ia : ib).unshift('0');
    }

    while(da.length !== db.length){
      (da.length < db.length ? da : db).push('0');
    }

    w.beginTable();
    w.columnalign = 'center';
    w.columnspacing = '1px';
    w.equalcolumns = true;

    this.writeNumberRow(w, ia, da, ib, db);
    this.writeNumberRow(w, ib, db, ia, da);

    w.endTable();
  }

  /**
   * ia: integer digits for the number being written
   * da: decimal digits for the number being written
   * ib: integer digits for the other number
   * db: decimal digits for the other number
   */
  private writeNumberRow(
      w:MmlWriter,
      ia:any[],
      da:any[],
      ib:any[],
      db:any[]):void{

    let highlight:boolean = false;

    w.beginTr();

    for(let i:number = 0 ; i < ia.length ; i++){
      if(!highlight && ia[i] !== ib[i]){
        highlight = true;
        this.writeCell(w, ia[i], 'blue');
      }else if(highlight){
        this.writeCell(w, ia[i], 'gray');
      }else{
        this.writeCell(w, ia[i]);
      }
    }

    if(da.length !== 0){
      this.writeCell(w, this.culture.numberFormatter.decimalSeparator, highlight ? 'gray' : null);
      for(let d:number = 0 ; d < da.length ; d++){
        if(!highlight && da[d] !== db[d]){
          highlight = true;
          this.writeCell(w, da[d], 'blue');
        }else if(highlight){
          this.writeCell(w, da[d], 'gray');
        }else{
          this.writeCell(w, da[d]);
        }
      }
    }

    w.endTr();
  }

  /**
   *
   */
  private writeCell(w:MmlWriter, value:string, style:string = null):void{
    w.beginTd();
    w.appendText(value);

    if(style){
      w.color = style === 'blue' ? '#3388BD' : '#8D8D8D';
      if(style === 'blue'){
        w.mathvariant = 'bold';
      }
    }

    w.endTd();
  }

}
