import { XMath } from '../../core/XMath';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Lowest common denominator of two rational numbers.
 * http://en.wikipedia.org/wiki/Lowest_common_denominator
 */
export class Lcd extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 2){
      return args.expectingArguments(2, 2);
    }

    if(args.getRational(0) && args.getRational(1)){
      return args.env.culture.createNumber(XMath.lcm(args.getRational(0).denominator, args.getRational(1).denominator));
    }

    return null;
  }

}
