import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { MatrixTransform } from '../../funcs/matrices/MatrixTransform';

/**
 * Inverser horizontalement.
 */
export class FlipHorizontal extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 1){
      return args.expectingArguments(1, 1);
    }

    const matrix:WMatrix = args.getMatrix(0);
    if(!matrix){
      return null;
    }

    const transform:MatrixTransform = new MatrixTransform(args.getMatrix(0));

    return transform.flipH().getMatrix();
  }

}
