import { MathError } from '../../core/MathError';
import { XString } from '../../core/XString';
import { Prng } from '../../core/prng/Prng';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { Node } from '../../elements/abstract/Node';
import { WFormula } from '../../elements/tokens/WFormula';
import { WString } from '../../elements/tokens/WString';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Context } from '../../expr/Context';
import { Environment } from '../../expr/Environment';

/**
 *
 */
export class ByProduct extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length < 2){
      return args.expectingArguments(2, Number.POSITIVE_INFINITY);
    }
    if (args.getFormula(0) && args.getString(1)) {
      return this.value(args.getFormula(0), args.getString(1), args.args.slice(2), args.env);
    }
    return null;
  }

  /**
   *
   */
  private value(
      formula:WFormula,
      keyS:WString,
      args:ContentElement[],
      env:Environment):ContentElement{

    const context:Context =
      Context.generate(
        null,
        formula.definition.getContextModel(),
        env.culture,
        env.extensions,
        false,
        formula.definition.getArgumentsContext(args, env),
        new Prng(formula.seed),
        false);

    const key:string = keyS.getString();

    if(context.isDefined(key)){
      const node:Node = context.result(key);

      if(!node.isLeaf){
        throw new MathError(XString.substitute('{0} is not a value.', key));
      }

      return node.value;
    }

    throw new MathError(XString.substitute('{0} is undefined.', key));
  }

}
