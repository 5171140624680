import { TokenElement } from '../../elements/abstract/TokenElement';
import { WLine } from '../../elements/tokens/WLine';
import { IEval } from '../../elements/effofeks/IEval';

export class FxLine implements IEval {

  private value:WLine;

  constructor(value:WLine) {

    this.value = value;
  }

  public evalX(x:number):number{
    return this.value.map(x);
  }

  public getUnderlyingElement():TokenElement{
    return this.value;
  }

}
