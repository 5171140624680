import { CSSProperties } from 'react';
import { ColorSet } from '../../../themes/ColorSet';

export interface ITooltipTheme {
  default: {
    color: CSSProperties['color'];
    backgroundColor: CSSProperties['backgroundColor'];
    border?: CSSProperties['border'];
  };
}

export const darkTooltipTheme: ITooltipTheme = {
  default: {
    color: ColorSet.white,
    backgroundColor: ColorSet.darkness,
    border: `1px solid ${ColorSet.grey025}`,
  },
};

export const lightTooltipTheme: ITooltipTheme = { ...darkTooltipTheme };
