import { ContentElement } from '../../../elements/abstract/ContentElement';
import { FunctionElement } from '../../../elements/abstract/FunctionElement';
import { WBoolean } from '../../../elements/tokens/WBoolean';
import { ArgumentsObject } from '../../../expr/ArgumentsObject';

/**
 * Sont identiques?
 */
export class AreEquals extends FunctionElement {

  public callReturnElement(args:ArgumentsObject):ContentElement {
    if (args.length !== 2) {
      return args.expectingArguments(2, 2);
    }
    if(args.getContentElement(0) != null && args.getContentElement(1) != null){
      return WBoolean.parse(args.getContentElement(0).equalsTo(args.getContentElement(1)));
    }
    return null;
  }

}
