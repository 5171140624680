import { Point } from '../../../js/geom/Point';

import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { EdgeData } from '../../elements/models/graphs/EdgeData';
import { WGraph } from '../../elements/tokens/WGraph';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';

/**
 *
 */
export class IncidenceMatrix extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 1){
      return args.expectingArguments(1, 1);
    }

    if (args.getGraph(0)) {
      return this.matrix(args.getGraph(0), args.env);
    }

    return null;
  }

  /**
   *
   */
  private matrix(graph:WGraph, env:Environment):WMatrix{
    const n:number = graph.vertices.length;
    const m:number = graph.edges.length;

    const o:number[] = [];
    for(let i:number = 0 ; i < n * m ; i++){
      o.push(0);
    }

    for(let e:number = 0 ; e < graph.edges.length ; e++){
      const edge:EdgeData = graph.edges[e];
      const v:Point = edge.vertices;
      o[v.x * m + e] = edge.directed ? -1 : 1;
      o[v.y * m + e] = 1;
    }

    return env.culture.createMatrix(o, m);
  }

}
