import { XMath } from '../../core/XMath';
import { IntervalClosure } from '../models/IntervalClosure';
import { WBoolean } from '../tokens/WBoolean';
import { WInterval } from '../tokens/WInterval';
import { WPolynomial } from '../tokens/WPolynomial';
import { WRational } from '../tokens/WRational';
import { WRelation } from '../tokens/WRelation';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';
import { TokensImporter } from '../../expr/conversion/input/TokensImporter';
import { Divide } from '../../funcs/arithmetic/Divide';
import { Minus } from '../../funcs/arithmetic/Minus';
import { Plus } from '../../funcs/arithmetic/Plus';
import { Times } from '../../funcs/arithmetic/Times';
import { FunctionElement } from '../abstract/FunctionElement';
import { SymbolElement } from '../abstract/SymbolElement';
import { TokenElement } from '../abstract/TokenElement';
import { RealElement } from '../abstract/RealElement';
import { Attributes } from '../abstract/Attributes';
import { ContentElement } from '../abstract/ContentElement';
import { OperatorElement } from '../abstract/OperatorElement';
import { WList } from '../tokens/WList';

/**
 *
 */
export class RelationalElement extends OperatorElement {

  private _operator:string;

  constructor(operator:string){
    super();
    this._operator = operator;
  }

  /**
   * Turned to true if the last operation
   * need the operator to be reversed.
   */
  public requireReverse:boolean = false;

  /**
   *
   */
  public assignFlag:boolean = false;

  /**
   * Return the reverse operator for the current relational operator.
   * For example, the reverse of less than is greater than.
   */
  public get reverse():RelationalElement{
    return this;
  }

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 2){
      return args.expectingArguments(2, 2);
    }

    const a:ContentElement = args.getContentElement(0);
    const b:ContentElement = args.getContentElement(1);
    if(!a){
      return null;
    }
    if(!b){
      return null;
    }

    if(this.isAlmostEqualTo()){
      return null;
    }

    if(this.isEqualTo()){

      const aIsAssignable:boolean = (a.getAttributes() & Attributes.ASSIGNABLE) > 0;
      const bIsAssignable:boolean = (b.getAttributes() & Attributes.ASSIGNABLE) > 0;

      if(aIsAssignable && bIsAssignable){
        return null;
      }

      if(aIsAssignable){
        this.assignFlag = true;
        return b;
      }

      if(bIsAssignable){
        this.assignFlag = true;
        return a;
      }
    }

    const _pre:WRelation = this.preserve(a, b, args.env);
    if(_pre){
      return _pre;
    }

    const _sim:ContentElement = this.simplify(a, b, args.env);
    if(_sim || this.newArguments2){
      return _sim;
    }

    const na:WList = args.getReals(0);
    const nb:WList = args.getReals(1);

    if(na && nb && this.hasNumberComparer()){
      if(this.isNotEqualTo()){
        return this.realsAny(na, nb);
      }
      return this.realsAll(na, nb);

    }

    if(this.isEqualTo()){
      return WBoolean.parse(a.equalsTo(b));
    }
    if(this.isNotEqualTo()){
      return WBoolean.parse(!a.equalsTo(b));
    }

    const ta:TokenElement = a instanceof TokenElement ? <TokenElement>a  : null;
    const tb:TokenElement = b instanceof TokenElement ? <TokenElement>b  : null;

    this.assignFlag = true;
    if(ta && tb){
      return new WRelation(ta, this, tb);
    }
    return null;
  }

  /**
   *
   */
  private realsAll(a: WList, b: WList): ContentElement {
    if(a.count !== b.count){
      return WBoolean.FALSE;
    }

    for (let i: number = 0 ; i < a.count ; i++) {
      if (!this.compareNumbers(a.getValueAt(i), b.getValueAt(i))) {
        return WBoolean.FALSE;
      }
    }

    return WBoolean.TRUE;
  }

  private realsAny(a: WList, b: WList): ContentElement {
    if(a.count !== b.count){
      return WBoolean.TRUE;
    }

    for (let i: number = 0 ; i < a.count ; i++) {
      if (this.compareNumbers(a.getValueAt(i), b.getValueAt(i))) {
        return WBoolean.TRUE;
      }
    }

    return WBoolean.FALSE;
  }

  /**
   *
   */
  private polynomialReal(
      a:WPolynomial,
      b:RealElement,
      env:Environment):ContentElement{ // variable on left side
    this.requireReverse = false;
    if(!a.linear){
      return null;
    }
    return this.simplifyCoefficient(a, b, false, env);
  }

  /**
   *
   */
  private realPolynomial(
      a:RealElement,
      b:WPolynomial,
      env:Environment):ContentElement{ // variable on right side
    this.requireReverse = false;
    if(!b.linear){
      return null;
    }
    const r:ContentElement = this.simplifyCoefficient(b, a, true, env);
    if(this.newArguments2){
      this.newArguments2 = this.newArguments2.reverse();
    }
    return r;
  }

  /**
   *
   */
  private castPolynomial(
      value:ContentElement):WPolynomial{

    if(value instanceof WPolynomial){
      return <WPolynomial>value ;
    }

    if(value instanceof SymbolElement){
      return <WPolynomial>(<SymbolElement>value ).widen() ;
    }

    return null;
  }

  /**
   * Returns null if preserve is not enabled or
   * if one of the element is not a token.
   */
  private preserve(
      lhs:ContentElement,
      rhs:ContentElement,
      env:Environment):WRelation{
    if(!env.options.preserveRelations){
      return null;
    }
    if(!(lhs instanceof TokenElement)){
      return null;
    }
    if(!(rhs instanceof TokenElement)){
      return null;
    }
    this.assignFlag = true;
    return new WRelation(<TokenElement>lhs , this, <TokenElement>rhs );
  }

  /**
   *
   */
  private simplify(
      a:ContentElement,
      b:ContentElement,
      env:Environment):ContentElement{

    const pa:WPolynomial = this.castPolynomial(a);
    const pb:WPolynomial = this.castPolynomial(b);
    const ia:RealElement = a instanceof RealElement ? <RealElement>a  : null;
    const ib:RealElement = b instanceof RealElement ? <RealElement>b  : null;

    if(pa && pb){
      let o:ContentElement = this.simplifyDirectLinearRelations(pa, pb, env);
      if(!o && !this.newArguments2){
        o = this.simplifyConstants(pa, pb, env);
      }
      return o;
    }
    if(pa && ib){
      return this.polynomialReal(pa, ib, env);
    }
    if(ia && pb){
      return this.realPolynomial(ia, pb, env);
    }

    return null;
  }

  /**
   *
   */
  protected hasNumberComparer():boolean{
    return false;
  }

  /**
   *
   */
  protected compareNumbers(na:number, nb:number):boolean{
    throw new Error();
  }

  /**
   * a ? b
   * b ? a
   */
  private simplifyCoefficient(
      a:WPolynomial,
      b:RealElement,
      assumeReverseOperator:boolean,
      env:Environment):ContentElement{

    let c:RealElement;
    let o:FunctionElement;

    if(a.hasConstant){ // 2x+4 = 6
      c = a.constant.toAbsoluteValue();
      o = a.constant.toNumber() > 0 ? new Minus() : Plus.getInstance();
    }else{ // 2x = 6
      const coef:RealElement = a.coefs[0];
      if(coef.toNumber() !== 1){
        if(coef instanceof WRational && !XMath.isInteger(coef.toNumber())){
          c = env.culture.createNumber((<WRational>coef ).denominator);
          o = Times.getInstanceDot(); // Multiplication sign when variables are involved should always be the dot multiplication.
        }else{
          if(coef.toNumber() < 0){
            this.requireReverse = true; // If we divide both side by a negative number, we need to reverse the operator
          }
          c = coef;
          o = Divide.getInstance();
        }
      }else{
        // x = 4
        if(this.isEqualTo()){
          this.assignFlag = true;
          return b;
        }
        if(env.options.intervalInequalities &&
          (	this.isGreaterThan() ||
            this.isGreaterThanOrEqualTo() ||
            this.isLessThan() ||
            this.isLessThanOrEqualTo())){

          this.assignFlag = true;

          let positiveInfinity:boolean =
            this.isGreaterThan() ||
            this.isGreaterThanOrEqualTo();

          if(assumeReverseOperator){
            positiveInfinity = !positiveInfinity;
          }

          const open:boolean =
            this.isLessThan() ||
            this.isGreaterThan();

          const closure:IntervalClosure =
            open ?
              IntervalClosure.OPEN :
              (positiveInfinity ? IntervalClosure.CLOSED_OPEN : IntervalClosure.OPEN_CLOSED);

          return new WInterval(
            closure,
            positiveInfinity ? b : null,
            positiveInfinity ? null : b,
            env.culture.formats.intervalFormatImpl);
        }
      }
    }

    if(c && o){
      this.newArguments2 = [];
      c.tempClass = o.tempClass = 'emphasis';
      this.newArguments2.push(TokensImporter.importTokens([a.normalize(env.reals), o, c], env));
      // need to do it again because importing tokens will consume the temporary class.
      c.tempClass = o.tempClass = 'emphasis';
      this.newArguments2.push(TokensImporter.importTokens([b, o, c], env));
      return null;
    }

    return null;
  }

  /**
   *
   */
  private simplifyDirectLinearRelations(
      a:WPolynomial,
      b:WPolynomial,
      env:Environment):ContentElement{
    if(!a.linear || !b.linear){
      return null;
    }
    if(a.hasConstant || b.hasConstant){
      return null; // simplify constants first
    }

    const c:WPolynomial = b.hasNegativeCoef ? b.toOpposite() : b.clone();
    const o:FunctionElement = b.hasNegativeCoef ? Plus.getInstance() : Minus.getInstance();

    this.newArguments2 = [];

    c.tempClass = o.tempClass = 'emphasis';
    this.newArguments2.push(TokensImporter.importTokens([a, o, c], env));

    // need to do it again because importing tokens will consume the temporary class.
    c.tempClass = o.tempClass = 'emphasis';
    this.newArguments2.push(TokensImporter.importTokens([b, o, c], env));

    return null;
  }

  /**
   *
   */
  private simplifyConstants(
      a:WPolynomial,
      b:WPolynomial,
      env:Environment):ContentElement{

    if(!a.linear || !b.linear){
      return null;
    }

    // SB: If the two constant parts are 0 then this
    // function will not produce any change,
    // causing an infinite loop
    if(!a.hasConstant && !b.hasConstant){
      if(this.isEqualTo()){
        return WBoolean.parse(a.equalsTo(b));
      }
      if(this.isNotEqualTo()){
        return WBoolean.parse(!(a.equalsTo(b)));
      }
    }

    // 2x+4 = 4x+10
    // -2x = 6

    const ca:number = a.coefs[0].toNumber();
    const cb:number = b.coefs[0].toNumber();

    const o = (ca > cb && cb < 0) ||
      (ca < cb && ca < 0) ||
      (ca === cb && ca < 0) ? Plus.getInstance() : Minus.getInstance();

    const m:WPolynomial = ca > cb ? b.clone() : a.clone();

    m.coefs[0] = m.coefs[0].toAbsoluteValue();

    // This remove the constant part leaving only one monomial
    const c = env.polynomials.addR(m, m.constant.toOpposite()).normalize(env.reals);

    if(c instanceof SymbolElement || c instanceof WPolynomial){
      if(o){
        this.newArguments2 = [];

        c.tempClass = o.tempClass = 'emphasis';
        this.newArguments2.push(TokensImporter.importTokens([a.normalize(env.reals), o, c], env));

        // need to do it again because importing tokens will consume the temporary class.
        c.tempClass = o.tempClass = 'emphasis';
        this.newArguments2.push(TokensImporter.importTokens([b.normalize(env.reals), o, c], env));

        return null;
      }
    }

    return null;
  }

  public isEqualTo():boolean{return this._operator === '=';}

  public isNotEqualTo():boolean{return this._operator === '≠';}

  public isAlmostEqualTo():boolean{return this._operator === '≈';}

  public isLessThan():boolean{return this._operator === '<';}

  public isLessThanOrEqualTo():boolean{return this._operator === '≤';}

  public isGreaterThan():boolean{return this._operator === '>';}

  public isGreaterThanOrEqualTo():boolean{return this._operator === '≥';}

  public toString():string{
    return this._operator;
  }

  public getOperator():string{
    return this._operator;
  }

  public getElementCode():string{
    return this._operator;
  }

}
