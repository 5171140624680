import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';
import { IMarkupFactory } from '../../elements/markers/IMarkupFactory';
import { WPolynomial } from '../../elements/tokens/WPolynomial';
import { FactorizeUtil } from '../../funcs/factorization/utils/FactorizeUtil';
import { CultureInfo } from '../../localization/CultureInfo';

/**
 *
 */
export class FactorizeDetailDoubleImpl implements IMarkupFactory {

  private polynomial:WPolynomial;

  private factorizeUtil:FactorizeUtil;

  constructor(
      polynomial:WPolynomial,
      factorizeUtil:FactorizeUtil) {

    this.polynomial = polynomial;
    this.factorizeUtil = factorizeUtil;
  }

  public get culture():CultureInfo{
    return this.polynomial.formatter.culture;
  }

  public writeTo(exporter:IMarkupExporter):void{
    if(this.polynomial.numMonomials === 4){
      this.factorizeUtil.writeTo(exporter, this.factorizeUtil.double(this.polynomial));
    }
  }

}
