import { MathError } from '../../core/MathError';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WTimeOfDay } from '../../elements/tokens/WTimeOfDay';
import { WTimeSpan } from '../../elements/tokens/WTimeSpan';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class AddTime extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 2){
      return args.expectingArguments(2, 2);
    }

    if (args.getTimeOfDay(0) && args.getTimeOfDay(1)) {
      return this.timeOfDayList(args.getTimeOfDay(0), args.getTimeOfDay(1));
    }
    if (args.getTimeOfDay(0) && args.getTimeSpan(1)) {
      return this.timeSpan(args.getTimeOfDay(0), args.getTimeSpan(1));
    }
    if (args.getTimeSpan(0) && args.getTimeSpan(1)) {
      return this.spanSpan(args.getTimeSpan(0), args.getTimeSpan(1));
    }
    return null;
  }

  /**
   *
   */
  private timeOfDayList(
      a:WTimeOfDay,
      b:WTimeOfDay):WTimeOfDay{

    const s:number = a.totalSeconds + b.totalSeconds;

    if(s >= WTimeOfDay.SECONDS_PER_DAY){
      throw new MathError('Time of day overflow');
    }

    return WTimeOfDay.parseSeconds(s, a.formatter);
  }

  /**
   *
   */
  private timeSpan(
      a:WTimeOfDay,
      b:WTimeSpan):WTimeOfDay{

    const s:number =
      a.totalSeconds +
      b.totalSeconds;

    if(s >= WTimeOfDay.SECONDS_PER_DAY){
      throw new MathError('Time of day overflow');
    }

    return WTimeOfDay.parseSeconds(s, a.formatter);
  }

  /**
   *
   */
  private spanSpan(
      a:WTimeSpan,
      b:WTimeSpan):WTimeSpan{

    return new WTimeSpan(a.ms + b.ms);
  }

}
