import { createEditor, withReact } from '../TypedSlate';
import { IPlugin } from '../models';
import { withLinks } from './withLinks';
import { withTTS } from './withTTS';
import { withImages } from './withImages';
import { withMarkup } from './withMarkup';
import { withLists } from './withLists';
import { withKeyboard } from './withKeyboard';

const plugins: ReadonlyArray<IPlugin> = [
  withReact,
  withLinks,
  withTTS,
  withImages,
  withMarkup,
  withLists,
  withKeyboard,
];

export const createEditorWithPlugins = () => {
  let editor = createEditor();
  plugins.forEach((plugin) => {
    editor = plugin(editor);
  });
  return editor;
};
