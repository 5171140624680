import { action } from 'mobx';
import { inject } from '../dependencyInjection/inject';
import { ViewModel } from '../dependencyInjection/ViewModel';
import { UserSessionStore } from '../models/store/UserSessionStore';
import { IVariant } from '../models/IVariant';
import { IVariantMetadata } from '../models/IVariantMetadata';
import { VariantStore } from '../models/store/VariantStore';

@ViewModel
export class VariantMediator {
  @inject(VariantStore)
  private variantStore: VariantStore;

  @inject(UserSessionStore)
  private readonly userSessionStore: UserSessionStore;

  constructor() {
    if (process.env.NO_AUTHENTICATION) {
      this.variantStore.useLocalVariants();
    }
  }

  public get variants(): ReadonlyArray<IVariant> {
    return this.variantStore.variantsList;
  }

  @action
  public changeDescription = (variant: IVariant, newDescription: string) => {
      variant.metadata.description = newDescription;
      this.updateMetadata(variant.name, variant.metadata);
    }

  @action
  public toggleObsolete = (variant: IVariant) => {
      variant.metadata.isObsolete = !variant.metadata.isObsolete;
      this.updateMetadata(variant.name, variant.metadata);
    }

  @action
  public createVariant = (variant: IVariant) => {
      variant.metadata.creationDateISO = (new Date()).toISOString();
      variant.metadata.creatorId = this.userSessionStore.memberInfo.id;
      this.variantStore.createVariant(variant.name, variant.metadata);
    }

  public getVariantMetadata = (variantName: string) => {
    return this.variantStore.variantsMap[variantName];
  }

  private updateMetadata = (variantName: string, metadata: IVariantMetadata) => {
    metadata.modificationDateISO = (new Date()).toISOString();
    metadata.modificatorId = this.userSessionStore.memberInfo.id;
    this.variantStore.updateVariant(variantName, metadata);
  }
}
