import React from 'react';
import { observer } from 'mobx-react';
import {
  SortOrder,
} from '@scolab/publisher-ui-kit';
import { inject } from '../../../dependencyInjection/inject';
import { ContentTagsMediator } from '../../../mediators/ContentTagsMediator';
import { SortCriteria } from '../../SortButton';
import { IContentTag } from '../../../models/IContentTag';
import { alphabeticSort, dateSort } from './sortUtils';
import { AccessAndRightsMediator } from '../../../mediators/AccessAndRightsMediator';
import { SELECT_ALL_ID } from '../../subject/SubjectSelector';
import { StringUtils } from '../../../utils/StringUtils';
import {
  DEFAULT_FILTERS_AND_SORT,
  IFilterAndSortState,
} from './FilterAndSortingControls';
import { TagEditionModal } from './modals/TagEditionModal';
import { TagSelectionModal } from './modals/TagSelectionModal';
import { TagMergingModal } from './modals/TagMergingModal';

export interface IContentTagManagerModalProps {
  isOpen: boolean;
  onClose: () => void;
}

enum ModalState {
  EditingTags = 'EditingTags',
  ChoosingTagsToMerge = 'ChoosingTagsToMerge',
  MergingTags = 'MergingTags',
}

interface IContentTagManagerModalState {
  filterAndSortState: IFilterAndSortState;
  modalState: ModalState;
  selectedContentTagIds: ReadonlyArray<number>;
}

@observer
export class ContentTagManagerModal extends React.Component<IContentTagManagerModalProps, IContentTagManagerModalState> {

  @inject(ContentTagsMediator)
  private contentTagsMediator: ContentTagsMediator;

  @inject(AccessAndRightsMediator)
  private accessAndRightsMediator: AccessAndRightsMediator;

  constructor(props: IContentTagManagerModalProps) {
    super(props);
    this.state = {
      filterAndSortState: DEFAULT_FILTERS_AND_SORT,
      modalState: ModalState.EditingTags,
      selectedContentTagIds: [],
    };
  }

  public render(): React.ReactNode {
    const {
      isOpen,
    } = this.props;
    const {
      filterAndSortState,
      modalState,
      selectedContentTagIds,
    } = this.state;
    if (!isOpen || !this.accessAndRightsMediator.canManageContentTags) {
      return null;
    }

    switch (modalState) {
      case ModalState.EditingTags:
        return (
          <TagEditionModal
            onClose={this.onClose}
            filterAndSortState={filterAndSortState}
            sortedContentTags={this.sortedContentTags}
            onChangeFilterAndSort={this.onChangeFilterAndSort}
            onStartMerge={this.goToNextModalState}
          />
        );
      case ModalState.ChoosingTagsToMerge:
        return (
          <TagSelectionModal
            filterAndSortState={filterAndSortState}
            sortedContentTags={this.sortedContentTags}
            selectedContentTagIds={selectedContentTagIds}
            onChangeFilterAndSort={this.onChangeFilterAndSort}
            onChangeSelection={this.onChangeSelection}
            onCancel={this.goToPreviousModalState}
            onConfirm={this.goToNextModalState}
          />
        );
      case ModalState.MergingTags:
        return (
          <TagMergingModal
            selectedContentTagIds={selectedContentTagIds}
            onChangeSelection={this.onChangeSelection}
            onCancel={this.goToPreviousModalState}
            onConfirm={this.onMergeTags}
          />
        );
    }
  }

  private onClose = () => {
    this.setState({
      filterAndSortState: DEFAULT_FILTERS_AND_SORT,
      modalState: ModalState.EditingTags,
      selectedContentTagIds: [],
    });
    this.props.onClose();
  }

  private onChangeFilterAndSort = (filterAndSortState: IFilterAndSortState) => {
    this.setState({
      filterAndSortState,
    });
  }

  private goToNextModalState = () => {
    const { modalState } = this.state;
    switch (modalState) {
      case ModalState.EditingTags: this.setModalState(ModalState.ChoosingTagsToMerge); break;
      case ModalState.ChoosingTagsToMerge: this.setModalState(ModalState.MergingTags); break;
    }
  }

  private goToPreviousModalState = () => {
    const { modalState } = this.state;
    switch (modalState) {
      case ModalState.ChoosingTagsToMerge: this.setModalState(ModalState.EditingTags); break;
      case ModalState.MergingTags: this.setModalState(ModalState.ChoosingTagsToMerge); break;
    }
  }

  private onChangeSelection = (contentTag: IContentTag, isChecked: boolean) => {
    const { selectedContentTagIds } = this.state;
    this.setState({
      selectedContentTagIds: isChecked ?
        selectedContentTagIds.concat(contentTag.id) :
        selectedContentTagIds.filter((id) => id !== contentTag.id),
    })
  }

  private setModalState = (newModalState: ModalState) => {
    this.setState({
      modalState: newModalState,
      selectedContentTagIds: newModalState === ModalState.EditingTags ? [] : this.state.selectedContentTagIds,
    })
  }

  private onMergeTags = (subjectId: number, nameFr: string, description: string) => {
    this.contentTagsMediator.mergeTags(this.state.selectedContentTagIds, subjectId, nameFr, description);
    this.setState({
      selectedContentTagIds: [],
      modalState: ModalState.EditingTags,
    });
  }

  private get filteredContentTags(): ReadonlyArray<IContentTag> {
    const { searchFilter, subjectFilter } = this.state.filterAndSortState;
    const sanitizedSearch = StringUtils.normalize(searchFilter.toLowerCase());
    return this.contentTagsMediator.contentTags.filter((contentTag) =>
      filterNames(contentTag, sanitizedSearch) &&
      filterSubjects(contentTag, subjectFilter)
    );
  }

  private get sortedContentTags(): ReadonlyArray<IContentTag> {
    const { sortOrder, sortCriteria } = this.state.filterAndSortState;
    const sortFn = sortCriteria === SortCriteria.alphabetic ? alphabeticSort : dateSort;
    const sortedList = this.filteredContentTags.concat().sort(sortFn);
    if (sortOrder === SortOrder.descending) {
      sortedList.reverse();
    }
    return sortedList;
  }
}

const filterNames = (contentTag: IContentTag, sanitizedSearch: string) =>
  includesFilter(contentTag.name.fr, sanitizedSearch) ||
  includesFilter(contentTag.description, sanitizedSearch)

const filterSubjects = (contentTag: IContentTag, subjectId: number) =>
  subjectId === SELECT_ALL_ID ||
  contentTag.subjectId === subjectId;

const includesFilter = (text: string, filter: string) => {
  if (!text) return false;
  return StringUtils.normalize(text.toLowerCase()).includes(filter);
}
