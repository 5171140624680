import React, { AnimationEvent } from 'react';
import styled, { keyframes } from 'styled-components';
import { SNACKBAR_ZINDEX } from '../../themes/zIndex';

export interface ISnackbar {
  readonly open: boolean;
  readonly autoHideDuration: number;
  readonly onClose: (event: AnimationEvent<HTMLDivElement>) => void;
}
export const Snackbar: React.FC<ISnackbar> = (props) => {

  const animationEnd = (event: AnimationEvent<HTMLDivElement>) => {
    if (event.animationName === Hide.getName()) {
      props.onClose(event);
    }
  }

  return (
    <SnackbarDiv
      open={props.open}
      autoHideDuration={props.autoHideDuration}
      onAnimationEnd={animationEnd}
    >
      {props.children}
    </SnackbarDiv>
  );
}


const Show = keyframes`
  from {bottom: 0; opacity: 0;}
  to {bottom: 24px; opacity: 1;}
`;

const Hide = keyframes`
  from {bottom: 24px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
`;

interface ISnackbarProps {
  readonly open: boolean;
  readonly autoHideDuration: number;
}

const SnackbarDiv = styled.div<ISnackbarProps>`
  animation: ${Show} 0.5s, ${Hide} 0.5s ${(props: ISnackbarProps) => props.autoHideDuration}ms;
  bottom: 24px;
  color: #000;
  left: 50%;
  position: fixed;
  right: auto;
  transform: translateX(-50%);
  visibility: ${(props: ISnackbarProps) => props.open ? 'visible' : 'hidden'};
  z-index: ${SNACKBAR_ZINDEX};
`;
