import React from 'react';
import styled from 'styled-components';
import { Tooltip } from '../../../utils/Tooltip/Tooltip';

export interface IIconContainerProps {
  label: string;
  Icon: React.ReactNode;
  className?: string;
}

export const IconContainer: React.FC<IIconContainerProps> = (props) => {
  const {
    children,
    Icon,
    label,
    className,
  } = props;
  return (
    <Container
      className={className}
    >
      <Tooltip
        title={label}
      >
        <IconWrapper>
          {Icon}
        </IconWrapper>
      </Tooltip>
      <ChildContainer>
        {children}
      </ChildContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const ChildContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  flex: 1;
`;

const IconWrapper = styled.span`
  cursor: help;
  margin-top: 8px;
  flex-shrink: 0;

  & svg {
    height: 24px;
    width: 24px;
    color: ${props => props.theme.colorset.grey10};
  }
`;
