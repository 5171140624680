import {
  Path,
  Point,
  Range,
  RangeRef,
  Text,
  Node,
  Element,
  Editor,
  Transforms,
  BaseEditor,
  BaseElement,
  BaseRange,
  BasePoint,
  Descendant,
  NodeEntry,
  EditorInterface,
  ElementInterface,
  Selection,
  createEditor,
} from 'slate';
import {
  ReactEditor,
  Editable,
  Slate,
  DefaultPlaceholder,
  RenderPlaceholderProps,
  RenderLeafProps,
  RenderElementProps,
  withReact,
  useSlate,
  useSelected,
} from 'slate-react';
import { ICustomEditor, ICustomEditorInterface, ICustomElementInterface } from './models/editor';

const TypedElement = Element as ICustomElementInterface;
const TypedEditor = Editor as ICustomEditorInterface;
const TypedCreateEditor = createEditor as () => ICustomEditor;
const TypedUseSlate = useSlate as () => ICustomEditor;

export {
  Path,
  Point,
  Range,
  RangeRef,
  Text,
  Node,
  TypedElement as Element,
  TypedEditor as Editor,
  Transforms,
  TypedCreateEditor as createEditor,
  ReactEditor,
  Editable,
  Slate,
  DefaultPlaceholder,
  withReact,
  TypedUseSlate as useSlate,
  useSelected,
};

export type {
  Selection,
  BaseEditor,
  BaseElement,
  BaseRange,
  BasePoint,
  Descendant,
  NodeEntry,
  EditorInterface,
  ElementInterface,
  RenderPlaceholderProps,
  RenderLeafProps,
  RenderElementProps,
};
