import { BaseRationalFormatter } from '../../../elements/formats/BaseRationalFormatter';
import { NumberWordsFormatter } from '../../../elements/formats/numbers/NumberWordsFormatter';
import { IMarkupExporter } from '../../../elements/markers/IMarkupExporter';
import { CultureInfo } from '../../../localization/CultureInfo';

/**
 *
 */
export class WriteFractionFrFormatter extends BaseRationalFormatter {

  /**
   *
   */
  constructor(culture:CultureInfo){
    super(culture);
  }

  /**
   *
   */
  public toLocaleString(numerator:number, denominator:number):string{
    const numberImpl:NumberWordsFormatter = new NumberWordsFormatter(this.culture);

    const value:number = numerator / denominator;
    let n:number = Math.abs(numerator);
    const d:number = Math.abs(denominator);

    if(n % d === 0){
      return numberImpl.toLocaleString(value);
    }

    const o:string[] = [];

    if(value < 0){
      o.push('moins ');
    }

    if(n > d){
      o.push(numberImpl.toLocaleString(Math.floor(n / d)));
      o.push(' et ');
    }

    n %= d;
    o.push(numberImpl.toLocaleString(n));

    o.push(' ');

    let plur:boolean = true; // allow pluralize
    let s:string;
    switch(d){
      case 2:
        s = 'demi';
        break;
      case 3:
        plur = false;
        s = 'tiers';
        break;
      case 4:
        s = 'quart';
        break;
      default:
        s = numberImpl.toLocaleString(d);
        const _d:string = d.toString();
        switch(Number(_d.charAt(_d.length - 1))){
          case 1:
          case 2:
          case 3:
          case 6:
          case 7:
          case 8:
            s += 'ième';
            break;
          case 4:
            s = `${s.substring(0, s.length - 1)}ième`;
            break;
          case 5:
            s += 'uième';
            break;
          case 9:
            s = `${s.substring(0, s.length - 1)}vième`;
            break;
          case 0:

            switch(Number(_d.substring(_d.length - 2, _d.length))){
              case 10:
              case 20:
              case 70:
              case 80:
              case 90:
              case 0:
                s += 'ième';
                break;
              case 30:
              case 40:
              case 50:
              case 60:
                s = s.substring(0, s.length - 1) + 'ième';
                break;
            }
            if(s.substring(s.length - 5, s.length) === 'eième'){
              s = `${s.substring(0, s.length - 5)}ième`;
            }
            break;
        }
        break;
    }
    s = s.split('eième').join('ième');
    s = s.split('euième').join('ième');
    s = s.split('vingtsième').join('vingtième');
    if(n > 1 && plur){
      s += 's';
    }
    o.push(s);
    return o.join('');
  }

  /**
   *
   */
  public writeTo(exporter:IMarkupExporter, numerator:number, denominator:number):boolean{
    return false;
  }

  /**
   *
   */
  public equals(other:BaseRationalFormatter):boolean{
    return other instanceof WriteFractionFrFormatter;
  }

}
