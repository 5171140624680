import { IContextModel } from '@scolab/content-model';
import { InlineContext } from '../../../expr/InlineContext';

export class PolygonsList extends InlineContext {

  /**
   *
   */
  constructor(){
    super('PolygonsList', (<string[]>['s', 'p']), 'r');
    // s: number of sides
    // p: plural
  }

  /**
   *
   */
  public getContextModel():IContextModel{
    const o:IContextModel = {
      variables: [{
        type: 'texts',
        texts: {
          useList: true,
          values: [
            {
              value: 'triangles équilatéraux'
            }, {
              value: 'carrés'
            }, {
              value: 'pentagones réguliers'
            }, {
              value: 'hexagones réguliers'
            }, {
              value: 'heptagones réguliers'
            }, {
              value: 'octogones réguliers'
            }, {
              value: 'ennéagones réguliers'
            }, {
              value: 'décagones réguliers'
            }, {
              value: 'hendécagones réguliers'
            }, {
              value: 'dodécagones réguliers'
            }
          ]
        },
        id: 'pluralFr'
      }, {
        type: 'texts',
        texts: {
          useList: true,
          values: [
            {
              value: 'equilateral triangles'
            }, {
              value: 'squares'
            }, {
              value: 'regular pentagons'
            }, {
              value: 'regular hexagons'
            }, {
              value: 'regular heptagons'
            }, {
              value: 'regular octagons'
            }, {
              value: 'regular nonagons'
            }, {
              value: 'regular decagons'
            }, {
              value: 'regular hendecagons'
            }, {
              value: 'regular dodecagons'
            }
          ]
        },
        id: 'pluralEn'
      }, {
        type: 'texts',
        texts: {
          useList: true,
          values: [
            {
              value: 'triangle équilatéral'
            }, {
              value: 'carré'
            }, {
              value: 'pentagone régulier'
            }, {
              value: 'hexagone régulier'
            }, {
              value: 'heptagone régulier'
            }, {
              value: 'octogone régulier'
            }, {
              value: 'ennéagone régulier'
            }, {
              value: 'décagone régulier'
            }, {
              value: 'hendécagone régulier'
            }, {
              value: 'dodécagone régulier'
            }
          ]
        },
        id: 'singularFr'
      }, {
        type: 'texts',
        texts: {
          useList: true,
          values: [
            {
              value: 'equilateral triangle'
            }, {
              value: 'square'
            }, {
              value: 'regular pentagon'
            }, {
              value: 'regular hexagon'
            }, {
              value: 'regular heptagon'
            }, {
              value: 'regular octagon'
            }, {
              value: 'regular nonagon'
            }, {
              value: 'regular decagon'
            }, {
              value: 'regular hendecagon'
            }, {
              value: 'regular dodecagon'
            }
          ]
        },
        id: 'singularEn'
      }, {
        value: 'if(isfr(), if(p, pluralFr, singularFr), if(p, pluralEn, singularEn))',
        id: 'select'
      }, {
        value: 'select_(s-3)',
        id: 'r'
      }
      ]
    };

    return o;
  }

}
