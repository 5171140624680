import React from 'react';
import { ITreeDataDescriptor } from './ITreeDataDescriptor';

export interface ITreeContext {
  dataDescriptor: ITreeDataDescriptor<any>;
  nodeRenderer: (node: any, isOpen: boolean, isSelected: boolean) => JSX.Element;
  isOpen: (nodeId: string) => boolean;
  isSelected: (nodeId: string) => boolean;
  isChecked: (nodeId: string) => boolean;
  isPartiallyChecked: (nodeId: string) => boolean;
  insertNodeEnabled: boolean;
  insertButtonRenderer: (parentNode: any, onClick: () => void) => JSX.Element;
  onInsertChild: (parentNode: any) => void;
  checkboxVisible: boolean;
  dragEnabled: boolean;
  dropEnabled: boolean;
  onDropAction: TTreeDropAction; // when the drop occurs from outside the tree
  onMoveAction: TTreeMoveAction; // when the drop occurs from within the same tree
}

export interface INodeDropInfo<TNode = any> {
  nodeId: string;
  node: TNode;
  ancestors: ReadonlyArray<TNode>;
}

export type TDropPreviewAction = null | 'insertAfter' | 'insertAsFirstChild' | 'insertAt';
export type TTreeMoveAction = (draggedNode: INodeDropInfo, dropTarget: INodeDropInfo) => TDropPreviewAction;
export type TTreeDropAction = (dragId: string, dropId: string) => TDropPreviewAction;

export const TreeContext = React.createContext<ITreeContext>(null);
