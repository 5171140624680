import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { CurrencyInfo } from '../../localization/CurrencyInfo';

/**
 * Billets.
 */
export class Bills extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    args.expectingArguments(0, 1);
    if(args.length > 1){
      return null;
    }

    let currency:CurrencyInfo = args.env.culture.currency;

    if(args.length > 0){
      const currencyName =
        args.getStringEnumValue(
          0,
          CurrencyInfo.names,
          true);
      if(currencyName){
        currency = CurrencyInfo.parse(currencyName.getString());
      }else{
        return null;
      }
    }

    if(!currency){
      return null;
    }

    return args.env.culture.listFactory.createFromArray(currency.bills);
  }

}
