import { TokenElement } from '../../elements/abstract/TokenElement';

/**
 *
 */
export class TokensList {

  /**
   *
   */
  private items:TokenElement[];

  /**
   *
   */
  constructor(items:TokenElement[]) {

    this.items = items;
  }

  /**
   *
   */
  public getIntersection(tokens:TokensList):TokensList{
    const u:TokenElement[] = [];
    const temp:TokenElement[] = this.items.concat();

    for(let i:number = 0 ; i < tokens.items.length ; i++){
      let k:number = 0;
      while(k < temp.length){
        if(tokens.items[i].equalsTo(temp[k])){
          u.push(tokens.items[i]);
          temp.splice(k, 1);
          break;
        }
        k++;
      }
    }

    return new TokensList(u);
  }

  /**
   *
   */
  public getDifference(tokens:TokensList):TokensList{
    const u:TokenElement[] = [];
    const temp:TokenElement[] = tokens.items.concat();

    for(let i:number = 0 ; i < this.items.length ; i++){
      let k:number = 0;
      let c:boolean = false;
      while(k < temp.length){
        if(this.items[i].equalsTo(temp[k])){
          temp.splice(k, 1);
          c = true;
          break;
        }
        k++;
      }
      if(!c){
        u.push(this.items[i]);
      }
    }

    return new TokensList(u);
  }

  /**
   *
   */
  public getUnion(tokens:TokensList):TokensList{
    const u:TokenElement[] = this.items.concat();

    for(let i:number = 0 ; i < tokens.items.length ; i++){
      u.push(tokens.items[i]);
    }

    return new TokensList(u);
  }

  /**
   *
   */
  public getDistincts():TokensList{
    const o:TokenElement[] = this.items.concat();
    let i:number = 0;
    while(i < o.length){
      let k:number = i + 1;
      while(k < o.length){
        if(o[i].equalsTo(o[k])){
          o.splice(k, 1);
        }else{
          k++;
        }
      }
      i++;
    }
    return new TokensList(o);
  }

  /**
   *
   */
  public getItems():TokenElement[]{
    return this.items;
  }

}
