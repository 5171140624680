import React from 'react';
import styled from 'styled-components';
import { LoadScriptOnce } from '../../../utils/ScriptLoadingUtils';

const SCRIPT = 'https://cdn.jsdelivr.net/npm/mathjax@3.1/es5/mml-chtml.js';

export interface IMathJax3ImplProps extends React.HTMLAttributes<HTMLSpanElement> {
  readonly math: string;
  readonly onRef?: (element: HTMLSpanElement) => void;
}

export class MathJax3Impl extends React.PureComponent<IMathJax3ImplProps> {

  private container: HTMLSpanElement;

  private isUpdatingSource: boolean;

  private currentlyDisplayedValue: string;

  private nextValueToDisplay: string;

  constructor(props: IMathJax3ImplProps) {
    super(props);
    this.isUpdatingSource = false;
    this.currentlyDisplayedValue = '';
    this.nextValueToDisplay = '';
  }

  public componentDidMount(): void {
    this.nextValueToDisplay = this.props.math;
    LoadScriptOnce.loadScript(SCRIPT, this.updateSource);
  }

  public componentDidUpdate(prevProps: IMathJax3ImplProps): void {
    this.nextValueToDisplay = this.props.math;
    this.updateSource();
  }

  public render(): JSX.Element {
    const {
      math,
      onRef,
      ...otherProps
    } = this.props;
    return (
      <Container
        {...otherProps}
        ref={this.onRef}
      />
    );
  }

  private onRef = (element: HTMLDivElement) => {
    this.container = element;
    this.props.onRef?.(element);
  }

  private swapSource = (value: string) => {
    if (!window.MathJax.typesetPromise) { // Pale Moon doesn't wait long enough for MathJax to be fully loaded
      requestAnimationFrame(() => this.swapSource(value));
      return;
    }

    this.isUpdatingSource = true;
    const span = document.createElement('span');
    span.innerHTML = value;

    window.MathJax.typesetPromise([span]).then(() => {
      if (!this.container) {
        return; // The node was removed from the DOM before MathJax could process it
      }
      const currentChild = this.container.childNodes[0];
      if (currentChild) {
        this.container.replaceChild(span, currentChild);
      } else {
        this.container.appendChild(span);
      }

      this.currentlyDisplayedValue = value;
      this.isUpdatingSource = false;
      this.updateSource();
    });
  }

  private updateSource = () => {
    if (!this.isUpdatingSource &&
      this.currentlyDisplayedValue !== this.nextValueToDisplay &&
      LoadScriptOnce.isScriptLoaded(SCRIPT)) {
      this.swapSource(this.nextValueToDisplay);
    }
  }

}

const Container = styled.span`
  overflow: hidden;
`;
