import { OperationNode } from '../../../funcs/expr/model/OperationNode';
import { BaseNode } from '../../../funcs/expr/model/BaseNode';

/**
 *
 */
export class ValueNode extends BaseNode {

  private _value:number;

  public get value():number{
    return this._value;
  }

  public set value(n:number){
    this._value = n;
  }

  constructor(value:number) {

    super();
    this.value = value;
  }

  public toString():string{
    return this.value.toString();
  }

  public values(value:any[]):void{
    value.push(this);
  }

  public get isPower():boolean{
    if(this.parent instanceof OperationNode){
      const op:OperationNode = <OperationNode>this.parent ;
      if(op.operator === '^' && op.right === this){
        return true;
      }
    }
    return false;
  }

  public get isBase():boolean{
    if(this.parent instanceof OperationNode){
      const op:OperationNode = <OperationNode>this.parent ;
      if(op.operator === '^' && op.left === this){
        return true;
      }
    }
    return false;
  }

  public get isDivisor():boolean{
    if(this.parent instanceof OperationNode){
      const op:OperationNode = <OperationNode>this.parent ;
      if(op.operator === '÷' && op.right === this){
        return true;
      }
    }
    return false;
  }

  public get isSubstracted():boolean{
    if(this.parent instanceof OperationNode){
      const op:OperationNode = <OperationNode>this.parent ;
      if(op.operator === '-' && op.right === this){
        return true;
      }
    }
    return false;
  }

}
