export class StringPipe {

  private next:StringPipe;

  public setNext(value:StringPipe):StringPipe{
    this.next = value;
    return value;
  }

  public process(value:string):string{
    return this.next ? this.next.process(value) : value;
  }

}
