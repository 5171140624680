import { ContentElement } from '../../elements/abstract/ContentElement';
import { TokenElement } from '../../elements/abstract/TokenElement';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';

/**
 * Wrapper class for Boolean primitive datatype.
 */
export class WBoolean extends TokenElement {

  public static TRUE:WBoolean = new WBoolean(true);

  public static FALSE:WBoolean = new WBoolean(false);

  private value:boolean;

  /**
   *
   */
  constructor(value:boolean) {

    super();
    this.value = value;
  }

  /**
   *
   */
  public toBoolean():boolean{
    return this.value;
  }

  /**
   *
   */
  public equalsTo(value:ContentElement):boolean{
    if(value instanceof WBoolean){
      return (<WBoolean>value ).toBoolean() === this.toBoolean();
    }
    return false;
  }

  /**
   *
   */
  public writeTo(exporter:IMarkupExporter = null):boolean{
    if(exporter){
      exporter.writer.appendText(String(this.value));
    }
    return true;
  }

  /**
   *
   */
  public toText(strict:boolean):string{
    return String(this.value);
  }

  /**
   *
   */
  public static parse(value:boolean):WBoolean{
    return value ? WBoolean.TRUE : WBoolean.FALSE;
  }

  /**
   *
   */
  public static parseElement(element:ContentElement):WBoolean{
    return element instanceof WBoolean ? <WBoolean>element  : null;
  }

  public getType():string {
    return 'boolean';
  }

}
