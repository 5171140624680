import { RealElement } from '../../elements/abstract/RealElement';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';
import { CultureInfo } from '../../localization/CultureInfo';
import { AbstractFormatter } from '../../elements/formats/AbstractFormatter';

/**
 *
 */
export class BaseMatrixFormatter extends AbstractFormatter {

  /**
   *
   */
  constructor(culture:CultureInfo){
    super(culture);
  }

  /**
   *
   */
  public writeTo(
      exporter:IMarkupExporter,
      values:RealElement[],
      columns:number):boolean{
    throw new Error();
  }

}
