import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WRational } from '../../elements/tokens/WRational';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Numérateur.
 * Always return a positive value by default.
 */
export class Numerator extends FunctionElement {

  /**
   * Fix for https://github.com/scolab/content/issues/219
   * Return the polynomial for same behavior as for int.
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 1){
      return args.expectingArguments(1, 1);
    }

    if(args.getRational(0)){
      const rational:WRational = args.getRational(0);
      return args.env.culture.createNumber(
        rational.formatter.isMixed() ?
          Math.abs(rational.numerator % rational.denominator) :
          Math.abs(rational.numerator));
    }
    if(args.getReal(0)){
      const n:RealElement = args.getReal(0);
      return n.toAbsoluteValue();
    }
    if(args.getPolynomial(0)){
      return args.getPolynomial(0);
    }
    if(args.getFraction(0)){
      return args.getFraction(0).numerator;
    }

    return null;
  }

}
