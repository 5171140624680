import { Match } from '../core/Match';
import { XString } from '../core/XString';
import { ContentElement } from '../elements/abstract/ContentElement';
import { Node } from '../elements/abstract/Node';
import { RealElement } from '../elements/abstract/RealElement';
import { WEulerConstant } from '../elements/tokens/WEulerConstant';
import { WLog } from '../elements/tokens/WLog';
import { WNumber } from '../elements/tokens/WNumber';
import { IWriter } from '../expr/conversion/writers/IWriter';
import { OldWriterFacade } from '../expr/conversion/writers/OldWriterFacade';
import { InputCapabilities } from './InputCapabilities';
import { KeyboardConfiguration } from './KeyboardConfiguration';
import { CommonError } from './CommonError';
import { BaseCorrector } from './BaseCorrector';

/**
 *
 */
export class CLog extends BaseCorrector {

  /**
   *
   */
  public parse(value:string):Node{
    const log:WLog = this.parseLog(this.translateInput(value));
    if(log){
      const node:Node = new Node(log);
      node.userData =
        log.displayBase ?
          'Log(' + String(log.n.toNumber()) + ', ' + String(log.base.toNumber()) + ')' :
          'Log(' + String(log.n.toNumber()) + ')';

      node.userData += '@8192'; // preserve logs
      return node;
    }
    return null;
  }

  /**
   *
   */
  public correct(
      value:string,
      target:ContentElement,
      ...targets:any[]):boolean{

    const target2:WLog = <WLog>target ;
    const value2:WLog = this.parseLog(this.translateInput(value));
    if(value2){
      return value2.equalsTo(target2);
    }
    this.raiseError(CommonError.LOGARITHM_FORMAT);
    return false;
  }

  /**
   *
   */
  private translateInput(valueArg:string):string{
    let value = valueArg;
    if(this.useLatex){
      value = this.sanitizeInput(value);
      value = value.replace(/\\ln/g, 'ln');
      value = value.replace(/\\log_\{?(\d+)\}?/g, 'log$1');
      value = value.replace(/\\log_?/g, 'log');
    }
    return value;
  }

  /**
   *
   */
  private parseLog(valueArg:string):WLog{
    if(!valueArg){
      return null;
    }

    const value = valueArg.split(' ').join('');

    // \log_3\left(1\right)
    // \log_{10}\left(1\right)

    const Nx:string = '[-−]?[\\d]*[\\.,]?[\\d]+';

    const x1:RegExp = new RegExp(XString.substitute('^ln\\(({0})\\)$', Nx), 'i');
    const x2:RegExp = new RegExp(XString.substitute('^log([0-9]+)\\(({0})\\)$', Nx), 'i');
    const x3:RegExp = new RegExp(XString.substitute('^log\\(({0})\\)$', Nx), 'i');

    let s:Match;

    let n:RealElement;
    let base:RealElement;
    let displayBase:boolean;

    if(x1.test(value)){
      s = Match.tryParse(x1.exec(value));
      n = this.convertNumber(String(s.groups[1]));
      base = new WEulerConstant(this.env.culture.createNumber(Math.E));
      displayBase = false;
    }else if(x2.test(value)){
      s = Match.tryParse(x2.exec(value));
      n = this.convertNumber(String(s.groups[2]));
      base = this.convertNumber(String(s.groups[1]));
      displayBase = true;
    }else if(x3.test(value)){
      s = Match.tryParse(x3.exec(value));
      n = this.convertNumber(String(s.groups[1]));
      base = this.env.culture.createNumber(10);
      displayBase = false;
    }

    if(n && base){
      return new WLog(n, base, displayBase);
    }
    return null;
  }

  /**
   *
   */
  private convertNumber(value:string):WNumber{
    const n:number = this.numberParser.parseNumber(value);
    return isNaN(n) ? null : this.env.culture.createNumber(n);
  }

  /**
   *
   */
  public writeTo(
      w:IWriter,
      target:ContentElement,
      ...targets:any[]):void{
    CLog.writeLog(w, <WLog>target );
  }

  /**
   *
   */
  public static writeLog(
      w:IWriter,
      value:WLog):void{

    value.flushTo(new OldWriterFacade(w));
  }

  /**
   *
   */
  public get mathKeyboard():number{
    return KeyboardConfiguration.LOGARITHM;
  }

  /**
   *
   */
  public get inputCapabilities():InputCapabilities{
    const o:InputCapabilities = new InputCapabilities();
    o.logarithm = true;
    return o;
  }

}
