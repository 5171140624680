import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import styled from 'styled-components';
import {
  IHistoryEvent,
} from '../../models/history';
import { getHistoryEventShortDescription } from './utils';

export interface IHistoryItemDescriptionProps extends WrappedComponentProps {
  historyEvent: IHistoryEvent;
  className?: string;
}

class HistoryItemDescriptionComponent extends React.Component<IHistoryItemDescriptionProps> {

  public render(): JSX.Element {
    const {
      historyEvent,
      intl,
      className,
    } = this.props;

    return (
      <DescriptionLabel
        className={className}
      >
        {getHistoryEventShortDescription(historyEvent, intl)}
      </DescriptionLabel>
    );
  }

}

export const HistoryItemDescription = injectIntl(HistoryItemDescriptionComponent);

const DescriptionLabel = styled.div`
  width: 100%;
  font: ${(props) => props.theme.typeset.body1SemiBold};
  font-size: 16px;
  color: ${(props) => props.theme.colorset.grey900};
`;
