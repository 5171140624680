import { IDictionary } from '../../../js/utils/IDictionary';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { TokenElement } from '../../elements/abstract/TokenElement';
import { IFunctionForm } from '../../elements/functions/models/IFunctionForm';
import { IInputAdapter } from '../../elements/markers/IInputAdapter';
import { ParamStyles } from '../../expr/conversion/models/ParamStyles';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';
import { TokensExporter } from '../../expr/conversion/output/TokensExporter';
import { FunctionStyles } from '../../elements/functions/adapters/FunctionStyles';
import { WInterval } from '../../elements/tokens/WInterval';
import { TokensHash } from '../../expr/conversion/models/TokensHash';

/**
 *
 */
export class WFunction extends TokenElement implements IInputAdapter {

  private _form:IFunctionForm;

  public get form():IFunctionForm{return this._form;}

  private _part:WInterval;

  public get part():WInterval{return this._part;}

  private _style:string; // null|periodic|constant-piecewise

  public get style():string{return this._style;}

  private _varName:string;

  public get varName():string{return this._varName;}

  constructor(
      form:IFunctionForm,
      part:WInterval = null,
      style:string = null,
      varName:string = 'x'){
    super();
    this._form = form;
    this._part = part;
    this._style = style;
    this._varName = varName;
  }

  public map(value:number):number{
    if(this.style === FunctionStyles.PERIODIC){
      const o:number = this.part.lBoundN;
      const l:number = (this.part.rBoundN - this.part.lBoundN);
      const z:number = (value - o) / l;
      const x:number = o + (z - Math.floor(z)) * l;
      return this.form.map(x);
    }
    return this.form.map(value);
  }

  public writeTo(exporter:IMarkupExporter = null):boolean{
    if(exporter){
      const te:TokensExporter =
        new TokensExporter(
          this.form.getSimplifyTokens(this.form.parameters, this.varName),
          ParamStyles.VALUE,
          false);

      te.writeTo(exporter);
    }
    return true;
  }

  public writeStructureTo(exporter:IMarkupExporter, parameters:IDictionary):void{
    const te:TokensExporter =
      new TokensExporter(
        this.form.getRawTokens(parameters, this.varName),
        ParamStyles.INPUT,
        true);

    te.writeTo(exporter);
  }

  public getTokens(parameters:IDictionary):any[]{
    return this.form.getRawTokens(parameters, this.varName);
  }

  public copy(parameters:IDictionary):ContentElement{
    return new WFunction(
      this.form.copy(parameters),
      this.part,
      this.style,
      this.varName);
  }

  public hashCode(): string {
    const tokens = this.form.getRawTokens(this.form.parameters, this.varName);
    return [
      (new TokensHash(tokens)).getHashCode(),
      this.part ?
        this.part.toText(false) :
        null,
      this.style,
      this.varName].join(';');
  }

  /**
   *
   */
  public getType():string{
    return 'function';
  }

}
