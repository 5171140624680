import { XMath } from '../../core/XMath';
import { XRound } from '../../core/XRound';
import { MmlWriter } from '../../core/mml/MmlWriter';
import { TokenElement } from '../../elements/abstract/TokenElement';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';
import { BaseNumberFormatter } from '../../elements/formats/BaseNumberFormatter';
import { WNumber } from '../../elements/tokens/WNumber';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { AbstractFormatter } from '../../elements/formats/AbstractFormatter';

/**
 *
 */
export class WRange extends TokenElement {

  private _minimum:number;

  public get minimum():number{return this._minimum;}

  private _maximum:number;

  public get maximum():number{return this._maximum;}

  private _step:number;

  public get step():number{return this._step;}

  private _formatter:BaseNumberFormatter;

  public get formatter(): BaseNumberFormatter {
    return this._formatter;
  }

  /**
   *
   */
  constructor(
      minimum:number,
      maximum:number,
      step:number,
      formatter: BaseNumberFormatter){
    super();
    if(minimum > maximum){
      // Do not allow this.
      throw new Error();
    }
    this._minimum = minimum;
    this._maximum = maximum;
    this._step = step;
    this._formatter = formatter;
  }

  public applyFormat(formatter:AbstractFormatter):ContentElement{
    if(formatter instanceof BaseNumberFormatter){
      return new WRange(
        this.minimum,
        this.maximum,
        this.step,
        <BaseNumberFormatter>formatter );
    }
    return this;
  }

  public getFormat():AbstractFormatter{
    return this._formatter;
  }

  /**
   * Number of elements in this set
   */
  public getCardinal():number{
    // NOTE: +1 parce que
    // floor assure qu'on ne dépasse pas le maximum et
    // +1 assure que le minimum est aussi une possibilité
    const k:number = XRound.safeRound((this.maximum - this.minimum) / this.step);
    return Math.floor(k) + 1;
  }

  /**
   *
   */
  public contains(o:number):boolean{
    return o >= this.minimum && o <= this.maximum && ((o - this.minimum) % this.step === 0);
  }

  /**
   *
   */
  public valueAt(index:number):number{
    return XMath.safeRepeatedAddition(this.minimum, this.step, index);
  }

  /**
   *
   */
  public toNumbers(): number[] {
    const o:number[] = [];
    for(let i:number = 0 ; i < this.getCardinal() ; i++){
      o.push(this.valueAt(i));
    }
    return o;
  }

  /**
   *
   */
  public itemAt(index: number): WNumber {
    return new WNumber(this.valueAt(index), 1, false, this.formatter);
  }

  /**
   *
   */
  public toItems(): ContentElement[] {
    const o:ContentElement[] = [];
    for(let i:number = 0 ; i < this.getCardinal() ; i++){
      o.push(this.itemAt(i));
    }
    return o;
  }

  /**
   *
   */
  public writeTo(exporter:IMarkupExporter = null):boolean{
    if(exporter){
      const w:MmlWriter = exporter.writer;
      w.beginFenced('{', '}');
      w.addCommaCheck();

      if(this.getCardinal() < 4){
        for(let i:number = 0 ; i < this.getCardinal() ; i++){
          w.appendNumber(this.formatter.toLocaleString(this.valueAt(i)));
        }
      }else{
        w.appendNumber(this.formatter.toLocaleString(this.valueAt(0)));
        w.appendNumber(this.formatter.toLocaleString(this.valueAt(1)));
        w.appendText('...');
        w.appendNumber(this.formatter.toLocaleString(this.valueAt(this.getCardinal() - 1)));
      }

      w.separators = exporter.listSeparator(w.checkComma());
      w.endFenced();
    }
    return true;
  }

  /**
   *
   */
  public getType():string{
    return 'range';
  }

}
