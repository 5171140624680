import { RelationalElement } from '../../elements/abstract/RelationalElement';

/**
 *
 */
export class AlmostEqualTo extends RelationalElement {

  constructor(){
    super('≈');
  }

}
