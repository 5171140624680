import {
  Editor,
  Range,
  Transforms,
  Element,
  RangeRef,
} from '../TypedSlate';
import { ILinkElement } from '../models/elements';
import { ICustomEditor, ICustomElement } from '../models';

export const isLink = (node: ICustomElement): node is ILinkElement => {
  return node.type === 'link';
};

export const insertLink = (editor: ICustomEditor, url: string, rangeRef?: RangeRef) => {
  wrapLink(editor, url, rangeRef);
};

export const isLinkActive = (editor: ICustomEditor, rangeRef?: RangeRef) => {
  const [link] = Editor.nodes(editor, {
    at: getRange(editor, rangeRef),
    match: n =>
      !Editor.isEditor(n) && Element.isElement(n) && isLink(n),
  });
  return !!link;
};

export const unwrapLink = (editor: ICustomEditor, rangeRef?: RangeRef) => {
  Transforms.unwrapNodes(editor, {
    at: getRange(editor, rangeRef),
    match: n =>
      !Editor.isEditor(n) && Element.isElement(n) && isLink(n),
  });
};

export const wrapLink = (editor: ICustomEditor, href: string, rangeRef?: RangeRef) => {
  let selection = getRange(editor, rangeRef);
  if (isLinkActive(editor, rangeRef)) {
    unwrapLink(editor, rangeRef);
    selection = getRange(editor, rangeRef);
  }

  const isCollapsed = selection && Range.isCollapsed(selection);
  const link: ILinkElement = {
    href,
    target: '_blank',
    type: 'link',
    children: isCollapsed ? [{ text: href }] : [],
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, link, { at: selection });
  } else {
    Transforms.wrapNodes(editor, link, { at: selection, split: true });
    Transforms.collapse(editor, { edge: 'end' });
  }
};

const getRange = (editor: ICustomEditor, rangeRef?: RangeRef) =>
  rangeRef ? rangeRef.current : editor.selection;
