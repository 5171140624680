import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WSequence } from '../../elements/tokens/WSequence';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class ValueAt extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 2){
      return args.expectingArguments(2, 2);
    }

    const sequence:WSequence = args.getSequence(0);
    if(!sequence){
      return null;
    }

    const index:RealElement = args.getInteger(1);
    if(index){

      const options:number = 1; // preserve rationals only

      return args.env.expressions.add(
        sequence.origin,
        args.env.expressions.multiply(
          sequence.step,
          index,
          options),
        options);
    }

    return null;
  }

}
