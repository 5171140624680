import { MathError } from '../../core/MathError';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { TokenElement } from '../../elements/abstract/TokenElement';
import { WFiniteSet } from '../../elements/tokens/WFiniteSet';
import { WList } from '../../elements/tokens/WList';
import { WString } from '../../elements/tokens/WString';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';

/**
 * Génère un nombre à partir d'un modèle.
 */
export class RandomNumber extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length < 1 || args.length > 2){
      return args.expectingArguments(1, 2);
    }
    const pattern:WString = args.getString(0);
    if(!pattern){
      return null;
    }

    if(args.length === 1){
      return this.random(pattern, args.env);
    }
    if(args.length === 2){
      // Check for set first because the generator is different.
      if(args.getFiniteSet(1)){
        return this.random3(pattern, args.getFiniteSet(1), args.env);
      }
      if(args.getReals(1)){
        return this.random2(pattern, args.getReals(1), args.env);
      }
    }

    return null;
  }

  /**
   * Génère le nombre en utilisant tous les chiffres sauf le 0 à des positions non significatives.
   */
  private random(
      pattern:WString,
      env:Environment):TokenElement{

    const o:number[] = [];
    o.push(0, 1, 2, 3, 4, 5, 6, 7, 8, 9);
    return RandomNumber.parse(pattern.getString(), o, false, env);
  }

  /**
   * Génère un nombre en utilisant les chiffres énumérés qu'une seule fois chacun. Si le même chiffre se retrouve
   * deux fois dans la liste de chiffres, ce dernier pourra être utilisé deux fois dans la génération du nombre.
   */
  private random2(
      pattern:WString,
      digits:WList,
      env:Environment):TokenElement{
    const o:number[] = [];
    for(let i:number = 0 ; i < digits.count ; i++){
      const n:number = digits.getValueAt(i);
      if(n >= 0 && n <= 9){
        o.push(n);
      }else{
        throw new MathError('List of digits expected');
      }
    }
    return RandomNumber.parse(pattern.getString(), o, true, env);
  }

  /**
   * Génère un nombre en utilisant les chiffres énumérés autant de fois que possible.
   */
  private random3(
      pattern:WString,
      digits:WFiniteSet,
      env:Environment):TokenElement{

    const o:number[] = [];
    for(let i:number = 0 ; i < digits.cardinal ; i++){
      const element:TokenElement = digits.getElementAt(i);
      if(!(element instanceof RealElement)){
        throw new MathError('List of digits expected');
      }
      const n:number = (<RealElement>element ).toNumber();
      if(n >= 0 && n <= 9){
        o.push(n);
      }else{
        throw new MathError('List of digits expected');
      }
    }

    return RandomNumber.parse(pattern.getString(), o, false, env);
  }

  /**
   * Pattern can only contains #, 0-9, minus sign, and dot decimal separator
   */
  private static parse(
      pattern:string,
      digits:number[],
      remove:boolean,
      env:Environment):TokenElement{

    // [-−]?[#\d]+\.?[#\d]*

    const x:RegExp = new RegExp('^[-−]?[#\\d]+\\.?[#\\d]*$', 'gi');
    if(!x.test(pattern)){
      throw new MathError('InvalidPattern');
    }

    const temp:number[] = [];

    const tokens:any[] = pattern.split('−').join('-').split('');
    let decimal:boolean = false;
    let k:number;
    for(let i:number = 0 ; i < tokens.length ; i++){
      const c:string = tokens[i];
      if(c === '.'){
        decimal = true;
      }
      if(c === '#'){

        if(i === 0 || (i === tokens.length - 1 && decimal)){
          // no zeros, if zero is drawn then keep it for the next digit, draw a new digit
          do{
            if(digits.length === 0){
              throw new MathError('InvalidNumber');
            }
            k = env.prng.randomIndex(digits.length);
            if(digits[k] === 0){
              temp.push(0);
              if(remove){
                digits.splice(k, 1);
              }
            }else{
              tokens[i] = digits[k];
              if(remove){
                digits.splice(k, 1);
              }
              break;
            }
          }while(true);
        }else{
          if(digits.length === 0 && temp.length === 0){
            throw new MathError('InvalidNumber');
          }

          if(temp.length > 0){
            tokens[i] = temp.pop();
          }else{
            k = env.prng.randomIndex(digits.length);
            tokens[i] = digits[k];
            if(remove){
              digits.splice(k, 1);
            }
          }
        }

      }
    }

    return env.culture.parseNumber(Number(tokens.join('')));
  }

}
