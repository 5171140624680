import { Point } from '../../js/geom/Point';
import { XRound } from './XRound';

/**
 *
 */
export class XNumber {

  /**
   *
   */
  public static MAX_SCIENTIFIC_NOTATION:number = 10 ** 21;

  /**
   *
   */
  public static factors(nArg:number):any[]{
    let n = nArg;
    const r:any[] = [];
    for(let i:number = 2 ; i <= n / i; i++){
      while(n % i === 0) {
        r.push(i);
        n /= i;
      }
    }
    if(n > 1){
      r.push(n);
    }
    return r;
  }

  /**
   *
   */
  public static repeat(n:number, k:number):number[]{
    const o: number[] = [];
    for(let i: number = 0 ; i < k ; i++){
      o.push(n);
    }
    return o;
  }

  /**
   * l2 false
   * 123 --> 100, 20, 3
   *
   * l2 true
   * 123 --> 100, 10, 10, 1, 1, 1
   */
  public static expand(value:number, l2:boolean):number[]{
    const o:number[] = [];
    const value2 = Math.abs(value);
    const negative = value < 0;

    let i:number;
    let j:number;
    let digit:number;
    const parts:string[] = String(value2).split('.');
    const integers:string[] = parts[0].split('').reverse();

    for(i = 0 ; i < integers.length ; i++){
      digit = Number(integers[i]);
      if(digit === 0){
        continue;
      }

      if(l2){
        for(j = 0 ; j < digit ; j++){
          o.unshift(XRound.safeRound(10 ** i));
        }
      }else{
        o.unshift(XRound.safeRound(digit * (10 ** i)));
      }
    }

    if(parts.length > 1){
      const fractional:string[] = parts[1].split('');
      for(i = 0 ; i < fractional.length ; i++){
        digit = Number(fractional[i]);
        if(digit === 0){
          continue;
        }

        if(l2){
          for(j = 0 ; j < digit ; j++){
            o.unshift(XRound.safeRound(10 ** i));
          }
        }else{
          const pow = 10 ** -(i + 1);
          o.push(XRound.safeRound(digit * pow));
        }
      }
    }

    return negative ? o.map((n: number) => n * -1) : o;
  }

  /**
   *
   */
  public static truncate(value:number, precision:number):number{
    if(XNumber.decimals(value) <= precision){
      return value;
    }
    if (precision !== 0) {
      const a:number = Math.abs(Math.floor(10 ** precision));
      const b:number = a * value;
      const c:number = Math.ceil(b) / a;
      const d:number = Math.floor(b) / a;
      return value < 0 ? c : d;
    }
    return value < 0 ? Math.ceil(value) : Math.floor(value);
  }

  /**
   * Returns a point {x: max integer part length, y: max decimal part length} for a list of numbers.
   */
  public static partsLength(list:number[]):Point{
    let intLength:number = 0;
    let decimalLength:number = 0;

    for(let i:number = 0 ; i < list.length ; i++){
      const o:any[] = XNumber.toDecimalString(list[i]).split('.');
      intLength = Math.max(intLength, String(o[0]).length);
      if(o.length > 1){
        decimalLength = Math.max(decimalLength, String(o[1]).length);
      }
    }

    return new Point(intLength, decimalLength);
  }

  /**
   * Return the number of decimal in one number, or
   * the maximum number of decimals among a list of numbers
   */
  public static decimals(...values:any[]):number{
    let decimalsCount:number = 0;
    for(let i:number = 0 ; i < values.length ; i++){
      decimalsCount = Math.max(decimalsCount, XNumber.decimalsImpl(values[i]));
    }
    return decimalsCount;
  }

  /**
   *
   */
  private static decimalsImpl(value:number):number{
    // 1234.567
    // k = 4
    // length = 8
    const n:string = XNumber.toDecimalString(value);
    const k:number = n.indexOf('.');
    if(k >= 0){
      return n.length - (k + 1);
    }
    return 0;
  }

  /**
   *
   */
  public static toDecimalString(value:number):string{
    let n:string = value.toString();
    if(n.indexOf('e') !== -1 && Math.abs(value) < XNumber.MAX_SCIENTIFIC_NOTATION){
      n = value % 1 === 0 ? value.toFixed(0) : value.toFixed(20);
    }

    if(n.indexOf('e') !== -1){
      return n;
    }

    const k:number = n.indexOf('.');
    if(k >= 0){
      while(n.charAt(n.length - 1) === '0'){
        n = n.substring(0, n.length - 1);
      }
      if(n.charAt(n.length - 1) === '.'){
        n = n.substring(0, n.length - 1);
      }
    }
    return n;
  }

}
