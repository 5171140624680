import { RawDocument } from '../../core/RawDocument';
import { MElement } from '../../core/mml/MElement';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';
import { IMarkupFactory } from '../../elements/markers/IMarkupFactory';
import { IExtensionsMethods } from '../../expr/IExtensionsMethods';
import { CultureInfo } from '../../localization/CultureInfo';

/**
 *
 */
export class LatexToMarkupImpl implements IMarkupFactory {

  private latex:string;

  private decimalSeparator:string;

  private identifiers:ReadonlyArray<string>;

  private extensions:IExtensionsMethods;

  private _culture:CultureInfo;

  public get culture():CultureInfo{return this._culture;}

  private element:MElement;

  /**
   *
   */
  constructor(latex:string,
              decimalSeparator:string,
              identifiers: ReadonlyArray<string>,
              extensions:IExtensionsMethods,
              culture:CultureInfo) {

    this.latex = latex;
    this.decimalSeparator = decimalSeparator;
    this.identifiers = identifiers;
    this.extensions = extensions;
    this._culture = culture;
  }

  /**
   *
   */
  public writeTo(exporter:IMarkupExporter):void{
    if(!this.element){
      const sanitizedLatex = this.sanitizeLatex(this.latex);
      this.element =
        MElement.fromDocument(
          new RawDocument(this.extensions.latexToMarkup(sanitizedLatex)).toDocument(),
          null,
          true);

      if (this.identifiers) {
        this.element.mergeIdentifiers(this.identifiers);
      }
    }
    exporter.writer.appendElement(this.element);
  }

  /**
   * Sanitize steps:
   * - replace "\ " by a simple space, otherwise it is converted to <mtext>\</mtext>.
   * - wrap numbers with a comma decimal separator into \itexnum command, or else it won't be converted as a single <mn\> tag.
   * - replace \sqrt[]{N} by \sqrt{N} or else the latex parser might not like it.
   * - replace private usage of \embed
   * @param latex
   */
  public sanitizeLatex(latex: string): string {
    let latex2 = latex;

    // Those are private identifiers, unknow by any latex to markup extension.
    latex2 = latex2.replace(/\\embed{intervalBracketLeft}/g, '[');
    latex2 = latex2.replace(/\\embed{intervalBracketRight}/g, ']');
    latex2 = latex2.replace(/\\embed{intervalParenthesisLeft}/g, '(');
    latex2 = latex2.replace(/\\embed{intervalParenthesisRight}/g, ')');

    if (this.decimalSeparator === ',') {
      latex2 = latex2.replace(/([\[\]]\d+),(\d+[\[\]])/g, '$1, $2'); // [10,4] --> [10, 4]
      latex2 = latex2.replace(/\d+\\frac\{\d+\}\{\d+\}/g, '($&)');
      // 85,5,10 --> 85, 5, 10 (minimum trois nombres séparés par des virgules sans espaces
      if(/[-−]?\d+(,[-−]?\d+){2,}/.test(latex2)){
        latex2 = latex2.replace(/,(\d)/g, ', $1');
      }
      latex2 = latex2.replace(/(\d+(\,\d+)?)|(\,\d+)/g, '\\itexnum{$&}');
    }
    latex2 = latex2.replace(/\\\s/g, ' ');
    latex2 = latex2.replace(/\\sqrt\[\]\{/g, '\\sqrt{');

    return latex2;
  }

}
