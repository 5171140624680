import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { AbstractFormatter } from '../../elements/formats/AbstractFormatter';
import { WFormat } from '../../elements/tokens/WFormat';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Formatter } from '../../expr/manipulation/Formatter';
import { CultureInfo } from '../../localization/CultureInfo';

/**
 *
 */
export class AbstractApplyFormat extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 1){
      return args.expectingArguments(1, 1);
    }

    const value:ContentElement = args.getContentElement(0);

    return value ? (new Formatter(new WFormat(this.getFormat(args.env.culture)))).applyFormat(value) : null;
  }

  /**
   *
   */
  protected getFormat(culture:CultureInfo):AbstractFormatter{
    // Must inherit
    throw new Error();
  }

}
