import React from 'react';
import { TooltipPopup } from './TooltipPopup';
import { useTimeout } from '../../../hooks/useTimeout';
import { useForkRef } from '../../../hooks/useForkRef';

export interface IToolTipProps {
  enterDelay?: number;
  title: React.ReactNode;
}

/**
 * Tooltip component
 * Inspired by: https://github.com/mui/material-ui/blob/d3ef60158ba066779102fba775dda6765e2cc0f5/packages/mui-material/src/Tooltip/Tooltip.js
 */
export const Tooltip: React.FC<IToolTipProps> = (props) => {
  const {
    title,
    children: childrenProp,
    enterDelay = 500,
  } = props;
  const bypassEnterDelay = React.useRef(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [childNode, setChildNode] = React.useState();
  const children: React.ReactElement & React.RefAttributes<any> = React.isValidElement(childrenProp) ? childrenProp : <span>{childrenProp}</span>;
  const handleRef = useForkRef(children.ref, setChildNode);

  const enterTimer = useTimeout();
  const leaveTimer  = useTimeout();

  const onEnter = React.useCallback(() => {
    enterTimer.clear();
    leaveTimer.clear();
    if (enterDelay && !bypassEnterDelay.current) {
      enterTimer.start(enterDelay, () => {
        bypassEnterDelay.current = true;
        setIsOpen(true);
      });
    } else {
      bypassEnterDelay.current = true;
      setIsOpen(true);
    }
  }, [enterDelay, enterTimer, leaveTimer]);

  const onLeave = React.useCallback(() => {
    enterTimer.clear();
    leaveTimer.clear();
    setIsOpen(false);
    leaveTimer.start(500, () => {
      bypassEnterDelay.current = false
    });
  }, [enterTimer, leaveTimer]);

  const handlePointerEnter = React.useCallback((event: React.PointerEvent) => {
    onEnter();
    children.props.onPointerEnter?.(event);
  }, [children.props.onPointerEnter, onEnter]);

  const handlePointerLeave = React.useCallback((event: React.PointerEvent) => {
    onLeave();
    children.props.onPointerLeave?.(event);
  }, [children.props.onPointerLeave, onLeave]);

  const handleBlur = React.useCallback((event: React.FocusEvent) => {
    onLeave();
    children.props.onBlur?.(event);
  }, [children.props.onBlur, onLeave]);

  const handleFocus = React.useCallback((event: React.FocusEvent) => {
    onEnter();
    children.props.onFocus?.(event);
  }, [children.props.onFocus, onEnter]);

  const childrenProps = {
    ...children.props,
    ref: handleRef,
    onPointerEnter: handlePointerEnter,
    onPointerLeave: handlePointerLeave,
    onFocus: handleFocus,
    onBlur: handleBlur,
  };

  return (
    <>
      {React.cloneElement(children, childrenProps)}
      <TooltipPopup
        targetElement={childNode}
        isOpen={isOpen}
      >
        {title}
      </TooltipPopup>
    </>
  )
};
