import { runInAction } from 'mobx';
import { ObjectBankStore } from '../../models/store/ObjectBankStore';
import { instantiate } from '../../dependencyInjection/instantiate';
import { getContentObjectList } from '../../api/objectBank/getContentObjectList';
import { ResponseErrors } from '../../models/ResponseErrors';
import { authenticationRequiredCommand } from '../../command/authenticationRequiredCommand';

type TSuccess = boolean;

export const fetchObjectsCommand = (): Promise<TSuccess> => {
  const objectBankStore = instantiate(ObjectBankStore);
  return getContentObjectList().then((response) => {
    runInAction(() => {
      response.objects.forEach((object) => {
        if (object.data && object.data.subgroup) {
          // Remove that field or else it won't validate properly when editing metadata.
          delete object.data.subgroup;
        }
      });
      objectBankStore.contentObjects = response.objects;
    });
    return true;
  }).catch((error) => {
    console.error(error);
    handleResourcesServiceError(error);
    return false;
  });
};

const handleResourcesServiceError = (error: Error) => {
  switch (error.message) {
    case ResponseErrors.EMPTY:
      alert('no result returned');
      break;
    case ResponseErrors.AUTHORIZATION_REQUIRED:
      authenticationRequiredCommand();
      break;
    default:
      console.error('MediaCollectionResources.ts * FATAL 500\n' + error.message);
      break;
  }
};
