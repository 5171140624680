import { IDictionary } from '../../../../js/utils/IDictionary';
import { BaseTimeFormatter } from '../../../elements/formats/BaseTimeFormatter';
import { TimeModel } from '../../../elements/models/TimeModel';
import { CultureInfo } from '../../../localization/CultureInfo';
import { TimeFormatUtil } from '../../../elements/formats/time/TimeFormatUtil';

/**
 *
 */
export class ShortTimeOfDayFormatter extends BaseTimeFormatter {

  /**
   *
   */
  constructor(
      culture:CultureInfo,
      twelveHoursClock:boolean){
    super(culture, twelveHoursClock);
  }

  /**
   *
   */
  public toLocaleString(value:TimeModel):string{
    const suffix:string = this.useTwelveHoursClock() ? '12' : '24';

    const resourceKey =
      (value.seconds === 0 && value.undefinedSeconds ?
        `hmshortformat${suffix}`:
        `hmsshortformat${suffix}`);

    let time =
      this.culture.getString(
        `Time.${resourceKey}`,
        (this.useTwelveHoursClock() ?
          TimeFormatUtil.hours12hourclock(value.hours) :
          value.hours),
        TimeFormatUtil.timePart(value.minutes),
        TimeFormatUtil.timePart(value.seconds),
        this.culture.getString(value.hours < 12 ? 'Time.amLabel' : 'Time.pmLabel'));

    // Replace all spaces by non-breaking spaces
    time = time.split(' ').join('\u00A0');

    return time;
  }

  public fromLocaleString(value:string):TimeModel{
    const suffix:string = this.useTwelveHoursClock() ? '12' : '24';
    const patterns:IDictionary = this.getPatterns();

    return TimeModel.tryParse(
      value,
      patterns[`hmshortparser${suffix}`],
      patterns[`hmsshortparser${suffix}`],
      this.useTwelveHoursClock());
  }

  private getPatterns():IDictionary{
    const o:IDictionary = {};
    o.hmshortparser12 = '^([0-9]{1,2})h([0-9]{2})min(a\.m\.|am|p\.m\.|pm)$';
    o.hmsshortparser12 = '^([0-9]{1,2})h([0-9]{2})min([0-9]{2})s(a\.m\.|am|p\.m\.|pm)$';
    o.hmshortparser24 = '^([0-9]{1,2})h([0-9]{2})min$';
    o.hmsshortparser24 = '^([0-9]{1,2})h([0-9]{2})min([0-9]{2})s$';
    return o;
  }

}
