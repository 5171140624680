import styled from 'styled-components';

export interface IDisable {
  disabled?: boolean;
}

export const LabelNoSelection = styled.div`
  padding: 11px 2px;
  color: ${(props) => props.theme.colorset.grey07};
  font-family: ${(props) => props.theme.fontFamily};
  font-size: 14px;
`;

export const LabelWithSelection = styled.div`
  color: ${(props) => props.theme.colorset.grey08};
  font-family: ${(props) => props.theme.fontFamily};
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
`;

export const SelectedLabel = styled.div<IDisable>`
  color: ${(props) => props.disabled ? props.theme.colorset.grey10 : props.theme.colorset.grey13};
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
`;
