import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class Mod extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 2){
      return args.expectingArguments(2, 2);
    }

    if(args.getReal(0) && args.getReal(1)){
      return args.env.culture.createNumber(args.getReal(0).toNumber() % args.getReal(1).toNumber());
    }

    return null;
  }

}
