import { AbstractFormatter } from '../formats/AbstractFormatter';
import { ContentElement } from '../abstract/ContentElement';

/**
 *
 */
export class TokenElement extends ContentElement {

  /**
   *
   */
  public getFormat():AbstractFormatter{
    return null;
  }

  /**
   *
   */
  public applyFormat(formatter:AbstractFormatter):ContentElement{
    return this;
  }

  /**
   *
   */
  public static compare(a:TokenElement, b:TokenElement):number{
    const ha:Object = a.getComparisonValue();
    const hb:Object = b.getComparisonValue();
    if(ha != null && hb != null){
      if(ha < hb){
        return -1;
      }
      if(ha > hb){
        return 1;
      }
    }
    return 0;
  }

  /**
   *
   */
  protected getComparisonValue():Object{
    return this.toText(false);
  }

}
