import { MmlWriter } from '../../core/mml/MmlWriter';
import { TokenElement } from '../../elements/abstract/TokenElement';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';

/**
 *
 */
export class WUnhandled extends TokenElement {

  private reason:string;

  /**
   *
   */
  constructor(reason:string){
    super();
    this.reason = reason;
  }

  /**
   *
   */
  public writeTo(exporter:IMarkupExporter = null):boolean{
    if(exporter){
      const w:MmlWriter = exporter.writer;
      w.beginStyle();
      w.color = '#FF6E02';
      w.appendText(`[${this.reason}]`);
      w.endStyle();
    }
    return true;
  }

  public getType():string {
    return 'unhandled';
  }
}
