import { CSSProperties } from 'react';
import { merge } from 'lodash';
import { ColorSet, setHexColorTransparency } from '../../../themes/ColorSet';

export interface IButtonTheme {
  default: {
    color: CSSProperties['color'];
    backgroundColor: CSSProperties['backgroundColor'];
    border?: CSSProperties['border'];
    hover: {
      backgroundColor: CSSProperties['backgroundColor'];
    };
    active: {
      backgroundColor: CSSProperties['backgroundColor'];
      border: CSSProperties['border'];
      color?: CSSProperties['color'];
      hover: {
        backgroundColor: CSSProperties['backgroundColor'];
      };
    };
    icon: {
      backgroundColor: CSSProperties['backgroundColor'];
      border?: CSSProperties['border'];
    };
    disabled: {
      backgroundColor: CSSProperties['backgroundColor'];
    };
  };
  primary: {
    color: CSSProperties['color'];
    backgroundColor: CSSProperties['backgroundColor'];
    border?: CSSProperties['border'];
    hover: {
      backgroundColor: CSSProperties['backgroundColor'];
      border?: CSSProperties['border'];
    };
    disabled: {
      backgroundColor: CSSProperties['backgroundColor'];
    };
    active: null;
    icon: null;
  };
}

export const darkButtonTheme: IButtonTheme = {
  default: {
    color: ColorSet.white,
    backgroundColor: ColorSet.penumbra,
    hover: {
      backgroundColor: ColorSet.grey800,
    },
    active: {
      backgroundColor: setHexColorTransparency(ColorSet.primary500, 0.2),
      border: `1px solid ${ColorSet.primary500}`,
      hover: {
        backgroundColor: setHexColorTransparency(ColorSet.primary500, 0.5),
      }
    },
    icon: {
      backgroundColor: null,
      border: null,
    },
    disabled: {
      backgroundColor: ColorSet.penumbra,
    },
  },
  primary: {
    color: ColorSet.white,
    backgroundColor: ColorSet.primary700,
    hover: {
      backgroundColor: ColorSet.primary500,
    },
    disabled: {
      backgroundColor: ColorSet.darkness,
    },
    active: null,
    icon: null,
  },
};

export const darkButtonTheme2: IButtonTheme = merge({}, darkButtonTheme, {
  default: {
    backgroundColor: ColorSet.grey900,
  },
  primary: {
    disabled: {
      backgroundColor: ColorSet.grey900,
    },
  }
});

export const lightButtonTheme: IButtonTheme = {
  default: {
    color: ColorSet.grey900,
    backgroundColor: ColorSet.white,
    border: `1px solid ${ColorSet.grey100}`,
    hover: {
      backgroundColor: setHexColorTransparency(ColorSet.grey500, 0.2),
    },
    active: {
      color: ColorSet.primary500,
      backgroundColor: setHexColorTransparency(ColorSet.primary500, 0.2),
      border: `1px solid ${ColorSet.primary500}`,
      hover: {
        backgroundColor: setHexColorTransparency(ColorSet.primary500, 0.5),
      }
    },
    icon: {
      backgroundColor: null,
      border: null,
    },
    disabled: {
      backgroundColor: ColorSet.white,
    },
  },
  primary: {
    color: ColorSet.white,
    backgroundColor: ColorSet.primary500,
    hover: {
      backgroundColor: ColorSet.primary700,
    },
    disabled: {
      backgroundColor: ColorSet.grey400,
    },
    active: null,
    icon: null,
  },
};
