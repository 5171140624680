import {
  AddPagesAlignments,
} from '../requests/AlignmentsRequests';
import { updateActivityAlignmentsCommand } from '../frameworks/commands/UpdateActivityAlignmentsCommand';
import { IStandard } from '../models/IStandard';

export const AddPagesAlignmentsCommand = async (pageGuid: string, standards: ReadonlyArray<IStandard>, activityId: number) => {
  await AddPagesAlignments(pageGuid, standards);
  updateActivityAlignmentsCommand(activityId);
}
