import { XMath } from '../../core/XMath';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WBoolean } from '../../elements/tokens/WBoolean';
import { WRational } from '../../elements/tokens/WRational';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class IsDecimal extends FunctionElement {

  /**
   * Returns true if the specified number has at least one decimal and also a finite number of decimals.
   *
   * In the actual form, this function could return false-positive due to math-core limitation.
   *
   * For exemple, if we inspect a WNumber which is a decimal approximation (ex. square root of 2).
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 1){
      args.expectingArguments(1, 1);
    }

    const r:WRational = args.getRational(0);
    if(r){
      const d:string = XMath.longDiv(r.numerator, r.denominator);
      return WBoolean.parse(d.indexOf('.') !== -1 && d.indexOf('(') === -1);
    }

    const n:RealElement = args.getReal(0);
    if(n){
      return WBoolean.parse(!n.isInteger());
    }

    return WBoolean.FALSE;
  }

}
