import { MathError } from '../../core/MathError';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Colle une matrice à droite d'une matrice existante,
 * si les deux matrices ont le même nombre de lignes.
 */
export class ConcatH extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 2){
      return args.expectingArguments(2, 2);
    }

    if (args.getMatrix(0) && args.getMatrix(1)) {
      return this.impl(args.getMatrix(0), args.getMatrix(1));
    }
    return null;
  }

  /**
   *
   */
  private impl(
      a:WMatrix,
      b:WMatrix):WMatrix{

    if(a.rows !== b.rows){
      throw new MathError('Matrices have different number of rows.');
    }

    const o:RealElement[] = [];

    for(let r:number = 0 ; r < a.rows ; r++){
      for(let c:number = 0 ; c < a.columns ; c++){
        o.push(a.valueAt(r, c));
      }
      for(let k:number = 0 ; k < b.columns ; k++){
        o.push(b.valueAt(r, k));
      }
    }

    return new WMatrix(
      o,
      a.columns + b.columns,
      a.formatter);
  }

}
