import React from 'react';
import { Keyboard } from './Keyboard';
import KeyboardManager, { IKeyboardManagerListener } from './KeyboardManager';
import { IKeyboard } from './Keyboard.models';

export interface IManagedKeyboardState {
  readonly keyboardConfiguration: IKeyboard;
  readonly visible: boolean;
  readonly onKeyPress: (key: string) => void;
}

export class ManagedKeyboard extends React.PureComponent<{}, IManagedKeyboardState> {
  private readonly listener: IKeyboardManagerListener;

  constructor(props: {}) {
    super(props);
    this.state = {
      keyboardConfiguration: null,
      visible: false,
      onKeyPress: () => null,
    };
    this.listener = {
      onShowKeyboard: this.onShowKeyboard,
      onHideKeyboard: this.onHideKeyboard,
    };
    KeyboardManager.getInstance().addListener(this.listener);
  }

  public componentWillUnmount(): void {
    KeyboardManager.getInstance().removeListener(this.listener);
  }

  public render(): JSX.Element {
    const {
      keyboardConfiguration,
      visible,
      onKeyPress,
    } = this.state;

    return (
      <Keyboard
        keyboardConfiguration={keyboardConfiguration}
        visible={visible}
        onKeyPress={onKeyPress}
      />
    );
  }

  private onShowKeyboard = (keyboardConfiguration: IKeyboard, onKeyPress: (key: string) => void) => {
    this.setState({
      keyboardConfiguration,
      onKeyPress,
      visible: true,
    });
  }

  private onHideKeyboard = () => {
    this.setState({
      visible: false,
    });
  }
}
