import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WString } from '../../elements/tokens/WString';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { XString } from '../../core/XString';
import { WBoolean } from '../../elements/tokens/WBoolean';

/**
 * Utiliser (v:forme vouvoiement) et (t:forme tutoiement) dans le texte source.
 * Cette fonction répond à la clé de localisatino vouvoyer.
 */
export class Vouvoyer extends FunctionElement {

  /**
   *
   * @param args
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length > 1){
      return args.expectingArguments(0, 1);
    }

    if(args.length === 0){
      return new WBoolean(args.env.culture.configuration.vouvoyer);
    }

    const s:WString = args.getString(0);
    if (!s) {
      return null;
    }

    return new WString(
      XString.vouvoyiser(
        s.getString(),
        args.env.culture.configuration.vouvoyer),
      s.toSpeakText(),
      s.getSource());
  }

}
