import { IDictionary } from '../../../../js/utils/IDictionary';

import { RealElement } from '../../../elements/abstract/RealElement';
import { AbstractNode } from '../../../elements/functions/tree/AbstractNode';
import { EmptyLeaf } from '../../../elements/functions/tree/EmptyLeaf';
import { NodeConstant } from '../../../elements/functions/tree/NodeConstant';
import { CultureInfo } from '../../../localization/CultureInfo';
import { IFunctionForm } from '../../../elements/functions/models/IFunctionForm';
import { AbstractFunctionForm } from '../../../elements/functions/models/AbstractFunctionForm';

/**
 * 6. = fonction cosinus (forme canonique)  f(x) = A cos B(x - H) + K
 */
export class TCos extends AbstractFunctionForm {

  private radians:boolean;

  private localizedName:string;

  constructor(
      culture:CultureInfo,
      A:RealElement,
      B:RealElement,
      H:RealElement,
      K:RealElement,
      radians:boolean,
      localizedName:string){

    super(culture, A, B, null, null, null, null, H, K);
    this.radians = radians;
    this.localizedName = localizedName;
  }

  public getRawTokens(parameters:IDictionary, varName:string):any[]{
    const o:any[] = [varName];
    if(parameters.hasOwnProperty('H')){
      o.unshift('(');
      o.push('−', this.getH(), ')');
    }
    if(parameters.hasOwnProperty('B')){
      o.unshift(this.getB());
    }
    o.unshift(this.localizedName, '(');
    o.push(')');
    if(parameters.hasOwnProperty('A')){
      o.unshift(this.getA());
    }
    if(parameters.hasOwnProperty('K')){
      o.push('+', this.getK());
    }
    return o;
  }

  public getSimplifyTokens(parameters:IDictionary, varName:string):any[] {
    let simplified:AbstractNode =  this.createCosSinTanTree( this.localizedName, varName ).simplify();

    if( simplified instanceof EmptyLeaf ) {
      simplified = new NodeConstant( this.getK() );
    }

    return simplified.getToken();
  }

  public map(value:number):number{
    const n:number = (this.nB * (value - this.nH)) * (this.radians ? 1 : 0.017453292519943295);
    return this.nA * Math.cos(n) + this.nK;
  }

  public get continuous():number{
    return 1;
  }

  public copy(parameters:IDictionary):IFunctionForm{
    return new TCos(
      this.culture,
      parameters.hasOwnProperty('A') ? parameters.A : this.A,
      parameters.hasOwnProperty('B') ? parameters.B : this.B,
      parameters.hasOwnProperty('H') ? parameters.H : this.H,
      parameters.hasOwnProperty('K') ? parameters.K : this.K,
      this.radians,
      this.localizedName);
  }

}
