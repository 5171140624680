import React from 'react';
import styled from 'styled-components';
import MUISlider, { SliderProps } from '@material-ui/core/Slider';
import { withStyles } from '@material-ui/core/styles';
import { ReadonlyContext } from '../../contexts/ReadonlyContext';

export const Slider: React.FC<SliderProps> = (props) => {
  const {
    disabled,
    ...otherProps
  } = props;
  const isReadonly = React.useContext(ReadonlyContext);
  const isDisabled = disabled || isReadonly;

  return (
    <StyledSlider
      disabled={isDisabled}
      {...otherProps}
    />
  );
};

const StyledSlider = styled(withStyles({
  thumb: {
    '&:hover': {
      boxShadow: 'var(--sliderThumbShadow)',
    },
    '&:focus': {
      boxShadow: 'var(--sliderThumbShadow)',
    },
  },
  valueLabel: {
    '& > span > span' : {
      color: 'var(--valueLabelColor)',
    },
  },
})(MUISlider) as typeof MUISlider)`
  && {
    color: ${(props) => props.theme.colorset.grey13};
    --valueLabelColor: ${(props) => props.theme.colorset.grey01};
    --sliderThumbShadow: 0px 0px 0px 8px ${(props) => props.theme.colorset.grey13}29;
  }
`;
