import { ClientApplicationModel } from '../../models/ClientApplicationModel';
import { api } from '../../utils/fetch';
import { IBackEndContentTag } from './IBackEndContentTag';

export const createContentTag = async (subjectId: number, nameFr: string, description: string): Promise<IBackEndContentTag> => {
  const clientApplication = ClientApplicationModel.getInstance();

  const data = {
    subjectId,
    name: {
      fr: nameFr,
    },
    synonyms: {
      fr: '',
    },
    notes: description,
  };

  const response = await api<IBackEndContentTag>(`/Publisher/ContentTags/Create?${clientApplication.params}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data),
  });
  return response.json();
};
