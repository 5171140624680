import { Point } from '../../../js/geom/Point';
import { AbstractStep } from '../AbstractStep';
import { Compartment } from '../Compartment';
import { LongMultiplicationOperation } from '../LongMultiplicationOperation';

export class MultZeroPlaceholders extends AbstractStep {

  private zeroPlaceholders:number;

  constructor(operation:LongMultiplicationOperation) {

    super(operation);

    const p:Point = operation.multCursor;
    this.zeroPlaceholders = p.y;

    if(p.x === 0 && p.y > 0){
      operation.multSteps.push([]);
      operation.multCarries.push([]);
      operation.lastMultCarries.push(null);
      for(let i:number = 0 ; i < p.y ; i++){
        const zeroPlaceholder:Compartment = Compartment.createDigit(0);
        this.target.push(zeroPlaceholder);
        operation.lastMultStep.push(zeroPlaceholder);
      }
    }

    operation.multZeroPlaceholders = this.zeroPlaceholders;

    this.setDescription(
      operation.culture.getString(
        'LongMultiplicationOperation.zeroPlaceholders',
        this.zeroPlaceholders));
  }

}
