/**
 *
 */
export class RawDocument {

  private source:string;

  constructor(source:string) {

    this.source = source;
  }

  public toDocument():Document{
    const parser = new DOMParser();
    return parser.parseFromString(this.source, 'application/xml');
  }

}
