import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { NumberWordsFormatter } from '../../elements/formats/numbers/NumberWordsFormatter';
import { WFormat } from '../../elements/tokens/WFormat';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class NumberWordsFormat extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length > 0){
      args.expectingArguments(0, 0);
    }
    return new WFormat(new NumberWordsFormatter(args.env.culture));
  }

}
