import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Retourne le premier élément d'une liste.
 */
export class First extends FunctionElement {
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length === 0) {
      return args.expectingArguments(1, Number.POSITIVE_INFINITY);
    }

    if (args.length === 1) {
      const point = args.getPoint(0);
      if (point) {
        return point.x;
      }

      const real = args.getReal(0);
      if (real) {
        return real;
      }

      const list = args.getNonEmptyList(0);
      if (list) {
        return list.getItemAt(0);
      }
    }

    const listOfList = args.toListOfList();
    if (listOfList) {
      return listOfList.getItemAt(0);
    }

    return null;
  }
}
