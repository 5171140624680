import { AbstractStep } from '../AbstractStep';
import { Compartment } from '../Compartment';
import { LongMultiplicationOperation } from '../LongMultiplicationOperation';
import { MultiplicationModel } from '../models/MultiplicationModel';

/**
 *
 */
export class MultEndDecSep extends AbstractStep {

  public model:MultiplicationModel;

  constructor(operation:LongMultiplicationOperation) {

    super(operation);
    this.model = operation.model;

    if(this.model.requireNormalize){
      const decimals:number = this.model.decimals1 + this.model.decimals2;
      let c:Compartment;
      const sum:Compartment[] = operation.sumRow;

      // copy result
      for(let i:number = sum.length - 1 ; i >= 0 ; i--){
        this.source.unshift(sum[i]);
        c = sum[i].clone();
        operation.result.unshift(c);
        this.target.unshift(c);
        // check whether we need to insert decimal separator
        if(operation.result.length === decimals){
          c = operation.decimalSeparator();
          this.target.unshift(c);
          operation.result.unshift(c);
        }
      }

      // insert extra zeros between result and decimal separator if needed.
      if(operation.result.length < decimals){
        for(let k: number = 0 ; k < decimals - operation.result.length ; k++){
          c = Compartment.createDigit(0);
          this.target.unshift(c);
          operation.result.unshift(c);
        }
        c = operation.decimalSeparator();
        this.target.unshift(c);
        operation.result.unshift(c);
      }

      // insert leading zero in front of decimal separator if needed.
      if(operation.result.length === decimals + 1){ // +1 decimal separator
        c = Compartment.createDigit(0);
        this.target.unshift(c);
        operation.result.unshift(c);
      }

      if(this.model.product < 0){
        c = Compartment.createOperator('−');
        this.target.push(c);
        operation.result.unshift(c);
      }
    }

    this.setDescription(
      operation.culture.getString(
        'LongMultiplicationOperation.endDecSepLabel',
        operation.culture.numberFormatter.getDecimalSeparatorLabel(false, ['le', 'la'])));
  }

}
