import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ITreeProps, Spinner, Tree } from '@scolab/publisher-ui-kit';
import styled from 'styled-components';
import { LocaleProvider } from '../LocaleProvider';
import { locales } from '../../autoGenerate/locale';

interface INavigationTreeViewProps {
  readonly title?: React.ReactNode;
  readonly treeProps: ITreeProps;
}

export class NavigationTreeView extends React.PureComponent<INavigationTreeViewProps> {
  public render(): JSX.Element {
    const { title, treeProps } = this.props;

    const headerElement = title && (
      <Header>
        {title}
      </Header>
    );

    const loaderElement = treeProps.dataDescriptor === null && (
      <Spinner/>
    );

    const closeTootip = (
      <LocaleProvider locales={locales}>
        <FormattedMessage id='common.close' />
      </LocaleProvider>
    );

    const openTooltip = (
      <LocaleProvider locales={locales}>
        <FormattedMessage id='common.open' />
      </LocaleProvider>
    );

    return (
      <Container>
        {headerElement}
        {loaderElement}
        <StyledTree
          closeTooltip={closeTootip}
          openTooltip={openTooltip}
          {...treeProps}
        />
      </Container>
    );
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
`;

const StyledTree = styled(Tree)`
  width: 100%;
`;

const Header = styled.div`
  color: ${(props) => props.theme.colorset.grey10};
  font: ${(props) => props.theme.typeset.h2Headline};
  font-size: 24px;
  padding: 24px;
  border-bottom: ${(props) => `1px solid ${props.theme.colorset.grey04}`};
  background-color: ${(props) => props.theme.colorset.white};
  position: sticky;
  top: 0;
  box-sizing: border-box;
  min-height: 80px;
  width: 100%;
  z-index: 1;
`;
