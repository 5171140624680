import { ContentElement } from '../../elements/abstract/ContentElement';
import { SymbolElement } from '../../elements/abstract/SymbolElement';
import { TokenElement } from '../../elements/abstract/TokenElement';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';
import { WString } from '../../elements/tokens/WString';
import { WPair } from '../../elements/tokens/WPair';
import { WId } from '../../elements/tokens/WId';

/**
 *
 */
export class WDictionary extends TokenElement {

  private _entries:WPair[];

  public get entries():WPair[]{return this._entries;}

  constructor(
      entries:WPair[]){
    super();
    this._entries = entries;
  }

  public containsKey(key:ContentElement):boolean{
    return this.item(key) != null;
  }

  public containsValue(value:ContentElement):boolean{
    return this._entries.some((entry: WPair) => entry.value.equalsTo(value));
  }

  public item(key:ContentElement):ContentElement{
    if(!this.hash(key)){
      return null;
    }
    for(let i:number = 0 ; i < this.entries.length ; i++){
      const entry:WPair = this.entries[i];
      if(this.hash(entry.key) === this.hash(key)){
        return entry.value;
      }
    }
    return null;
  }

  public item2(key:string):ContentElement{
    if(!key){
      return null;
    }
    for(let i:number = 0 ; i < this.entries.length ; i++){
      if(this.hash(this.entries[i].key) === key){
        return this.entries[i].value;
      }
    }
    return null;
  }

  public get keys():string[]{
    const keys:string[] = [];
    for(let i:number = 0 ; i < this.entries.length ; i++){
      keys.push(this.hash(this._entries[i].key));
    }
    return keys;
  }

  private hash(value:ContentElement):string{
    if(value instanceof SymbolElement){
      return (<SymbolElement>value ).getSymbol();
    }
    if(value instanceof WString){
      return (<WString>value ).getString();
    }
    if(value instanceof WId){
      return (<WId>value ).getId();
    }
    return null;
  }

  public writeTo(exporter:IMarkupExporter = null):boolean{
    if(exporter){
      exporter.writer.appendText('[Dictionary]');
    }
    return true;
  }

  /**
   *
   */
  public getType():string {
    return 'dictionary';
  }

}
