import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { WPoint } from '../../elements/tokens/WPoint';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { WTable } from '../../elements/tokens/WTable';

/**
 * Item d'une matrice.
 */
export class Entry extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length < 1 || args.length > 3){
      return args.expectingArguments(1, 3);
    }

    let row:RealElement;
    let column:RealElement;

    if(args.length === 2){
      const position:WPoint = args.getPoint(1);
      if(position){
        row = position.x.isWholeNumber() ? position.x : null;
        column = position.y.isWholeNumber() ? position.y : null;
      }
    }else if(args.length === 3){
      row = args.getWholeNumber(1);
      column = args.getWholeNumber(2);
    }

    if(row && column){
      const matrix: WMatrix = args.getMatrix(0);
      if(matrix){
        return this.getMatrixItem(matrix, row.toNumber(), column.toNumber());
      }

      const table: WTable = args.getTable(0);
      if(table) {
        return this.getTableItem(table, row.toNumber(), column.toNumber());
      }
    }

    return null;
  }

  /**
   *
   */
  private getMatrixItem(matrix: WMatrix,
                        row: number,
                        column: number):RealElement{

    if(row >= matrix.rows){
      return null;
    }
    if(column >= matrix.columns){
      return null;
    }
    return matrix.valueAt(row, column);
  }

  private getTableItem(table: WTable,
                       row: number,
                       column: number):ContentElement{
    return table.columns[column]?.getItemAt(row) ?? null;
  }

}
