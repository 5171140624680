import { IDictionary } from '../../../../js/utils/IDictionary';

import { Node } from '../../../elements/abstract/Node';
import { BoundVariable } from '../../../elements/constructs/BoundVariable';
import { Lambda } from '../../../elements/constructs/Lambda';
import { List } from '../../../elements/constructs/List';
import { Otherwise } from '../../../elements/constructs/Otherwise';
import { Piece } from '../../../elements/constructs/Piece';
import { Piecewise } from '../../../elements/constructs/Piecewise';
import { WVariable } from '../../../elements/tokens/WVariable';

/**
 *
 */
export class ConstructWorker {

  /**
   *
   */
  public static lambdaConstructs(
      parent:Node,
      bvars:IDictionary):void{

    let child:Node;
    let bvar:BoundVariable;
    if(parent.value instanceof Lambda){

      // Last element is the body
      // Every element before must be a WVariable that is transformed into a BoundVariable
      if(parent.numChildren === 1){
        const def:Node = parent.firstChild;
        parent.clear();

        if(def.value instanceof List){
          if(def.numChildren >= 2){ // At least one argument and an expression

            let i:number;

            // Every WVariable before the first Piece is an argument
            let argumentsEndIndex:number = def.numChildren - 1;
            for(i = 0 ; i < def.numChildren - 1 ; i++){
              if(!(def.childs[i].value instanceof WVariable)){
                argumentsEndIndex = i;
                break;
              }
            }

            if(argumentsEndIndex > 0){ // Check at least one argument

              // Every other argument must be Piece except the last that can be any ContentElement
              let valid:boolean = true;
              for(i = argumentsEndIndex ; i < def.numChildren ; i++){
                const qPiece:boolean = def.childs[i].value instanceof Piece;
                valid = qPiece || i === def.numChildren - 1;
                if(!valid){
                  break;
                }
              }

              if(valid){

                // Add arguments
                for(i = 0 ; i < argumentsEndIndex ; i++){
                  bvar = new BoundVariable(<WVariable>def.childs[i].value );
                  bvars[bvar.getVariable().getSymbol()] = bvar;
                  parent.appendChild(new Node(bvar));
                }

                // Add body
                const piecewise:Node = new Node(new Piecewise());
                parent.appendChild(piecewise);
                for(i = argumentsEndIndex ; i < def.numChildren ; i++){
                  const body:Node = def.childs[i];
                  if(body.value instanceof Piece){
                    piecewise.appendChild(body);
                  }else{
                    piecewise.appendChild(
                      new Node(
                        new Otherwise(),
                        body));
                  }
                  if(!ConstructWorker.bindVariable(body, bvars)){
                    ConstructWorker.lambdaConstructs(body, bvars);
                  }
                }
              }
            }
          }
        }
      }
    }else{

      for(let k:number = 0 ; k < parent.childs.length ; k++){
        child = parent.childs[k];
        if(!ConstructWorker.bindVariable(child, bvars)){
          ConstructWorker.lambdaConstructs(child, bvars);
        }
      }

    }
  }

  private static bindVariable(
      node:Node,
      bvars:IDictionary):boolean{

    if(node.value instanceof WVariable){
      const v:WVariable = <WVariable>node.value ;
      if(bvars[v.getSymbol()] !== undefined){
        node.value = bvars[v.getSymbol()];
        return true;
      }
    }
    return false;
  }

}
