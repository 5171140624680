/**
 *
 */
import {IMmlProxy} from './IMmlProxy';

export class MmlXmlProxy implements IMmlProxy {

  /**
   *
   */
  public elementName(node:any):string {
    return (<Element> node).localName;
  }

  /**
   *
   */
  public createNode(elementName:string):any {
    return document.createElement(elementName);
  }

  /**
   *
   */
  public setAttribute(node:any, attributeName:string, value:any):void {
    (<HTMLElement> node).setAttribute(attributeName, value);
  }

  /**
   *
   */
  public setText(node:any, text:string):void {
    (<HTMLElement> node).innerHTML = text;
  }

  /**
   *
   */
  public addChild(parent:any, child:any):void {
    (<Element> parent).appendChild(child);
  }

  /**
   *
   */
  public getParent(child:any):any {
    return (<Element> child).parentElement;
  }

  /**
   *
   */
  public numChildren(parent:any):number {
    return (<Element> parent).childElementCount;
  }
}
