export enum HistoryEventTypes {
  CurriculumCreated = 'curriculumCreated',
  CurriculumArchived = 'curriculumArchived',
  CurriculumRestored = 'curriculumRestored',
  CurriculumRenamed = 'curriculumRenamed',
  CurriculumDeleted = 'curriculumDeleted',

  CourseCreated = 'courseCreated',
  CourseRenamed = 'courseRenamed',
  CourseVisibilityChanged = 'courseVisibilityChanged',
  CourseSubjectChanged = 'courseSubjectChanged',
  CourseDeleted = 'courseDeleted',

  StandardCreated = 'standardCreated',
  StandardDisplayIdChanged = 'standardDisplayIdChanged',
  StandardDescriptionChanged = 'standardDescriptionChanged',
  StandardNotesChanged = 'standardNotesChanged',
  StandardIndentChanged = 'standardIndentChanged',
  StandardPositionChanged = 'standardPositionChanged',
  StandardDeleted = 'standardDeleted',

  StandardActivityAlignmentAdded = 'standardActivityAlignmentAdded',
  StandardActivityAlignmentRemoved = 'standardActivityAlignmentRemoved',
  StandardActivityAlignmentQualityChanged = 'standardActivityAlignmentQualityChanged',

  StandardPageAlignmentAdded = 'standardPageAlignmentAdded',
  StandardPageAlignmentRemoved = 'standardPageAlignmentRemoved',
  StandardPageAlignmentQualityChanged = 'standardPageAlignmentQualityChanged',

  LibraryCreated = 'libraryCreated',
  LibraryDuplicated = 'libraryDuplicated',
  LibraryRenamed = 'libraryRenamed',
  LibraryCreatorChanged = 'libraryCreatorChanged',
  LibraryIsbnChanged = 'libraryIsbnChanged',
  LibraryAuthorsChanged = 'libraryAuthorsChanged',
  LibraryTranslatorsChanged = 'libraryTranslatorsChanged',
  LibraryTerritoriesChanged = 'libraryTerritoriesChanged',
  LibraryBookAdded = 'libraryBookAdded',
  LibraryBookRemoved = 'libraryBookRemoved',
  LibraryBookMoved = 'libraryBookMoved',
  LibraryArchived = 'libraryArchived',
  LibraryRestored = 'libraryRestored',

  BookCreated = 'bookCreated',
  BookCreatorChanged = 'bookCreatorChanged',
  BookIsbnChanged = 'bookIsbnChanged',
  BookAuthorsChanged = 'bookAuthorsChanged',
  BookTranslatorsChanged = 'bookTranslatorsChanged',
  BookLanguagesChanged = 'bookLanguagesChanged',
  BookTitleChanged = 'bookTitleChanged',
  BookIconChanged = 'bookIconChanged',
  BookSubjectChanged = 'bookSubjectChanged',

  BookToCRootVisibilityChanged = 'bookToCRootVisibilityChanged',
  BookToCRootThemeChanged = 'bookToCRootThemeChanged',
  BookToCRootTitleChanged = 'bookToCRootTitleChanged',
  BookToCRootShortTitleChanged = 'bookToCRootShortTitleChanged',
  BookToCRootDescriptionChanged = 'bookToCRootDescriptionChanged',
  BookToCRootIconChanged = 'bookToCRootIconChanged',
  BookToCRootVariantsChanged = 'bookToCRootVariantsChanged',
  BookToCRootExtraDataChanged = 'bookToCRootExtraDataChanged',

  BookToCTopicCreated = 'bookToCTopicCreated',
  BookToCTopicRemoved = 'bookToCTopicRemoved',
  BookToCTopicMoved = 'bookToCTopicMoved',
  BookToCTopicVisibilityChanged = 'bookToCTopicVisibilityChanged',
  BookToCTopicThemeChanged = 'bookToCTopicThemeChanged',
  BookToCTopicTitleChanged = 'bookToCTopicTitleChanged',
  BookToCTopicDescriptionChanged = 'bookToCTopicDescriptionChanged',
  BookToCTopicIconChanged = 'bookToCTopicIconChanged',
  BookToCTopicVariantsChanged = 'bookToCTopicVariantsChanged',
  BookToCTopicExtraDataChanged = 'bookToCTopicExtraDataChanged',

  BookToCChapterCreated = 'bookToCChapterCreated',
  BookToCChapterRemoved = 'bookToCChapterRemoved',
  BookToCChapterMoved = 'bookToCChapterMoved',
  BookToCChapterVisibilityChanged = 'bookToCChapterVisibilityChanged',
  BookToCChapterThemeChanged = 'bookToCChapterThemeChanged',
  BookToCChapterTitleChanged = 'bookToCChapterTitleChanged',
  BookToCChapterDescriptionChanged = 'bookToCChapterDescriptionChanged',
  BookToCChapterIconChanged = 'bookToCChapterIconChanged',
  BookToCChapterVariantsChanged = 'bookToCChapterVariantsChanged',
  BookToCChapterExtraDataChanged = 'bookToCChapterExtraDataChanged',

  BookToCElementCreated = 'bookToCElementCreated',
  BookToCElementRemoved = 'bookToCElementRemoved',
  BookToCElementMoved = 'bookToCElementMoved',
  BookToCElementVisibilityChanged = 'bookToCElementVisibilityChanged',
  BookToCElementContentRoleChanged = 'bookToCElementContentRoleChanged',
  BookToCElementThemeChanged = 'bookToCElementThemeChanged',
  BookToCElementTitleChanged = 'bookToCElementTitleChanged',
  BookToCElementDescriptionChanged = 'bookToCElementDescriptionChanged',
  BookToCElementHrefChanged = 'bookToCElementHrefChanged',
  BookToCElementTitleImageChanged = 'bookToCElementTitleImageChanged',
  BookToCElementIconChanged = 'bookToCElementIconChanged',
  BookToCElementVariantsChanged = 'bookToCElementVariantsChanged',
  BookToCElementExtraDataChanged = 'bookToCElementExtraDataChanged',
  BookToCElementActivityInserted = 'bookToCElementActivityInserted',

  ActivityCreated = 'activityCreated',
  ActivityDeleted = 'activityDeleted', // not implemented
  ActivityDuplicated = 'activityDuplicated', // not implemented
  ActivityRenamed = 'activityRenamed',
  ActivityKindChanged = 'activityKindChanged',
  ActivityNavigationChanged = 'activityNavigationChanged',
  ActivityAuthorChanged = 'activityAuthorChanged',
  ActivityAnnouncedDateChanged = 'activityAnnouncedDateChanged',
  ActivityMetadataTitleChanged = 'activityMetadataTitleChanged',
  ActivityMetadataDescriptionChanged = 'activityMetadataDescriptionChanged',
  ActivityMetadataHrefChanged = 'activityMetadataHrefChanged',
  ActivityMetadataTitleImageChanged = 'activityMetadataTitleImageChanged',
  ActivityExtraDataChanged = 'activityExtraDataChanged',
  ActivityPublicationStatusChanged = 'activityPublicationStatusChanged',

  ActivityPageAdded = 'activityPageAdded',
  ActivityPageVariantsChanged = 'activityPageVariantsChanged',
  ActivityPageVisibilityChanged = 'activityPageVisibilityChanged',
  ActivityPageMoved = 'activityPageMoved',
  ActivityPageRemoved = 'activityPageRemoved',
  ActivityPreferredPageThumbnailChanged = 'activityPreferredPageThumbnailChanged',

  PageCreated = 'pageCreated',
  PageDuplicated = 'pageDuplicated',
  PageConverted = 'pageConverted',
  PageAuthorChanged = 'pageAuthorChanged',
  PageThumbnailViewportChanged = 'pageThumbnailViewportChanged',
  PageContentTagsChanged = 'pageContentTagsChanged',

  ContentTagCreated = 'contentTagCreated',
  ContentTagUpdated = 'contentTagUpdated',
  ContentTagDeleted = 'contentTagDeleted',
  ContentTagsMerged = 'contentTagsMerged',
}
