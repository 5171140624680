import React from 'react';
import styled from 'styled-components';
import { AvatarSizes } from './AvatarSizes';

export interface IAvatarProps {
  readonly size?: AvatarSizes;
  readonly src: string;
  readonly label: string;
}

export const Avatar: React.FC<IAvatarProps> = (
  {
    src,
    label,
    size = AvatarSizes.medium,
  },
) => {

  return (
    <AvatarImage
      src={src}
      alt={label}
      title={label}
      size={size.valueOf()}
    />
  );
};

const AvatarImage = styled.img<{size: number}>`
  user-select: none;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: 100%;
  align-self: center;
  border: 2px solid white;
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25));
`;
