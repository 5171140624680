import { AbstractFormatter } from '../../elements/formats/AbstractFormatter';
import { CardShortNameFormatter } from '../../elements/formats/cards/CardShortNameFormatter';
import { AbstractApplyFormat } from '../../funcs/formats/AbstractApplyFormat';
import { CultureInfo } from '../../localization/CultureInfo';

/**
 *
 */
export class CardShortName extends AbstractApplyFormat {

  /**
   *
   */
  protected getFormat(culture:CultureInfo):AbstractFormatter{
    return new CardShortNameFormatter(culture);
  }

}
