import React from 'react';
import styled from 'styled-components';

export interface ITabPanelsProps {
  value: number;
  className?: string;
}

export const TabPanels: React.FC<ITabPanelsProps> = ({ children, className, value }) => {
  const panels = React.Children.toArray(children);
  const selectedPanel = panels[value];
  return (
    <Container
      className={className}
    >
      {selectedPanel}
    </Container>
  );
};

const Container = styled.div`
`;
