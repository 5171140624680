(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory();
	else if(typeof define === 'function' && define.amd)
		define("@scolab/publisher", [], factory);
	else if(typeof exports === 'object')
		exports["@scolab/publisher"] = factory();
	else
		root["@scolab/publisher"] = factory();
})(self, () => {
return 