import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WString } from '../../elements/tokens/WString';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Retourne un nom de famille de l'une des listes de noms de famille.
 */
export class LastName extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 0){
      return args.expectingArguments(0, 0);
    }

    // keep in alphabetical order
    const lastNameLists:any[] =
      ['chineselastnames',
       'englishlastnames',
       'frenchlastnames',
       'italianlastnames',
       'mexicanlastnames'];

    const listName:string =
      lastNameLists[args.prng.randomIndex(lastNameLists.length)];

    const name:string =
      args.env.names.getNames(listName).nextName();

    return new WString(name);
  }

}
