import { isEqual } from 'lodash';
import { ISignal, Signal } from '@robotlegsjs/signals';
import { action, computed, observable, runInAction, toJS } from 'mobx';
import { IGenericStoreUndoManager } from './interfaces/IGenericStoreUndoManager';
import { OperationToken } from './OperationToken';
import { OperationsTrackerStore } from './OperationsTrackerStore';
import { applySnapshot, getSnapshot, ISnapshot, ISnapshotData, onSnapshot } from './snapshotUtils';
import { Injector } from '../dependencyInjection/Injector';
import { inject } from '../dependencyInjection/inject';

/**
 * Scenarios to support:
 *  - Async operations that are chained (Redo: Restore activityId -> fetch activity, Restore pages VS fetch pages,
 *    put a default focused item). See operationToken
 *  - Undo/Redo operations that could need validation from server side (could state a major diff now exist due to concurrent session)
 *  - Time-based or constraint based operation like slowly typing a word. (Can be managed at a component level..)
 *  - Fastforwardable operation
 *  - Every UndoRedoStore can manage multiple scope of undo redo for a given Store (see ContextModelStore,
 *    one series of contexts for each pages)
 *
 */

export interface IOperation {
  snapshot: ISnapshotData;
  storePath: string;
  type: 'undoRedo';
  operators: { undo: () => void, redo: () => void };
  undoManager: IGenericStoreUndoManager;
  operationToken?: OperationToken;
  isFastforwardable?: boolean;
}

export class GenericStoreUndoManager implements IGenericStoreUndoManager {

  public operationToken: OperationToken;

  public storePath: string = null;

  @inject(OperationsTrackerStore)
  private operationsTracker: OperationsTrackerStore;

  private tokenInUndoRedoSequence: OperationToken;

  private guard?: () => boolean | null;

  @observable
  public undoStack: IOperation[];

  @observable
  public redoStack: IOperation[];

  private ready:boolean = false;

  private _availabilityChanged:ISignal = new Signal(GenericStoreUndoManager, Boolean);

  private _operationAppended:ISignal = new Signal(Object);

  private _suspended: boolean;

  private beforeRestoringSnapshot?: (snapshot: ISnapshotData) => void;

  private afterUndoRedo?: () => void;

  /**
   *
   * @param actionHandler
   */
  public constructor(storePath: string, guard?: () => boolean | null, beforeRestoringSnapshot?: (snapshot: ISnapshotData) => void, afterUndoRedo?: () => void) {
    runInAction(() => {
      this.storePath = storePath;
      this.beforeRestoringSnapshot = beforeRestoringSnapshot;
      this.afterUndoRedo = afterUndoRedo;
      this.guard = guard;
      this.undoStack = [];
      this.redoStack = [];
    });
  }

  public get isAvailable():boolean {
    return this.ready;
  }

  public set isAvailable(value:boolean) {
    this.ready = value;
    this._availabilityChanged.dispatch(this, this.isAvailable);
  }

  public get availabilityChanged():ISignal {
    return this._availabilityChanged;
  }

  public get operationAppended():ISignal {
    return this._operationAppended;
  }

  @action.bound
  public redo(): void {
    this.tokenInUndoRedoSequence = this.operationsTracker.trackCurrentOperation();
    const operation = this.redoStack.pop();
    applySnapshot(this.storePath, toJS(operation.snapshot.data), this.beforeRestoringSnapshot);
    this.undoStack.push(operation);
    this.afterUndoRedo?.();
  }

  @action.bound
  public undo(): void {
    this.tokenInUndoRedoSequence = this.operationsTracker.trackCurrentOperation();
    const operation = this.undoStack.pop();
    if (this.undoStack.length > 0) {
      applySnapshot(this.storePath, toJS(this.undoStack[this.undoStack.length - 1].snapshot.data), this.beforeRestoringSnapshot);
      this.redoStack.push(operation);
    }
    this.afterUndoRedo?.();
  }

  @computed
  public get canRedo(): boolean {
    return this.redoStack.length > 0;
  }

  @computed
  public get canUndo(): boolean {
    return this.undoStack.length > 1;
  }

  public set suspended(value: boolean) {
    this._suspended = value;
  }

  public get suspended(): boolean {
    return this._suspended;
  }

  @action.bound
  public initialize(StoreConstructor: { new(): any }): void {
    this.ensureStoreExists(StoreConstructor, this.storePath);
    this.undoStack = [];
    this.redoStack = [];
    this.isAvailable = true;

    const snapshot = getSnapshot(this.storePath);

    if (snapshot.data && Object.entries(snapshot.data).length !== 0) {
      // NOTE: do not create empty snapshot entry in ledger, mobx will go blam when applying those.
      this.undoStack.push({
        snapshot,
        storePath: this.storePath,
        type: 'undoRedo',
        operators: { undo: this.undo, redo: this.redo },
        undoManager: this,
      });
    }
    this.listenToActionsSnapshot();
    // NOTE: everytime a mobx.action occur, mmlpx/snapshot.ts will evaluate a mobx.reaction (l:155) with a predicate
    // that check if the snapshot changed; if it did onSnapshot.onChange will be invoked.
  }

  public suspendListenToActions(): void {
    this.suspended = true;
  }

  public resumeListenToActions(): void {
    this.suspended = false;
  }

  public removeOldestUndoOperation(): void {
    this.undoStack.shift();
  }

  private listenToActionsSnapshot(): void {
    onSnapshot(
      this.storePath,
      (snapshot: ISnapshot) => {
        runInAction(() => {
          if (this.suspended) {
            return;
          }
          // Issue here, must match previous change of the storePath that changed...
          if (this.undoStack.length > 0 && isEqual(this.undoStack[this.undoStack.length - 1].snapshot, snapshot)) {
            return;
          }
          const operation: IOperation = {
            snapshot,
            storePath: this.storePath,
            type: 'undoRedo',
            operators: { undo: this.undo, redo: this.redo },
            operationToken: this.operationToken || this.operationsTracker.trackCurrentOperation(),
            undoManager: this,
          };
          if (this.tokenInUndoRedoSequence && operation.operationToken === this.tokenInUndoRedoSequence) {
            return; // NOTE: when a tokenInUndoRedoSequence is executed, we do not want to create new entry in our UndoRedo history
          }
          this.undoStack.push(operation);
          this.redoStack = [];
          this._operationAppended.dispatch(operation);
        });
      },
      this.guard
    );
  }

  private ensureStoreExists = (StoreConstructor: { new(): any }, storeName: string) => {
    Injector.get(StoreConstructor, storeName);
  }

}
