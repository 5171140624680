import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WFactors } from '../../elements/tokens/WFactors';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class Factors extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 1){
      return args.expectingArguments(1, 1);
    }
    if(args.getFactors(0)){
      return args.getFactors(0);
    }
    if(args.getReals(0)){
      return new WFactors(args.getReals(0).toReals());
    }
    if(args.getFiniteSet(0)){
      return new WFactors(args.getFiniteSet(0).toElements());
    }
    return null;
  }

}
