import { MathError } from '../../core/MathError';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { RealsImpl } from '../../elements/utils/RealsImpl';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Take a matrix in parameter and subtract all column to the first
 * one. Add a column with the result.
 *
 * | 1 2 3 |
 * | 1 1 1 |
 * | 1 2 3 |
 *
 * operation
 * | 1 - 2 - 3 = -4 |
 * | 1 - 1 - 1 = -1 |
 * | 1 - 2 - 3 = -4 |
 *
 */
export class DiffColumn extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 1){
      return args.expectingArguments(1, 1);
    }
    if(args.getMatrix(0)){
      return this.diff(args.getMatrix(0), args.env.reals);
    }
    return null;
  }

  /**
   *
   */
  private diff(m:WMatrix, reals:RealsImpl):WMatrix {
    if( m.columns < 2 ) {
      throw new MathError('DiffColumn need 2 or more column.');
    }

    let result:RealElement[] = [];
    let diff:RealElement;
    let row:RealElement[];

    for(let i:number = 0; i < m.rows; i++) {
      row = m.getRow(i);

      for( let j:number = 0; j < row.length; j++ ) {
        if( j === 0 ) {
          diff = row[j];
        } else {
          diff = reals.subtract(diff, row[ j ]);
        }
      }

      result = result.concat(row);
      result.push(diff);
    }

    return new WMatrix(result, m.columns+1, m.formatter);
  }
}
