import { IDictionary } from '../../../js/utils/IDictionary';

import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { DictionaryFormatter } from '../../elements/formats/numbers/DictionaryFormatter';
import { WFormat } from '../../elements/tokens/WFormat';
import { WList } from '../../elements/tokens/WList';
import { WListOfString } from '../../elements/tokens/WListOfString';
import { WString } from '../../elements/tokens/WString';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';

/**
 *
 */
export class DictionaryFormat extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 2){
      return args.expectingArguments(2, 2);
    }
    if (args.getReals(0) && args.getString(1)) {
      return this.create(args.getReals(0), args.getString(1), args.env);
    }
    if (args.getReals(0) && args.getStrings(1)) {
      return this.create2(args.getReals(0), args.getStrings(1), args.env);
    }
    return null;
  }

  /**
   *
   */
  private create(
      keys:WList,
      values:WString,
      env:Environment):WFormat{

    // comma-separated list
    const valuesToText:IDictionary = {};
    const valuesToSpeak:IDictionary = {};
    const values2:any[] = values.getString().split(',');
    for(let i:number = 0 ; i < Math.min(keys.count, values2.length) ; i++){
      const key:string = String(keys.getValueAt(i));
      valuesToText[key] = values2[i];
      valuesToSpeak[key] = values2[i];
    }

    return new WFormat(
      new DictionaryFormatter(
        env.culture,
        valuesToText,
        valuesToSpeak));
  }

  /**
   *
   */
  private create2(
      keys:WList,
      values:WListOfString,
      env:Environment):WFormat{

    const valuesToText:IDictionary = {};
    const valuesToSpeak:IDictionary = {};
    for(let i:number = 0 ; i < Math.min(keys.count, values.count) ; i++){
      const key:string = String(keys.getValueAt(i));
      const value:WString = values.getTypedItemAt(i);
      valuesToText[key] = value.getString();
      if(value.toSpeakText()){
        valuesToSpeak[key] = value.toSpeakText();
      }else{
        valuesToSpeak[key] = value.getString();
      }
    }

    return new WFormat(
      new DictionaryFormatter(
        env.culture,
        valuesToText,
        valuesToSpeak));
  }

}
