import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WInterval } from '../../elements/tokens/WInterval';
import { WList } from '../../elements/tokens/WList';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { ListElement } from '../../elements/abstract/ListElement';
import { Environment } from '../../expr/Environment';
import { ListOfListElement } from '../../elements/abstract/ListOfListElement';

/**
 * Retire certains éléments d'une liste.
 */
export class Remove extends FunctionElement {
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 2) {
      return args.expectingArguments(2, 2);
    }

    const [listOfList, ordinal] = [args.getListOfList(0), args.getReal(1)];
    if (listOfList && ordinal.isNaturalNumber()) {
      return this.listOfList(listOfList, ordinal.toNumber());
    }

    const [reals, interval] = [args.getReals(0), args.getInterval(1)];
    if (reals && interval) {
      return this.listInterval(reals, interval);
    }

    const [list, removeValues] = [args.getList(0), args.getList(1)];
    if (list && removeValues) {
      return this.list(list, removeValues, args.env);
    }

    return null;
  }

  /**
   * Retire toutes les occurences d'une nombre dans une liste.
   */
  private list(list: ListElement, removeValues: ListElement, env: Environment): ContentElement {
    if (list.count === 0 || removeValues.count === 0) {
      return list;
    }
    if (list.getListItemCode() !== removeValues.getListItemCode()) {
      return list;
    }

    const items: ContentElement[] = [];
    list: for (const item of list.items) {
      for (const removeValue of removeValues.items) {
        if (item.equalsTo(removeValue)) {
          continue list;
        }
      }
      items.push(item);
    }

    return env.culture.listFactory.createList(items).applyFormat(list.formatter);
  }

  /**
   * Retire tous les nombres qui sont dans l'intervalle spécifié.
   */
  private listInterval(list: WList, interval: WInterval): WList {
    const items: ContentElement[] = [];
    for (const item of list.items) {
      if (!interval.contains((item as RealElement).toNumber())) {
        items.push(item);
      }
    }
    return new WList(items, list.formatter2);
  }

  private listOfList(list: ListOfListElement, ordinal: number): ListOfListElement {
    if (list.count === 0 || ordinal < 0 || ordinal >= list.count) {
      return list;
    }

    const indices: number[] = [];
    for (let i = 0; i < list.count; i++){
      if (i + 1 !== ordinal) {
        indices.push(i);
      }
    }

    return list.transform(indices) as ListOfListElement;
  }
}
