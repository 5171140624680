import { runInAction } from 'mobx';
import { ActivityStore } from '../models/store/ActivityStore';
import { IActivity } from '../models/IActivity';
import { instantiate } from '../dependencyInjection/instantiate';

export function setCurrentActivityCommand(activity: IActivity) {
  runInAction(() => {
    const store = instantiate(ActivityStore);
    store.setCurrentActivity(activity);
  });
}
