import { XSort } from '../core/XSort';
import { XString } from '../core/XString';
import { ContentElement } from '../elements/abstract/ContentElement';
import { ListElement } from '../elements/abstract/ListElement';
import { Node } from '../elements/abstract/Node';
import { WList } from '../elements/tokens/WList';
import { IWriter } from '../expr/conversion/writers/IWriter';
import { CultureInfo } from '../localization/CultureInfo';
import { KeyboardConfiguration } from './KeyboardConfiguration';
import { CommonError } from './CommonError';
import { BaseCorrector } from './BaseCorrector';

/**
 *
 */
export class CList extends BaseCorrector {

  /**
   *
   */
  public parse(value:string):Node{
    try{
      const items:any[] = this.parseList(this.sanitizeInput(value));
      const list:ListElement = this.env.culture.listFactory.createFromArray(items, 'real');

      if(list){
        const node:Node = new Node(list);
        node.userData = 'Listify(' + items.join(', ') + ')';
        return node;
      }
      return null;
    }catch(e){
      return null;
    }
  }

  /**
   *
   */
  public correct(
      value:string,
      target:ContentElement,
      ...targets:any[]):boolean{

    return this.listValidation(this.sanitizeInput(value), <WList>target );

  }

  /**
   *
   */
  private listValidation(
      value:string,
      target:WList):boolean{

    const list1:any[] = this.parseList(value);
    const list2:any[] = target.toNumbers();

    if(!this.compareLists(list1, list2)){

      const list3 = this.parseListSpecialFrCase(value);
      if(this.compareLists(list3, list2)){
        // Espace manquant après une virgule.
        this.raiseError(CommonError.LIST_MISSING_SPACE_AFTER_COMMA);
      }

      const list4 = this.parseListIntegers(value);
      if(this.compareLists(list4, list2)){
        // Espaces manquant après les virgules.
        this.raiseError(CommonError.LIST_MISSING_SPACES_AFTER_COMMAS);
      }

      return false;
    }

    return true;
  }

  /**
   * Special case of list parsing when the decimal separator is the same
   * as the item separator. If the list has two elements, and the
   * user writes "10 000,11 000", is it one decimal number or two?
   * It should be one decimal number since the comma is not followed by a space
   * which would indicate an item separator. But we want to relax that condition in
   * the case where we have only two numbers.
   */
  private parseListSpecialFrCase(valueArg:string):any[]{
    let value = valueArg;
    if(this.env.culture.numberFormatter.decimalSeparator === ','){
      if(value.indexOf(',') === value.lastIndexOf(',') && value.indexOf(',') !== -1){
        value = value.split(',').join(', ');
      }
    }
    return this.parseList(value);
  }

  /**
   * Special case of list parsing when the decimal separator is the same
   * as the item separator. Parse the list as if there were no decimal
   * separators by making sure there's a space after each comma.
   */
  private parseListIntegers(valueArg:string):any[]{
    let value = valueArg;
    if(this.env.culture.numberFormatter.decimalSeparator === ','){
      value = value.replace(/,\s+/g, ',').replace(/,/g, ', ');
    }
    return this.parseList(value);
  }

  /**
   *
   */
  private compareLists(list1:any[], list2:any[]):boolean{
    // Vérifie qu'il y a le même nombre d'items dans les deux listes à comparer
    if(list1.length !== list2.length){
      return false;
    }

    if(!this.options.ordered){ // L'ordre n'a pas d'importance alors on ordonner les nombres avant de les comparer par index
      list1.sort(XSort.numeric);
      list2.sort(XSort.numeric);
    }

    for(let i:number = 0 ; i < list1.length ; i++){
      if(list1[i] !== list2[i]){
        return false;
      }
    }

    return true;
  }

  /**
   *
   */
  public writeTo(
      w:IWriter,
      target:ContentElement,
      ...targets:any[]):void{

    const list:WList = <WList>target ;
    const s:string = list.toText(true);

    if(s != null){
      if(XString.isEnclosed(s, '(', ')')){
        w.openParenthesis();
        w.writeRaw(s.substring(1, s.length - 1));
        w.closeParenthesis();
      }else if(XString.isEnclosed(s, '{', '}')){
        w.openBrace();
        w.writeRaw(s.substring(1, s.length - 1));
        w.closeBrace();
      }else{
        w.writeRaw(s);
      }
      return;
    }

    let n:number;
    let i:number;

    const o:string[] = [];
    for(i = 0 ; i < list.count ; i++){
      n = list.getValueAt(i);
      o.push(this.env.culture.formatNumber(n));
    }

    const listSeparator:string = this.env.culture.listFormatter.outputSeparator(o.join('\n'));

    for(i = 0 ; i < list.count ; i++){
      if(i > 0){
        w.writeRaw(`${listSeparator} `);
      }
      w.writeNumber(list.getValueAt(i));
    }
  }

  /**
   *
   */
  public get mathKeyboard():number{
    return KeyboardConfiguration.NUMERIC;
  }

  /**
   *
   */
  private parseList(valueArg:string):any[]{
    const value = valueArg.replace(/−/g, '-');

    if(!value){
      return [];
    }

    const items:any[] = this.parseItems(value, this.env.culture);
    let i:number = 0;
    while(i < items.length){
      const item:string = items[i];
      const n:number = this.numberParser.parseNumber(item);
      if(item === ''){
        items.splice(i, 1);
      }else if(isNaN(n)){
        this.raiseError(CommonError.LIST_FORMAT_INVALID);
      }else{
        items[i] = n;
        i++;
      }
    }

    return items;
  }

  /**
   *
   */
  public parseItems(valueArg:string, culture:CultureInfo):any[]{
    let value = valueArg;
    const decimalSeparator:string = culture.numberFormatter.decimalSeparator;
    let items:any[];

    value = XString.trimParenthesis(value);
    value = XString.trimBraces(value);

    if(decimalSeparator === '.'){
      value = value.replace(/ /g, ''); // On ignore les espace seulement si le séparateur décimal n'est pas une virgule
      if(value.indexOf(';') !== -1){
        items = value.split(';');
      }else{
        items = value.split(',');
      }
    }else if(decimalSeparator === ','){
      value = XString.trim(value);
      if(isNaN(Number(value))){
        if(value.indexOf(' ') === -1 && value.indexOf(';') === -1){
          // Lors de la correction on exige des espaces après la virgule
          // s'il s'agit d'un séparateur d'items. Donc si on a 1,2,3,4 on considère comme
          // étant une liste 1, 2, 3, 4
          value = value.split(',').join(', ');
        }
      }
      value = value.split(', ').join(';');
      items = value.split(';');
    }
    return items;
  }

}
