import { XRound } from '../../core/XRound';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';
import { XMath } from '../../core/XMath';

/**
 * Arrondissement monétaire.
 *
 * Tient compte des règles d'arrondissement pour la monnaie.
 * Par exemple l'arrondissement aux 5¢ pour la monnaie canadienne.
 */
export class RoundMoney extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 1){
      return args.expectingArguments(1, 1);
    }

    if(args.getReal(0)) {
      return this.round(args.getReal(0), args.env);
    }
    return null;
  }

  /**
   *
   */
  private round(amount:RealElement, env:Environment):RealElement{
    let value:number = amount.toNumber();

    // a. Round to the currency precision first
    value = XRound.halfAway(value, env.culture.currency.precision);

    // b. Apply currency specific rounding
    if(env.culture.currency.rounding === '5-cents-rule'){
      const cents:number = XRound.safeRound(value * 100) % 10;
      const temp:number = XRound.safeRound(Math.floor(value * 10) / 10);

      switch(cents){
        case 1:
        case 2:
          value = temp; // down
          break;
        case 6:
        case 7:
          value = XMath.safeAdd(temp, 0.05); // down
          break;
        case 3:
        case 4:
          value = XMath.safeAdd(temp, 0.05); // down
          break;
        case 8:
        case 9:
          value = XMath.safeAdd(temp, 0.1); // up
          break;
        case 0:
        case 5:
          break;
      }
    }

    return env.culture.createNumber(value);
  }

}
