import React from 'react';
import styled, { keyframes } from 'styled-components';
export const SPINNER_TEST_ID = 'SPINNER_TEST_ID';

export const Spinner = () => {
  return (
    <SpinnerElement
      data-testid={SPINNER_TEST_ID}
    />
  );
};

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const SpinnerElement = styled.div`
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);

  border-top: 2px solid grey;
  border-right: 2px solid grey;
  border-bottom: 2px solid grey;
  border-left: 2px solid black;
  background: transparent;
  width: 24px !important;
  height: 24px !important;
  border-radius: 50%;

  position: absolute;
  top: 50%;
  left: 50%;
`;
