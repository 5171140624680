import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { scrollToElement } from '../../../utils/scrollUtils';
import { IFilterResult } from '../../../utils/filtering';
import {
  IAutocompleteSuggestion,
  IPosition,
} from './BaseSyntaxHighlightInput';

interface ISuggestionListProps {
  suggestions: ReadonlyArray<IFilterResult<IAutocompleteSuggestion>>;
  selectedSuggestionIndex: number;
  onSelectSuggestion: (suggestionIndex: number) => void;
  anchorPosition: IPosition;
}

export class SuggestionList extends React.Component<ISuggestionListProps> {
  private containerRef: React.RefObject<HTMLDivElement>;

  constructor(props: ISuggestionListProps) {
    super(props);
    this.containerRef = React.createRef();
  }

  public componentDidUpdate(prevProps: ISuggestionListProps) {
    if (this.props.suggestions !== prevProps.suggestions ||
        this.props.selectedSuggestionIndex !== prevProps.selectedSuggestionIndex) {
      const container = this.containerRef.current;
      if (container) {
        scrollToElement(container, container.children[this.props.selectedSuggestionIndex]);
      }
    }
  }

  public render(): React.ReactNode {
    const {
      suggestions,
      onSelectSuggestion,
      anchorPosition,
      selectedSuggestionIndex,
    } = this.props;

    if (!suggestions || suggestions.length === 0 || !anchorPosition) {
      return null;
    }

    const list = suggestions.map((suggestion, index) => {
      const onClick = () => onSelectSuggestion(index)
      const isSelected = selectedSuggestionIndex === index;
      return suggestion.value.renderFn(suggestion, isSelected, onClick);
    });

    return ReactDOM.createPortal(
      (
        <Container
          anchorPosition={anchorPosition}
          data-testid='SuggestionList'
          ref={this.containerRef}
        >
          {list}
        </Container>
      ),
      document.body,
    );
  }
}

interface IContainer {
  anchorPosition: IPosition;
}

const Container = styled.div<IContainer>`
  position: absolute;
  top: ${(props) => props.anchorPosition.y}px;
  left: ${(props) => props.anchorPosition.x}px;
  display: flex;
  flex-direction: column;
  min-width: 288px;
  background-color: ${(props) => props.theme.colorset.darkness};
  color: ${(props) => props.theme.colorset.white};
  cursor: pointer;
  max-height: 408px;
  overflow: overlay;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
`;
