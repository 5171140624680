
const isArray = (possibleArray: number | ReadonlyArray<number>): possibleArray is ReadonlyArray<number> => {
  return typeof possibleArray === 'object';
};

export function findDropTargetIndex(targetIndex: number, selectedItemIndexOrIndexes: number | ReadonlyArray<number>): number {
  if (isArray(selectedItemIndexOrIndexes)) {
    let validatedTargetIndex = targetIndex;
    selectedItemIndexOrIndexes.forEach((itemIndex) => {
      if (itemIndex < targetIndex) {
        validatedTargetIndex -= 1;
      }
    });
    return validatedTargetIndex;
  }
  return targetIndex > selectedItemIndexOrIndexes ? targetIndex - 1 : targetIndex;
  
}
