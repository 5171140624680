import { MElement } from '../../../core/mml/MElement';
import { ContentElement } from '../../../elements/abstract/ContentElement';
import { WBoolean } from '../../../elements/tokens/WBoolean';
import { WMarkup } from '../../../elements/tokens/WMarkup';
import { ArgumentsObject } from '../../../expr/ArgumentsObject';
import { MarkupAsserts } from '../../../funcs/asserts/markup/MarkupAsserts';

/**
 * Returns true if an exponent is attached to a
 * fraction without the parenthesis to
 * make it clear that the script is attached to
 * the fraction instead of the numerator.
 */
export class HasAmbiguousFractionScript extends MarkupAsserts {

  public callReturnElement(args:ArgumentsObject):ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }

    if(args.getMarkup(0) ){
      return this.check(args.getMarkup(0));
    }
    return null;
  }

  /**
   *
   */
  private check(value:WMarkup):WBoolean{
    let o:MElement[] = [];
    o.push(value.toElement());

    while(o.length > 0){
      const e:MElement = o.pop();
      o = o.concat(e.children);

      if(e.name !== 'msup'){
        continue;
      }
      if(e.children.length === 0){
        continue;
      }

      let base:MElement = e.children[0];
      while(base.children.length === 1 && HasAmbiguousFractionScript.isRowLike(base)){
        base = base.children[0];
      }
      if(base.name === 'mfrac'){
        return WBoolean.TRUE;
      }
    }

    return WBoolean.FALSE;
  }

  /**
   *
   */
  private static isRowLike(node:MElement):WBoolean{
    if(String(node.name) === 'mrow'){
      return WBoolean.TRUE;
    }
    if(String(node.name) === 'mstyle'){
      return WBoolean.TRUE;
    }
    if(String(node.name) === 'mfenced'){
      if(node.attributes.hasOwnProperty('open') && node.attributes.hasOwnProperty('close')){
        return WBoolean.parse(node.attributes.open === '' && node.attributes.close === '');
      }
      return WBoolean.FALSE;
    }
    return WBoolean.FALSE;
  }

}
