import { BasicWriter } from '../../../core/mml/BasicWriter';
import { MmlWriter } from '../../../core/mml/MmlWriter';
import { FunctionElement } from '../../../elements/abstract/FunctionElement';
import { FormatProvider } from '../../../elements/factories/FormatProvider';
import { IMarkupExporter } from '../../../elements/markers/IMarkupExporter';
import { WMarkup } from '../../../elements/tokens/WMarkup';
import { Environment } from '../../../expr/Environment';
import { MarkupExporter } from '../../../expr/conversion/output/MarkupExporter';

/**
 *
 */
export class MarkupAsserts extends FunctionElement {

  /**
   *
   */
  protected getElementNames(value:WMarkup, env:Environment):string[]{
    const w:BasicWriter = new BasicWriter();
    const m:MmlWriter = new MmlWriter(w);
    const e:IMarkupExporter =
      new MarkupExporter(
        m,
        new FormatProvider(env.culture),
        false);
    value.writeTo(e);
    return w.elementNames;
  }

}
