import { Point } from '../../../../js/geom/Point';

import { XGeom } from '../../../core/XGeom';
import { WLine } from '../../../elements/tokens/WLine';
import { IGeomTransform } from '../../../elements/models/transforms/IGeomTransform';

/**
 *
 */
export class OrthogonalProjectionImpl implements IGeomTransform {

  private axis:WLine;

  /**
   *
   */
  constructor(axis:WLine) {

    this.axis = axis;
  }

  /**
   *
   */
  public transformPoint(value:Point):Point{
    const i:Point = this.axis.orthogonalProjection(value);
    return XGeom.safePoint(i);
  }

}
