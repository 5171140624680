import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { ListElement } from '../../elements/abstract/ListElement';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { ListOfListElement } from '../../elements/abstract/ListOfListElement';

export class RemoveAt extends FunctionElement {
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 2) {
      return args.expectingArguments(2, 2);
    }

    const [listOfList, index] = [args.getListOfList(0), args.getReal(1)];
    if (listOfList && index.isWholeNumber()) {
      return this.listOfList(listOfList, index.toNumber());
    }

    const [list, indices] = [args.getList(0), args.getReals(1)];
    if (list && indices) {
      return this.list(list, indices.toNumbersV());
    }

    return null;
  }

  private list(list: ListElement, indices: number[]): ListElement {
    if (list.count === 0 || indices.length === 0) {
      return list;
    }
    this.wasCondensedWork = true;

    const keep: number[] = [];
    for (let i = 0; i < list.count; i++) {
      if (!indices.includes(i)) {
        keep.push(i);
      }
    }

    return list.transform(keep);
  }

  private listOfList(list: ListOfListElement, index: number): ListOfListElement {
    if (list.count === 0 || index < 0 || index >= list.count) {
      return list;
    }
    this.wasCondensedWork = true;

    const indices: number[] = [];
    for (let i = 0; i < list.count; i++){
      if (i !== index) {
        indices.push(i);
      }
    }

    return list.transform(indices) as ListOfListElement;
  }
}
