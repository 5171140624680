import { ContentElement } from '../../elements/abstract/ContentElement';
import { ElementCodes } from '../../elements/abstract/ElementCodes';
import { OperatorElement } from '../../elements/abstract/OperatorElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { TokenElement } from '../../elements/abstract/TokenElement';
import { WBoolean } from '../../elements/tokens/WBoolean';
import { WFiniteSet } from '../../elements/tokens/WFiniteSet';
import { WInterval } from '../../elements/tokens/WInterval';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Pas élément de?
 */
export class NotElementOf extends OperatorElement {

  /**
   *
   */
  public toString():string{return '∉';}

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 2){
      return args.expectingArguments(2, 2);
    }

    if(args.getReal(0) && args.getInterval(1)){
      return this.notElementOfInterval(args.getReal(0), args.getInterval(1));
    }
    if(args.getTokenElement(0) && args.getFiniteSet(1)){
      return this.notElementOfSet(args.getTokenElement(0), args.getFiniteSet(1));
    }

    return null;
  }

  /**
   *
   */
  private notElementOfInterval(value:RealElement, interval:WInterval):WBoolean{
    return WBoolean.parse(!interval.contains(value.toNumber()));
  }

  /**
   *
   */
  private notElementOfSet(
      value:TokenElement,
      finiteSet:WFiniteSet):WBoolean{

    return WBoolean.parse(!finiteSet.contains(value));
  }

  /**
   *
   */
  public getElementCode():string{
    return ElementCodes.OP_NOT_ELEMENT_OF;
  }

}
