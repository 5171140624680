import { XRound } from '../../core/XRound';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { TokenElement } from '../../elements/abstract/TokenElement';
import { WInterval } from '../../elements/tokens/WInterval';
import { WList } from '../../elements/tokens/WList';
import { WListOfIntervals } from '../../elements/tokens/WListOfIntervals';
import { WSequence } from '../../elements/tokens/WSequence';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';

/**
 * Index of a value inside a sequence.
 * If value is a list then returns all indices for the values.
 * If value is an interval then returns an interval with lBound and rBound being indices.
 */
export class IndexOf extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 2){
      return args.expectingArguments(2, 2);
    }

    const sequence:WSequence = args.getSequence(0);
    if(!sequence){
      return null;
    }

    if(sequence.isNumeric){
      let indices:ContentElement[];
      let i:number;
      if(args.getReal(1)){
        return this.indexOfN(sequence, args.getReal(1), args.env);
      }
      if(args.getReals(1)){
        const values:WList = args.getReals(1);
        if(values.count === 0){
          return values;
        }
        indices = [];
        for(i = 0 ; i < values.count ; i++){
          indices.push(this.indexOfN(sequence, values.getTypedItemAt(i), args.env));
        }
        return args.env.culture.listFactory.createList(indices);
      }
      if(args.getInterval(1)){
        return this.indexOfInterval(sequence, args.getInterval(1), args.env);
      }
      if(args.getIntervals(1)){
        const intervals:WListOfIntervals = args.getIntervals(1);
        if(intervals.count === 0){
          return intervals;
        }
        indices = [];
        for(i = 0 ; i < intervals.count ; i++){
          indices.push(this.indexOfInterval(sequence, intervals.getTypedItemAt(i), args.env));
        }
        return args.env.culture.listFactory.createList(indices);
      }
    }

    const value:TokenElement = args.getTokenElement(1);
    if(!WSequence.isValidElement(value)){
      return null;
    }

    const index:TokenElement =
      args.env.expressions.divide(
        args.env.expressions.subtract(
          value,
          sequence.origin),
        sequence.step);

    return index instanceof RealElement ?
      args.env.culture.createNumber(XRound.safeRound((<RealElement>index ).toNumber())) :
      null;
  }

  /**
   * Sequence must be numeric.
   */
  private indexOfN(sequence:WSequence, n:RealElement, env:Environment):RealElement{
    const originN:RealElement = <RealElement>sequence.origin ;
    const stepN:RealElement = <RealElement>sequence.step ;
    return env.culture.createNumber(XRound.safeRound((n.toNumber() - originN.toNumber()) / stepN.toNumber()));
  }

  /**
   * Returns an interval with lBound and rBound as index.
   *
   * Sequence must be numeric.
   */
  private indexOfInterval(sequence:WSequence, interval:WInterval, env:Environment):WInterval{
    const originN:RealElement = <RealElement>sequence.origin ;
    const stepN:RealElement = <RealElement>sequence.step ;
    const lBoundI:number = interval.lBound ? XRound.safeRound((interval.lBoundN - originN.toNumber()) / stepN.toNumber()) : Number.NaN;
    const rBoundI:number = interval.rBound ? XRound.safeRound((interval.rBoundN - originN.toNumber()) / stepN.toNumber()) : Number.NaN;
    return env.culture.intervalsFactory.createIntervalN(interval.closure, lBoundI, rBoundI);
  }

}
