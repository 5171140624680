import { ContentElement } from '../../../../elements/abstract/ContentElement';
import { FunctionElement } from '../../../../elements/abstract/FunctionElement';
import { WBoolean } from '../../../../elements/tokens/WBoolean';
import { WLine } from '../../../../elements/tokens/WLine';
import { WPoint } from '../../../../elements/tokens/WPoint';
import { WSegment } from '../../../../elements/tokens/WSegment';
import { ArgumentsObject } from '../../../../expr/ArgumentsObject';

/**
 * Test d'horizontalité.
 */
export class IsHorizontal extends FunctionElement {

  public callReturnElement(args:ArgumentsObject):ContentElement {
    if (args.length < 1 || args.length > 2) {
      return args.expectingArguments(1, 2);
    }

    if(args.length === 1){
      if(args.getSegment(0)){
        return this.segment(args.getSegment(0));
      }
      if(args.getLine(0)){
        return this.line(args.getLine(0));
      }
    }else if(args.length === 2){
      if(args.getPoint(0) && args.getPoint(1)){
        return this.points(args.getPoint(0), args.getPoint(1));
      }
    }

    return null;
  }

  /**
   * Détermine si la ligne passant par ces deux points est horizontal.
   * p()
   */
  private points(a:WPoint, b:WPoint):WBoolean{
    return WBoolean.parse(a.equalsTo(b) ? false : a.y.toNumber() === b.y.toNumber());
  }

  /**
   * s()
   */
  private segment(value:WSegment):WBoolean{
    return WBoolean.parse(value.a.y === value.b.y);
  }

  /**
   *
   */
  public line(value:WLine):WBoolean{
    return WBoolean.parse(value.slope ? value.slope.toNumber() === 0 : false);
  }

}
