/**
 *
 */
export class ScriptFormat {

  public static subdigits:string = '₀₁₂₃₄₅₆₇₈₉₋';

  public static supdigits:string = '⁰¹²³⁴⁵⁶⁷⁸⁹⁻';

  public static digits:string = '0123456789-';

  public static superscript(value:number):string{
    return value.toString().split('').map(
      ScriptFormat.superscriptMapFn, null).join('');
  }

  private static superscriptMapFn(char:string, ..._:any[]):string{
    return ScriptFormat.supdigits.charAt(ScriptFormat.digits.indexOf(char));
  }

  public static subscript(value:number):string{
    return value.toString().split('').map(
      ScriptFormat.subscriptMapFn, null).join('');
  }

  private static subscriptMapFn(char:string, ..._:any[]):string{
    return ScriptFormat.subdigits.charAt(ScriptFormat.digits.indexOf(char));
  }

  public static getDigitFromSuperscript(supChar:string):string{
    return ScriptFormat.digits.charAt(ScriptFormat.supdigits.indexOf(supChar));
  }

  public static isSuperscript(char:string):boolean{
    return ScriptFormat.supdigits.indexOf(char) !== -1;
  }

  public static isSubscript(char:string):boolean{
    return ScriptFormat.subdigits.indexOf(char) !== -1;
  }

}
