import { XString } from '../../../core/XString';
import { RealElement } from '../../../elements/abstract/RealElement';
import { IMarkupExporter } from '../../../elements/markers/IMarkupExporter';

/**
 *
 */
export class PatternToken {

  public columnIndex:number = -1;

  public numbering:boolean = false;

  public space:boolean = false;

  public text:string;

  public static createSpace():PatternToken{
    const o:PatternToken = new PatternToken();
    o.space = true;
    return o;
  }

  public static createText(strArg:string):PatternToken{
    let str = strArg;
    const o:PatternToken = new PatternToken();
    // Word joiner is not necessarly the exact char that should go there.
    // What we want to do is avoid having leading or traling spaces.
    // Also note that successive spaces will be collasped by mathml parser.
    if(str.length > 0){
      if(str.charAt(0) === ' '){
        str = XString.WORD_JOINER + str;
      }
      if(str.charAt(str.length - 1) === ' '){
        str += XString.WORD_JOINER;
      }
    }

    o.text = str;
    return o;
  }

  public static createNumbering():PatternToken{
    const o:PatternToken = new PatternToken();
    o.numbering = true;
    return o;
  }

  public static createPlaceholder(columnIndex:number):PatternToken{
    const o:PatternToken = new PatternToken();
    o.columnIndex = columnIndex;
    return o;
  }

  public writeTo(
      exporter:IMarkupExporter,
      values:RealElement[],
      columns:number,
      row:number):void{

    if(this.columnIndex !== -1){
      const index:number = row * columns + this.columnIndex;
      values[index].writeTo(exporter);
      return;
    }

    if(this.numbering){
      exporter.writer.appendNumber(String(row + 1));
      return;
    }

    if(this.space){
      exporter.writer.appendSpace();
      exporter.writer.width = '20px';
      return;
    }

    exporter.writer.appendText(this.text ? this.text : '');
  }

}
