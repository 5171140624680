import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WPolynomial } from '../../elements/tokens/WPolynomial';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';

/**
 * Derivé d'un polynôme.
 */
export class Derivative extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 1){
      return args.expectingArguments(1, 1);
    }

    if(args.getReal(0)){
      return args.env.culture.createNumber(0);
    }
    if(args.getPolynomial(0)){
      return this.polynomial(args.getPolynomial(0), args.env);
    }
    return null;
  }

  /**
   *
   */
  private polynomial(value:WPolynomial, env:Environment):ContentElement{
    if(value.symbols.length !== 1){
      return null;
    }

    const copy:WPolynomial = value.clone();

    for(let m:number = 0 ; m < copy.numMonomials ; m++){
      const p:number = copy.power(m, 0);
      if(p === 0){
        copy.coefs[m] = env.culture.createNumber(0);
      }else{
        copy.powers[m] = p - 1;
        copy.coefs[m] =
          env.reals.times(
            env.culture.createNumber(p),
            copy.coefs[m]);
      }
    }

    return copy.normalize(env.reals);
  }

}
