import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { ListElement } from '../../elements/abstract/ListElement';
import { WRelation } from '../../elements/tokens/WRelation';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Inverse l'ordre des éléments dans une liste.
 */
export class Reverse extends FunctionElement {
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length === 1) {
      const relation = args.getRelation(0);
      if (relation) {
        return this.relation(relation);
      }

      const list = args.getList(0);
      if (list) {
        return this.list(list);
      }

      return null;
    }

    const listOfList = args.toListOfList();
    if (listOfList) {
      return this.list(listOfList);
    }

    return null;
  }

  private list(list: ListElement): ListElement {
    const indices: number[] = [];
    for (let i = 0; i < list.count; i++) {
      indices.unshift(i);
    }
    return list.transform(indices);
  }

  private relation(a: WRelation): WRelation {
    return new WRelation(a.rhs, a.rel.reverse, a.lhs);
  }
}
