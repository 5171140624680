/**
 *
 */
export class Pt3 {

  public _page:number = -1;

  public get page():number{return this._page;}

  public _row:number = -1;

  public get row():number{return this._row;}

  public _col:number = -1;

  public get col():number{return this._col;}

  constructor(
      page:number,
      row:number,
      col:number){
    this._page = page;
    this._row = row;
    this._col = col;
  }

  public get vol():number{
    if(this._page < 0 || this._row < 0 || this._col < 0){
      throw new Error();
    }
    return this._page * this._row * this._col;
  }

  public clone():Pt3{
    return new Pt3(this._page, this._row, this._col);
  }

}
