import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WString } from '../../elements/tokens/WString';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Retourne un prénom masculin aléatoire.
 */
export class Male extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 0){
      return args.expectingArguments(0, 0);
    }
    return new WString(args.env.names.getNames('males').nextName());
  }

}
