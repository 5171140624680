import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Suite de Fibonacci.
 */
export class Fibonacci extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 1){
      return args.expectingArguments(1, 1);
    }

    if(args.getWholeNumber(0)){
      return args.env.culture.createNumber(this.valueAt(args.getWholeNumber(0).toNumber()));
    }

    return null;
  }

  /**
   *
   */
  private valueAt(index:number):number{
    return ((1 + Math.sqrt(5)) ** index) -
        ((1 - Math.sqrt(5)) ** index) /
        ((2 ** index) * Math.sqrt(5));
  }

}
