/**
 *
 */
export class StemLeafAttributes {

  public stemLabel:string;

  public leavesLabel:string;

  public countLabel:string;

  public showLeavesCount:boolean = false;

  public showTallies:boolean = false;

  public showEmptyStems:boolean = false;

  public clone():StemLeafAttributes{
    const o:StemLeafAttributes = new StemLeafAttributes();
    o.stemLabel = this.stemLabel;
    o.leavesLabel = this.leavesLabel;
    o.countLabel = this.countLabel;
    o.showLeavesCount = this.showLeavesCount;
    o.showTallies = this.showTallies;
    o.showEmptyStems = this.showEmptyStems;
    return o;
  }

}
