import React, { CSSProperties, useEffect, useRef, useState } from 'react';

/**
 * Render an invisible div to get the width of the text
 */
export function useTextWidth(text: string, style: CSSProperties = {}, initialValue: number = 0) {
  const ref = useRef<HTMLDivElement>();
  const [textWidth, setTextWidth] = useState(initialValue);

  useEffect(() => {
    ref.current.innerText = text;
    setTextWidth(ref.current?.clientWidth ?? initialValue);
  }, [initialValue, text]);

  const textWidthDiv = (
    <div
      ref={ref}
      style={{
        width: 'auto',
        display: 'inline-block',
        visibility: 'hidden',
        position: 'fixed',
        overflow: 'auto',
        ...style,
      }}
    />
  );

  return { textWidthDiv, textWidth };
}
