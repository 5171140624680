import { Point } from '../../../../js/geom/Point';
import { ContentElement } from '../../../elements/abstract/ContentElement';
import { FunctionElement } from '../../../elements/abstract/FunctionElement';
import { WBoundVector } from '../../../elements/tokens/WBoundVector';
import { WPoint } from '../../../elements/tokens/WPoint';
import { ArgumentsObject } from '../../../expr/ArgumentsObject';

/**
 * Grandeur d'un vecteur.
 */
export class Magnitude extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length < 1 || args.length > 2){
      return args.expectingArguments(1, 2);
    }

    if(args.length === 1){
      const v:WBoundVector = args.getBoundVector(0);
      if(v){
        return args.env.culture.createNumber(v.magnitude);
      }
    }else if(args.length === 2){
      const p0:WPoint = args.getPoint(0);
      const p1:WPoint = args.getPoint(1);
      if (p0 && p1) {
        return args.env.culture.createNumber(Point.distance(p0.toPoint(), p1.toPoint()));
      }
    }

    return null;
  }

}
