import { ContentElement } from '../elements/abstract/ContentElement';
import { Node } from '../elements/abstract/Node';
import { WInfinity } from '../elements/tokens/WInfinity';
import { IWriter } from '../expr/conversion/writers/IWriter';
import { KeyboardConfiguration } from './KeyboardConfiguration';
import { InputCapabilities } from './InputCapabilities';
import { BaseCorrector } from './BaseCorrector';

/**
 *
 */
export class CInfinity extends BaseCorrector {

  public parse(value:string):Node{
    let node:Node = null;

    const infinity:WInfinity = this.parseInfinity(value);
    if(infinity){
      node = new Node(infinity);
      node.userData = infinity.toText(true);
      return node;
    }

    const n:number = this.numberParser.parseNumber(value);
    if(!isNaN(n)){
      node = new Node(this.env.culture.createNumber(n));
      node.userData = String(n);
      return node;
    }

    return null;
  }

  public correct(
      value:string,
      target:ContentElement,
      ...targets:any[]):boolean{

    const value2:WInfinity = this.parseInfinity(value);
    const target2:WInfinity = <WInfinity>target ;

    if(!value2){
      return false;
    }

    return value2.equalsTo(target2);
  }

  public get inputCapabilities():InputCapabilities{
    return super.inputWithSymbols('+−∞');
    // superscript = true; ???
  }

  public get mathKeyboard():number{
    return KeyboardConfiguration.INFINITY;
  }

  public writeTo(
      w:IWriter,
      target:ContentElement,
      ...targets:any[]):void{

    w.writeSymbol(target.toText(true));
  }

  private parseInfinity(valueArg:string):WInfinity{
    let value = valueArg;
    if(this.useLatex){
      value = this.sanitizeInput(value);
    }
    value = value.replace(/ /g, ''); // Enlève les espaces
    if(value === '−∞'){
      return WInfinity.NEGATIVE;
    }
    if(value === '∞' || value === '+∞'){
      return WInfinity.POSITIVE;
    }
    return null;
  }

}
