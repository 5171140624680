import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import EllipsisIcon from '../../assets/ellipsis-v.svg';
import { ContextMenu } from './ContextMenu';
import { preventAndStopPropagation } from '../../utils/mouse';
import { Tooltip } from './Tooltip';

export interface IEllipsisButtonProps {
  isOpen: boolean;
  className?: string;
  onSetIsOpen: (isOpen: boolean) => void;
  modalLayer?: HTMLElement;
  horizontalAlignment?: 'left' | 'right';
  icon?: React.ReactNode;
  tooltip?: React.ReactNode;
  preventCloseOnClick?: boolean;
}

export const ELLIPSIS_BUTTON_TEST_ID = 'ELLIPSIS_BUTTON_TEST_ID';

export const EllipsisButton: React.FC<IEllipsisButtonProps> = (props) => {
  const {
    className,
    children,
    isOpen,
    onSetIsOpen,
    modalLayer,
    icon,
    horizontalAlignment = 'right',
    tooltip = 'Options',
    preventCloseOnClick,
  } = props;
  const anchorRef = React.useRef<HTMLElement>(null);
  let contextMenu = null;
  if (isOpen) {
    const iconRect = anchorRef.current.getBoundingClientRect();
    const anchorPos = {
      x: horizontalAlignment === 'right' ? iconRect.x : iconRect.right,
      y: iconRect.bottom,
    };
    contextMenu = (
      <StyledContextMenu
        onClose={() => onSetIsOpen(false)}
        anchorPosition={anchorPos}
        horizontalAlignment={horizontalAlignment}
        preventCloseOnClick={preventCloseOnClick}
      >
        {children}
      </StyledContextMenu>
    );
    if (modalLayer) {
      contextMenu = ReactDOM.createPortal(contextMenu, modalLayer);
    }
  }
  const onMouseDown = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.nativeEvent.stopImmediatePropagation();
    onSetIsOpen(!isOpen);
  };
  const Icon = icon ?? <StyledEllipsisIcon data-testid={ELLIPSIS_BUTTON_TEST_ID} />;
  return (
    <>
      <Tooltip title={tooltip}>
        <IconWrapper
          ref={anchorRef}
          className={className}
          onMouseDown={onMouseDown}
          onContextMenu={preventAndStopPropagation}
          onClick={preventAndStopPropagation}
        >
          {Icon}
        </IconWrapper>
      </Tooltip>
      {contextMenu}
    </>
  );
};

const IconWrapper = styled.span`
  line-height: 0;
  cursor: pointer;
`;

const StyledEllipsisIcon = styled(EllipsisIcon)`
  height: 16px;
  width: 16px;
`;

interface IStyledContextMenu {
  horizontalAlignment: 'left' | 'right';
}

const StyledContextMenu = styled(ContextMenu)<IStyledContextMenu>`
  transform: translateX(${(props) => props.horizontalAlignment === 'left' ? -100 : 0}%);
`;
