import { ITheme, IThemeColorset, ThemeName } from './ITheme';
import { ColorSet } from './ColorSet';
import { lightTooltipTheme } from '../components/utils/Tooltip';
import { lightButtonTheme } from '../components/inputs/Button';
import { lightSwitchButtonTheme } from '../components/inputs/SwitchButton';
import { lightSectionHeaderTheme } from '../components/layout/SectionHeader';
import { lightCheckboxTheme } from '../components/inputs/Checkbox';
import { lightInputTheme } from '../components/inputs/Input';
import { lightTreeviewTheme } from '../components/navigation/Treeview';
import { lightRadioTheme } from '../components/inputs/Radio/theme';
import { lightTagInstanceTheme } from '../components/tagging/theme';
import { lightDropdownTheme } from '../components/utils/Dropdown/theme';
import { lightTreeTheme } from '../components/navigation/Tree/theme';
import { lightCardTheme } from '../components/surfaces/card/theme';
import { lightSelectItemTheme } from '../components/inputs/Select/SelectItem.theme';
import { lightContextMenu } from '../components/utils/ContextMenu/theme';
import { lightModalTheme } from '../components/utils/Modal/theme';
import { lightPopoverTheme } from '../components/utils/Popups/theme';
import { lightTabViewTheme } from '../components/navigation/tabView/TabView.theme';

export class ThemeDefault {
  public fontFamily: string = '"Open Sans", sans-serif';

  public readonly name: ThemeName = ThemeName.light;

  public readonly nestingLevel: number = 1;

  protected colorset: IThemeColorset = {
    // New pallet according to the design system
    white: '#FFFFFF',
    black: '#000000',
    grey025: '#F2F2F5',
    grey050: '#E9ECF2',
    grey100: '#CED5DF',
    grey400: '#818C99',
    grey500: '#6B747D',
    grey700: '#51575E',
    grey800: '#42474D',
    grey900: '#303438',
    penumbra: '#1C1F21',
    darkness: '#111213',

    primary050: '#EAF6FF',
    primary075: '#D7EDFF',
    primary500: '#0086EC',
    primary700: '#0068B8',
    alert400: '#FF4E5F',
    alert500: '#DA4453',
    warning: '#FF7700',
    brand: '#72D200',

    french: ColorSet.french,
    mathematics: ColorSet.mathematics,

    grey01: '#FFFFFF',
    grey02: '#F2F2F5',
    grey03: '#E9ECF2',
    grey04: '#CED5DF',
    grey05: '#687472',
    grey06: '#818C99',
    grey07: '#6B747D',
    grey08: '#51575E',
    grey09: '#42474D',
    grey10: '#303438',
    grey11: '#282C30',
    grey12: '#1C1F21',
    grey13: '#000000',
    primary: '#0086EC',
    primaryDark: '#0068B8',
    secondary: '#FF45A2',
    secondaryDark: '#E9157F',
    alt1: '#00B57F',
    alt1Dark: '#008059',
    alt2: '#72D200',
    alt3: '#7E57C2',
    alt4: '#DA4453',
    alt5: '#FF7700',
    shadow: 'rgba(0, 0, 0, 0.1)',
    shadowAlt: 'rgba(0, 0, 0, 0.3)',
    error: '#FF2525',
    error2: '#FF4E5F',
  };

  protected typeset = {
    h1Headline: `normal 700 3em/1.25em ${this.fontFamily}`,
    h2Headline: `normal 700 1.5em/1.25em ${this.fontFamily}`,
    h3Headline: `normal 700 1.125em/1.333333em ${this.fontFamily}`,
    body1Bold: `normal 700 1em/1.5em ${this.fontFamily}`,
    body1SemiBold: `normal 600 1em/1.5em ${this.fontFamily}`,
    body1Regular: `normal 400 1em/1.5em ${this.fontFamily}`,
    body2Bold: `normal 700 0.875em/1.4286em ${this.fontFamily}`,
    body2SemiBold: `normal 600 0.875em/1.4286em ${this.fontFamily}`,
    body2Regular: `normal 400 0.875em/1.4286em ${this.fontFamily}`,
    captionBold: `normal 700 1.0em/1.65em ${this.fontFamily}`,
    captionSemiBold: `normal 600 1.0em/1.5em ${this.fontFamily}`,
    captionRegular: `normal 400 12px/16px ${this.fontFamily}`,
    button: `normal 400 0.875em/1.4286em ${this.fontFamily}`,
  };

  public get theme(): ITheme {
    return {
      colorset: this.colorset,
      typeset: this.typeset,
      name: this.name,
      nestingLevel: this.nestingLevel,
      baseFontSize: '16px',
      baseBorderRadius: '16px',
      baseForeground: this.colorset.grey900,
      baseBackground: this.colorset.grey01,
      baseBorderColor: this.colorset.grey04,
      baseHorizontalPadding: '0.8em',
      baseVerticalPadding: '0.5em',
      baseVerticalSpacing: '0.5em',
      baseIndent: '1.5625em',
      baseShadow: `0 0.125em 0 ${this.colorset.shadow}`,
      fontFamily: this.fontFamily,
      popupBackgroundColor: '#eeedec',
      popupGradient: {
        colorA: this.colorset.grey025,
        colorB: this.colorset.white,
      },
      modalBackgroundColor: '#ffffff',
      button: lightButtonTheme,
      card: lightCardTheme,
      tooltip: lightTooltipTheme,
      switchButton: lightSwitchButtonTheme,
      sectionHeader: lightSectionHeaderTheme,
      checkbox: lightCheckboxTheme,
      input: lightInputTheme,
      tree: lightTreeTheme,
      treeview: lightTreeviewTheme,
      radio: lightRadioTheme,
      tagInstance: lightTagInstanceTheme,
      dropdown: lightDropdownTheme,
      selectItem: lightSelectItemTheme,
      contextMenu: lightContextMenu,
      modal: lightModalTheme,
      popover: lightPopoverTheme,
      tabView: lightTabViewTheme,
    };
  }
}
