import { Point } from '../../../js/geom/Point';

import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WLine } from '../../elements/tokens/WLine';
import { WListOfPoints } from '../../elements/tokens/WListOfPoints';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { CultureInfo } from '../../localization/CultureInfo';

/**
 *
 */
export class RegressionLine extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 1){
      return args.expectingArguments(1, 1);
    }

    const pts:WListOfPoints = args.getPoints(0);
    if(!pts || pts.count < 2){
      return null;
    }

    return this.leastSquaresRegression(pts.toPoints(), args.env.culture);
  }

  /**
   * Least Squares
   */
  private leastSquaresRegression(data:Point[], culture:CultureInfo):WLine{
    const N:number = data.length;

    if(N < 2){
      return null;
    }

    let sX:number = 0;
    let sY:number = 0;
    let sXX:number = 0;
    let sXY:number = 0;

    for(let i:number = 0 ; i < N ; i++){
      const p:Point = data[i];
      sX += p.x;
      sY += p.y;
      sXX += p.x * p.x;
      sXY += p.x * p.y;
    }

    const m:number = (N * sXY - sX * sY) / (N * sXX - sX * sX);
    if(isNaN(m)){
      return null;
    }

    const b:number = (sY - m * sX) / N;

    return new WLine(
      culture.createNumber(-m),
      culture.createNumber(1),
      culture.createNumber(-b),
      culture.formats.lineFormatImpl);
  }

}
