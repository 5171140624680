import { createGlobalStyle } from 'styled-components';
import { themedColor } from '../../themes/utils';
import { ColorSet, setHexColorTransparency } from '../../themes/ColorSet';

export const GlobalScrollbarStyle = createGlobalStyle`
  ::-webkit-scrollbar {
    width: 16px;
    height: 16px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: ${themedColor({ dark: setHexColorTransparency(ColorSet.white, 0.4), light: setHexColorTransparency(ColorSet.penumbra, 0.3) })};
    box-shadow: inset 0 0 0 1px ${themedColor({ dark: setHexColorTransparency(ColorSet.darkness, 0.5), light: setHexColorTransparency(ColorSet.white, 0.5) })};
    border: 4px solid transparent;
    background-clip: padding-box;
  }

  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
`;
