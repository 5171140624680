import { ContentElement } from '../../elements/abstract/ContentElement';
import { ElementCodes } from '../../elements/abstract/ElementCodes';
import { OperatorElement } from '../../elements/abstract/OperatorElement';
import { WBoolean } from '../../elements/tokens/WBoolean';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * ∧
 * U+2227
 * Et logique
 */
export class And extends OperatorElement {

  /**
   *
   */
  public toString():string{return '∧';}

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length < 2){
      return args.expectingArguments(2, Number.POSITIVE_INFINITY);
    }

    for(let i:number = 0 ; i < args.length ; i++){
      const b:WBoolean = args.getBoolean(i);
      if(!b){
        return null;
      }
      if(!b.toBoolean()){
        return b;
      }
    }

    return WBoolean.TRUE;
  }

  /**
   *
   */
  public getElementCode():string{
    return ElementCodes.OP_AND;
  }

}
