import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { ChessBoardImpl } from '../../funcs/chess/ChessBoardImpl';

/**
 * Enlève une pièce d'un échiquier.
 */
export class ChessRemove extends FunctionElement {

  /**
   * Si l'échiquier n'est pas spécifié, on assume qu'on part avec un
   * échiquier où les pièces sont placées selon un début de partie.
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length < 1 || args.length > 2){
      return args.expectingArguments(1, 2);
    }

    const boardImpl:ChessBoardImpl = new ChessBoardImpl(args.env.culture);

    let board:WMatrix;
    let notations:string[];

    if(args.length === 1 && args.getStrings(0)){
      board = boardImpl.createInitial();
      notations = args.getStrings(0).toStrings();
    }else if(args.length === 2 && args.getMatrixWithSize(0, 8, 8) && args.getStrings(1)){
      board = args.getMatrixWithSize(0, 8, 8);
      notations = args.getStrings(1).toStrings();
    }

    if(!board || !notations){
      return null;
    }

    return boardImpl.remove(board, notations);
  }

}
