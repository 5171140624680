import React from 'react';
import { isSubscriptMarkActive, toggleSubscriptMark } from '../../utils/characterFormatUtils';
import { ICustomEditor } from '../../models';
import { Button } from '../../../Button';
import SubscriptIcon from '../../../../../assets/subscript.svg';
import { Tooltip } from '../../../../utils/Tooltip/Tooltip';

interface ISubscriptControlProps {
  editor: ICustomEditor;
  label: string;
}

export const SubscriptControl: React.FC<ISubscriptControlProps> = ({ editor, label }) => {
  const onMouseDown = (event: React.MouseEvent) => {
    event.preventDefault();
    toggleSubscriptMark(editor);
  };
  return (
    <Tooltip
      title={label}
    >
      <Button
        active={isSubscriptMarkActive(editor)}
        onMouseDown={onMouseDown}
        aria-label={label}
        icon
      >
        <SubscriptIcon />
      </Button>
    </Tooltip>
  );
};
