import { CSSProperties } from 'react';
import { ColorSet, setHexColorTransparency } from '../../../themes/ColorSet';

export interface ICardTheme {
  default: {
    backgroundColor: CSSProperties['backgroundColor'];
    borderColor: CSSProperties['borderColor'];
    color: CSSProperties['color'];
    hover: {
      backgroundColor: CSSProperties['backgroundColor'];
    };
  };
  selected: {
    backgroundColor: CSSProperties['backgroundColor'];
    borderColor: CSSProperties['borderColor'];
    color: CSSProperties['color'];
    hover: {
      backgroundColor: CSSProperties['backgroundColor'];
    };
  };
}

export const darkCardTheme: ICardTheme = {
  default: {
    backgroundColor: ColorSet.grey900,
    color: ColorSet.white,
    borderColor: ColorSet.grey700,
    hover: {
      backgroundColor: ColorSet.grey800,
    },
  },
  selected: {
    backgroundColor: setHexColorTransparency(ColorSet.primary500, 0.2),
    color: ColorSet.white,
    borderColor: ColorSet.primary500,
    hover: {
      backgroundColor: setHexColorTransparency(ColorSet.primary500, 0.5),
    },
  },
};

export const lightCardTheme: ICardTheme = {
  default: {
    backgroundColor: ColorSet.white,
    color: ColorSet.penumbra,
    borderColor: ColorSet.grey100,
    hover: {
      backgroundColor: ColorSet.grey025,
    },
  },
  selected: {
    backgroundColor: ColorSet.primary050,
    color: ColorSet.primary700,
    borderColor: ColorSet.primary700,
    hover: {
      backgroundColor: ColorSet.primary075,
    },
  },
};
