import { XMath } from '../../core/XMath';
import { XSort } from '../../core/XSort';
import { ContentElement } from '../abstract/ContentElement';
import { TokenElement } from '../abstract/TokenElement';

export class RealElement extends TokenElement {
  /**
   * ..., -3, -2, -1, 0, 1, 2, 3, ...
   */
  public isInteger(): boolean {
    return XMath.isInteger(this.toNumber());
  }

  /**
   * 0, 1, 2, 3, 4, 5, ...
   */
  public isWholeNumber(): boolean {
    return XMath.isInteger(this.toNumber()) && this.toNumber() >= 0;
  }

  /**
   * 1, 2, 3, 4, 5, ...
   */
  public isNaturalNumber(): boolean {
    return XMath.isInteger(this.toNumber()) && this.toNumber() >= 1;
  }

  public toNumber(): number {
    throw new Error('Must inherit');
  }

  public toAbsoluteValue(): RealElement {
    throw new Error('Must inherit');
  }

  public toOpposite(): RealElement {
    throw new Error('Must inherit');
  }

  public toText(strict: boolean): string {
    throw new Error('Must inherit');
  }

  public getListItemCode(): string {
    return RealElement.getListItemCode();
  }

  public static getListItemCode(): string {
    return 'real';
  }

  protected getComparisonValue(): Object {
    return this.toNumber();
  }

  public hashCode(): string {
    return String(this.toNumber());
  }

  public static compare(a: RealElement, b: RealElement): number {
    return XSort.numeric(a.toNumber(), b.toNumber());
  }

  public static compareEncapsulated(a: any, b: any): number {
    const result = XSort.numeric(a.item.toNumber(), b.item.toNumber());
    if (result !== 0) {
      return result;
    }
    if (a.index < b.index) {
      return -1;
    }
    if (a.index > b.index) {
      return 1;
    }
  }

  public static encapsulator(value: RealElement, index: number, ..._: any[]): any {
    return { index, item: value };
  }

  public static decapsulator(encapsulated: any, index: number, ..._: any[]): RealElement {
    return encapsulated.item;
  }

  public static parseElement(element: ContentElement): RealElement {
    return element instanceof RealElement ? element : null;
  }

  public static parseDecimal(element: ContentElement): number {
    if (element instanceof RealElement) {
      return element.toNumber();
    }
    return NaN;
  }

  public get type(): string {
    return 'realElement';
  }
}
