/**
 *
 */
export class SquareTileSymmetry {

  public static VER_AXIS:number = 1;

  public static HOR_AXIS:number = 2;

  public static UP_DIAG_AXIS:number = 4;

  public static DOWN_DIAG_AXIS:number = 8;

  public static CROSS:number = SquareTileSymmetry.VER_AXIS | SquareTileSymmetry.HOR_AXIS;

  public static X:number = SquareTileSymmetry.UP_DIAG_AXIS | SquareTileSymmetry.DOWN_DIAG_AXIS;

  public static STAR:number = SquareTileSymmetry.VER_AXIS | SquareTileSymmetry.HOR_AXIS | SquareTileSymmetry.UP_DIAG_AXIS | SquareTileSymmetry.DOWN_DIAG_AXIS;

}
