import { ContentElement } from '../elements/abstract/ContentElement';
import { Node } from '../elements/abstract/Node';
import { RelationalElement } from '../elements/abstract/RelationalElement';
import { SymbolElement } from '../elements/abstract/SymbolElement';
import { WNumber } from '../elements/tokens/WNumber';
import { TokensImporter } from '../expr/conversion/input/TokensImporter';
import { IWriter } from '../expr/conversion/writers/IWriter';
import { Skeleton } from '../expr/manipulation/Skeleton';
import { KeyboardConfiguration } from './KeyboardConfiguration';
import { InputCapabilities } from './InputCapabilities';
import { CRelation } from './CRelation';
import { CommonError } from './CommonError';
import { BaseCorrector } from './BaseCorrector';

/**
 *
 */
export class CInequality extends BaseCorrector {

  private relation:RelationalElement;

  /**
   *
   */
  constructor(relation:RelationalElement){
    super();
    this.relation = relation;
  }

  public parse(value:string):Node{
    const o:ContentElement[] = this.parseInput(value);
    if(o != null){
      const l:ContentElement = o[0];
      const op:ContentElement = o[1];
      const r:ContentElement = o[2];
      const node:Node = TokensImporter.importTokens([l, op, r], this.env);
      node.userData = l.userData + op.userData + r.userData;
      return node;
    }
    return null;
  }

  public correct(
      value:string,
      target:ContentElement,
      ...targets:any[]):boolean{

    const o:ContentElement[] = this.parseInput(value);
    if(o == null){
      this.raiseError(CommonError.INEQUALITY_INVALID);
    }

    return 	(o[0].equalsTo(target) &&
         o[2].equalsTo(targets[0]) &&
         this.relation.toString() === String(o[1])) ||
        (o[0].equalsTo(targets[0]) &&
         o[2].equalsTo(target) &&
         String(this.relation.reverse) === String(o[1]));
  }

  private parseInput(valueArg:string):ContentElement[] {
    let value = valueArg;
    if(this.useLatex){
      value = this.sanitizeInput(value);
    }

    const k:number = value.search(new RegExp('[<>≤≥]'));
    if(k === -1){
      return null;
    }

    const o:ContentElement[] = [];

    const a:ContentElement = this.parseTerm(value.substring(0, k));
    const r:RelationalElement = CRelation.relationalOperator(value.charAt(k));
    const b:ContentElement = this.parseTerm(value.substring(k + 1));

    if(a == null || r == null || b == null){
      return null;
    }

    o.push(a, r, b);

    return o;
  }

  private parseTerm(valueArg:string):ContentElement{
    const value = valueArg.split(' ').join('');
    if(value.length === 1){
      if(value >= 'a' && value <= 'z'){
        return this.env.culture.createVariable(value);
      }
    }
    const n:number = this.numberParser.parseNumber(value);
    if(!isNaN(n)){
      return this.env.culture.createNumber(n);
    }
    return null;
  }

  public get inputCapabilities():InputCapabilities{
    return super.inputWithSymbols('<>≤≥');
  }

  public get mathKeyboard():number{
    return KeyboardConfiguration.INEQUALITY;
  }

  public writeTo(
      w:IWriter,
      target:ContentElement,
      ...targets:any[]):void{

    if(target instanceof WNumber){
      w.writeNumber((<WNumber>target ).toNumber());
    }else if(target instanceof SymbolElement){
      w.writeSymbol((<SymbolElement>target ).getSymbol());
    }

    w.writeOperator(this.relation.toString(), 'infix');

    if(targets[0] instanceof WNumber){
      w.writeNumber((<WNumber>targets[0] ).toNumber());
    }else if(targets[0] instanceof SymbolElement){
      w.writeSymbol((<SymbolElement>targets[0] ).getSymbol());
    }
  }

  public static validate(node:Node):boolean{
    const skeleton:string = Skeleton.createSkeleton(node);
    if(skeleton == null){
      return false;
    }
    const skeletons:any[] = Skeleton.combine('{0}({1},{2})', [['<', '>', '≤', '≥'], ['x', 'n'], ['x', 'n']]);
    return skeletons.indexOf(skeleton) !== -1;
  }

}
