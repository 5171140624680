import { XString } from '../../../core/XString';
import { MmlWriter } from '../../../core/mml/MmlWriter';
import { RealElement } from '../../../elements/abstract/RealElement';
import { BasePointFormatter } from '../../../elements/formats/BasePointFormatter';
import { IMarkupExporter } from '../../../elements/markers/IMarkupExporter';
import { CultureInfo } from '../../../localization/CultureInfo';

/**
 *
 */
export class PointFormatter extends BasePointFormatter {

  /**
   *
   */
  constructor(culture:CultureInfo) {

    super(culture);
  }

  /**
   *
   */
  public writeTo(exporter:IMarkupExporter, x:RealElement, y:RealElement):boolean{
    if(exporter){
      const w:MmlWriter = exporter.writer;
      w.beginFenced();
      w.addCommaCheck();
      x.writeTo(exporter);
      y.writeTo(exporter);
      w.separators = exporter.listSeparator(w.checkComma());
      w.endFenced();
    }
    return true;
  }

  /**
   *
   */
  public toLocaleString(x:RealElement, y:RealElement, strict:boolean):string{
    const sx:string = x.toText(strict);
    const sy:string = y.toText(strict);
    if(sx && sy){
      const separator:string = this.culture.listFormatter.outputSeparator(sx + sy);
      return XString.substitute('({0}{1}\u00A0{2})', sx, separator, sy);
    }
    return null;
  }

}
