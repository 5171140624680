import React from 'react';

interface ISelectContext {
  value: any;
  onChange: (value: any) => void;
}

const defaultValue: ISelectContext = {
  value: null,
  onChange: null,
};

export const SelectContext = React.createContext<ISelectContext>(defaultValue);
