import {
  Editor,
  Range,
  Transforms,
  Element,
  RangeRef,
} from '../TypedSlate';
import { ITTSElement } from '../models/elements';
import { ICustomEditor, ICustomElement } from '../models/editor';

export const isTTS = (node: ICustomElement): node is ITTSElement => {
  return node.type === 'tts';
};

export const insertTTS = (editor: ICustomEditor, url: string, rangeRef?: RangeRef) => {
  wrapTTS(editor, url, rangeRef);
};

export const isTTSActive = (editor: ICustomEditor, rangeRef?: RangeRef) => {
  const [TTS] = Editor.nodes(editor, {
    at: getRange(editor, rangeRef),
    match: n =>
      !Editor.isEditor(n) && Element.isElement(n) && isTTS(n),
  });
  return !!TTS;
};

export const unwrapTTS = (editor: ICustomEditor, rangeRef?: RangeRef) => {
  Transforms.unwrapNodes(editor, {
    at: getRange(editor, rangeRef),
    match: n =>
      !Editor.isEditor(n) && Element.isElement(n) && isTTS(n),
  });
};

export const wrapTTS = (editor: ICustomEditor, altText: string, rangeRef?: RangeRef) => {
  let selection = getRange(editor, rangeRef);
  if (isTTSActive(editor, rangeRef)) {
    unwrapTTS(editor, rangeRef);
    selection = getRange(editor, rangeRef);
  }

  const isCollapsed = selection && Range.isCollapsed(selection);
  const TTS: ITTSElement = {
    altText,
    type: 'tts',
    children: isCollapsed ? [{ text: altText }] : [],
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, TTS, { at: selection });
  } else {
    Transforms.wrapNodes(editor, TTS, { at: selection, split: true });
    Transforms.collapse(editor, { edge: 'end' });
  }
};

const getRange = (editor: ICustomEditor, rangeRef?: RangeRef) =>
  rangeRef ? rangeRef.current : editor.selection;
