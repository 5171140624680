import { MathError } from '../../core/MathError';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Copie une matrice et colle le résultat en dessous de cette matrice.
 */
export class CopyVer extends FunctionElement {

  /**
   * Copie une matrice en lui appliquant une réflexion d'axe
   * horizontal et colle le résultat en dessous de cette matrice.
   */
  private useReflection:boolean;

  /**
   *
   */
  constructor(useReflection:boolean){
    super();
    this.useReflection = useReflection;
  }

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length < 1 || args.length > 2){
      return args.expectingArguments(1, 2);
    }

    const matrix:WMatrix = args.getMatrix(0);
    if(!matrix){
      return null;
    }

    let n:number = 1;
    if(args.length === 2){
      if(args.getWholeNumber(1)){
        n = args.getWholeNumber(1).toNumber();
      }else{
        return null;
      }
    }

    if(n === 0){
      return matrix;
    }

    const maxCopies:number = 99;

    if(n > maxCopies){
      throw new MathError('Too many copies requested');
    }

    return new WMatrix(this.copyImpl(matrix.values, matrix.columns, n), matrix.columns, matrix.formatter);
  }

  /**
   *
   */
  private copyImpl(
      values:RealElement[],
      cols:number,
      n:number):RealElement[]{

    const o:RealElement[] = [];
    const rows:number = values.length / cols;

    for(let k:number = 0 ; k <= n ; k++){
      for(let r:number = 0 ; r < rows ; r++){
        for(let c:number = 0 ; c < cols ; c++){
          if(this.useReflection && k % 2 !== 0){ // odd repetition index
            o.push(values[(rows - (r + 1)) * cols + c]);
          }else{
            o.push(values[r * cols + c]);
          }
        }
      }
    }

    return o;
  }

}
