import { MmlWriter } from '../../core/mml/MmlWriter';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';
import { IMarkupFactory } from '../../elements/markers/IMarkupFactory';
import { WList } from '../../elements/tokens/WList';
import { CultureInfo } from '../../localization/CultureInfo';

/**
 *
 */
export class JoinImpl implements IMarkupFactory {

  private values:WList;

  private separator:string;

  /**
   *
   */
  constructor(
      values:WList,
      separator:string){
    this.values = values;
    this.separator = separator.split(' ').join('\u00A0');
  }

  /**
   *
   */
  public get culture():CultureInfo{
    return this.values.formatter.culture;
  }

  /**
   *
   */
  public writeTo(exporter:IMarkupExporter):void{
    const w:MmlWriter = exporter.writer;
    w.beginRow();
    for(let i:number = 0 ; i < this.values.count ; i++){
      if(i > 0){
        w.appendOperator(this.separator);
      }
      this.values.getTypedItemAt(i).writeTo(exporter);
    }
    w.endRow();
  }

}
