import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { DigitPlaceValueFormatter } from '../../elements/formats/numbers/DigitPlaceValueFormatter';
import { WNumber } from '../../elements/tokens/WNumber';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Position d'un chiffre. "milliers", "dizaines", ect...
 */
export class FormatDigitPlaceValue extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length < 1 || args.length > 2){
      return args.expectingArguments(1, 2);
    }

    const n:RealElement = args.getReal(0);
    if(!n){
      return null;
    }

    let plural:boolean = true;
    if(args.length > 1){
      if(args.getBoolean(1)){
        plural = args.getBoolean(1).toBoolean();
      }else{
        return null;
      }
    }

    return new WNumber(n.toNumber(), 1, false, new DigitPlaceValueFormatter(args.env.culture, plural));
  }

}
