import { Point } from '../../../js/geom/Point';
import { TokenElement } from '../../elements/abstract/TokenElement';

/**
 *
 */
export class RestrictExpression {

  /**
   * The expression must evaluate to this value.
   */
  public results:TokenElement[];

  /**
   * deny|allow
   */
  public resultsPolicy:string;

  /**
   * Restrict terms, each term can be used once only.
   */
  public terms:TokenElement[];

  /**
   * deny|allow
   */
  public termsPolicy:string;

  /**
   *
   */
  public termsReuseEnabled:boolean;

  /**
   * Restrict operators.
   * +-/*^×÷−
   */
  public operators:string[];

  /**
   * deny|allow
   */
  public operatorsPolicy:string;

  /**
   * Restrict the maximum of operations.
   * x: min
   * y: max
   */
  public operations:Point;

  public clone():RestrictExpression{
    const o:RestrictExpression = new RestrictExpression();
    o.results = this.results;
    o.resultsPolicy = this.resultsPolicy;
    o.terms = this.terms;
    o.termsPolicy = this.termsPolicy;
    o.termsReuseEnabled = this.termsReuseEnabled;
    o.operators = this.operators;
    o.operatorsPolicy = this.operatorsPolicy;
    o.operations = this.operations;
    return o;
  }

}
