import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Tooltip } from './Tooltip';

interface IDisabledButtonTooltipProps extends WrappedComponentProps {
  disabled: boolean;
  intlKey: string;
}


const DisabledButtonTooltipComponent: React.FC<IDisabledButtonTooltipProps> = (props) => {
  const {
    children,
    intl,
    disabled,
    intlKey,
  } = props;
  if (disabled) {
    return (
      <Tooltip
        title={intl.formatMessage({ id: intlKey })}
      >
        <div>
          {children}
        </div>
      </Tooltip>
    )
  }
  return (
    <>
      {children}
    </>
  );
};

export const DisabledButtonTooltip = injectIntl(DisabledButtonTooltipComponent)
