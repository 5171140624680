import { AbstractStep } from '../../AbstractStep';
import { Compartment } from '../../Compartment';
import { LongDivisionOperationEnglish } from '../../LongDivisionOperationEnglish';
import { DivisionModel } from '../../models/DivisionModel';
import { DivMultiplyEnglish } from './DivMultiplyEnglish';

/**
 *
 */
export class DivQuotientEnglish extends AbstractStep {

  private rest:number;

  private divisor:number;

  private division:LongDivisionOperationEnglish;

  private model:DivisionModel;

  private quotient:number;

  constructor(
      division:LongDivisionOperationEnglish,
      rest:number,
      divisor:number){
    super(division);
    this.rest = rest;
    this.divisor = divisor;
    this.division = division;
    this.model = division.model;

    this.quotient = divisor === 0 ? 0 : Math.floor(rest / divisor);
    const result:Compartment = Compartment.createDigit(this.quotient);
    division.result.push(result);
    this.target.push(result);

    this.setDescription(division.culture.getString('LongDivisionEnglish.quotient', rest, divisor));
  }

  public next():AbstractStep {
    return new DivMultiplyEnglish(this.division, this.quotient, this.model.normalizedDivisor);
  }

}
