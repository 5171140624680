import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { IntervalsFactory } from '../../elements/factories/IntervalsFactory';
import { IntervalClosure } from '../../elements/models/IntervalClosure';
import { WLine } from '../../elements/tokens/WLine';
import { WQuadratic } from '../../elements/tokens/WQuadratic';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class ConstantIntervals extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 1){
      return args.expectingArguments(1, 1);
    }

    const intervalsFactory:IntervalsFactory = args.env.culture.intervalsFactory;

    const line:WLine = args.getLineOrPoly(0);
    if(line){
      if(!line.slope){
        return null; // vertical line
      }
      return line.slope.toNumber() === 0 ?
        intervalsFactory.createReals() :
        intervalsFactory.createEmpty();
    }

    const quadratic:WQuadratic = args.getQuadraticOrPoly(0);
    if(quadratic){
      return intervalsFactory.createInterval(
        IntervalClosure.CLOSED,
          quadratic.apex.x,
          quadratic.apex.x);
    }

    return null;
  }

}
