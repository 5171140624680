import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { ListElement } from '../../elements/abstract/ListElement';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Retourne les éléments d'une liste en excluant certains éléments en début de liste.
 */
export class Skip extends FunctionElement {
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 2) {
      return args.expectingArguments(2, 2);
    }

    const [list, count] = [args.getList(0), args.getReal(1)];
    if (list && count.isNaturalNumber()) {
      return this.list(list, count.toNumber());
    }

    return null;
  }

  private list(value: ListElement, count: number): ListElement {
    const indices: number[] = [];
    for (let i = count; i < value.count; i++) {
      indices.push(i);
    }
    return value.transform(indices);
  }
}
