import { CSSProperties } from 'react';
import { merge } from 'lodash';
import { ColorSet } from '../../../themes/ColorSet';

export interface IRadioTheme {
  default: {
    backgroundColor: CSSProperties['backgroundColor'];
    border?: CSSProperties['border'];
    color: CSSProperties['color'];
    opacity?: null;
    check: {
      border: CSSProperties['border'];
      backgroundColor: CSSProperties['backgroundColor'];
    };
  };
  disabled: {
    backgroundColor: CSSProperties['backgroundColor'];
    opacity: CSSProperties['opacity'];
    border?: CSSProperties['border'];
    color: CSSProperties['color'];
    check: {
      border: CSSProperties['border'];
      backgroundColor: CSSProperties['backgroundColor'];
    };
  };
}

export const darkRadioTheme: IRadioTheme = {
  default: {
    backgroundColor: ColorSet.penumbra,
    color: ColorSet.white,
    check: {
      border: `8px solid ${ColorSet.primary500}`,
      backgroundColor: ColorSet.white,
    },
  },
  disabled: {
    backgroundColor: ColorSet.penumbra,
    opacity: 0.4,
    color: ColorSet.white,
    check: {
      border: `8px solid ${ColorSet.grey500}`,
      backgroundColor: ColorSet.white,
    },
  },
};

export const darkRadioTheme2: IRadioTheme = merge({}, darkRadioTheme, {
  default: {
    backgroundColor: ColorSet.grey900,
  },
  disabled: {
    backgroundColor: ColorSet.grey900,
  },
});

export const lightRadioTheme: IRadioTheme = {
  default: {
    backgroundColor: ColorSet.white,
    border: `1px solid ${ColorSet.grey100}`,
    color: ColorSet.penumbra,
    check: {
      border: `8px solid ${ColorSet.primary500}`,
      backgroundColor: ColorSet.white,
    },
  },
  disabled: {
    backgroundColor: ColorSet.white,
    border: `1px solid ${ColorSet.grey100}`,
    opacity: 0.5,
    color: ColorSet.penumbra,
    check: {
      border: `8px solid ${ColorSet.grey400}`,
      backgroundColor: ColorSet.white,
    },
  },
};
