import React from 'react';
import { alignText, isTextAligned } from '../../utils/alignUtils';
import { ICustomEditor } from '../../models';
import { Button } from '../../../Button';
import AlignJustifiedIcon from '../../../../../assets/alignJustified.svg';
import { Tooltip } from '../../../../utils/Tooltip/Tooltip';

interface IAlignJustifyControlProps {
  editor: ICustomEditor;
  label: string;
}

export const AlignJustifyControl: React.FC<IAlignJustifyControlProps> = ({ editor, label }) => {
  const onMouseDown = (event: React.MouseEvent) => {
    event.preventDefault();
    alignText(editor, 'justify');
  };
  return (
    <Tooltip
      title={label}
    >
      <Button
        active={isTextAligned(editor, 'justify')}
        onMouseDown={onMouseDown}
        aria-label={label}
        icon
      >
        <AlignJustifiedIcon />
      </Button>
    </Tooltip>
  );
};
