import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WQuadratic } from '../../elements/tokens/WQuadratic';
import { WUnhandled } from '../../elements/tokens/WUnhandled';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * http://en.wikipedia.org/wiki/Discriminant
 * b * b - 4 * a * c
 */
export class Discriminant extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 1){
      return args.expectingArguments(1, 1);
    }

    const quadratic:WQuadratic = args.getQuadraticOrPoly(0);

    if(!quadratic){
      if(args.getPolynomial(0)){
        return new WUnhandled(args.env.culture.getString('MathCore.unhandledCase'));
      }
      return null;
    }

    return args.env.culture.createNumber(quadratic.discriminant);
  }

}
