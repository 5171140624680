/**
 *
 */
export class Separator {

  public static COMMA:Separator = new Separator(',');

  public static SEMICOLON:Separator = new Separator(';');

  private value:string;

  constructor(value:string) {

    this.value = value;
  }

}
