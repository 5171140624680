import { TimeModel } from '../../elements/models/TimeModel';
import { CultureInfo } from '../../localization/CultureInfo';
import { AbstractFormatter } from '../../elements/formats/AbstractFormatter';

/**
 *
 */
export class BaseTimeFormatter extends AbstractFormatter {

  private _useTwelveHoursClock:boolean;

  /**
   *
   */
  constructor(
      culture:CultureInfo,
      useTwelveHoursClock:boolean){
    super(culture);
    this._useTwelveHoursClock = useTwelveHoursClock;
  }

  /**
   *
   */
  public toLocaleString(value:TimeModel):string{
    throw new Error();
  }

  /**
   *
   */
  public fromLocaleString(value:string):TimeModel{
    throw new Error();
  }

  /**
   *
   */
  public useTwelveHoursClock():boolean{
    return this._useTwelveHoursClock;
  }

}
