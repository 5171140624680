import { AbstractStep } from '../../AbstractStep';
import { LongDivisionOperationEuclidienne } from '../../LongDivisionOperationEuclidienne';
import { DivisionModel } from '../../models/DivisionModel';
import { DivQuotient } from './DivQuotient';

/**
 *
 */
export class DivAddSeparator extends AbstractStep {

  private model:DivisionModel;

  private division:LongDivisionOperationEuclidienne;

  constructor(division:LongDivisionOperationEuclidienne) {

    super(division);
    this.division = division;
    this.model = division.model;

    const separator = division.decimalSeparator();
    this.target.push(separator);
    this.division.result.push(separator);

    this.setDescription(
      division.culture.getString(
        'LongDivisionEuclidienne.newDecimalSeparator',
        division.culture.numberFormatter.getDecimalSeparatorLabel(false, ['un', 'une'])));
  }

  public next():AbstractStep {
    return new DivQuotient(this.division, this.division.getRestToUInt(), this.model.normalizedDivisor);
  }
}
