import { MathError } from '../../core/MathError';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { IntervalClosure } from '../../elements/models/IntervalClosure';
import { WInterval } from '../../elements/tokens/WInterval';
import { WList } from '../../elements/tokens/WList';
import { WListOfIntervals } from '../../elements/tokens/WListOfIntervals';
import { WRange } from '../../elements/tokens/WRange';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';

/**
 *
 */
export class Intervals extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 1){
      return args.expectingArguments(1, 1);
    }
    if(args.getReals(0)){
      return this.nonOverlappingIntervalsFromList(args.getReals(0), args.env);
    }
    if(args.getRange(0)){
      return this.nonOverlappingIntervalsFromRange(args.getRange(0), args.env);
    }
    if(args.getIntervals(0)){
      return args.getIntervals(0);
    }
    if(args.getNumbersSet(0)){
      const interval = args.getNumbersSet(0).narrow() as WInterval;
      if (interval) {
        return new WListOfIntervals([interval], args.env.culture.listFormatter);
      }
    }
    return null;
  }

  /**
   * {minimum: 1, maximum: 10, step: 3}
   * (1, 4, 7, 10)
   * [1, 4[, [4, 7[, [7, 10[
   */
  private nonOverlappingIntervalsFromRange(
      range:WRange,
      env:Environment):WListOfIntervals{

    if(range.getCardinal() < 2){
      throw new MathError('Require at least two numbers');
    }

    const o:ContentElement[] = [];

    for(let i:number = 1 ; i < range.getCardinal() ; i++){
      o.push(new WInterval(
        IntervalClosure.CLOSED_OPEN,
        range.itemAt(i - 1),
        range.itemAt(i),
        env.culture.formats.intervalFormatImpl));
    }

    return <WListOfIntervals>env.culture.listFactory.createList(o) ;
  }

  /**
   * (0, 5, 10, 15, 20)
   * [0, 5[, [5, 10[, [10, 15[, [15, 20[
   * Créé une liste d'intervalles selon les limites spécifiées.
   * Les intervalles sont tous fermés à gauche et ouverts à droite.
   */
  private nonOverlappingIntervalsFromList(
      boundaries:WList,
      env:Environment):WListOfIntervals{

    if(boundaries.count < 2){
      throw new MathError('Require at least two numbers');
    }

    if(!this.validateBoundaries(boundaries)){
      throw new MathError('Numbers must be ordered and distinct.');
    }

    const o:ContentElement[] = [];

    for(let i:number = 1 ; i < boundaries.count ; i++){
      o.push(new WInterval(
        IntervalClosure.CLOSED_OPEN,
        boundaries.getTypedItemAt(i - 1),
        boundaries.getTypedItemAt(i),
        env.culture.formats.intervalFormatImpl));
    }

    return <WListOfIntervals>env.culture.listFactory.createList(o) ;
  }

  /**
   * Returns whether the items in the list are ordered
   */
  private validateBoundaries(values:WList):boolean{
    const o:number[] = values.toNumbersV();
    for(let i:number = 1 ; i < o.length ; i++){
      if(o[i] <= o[i - 1]){
        return false;
      }
    }
    return true;
  }

}
