import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WLine } from '../../elements/tokens/WLine';
import { WNotANumber } from '../../elements/tokens/WNotANumber';
import { WPoint } from '../../elements/tokens/WPoint';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';

/**
 *
 */
export class Slope extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length < 1 || args.length > 2){
      return args.expectingArguments(1, 2);
    }

    if(args.length === 1){
      if (args.getLine(0)) {
        return this.line(args.getLine(0));
      }
    }else if(args.length === 2){
      if (args.getPoint(0) && args.getPoint(1)) {
        return this.fromPoint(args.getPoint(0), args.getPoint(1), args.env);
      }
    }

    return null;
  }

  /**
   *
   */
  private fromPoint(a:WPoint, b:WPoint, env:Environment):ContentElement{
    if(a.x.toNumber() === b.x.toNumber()){
      return WNotANumber.getInstance();
    }
    return env.reals.divide(
      env.reals.subtract(b.y, a.y),
      env.reals.subtract(b.x, a.x),
      true);
  }

  /**
   *
   */
  private line(a:WLine):ContentElement{
    if(a.slope){
      return a.slope;
    }
    return WNotANumber.getInstance();
  }

}
