import React from 'react';
import { ICustomEditor } from '../../models';
import {
  hasBreakingCharacters,
  hasNonBreakingCharacters,
  toggleNonBreakingCharacters,
} from '../../utils/textUtils';
import { Button } from '../../../Button';
import { Tooltip } from '../../../../utils/Tooltip';

interface IToggleNonBreakingCharacterControlProps {
  editor: ICustomEditor;
  label: string;
}

export const ToggleNonBreakingCharacterControl: React.FC<IToggleNonBreakingCharacterControlProps> = ({ editor, label }) => {
  const onMouseDown = (event: React.MouseEvent) => {
    event.preventDefault();
    toggleNonBreakingCharacters(editor);
  };

  const selectionHasBreakingCharacters = hasBreakingCharacters(editor);
  const selectionHasNonBreakingCharacters = hasNonBreakingCharacters(editor);

  const isEnabled = selectionHasBreakingCharacters || selectionHasNonBreakingCharacters;
  const isActive = selectionHasNonBreakingCharacters && !selectionHasBreakingCharacters;

  return (
    <Tooltip
      title={label}
    >
      <Button
        disabled={!isEnabled}
        active={isActive}
        onMouseDown={onMouseDown}
      >
        {label}
      </Button>
    </Tooltip>
  );
};
