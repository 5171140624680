import { MathError } from '../../core/MathError';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { HoursFormatter } from '../../elements/formats/time/HoursFormatter';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Applique un format d'heures à un nombre.
 */
export class FormatHours extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 1){
      return args.expectingArguments(1, 1);
    }

    const hours:RealElement = args.getWholeNumber(0);
    if(!hours){
      return null;
    }

    if(hours.toNumber() > 23){
      throw new MathError('Hours must be between 0 and 23.');
    }
    return hours.applyFormat(new HoursFormatter(args.env.culture));
  }

}
