import { RestrictExpression } from '../../elements/models/RestrictExpression';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { TokenElement } from '../../elements/abstract/TokenElement';
import { WExpression } from '../../elements/tokens/WExpression';
import { WFiniteSet } from '../../elements/tokens/WFiniteSet';
import { WList } from '../../elements/tokens/WList';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { RestrictPolicy } from '../../funcs/expr/RestrictPolicy';

/**
 *
 */
export class DenyResults extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 2){
      return args.expectingArguments(2, 2);
    }
    if (args.getExpression(0) && args.getReals(1)) {
      return this.denyNumbers(args.getExpression(0), args.getReals(1));
    }
    if (args.getExpression(0) && args.getFiniteSet(1)) {
      return this.denyItems(args.getExpression(0), args.getFiniteSet(1));
    }
    if (args.getExpression(0) && args.getTokenElement(1)) {
      return this.denyNumber(args.getExpression(0), args.getTokenElement(1));
    }
    return null;
  }

  /**
   *
   */
  private denyNumber(
      expr:WExpression,
      result:TokenElement):WExpression{

    const o:TokenElement[] = [];
    o.push(result);
    return DenyResults.changeRestriction(expr, o);
  }

  /**
   *
   */
  private denyNumbers(
      expr:WExpression,
      results:WList):WExpression{

    const o:TokenElement[] = [];
    for(let i:number = 0 ; i < results.items.length ; i++){
      o.push(results.getTypedItemAt(i));
    }
    return DenyResults.changeRestriction(expr, o);
  }

  /**
   *
   */
  private denyItems(
      expr:WExpression,
      results:WFiniteSet):WExpression{

    return DenyResults.changeRestriction(expr, results.toElements());
  }

  /**
   *
   */
  private static changeRestriction(
      expr:WExpression,
      results:TokenElement[]):WExpression{

    const r:RestrictExpression =
      expr.restrict ?
      expr.restrict.clone() :
      new RestrictExpression();

    r.results = results;
    r.resultsPolicy = RestrictPolicy.DENY;

    return expr.setRestrict(r);
  }

}
