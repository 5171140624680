import { AbstractStep } from '../AbstractStep';
import { Compartment } from '../Compartment';
import { HLine } from '../HLine';
import { LongAdditionOperation } from '../LongAdditionOperation';
import { Padding } from '../Padding';
import { AdditionModel } from '../models/AdditionModel';

/**
 *
 */
export class AddInit extends AbstractStep {

  private model:AdditionModel;

  constructor(operation:LongAdditionOperation) {

    super(operation);
    this.model = operation.model;

    let n:number;
    let i:number;

    for(i = 0 ; i < this.model.operands.length ; i++){
      n = this.model.operands[i];
      const operand:Compartment[] = [];
      super.writeNumber(
        n,
        operand,
        this.target,
        new Padding(this.model.integerLength, null),
        this.model.decimalLength > 0 ?
        new Padding(
          this.model.decimalLength,
          this.model.padDecimals ?
          Compartment.createDigit(0).fade() :
          null) :
        null);

      operation.operands.push(operand);
    }

    operation.addLine = new HLine();
    this.lines.push(operation.addLine);

    operation.addSign = Compartment.createOperator('+');
    this.target.push(operation.addSign);

    operation.position = -this.model.decimalLength;

    operation.carries.push(null); // insert the first carry, there's never a carry over the rightmost column

    const o:any[] = [];
    for(i = 0 ; i < this.model.operands.length ; i++){
      o.push(operation.culture.formatNumber(this.model.operands[i]));
    }

    this.setDescription(
      operation.culture.getString(
        'LongAdditionOperation.initLabel',
        operation.culture.numberFormatter.getDecimalSeparatorLabel(true)));
  }

}
