import React from 'react';
import { Spinner } from '@scolab/publisher-ui-kit';
import styled from 'styled-components';
import { IStandard } from '../../../../models/IStandard';
import { StandardItem } from './StandardItem';

interface ISelectedStandardsList {
  readonly standards: ReadonlyArray<IStandard>;
  readonly onClear: (standard: IStandard) => void;
}

export class SelectedStandardsList extends React.Component<ISelectedStandardsList> {

  public render(): JSX.Element {

    return (
      <Wrapper>
        <ListContainer>
          {this.renderStandardsList()}
        </ListContainer>
      </Wrapper>
    );
  }

  private renderStandardsList = (): JSX.Element[] => {
    const {
      standards,
      onClear
    } = this.props;

    if (!standards) {
      return [
        (<Spinner key='spinner' />)
      ];
    }

    return standards.map((s: IStandard) => {
      return (
        <StandardItem
          key={s.id}
          standard={s}
          selected
          isOpen={false}
          onClear={onClear}
        />
      );
    });
  }
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow: hidden;
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  gap: 4px;
  overflow-y: overlay;
  padding 0 16px 16px;
`;
