import React from 'react';
import styled from 'styled-components';
import { Dropdown } from '../../utils/Dropdown';
import { ColorPresets } from './ColorPresets';
import { ColorCircle } from './ColorCircle';
import { TopLabeledInput } from '../TopLabeledInput';
import { IPresetColorGroup } from './models';
import { TopLabeledSelectedItem } from '../Select/TopLabeledSelectedItem';
import { ReadonlyContext } from '../../../contexts/ReadonlyContext';

export interface IColorPickerProps {
  selectedColor: string;
  colorPresets: ReadonlyArray<IPresetColorGroup>;
  onChange: (color: string) => void;
  Label: React.ReactNode;
  disabled?: boolean;
  className?: string;
  onChangeOpen?: (open: boolean) => void;
  onMouseDown?: (event: React.MouseEvent) => void;
}

export const ColorPicker: React.FC<IColorPickerProps> = (props) => {
  const {
    onChange,
    Label,
    selectedColor,
    colorPresets,
    onChangeOpen,
    className,
    onMouseDown,
    disabled,
  } = props;
  const dropdownRef = React.useRef<HTMLDivElement>(null);
  const availableWidthRef = React.useRef<number>(0);
  const [open, _setOpen] = React.useState(false);
  const [inputValue, setInput] = React.useState('');
  const isReadonly = React.useContext(ReadonlyContext);
  const isDisabled = disabled || isReadonly;
  const setOpen = (newOpen: boolean) => {
    availableWidthRef.current = dropdownRef.current.getBoundingClientRect().width - 16;
    _setOpen(newOpen);
    onChangeOpen?.(newOpen);
  };
  const onClickPreset = (newValue: string) => {
    setInput('');
    onChange(newValue);
    setOpen(false);
  };
  const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = getValidColor(event.target.value);
    setInput(value);
    const isValidColor = value.length === 3 || value.length === 6 || value.length === 8;
    if (isValidColor) {
      onChange('#' + value);
    }
  };
  const onInputKeyUp = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      setOpen(false);
    }
  };
  const colorPresetName = getColorPresetName(selectedColor, colorPresets);
  const onChangeOpenLocal = (newOpen: boolean) => {
    if (newOpen && !colorPresetName) {
      setInput(getValidColor(selectedColor));
    }
    setOpen(newOpen);
  };
  const SelectedComponent = (
    <AnchorContainer>
      <ColorCircle
        color={selectedColor}
      />
      <TopLabeledSelectedItem
        label={Label}
        selectedLabel={colorPresetName || selectedColor}
      />
    </AnchorContainer>
  );
  return (
    <Dropdown
      ref={dropdownRef}
      SelectedComponent={SelectedComponent}
      open={open}
      onChangeOpen={onChangeOpenLocal}
      onMouseDown={onMouseDown}
      className={className}
      disabled={isDisabled}
    >
      <PopupContainer>
        <StyledTopLabeledInput
          label="HEX"
          value={inputValue}
          onChange={onChangeInput}
          onKeyUp={onInputKeyUp}
        />
        <ColorPresets
          colorPresets={colorPresets}
          onClickPreset={onClickPreset}
          availableWidth={availableWidthRef.current}
        />
      </PopupContainer>
    </Dropdown>
  );
};

const getValidColor = (color: string) => {
  if (!color) {
    return color;
  }
  return color
    .replace(/[^0-9|a-f|A-F]+/g, '')
    .substring(0, 8);
};

const getColorPresetName = (color: string, colorPresets: ReadonlyArray<IPresetColorGroup>) => {
  for (const colorPresetGroup of colorPresets) {
    for (const colorPresetArray of colorPresetGroup.values) {
      for (const colorPreset of colorPresetArray) {
        if (colorPreset.value === color) {
          return colorPreset.Label;
        }
      }
    }
  }
  return null;
};

const AnchorContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 8px;
  user-select: none;
`;

const PopupContainer = styled.div`
  padding: 8px;
`;

const StyledTopLabeledInput = styled(TopLabeledInput)`
  margin-bottom: 12px;
  width: 100%;
  box-sizing: border-box;
`;
