import { AnonymousFunction } from '../../../elements/abstract/AnonymousFunction';
import { Node } from '../../../elements/abstract/Node';
import { RealElement } from '../../../elements/abstract/RealElement';
import { Apply } from '../../../elements/constructs/Apply';
import { BoundVariable } from '../../../elements/constructs/BoundVariable';
import { Lambda } from '../../../elements/constructs/Lambda';
import { Otherwise } from '../../../elements/constructs/Otherwise';
import { Piecewise } from '../../../elements/constructs/Piecewise';
import { WVariable } from '../../../elements/tokens/WVariable';
import { Abs } from '../../../funcs/arithmetic/Abs';
import { Divide } from '../../../funcs/arithmetic/Divide';
import { Minus } from '../../../funcs/arithmetic/Minus';
import { Plus } from '../../../funcs/arithmetic/Plus';
import { Power } from '../../../funcs/arithmetic/Power';
import { Sqrt } from '../../../funcs/arithmetic/Sqrt';
import { Times } from '../../../funcs/arithmetic/Times';
import { Cos } from '../../../funcs/trigonometry/Cos';
import { Degrees } from '../../../funcs/trigonometry/Degrees';
import { Radians } from '../../../funcs/trigonometry/Radians';
import { Sin } from '../../../funcs/trigonometry/Sin';
import { Tan } from '../../../funcs/trigonometry/Tan';
import { Operation } from '../../../expr/manipulation/optimized/Operation';
import { CompiledFunction } from '../../../expr/manipulation/optimized/CompiledFunction';
import { IEval2 } from '../../../expr/manipulation/optimized/IEval2';
import { IEval1 } from '../../../expr/manipulation/optimized/IEval1';

/**
 *
 */
export class FunctionCompiler {

  /**
   *
   */
  public static compileOneArgumentFunction(f:AnonymousFunction):IEval1{
    return FunctionCompiler.tryParse(f, 1);
  }

  /**
   *
   */
  public static compileTwoArgumentsFunction(f:AnonymousFunction):IEval2{
    return FunctionCompiler.tryParse(f, 2);
  }

  /**
   * Try to convert anonymous function into a fast evaluator.
   * If some functionalities of the anonymous function are not handled by the
   * FunctionCompiler class then it returns null and client code should
   * fallback on classic Evaluate.
   */
  private static tryParse(
      func:AnonymousFunction,
      requiredArgumentCount:number):CompiledFunction{

    //
    if(func.node.numChildren !== requiredArgumentCount+1){
      return null;
    }

    const o:any[] = [0]; // the first index is reserved for the value of the variable x
    const z:Operation[] = [];
    const a:string[] = [];

    try{
      if(func.node.value instanceof Lambda){
        // List args names
        for(let i:number = 0 ; i < func.node.childs.length - 1 ; i++){
          const argument:Node = func.node.childs[i];
          if(!(argument.value instanceof BoundVariable)){
            throw new Error('Function signature invalid');
          }
          a.push((<BoundVariable>argument.value ).getVariable().getSymbol());
        }

        // Last child is the body
        const body:Node = func.node.childs[func.node.childs.length - 1];
        if(!(body.value instanceof Piecewise)){
          throw new Error('Missing function body');
        }else{
          FunctionCompiler.tryParseNode(body, o, z, a);
        }

        return new CompiledFunction(o, z);
      }
    }catch(e){
      return null;
    }
  }

  /*
  <math>
    <lambda>
      <boundvariable/>
      <piecewise>
        <otherwise>
          <boundvariable/>
        </otherwise>
      </piecewise>
    </lambda>
  </math>
  */
  private static tryParseNode(
      node:Node,
      initial:any[],
      operations:Operation[],
      varNames:string[]):void{

    let child:Node;

    if(		node.value instanceof Piecewise ||
        node.value instanceof Otherwise){

      for(let i:number = 0 ; i < node.numChildren ; i++){
        child = node.childs[i];
        FunctionCompiler.tryParseNode(child, initial, operations, varNames);
      }
    }else if(node.value instanceof Apply){
      FunctionCompiler.tryParseOperation(node, initial, operations, varNames);
    }else if(node.value instanceof RealElement){
      FunctionCompiler.tryParseConstant(node, initial, operations, varNames);
    }else if(node.value instanceof BoundVariable){
      FunctionCompiler.tryParseVariable(node, initial, operations, varNames);
    }else{
      throw new Error(`Could not optimize evaluation because of construct ${node.value}`);
    }
  }

  /**
   *
   */
  private static tryParseConstant(
      node:Node,
      initial:any[],
      operations:Operation[],
      varNames:string[]):void{

    initial.push((<RealElement>node.value ).toNumber()); // input
    initial.push(0); // ouput

    const o:Operation = new Operation();
    o.operator = 0; // fetch
    o.input = [initial.length - 2];
    o.output = initial.length - 1;
    operations.push(o);
  }

  /**
   *
   */
  private static tryParseVariable(
      node:Node,
      initial:any[],
      operations:Operation[],
      varNames:string[]):void{

    initial.push(0); // ouput

    const bvar:string = (<BoundVariable>node.value ).getVariable().getSymbol();
    const bvarIndex:number = varNames.indexOf(bvar);
    if(bvarIndex === -1){
      throw new Error(`Unknown variable ${bvar}`);
    }

    const o:Operation = new Operation();
    o.operator = 0; // fetch
    o.input = [bvarIndex];
    o.output = initial.length - 1;
    operations.push(o);
  }

  /**
   *
   */
  private static tryParseOperation(
      node:Node,
      initial:any[],
      operations:Operation[],
      varNames:string[]):void{

    let operator:number;
    if(node.firstChild.value instanceof Plus){
      operator = 1;
    }else if(node.firstChild.value instanceof Minus){
      operator = 2;
    }else if(node.firstChild.value instanceof Divide){
      operator = 3;
    }else if(node.firstChild.value instanceof Times){
      operator = 4;
    }else if(node.firstChild.value instanceof Power){
      operator = 5;
    }else if(node.firstChild.value instanceof Radians){
      operator = 6;
    }else if(node.firstChild.value instanceof Degrees){
      operator = 7;
    }else if(node.firstChild.value instanceof Sin){
      operator = 8;
    }else if(node.firstChild.value instanceof Cos){
      operator = 9;
    }else if(node.firstChild.value instanceof Tan){
      operator = 10;
    }else if(node.firstChild.value instanceof Sqrt){
      operator = 11;
    }else if(node.firstChild.value instanceof Abs){
      operator = 12;
    }else{
      throw new Error(`Could not optimize evaluation because of operator ${node.firstChild.value}`); // unsupported operator
    }

    const o:Operation = new Operation();
    const input:any[] = [];
    const output:number = initial.length;
    initial.push(0);

    for(let i:number = 1 ; i < node.numChildren ; i++){
      const arg:Node = node.childs[i];
      if(arg.value instanceof Apply){
        input.push(initial.length);
        FunctionCompiler.tryParseOperation(arg, initial, operations, varNames);
      }else if(arg.value instanceof RealElement){
        input.push(initial.length);
        initial.push((<RealElement>arg.value ).toNumber());
      }else if(arg.value instanceof BoundVariable){
        const bvar:string = (<BoundVariable>arg.value ).getVariable().getSymbol();
        const bvarIndex:number = varNames.indexOf(bvar);
        if(bvarIndex === -1){
          throw new Error(`Unknown variable ${bvar}`);
        }
        input.push(bvarIndex);
      }else if(arg.value instanceof WVariable){
        throw new Error(`Could not optimize evaluation because of argument ${(<WVariable>arg.value).getSymbol()}`); // unsupported operand
      }else{
        throw new Error(`Could not optimize evaluation because of argument ${arg.value}`); // unsupported operand
      }
    }

    o.operator = operator;
    o.input = input;
    o.output = output;
    operations.push(o);
  }

}
