import { XMath } from '../../core/XMath';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Greatest Common Divisor.
 */
export class Gcd extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 2){
      return args.expectingArguments(2, 2);
    }
    if(args.getInteger(0) && args.getInteger(1)){
      return args.env.culture.createNumber(XMath.gcd(args.getInteger(0).toNumber(), args.getInteger(1).toNumber()));
    }
    return null;
  }

}
