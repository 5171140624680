import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';
import { RadicalModel } from '../../elements/models/RadicalModel';
import { CultureInfo } from '../../localization/CultureInfo';
import { AbstractFormatter } from '../../elements/formats/AbstractFormatter';

/**
 *
 */
export class BaseRadicalFormatter extends AbstractFormatter {

  /**
   *
   */
  constructor(culture:CultureInfo){
    super(culture);
  }

  /**
   *
   */
  public writeTo(exporter:IMarkupExporter, value:RadicalModel):boolean{
    throw new Error();
  }

  /**
   * Indicates whether the specified radical is handled by the formatter.
   *
   * For example, some formatters may be able to work
   * only on a range of numbers, or on integers only, ect...
   */
  public willFormat(value:RadicalModel):boolean{
    return true;
  }

  /**
   * 0: No factorization,
   * 1: Basic integer factorization of base and coef,
   * 2: Separate base factors when having exponents that are a multiple of the root index.
   * 	  For example, if one of the factor in the base of a square root is 2^3, write it as 2^2 x 2.
   * 3: In the coefficient, cross common factors with a specified list of factors.
   */
  public get factorizationLevel():number{
    return 0;
  }

}
