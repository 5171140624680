import { StringNavigator } from '../../core/str/StringNavigator';
import { StringQuery } from '../../funcs/str/StringQuery';

/**
 *
 */
export class Words extends StringQuery {

  /**
   *
   */
  protected searchPattern():RegExp{
    return new RegExp(`[a-z-${StringNavigator.ACCENTS}]+`, 'gi');
  }

}
