import { RealElement } from '../../elements/abstract/RealElement';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';
import { WNumber } from '../../elements/tokens/WNumber';

/**
 *
 */
export class WColor extends RealElement {

  private _name:string;

  private _rgb:WNumber;

  public get name():string{return this._name;}

  public get rgb():WNumber{return this._rgb;}

  constructor(name:string, rgb:WNumber) {

    super();
    this._name = name;
    this._rgb = rgb;
  }

  public toString():string{
    return this._name;
  }

  public toNumber():number{
    return this._rgb.toNumber();
  }

  public toText(strict:boolean):string{
    return this._name;
  }

  public writeTo(exporter:IMarkupExporter = null):boolean{
    if(exporter){
      exporter.writer.appendNumber(this._name);
    }
    return true;
  }

  public toAbsoluteValue():RealElement{
    return this;
  }

  public toOpposite():RealElement{
    return this._rgb.toOpposite();
  }

  public getType():string {
    return 'color';
  }

}
