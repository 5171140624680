import React from 'react';
import styled from 'styled-components';
import { Editor, RangeRef } from '../../TypedSlate';
import { insertTTS } from '../../utils';
import { PopoverIconButton } from '../../components/PopoverIconButton';
import AudioIcon from '../../../../../assets/volume.svg';
import { Button } from '../../../Button';
import AddIcon from '../../../../../assets/add.svg';
import { PopupInput } from '../../components/Input';
import { Tooltip } from '../../../../utils/Tooltip/Tooltip';
import { ICustomEditor } from '../../models';

export interface IAddTTSButtonProps {
  editor: ICustomEditor;
  label: string;
  inputPlaceholder: string;
}

interface IAddTTSButtonState {
  altText: string;
  rangeRef: RangeRef;
  open: boolean;
}

export class AddTTSButton extends React.Component<IAddTTSButtonProps, IAddTTSButtonState> {
  constructor(props: IAddTTSButtonProps) {
    super(props);
    this.state = {
      altText: '',
      rangeRef: null,
      open: false,
    };
  }

  public render(): React.ReactNode {
    const { label, inputPlaceholder } = this.props;
    const { altText, open } = this.state;

    const button = (
      <Tooltip
        title={label}
      >
        <Button
          icon
          data-testid="AddTTSButton"
        >
          <AudioIcon/>
        </Button>
      </Tooltip>
    );

    return (
      <PopoverIconButton
        open={open}
        onOpen={this.onOpen}
        onClose={this.onClose}
        ButtonContent={button}
        aria-label={label}
      >
        <SizedPopupInput
          value={altText}
          placeholder={inputPlaceholder}
          onChange={this.onChangeAltText}
          data-testid="AddTTSInput"
        />
        <Button
          onClick={this.onCreateTTS}
          data-testid="AddTTSConfirmButton"
          icon
        >
          <AddIcon/>
        </Button>
      </PopoverIconButton>
    );
  }

  private onClose = () => {
    const { rangeRef } = this.state;
    rangeRef.unref();
    this.setState({
      altText: '',
      open: false,
      rangeRef: null,
    });
  }

  private onOpen = () => {
    const { editor } = this.props;
    const rangeRef = Editor.rangeRef(editor, editor.selection);
    this.setState({
      rangeRef,
      altText: '',
      open: true,
    });
  }

  private onCreateTTS = () => {
    const { editor } = this.props;
    const { altText, rangeRef } = this.state;
    insertTTS(editor, altText, rangeRef);
    this.onClose();
  }

  private onChangeAltText = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      altText: event.target.value,
    });
  }
}

const SizedPopupInput = styled(PopupInput)`
  width: 184px;
`;
