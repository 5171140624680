import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Button, ColorSet, SearchIcon, themedColor, TopLabeledInput } from '@scolab/publisher-ui-kit';
import styled from 'styled-components';

export const searchBoxHeight = 73;

interface IAlignmentSearchBoxProps extends WrappedComponentProps{
  onClick: () => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClear: () => void;
  onEnter: () => void;
  value: string;
}

const AlignmentSearchBoxComponent: React.FC<IAlignmentSearchBoxProps> = (props) => {
  const {
    onClick,
    onChange,
    onClear,
    onEnter,
    value,
    intl
  } = props;

  return (
    <SearchBoxDiv>
      <TopLabeledInputStyled
        data-testid="searchInput"
        label={
          intl.formatMessage({
            id: 'alignmentAndStandard.keyWord',
            defaultMessage: 'Key word'
          })
        }
        onChange={onChange}
        onClear={onClear}
        onEnter={onEnter}
        value={value}
      />
      <StyledButton
        onClick={onClick}
        icon
      >
        <SearchIcon/>
      </StyledButton>
    </SearchBoxDiv>
  )
};

export const AlignmentSearchBox = injectIntl(AlignmentSearchBoxComponent);

const SearchBoxDiv = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  height: ${searchBoxHeight}px;
  padding: 0 16px 0 16px;
  border-bottom: 1px solid ${(props) => props.theme.modal.separator.color};
`;

const TopLabeledInputStyled = styled(TopLabeledInput)`
  flex: 1;
  margin-right: 8px;
`;

const StyledButton = styled(Button)`
  background-color: ${themedColor({ dark: ColorSet.penumbra })};
  height: 40px;
  width: 40px;
`;
