import { MathError } from '../../core/MathError';
import { XString } from '../../core/XString';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { DecimalExpansionFormatter } from '../../elements/formats/rationals/DecimalExpansionFormatter';
import { WList } from '../../elements/tokens/WList';
import { WRational } from '../../elements/tokens/WRational';
import { WString } from '../../elements/tokens/WString';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { CultureInfo } from '../../localization/CultureInfo';

/**
 * Compatibility
 */
export class CnmRepeatingDecimal extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 2){
      return args.expectingArguments(2, 2);
    }

    const pattern:WString = args.getString(0);
    if(!pattern){
      return null;
    }

    const values:WList = args.getReals(1);
    if(!values){
      return null;
    }

    return this.exec(pattern.getString(), values.toNumbers(), args.env.culture);
  }

  /**
   *
   */
  private exec(
      patternArg:string,
      values:any[],
      culture:CultureInfo):WRational{

    let pattern = patternArg;
    let o:any[] = [pattern];
    o = o.concat(values);

    pattern = XString.substitute.apply(null, o);

    let s:string = pattern.split('|')[0];
    const rep:string = pattern.split('|')[1];

    if(rep === '9'){
      while(this.validateNextRep(s, rep)){
        s += rep;
      }
    }else{
      while(s.length < 20){
        s += rep;
      }
    }

    if(isNaN(Number(s))){
      return null;
    }

    const r:WRational =
      WRational.rationalize(
        Number(s),
        999999,
        new DecimalExpansionFormatter(culture));

    let d:string = r.formatter.toLocaleString(r.numerator, r.denominator);

    if(d.indexOf('(') !== -1){
      d = d.split('(').join('|').split(')').join('');
    }
    d = d.split(',').join('.');

    if(d === pattern){
      return r;
    }
    throw new MathError('Invalid pattern');
  }

  /**
   *
   */
  private validateNextRep(
      value:string,
      rep:string):boolean{
    const n:number = Number(value + rep);
    const s:string = n.toString();
    return s.charAt(s.length - rep.length) === rep;
  }

}
