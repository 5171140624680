import { MmlWriter } from '../../../core/mml/MmlWriter';
import { BaseRationalFormatter } from '../../../elements/formats/BaseRationalFormatter';
import { IMarkupExporter } from '../../../elements/markers/IMarkupExporter';
import { CultureInfo } from '../../../localization/CultureInfo';
import { FractionFormatter } from '../../../elements/formats/rationals/FractionFormatter';

/**
 *
 */
export class PositiveNegativeFractionFormatter extends BaseRationalFormatter {

  private withSpace:boolean = false;

  /**
   *
   */
  constructor(
      culture:CultureInfo,
      withSpace:boolean = false) {
    super(culture);
    this.withSpace = withSpace;
  }

  /**
   *
   */
  public toLocaleString(numerator:number, denominator:number):string {
    const value:number = numerator / denominator;
    return 	(value < 0 ? '−' : '+') +
        (this.withSpace ? ' ' : '') +
        FractionFormatter.getImproperNotation(this.culture).toLocaleString(Math.abs(numerator), Math.abs(denominator));
  }

  /**
   *
   */
  public writeTo(exporter:IMarkupExporter, numerator:number, denominator:number):boolean{
    const value:number = numerator / denominator;

    const writer:MmlWriter = exporter.writer;

    writer.beginRow();
    writer.appendOperator(value < 0 ? '−' : '+');

    if( this.withSpace ) {
      writer.appendSpace();
      writer.width = '0.2em';
    }

    FractionFormatter.getImproperNotation(this.culture).writeTo(exporter, Math.abs(numerator), Math.abs(denominator));
    writer.endRow();
    return true;
  }

  /**
   *
   */
  public equals(other:BaseRationalFormatter):boolean {
    return other instanceof PositiveNegativeFractionFormatter;
  }

  /**
   *
   */
  public isPrefixedWithSign():boolean{
    return true;
  }

}
