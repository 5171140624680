import { FunctionElement } from './FunctionElement';
import { AnonymousFunction } from './AnonymousFunction';

export class RecallFunction extends FunctionElement {

  private _functionRef:AnonymousFunction;

  public get functionRef():AnonymousFunction{return this._functionRef;}

  constructor(functionRef: AnonymousFunction){
    super();
    this.setDisplayName('@');
    this._functionRef = functionRef;
  }

}
