import { XMath } from '../../../core/XMath';
import { XString } from '../../../core/XString';
import { ContentElement } from '../../../elements/abstract/ContentElement';
import { FunctionElement } from '../../../elements/abstract/FunctionElement';
import { Node } from '../../../elements/abstract/Node';
import { RealElement } from '../../../elements/abstract/RealElement';
import { FormatProvider } from '../../../elements/factories/FormatProvider';
import { FractionFormatter } from '../../../elements/formats/rationals/FractionFormatter';
import { WNumber } from '../../../elements/tokens/WNumber';
import { WRational } from '../../../elements/tokens/WRational';
import { Environment } from '../../../expr/Environment';
import { Skeleton } from '../../../expr/manipulation/Skeleton';
import { Divide } from '../../../funcs/arithmetic/Divide';
import { Minus } from '../../../funcs/arithmetic/Minus';
import { Plus } from '../../../funcs/arithmetic/Plus';
import { Times } from '../../../funcs/arithmetic/Times';
import { CultureInfo } from '../../../localization/CultureInfo';
import { AbstractRule } from '../../../expr/manipulation/rules/AbstractRule';

/**
 *
 */
export class FractionsArithmetic extends AbstractRule {

  private intPart:number;

  private numPart:number;

  private denPart:number;

  constructor(){
    super(true, false);
    this.intPart = FractionFormatter.INT_PART;
    this.numPart = FractionFormatter.NUMERATOR_PART;
    this.denPart = FractionFormatter.DENOMINATOR_PART;
  }

  private static skeletons:any[] = FractionsArithmetic.createSkeletons();

  private static createSkeletons():any[]{
    return Skeleton.combine(
        '{0}({1},{2})',
        [['*', '/', '+', '-'],
        ['r', 'n'], ['r', 'n']]);
  }

  public applyNode(node:Node, stateMode:number, env:Environment):Node{
    this._comment = null;

    const skeleton:string = Skeleton.createSkeleton(node);
    const rxCommutative:RegExp = /^[\+\*]\(r,r(,r)*\)$/;

    if(FractionsArithmetic.skeletons.indexOf(skeleton) === -1 && !rxCommutative.test(skeleton)){
      return null;
    }

    let i:number;
    let n:RealElement;
    const op:FunctionElement = <FunctionElement>node.childs[0].value ;

    let hasRational:boolean = false;
    let hasDecimal:boolean = false;

    const operands:RealElement[] = [];
    for(i = 1 ; i < node.numChildren; i++){
      n = <RealElement>node.childs[i].value ;
      operands.push(n);
      if(n instanceof WRational){
        hasRational = true;
      }else if(n instanceof WNumber){
        hasDecimal = true;
      }
    }

    if(!hasRational){return null;}

    if(hasDecimal){
      const node2 = node.clone();
      node2.clear(1);

      for(i = 0 ; i < operands.length ; i++){
        n = operands[i];

        if(n instanceof WRational){
          node2.appendChild(new Node(n));
        }else if(n.isInteger()){
          node2.appendChild(new Node(env.culture.createRational(n.toNumber(), 1)));
        }else{
          return null;
        }
      }

      return node2;
    }

    // All arguments should be rationals at this point
    const operandsR:WRational[] = [];
    for(i = 0 ; i < operands.length ; i++){
      const r:WRational = <WRational>operands[i] ;
      if(!(r.formatter instanceof FractionFormatter)){
        return null;
      }
      operandsR.push(this.normalizeNegative(r));
    }

    if(op instanceof Minus){
      return this.checkMinus(node, operandsR, env);
    }
    if(op instanceof Plus){
      return this.checkPlus(node, operandsR, env);
    }
    if(op instanceof Times){
      return this.checkTimes(node, operandsR, env);
    }
    if(op instanceof Divide){
      return this.checkDivide(node, operandsR, env);
    }

    return null;
  }

  public applyValue(
      element:ContentElement,
      format:FormatProvider,
      stateMode:number,
      env:Environment,
      isLastOpportunity:boolean):ContentElement{

    if(!(element instanceof WRational)){
      return null;
    }

    let r:WRational = <WRational>element ;

    if(r.formatter instanceof FractionFormatter){
      const rf:FractionFormatter = <FractionFormatter>r.formatter ;
      if(rf.integer > 0){
        if(rf.integer < this.explicitInteger(r)){
          if(!rf.mixed){
            this._comment =
              this.getString(
                env.culture.configuration.avoidImproperFractionTerm ?
                  'mixedNumber2' :
                  'mixedNumber',
                env.culture);
            r = <WRational>r.applyFormat(FractionFormatter.getMixedNotation(env.culture).setInteger(rf.integer)) ;
            return this.setValueEmphasis(r, this.intPart | this.numPart);
          }
          this._comment = this.getString('addWholeNumbers', env.culture);
          r = <WRational>r.applyFormat(FractionFormatter.getMixedNotation(env.culture)) ;
          return this.setValueEmphasis(r, this.intPart);
        }
      }
    }

    if(isLastOpportunity){
      // Write the fraction in simplest form.
      const c:RealElement = r.normalize(env.options.simplifyRationals);
      if(!r.equalsTo(c)){
        this._comment = this.getString('simplifyFraction', env.culture);
        return c;
      }

      if(r.isImproper && format.rationalFormatImpl && format.rationalFormatImpl.isMixed()){
        this._comment =
          this.getString(
            env.culture.configuration.avoidImproperFractionTerm ?
            'mixedNumber' :
            'mixedNumber2',
            env.culture);

        r = new WRational(
          r.numerator,
          r.denominator,
          format.rationalFormatImpl);

        return this.setValueEmphasis(r, this.intPart | this.numPart);
      }

      if(format.rationalFormatImpl){
        if(!r.formatter.equals(format.rationalFormatImpl)){
          this._comment = null;
          r = new WRational(
            r.numerator,
            r.denominator,
            format.rationalFormatImpl);

          return r;
        }
      }else if(r.formatter instanceof FractionFormatter && (<FractionFormatter>r.formatter ).isDecorated){
        this._comment = null;
        this._decorationRemoved = true;
        r = new WRational(
          r.numerator,
          r.denominator,
          (<FractionFormatter>r.formatter ).clearDecoration());

        return r;
      }
    }

    return null;
  }

  private mixedToImproper(node:Node, operandsR:WRational[], env:Environment):Node{

    let i:number;
    let r:WRational;

    let hasMixed:boolean = false;
    for(i = 0 ; i < operandsR.length ; i++){
      r = operandsR[i];
      if(r.isMixed){
        hasMixed = true;
        break;
      }
    }

    if(!hasMixed){
      return null;
    }

    const node2 = node.clone();
    node2.clear(1);

    for(i = 0 ; i < operandsR.length ; i++){
      r = operandsR[i];
      if(r.isMixed){
        r = this.setValueEmphasis(this.improper(r), this.numPart);
      }
      node2.appendChild(new Node(r));
    }

    this._comment =
      this.getString(
        env.culture.configuration.avoidImproperFractionTerm ?
          'improperFraction2' :
          'improperFraction',
        env.culture);

    return node2;
  }

  private checkDivide(node:Node, operandsR:WRational[], env:Environment):Node{
    let node2:Node;

    // Change mixed numbers to improper fractions
    node2 = this.mixedToImproper(node, operandsR, env);
    if(node2){
      return node2;
    }

    const a:WRational = operandsR[0];
    const b:WRational = operandsR[1];

    // Rewrite as multiplication.
    const times:Times = new Times();
    times.other = '×';
    node2 = node.clone();
    node2.clear();
    node2.appendChild(new Node(times));
    node2.appendChild(new Node(a));
    node2.appendChild(new Node(b.reverse));

    // Cant emphasize the multiplication symbol because the MathML renderer
    // is not able yet to detect the right form, infix in that case, when
    // inside an mstyle element.
    // node2.childs[0].className =

    this.setOperationEmphasis2(node2, 0, this.numPart | this.denPart);

    this._comment = this.getString('rewriteDivision', env.culture);

    return node2;
  }

  private checkTimes(node:Node, operandsR:WRational[], env:Environment):Node{
    let node2:Node;

    // Change mixed numbers to improper fractions
    node2 = this.mixedToImproper(node, operandsR, env);
    if(node2){
      return node2;
    }

    if(operandsR.length === 2){
      const a:WRational = operandsR[0];
      const b:WRational = operandsR[1];

      // Reduce factors
      const down:number = XMath.gcd(a.numerator, b.denominator);
      if(down !== 1){
        node2 = node.clone();
        node2.clear(1);
        node2.appendChild(new Node(this.improper(a.setNumerator(a.numerator / down))));
        node2.appendChild(new Node(this.improper(b.setDenominator(b.denominator / down))));
        this.setOperationEmphasis2(node2, this.numPart, this.denPart);
        this._comment = XString.substitute(this.getString('commonFactor', env.culture), down);
        return node2;
      }

      // Reduce factors
      const up:number = XMath.gcd(a.denominator, b.numerator);
      if(up !== 1){
        node2 = node.clone();
        node2.clear(1);
        node2.appendChild(new Node(this.improper(a.setDenominator(a.denominator / up))));
        node2.appendChild(new Node(this.improper(b.setNumerator(b.numerator / up))));
        this.setOperationEmphasis2(node2, this.denPart, this.numPart);
        this._comment = XString.substitute(this.getString('commonFactor', env.culture), up);
        return node2;
      }
    }

    // Multiply
    let numeratorsProduct:number = 1;
    let denominatorsProduct:number = 1;

    for(let i:number = 0 ; i < operandsR.length ; i++){
      numeratorsProduct *= operandsR[i].numerator;
      denominatorsProduct *= operandsR[i].denominator;
    }

    this._comment = this.getString('multiplyFractions', env.culture);
    node2 =
      new Node(
        env.culture.createRational(
          numeratorsProduct,
          denominatorsProduct));

    this.setNodeValueEmphasis(node2, this.numPart | this.denPart);

    return node2;
  }

  private checkMinus(node:Node, operandsR:WRational[], env:Environment):Node{
    let node2:Node;

    // Change mixed numbers to improper fractions
    node2 = this.mixedToImproper(node, operandsR, env);
    if(node2){
      return node2;
    }

    // Convert fractions using the same denominator
    node2 = this.sameDenominator(node, operandsR, env);
    if(node2){
      return node2;
    }

    const a:WRational = operandsR[0];
    const b:WRational = operandsR[1];

    if(a.denominator === b.denominator){
      this._comment = this.getString('subtractNumerators', env.culture);

      node2 = new Node(
        env.culture.createRational(
          a.numerator - b.numerator,
          a.denominator));

      this.setNodeValueEmphasis(node2, this.numPart);

      return node2;
    }

    return null;
  }

  private checkPlus(node:Node, operandsR:WRational[], env:Environment):Node{
    let node2:Node;
    let i:number;

    // Convert fractions using the same denominator
    node2 = this.sameDenominator(node, operandsR, env);
    if(node2){
      return node2;
    }

    let numMixedNumbers:number = 0;
    let mixedNumberAt:number = -1;
    let sumNumerators:number = 0;
    for(i = 0 ; i < operandsR.length ; i++){
      sumNumerators += operandsR[i].numerator;
      if(operandsR[i].isMixed){
        mixedNumberAt = i;
        numMixedNumbers++;
      }
    }

    // Add whole numbers
    if(numMixedNumbers >= 2){
      node2 = node.clone();
      let cumuInteger:number = 0;
      const operandsR2:WRational[] = [];

      for(i = 1 ; i < operandsR.length ; i++){
        const integer:number = Math.floor(operandsR[i].toNumber());
        cumuInteger += integer;
        operandsR2.push(this.addInt(this.breakMixed(operandsR[i]), -integer));
      }

      operandsR2.unshift(this.addInt(this.breakMixed(operandsR[0]), cumuInteger));

      node2.clear(1);
      for(i = 0 ; i < operandsR.length ; i++){
        node2.appendChild(new Node(operandsR2[i]));
      }

      this.setOperationEmphasisN(node2, this.intPart);
      this._comment = this.getString('addWholeNumbers', env.culture);
      return node2;
    }

    // Add numerators
    this._comment = this.getString('combineNumerators', env.culture);

    let rf:FractionFormatter = null;

    if(mixedNumberAt !== -1){
      rf = new FractionFormatter(env.culture, false, false, true);
      rf = rf.setInteger(this.explicitInteger(operandsR[mixedNumberAt])).setExpand(true);
    }

    let r:WRational =
      new WRational(
        sumNumerators,
        operandsR[0].denominator,
        rf || env.culture.formats.rationalFormatImpl);

    r = this.setValueEmphasis(r, this.numPart);
    return new Node(r);
  }

  private explicitInteger(value:WRational):number{
    return Math.abs(Math.floor(value.toNumber()));
  }

  private sameDenominator(
      nodeArg:Node,
      operandsR:WRational[],
      env:Environment):Node{

    let node = nodeArg;
    let i:number;
    let hasDifferentDenominators:boolean = false;
    let lowestCommonMultiple:number = operandsR[0].denominator;
    for(i = 1 ; i < operandsR.length ; i++){
      lowestCommonMultiple = XMath.lcm(lowestCommonMultiple, operandsR[i].denominator);
      if(operandsR[0].denominator !== operandsR[i].denominator){
        hasDifferentDenominators = true;
      }
    }

    if(!hasDifferentDenominators){
      return null;
    }

    node = node.clone();
    node.clear(1);

    for(i = 0 ; i < operandsR.length ; i++){
      node.appendChild(new Node(this.changeDenominator(operandsR[i], lowestCommonMultiple)));
    }

    this.setOperationEmphasisN(node, this.numPart | this.denPart);
    this._comment = this.getString('commonDenominator', env.culture);
    return node;
  }

  private setOperationEmphasis2(node:Node, emphasis0:number, emphasis1:number):void{
    const ra:WRational = <WRational>node.childs[1].value ;
    const rb:WRational = <WRational>node.childs[2].value ;
    node.clear(1);
    node.appendChild(new Node(this.setValueEmphasis(ra, emphasis0)));
    node.appendChild(new Node(this.setValueEmphasis(rb, emphasis1)));
  }

  private setOperationEmphasisN(node:Node, emphasis:number):void{
    let i:number;

    const r:WRational[] = [];
    for(i = 1 ; i < node.numChildren ; i++){
      r.push(this.setValueEmphasis(<WRational>node.childs[i].value , emphasis));
    }

    node.clear(1);

    for(i = 0 ; i < r.length ; i++){
      node.appendChild(new Node(r[i]));
    }
  }

  private setNodeValueEmphasis(node:Node, emphasis:number):void{
    node.value = this.setValueEmphasis(<WRational>node.value , emphasis);
  }

  private setValueEmphasis(value:WRational, emphasis:number):WRational{
    return new WRational(
      value.numerator,
      value.denominator,
      (<FractionFormatter>value.formatter ).setEmphasis(emphasis));
  }

  private getString(key:string, culture:CultureInfo):string{
    return XString.vouvoyiser(culture.getString(`FractionsTutor.${key}`), culture.configuration.vouvoyer);
  }

  private normalizeNegative(valueArg:WRational):WRational{
    let value = valueArg;
    if(value.denominator < 0){
      value = new WRational(
        value.numerator * -1,
        value.denominator * -1,
        value.formatter);
    }
    return value;
  }

  private changeDenominator(
      value:WRational,
      n:number):WRational{

    return new WRational(
      n / value.denominator * value.numerator,
      n,
      value.formatter);
  }

  private addInt(value:WRational, i:number):WRational{
    return new WRational(
      value.numerator + i * value.denominator,
      value.denominator,
      value.formatter);
  }

  private improper(value:WRational):WRational{
    return <WRational>value.applyFormat(FractionFormatter.getImproperNotation(value.formatter.culture)) ;
  }

  private breakMixed(value:WRational):WRational{
    return <WRational>value.applyFormat(FractionFormatter.getMixedNotation(value.formatter.culture).setExpand(true)) ;
  }

}
