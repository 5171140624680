import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { NumberWordsFormatter } from '../../elements/formats/numbers/NumberWordsFormatter';
import { WNumber } from '../../elements/tokens/WNumber';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Nombre en lettre.
 */
export class FormatNumberWords extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 1){
      return args.expectingArguments(1, 1);
    }

    const n:RealElement = args.getReal(0);
    if(!n){
      return null;
    }

    return new WNumber(n.toNumber(), 1, false, new NumberWordsFormatter(args.env.culture));
  }

}
