import React from 'react';
import styled from 'styled-components';
import { IKeyboardSection } from './Keyboard.models';
import { CustomKeypad } from './CustomKeypad';

export interface IMainKeypad {
  readonly sections: ReadonlyArray<IKeyboardSection>;
  readonly onKeyPress: (key: string) => void;
}

export class MainKeypad extends React.PureComponent<IMainKeypad, {}> {

  public render(): JSX.Element {
    const {
      sections,
      onKeyPress,
    } = this.props;

    const CustomSections = sections.map((section, index) => {
      return (
        <CustomKeypad
          key={`section_${index}`}
          rows={section.rows}
          onKeyPress={onKeyPress}
        />
      );
    });

    return (
      <Container>
        {CustomSections}
      </Container>
    );
  }
}

const Container = styled.div`
  background-color: white;
  border-top-left-radius: 12px;
  display: flex;
  flex-grow: 1;
  flex-basis: 0%;
  flex-direction: row;
`;
