import React from 'react';
import { useVirtual } from 'react-virtual';
import styled from 'styled-components';
import { ContentTagListItem } from './ContentTagListItem';
import { IContentTag } from '../../../../models/IContentTag';
import { ListHeaders } from './ListHeaders';

interface IContentTagListProps {
  contentTagList: ReadonlyArray<IContentTag>;
  selectedContentTagIds?: ReadonlyArray<number>;
  onChangeNameFr?: (contentTag: IContentTag, newName: string) => void;
  onChangeDescription?: (contentTag: IContentTag, newDescription: string) => void;
  onChangeSubject?: (contentTag: IContentTag, newSubject: number) => void;
  onDelete?: (contentTag: IContentTag) => void;
  onStartMerge?: () => void;
  onCheck?: (contentTag: IContentTag, isChecked: boolean) => void;
  onRemove?: (contentTag: IContentTag) => void;
}

export const CONTENT_TAG_LIST_TEST_ID = 'CONTENT_TAG_LIST_TEST_ID';

export const ContentTagList: React.FC<IContentTagListProps> = (props) => {
  const {
    contentTagList,
    selectedContentTagIds,
    onChangeDescription,
    onDelete,
    onStartMerge,
    onChangeNameFr,
    onChangeSubject,
    onCheck,
    onRemove,
  } = props;
  const parentRef = React.useRef<HTMLDivElement>(null);
  const rowVirtualizer = useVirtual({
    parentRef,
    size: contentTagList.length,
  });

  const rowRenderer = (index: number) => {
    const contentTag = contentTagList[index];
    const isChecked = selectedContentTagIds && selectedContentTagIds.includes(contentTag.id);
    return (
      <ContentTagListItem
        key={contentTag.id}
        contentTag={contentTag}
        isChecked={isChecked}
        onChangeNameFr={onChangeNameFr}
        onChangeDescription={onChangeDescription}
        onChangeSubject={onChangeSubject}
        onDelete={onDelete}
        onStartMerge={onStartMerge}
        onCheck={onCheck}
        onRemove={onRemove}
      />
    );
  };

  return (
    <Container>
      <ListHeaders
        hasCheckbox={Boolean(onCheck)}
      />
      <ListItemsContainer
        ref={parentRef}
      >
        <div
          style={{
            height: rowVirtualizer.totalSize,
            width: '100%',
            position: 'relative',
          }}
          data-testid={CONTENT_TAG_LIST_TEST_ID}
        >
          {rowVirtualizer.virtualItems.map((virtualRow) => (
            <div
              key={virtualRow.index}
              ref={virtualRow.measureRef}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                transform: `translateY(${virtualRow.start}px)`,
                zIndex: contentTagList.length - virtualRow.index,
              }}
            >
              {rowRenderer(virtualRow.index)}
            </div>
          ))}
        </div>
      </ListItemsContainer>
    </Container>
  );
};

const Container = styled.div`
  flex: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const ListItemsContainer = styled.div`
  flex: 1;
  min-height: 0;
  overflow: overlay;
  margin-right: -16px;
  padding-right: 16px;
`;
