import React from 'react';
import { ICustomRenderElementProps } from '../../models';
import { IParagraphElement } from '../../models/elements';
import { INDENT_SPACING } from '../../constants/style';

export const ParagraphElement: React.FC<ICustomRenderElementProps<IParagraphElement>> = (props) => {
  const { attributes, children, element } = props;

  const style = {
    marginLeft: element.indent ? `${element.indent * INDENT_SPACING}px` : null,
    textAlign: element.textAlign ? element.textAlign : null,
  };

  return (
    <p
      className={element.className ? element.className : null}
      style={style}
      {...attributes}
    >
      {children}
    </p>
  );
};
