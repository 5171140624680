import { XString } from '../../core/XString';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { WString } from '../../elements/tokens/WString';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';
import { TextExporter } from '../../expr/conversion/output/TextExporter';

/**
 * Convertit les lignes d'une matrice en une liste de chaînes en
 * appliquant un modèle de format à chaque ligne.
 */
export class StringifyRows extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 2){
      return args.expectingArguments(2, 2);
    }

    const matrix:WMatrix = args.getMatrix(0);
    if(!matrix){
      return null;
    }

    if(matrix.count === 0){
      return new WString('');
    }

    const format:WString = args.getString(1);
    if(!format){
      return null;
    }

    return args.env.culture.listFactory.createList(this.stringify(matrix, format, args.env));
  }

  /**
   *
   */
  private stringify(
      value:WMatrix,
      format:WString,
      env:Environment):ContentElement[]{

    const o:ContentElement[] = [];

    for(let r:number = 0 ; r < value.rows ; r++){
      const args:any[] = [];
      for(let c:number = 0 ; c < value.columns ; c++){
        const arg:string = TextExporter.toString(value.valueAt(r, c), env.format);
        if(!arg){
          return null;
        }
        args.push(arg);
      }
      args.unshift(format.getString());
      o.push(new WString(XString.substitute.apply(null, args), null, format.getSource()));
    }

    return o;
  }

}
