import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { Mixer } from '../../elements/utils/Mixer';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Mélanger les colonnes.
 */
export class MixColumns extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 1){
      return args.expectingArguments(1, 1);
    }
    if(args.getMatrix(0)){
      return this.mix(args.getMatrix(0), new Mixer(args.prng));
    }
    return null;
  }

  /**
   *
   */
  private mix(value:WMatrix, mixer:Mixer):WMatrix{
    const columns:number[] = mixer.mix(value.columns);
    const values:RealElement[] = [];
    for(let i:number = 0 ; i < value.rows * value.columns ; i++){
      const r:number = Math.floor(i / value.columns);
      const c:number = i % value.columns;
      values.push(value.valueAt(r, columns[c]));
    }
    return new WMatrix(values, value.columns, value.formatter);
  }

}
