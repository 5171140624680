import { ContentElement } from '../../../../elements/abstract/ContentElement';
import { FunctionElement } from '../../../../elements/abstract/FunctionElement';
import { WBoolean } from '../../../../elements/tokens/WBoolean';
import { WPolygon } from '../../../../elements/tokens/WPolygon';
import { WPolyline } from '../../../../elements/tokens/WPolyline';
import { SegmentsUtil } from '../../../../elements/utils/SegmentsUtil';
import { ArgumentsObject } from '../../../../expr/ArgumentsObject';

/**
 * Est croisé?
 */
export class IsCrossed extends FunctionElement {

  public callReturnElement(args:ArgumentsObject):ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }

    if(args.getPolygon(0) ){
      return this.polygon(args.getPolygon(0));
    }
    if(args.getPolyline(0) ){
      return this.path(args.getPolyline(0));
    }
    return null;
  }

  /**
   *
   */
  private path(value:WPolyline):WBoolean{
    return WBoolean.parse(SegmentsUtil.selfIntersects(value.vertices, false));
  }

  /**
   *
   */
  private polygon(value:WPolygon):WBoolean{
    return WBoolean.parse(value.isCrossed);
  }
}
