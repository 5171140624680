import { AbstractStep } from '../AbstractStep';
import { Compartment } from '../Compartment';
import { LongSubtractionOperation } from '../LongSubtractionOperation';

/**
 *
 */
export class SubtractCrossout extends AbstractStep {

  /**
   * Value at the column we are borrowing from before the borrowing.
   */
  private v1:number;

  constructor(operation:LongSubtractionOperation) {

    super(operation);
    let o0:Compartment;
    let o1:Compartment;
    let v1:number;
    let p:number = operation.position;
    do{
      p++; // look left
      o0 = operation.operandAt(p, -1);
      o1 = operation.operandAt(p, 0);
      v1 = (o0 ? o0.value : (o1 ? o1.value : 0));
    }while(v1 === 0);

    let c:Compartment;
    const index:number = operation.columnIndex(p);

    // crossout
    c = Compartment.createCrossout();
    if(operation.crossouts[0][index] == null){
      operation.crossouts[0][index] = c;
    }else{
      operation.crossouts[1][index] = c;
    }
    this.source.push(c);

    // rewrite digit
    c = Compartment.createRegroup(v1 - 1);
    if(operation.regroups[0][index] == null){
      operation.regroups[0][index] = c;
    }else{
      operation.regroups[1][index] = c;
    }
    this.target.push(c);

    operation.borrowPosition = p;

    this.v1 = v1;

    this.setDescription(operation.culture.getString('LongSubtractionOperation.crossoutLabel', v1, v1 - 1));
  }

}
