import { MathError } from '../../core/MathError';
import { AnonymousFunction } from '../../elements/abstract/AnonymousFunction';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WList } from '../../elements/tokens/WList';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';
import { FunctionCompiler } from '../../expr/manipulation/optimized/FunctionCompiler';
import { WListOfList } from '../../elements/tokens/WListOfList';
import { WNumber } from '../../elements/tokens/WNumber';
import { ListOfListElement } from '../../elements/abstract/ListOfListElement';

type Callback1 = (number: number) => ContentElement;
type Callback2 = (number1: number, number2: number) => ContentElement;

/**
 * NOTE: This class is not intended for production,
 * but only for testing fast evaluation feature.
 */
export class FastMap extends FunctionElement {
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 2 && args.length !== 3) {
      return args.expectingArguments(2, 3);
    }

    const mapFunction = args.getAnonymousFunction(args.length - 1);
    if (!mapFunction) {
      return null;
    }

    if (args.length === 2) {
      const reals = args.getReals(0);
      if (reals) {
        return this.list1(reals, mapFunction, args.env);
      }

      const listOfList = args.getListOfList(0);
      if (listOfList instanceof WListOfList) {
        return this.listOfList1(listOfList, mapFunction, args.env);
      }
    }

    if (args.length === 3) {
      const listOfLists: Array<ListOfListElement | WListOfList> = [args.getListOfList(0), args.getListOfList(1)];
      if (listOfLists[0] instanceof WListOfList && listOfLists[1] instanceof WListOfList) {
        return this.listOfList2(listOfLists[0], listOfLists[1], mapFunction, args.env);
      }
    }

    return null;
  }

  private list1(list: WList, mapFunction: AnonymousFunction, env: Environment): WList {
    const eval1 = this.compile(mapFunction, FunctionCompiler.compileOneArgumentFunction);
    const callback = (number: number) => env.culture.createNumber(eval1.eval1(number));
    return new WList(this.mapItems1(list.items, callback), list.formatter);
  }

  private listOfList1(list: WListOfList, mapFunction: AnonymousFunction, env: Environment): WListOfList {
    const eval1 = this.compile(mapFunction, FunctionCompiler.compileOneArgumentFunction);
    const callback = (number: number) => env.culture.createNumber(eval1.eval1(number));
    return new WListOfList(this.mapItems1(list.items, callback), list.formatter);
  }

  private listOfList2(list1: WListOfList, list2: WListOfList, mapFunction: AnonymousFunction, env: Environment): WListOfList {
    const eval2 = this.compile(mapFunction, FunctionCompiler.compileTwoArgumentsFunction);
    const callback = (number1: number, number2: number) => env.culture.createNumber(eval2.eval2(number1, number2));
    return new WListOfList(this.mapItems2(list1.items, list2.items, callback), list1.formatter);
  }

  private mapItems1(items: ContentElement[], callback: Callback1): ContentElement[] {
    const elements: ContentElement[] = [];
    for (const item of items) {
      if (item instanceof WList) {
        elements.push(new WList(this.mapItems1(item.items, callback), item.formatter));
      } else if (item instanceof WListOfList) {
        elements.push(new WListOfList(this.mapItems1(item.items, callback), item.formatter));
      } else if (item instanceof WNumber) {
        elements.push(callback(item.toNumber()));
      }
    }
    return elements;
  }

  private mapItems2(items1: ContentElement[], items2: ContentElement[], callback: Callback2): ContentElement[] {
    const elements: ContentElement[] = [];
    for (let i = 0; i < items1.length; i++) {
      const [item1, item2] = [items1[i], items2[i]];
      if (item1 instanceof WList && item2 instanceof WList) {
        elements.push(new WList(this.mapItems2(item1.items, item2.items, callback), item1.formatter));
      } else if (item1 instanceof WListOfList && item2 instanceof WListOfList) {
        elements.push(new WListOfList(this.mapItems2(item1.items, item2.items, callback), item1.formatter));
      } else if (item1 instanceof WNumber && item2 instanceof WNumber) {
        elements.push(callback(item1.toNumber(), item2.toNumber()));
      }
    }
    return elements;
  }

  private compile<T extends (f: AnonymousFunction) => any>(mapFunction: AnonymousFunction, compiler: T): ReturnType<T> {
    const evaluator = compiler(mapFunction);
    if (!evaluator) {
      throw new MathError('Not supported');
    }
    return evaluator;
  }
}
