import { MmlWriter } from '../../core/mml/MmlWriter';
import { ListElement } from '../../elements/abstract/ListElement';
import { Node } from '../../elements/abstract/Node';
import { TokenElement } from '../../elements/abstract/TokenElement';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';
import { ContentElement } from '../abstract/ContentElement';

/**
 * Tableau.
 */
export class WTable extends TokenElement {

  private _columns:ListElement[];

  public get columns():ListElement[]{return this._columns;}

  private _header:ListElement;

  public get header():ListElement{return this._header;}

  private _vheader:ListElement;

  public get vheader():ListElement{return this._vheader;}

  private _footer:ListElement;

  public get footer():ListElement{return this._footer;}

  private _vfooter:ListElement;

  public get vfooter():ListElement{return this._vfooter;}

  private _frame:string;

  public get frame():string{return this._frame;}

  private _rowlines:string[];

  public get rowlines():string[]{return this._rowlines;}

  private _columnlines:string[];

  public get columnlines():string[]{return this._columnlines;}

  private _equalrows:boolean;

  public get equalrows():boolean{return this._equalrows;}

  private _equalcolumns:boolean;

  public get equalcolumns():boolean{return this._equalcolumns;}

  constructor(
      columns:ListElement[],
      header:ListElement = null,
      vheader:ListElement = null,
      footer:ListElement = null,
      vfooter:ListElement = null,
      frame:string = null,
      rowlines:string[] = null,
      columnlines:string[] = null,
      equalrows:boolean = false,
      equalcolumns:boolean = false){
    super();
    this._columns = columns;
    this._header = header;
    this._vheader = vheader;
    this._footer = footer;
    this._vfooter = vfooter;
    this._frame = frame;
    this._rowlines = rowlines;
    this._columnlines = columnlines;
    this._equalrows = equalrows;
    this._equalcolumns = equalcolumns;
  }

  public writeTo(exporter:IMarkupExporter = null):boolean{
    if(exporter){
      const w:MmlWriter = exporter.writer;
      w.beginTable();

      // 1. Set attributes
      if(this.frame){
        w.frame = this.frame;
      }
      if(this.rowlines){
        w.rowlines = this.rowlines.join(' ');
      }
      if(this.columnlines){
        w.columnlines = this.columnlines.join(' ');
      }
      if(this.equalrows){
        w.equalrows = this.equalrows;
      }
      if(this.equalcolumns){
        w.equalcolumns = this.equalcolumns;
      }

      let k:number;
      let col:ListElement;
      let rows:number = 0;
      const cols:number = this.columns.length;

      // 2. Compute number of rows
      for(k = 0 ; k < this.columns.length ; k++){
        rows = Math.max(rows, this.columns[k].count);
      }

      // 3. Top header
      if(this.header){
        w.beginTr();
        if(this.vheader){
          //  Empty corner
          w.beginTd();
          w.endTd();
        }
        for(k = 0 ; k < this.header.count ; k++){
          w.beginTd();
          exporter.writeNode(new Node(this.header.getItemAt(k)));
          w.endTd();
        }
        w.endTr();
      }

      // 4. Create rows and columns for the table
      for(let r:number = 0 ; r < rows ; r++){
        w.beginTr();

        if(this.vheader){
          w.beginTd();
          if(r < this.vheader.count){
            exporter.writeNode(new Node(this.vheader.getItemAt(r)));
          }
          w.endTd();
        }

        for(let c:number = 0 ; c < cols ; c++){
          w.beginTd();
          col = this.columns[c];
          if(r < col.count){
            exporter.writeNode(new Node(col.getItemAt(r)));
          }
          w.endTd();
        }

        if(this.vfooter){
          w.beginTd();
          if(r < this.vfooter.count){
            exporter.writeNode(new Node(this.vfooter.getItemAt(r)));
          }
          w.endTd();
        }

        w.endTr();
      }

      // 5. Bottom footer
      if(this.footer){
        w.beginTr();
        if(this.vheader){
          //  Empty corner
          w.beginTd();
          w.endTd();
        }
        for(k = 0 ; k < this.footer.count ; k++){
          w.beginTd();
          exporter.writeNode(new Node(this.footer.getItemAt(k)));
          w.endTd();
        }
        w.endTr();
      }

      w.endTable();
    }
    return true;
  }

  public getType():string {
    return 'table';
  }

  public equalsTo(value:ContentElement):boolean{
    if(!(value instanceof WTable)){
      return false;
    }

    const table:WTable = <WTable>value;

    if (this.columns.length !== table.columns.length ||
      !this.columns.every((column, index) => column.equalsTo(table.columns[index])) ||
      !areListEqual(this.header, table.header) ||
      !areListEqual(this.vheader, table.vheader) ||
      !areListEqual(this.footer, table.footer) ||
      !areListEqual(this.vfooter, table.vfooter) ||
      this.frame !== table.frame ||
      !areStringListsEqual(this.rowlines,  table.rowlines) ||
      !areStringListsEqual(this.columnlines, table.columnlines) ||
      this.equalrows !== table.equalrows ||
      this.equalcolumns !== table.equalcolumns) {
      return false;
    }

    return true;
  }
}

const areListEqual = (a:ListElement | null, b:ListElement | null):boolean => {
  if(a === null && b === null){
    return true;
  }
  if(a === null || b === null){
    return false;
  }
  return a.equalsTo(b);
};

const areStringListsEqual = (a:string[] | null, b:string[] | null):boolean => {
  if(a === null && b === null){
    return true;
  }
  if(a === null || b === null){
    return false;
  }
  return a.every((value, index) => value === b[index]);
};
