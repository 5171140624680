import { XStatistics } from '../../core/XStatistics';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WList } from '../../elements/tokens/WList';
import { WRange } from '../../elements/tokens/WRange';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';

/**
 * Compatibility
 * Returns random list that contains it's own arithmetic mean.
 */
export class RandomListWMean extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 2){
      return args.expectingArguments(2, 2);
    }

    if (args.getRange(0) && args.getReal(1)) {
      return this.rand(args.getRange(0), args.getReal(1), args.env);
    }
    return null;
  }

  /**
   *
   */
  private rand(range:WRange, length:RealElement, env:Environment):WList{
    if(!length.isNaturalNumber()){
      return null;
    }

    const middle:number = Math.floor(range.getCardinal() / 2);
    const target:number = range.valueAt(middle);

    const o:number[] = [];
    o.push(target);

    let i:number;
    let j:number;
    const u:number[] = [];
    for(i = 0 ; i < length.toNumber() - 1 ; i++){
      u.push(env.prng.randomInt(1, middle) * (i % 2 === 0 ? 1 : -1));
    }

    while(XStatistics.sum(u) < 0){
      for(j = 0 ; j < u.length ; j++){
        if(u[j] < 0 && env.prng.random() > 0.5){
          u[j]++;
          break;
        }
      }
    }

    while(XStatistics.sum(u) > 0){
      for(j = 0 ; j < u.length ; j++){
        if(u[j] > 0 && env.prng.random() > 0.5){
          u[j]--;
          break;
        }
      }
    }

    for(i = 0 ; i < u.length ; i++){
      o.push(range.valueAt(middle + u[i]));
    }

    return env.culture.listFactory.createFromNumbers(o);
  }

}
