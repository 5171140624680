import { XString } from '../../../core/XString';
import { BaseNumberFormatter } from '../../../elements/formats/BaseNumberFormatter';
import { CultureInfo } from '../../../localization/CultureInfo';

/**
 *
 */
export class ShortCurrencyFormatter extends BaseNumberFormatter {

  /**
   *
   */
  constructor(culture:CultureInfo){
    super(culture);
  }

  /**
   *
   */
  public toLocaleString(valueArg:number):string{
    let value = valueArg;
    const negative:boolean = value < 0;
    value = Math.abs(value);
    value = Number(value.toFixed(this.culture.currency.precision));

    const dollars:number = Math.floor(value);
    const cents:number = Math.round(Math.abs(Math.floor(value) - value) * 100);

    const o:string[] = [];

    if(negative){
      o.push(this.culture.numberFormatter.negativeLabel);
    }

    if(dollars > 0){
      o.push(XString.substitute(
        this.culture.numberFormatter.currencyFormat,
        this.culture.numberFormatter.toLocaleString(dollars),
        this.culture.currency.unitSymbol));
    }

    if(cents > 0){
      if(dollars > 0){
        o.push(this.culture.numberFormatter.andLabel);
      }
      o.push(XString.substitute(
        this.culture.numberFormatter.centsFormat,
        this.culture.numberFormatter.toLocaleString(cents),
        this.culture.currency.fractionSymbol));
    }

    return o.join(' ');
  }

}
