/**
 * Specialises the native Response object in order to cast the response to a JSON object seamlessly.
 */
interface FetchResponse<T> extends Response {
  json(): Promise<T>;
}

/**
 * Represents an authenticated REST call to the Media API backend. This wrapper around the fetch() API adds type
 * casting.
 * There is automated error handling on the response object. All calls are expected to be JSON call that return
 * a structure, anything else will throw an error.
 * @param uri
 * @param init
 * @throws Error
 */
export const localizationRules = async <T = Record<string, any>> (uri: string, init: RequestInit = {}): Promise<FetchResponse<T>> => {
  const headers = new Headers(init.headers || {});
  if (!headers.has('Access-Control-Max-Age')) {
    headers.set('Access-Control-Max-Age', '3600');
  }
  if (!headers.has('origin')) {
    headers.set('origin', 'http://localhost/');
  }

  return remoteCall<T>(`${ScolabConfiguration.LOCALIZATION_RULES_API}${uri}`, init);
};

const remoteCall = async <T> (url: string, configuration: RequestInit): Promise<Response> => {
  const response = await fetch(url, configuration) as FetchResponse<T>;
  await validateResponse(response);
  return response;
};

const validateResponse = async (response: Response) => {
  if (!response.ok) {
    throw new Error(`statusNotOk - ${response.statusText}`);
  }

  if (!response.body) {
    throw new Error('Response empty');
  }
};
