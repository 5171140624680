import { AnonymousFunction } from '../../elements/abstract/AnonymousFunction';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { ListFactory } from '../../elements/factories/ListFactory';
import { WBoolean } from '../../elements/tokens/WBoolean';
import { WList } from '../../elements/tokens/WList';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Evaluate } from '../../expr/manipulation/Evaluate';

/**
 * Génère une liste de nombres selon une règle et une condition d'arrêt.
 */
export class NestWhile extends FunctionElement {

  /**
   *
   */
  private static MAX_CAPACITY:number = 1000;

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length < 3 || args.length > 4){
      return args.expectingArguments(3, 4);
    }

    const nestFunction:AnonymousFunction = args.getAnonymousFunction(0);
    const seed:RealElement = args.getReal(1);
    const testFunction:AnonymousFunction = args.getAnonymousFunction(2);

    let count:number = NestWhile.MAX_CAPACITY;
    if(args.length > 3){
      if(args.getWholeNumber(3)){
        count = args.getWholeNumber(3).toNumber();
      }else{
        return null;
      }
    }

    if(nestFunction && seed && testFunction && count){
      return this.nestWhileImpl(
        new Evaluate(nestFunction, args.env),
        seed,
        new Evaluate(testFunction, args.env),
        count,
        args.env.culture.listFactory);
    }

    return null;
  }

  /**
   *
   */
  private nestWhileImpl(
      nestEvaluator:Evaluate,
      valueArg:RealElement,
      testEvaluator:Evaluate,
      capacity:number,
      listFactory:ListFactory):WList{

    let value = valueArg;
    const r:ContentElement[] = [];
    let c:number = 0;
    let b:WBoolean;
    do{
      r.push(value);
      b = WBoolean.parseElement(testEvaluator.evaluate1(listFactory.createList(r)));
      if(!b){
        return null;
      }
      value = RealElement.parseElement(nestEvaluator.evaluate1(value));
      if(!value){
        return null;
      }
      c++;
      if(c >= capacity){
        break;
      }
    }while(b.toBoolean());

    return <WList>listFactory.createList(r) ;
  }

}
