import { IExtensionsMethods } from '../expr/IExtensionsMethods';

/**
 *
 */
export class NoExtensions implements IExtensionsMethods {

  /**
   *
   */
  public get latexEnabled():boolean{
    return false;
  }

  /**
   *
   */
  public latexToMarkup(latex:string):string{
    throw new Error();
  }

  /**
   *
   * @param name
   */
  public getEnvVariable(name:string): string{
    return null;
  }

}
