import { createKey, NoopKey, DismissKey } from '../../Keyboards';
import { IKey, IKeyboard, IKeyboardRow, IKeyboardSection } from '../../../keyboard';

const NB_ROWS = 5;

const charactersToSection = (characters: string) => {
  const nbColumns = Math.ceil( characters.length / NB_ROWS);
  const rows: IKeyboardRow[] = [];
  for (let i = 0; i < NB_ROWS; i += 1) {
    const keys: IKey[] = [];
    for (let j = 0; j < nbColumns; j += 1) {
      const charIndex = (i * nbColumns) + j;
      const character = characters[charIndex];
      keys.push(character ? createKey(character) : NoopKey);
    }
    rows.push({
      keys,
    });
  }
  const section: IKeyboardSection = {
    rows,
  };
  return section;
};

export const keyboardLayout: IKeyboard = {
  sections: [
    charactersToSection('×±÷−⋅∘≤≥≠≈∼≙∪∩∖∆∈∉∅⊆⊂≟⊥ℕℤℚℝ∀∃~≅∞∟∠∥∦|∧∨←→⇔⬚Δ'),
    charactersToSection('ôêûâî•▪◦ᵉʳ°$¢€αβγδεηθλμνπρφψΩΣ'),
  ],
  actionSection: {
    rows: [
      {
        keys: [
          DismissKey,
        ],
      },
    ],
  },
};
