import { Constants } from '../../core/Constants';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { XRound } from '../../core/XRound';

/**
 *
 */
export class Degrees extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }

    const radianToDegrees = (d: number) => XRound.safeRound2(d * Constants.RAG_TO_DEG);

    if(args.getReal(0)) {
      return args.env.culture.createNumber(radianToDegrees(args.getReal(0).toNumber()));
    }
    if(args.getConstant(0)){
      return args.env.culture.createNumber(radianToDegrees(args.getConstant(0).toNumber()));
    }
    if(args.getNaN(0)){
      return args.getNaN(0);
    }
    return null;
  }

}
