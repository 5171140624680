import React from 'react';
import styled from 'styled-components';
import { preventDefault } from '../../utils/eventUtils';
import { ListStyleTypes } from '../../models/elements';
import { Select } from '../../../Select/Select';
import { SelectItem } from '../../../Select/SelectItem';
import { Button } from '../../../Button';

export interface IListItem {
  Icon: React.ReactNode;
  value: ListStyleTypes;
}

export interface IListControlProps {
  Icon: JSX.Element;
  isActive: boolean;
  value: ListStyleTypes;
  onClickIcon: (event: React.MouseEvent) => void;
  items: ReadonlyArray<IListItem>;
  onClickItem: (listStyleType: ListStyleTypes) => void;
}

export const ListControl: React.FC<IListControlProps> = (props) => {
  const {
    Icon,
    isActive,
    onClickIcon,
    items,
    onClickItem,
    value,
  } = props;
  const onMainIconClick = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    onClickIcon(event);
  };
  const MainIcon = (
    <Button
      active={isActive}
      onMouseDown={preventDefault}
      onClick={onMainIconClick}
      icon
    >
      {Icon}
    </Button>
  );
  return (
    <Select
      SelectedComponent={MainIcon}
      value={value}
      onChange={onClickItem}
    >
      <ItemsContainer>
        {items.map((item) => {
          return (
            <StyledSelectItem
              key={item.value}
              value={item.value}
              onMouseDown={preventDefault}
            >
              {item.Icon}
            </StyledSelectItem>
          );
        })}
      </ItemsContainer>
    </Select>
  );
};

const StyledSelectItem = styled(SelectItem)`
  padding: 2px 8px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
`;

const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
