import { AbstractStep } from '../../AbstractStep';
import { Compartment } from '../../Compartment';
import { FrameBorders } from '../../FrameBorders';
import { LongDivisionOperationEuclidienne } from '../../LongDivisionOperationEuclidienne';
import { DivisionModel } from '../../models/DivisionModel';
import { DivInit } from './DivInit';

/**
 *
 */
export class DivNormalize extends AbstractStep {

  private model:DivisionModel;

  private division:LongDivisionOperationEuclidienne;

  constructor(division:LongDivisionOperationEuclidienne) {

    super(division);
    this.model = division.model;
    this.division = division;

    let i:number;
    let c:Compartment;

    let chars:any[] = String(this.model.originalDividend).split('');
    for(i = 0 ; i < chars.length ; i++){
      c = chars[i] === '.' ? division.decimalSeparator() : Compartment.createDigit(Number(chars[i]));
      division.rawDividend.push(c);
      this.target.push(c);
    }

    chars = String(this.model.originalDivisor).split('');
    for(i = 0 ; i < chars.length ; i++){
      c = chars[i] === '.' ? division.decimalSeparator() : Compartment.createDigit(Number(chars[i]));
      c.frame = new FrameBorders();
      c.frame.bottom = true;
      c.frame.left = i === 0;
      division.rawDivisor.push(c);
      this.target.push(c);
    }

    this.setDescription(
      division.culture.getString(
        'LongDivisionEuclidienne.initLabel',
        division.culture.formatNumber(this.model.originalDividend),
        division.culture.formatNumber(this.model.originalDivisor),
        division.culture.formatNumber(this.model.normalizedDividend),
        division.culture.formatNumber(this.model.normalizedDivisor)));
  }

  public next():AbstractStep {
    return new DivInit(
      this.division) ;
  }
}
