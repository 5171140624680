
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { StandardQuadraticFormatter } from '../../elements/formats/quad/StandardQuadraticFormatter';
import { WListOfPoints } from '../../elements/tokens/WListOfPoints';
import { WPoint } from '../../elements/tokens/WPoint';
import { WQuadratic } from '../../elements/tokens/WQuadratic';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';

/**
 *
 */
export class Quadratic extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length < 1 || args.length > 3){
      return args.expectingArguments(1, 3);
    }

    if(args.length === 1){
      if(args.getQuadraticOrPoly(0)){
        return args.getQuadraticOrPoly(0);
      }
      if(args.getPoints(0)){
        const points:WListOfPoints = args.getPoints(0);
        if(points.count === 2){
          return this.createFromVertexAndPoint(
            points.getTypedItemAt(0),
            points.getTypedItemAt(1),
            args.env);
        }
        if(points.count === 3){
          return this.createFromThreePoints(
            points.getTypedItemAt(0),
            points.getTypedItemAt(1),
            points.getTypedItemAt(2),
            args.env);
        }
        return null;
      }
    }else if(args.length === 2){
      if(args.getPoint(0) && args.getPoint(1)){
        return this.createFromVertexAndPoint(
          args.getPoint(0),
          args.getPoint(1),
          args.env);
      }
    }else if(args.length === 3){
      if(args.getPoint(0) && args.getPoint(1) && args.getPoint(2)){
        return this.createFromThreePoints(
          args.getPoint(0),
          args.getPoint(1),
          args.getPoint(2),
          args.env);
      }
    }

    return null;
  }

  /**
   *
   */
  private createFromVertexAndPoint(vertex:WPoint, p1:WPoint, env:Environment):WQuadratic{
    const vx:number = vertex.x.toNumber();
    const vy:number = vertex.y.toNumber();
    const px:number = p1.x.toNumber();
    const py:number = p1.y.toNumber();

    if(vx === px){return null;} // vertical line
    if(vy === py){return null;} // horizontal line
    const aa:number = (py - vy) / ((px - vx) * (px - vx));
    return WQuadratic.parsePolynomial(
      env.expressions.toPolynomial(`${aa}*(x-${vx})^2+${vy}`),
      new StandardQuadraticFormatter(env.culture),
      vertex,
      p1);
  }

  /**
   *
   */
  private createFromThreePoints(p1:WPoint, p2:WPoint, p3:WPoint, env:Environment):WQuadratic{
    return WQuadratic.parsePoints(p1, p2, p3, new StandardQuadraticFormatter(env.culture));
  }

}
