import { IPageContent } from '@scolab/content-model';
import { ClientApplicationModel } from '../../models/ClientApplicationModel';
import { api } from '../../utils/fetch';

export interface IPageContentGetContentResponse {
  pageGuid: string;
  pageLock: {
    pageGuid: string,
    status: 'acquired' | 'failed' | 'requireMigration',
    lockedBy: string,
  };
  pageVersion: {
    copyType: 'publishedCopy' | 'draftCopy',
    version: number | null,
    dateModified: string,
    contentFormat: 'json'
  };
  pageContent: IPageContent;
}

export const pageContentGetPageContentLatestCopy = async (pageGuid: string,
                                                          clientApplication: ClientApplicationModel): Promise<IPageContentGetContentResponse> => {
  const response = await api<IPageContentGetContentResponse>(`/Publisher/PageContent/GetPageContent?pageGuid=${pageGuid}&${clientApplication.params}`);
  return response.json();
};

export const pageContentGetPageContentVersion = async (pageGuid: string,
                                                       copyType: 'publishedCopy' | 'draftCopy',
                                                       version: number | null,
                                                       clientApplication: ClientApplicationModel): Promise<IPageContentGetContentResponse> => {
  const response = await api<IPageContentGetContentResponse>(`/Publisher/PageContent/GetPageContent?pageGuid=${pageGuid}&copyType=${copyType}&version=${version}&${clientApplication.params}`);
  return response.json();
};
