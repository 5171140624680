import { ClientApplicationModel } from '../../models/ClientApplicationModel';
import { IContentTag } from '../../models/IContentTag';
import { api } from '../../utils/fetch';

export const updateContentTag = async (contentTag: IContentTag) => {
  const clientApplication = ClientApplicationModel.getInstance();

  const data = {
    subjectId: contentTag.subjectId,
    name: contentTag.name,
    synonyms: {
      fr: '',
    },
    notes: contentTag.description,
  };

  const response = await api(`/Publisher/ContentTags/Update?contentTagId=${contentTag.id}&${clientApplication.params}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data),
  });
  return response.ok;
};
