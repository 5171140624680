import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WString } from '../../elements/tokens/WString';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class Alphabet extends FunctionElement {

  /**
   *
   */
  private cache:ContentElement[];

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 0){
      return args.expectingArguments(0, 0);
    }

    if(!this.cache){
      this.cache = [];
      for(let i:number = 0 ; i < 26 ; i++){
        this.cache.push(new WString(String.fromCharCode(65 + i)));
      }
    }

    return args.env.culture.listFactory.createList(this.cache);
  }

}
