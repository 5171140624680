import { IPrng } from '../../core/prng/IPrng';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { ListElement } from '../../elements/abstract/ListElement';
import { WString } from '../../elements/tokens/WString';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Mélange les éléments d'une liste.
 */
export class Mix extends FunctionElement {
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length === 0) {
      return args.expectingArguments(1, Number.POSITIVE_INFINITY);
    }

    if (args.length === 1) {
      const string = args.getString(0);
      if (string) {
        return this.string(string, args.prng);
      }

      const list = args.getList(0);
      if (list) {
        return this.list(list, args.prng);
      }
    }

    const listOfList = args.toListOfList();
    if (listOfList) {
      return this.list(listOfList, args.prng);
    }

    return null;
  }

  private string(value: WString, prng: IPrng): WString {
    return new WString(this.mix(value.getString().split(''), prng).join(''), null, value.getSource());
  }

  private list(list: ListElement, prng: IPrng): ListElement {
    let indices = this.indices(list.count);
    indices = this.mix(indices, prng);
    return list.transform(indices);
  }

  private mix<T>(items: T[], prng: IPrng): T[] {
    const mixed: T[] = [];
    const hat = this.indices(items.length);
    while (hat.length > 0) {
      const index = prng.randomIndex(hat.length);
      mixed.push(items[hat[index]]);
      hat.splice(index, 1);
    }
    return mixed;
  }

  private indices(length: number): number[] {
    return Array.from({ length }, (_, i) => i);
  }
}
