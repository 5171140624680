import React from 'react';

export interface ISortableListContext {
  readonly onDragStart: (index: number) => void;
  readonly onDragEnd: () => void;
  readonly onChangeDropTarget: (targetIndex: number) => void;
  readonly onSelectItem: (itemIndex: number, event: React.MouseEvent) => void;
  readonly afterSelectItem: (itemIndex: number, event: React.MouseEvent) => void;
}

const defaultValue: ISortableListContext = {
  onDragStart: () => undefined,
  onDragEnd: () => undefined,
  onChangeDropTarget: () => undefined,
  onSelectItem: () => undefined,
  afterSelectItem: () => undefined,
};

export const SortableListContext = React.createContext(defaultValue);
