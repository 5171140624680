import { XObject } from '../XObject';
import { MElement } from './MElement';
import { IMmlProxy } from './IMmlProxy';

/**
 * Forward-only MathML writer.
 */
export class MmlWriter {

  private proxy:IMmlProxy;

  private root:any;

  private group:any;

  private token:any;

  private commas:any[] = []; /* of Boolean */

  private validation:boolean = true;

  constructor(proxy:IMmlProxy) {

    this.proxy = proxy;
  }

  public beginMath():void{
    if(this.root){
      throw new Error('math must be the root');
    }
    this.root = this.group = this.proxy.createNode('math');
  }

  public endMath():void{this.group = null; this.token = null;}

  public beginRow():void{this.beginGroup('mrow');}

  public endRow():void{this.endGroup('mrow');}

  public beginFraction():void{this.beginGroup('mfrac');}

  public endFraction():void{this.endGroup('mfrac');}

  public beginSqrt():void{this.beginGroup('msqrt');}

  public endSqrt():void{this.endGroup('msqrt');}

  public beginRoot():void{this.beginGroup('mroot');}

  public endRoot():void{this.endGroup('mroot');}

  public beginStyle():void{this.beginGroup('mstyle');}

  public endStyle():void{this.endGroup('mstyle');}

  public beginError():void{this.beginGroup('merror');}

  public endError():void{this.endGroup('merror');}

  public beginPadded():void{this.beginGroup('mpadded');}

  public endPadded():void{this.endGroup('mpadded');}

  public beginPhantom():void{this.beginGroup('mphantom');}

  public endPhantom():void{this.endGroup('mphantom');}

  public beginFenced(open:string = null, close:string = null):void{
    this.beginGroup('mfenced');
    if(open != null){
      this.open = open;
    }
    if(close != null){
      this.close = close;
    }
  }

  public endFenced():void{this.endGroup('mfenced');}

  public beginEnclose(notation:string):void{
    this.beginGroup('menclose');
    if(notation != null){
      this.notation = notation;
    }
  }

  public endEnclose():void{this.endGroup('menclose');}

  public beginSub():void{this.beginGroup('msub');}

  public endSub():void{this.endGroup('msub');}

  public beginSup():void{this.beginGroup('msup');}

  public endSup():void{this.endGroup('msup');}

  public beginSubSup():void{this.beginGroup('msubsup');}

  public endSubSup():void{this.endGroup('msubsup');}

  public beginUnder():void{this.beginGroup('munder');}

  public endUnder():void{this.endGroup('munder');}

  public beginOver():void{this.beginGroup('mover');}

  public endOver():void{this.endGroup('mover');}

  public beginUnderOver():void{this.beginGroup('munderover');}

  public endUnderOver():void{this.endGroup('munderover');}

  public beginTable():void{this.beginGroup('mtable');}

  public endTable():void{this.endGroup('mtable');}

  public beginAction():void{this.beginGroup('maction');}

  public endAction():void{this.endGroup('maction');}

  public beginTr():void{
    if(this.groupName !== 'mtable'){
      throw new Error('mtr must be placed into mtable');
    }
    this.beginGroup('mtr');
  }

  public endTr():void{this.endGroup('mtr');}

  public beginTd():void{
    if(this.groupName !== 'mtr'){
      throw new Error('mtd must be placed into mtr');
    }
    this.beginGroup('mtd');
  }

  public endTd():void{this.endGroup('mtd');}

  private beginGroup(name:string):void{
    this.token = null; // Not anymore in a token context.
    this.addChild(this.proxy.createNode(name));

    // When annotation and annotation-xml, we are no longer
    // in the context of MathML presentation markup.
    if(name === 'annotation' || name === 'annotation-xml'){
      this.validation = false;
    }
  }

  private endGroup(name:string):void{
    const group:any = this.lookup(name);
    if(!group){
      throw new Error(`Cannot close group ${name}`);
    }

    if(!this.acceptUnlimitedArguments(group) && this.validation){
      if(this.proxy.numChildren(group) < this.requiredArgumentsCount(group)){
        throw new Error(`Insufficient arguments for ${name}`);
      }
    }

    if(name === 'annotation' || name === 'annotation-xml'){
      this.validation = true;
    }

    this.group = this.proxy.getParent(group);
    this.token = null;
  }

  public beginStyleName(value:string):void{
    switch(value){
      case 'emphasis':
        this.beginEmphasis();
        break;
      case 'right':
        this.beginAlignRight();
        break;
      default:
        this.beginStyle();
        break;
    }
  }

  public endStyleName():void{
    this.endStyle();
  }

  public beginAlignRight():void{
    this.beginStyle();
    this.numalign = 'right';
    this.denomalign = 'right';
  }

  public endAlignRight():void{
    this.endStyle();
  }

  public beginEmphasis():void{
    this.beginStyle();
    this.mathvariant = 'bold';
    this.mathcolor = '#225078';
  }

  public endEmphasis():void{
    this.endStyle();
  }

  public appendIdentifier(value:string):void{this.addToken('mi', value);}

  public appendNumber(value:string):void{this.addToken('mn', value);}

  public appendOperator(value:string):void{this.addToken('mo', value);}

  public appendText(value:string):void{this.addToken('mtext', value);}

  public appendSpace():void{this.addChild(this.proxy.createNode('mspace'));}

  /**
   * We can directly append the first child of an element if
   * we already have a root and what we are trying to append
   * is a math element with no attributes and a single child.
   */
  private tryAppendFirstChild(parent:MElement):boolean{
    if(this.root == null){
      return false;
    }
    if(parent.name !== 'math' || parent.children.length !== 1){
      return false;
    }
    const attributeNames:any[] = XObject.getProps(parent.attributes);
    const xmlns:number =  attributeNames.indexOf('xmlns');
    if(xmlns !== -1){
      attributeNames.splice(xmlns, 1);
    }
    if(attributeNames.length !== 0){
      return false;
    }

    this.appendElement(parent.children[0]);

    return true;
  }

  public appendElement(value:MElement):void{
    if(this.tryAppendFirstChild(value)){
      return;
    }

    let i:number;

    const hasRoot:boolean = this.root != null;

    if(value.name === 'math'){
      if(hasRoot){
        this.beginStyle();
      }else{
        this.beginMath();
      }
    }else if(MmlWriter.tokens.indexOf(value.name) !== -1){
      this.addToken(value.name, value.text);
    }else{
      this.beginGroup(value.name);
    }

    const attributes:any = value.attributes;
    const attributeNames:any[] = XObject.getProps(value.attributes);
    for(i = 0 ; i < attributeNames.length ; i++){
      const attributeName:string = attributeNames[i];
      // If there is already a math node, we replace it with mstyle,
      // but the namespace should be at node math level.
      if (attributeName.indexOf('xmlns') !== -1 && value.name === 'math' && hasRoot) {
        continue;
      }
      this.proxy.setAttribute(this.token ? this.token : this.group, attributeName, attributes[attributeName]);
    }

    if(!this.validation && value.text != null){
      this.proxy.setText(this.group, value.text);
    }

    for(i = 0 ; i < value.children.length ; i++){
      this.appendElement(value.children[i]);
    }

    if(value.name === 'math'){
      if(hasRoot){
        this.endStyle();
      }else{
        this.endMath();
      }
    }else if(MmlWriter.tokens.indexOf(value.name) !== -1){
      // token, no need to close it
    }else{
      this.endGroup(value.name);
    }
  }

  public addCommaCheck():void{
    this.commas.push(false);
  }

  public checkComma():boolean{
    return this.commas.pop();
  }

  private addToken(name:string, value:string):void{
    const token:any = this.proxy.createNode(name);
    if (value != null) {
      this.proxy.setText(token, value);
    }
    this.addChild(token);
    if(value){
      if(value.indexOf(',') !== -1){
        for(let i:number = 0 ; i < this.commas.length ; i++){
          this.commas[i] = true;
        }
      }
    }
  }

  public lineBreak():void{
    const token:any = this.proxy.createNode('mspace');
    this.proxy.setAttribute(token, 'linebreak', 'newline');
    this.addChild(token);
  }

  private addChild(child:any):void{
    if(!this.group){
      if(!this.root){
        this.root = child;
        if(this.isToken(child)){
          this.token = child;
        }else{
          this.group = child;
        }
      }else{
        // If there's a root without selection, it means the
        // root node was close with a call to a prefixed end
        // function such as endTable.
        throw new Error('Cannot add siblings to the root');
      }
    }else{

      if(!this.acceptUnlimitedArguments(this.group) && this.validation){
        if(this.proxy.numChildren(this.group) === this.requiredArgumentsCount(this.group)){
          throw new Error(`Too many arguments for ${this.proxy.elementName(this.group)}`);
        }
      }

      this.proxy.addChild(this.group, child);
      if(this.isToken(child)){
        this.token = child;
      }else{
        this.group = child;
      }
    }
  }

  private requiredArgumentsCount(node:any):number{
    const name:string = this.proxy.elementName(node);
    if(		name === 'mfrac' ||
        name === 'mroot' ||
        name === 'msub' ||
        name === 'msup' ||
        name === 'munder' ||
        name === 'mover'){
      return 2;
    }

    if(		name === 'msubsup' ||
        name === 'munderover'){
      return 3;
    }

    return 0;
  }

  /**
   * List of elements that accepts an unlimited number of arguments.
   *
   * NOTE: mmultiscripts accept an unlimited number of arguments, but with a certain pattern. elementsWithUnlimitedArguments is not enough to really validate mmultiscripts element. MUST be improved.
   *
   * @type {[string,string,string,string,string,string,string,string,string,string,string,string,string,string]}
   */
  private static elementsWithUnlimitedArguments:any[] =
    ['mrow', 'msqrt', 'mstyle', 'merror', 'mpadded', 'mphantom', 'mfenced', 'menclose', 'mtable', 'mtr', 'mtd', 'math', 'semantics', 'mmultiscripts'];

  private acceptUnlimitedArguments(node:any):boolean{
    const name:string = this.proxy.elementName(node);
    return MmlWriter.elementsWithUnlimitedArguments.indexOf(name) !== -1;
  }

  private static tokens:any[] = ['mi', 'mn', 'mo', 'mtext', 'mspace', 'ms'];

  private isToken(node:any):boolean{
    const name:string = this.proxy.elementName(node);
    return MmlWriter.tokens.indexOf(name) !== -1;
  }

  private lookup(name:string):any{
    let parent:any = this.group;
    while(parent){
      if(name === this.proxy.elementName(parent)){
        return parent;
      }
      parent = this.proxy.getParent(parent);
    }
    return null;
  }

  private get groupName():string{
    if(!this.group){
      return null;
    }
    return this.proxy.elementName(this.group);
  }

  private get tokenName():string{
    if(!this.token){
      return null;
    }
    return this.proxy.elementName(this.token);
  }

  private get tokenOrGroup(): any {
    return this.token ? this.token : this.group;
  }

  private validateGroupAttributeName(attributeName:string, ...groupNames:any[]):void{
    if(this.groupName === 'mstyle'){
      return;
    }
    if(groupNames.indexOf(this.groupName) === -1){
      throw new Error(`Attribute '${attributeName}' only valid on ${groupNames.join(', ')}`);
    }
  }

  private validateTokenAttributeName(attributeName:string, ...tokensNames:any[]):void{
    if(this.groupName === 'mstyle'){
      return;
    }
    if(tokensNames.indexOf(this.tokenName) === -1){
      throw new Error(`Attribute '${attributeName}' only valid on ${tokensNames.join(', ')}`);
    }
  }

  private validateGroupOrTokenAttributeName(attributeName:string, ...groupOrTokenNames:any[]):void{
    if(this.groupName === 'mstyle' && this.tokenName == null){
      return;
    }
    if(groupOrTokenNames.indexOf(this.groupName) === -1 && groupOrTokenNames.indexOf(this.tokenName) === -1){
      throw new Error(`Attribute '${attributeName}' only valid on ${groupOrTokenNames.join(', ')}`);
    }
  }

  private validateTokenSharedAttributes(attributeName:string):void{
    if(this.groupName === 'mstyle'){
      return;
    }
    if(!this.token){
      throw new Error(`Attribute '${attributeName}' valid on tokens only`);
    }
  }

  /**
   * Shared by all MathML elements.
   */
  public set id(value:string){
    this.proxy.setAttribute(this.tokenOrGroup, 'id', value);
  }

  /**
   * Shared by all MathML elements.
   */
  public set xref(value:string){
    this.proxy.setAttribute(this.tokenOrGroup, 'xref', value);
  }

  /**
   * Shared by all MathML elements.
   */
  public set _class(value:string){
    this.proxy.setAttribute(this.tokenOrGroup, 'class', value);
  }

  /**
   * Shared by all MathML elements.
   */
  public set style(value:string){
    this.proxy.setAttribute(this.tokenOrGroup, 'style', value);
  }

  /**
   * Shared by all MathML elements.
   */
  public set href(value:string){
    this.proxy.setAttribute(this.tokenOrGroup, 'href', value);
  }

  /**
   * Shared by all MathML elements.
   */
  public set other(value:string){
    this.proxy.setAttribute(this.tokenOrGroup, 'other', value);
  }

  /**
   * Shared by all tokens elements (and mstyle).
   */
  public set mathcolor(value:string){
    this.validateTokenSharedAttributes('mathcolor');
    this.proxy.setAttribute(this.tokenOrGroup, 'mathcolor', value);
  }

  /**
   * Shared by all tokens elements (and mstyle).
   */
  public set mathbackground(value:string){
    this.validateTokenSharedAttributes('mathbackground');
    this.proxy.setAttribute(this.tokenOrGroup, 'mathbackground', value);
  }

  /**
   * maction
   */
  public set actiontype(value:string){
    this.validateGroupAttributeName('actiontype', 'maction');
    this.proxy.setAttribute(this.group, 'actiontype', value);
  }

  /**
   * maction
   */
  public set selection(value:number){
    this.validateGroupAttributeName('selection', 'maction');
    this.proxy.setAttribute(this.group, 'selection', value);
  }

  /**
   * menclose
   */
  public set notation(value:string){
    this.validateGroupAttributeName('notation', 'menclose');
    this.proxy.setAttribute(this.group, 'notation', value);
  }

  /**
   * mfenced
   */
  public set open(value:string){
    this.validateGroupAttributeName('open', 'mfenced');
    this.proxy.setAttribute(this.group, 'open', value);
  }

  /**
   * mfenced
   */
  public set close(value:string){
    this.validateGroupAttributeName('close', 'mfenced');
    this.proxy.setAttribute(this.group, 'close', value);
  }

  /**
   * mfenced
   */
  public set separators(value:string){
    this.validateGroupAttributeName('separators', 'mfenced');
    this.proxy.setAttribute(this.group, 'separators', value);
  }

  /**
   * mfrac
   */
  public set linethickness(value:string){
    this.validateGroupAttributeName('linethickness', 'mfrac');
    this.proxy.setAttribute(this.group, 'linethickness', value);
  }

  /**
   * mfrac
   * left | center | right  (center)
   */
  public set numalign(value:string){
    this.validateGroupAttributeName('numalign', 'mfrac');
    this.proxy.setAttribute(this.group, 'numalign', value);
  }

  /**
   * mfrac
   * left | center | right  (center)
   */
  public set denomalign(value:string){
    this.validateGroupAttributeName('denomalign', 'mfrac');
    this.proxy.setAttribute(this.group, 'denomalign', value);
  }

  /**
   * mfrac
   * true | false  (false)
   */
  public set bevelled(value:boolean){
    this.validateGroupAttributeName('bevelled', 'mfrac');
    this.proxy.setAttribute(this.group, 'bevelled', value);
  }

  /**
   * mmultiscripts, msub, msubsup
   */
  public set subscriptshift(value:string){
    this.validateGroupAttributeName('subscriptshift', 'mmultiscripts', 'msub', 'msubsup');
    this.proxy.setAttribute(this.group, 'subscriptshift', value);
  }

  /**
   * mmultiscripts, msup, msubsup
   */
  public set superscriptshift(value:string){
    this.validateGroupAttributeName('superscriptshift', 'mmultiscripts', 'msup', 'msubsup');
    this.proxy.setAttribute(this.group, 'superscriptshift', value);
  }

  /**
   * mover, munderover, mo
   */
  public set accent(value:boolean){
    this.validateGroupOrTokenAttributeName('accent', 'mover', 'munderover', 'mo');
    this.proxy.setAttribute(this.tokenOrGroup, 'accent', value);
  }

  /**
   * munder, munderover
   */
  public set accentunder(value:boolean){
    this.validateGroupAttributeName('accentunder', 'munder', 'munderover');
    this.proxy.setAttribute(this.group, 'accentunder', value);
  }

  /**
   * mover, munder, munderover, mtable
   */
  public set align(value:string){
    this.validateGroupAttributeName('align', 'mover', 'munder', 'munderover', 'mtable');
    this.proxy.setAttribute(this.group, 'align', value);
  }

  /**
   * mpadded, mspace
   */
  public set height(value:string){
    this.validateGroupOrTokenAttributeName('height', 'mpadded', 'mspace');
    this.proxy.setAttribute(this.tokenOrGroup, 'height', value);
  }

  /**
   * mpadded, mspace
   */
  public set depth(value:string){
    this.validateGroupOrTokenAttributeName('depth', 'mpadded', 'mspace');
    this.proxy.setAttribute(this.tokenOrGroup, 'depth', value);
  }

  /**
   * mpadded, mtable, mspace
   */
  public set width(value:string){
    this.validateGroupOrTokenAttributeName('width', 'mpadded', 'mspace', 'mtable');
    this.proxy.setAttribute(this.tokenOrGroup, 'width', value);
  }

  /**
   * mpadded, mo
   */
  public set lspace(value:string){
    this.validateGroupOrTokenAttributeName('lspace', 'mpadded', 'mo');
    this.proxy.setAttribute(this.tokenOrGroup, 'lspace', value);
  }

  /**
   * mpadded
   */
  public set voffset(value:string){
    this.validateGroupAttributeName('voffset', 'mpadded');
    this.proxy.setAttribute(this.group, 'voffset', value);
  }

  /**
   * Valid on any token element and mrow
   */
  public set dir(value:string){
    this.validateGroupOrTokenAttributeName('dir', 'mrow', 'mi', 'mn', 'mo', 'mtext', 'mspace', 'ms', 'mglyph');
    this.proxy.setAttribute(this.tokenOrGroup, 'dir', value);
  }

  /**
   * Shared by all tokens elements (and mstyle).
   */
  public set mathvariant(value:string){
    this.validateTokenSharedAttributes('mathvariant');
    this.proxy.setAttribute(this.tokenOrGroup, 'mathvariant', value);
  }

  /**
   * Shared by all tokens elements (and mstyle).
   */
  public set mathsize(value:string){
    this.validateTokenSharedAttributes('mathsize');
    this.proxy.setAttribute(this.tokenOrGroup, 'mathsize', value);
  }

  /**
   * Shared by all tokens elements (and mstyle).
   */
  public set fontfamily(value:string){
    this.validateTokenSharedAttributes('fontfamily');
    this.proxy.setAttribute(this.tokenOrGroup, 'fontfamily', value);
  }

  /**
   * Shared by all tokens elements (and mstyle).
   */
  public set fontweight(value:string){
    this.validateTokenSharedAttributes('fontweight');
    this.proxy.setAttribute(this.tokenOrGroup, 'fontweight', value);
  }

  /**
   * Shared by all tokens elements (and mstyle).
   */
  public set fontstyle(value:string){
    this.validateTokenSharedAttributes('fontstyle');
    this.proxy.setAttribute(this.tokenOrGroup, 'fontstyle', value);
  }

  /**
   * Shared by all tokens elements (and mstyle).
   */
  public set fontsize(value:string){
    this.validateTokenSharedAttributes('fontsize');
    this.proxy.setAttribute(this.tokenOrGroup, 'fontsize', value);
  }

  /**
   * Shared by all tokens elements (and mstyle).
   */
  public set color(value:string){
    this.validateTokenSharedAttributes('color');
    this.proxy.setAttribute(this.tokenOrGroup, 'color', value);
  }

  /**
   * Shared by all tokens elements (and mstyle).
   */
  public set background(value:string){
    this.validateTokenSharedAttributes('background');
    this.proxy.setAttribute(this.tokenOrGroup, 'background', value);
  }

  /**
   * maligngroup, mtable, mtr, mtd
   * left | center | right | decimalpoint
   */
  public set groupalign(value:string){
    this.validateGroupAttributeName('groupalign', 'maligngroup', 'mtable', 'mtr', 'mtd');
    this.proxy.setAttribute(this.group, 'groupalign', value);
  }

  /**
   * malignmark
   * left | right  (left)
   */
  public set edge(value:string){
    this.validateGroupAttributeName('edge', 'malignmark');
    this.proxy.setAttribute(this.group, 'edge', value);
  }

  /**
   * mtable, mtr, mtd
   */
  public set rowalign(value:string){
    this.validateGroupAttributeName('rowalign', 'mtable', 'mtr', 'mtd');
    this.proxy.setAttribute(this.group, 'rowalign', value);
  }

  /**
   * mtable, mtr, mtd
   */
  public set columnalign(value:string){
    this.validateGroupAttributeName('columnalign', 'mtable', 'mtr', 'mtd');
    this.proxy.setAttribute(this.group, 'columnalign', value);
  }

  /**
   * mtable
   */
  public set alignmentscope(value:string){
    this.validateGroupAttributeName('alignmentscope', 'mtable');
    this.proxy.setAttribute(this.group, 'alignmentscope', value);
  }

  /**
   * mtable
   */
  public set columnwidth(value:string){
    this.validateGroupAttributeName('columnwidth', 'mtable');
    this.proxy.setAttribute(this.group, 'columnwidth', value);
  }

  /**
   * mtable
   */
  public set rowspacing(value:string){
    this.validateGroupAttributeName('rowspacing', 'mtable');
    this.proxy.setAttribute(this.group, 'rowspacing', value);
  }

  /**
   * mtable
   */
  public set columnspacing(value:string){
    this.validateGroupAttributeName('columnspacing', 'mtable');
    this.proxy.setAttribute(this.group, 'columnspacing', value);
  }

  /**
   * mtable
   */
  public set rowlines(value:string){
    this.validateGroupAttributeName('rowlines', 'mtable');
    this.proxy.setAttribute(this.group, 'rowlines', value);
  }

  /**
   * mtable
   */
  public set columnlines(value:string){
    this.validateGroupAttributeName('columnlines', 'mtable');
    this.proxy.setAttribute(this.group, 'columnlines', value);
  }

  /**
   * mtable
   */
  public set frame(value:string){
    this.validateGroupAttributeName('frame', 'mtable');
    this.proxy.setAttribute(this.group, 'frame', value);
  }

  /**
   * mtable
   */
  public set framespacing(value:string){
    this.validateGroupAttributeName('framespacing', 'mtable');
    this.proxy.setAttribute(this.group, 'framespacing', value);
  }

  /**
   * mtable
   */
  public set equalrows(value:boolean){
    this.validateGroupAttributeName('equalrows', 'mtable');
    this.proxy.setAttribute(this.group, 'equalrows', value);
  }

  /**
   * mtable
   */
  public set equalcolumns(value:boolean){
    this.validateGroupAttributeName('equalcolumns', 'mtable');
    this.proxy.setAttribute(this.group, 'equalcolumns', value);
  }

  /**
   * mtable
   */
  public set displaystyle(value:string){
    this.validateGroupAttributeName('displaystyle', 'mtable');
    this.proxy.setAttribute(this.group, 'displaystyle', value);
  }

  /**
   * mtable
   */
  public set side(value:string){
    this.validateGroupAttributeName('side', 'mtable');
    this.proxy.setAttribute(this.group, 'side', value);
  }

  /**
   * mtable
   */
  public set minlabelspacing(value:string){
    this.validateGroupAttributeName('minlabelspacing', 'mtable');
    this.proxy.setAttribute(this.group, 'minlabelspacing', value);
  }

  /**
   * mtd
   */
  public set rowspan(value:number){
    this.validateGroupAttributeName('rowspan', 'mtd');
    this.proxy.setAttribute(this.group, 'rowspan', value);
  }

  /**
   * mtd
   */
  public set columnspan(value:number){
    this.validateGroupAttributeName('columnspan', 'mtd');
    this.proxy.setAttribute(this.group, 'columnspan', value);
  }

  /**
   * mo
   */
  public set form(value:string){
    this.validateTokenAttributeName('form', 'mo');
    this.proxy.setAttribute(this.tokenOrGroup, 'form', value);
  }

  /**
   * mo
   */
  public set fence(value:boolean){
    this.validateTokenAttributeName('fence', 'mo');
    this.proxy.setAttribute(this.tokenOrGroup, 'fence', value);
  }

  /**
   * mo
   */
  public set separator(value:boolean){
    this.validateTokenAttributeName('separator', 'mo');
    this.proxy.setAttribute(this.tokenOrGroup, 'separator', value);
  }

  /**
   * mo
   */
  public set rspace(value:string){
    this.validateTokenAttributeName('rspace', 'mo');
    this.proxy.setAttribute(this.tokenOrGroup, 'rspace', value);
  }

  /**
   * mo
   */
  public set stretchy(value:boolean){
    this.validateTokenAttributeName('stretchy', 'mo');
    this.proxy.setAttribute(this.tokenOrGroup, 'stretchy', value);
  }

  /**
   * mo
   */
  public set symmetric(value:boolean){
    this.validateTokenAttributeName('symmetric', 'mo');
    this.proxy.setAttribute(this.tokenOrGroup, 'symmetric', value);
  }

  /**
   * mo
   */
  public set maxsize(value:string){
    this.validateTokenAttributeName('maxsize', 'mo');
    this.proxy.setAttribute(this.tokenOrGroup, 'maxsize', value);
  }

  /**
   * mo
   */
  public set minsize(value:string){
    this.validateTokenAttributeName('minsize', 'mo');
    this.proxy.setAttribute(this.tokenOrGroup, 'minsize', value);
  }

  /**
   * mo
   */
  public set largeop(value:boolean){
    this.validateTokenAttributeName('largeop', 'mo');
    this.proxy.setAttribute(this.tokenOrGroup, 'largeop', value);
  }

  /**
   * mo
   */
  public set movablelimits(value:boolean){
    this.validateTokenAttributeName('movablelimits', 'mo');
    this.proxy.setAttribute(this.tokenOrGroup, 'movablelimits', value);
  }

  /**
   * mo, mspace
   */
  public set linebreak(value:string){
    this.validateTokenAttributeName('linebreak', 'mo', 'mspace');
    this.proxy.setAttribute(this.tokenOrGroup, 'linebreak', value);
  }

  /**
   * mo
   */
  public set lineleading(value:string){
    this.validateTokenAttributeName('lineleading', 'mo');
    this.proxy.setAttribute(this.tokenOrGroup, 'lineleading', value);
  }

  /**
   * mo
   */
  public set linebreakstyle(value:string){
    this.validateTokenAttributeName('linebreakstyle', 'mo');
    this.proxy.setAttribute(this.tokenOrGroup, 'linebreakstyle', value);
  }

  /**
   * mo
   */
  public set linebreakmultchar(value:string){
    this.validateTokenAttributeName('linebreakmultchar', 'mo');
    this.proxy.setAttribute(this.tokenOrGroup, 'linebreakmultchar', value);
  }

  /**
   * mo, mspace
   */
  public set indentalign(value:string){
    this.validateTokenAttributeName('indentalign', 'mo', 'mspace');
    this.proxy.setAttribute(this.tokenOrGroup, 'indentalign', value);
  }

  /**
   * mo, mspace
   */
  public set indentshift(value:string){
    this.validateTokenAttributeName('indentshift', 'mo', 'mspace');
    this.proxy.setAttribute(this.tokenOrGroup, 'indentshift', value);
  }

  /**
   * mo, mspace
   */
  public set indenttarget(value:string){
    this.validateTokenAttributeName('indenttarget', 'mo', 'mspace');
    this.proxy.setAttribute(this.tokenOrGroup, 'indenttarget', value);
  }

  /**
   * mo, mspace
   */
  public set indentalignfirst(value:string){
    this.validateTokenAttributeName('indentalignfirst', 'mo', 'mspace');
    this.proxy.setAttribute(this.tokenOrGroup, 'indentalignfirst', value);
  }

  /**
   * mo, mspace
   */
  public set indentshiftfirst(value:string){
    this.validateTokenAttributeName('indentshiftfirst', 'mo', 'mspace');
    this.proxy.setAttribute(this.tokenOrGroup, 'indentshiftfirst', value);
  }

  /**
   * mo, mspace
   */
  public set indentalignlast(value:string){
    this.validateTokenAttributeName('indentalignlast', 'mo', 'mspace');
    this.proxy.setAttribute(this.tokenOrGroup, 'indentalignlast', value);
  }

  /**
   * mo, mspace
   */
  public set indentshiftlast(value:string){
    this.validateTokenAttributeName('indentshiftlast', 'mo', 'mspace');
    this.proxy.setAttribute(this.tokenOrGroup, 'indentshiftlast', value);
  }

  /**
   * ms
   */
  public set lquote(value:string){
    this.validateTokenAttributeName('lquote', 'ms');
    this.proxy.setAttribute(this.tokenOrGroup, 'lquote', value);
  }

  /**
   * ms
   */
  public set rquote(value:string){
    this.validateTokenAttributeName('rquote', 'ms');
    this.proxy.setAttribute(this.tokenOrGroup, 'rquote', value);
  }

  public get content():any{return this.root;}

}
