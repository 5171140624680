import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WQuadratic } from '../../elements/tokens/WQuadratic';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class Apex extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 1){
      return args.expectingArguments(1, 1);
    }

    const quadratic:WQuadratic = args.getQuadraticOrPoly(0);
    if(!quadratic){
      return null;
    }

    return quadratic.apex;
  }

}
