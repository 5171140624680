import { IDictionary } from '../../../js/utils/IDictionary';

import { XObject } from '../../core/XObject';

/**
 *
 */
export class ColorsTable {

  /**
   *
   */
  private names:IDictionary;

  /**
   *
   */
  constructor() {

    const o:IDictionary = {};
    o.AliceBlue = 0xF0F8FF;
    o.AntiqueWhite = 0xFAEBD7;
    o.Aqua = 0x00FFFF;
    o.Aquamarine = 0x7FFFD4;
    o.Azure = 0xF0FFFF;
    o.Beige = 0xF5F5DC;
    o.Bisque = 0xFFE4C4;
    o.Black = 0x000000;
    o.BlanchedAlmond = 0xFFEBCD;
    o.Blue = 0x0000FF;
    o.BlueViolet = 0x8A2BE2;
    o.Brown = 0xA52A2A;
    o.BurlyWood = 0xDEB887;
    o.CadetBlue = 0x5F9EA0;
    o.Chartreuse = 0x7FFF00;
    o.Chocolate = 0xD2691E;
    o.Coral = 0xFF7F50;
    o.CornflowerBlue = 0x6495ED;
    o.Cornsilk = 0xFFF8DC;
    o.Crimson = 0xDC143C;
    o.Cyan = 0x00FFFF;
    o.DarkBlue = 0x00008B;
    o.DarkCyan = 0x008B8B;
    o.DarkGoldenRod = 0xB8860B;
    o.DarkGray = 0xA9A9A9;
    o.DarkGreen = 0x006400;
    o.DarkKhaki = 0xBDB76B;
    o.DarkMagenta = 0x8B008B;
    o.DarkOliveGreen = 0x556B2F;
    o.DarkOrange = 0xFF8C00;
    o.DarkOrchid = 0x9932CC;
    o.DarkRed = 0x8B0000;
    o.DarkSalmon = 0xE9967A;
    o.DarkSeaGreen = 0x8FBC8F;
    o.DarkSlateBlue = 0x483D8B;
    o.DarkSlateGray = 0x2F4F4F;
    o.DarkTurquoise = 0x00CED1;
    o.DarkViolet = 0x9400D3;
    o.DeepPink = 0xFF1493;
    o.DeepSkyBlue = 0x00BFFF;
    o.DimGray = 0x696969;
    o.DodgerBlue = 0x1E90FF;
    o.FireBrick = 0xB22222;
    o.FloralWhite = 0xFFFAF0;
    o.ForestGreen = 0x228B22;
    o.Fuchsia = 0xFF00FF;
    o.Gainsboro = 0xDCDCDC;
    o.GhostWhite = 0xF8F8FF;
    o.Gold = 0xFFD700;
    o.GoldenRod = 0xDAA520;
    o.Gray = 0x808080;
    o.Green = 0x008000;
    o.GreenYellow = 0xADFF2F;
    o.HoneyDew = 0xF0FFF0;
    o.HotPink = 0xFF69B4;
    o.IndianRed = 0xCD5C5C;
    o.Indigo = 0x4B0082;
    o.Ivory = 0xFFFFF0;
    o.Khaki = 0xF0E68C;
    o.Lavender = 0xE6E6FA;
    o.LavenderBlush = 0xFFF0F5;
    o.LawnGreen = 0x7CFC00;
    o.LemonChiffon = 0xFFFACD;
    o.LightBlue = 0xADD8E6;
    o.LightCoral = 0xF08080;
    o.LightCyan = 0xE0FFFF;
    o.LightGoldenRodYellow = 0xFAFAD2;
    o.LightGray = 0xD3D3D3;
    o.LightGreen = 0x90EE90;
    o.LightPink = 0xFFB6C1;
    o.LightSalmon = 0xFFA07A;
    o.LightSeaGreen = 0x20B2AA;
    o.LightSkyBlue = 0x87CEFA;
    o.LightSlateGray = 0x778899;
    o.LightSteelBlue = 0xB0C4DE;
    o.LightYellow = 0xFFFFE0;
    o.Lime = 0x00FF00;
    o.LimeGreen = 0x32CD32;
    o.Linen = 0xFAF0E6;
    o.Magenta = 0xFF00FF;
    o.Maroon = 0x800000;
    o.MediumAquaMarine = 0x66CDAA;
    o.MediumBlue = 0x0000CD;
    o.MediumOrchid = 0xBA55D3;
    o.MediumPurple = 0x9370DB;
    o.MediumSeaGreen = 0x3CB371;
    o.MediumSlateBlue = 0x7B68EE;
    o.MediumSpringGreen = 0x00FA9A;
    o.MediumTurquoise = 0x48D1CC;
    o.MediumVioletRed = 0xC71585;
    o.MidnightBlue = 0x191970;
    o.MintCream = 0xF5FFFA;
    o.MistyRose = 0xFFE4E1;
    o.Moccasin = 0xFFE4B5;
    o.NavajoWhite = 0xFFDEAD;
    o.Navy = 0x000080;
    o.OldLace = 0xFDF5E6;
    o.Olive = 0x808000;
    o.OliveDrab = 0x6B8E23;
    o.Orange = 0xFFA500;
    o.OrangeRed = 0xFF4500;
    o.Orchid = 0xDA70D6;
    o.PaleGoldenRod = 0xEEE8AA;
    o.PaleGreen = 0x98FB98;
    o.PaleTurquoise = 0xAFEEEE;
    o.PaleVioletRed = 0xDB7093;
    o.PapayaWhip = 0xFFEFD5;
    o.PeachPuff = 0xFFDAB9;
    o.Peru = 0xCD853F;
    o.Pink = 0xFFC0CB;
    o.Plum = 0xDDA0DD;
    o.PowderBlue = 0xB0E0E6;
    o.Purple = 0x800080;
    o.Red = 0xFF0000;
    o.RosyBrown = 0xBC8F8F;
    o.RoyalBlue = 0x4169E1;
    o.SaddleBrown = 0x8B4513;
    o.Salmon = 0xFA8072;
    o.SandyBrown = 0xF4A460;
    o.SeaGreen = 0x2E8B57;
    o.SeaShell = 0xFFF5EE;
    o.Sienna = 0xA0522D;
    o.Silver = 0xC0C0C0;
    o.SkyBlue = 0x87CEEB;
    o.SlateBlue = 0x6A5ACD;
    o.SlateGray = 0x708090;
    o.Snow = 0xFFFAFA;
    o.SpringGreen = 0x00FF7F;
    o.SteelBlue = 0x4682B4;
    o.Tan = 0xD2B48C;
    o.Teal = 0x008080;
    o.Thistle = 0xD8BFD8;
    o.Tomato = 0xFF6347;
    o.Turquoise = 0x40E0D0;
    o.Violet = 0xEE82EE;
    o.Wheat = 0xF5DEB3;
    o.White = 0xFFFFFF;
    o.WhiteSmoke = 0xF5F5F5;
    o.Yellow = 0xFFFF00;
    o.YellowGreen = 0x9ACD32;

    const names:IDictionary = {};
    const props:any[] = XObject.getProps(o);
    for(let i:number = 0 ; i < props.length ; i++){
      names[props[i].toLowerCase()] = o[props[i]];
    }
    this.names = names;
  }

  /**
   *
   */
  public getColorNames():any[]{
    return XObject.getProps(this.names);
  }

  /**
   *
   */
  public getColorByName(name:string):number{
    return this.names.hasOwnProperty(name) ? this.names[name] : NaN;
  }

  /**
   *
   */
  public isColorName(name:string):boolean{
    return this.names.hasOwnProperty(name);
  }

}
