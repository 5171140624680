import { XString } from '../../../core/XString';
import { MmlWriter } from '../../../core/mml/MmlWriter';
import { AnonymousFunction } from '../../../elements/abstract/AnonymousFunction';
import { Attributes } from '../../../elements/abstract/Attributes';
import { ConstructorElement } from '../../../elements/abstract/ConstructorElement';
import { ContentElement } from '../../../elements/abstract/ContentElement';
import { Expression } from '../../../elements/abstract/Expression';
import { FunctionElement } from '../../../elements/abstract/FunctionElement';
import { ListElement } from '../../../elements/abstract/ListElement';
import { Node } from '../../../elements/abstract/Node';
import { RealElement } from '../../../elements/abstract/RealElement';
import { TokenElement } from '../../../elements/abstract/TokenElement';
import { UserFunction } from '../../../elements/abstract/UserFunction';
import { Apply } from '../../../elements/constructs/Apply';
import { BoundVariable } from '../../../elements/constructs/BoundVariable';
import { Declare } from '../../../elements/constructs/Declare';
import { Degree } from '../../../elements/constructs/Degree';
import { Interval } from '../../../elements/constructs/Interval';
import { Lambda } from '../../../elements/constructs/Lambda';
import { List } from '../../../elements/constructs/List';
import { Otherwise } from '../../../elements/constructs/Otherwise';
import { Piece } from '../../../elements/constructs/Piece';
import { Root } from '../../../elements/constructs/Root';
import { Set } from '../../../elements/constructs/Set';
import { FormatProvider } from '../../../elements/factories/FormatProvider';
import { BaseNumberFormatter } from '../../../elements/formats/BaseNumberFormatter';
import { IMarkupExporter } from '../../../elements/markers/IMarkupExporter';
import { WEval } from '../../../elements/tokens/WEval';
import { WList } from '../../../elements/tokens/WList';
import { WNumber } from '../../../elements/tokens/WNumber';
import { WPolynomial } from '../../../elements/tokens/WPolynomial';
import { Abs } from '../../../funcs/arithmetic/Abs';
import { Divide } from '../../../funcs/arithmetic/Divide';
import { Minus } from '../../../funcs/arithmetic/Minus';
import { NthRoot } from '../../../funcs/arithmetic/NthRoot';
import { Plus } from '../../../funcs/arithmetic/Plus';
import { Power } from '../../../funcs/arithmetic/Power';
import { Quotient } from '../../../funcs/arithmetic/Quotient';
import { Sqrt } from '../../../funcs/arithmetic/Sqrt';
import { Times } from '../../../funcs/arithmetic/Times';
import { ItemAt } from '../../../funcs/lists/ItemAt';
import { CultureInfo } from '../../../localization/CultureInfo';
import { AutoAnnotation } from '../../../expr/conversion/output/AutoAnnotation';
import { IRowAnnotation } from '../../../expr/conversion/output/IRowAnnotation';
import { Recall } from '../../../elements/constructs/Recall';
import { RecallFunction } from '../../../elements/abstract/RecallFunction';

/**
 * Export an expression tree into MathML presentation markup.
 */
export class MarkupExporter implements IMarkupExporter {

  private _writer:MmlWriter;

  public get writer():MmlWriter{return this._writer;}

  private _format:FormatProvider;

  public get format():FormatProvider{return this._format;}

  private _withStyles:boolean;

  public get withStyles():boolean{return this._withStyles;}

  private _ignoreComments:boolean;

  public get ignoreComments():boolean{return this._ignoreComments;}

  private _maxStringLength:number;

  public get maxStringLength():number{return this._maxStringLength;}

  /**
   *
   */
  constructor(
      writer:MmlWriter,
      format:FormatProvider,
      withStyles:boolean = true,
      ignoreComments:boolean = false,
      maxStringLength:number = Number.MAX_SAFE_INTEGER){

    this._writer = writer;
    this._format = format;
    this._withStyles = withStyles;
    this._ignoreComments = ignoreComments;
    this._maxStringLength = maxStringLength;
  }

  private currentExpression:Expression;

  /**
   *
   */
  public get culture():CultureInfo{
    return this.format.culture;
  }

  /**
   *
   */
  public writeStartDocument():void{
    this.writer.beginMath();
  }

  /**
   *
   */
  public writeEndDocument():void{
    this.writer.endMath();
  }

  /**
   *
   */
  public writeExpressions(expressions:Expression[]):void{
    if(expressions.length === 1){
      this.currentExpression = expressions[0];
      if(this.currentExpression){
        this.writeNode(this.currentExpression.root);
      }
      this.currentExpression = null;
    }else{
      const autoAnnotation:IRowAnnotation =
        new AutoAnnotation();

      let rowIndex:number = 0;

      let linePrefix:string;
      let firstLinePrefix:string;

      if(this.format.linePrefix == null){
        firstLinePrefix = null;
        linePrefix = '=';
      }else{
        firstLinePrefix = linePrefix = this.format.linePrefix;
      }

      this.writer.beginTable();
      for(let i:number = 0 ; i < expressions.length ; i++ ){
        this.currentExpression = expressions[i];
        if(!this.currentExpression){
          continue;
        }

        autoAnnotation.annotate(
          this,
          this.currentExpression.root,
          this.ignoreComments ? null : this.currentExpression.comment,
          rowIndex,
          firstLinePrefix,
          linePrefix,
          this.currentExpression.tag);

        rowIndex++;
        this.currentExpression = null;
      }
      this.writer.endTable();
    }
  }

  /**
   *
   */
  public writeNode(value:Node):void{
    if(value == null){
      return;
    }
    return this.writeNode2(value);
  }

  /**
   *
   */
  private writeNode2(
      node:Node,
      encloseNegativeArg:boolean = false,
      encloseComplex:boolean = false,
      invisibleTimesBefore:boolean = false,
      prefixForm:boolean = false):void{

    let encloseNegative = encloseNegativeArg;
    const enclose:boolean =
      node.isExplicitGroup &&
      ( node.value instanceof Apply ||
        invisibleTimesBefore);

    if(enclose){
      encloseNegative = false; // avoid double parenthesis
    }

    if(node.isLeaf || node.value instanceof ConstructorElement){

      if(this.withStyles && node.className){
        // Wrap the xml node into a style node top represent the style name
        this.writer.beginStyleName(node.className);
      }

      if(enclose){
        this.writer.beginFenced();
        this.writer.separators = '';
      }

      if(node.value instanceof ConstructorElement){
        this.writeConstructor(node);
      }else if(node.isLeaf){
        this.writeLeaf(
          node.value,
          node,
          enclose,
          encloseNegative,
          encloseComplex,
          prefixForm);
      }

      if(enclose){
        this.writer.endFenced();
      }

      if(this.withStyles && node.className){
        this.writer.endStyleName();
      }

    }
  }

  /**
   *
   */
  private writeConstructor(node:Node):void{
    if(node.value instanceof Apply){
      this.writeApply(node);
    }else if(node.value instanceof List){
      this.writer.beginFenced();
      if(this.isFunctionArguments(node)){
        // The function will put required parenthesis
        this.writer.open = '';
        this.writer.close = '';
      }
      this.writer.addCommaCheck();
      this.writeChilds(node, 0);
      this.writer.separators = this.listSeparator(this.writer.checkComma());
      this.writer.endFenced();
    }else if(node.value instanceof Root){

      const root:Root = <Root>node.value ;
      if(root.other === '2'){
        this.writer.beginSqrt();
        this.writeChilds(node, 0);
        this.writer.endSqrt();
      }else{
        this.writer.beginRoot();
        this.writer.beginRow();
        this.writeChilds(node, 0);
        this.writer.endRow();
        this.writeNumber(Number(root.other));
        this.writer.endRoot();
      }

    }else if(node.value instanceof Set){
      if(node.childs.length === 0){
        this.writer.appendText('∅'); // anything else than mtext?
      }else{
        this.writer.beginFenced('{', '}');
        this.writer.addCommaCheck();
        this.writeChilds(node, 0);
        this.writer.separators = this.listSeparator(this.writer.checkComma());
        this.writer.endFenced();
      }
    }else if(node.value instanceof Interval){
      const interval:Interval = <Interval>node.value ;
      this.writer.beginFenced(
        interval.closure.getLeftFence(this.culture.configuration.intervalsNotation),
        interval.closure.getRightFence(this.culture.configuration.intervalsNotation));

      if(node.numChildren > 0){
        this.writeNode(node.childs[0]);
      }
      if(node.numChildren > 1){
        this.writeNode(node.childs[1]);
      }
      this.writer.endFenced();
    }else if(node.value instanceof Degree){
      this.writer.beginRow();
      this.writeChilds(node, 0);
      this.writer.endRow();
    }else if(node.value instanceof Lambda) {
      this.writeLambda(node);
    }else if(node.value instanceof Recall){
      this.writeRecall(node);
    }else if(node.value instanceof Declare){
      this.writer.beginRow();
      this.writeChilds(node, 0, 1);
      this.writer.beginFenced();
      this.writeChilds(node, 1);
      this.writer.endFenced();
      this.writer.endRow();
    }
  }

  /**
   *
   */
  private writeLambda(node:Node):void{
    this.writer.beginRow();
    this.writer.appendIdentifier('ƒ'); // &#x0192;
    this.writer.beginFenced();
    this.writer.separators = '';
    for(let i:number = 0 ; i < node.numChildren - 1 ; i++){
      if(i > 0){
        this.writeOperator(',');
      }
      this.writer.appendIdentifier((<BoundVariable>node.childs[i].value ).getVariable().getSymbol());
    }
    this.writer.endFenced();
    this.writer.appendOperator('=');
    if(node.lastChild){
      this.writePiecewise(node.lastChild);
    }
    this.writer.endRow();
  }

  /**
   *
   */
  private writeRecall(node:Node):void{
    this.writer.beginRow();
    this.writer.appendIdentifier('ƒ'); // &#x0192;
    this.writer.beginFenced();
    this.writer.separators = '';
    this.writeChilds(node, 0);
    this.writer.endRow();
  }

  /**
   * Returns true if the piecewise construct
   * has at least one piece.
   */
  private hasPieces(piecewise:Node):boolean{
    for(let i:number = 0 ; i < piecewise.numChildren ; i++){
      if(piecewise.childs[i].value instanceof Piece){
        return true;
      }
    }
    return false;
  }

  /**
   *
   */
  private writePiecewise(node:Node):void{
    // The children are piece with otherwise or otherwise or pieces
    if(this.hasPieces(node)){
      this.writer.beginFenced('{', '');
      this.writer.beginTable();
      this.writer.columnalign = 'left';

      for(let i:number = 0 ; i < node.numChildren ; i++){

        const child:Node = node.childs[i];

        this.writer.beginTr();

        this.writer.beginTd();
        this.writeNode(child.childs[0]);
        this.writeOperator(',');
        this.writer.endTd();

        if(child.value instanceof Piece){
          this.writer.beginTd();
          const resourceName = child.childs[1].value instanceof Interval ? 'for' : 'if';
          this.writer.appendText(this.culture.getString(`MathML.${resourceName}`));
          this.writer.appendSpace();
          this.writer.width = '1em';
          this.writeNode(child.childs[1]);
          this.writeOperator(';');
          this.writer.endTd();
        }else{
          this.writer.beginTd();
          this.writer.appendText(this.culture.getString('MathML.otherwise'));
          this.writer.endTd();
        }

        this.writer.endTr();
      }

      this.writer.endTable();
      this.writer.endFenced();
    }else if(node.numChildren === 1){
      if(node.firstChild.value instanceof Otherwise){
        this.writeNode(node.firstChild.firstChild);
      }
    }
  }

  /**
   *
   */
  private writeApply(node:Node):void{
    if(node.numChildren === 0){
      this.writer.beginRow();
      this.writer.endRow();
      return;
    }

    const func:FunctionElement = <FunctionElement>node.childs[0].value ;

    if(func instanceof ItemAt){
      this.writer.beginSub();
      this.writeNode(node.childs[1]);
      this.writeNode(node.childs[2]);
      this.writer.endSub();
    }else if(func instanceof Abs){
      this.writer.beginRow();
      this.writeOperator('|');
      this.writeNode(node.childs[1]);
      this.writeOperator('|');
      this.writer.endRow();
    }else if(func instanceof Sqrt){
      this.writer.beginSqrt();
      this.writeNode(node.childs[1]);
      this.writer.endSqrt();
    }else if(func instanceof NthRoot && this.argumentsPair(node)){
      const rootContent:ContentElement[] = this.argumentsPair(node);
      this.writer.beginRoot();
      this.writeLeaf(rootContent[0]);
      this.writeLeaf(rootContent[1]);
      this.writer.endRoot();
    }else if((func.getAttributes() & Attributes.FUNCTION_MODEL) > 0){
      const functionName:string = func.displayName ? func.displayName : '';
      this.writer.beginRow();
      this.writer.appendIdentifier(functionName);
      this.writeOperator('\u2061'); // apply function
      this.writer.beginFenced();
      this.writeChilds(node, 1);
      this.writer.endFenced();
      this.writer.endRow();
    } else if(func instanceof Divide){
      const div:Divide = <Divide>func ;
      if(div.fractionLike){
        this.writer.beginFraction();
        if(node.className === 'right'){
          this.writer.numalign = 'right';
          this.writer.denomalign = 'right';
        }
        this.writeChildsFixed(node, [1, 2], [false, false]);
        this.writer.endFraction();
      }else{
        this.writer.beginRow();
        this.writeChildsFixed(node, [1, 0, 2], [false, false, false]);
        this.writer.endRow();
      }
    }else if(func instanceof Power){
      this.writer.beginSup();
      this.writeChildsFixed(node, [1, 2], [true, false]);
      this.writer.endSup();
    }else{

      const invisibleTimesBefore:boolean = this.isInvisibleTimes(node.childs[0]);

      // Raise enclose negative flag if the preceding operator is +, -, *, /
      const encloseNegative:boolean = this.raiseEncloseNegative(func);

      this.writer.beginRow();
      if(node.childs.length === 2){ // unary
        this.writeNode2(node.childs[0], false, false, false, true);
        this.writeNode(node.childs[1]);
      }else if(node.childs.length === 3){ // binary
        this.writeNode(node.childs[1]); // duplicate the operator between each arguments
        this.writeNode(node.childs[0]); // duplicate the operator between each arguments
        this.writeNode2(node.childs[2], encloseNegative, false, invisibleTimesBefore); // duplicate the operator between each arguments
      }else if(node.childs.length > 3){ // nary
        this.writeNode(node.childs[1]); // duplicate the operator between each arguments
        for(let i:number = 2 ; i < node.childs.length ; i++){
          this.writeNode(node.childs[0]); // duplicate the operator between each arguments
          this.writeNode2(node.childs[i], encloseNegative, false, invisibleTimesBefore);
        }
      }
      this.writer.endRow();
    }

  }

  /**
   * Returns a pair of argument if possible.
   */
  private argumentsPair(parent:Node):ContentElement[]{
    if(!parent.isSimple){
      return null;
    }

    const o:ContentElement[] = [];
    if(parent.numChildren === 3){
      // 0: function
      // 1: argument-1
      // 2: argument-2
      o.push(parent.childs[1].value);
      o.push(parent.childs[2].value);
    }else if(parent.numChildren === 2){
      // 0: function
      // 1: list with two items
      const list:ListElement =
        parent.childs[1].value instanceof ListElement ?
          <ListElement>parent.childs[1].value  :
          null;
      if(!list){
        return null;
      }
      if(list.count !== 2){
        return null;
      }
      o.push(list.getItemAt(0));
      o.push(list.getItemAt(1));
    }

    return o;
  }

  /**
   * Returns true if the current node is a list of arguments for a function.
   */
  private isFunctionArguments(node:Node):boolean{
    if(!node){
      return false;
    }
    const parent:Node = node.parent;
    if(		parent &&
        parent.value instanceof Apply &&
        parent.firstChild &&
        parent.firstChild.value instanceof FunctionElement){

      const func:FunctionElement = <FunctionElement>parent.childs[0].value ;
      if((func.getAttributes() & Attributes.FUNCTION_MODEL) > 0){
        return true;
      }
    }
    return false;
  }

  /**
   *
   */
  private raiseEncloseNegative(op:FunctionElement):boolean{
    if(!op){
      return false;
    }
    return op instanceof Times ||
      op instanceof Minus ||
      op instanceof Plus ||
      op instanceof Divide ||
      op instanceof Quotient;
  }

  /**
   *
   */
  private isInvisibleTimes(node:Node):boolean{
    if(node.value instanceof Times){
      if((<Times>node.value ).other === '\u2062'){
        return true;
      }
    }
    return false;
  }

  /**
   * Append every child from beginIndex to the end.
   */
  private writeChilds(
      parent:Node,
      beginIndex:number,
      endIndexArg:number = Number.MAX_SAFE_INTEGER):void{

    const endIndex = Math.min(endIndexArg, parent.childs.length);

    for(let i:number = beginIndex ; i < endIndex ; i++){
      this.writeNode(parent.childs[i]);
    }
  }

  /**
   * Append a list of childs in the same order defined by indices array.
   */
  private writeChildsFixed(
      parent:Node,
      indices:any[],
      encloseList:any[]):void{
    for(let i:number = 0 ; i < indices.length ; i++){
      const index:number = indices[i];
      const enclose:boolean = encloseList[i];
      this.writeNode2(parent.childs[index], enclose, enclose);
    }
  }

  /**
   *
   */
  private writeLeaf(
      value:ContentElement,
      node:Node = null,
      enclosed:boolean = false,
      encloseNegative:boolean = false,
      encloseComplex:boolean = false,
      prefixFormArg:boolean = false):void{
    let prefixForm = prefixFormArg;

    if(value instanceof WNumber){
      const wn:WNumber = <WNumber>value ;
      this.writeNumber2(wn.toNumber(), encloseNegative, wn.formatter);
    }else if(value instanceof WEval){
      this.writeLeaf(
        (<WEval>value ).evaluable.getUnderlyingElement(),
        node,
        enclosed,
        encloseNegative,
        encloseComplex,
        prefixForm);
    }else if(value instanceof WPolynomial){
      const poly:WPolynomial = <WPolynomial>value ;
      let powerBase:boolean = false;
      let operand:boolean = false;
      if(node){
        if(node.parent){
          if(node.parent.value instanceof Apply){
            const index:number = node.parent.childIndex(node);
            const operator:ContentElement = node.parent.childs[0].value;
            if(operator instanceof Times){
              operand = true; // wrap both sides
            }
            if(operator instanceof Minus){
              // only wrap if it's the right operand (binary or unary operation)
              if(index === 2 || (index === 1 && node.parent.numChildren === 2)){
                operand = true;
              }
            }
            if(operator instanceof Power && index === 1){
              powerBase = true; // wrap left operand
            }
          }
        }
      }

      this.writePolynomial2(poly, enclosed, operand, powerBase);
    }else if(value instanceof WList){
      this.writeNumbersList(<WList>value , node);
    }else if(value instanceof ListElement){
      const list:ListElement = (<ListElement>value );
      list.formatter.writeTo(
        this,
        (<ListElement>value ).items,
        this.isFunctionArguments(node),
        list.useUnambiguousItemSeparator());
    }else if(value.writeTo(null)){

      const useFence:boolean = encloseComplex && (value.getAttributes() & Attributes.COMPLEX_CONTENT) > 0;
      if(useFence){
        this.writer.beginFenced();
      }

      value.writeTo(this);

      if(useFence){
        this.writer.endFenced();
      }

    }else if(value.toText(true)){
      let str:string = value.toText(true);
      if(str.length > this.maxStringLength){
        str = XString.truncateMiddle(str, this.maxStringLength);
      }
      str = this.format.translateString(str);
      this.writer.appendText(str);
    }else if(value instanceof FunctionElement){
      if(value instanceof UserFunction){
        this.writer.appendText((<UserFunction>value ).displayName);
      }else if(value instanceof AnonymousFunction) {
        this.writeNode((<AnonymousFunction>value ).node);
      }else if(value instanceof RecallFunction){
        this.writer.appendText('@');
      }else{

        if(node){
          if(value instanceof Minus){
            if(node.parent.childs.length === 2){
              prefixForm = true;
            }
          }
        }

        let swapOperator:string = null;

        if(node){
          if(this.isInvisibleTimes(node)){
            if(this.revealInvisibleTimes(node)){
              swapOperator = Times.CROSS;
            }
          }
        }

        if(swapOperator){
          this.writeOperator(swapOperator);
        }else{
          this.writeOperator(String(<FunctionElement>value ));
        }

        if(prefixForm){
          this.writer.form = 'prefix';
        }

      }

    }else{
      this.writer.beginRow();
      this.writer.endRow();
    }
  }

  /**
   *
   */
  private revealInvisibleTimes(node:Node):boolean{
    // node is the invisible time node, parent is the apply node
    // times operator can have 2-n arguments, if they are all
    // numeric, we reveal the invisible times.
    for(let i:number = 1 ; i < node.parent.childs.length ; i++){
      if(!(node.parent.childs[i].value instanceof RealElement)){
        return false;
      }
    }
    return true;
  }

  /**
   *
   */
  private writeNumbersList(list:WList, node:Node):void{
    const noParenthesis:boolean = this.isFunctionArguments(node);
    if(!list.formatter2.writeTo(this, list.items, noParenthesis, true)){
      this.writer.appendText(
        list.formatter2.toText(
          list.items,
          true,
          noParenthesis,
          true));
    }
  }

  /**
   *
   */
  public appendItemSeparatorSpace():void{
    this.writer.appendSpace();
    this.writer.width = '20px';
  }

  /**
   *
   */
  public writePolynomial2(
      polynomial:WPolynomial,
      enclosed:boolean, // Indicates that this polynomial is already enclosed by another routine, so don't enclose at this level.
      operand:boolean,
      powerBase:boolean):void{

    let fence:boolean = false;

    if(enclosed){
      // No fence, already enclosed
    }else if(operand){
      // If the polynomial is an operand of a multiplication operation then
      // fence it if the monomials count is greater than one
      if(polynomial.numMonomials > 1){
        fence = true;
      }
    }else if(powerBase){
      // If the polynomial is the base of an exponent operation then fence it if
      // it has more than one variable or if the power of the single variable
      // if greater than one
      if(polynomial.symbols.length > 1){
        fence = true;
      }else if(polynomial.numMonomials > 1){
        fence = true;
      }else if(polynomial.power(0, 0) > 1){
        fence = true;
      }
    }

    if(fence){
      this.writer.beginFenced();
      this.writer.separators = '';
    }

    polynomial.writeTo(this);

    if(fence){
      this.writer.endFenced();
    }
  }

  /**
   *
   */
  public writeOperator(value:string):void{
    /*
    if(value == "\u2062"){
      value = "&it;"; // &InvisibleTimes;
    }
    */

    this.writer.appendOperator(value);
    if(this.currentExpression){
      if(this.currentExpression.lineBreakOperator != null){
        this.writer.linebreak = value === this.currentExpression.lineBreakOperator ? 'goodbreak' : 'badbreak';
      }
    }
  }

  /**
   *
   */
  public listSeparator(hasComma:boolean):string{
    return this.culture.listFormatter.outputSeparator(hasComma ? ',' : '');
  }

  /**
   *
   */
  public writeNumber(value:number):void{
    return this.writeNumber2(value, false, null);
  }

  /**
   *
   */
  private writeNumber2(
      value:number,
      encloseNegative:boolean = false,
      formatterArg:BaseNumberFormatter = null):void{

    let formatter = formatterArg;
    if(!formatter){
      formatter = this.culture.numberFormatter;
    }

    let n:string = formatter.toLocaleString(value);
    n = this.format.translateString(n);

    if(encloseNegative && value < 0 && this.format.encloseNegative){
      n = `(${n})`;
    }

    this.writer.appendNumber(n);
  }

  /**
   *
   */
  public writeIdentifier(
      value:string):void{

    this.writer.appendIdentifier(value);

    if(this._format.noItalic){
      this.writer.mathvariant = 'normal';
    }
  }

  /**
   *
   */
  public writeParagraph(
      value:string,
      maxCharsPerLine:number):void{

    const lines:any[] =
      XString.wordWrap(
        value,
        maxCharsPerLine);

    this.writer.beginStyle();
    this.writer.mathcolor = '#4f4f4f';

    for(let i:number = 0 ; i < lines.length ; i++){
      if(i > 0){
        this.writer.lineBreak();
      }
      this.writer.appendText(lines[i]);
    }

    this.writer.endStyle();
  }

  /**
   *
   */
  public writeToken(value:TokenElement):void{
    return this.writeLeaf(value);
  }

  /**
   *
   */
  public writePolynomial(value:WPolynomial):void{
    return this.writePolynomial2(value, false, false, false);
  }

  /**
   *
   */
  public writeReal(value:RealElement):void{
    if(!value.writeTo(this)){
      if(value.toText(true)){
        this.writer.appendNumber(value.toText(true));
      }else{
        this.writer.appendNumber(this.culture.formatNumber(value.toNumber()));
      }
    }
  }

  /**
   *
   */
  public writeText(value:string):void{
    this.writeTextAdvanced(value, this.maxStringLength);

  }

  /**
   *
   */
  private writeTextAdvanced(
      valueArg:string,
      maxLength:number):void{
    let value = valueArg;

    if(value.length === 0){
      this.writer.appendSpace();
      this.writer.width = '0em';
      return;
    }

    value = this.format.translateString(value);

    if(value.length > maxLength){
      value = XString.truncateMiddle(value, maxLength);
    }

    if(value.length === 1 && value === ' ' ) {
      value = '\u00A0';
    } else if(value.length > 0){
      if(value.charAt(0) === ' '){
        value = `\u00A0${value.substring(1)}`;
      }
      if(value.charAt(value.length - 1) === ' '){
        value = `${value.substring(0, value.length - 1)}\u00A0`;
      }
    }

    this.writer.appendText(value);
  }

  public beginWriteSet():void{
    this.writer.beginFenced(this.format.setEnclose ? '{' : '', this.format.setEnclose ? '}' : '');
  }

  public writeEmptySet():void{
    this.writer.appendIdentifier(this.format.emptySetNotation);
  }

  public beginWriteList():void{
    this.writer.beginFenced();
    if(this.format.listOpen != null){
      this.writer.open = this.format.listOpen;
    }
    if(this.format.listClose != null){
      this.writer.close = this.format.listClose;
    }
  }

}
