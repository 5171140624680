import { Point } from '../../../js/geom/Point';

import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { EdgeData } from '../../elements/models/graphs/EdgeData';
import { WGraph } from '../../elements/tokens/WGraph';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';

/**
 *
 */
export class InDegreeMatrix extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 1){
      return args.expectingArguments(1, 1);
    }

    if (args.getGraph(0)) {
      return this.matrix(args.getGraph(0), args.env);
    }

    return null;
  }

  /**
   *
   */
  private matrix(graph:WGraph, env:Environment):WMatrix{
    let i:number;
    const o:number = graph.vertices.length;
    const m:number[] = [];

    for(i = 0 ; i < o * o ; i++){
      m.push(0);
    }

    for(i = 0 ; i < graph.edges.length ; i++){
      const edge:EdgeData = graph.edges[i];
      if(edge.directed){
        const v:Point = edge.vertices;
        m[v.x * o + v.x]++;
      }
    }

    return env.culture.createMatrix(m, o);
  }

}
