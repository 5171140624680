import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WBoolean } from '../../elements/tokens/WBoolean';
import { WError } from '../../elements/tokens/WError';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Indicates if the value is an error.
 */
export class IsError extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 1){
      return args.expectingArguments(1, 1);
    }
    if(args.getContentElement(0)){
      return WBoolean.parse(args.getContentElement(0) instanceof WError);
    }
    return null;
  }

}
