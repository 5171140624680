import { IDictionary } from '../../../../js/utils/IDictionary';
import { BaseTimeFormatter } from '../../../elements/formats/BaseTimeFormatter';
import { TimeModel } from '../../../elements/models/TimeModel';
import { CultureInfo } from '../../../localization/CultureInfo';
import { TimeFormatUtil } from '../../../elements/formats/time/TimeFormatUtil';

/**
 *
 */
export class SemicolonTimeOfDayFormatter extends BaseTimeFormatter {

  /**
   *
   */
  constructor(
      culture:CultureInfo,
      twelveHoursClock:boolean){
    super(culture, twelveHoursClock);
  }

  /**
   *
   */
  public toLocaleString(value:TimeModel):string{
    const suffix:string = this.useTwelveHoursClock() ? '12' : '24';

    const hours: number = this.useTwelveHoursClock() ? TimeFormatUtil.hours12hourclock(value.hours) : value.hours;

    const resourceKey =
      (value.seconds === 0 && value.undefinedSeconds ?
        'hmsemicolonformat' + suffix :
        'hmssemicolonformat' + suffix);

    let time =
      this.culture.getString(
        `Time.${resourceKey}`,
        this.useTwelveHoursClock() ?
          hours :
          TimeFormatUtil.timePart(hours),
        TimeFormatUtil.timePart(value.minutes),
        TimeFormatUtil.timePart(value.seconds),
        this.culture.getString(value.hours < 12 ? 'Time.amLabel' : 'Time.pmLabel'));

    // Replace all spaces by non-breaking spaces
    time = time.split(' ').join('\u00A0');

    return time;
  }

  /**
   *
   */
  public fromLocaleString(value:string):TimeModel{
    const suffix:string = this.useTwelveHoursClock() ? '12' : '24';
    const patterns:IDictionary = this.getPatterns();
    return TimeModel.tryParse(
      value,
      patterns['hmsemicolonparser' + suffix],
      patterns['hmssemicolonparser' + suffix],
      this.useTwelveHoursClock());
  }

  /**
   *
   */
  private getPatterns():IDictionary{
    const o:IDictionary = {};
    if(this.useTwelveHoursClock()){
      o['hmsemicolonparser12'] = '^([0-9]{1,2}):([0-9]{2})(a\\.m\\.|am|p\\.m\\.|pm)$';
      o['hmssemicolonparser12'] = '^([0-9]{1,2}):([0-9]{2}):([0-9]{2})(a\\.m\\.|am|p\\.m\\.|pm)$';
      o['hmsemicolonparser24'] = '^([0-9]{1,2}):([0-9]{2})$';
      o['hmssemicolonparser24'] = '^([0-9]{1,2}):([0-9]{2}):([0-9]{2})$';
    }else{
      o['hmsemicolonparser12'] = '^([0-9]{2}):([0-9]{2})(a\\.m\\.|am|p\\.m\\.|pm)$';
      o['hmssemicolonparser12'] = '^([0-9]{2}):([0-9]{2}):([0-9]{2})(a\\.m\\.|am|p\\.m\\.|pm)$';
      o['hmsemicolonparser24'] = '^([0-9]{2}):([0-9]{2})$';
      o['hmssemicolonparser24'] = '^([0-9]{2}):([0-9]{2}):([0-9]{2})$';
    }
    return o;
  }

}
