import { Point } from '../../../../js/geom/Point';
import { XRound } from '../../../core/XRound';
import { XString } from '../../../core/XString';
import { ContentElement } from '../../../elements/abstract/ContentElement';
import { RealElement } from '../../../elements/abstract/RealElement';
import { CultureInfo } from '../../../localization/CultureInfo';
import { AlternateForm } from '../../../expr/manipulation/alt/AlternateForm';

/**
 * http://en.wikipedia.org/wiki/Scientific_notation
 */
export class ScientificNotation extends AlternateForm {

  /**
   *
   */
  constructor(culture:CultureInfo){
    super(culture);
  }

  /**
   *
   */
  public alt(value:ContentElement):string{
    if(!(value instanceof RealElement)){
      return null;
    }
    const n:number = (<RealElement>value ).toNumber();
    if(isNaN(n) || n === 0){
      return null; // a number?
    }
    const sc:Point = ScientificNotation.parse(n);
    return XString.substitute('{0}×10^{1}', sc.x, sc.y);
  }

  /**
   * {x, y} --> {mantissa, exponent}
   */
  public static parse(value:number):Point{
    let n:number = value;
    if(n === 0){
      return new Point(0, 0);
    }

    let e:number = 0;
    const negative:boolean = n < 0;
    n = Math.abs(n);

    while(n < 1){
      n *= 10;
      e--;
    }

    while(n >= 10){
      n /= 10;
      e++;
    }

    n = XRound.safeRound(n);

    if(negative){
      n = -n;
    }
    return new Point(n, e);
  }

}
