import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { ListElement } from '../../elements/abstract/ListElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';

/**
 * Return the positions at which the element has been found.
 * Retourne la ou les positions d'une éléments dans une liste.
 * La position 1 correspond au premier élément de la liste.
 * Retourne -1 si l'élément ne se trouve pas dans la liste.
 */
export class Find extends FunctionElement {
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 2) {
      return args.expectingArguments(2, 2);
    }

    const [list, element] = [args.getList(0), args.getContentElement(1)];
    if (list && element) {
      return this.list(list, element, args.env);
    }

    return null;
  }

  /**
   * Return -1 if item is not in the list
   * Return the position if found
   * Return a list of positions if found multiple times
   */
  private list(list: ListElement, item: ContentElement, env: Environment): ContentElement {
    const ordinals: number[] = [];
    const number = this.getNumberFromItem(item);

    for (let i = 0; i < list.count; i++) {
      const isSameNumber = number && number === RealElement.parseElement(list.getItemAt(i))?.toNumber();
      const isSameList = list.getItemAt(i).equalsTo(item);
      if (isSameNumber || isSameList) {
        ordinals.push(i + 1);
      }
    }

    if (ordinals.length < 2) {
      return env.culture.createNumber(ordinals.length === 1 ? ordinals[0] : -1);
    }

    return env.culture.listFactory.createFromNumbers(ordinals);
  }

  /**
   * Return the number from the item.
   * If the item is a list of 1 item, return the number from the first item.
   *
   * @param item
   * @private
   */
  private getNumberFromItem(item: ContentElement): number {
    const number = RealElement.parseElement(item)?.toNumber();
    if (number) {
      return number;
    }

    const list = item as ListElement;
    if (list && list.count === 1) {
      return this.getNumberFromItem(list.getItemAt(0));
    }

    return null;
  }
}
