import { TriangleAssert } from '../../../../funcs/asserts/geom/triangles/TriangleAssert';

/**
 * Est un triangle isoangle?
 */
export class IsIsoangle extends TriangleAssert {

  /**
   *
   */
  protected assertTrue(
      angles:number[],
      edges:number[]):boolean{

    return 	angles[0] === angles[1] ||
        angles[1] === angles[2] ||
        angles[2] === angles[0];
  }

}
