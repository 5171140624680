import { MathError } from '../../../core/MathError';
import { FxPoly } from '../../../elements/effofeks/FxPoly';
import { Zeros } from '../../../elements/functions/Zeros';
import { IFunctionAttributes } from '../../../elements/markers/IFunctionAttributes';
import { WLine } from '../../../elements/tokens/WLine';
import { WPolynomial } from '../../../elements/tokens/WPolynomial';
import { WQuadratic } from '../../../elements/tokens/WQuadratic';
import { CultureInfo } from '../../../localization/CultureInfo';
import { FunctionStyles } from '../../../elements/functions/adapters/FunctionStyles';
import { EvaluableAdapter } from '../../../elements/functions/adapters/EvaluableAdapter';

/**
 *
 */
export class PolynomialAdapter extends EvaluableAdapter implements IFunctionAttributes {

  private _poly:WPolynomial;

  private culture:CultureInfo;

  constructor(
      poly:WPolynomial,
      culture:CultureInfo) {

    super(new FxPoly(poly));
    this._poly = poly;
    this.culture = culture;
  }

  public get continuous():number{return 1;}

  public get type():string{
    return this._poly.linear ? FunctionStyles.LINEAR : FunctionStyles.UNKNOWN;
  }

  public get attributes():IFunctionAttributes{return this;}

  public getZeros():Zeros{
    const z:Zeros = new Zeros();
    z.list = [];
    const line:WLine = WLine.parsePolynomial(this._poly, this.culture.formats.lineFormatImpl);
    if(line){
      if(line.xintercept){
        z.list.push(line.xintercept.toNumber());
      }
    }else{
      const quad:WQuadratic = WQuadratic.parsePolynomial(this._poly, this.culture.formats.quadraticFormatImpl);
      if(quad){
        for(let i:number = 0 ; i < quad.xintercept.length ; i++){
          z.list.push(quad.xintercept[i]);
        }
      }
    }

    throw new MathError('Not implemented');
  }

  public getHashCode(): string {
    return this._poly.hashCode();
  }

}
