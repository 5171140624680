import { Point } from '../../js/geom/Point';

import { XRound } from '../core/XRound';
import { XString } from '../core/XString';
import { ContentElement } from '../elements/abstract/ContentElement';
import { Node } from '../elements/abstract/Node';
import { WPoint } from '../elements/tokens/WPoint';
import { IWriter } from '../expr/conversion/writers/IWriter';
import { KeyboardConfiguration } from './KeyboardConfiguration';
import { CommonError } from './CommonError';
import { NumberOrPercent } from './NumberOrPercent';
import { BaseCorrector } from './BaseCorrector';

/**
 *
 */
export class CPoint extends BaseCorrector {

  private plusMinus:NumberOrPercent;

  constructor(plusMinus:NumberOrPercent){
    super();
    this.plusMinus = plusMinus;
  }

  public parse(value:string):Node{
    try{
      const pt:Point = this.parsePoint(this.translateInput(value));
      if(pt){
        const node:Node = new Node(this.env.culture.parsePoint(pt));
        node.userData = 'Point(' + String(pt.x) + ', ' + String(pt.y) + ')';
        return node;
      }
    }catch(e){
      return null;
    }
    return null;
  }

  public correct(
      value:string,
      target:ContentElement,
      ...targets:any[]):boolean{

    const source:Point = this.parsePoint(this.translateInput(value));

    if(!source){
      this.raiseError(
        CommonError.POINT_FORMAT_INVALID,
        [this.env.culture.numberFormatter.decimalSeparator]);
    }

    const target2:Point = (<WPoint>target ).toPoint();

    if(source.equals(target2)){
      return true;
    }

    if(this.plusMinus != null){
      const dx:number = XRound.safeRound(Math.abs(source.x - target2.x));
      const dy:number = XRound.safeRound(Math.abs(source.y - target2.y));
      const dx_max:number = this.plusMinus.getAbsoluteValue(target2.x);
      const dy_max:number = this.plusMinus.getAbsoluteValue(target2.y);
      return  dx <= dx_max && dy <= dy_max;

    }

    return false;
  }

  public parsePoint(valueArg:string):Point{
    let value = XString.trim(valueArg);

    if(		value.charAt(0) === '(' && value.charAt(value.length - 1) === ')'){

      value = value.substring(1, value.length - 1);

      const values:number[] = this.parseList(value);
      if(values){
        if(values.length === 2){
          return new Point(
              values[0],
              values[1]);
        }
      }
    }

    return null;
  }

  private translateInput(value:string):string{
    if(this.useLatex){
      return this.sanitizeInput(value);
    }
    return value;
  }

  private parseList(
      value:string):number[]{

    const listSeparator:string = this.env.culture.listFormatter.inputSeparator(value);
    const temp:any[] = value.split(listSeparator);

    const items:number[] = [];

    for(let i:number = 0 ; i < temp.length ; i++){
      const n:string = temp[i];
      // do not accept empty x or y component
      if(!XString.hasDigits(n)){
        return null;
      }
      this.checkDecimalAndThousandSeparator(n);
      const item:number = this.numberParser.parseNumber(n);
      if(isNaN(item)){
        this.raiseError(CommonError.NAN);
      }
      items.push(item);
    }

    return items;
  }

  public writeTo(
      w:IWriter,
      target:ContentElement,
      ...targets:any[]):void{

    const point:Point = (<WPoint>target ).toPoint();
    const x:string = this.env.culture.formatNumber(point.x);
    const y:string = this.env.culture.formatNumber(point.y);
    w.writeRaw('(');
    w.writeNumber(point.x);
    w.writeRaw(`${this.env.culture.listFormatter.outputSeparator(x + y)} `);
    w.writeNumber(point.y);
    w.writeRaw(')');
  }

  public get mathKeyboard():number{
    return KeyboardConfiguration.NUMERIC;
  }

}
