import { AbstractNode } from '../../../elements/functions/tree/AbstractNode';

/**
 *
 */
export class NodeVariable extends AbstractNode {

  private _name:string = '';

  constructor( name:string ){
    super();
    this._name = name;
  }

  public getToken():any[] {
    return [this._name];
  }

  public simplify():AbstractNode {
    return this;
  }
}
