import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WNumber } from '../../elements/tokens/WNumber';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';

/**
 * Return the digit of a number.
 */
export class Digit extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length < 1 || args.length > 2){
      return args.expectingArguments(1, 2);
    }

    if(args.getReal(0) && args.getReal(1)){
      return this.digitAt(args.getReal(0), args.getReal(1), args.env);
    }

    return null;
  }

  /**
   * Return the digit at specific position. (Unit --> 0, tenths --> -1, tens --> 1, etc...)
   */
  private digitAt(
      value:RealElement,
      position:RealElement,
      env:Environment):WNumber{

    if(!position.isInteger()){
      return null;
    }

    const digit0:WNumber = env.culture.createNumber(0);

    const s:string = String(value.toNumber());
    let p:number = position.toNumber();
    if(p < 0){
      if(s.indexOf('.') !== -1){
        const decimals:any[] = String(s.split('.')[1]).split('');
        p = Math.abs(p) - 1;
        return p < decimals.length ? env.culture.createNumber(parseFloat(decimals[p])) : digit0;
      }
      return digit0;
    }

    let integers:any[];
    if(s.indexOf('.') !== -1){
      integers = String(s.split('.')[0]).split('');
    }else{
      integers = s.split('');
    }

    integers = integers.reverse();
    return p < integers.length ? env.culture.createNumber(parseFloat(integers[p])) : digit0;
  }

}
