import { ContentElement } from '../../elements/abstract/ContentElement';
import { ElementCodes } from '../../elements/abstract/ElementCodes';
import { OperatorElement } from '../../elements/abstract/OperatorElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { TokenElement } from '../../elements/abstract/TokenElement';
import { WBoolean } from '../../elements/tokens/WBoolean';
import { WFiniteSet } from '../../elements/tokens/WFiniteSet';
import { WInterval } from '../../elements/tokens/WInterval';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Élément de?
 */
export class ElementOf extends OperatorElement {

  /**
   *
   */
  public toString():string{return '∈';}

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 2){
      return args.expectingArguments(2, 2);
    }

    if(args.getReal(0) && args.getInterval(1)){
      return this.elementOfInterval(args.getReal(0), args.getInterval(1));
    }
    if(args.getTokenElement(0) && args.getFiniteSet(1)){
      return this.elementOfSet(args.getTokenElement(0), args.getFiniteSet(1));
    }

    return null;
  }

  /**
   *
   */
  private elementOfInterval(
      value:RealElement,
      interval:WInterval):WBoolean{

    return WBoolean.parse(interval.contains(value.toNumber()));
  }

  /**
   *
   */
  private elementOfSet(
      value:TokenElement,
      finiteSet:WFiniteSet):WBoolean{

    return WBoolean.parse(finiteSet.contains(value));
  }

  /**
   *
   */
  public getElementCode():string{
    return ElementCodes.OP_ELEMENT_OF;
  }

}
