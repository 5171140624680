import { MmlWriter } from '../../../core/mml/MmlWriter';
import { Node } from '../../../elements/abstract/Node';
import { RealElement } from '../../../elements/abstract/RealElement';
import { SymbolElement } from '../../../elements/abstract/SymbolElement';
import { TokenElement } from '../../../elements/abstract/TokenElement';
import { Apply } from '../../../elements/constructs/Apply';
import { IMarkupExporter } from '../../../elements/markers/IMarkupExporter';
import { WFactors } from '../../../elements/tokens/WFactors';
import { WPolynomial } from '../../../elements/tokens/WPolynomial';
import { Environment } from '../../../expr/Environment';
import { Minus } from '../../../funcs/arithmetic/Minus';
import { Plus } from '../../../funcs/arithmetic/Plus';
import { FactorizeStepSimplified } from '../../../funcs/factorization/utils/FactorizeStepSimplified';
import { FactorizeStepDefault } from '../../../funcs/factorization/utils/FactorizeStepDefault';
import { IFactorizeStepDecorator } from '../../../funcs/factorization/utils/IFactorizeStepDecorator';

/**
 *
 */
export class FactorizeUtil {

  /**
   *
   */
  private env:Environment;

  /**
   *
   */
  constructor(env:Environment){
    this.env = env;
  }

  /**
   *
   */
  private static DOUBLE_COMBINAISONS:any[] = [ [
    [ 0, 1 ], [ 2, 3 ],
    [ 0, 2 ], [ 1, 3 ],
    [ 0, 3 ], [ 1, 2 ],
  ] ];

  /**
   *
   */
  public simple( poly:WPolynomial ):any[] {
    if( !poly.isSimplified() ) {
      return [ poly, new WFactors( this.env.polynomials.simplify(poly) ) ];
    }
    return null;
  }

  /**
   *
   */
  public double( polyArg:WPolynomial ):any[] {
    let poly = polyArg;
    let i:number = 0;
    let data:any[];
    let coef:TokenElement = null;
    const result:any[] = [];
    let decorator:IFactorizeStepDecorator = new FactorizeStepDefault();

    result.push( poly );

    if( !poly.isSimplified() ) {
      const simplified:TokenElement[] = this.env.polynomials.simplify(poly);

      coef = simplified[0];
      poly = <WPolynomial>simplified[1] ;

      decorator = new FactorizeStepSimplified( coef );

      result.push( new WFactors( (<TokenElement[]>[ coef, poly ] ) ) );
    }

    /**
     * Try every combinaison for double.
     */
    do {

      const a:WPolynomial = poly.extractMonomials( FactorizeUtil.DOUBLE_COMBINAISONS[i][0] );
      const b:WPolynomial = poly.extractMonomials( FactorizeUtil.DOUBLE_COMBINAISONS[i][1] );

      data = this.processDouble( decorator, a, b );

      i++;
    } while( data == null && i < FactorizeUtil.DOUBLE_COMBINAISONS.length );

    if( data == null ){
      return null;
    }

    return result.concat( data );
  }

  public doubleResult( coef:TokenElement, poly:WPolynomial ):TokenElement[] {
    let operations:any[] = [];

    operations = this.double( poly );

    if( operations != null && operations.length > 0 && operations[ operations.length - 1 ] instanceof WFactors ) {

      return ( <WFactors>operations[ operations.length - 1 ]  ).toFactors();
    }

    return null;
  }

  private processDouble( decorator:IFactorizeStepDecorator, p1:WPolynomial, p2:WPolynomial ):any[] {
    const result:any[] = [];

    const s1:TokenElement[] = this.env.polynomials.simplify(p1);
    const s2:TokenElement[] = this.env.polynomials.simplify(p2);

    // Should have 4 possibilities
    for(let i:number = 0 ; i < s1.length ; i++){
      for(let j:number = 0 ; j < s2.length ; j++){

        /** Special case for double evidence, if the first polynomial is equals to the opposite of the second
         * polynomial, we need to change the sign of is factor and use opposite for polynomial.
         *
         * Example : (2x)(x-1) become (-2x)(-x+1)
         */
        if( s1[i] instanceof WPolynomial
          && s2[j] instanceof WPolynomial
          && s1[i].equalsTo(( <WPolynomial>s2[j]  ).toOpposite() )) {

          s2[j] = this.opposite( s2[j] );

          s2[j-1] = this.opposite( s2[j-1] );
        }

        if(s1[i].equalsTo(s2[j])  ){

          const coef:TokenElement = this.absoluteValue( s2[0] );
          const negative:boolean = this.isNegative( s2[0] );
          const operator:Node = ( negative ) ? new Node( new Minus() ) : new Node( new Plus() );

          const node:Node = new Node( new Apply()
              , operator
              , new Node( new WFactors( (<TokenElement[]>[ s1[0], s1[1] ] ) ) )
              , new Node( new WFactors( (<TokenElement[]>[ coef, s2[1] ] ) ) )
             );

          result.push( decorator.decorate( node ) );

          const o:TokenElement[] = [];
          o.push(s1[i]);
          o.push(this.env.expressions.add(s1[i === 0 ? 1 : 0], s2[j === 0 ? 1 : 0]));

          result.push( decorator.decorate( new WFactors( o.concat() ) ) );

          if( o[1] instanceof WPolynomial && !( (<WPolynomial>o[1]  ).isSimplified() ) ) {
            const tp:TokenElement[] = this.env.polynomials.simplify(<WPolynomial>o[1] );
            o.pop();

            // Add coefficient at start
            o.unshift( tp[0] );

            // Add binome at end.
            o.push( tp[1] );

            result.push( decorator.decorate( new WFactors( o ) ) );
          }

          return result;
        }
      }
    }

    return null;
  }

  private opposite( element:TokenElement ):TokenElement {
    // Change factor.
    if( element instanceof RealElement ) {
      return (<RealElement>element ).toOpposite();
    }
    if( element instanceof SymbolElement ) {
      const p:WPolynomial = <WPolynomial>( <SymbolElement>element  ).widen() ;
      return p.toOpposite();
    }
    if( element instanceof WPolynomial ) {
      return ( <WPolynomial>element  ).toOpposite();
    }
    return null;
  }

  private isNegative( element:TokenElement ):boolean {
    if( element instanceof RealElement ) {
      return ( <RealElement>element  ).toNumber() < 0;
    }
    if( element instanceof WPolynomial || element instanceof SymbolElement ) {
      const p:WPolynomial = ( element instanceof SymbolElement ) ?
        <WPolynomial>( <SymbolElement>element  ).widen()  :
        <WPolynomial>element ;

      return p.coefs.length > 0 && p.coefs[0].toNumber() < 0;
    }

    return false;
  }

  private absoluteValue( element:TokenElement ):TokenElement {
    let p:WPolynomial;

    if( element instanceof RealElement ) {
      return ( <RealElement>element  ).toAbsoluteValue();

    }
    if( element instanceof SymbolElement || element instanceof WPolynomial ) {
      p = element instanceof SymbolElement ?
        <WPolynomial>( <SymbolElement>element  ).widen()
        : <WPolynomial>element ;

      return ( p.coefs[0].toNumber() < 0 ) ? p.toOpposite() : p;
    }

    return null;
  }

  public writeTo( exporter:IMarkupExporter, operations:any[] ):void {
    const writer:MmlWriter = exporter.writer;
    writer.beginRow();

    for(let i:number = 0 ; i < operations.length ; i++) {
      const e:Object = operations[i];
      if( e instanceof TokenElement ) {
        (<TokenElement>e ).writeTo(exporter);
      } else if( e instanceof Node ) {
        exporter.writeNode(<Node>e );
      }
      writer.appendSpace();
      writer.linebreak = 'newline';
    }

    writer.endRow();
  }

}
