import React from 'react';
import styled from 'styled-components';
import { tagNameForDisplay } from './NameUtils';

export interface ITagLinkProps {
  readonly tagName: string;
  readonly useCount: number;
  readonly onSelectTag: (tagName: string) => void;
}

export const TagLink: React.FC<ITagLinkProps> = (
  {
    tagName,
    useCount,
    onSelectTag,
  },
) => {

  const onClick = () => onSelectTag(tagName);

  const tagDisplayName = tagNameForDisplay(tagName, true);

  return (
    <TagButton>
      <TagName
        onClick={onClick}
      >
        {tagDisplayName}
      </TagName>
      <TagUseCount>
        &nbsp;({useCount})
      </TagUseCount>
    </TagButton>
  );
};

const TagButton = styled.div`
  display: inline-block;
  margin-right: 4px;
  color: ${(props) => props.theme.colorset.grey07};
  :not(:last-child):after {
    content: ',';
  }
`;

const TagName = styled.span`
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  color: ${(props) => props.theme.colorset.primary};
`;

const TagUseCount = styled.span`
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
`;
