import { XNumber } from '../../../core/XNumber';
import { MmlWriter } from '../../../core/mml/MmlWriter';
import { RealElement } from '../../../elements/abstract/RealElement';
import { BaseMatrixFormatter } from '../../../elements/formats/BaseMatrixFormatter';
import { IMarkupExporter } from '../../../elements/markers/IMarkupExporter';
import { CultureInfo } from '../../../localization/CultureInfo';
import { ChessPieceFormatter } from '../../../elements/formats/chess/ChessPieceFormatter';

/**
 *
 */
export class ChessBoardFormatter extends BaseMatrixFormatter {

  /**
   *
   */
  constructor(culture:CultureInfo){
    super(culture);
  }

  /**
   *
   */
  public writeTo(exporter:IMarkupExporter, values:RealElement[], columns:number):boolean{
    let r:number;
    let c:number;
    let s:string[];

    const w:MmlWriter = exporter.writer;

    w.beginTable();
    for(r = 0 ; r < 8 ; r++){
      s = [];
      s.push(String.fromCharCode(7 - r + 49)); // Chiffres
      for(c = 0 ; c < 8 ; c++){
        const z:number = XNumber.truncate(values[r * columns + c].toNumber(), 0);
        if(z === 0){
          if(r % 2 === 0){
            s.push(c % 2 === 0 ? '□' : '■');
          }else{
            s.push(c % 2 === 0 ? '■' : '□');
          }
        }else{
          s.push(ChessPieceFormatter.figurine(z));
        }
      }
      this.writeLine(w, s.join('\u0009'));
    }

    // Lettres
    s = [];
    s.push('\u00A0');
    for(c = 0 ; c < 8 ; c++){
      s.push(String.fromCharCode(c + 97));
    }
    this.writeLine(w, s.join('\u0009'));
    w.endTable();

    return true;
  }

  /**
   *
   */
  private writeLine(writer:MmlWriter, s:string):void{
    writer.beginTr();
    writer.beginTd();
    writer.appendText(s);
    writer.endTd();
    writer.endTr();
  }

}
