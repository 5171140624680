import React from 'react';
import styled from 'styled-components';

interface IProps {
  readonly details?: Error;
}

export class Unauthorized extends React.Component<IProps> {
  public render(): JSX.Element {
    return (
      <Box data-testid="error-401">
        <Title>Unauthorized Request</Title>
        <Message>{this.generateDetails()}</Message>
      </Box>
    );
  }

  private generateDetails(): string {
    const { details } = this.props;
    if (details) {
      return details.message;
    }
    return 'You are not authorized to view this page.'
  }
}

const Box = styled.div`
  left: 50%;
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 450px;
`;

const Title = styled.h2`
  color: ${(props) => props.theme.colorset.penumbra};
  font: ${(props) => props.theme.typeset.h2Headline};
`;

const Message = styled.p`
  color: ${(props) => props.theme.colorset.grey800};
  font: ${(props) => props.theme.typeset.body1Regular};
`;
