import React from 'react';
import { useDraggable } from '@dnd-kit/core';

export interface IDragItemCopyProps {
  readonly id: string;
  readonly className?: string;
}

export const DragItemCopy: React.FC<IDragItemCopyProps> = (props) => {
  const { attributes, listeners, setNodeRef } = useDraggable({
    id: props.id,
  });

  return (
    <div
      ref={setNodeRef}
      className={props.className}
      {...listeners}
      {...attributes}
    >
      {props.children}
    </div>
  );
};
