import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { RealsImpl } from '../../elements/utils/RealsImpl';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Multiplication de matrices.
 */
export class MultiplyMatrices extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length < 2){
      return args.expectingArguments(2, Number.POSITIVE_INFINITY);
    }

    const matrices:WMatrix[] = [];

    for(let i:number = 0 ; i < args.length ; i++){
      const matrix:WMatrix = args.getMatrix(i);
      if(!matrix){
        return null;
      }
      matrices.push(matrix);
    }

    while(matrices.length > 1){
      const product:WMatrix = this.multiply(matrices.shift(), matrices.shift(), args.env.reals);
      if(!product){
        return null;
      }
      matrices.splice(0, 0, product);
    }

    return matrices[0];
  }

  /**
   *
   */
  private multiply(a:WMatrix, b:WMatrix, reals:RealsImpl):WMatrix{
    if(a.columns !== b.rows){
      return null;
    }

    const o:RealElement[] = [];

    for(let r:number = 0 ; r < a.rows ; r++){
      for(let c:number = 0 ; c < b.columns ; c++){
        let s:RealElement = null;
        for(let i:number = 0 ; i < a.columns ; i++){
          const p:RealElement = reals.times(a.valueAt(r, i), b.valueAt(i, c));
          if(!s){
            s = p;
          }else{
            s = reals.add(s, p);
          }
        }
        o.push(s);
      }
    }

    return new WMatrix(o, b.columns, a.formatter);
  }

}
