import { AbstractStep } from '../AbstractStep';
import { Compartment } from '../Compartment';
import { LongSubtractionOperation } from '../LongSubtractionOperation';
import { SubtractionModel } from '../models/SubtractionModel';

/**
 *
 */
export class SubtractEnd extends AbstractStep {

  private model:SubtractionModel;

  constructor(
      operation:LongSubtractionOperation){
    super(operation);
    this.model = operation.model;

    if(this.model.difference < 0){
      operation.tempResult = operation.result;
      operation.result = [];
      let c:Compartment = Compartment.createOperator('−');
      operation.result.push(c);
      this.target.push(c);
      for(let i:number = 0 ; i < operation.tempResult.length ; i++){
        c = operation.tempResult[i];
        this.source.push(c);
        const c2:Compartment = c.clone();
        this.target.push(c2);
        operation.result.push(c2);
      }
    }

    this.setDescription(
      operation.culture.getString(
        'LongSubtractionOperation.endLabel',
        operation.culture.formatNumber(this.model.operand1),
        operation.culture.formatNumber(this.model.operand2),
        operation.culture.formatNumber(this.model.difference)
      ));
  }

}
