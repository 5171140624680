import { MathError } from '../../core/MathError';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WTimeOfDay } from '../../elements/tokens/WTimeOfDay';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';

/**
 *
 */
export class TimeOfDay extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length < 2 || args.length > 3){
      return args.expectingArguments(2, 3);
    }

    const hours:RealElement = args.getWholeNumber(0);
    if(!hours){
      return null;
    }

    const minutes:RealElement = args.getWholeNumber(1);
    if(!minutes){
      return null;
    }

    const seconds:RealElement = args.length > 2 ? args.getWholeNumber(2) : args.env.culture.createNumber(0);
    if(!seconds){
      return null;
    }

    return this.create(
      hours.toNumber(),
      minutes.toNumber(),
      seconds.toNumber(),
      args.length === 2,
      args.env);
  }

  /**
   *
   */
  private create(
      hours:number,
      minutes:number,
      seconds:number,
      hideZeroSeconds:boolean,
      env:Environment):WTimeOfDay{

    if(hours > 23){
      throw new MathError('Hours part must be less than 24');
    }

    if(minutes > 59){
      throw new MathError('Minutes part must be less than 60');
    }

    if(seconds > 59){
      throw new MathError('Seconds part must be less than 60');
    }

    return new WTimeOfDay(
      hours,
      minutes,
      seconds,
      hideZeroSeconds,
      env.culture.formats.timeFormatImpl);
  }

}
