import React from 'react';
import styled from 'styled-components';
import { Button } from '@scolab/publisher-ui-kit';
import { FormattedMessage } from 'react-intl';
import { VariantCreationFormFields } from '../VariantCreationFormFields';

interface IValueCreationFormProps {
  initialValue: string;
  existingNames: ReadonlyArray<string>;
  onSave: (name: string, description: string) => void;
  onCancel: () => void;
}

export const ValueCreationForm: React.FC<IValueCreationFormProps> = (props) => {
  const {
    initialValue,
    existingNames,
    onSave,
    onCancel,
  } = props;
  const [name, setName] = React.useState(initialValue);
  const [description, setDescription] = React.useState('');
  const isNameAlreadyUsed = existingNames.includes(name.trim());
  return (
    <Container>
      <VariantCreationFormFields
        name={name}
        onChangeName={setName}
        description={description}
        onChangeDescription={setDescription}
        isNameAlreadyUsed={isNameAlreadyUsed}
      />
      <Row>
        <Button
          onClick={onCancel}
        >
          <FormattedMessage id="variants.cancel" />
        </Button>
        <Button
          onClick={() => onSave(name.trim(), description)}
          disabled={!name || isNameAlreadyUsed}
          primary
        >
          <FormattedMessage id="variants.save" />
        </Button>
      </Row>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Row = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-end;
`;
