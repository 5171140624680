/**
 *
 */
export class Operation {

  /**
   *
   */
  private static operators:any[] =
    ['pass',
     '+',
     '-',
     '/',
     '*',
     '^',
     'rad',
     'deg',
     'sin',
     'cos',
     'tan',
     'sqrt',
     'abs'];

  /**
   * Operation to perform.
   */
  public operator:number;

  /**
   * Where to get the input values.
   */
  public input:any[];

  /**
   * Where to put the result of the operation.
   */
  public output:number;

  /**
   *
   */
  public toString():string{
    return `${Operation.operators[this.operator]}(${this.input.join(', ')}) --> ${this.output}`;
  }

}
