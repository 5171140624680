import { TriangleAssert } from '../../../../funcs/asserts/geom/triangles/TriangleAssert';

/**
 * Est un triangle isocèle?
 */
export class IsIsosceles extends TriangleAssert {

  /**
   *
   */
  protected assertTrue(
      angles:number[],
      edges:number[]):boolean{

    return edges[0] === edges[1] || edges[1] === edges[2] || edges[2] === edges[0];
  }

}
