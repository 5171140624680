import { MmlWriter } from '../../core/mml/MmlWriter';
import { Attributes } from '../../elements/abstract/Attributes';
import { ElementCodes } from '../../elements/abstract/ElementCodes';
import { TokenElement } from '../../elements/abstract/TokenElement';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';

/**
 *
 */
export class WId extends TokenElement {

  private _id:string;

  public get id():string{return this._id;}

  public getId():string{return this._id;}

  /**
   *
   */
  constructor(id:string) {

    super();
    this._id = id;
  }

  /**
   *
   */
  public getAttributes():number{
    return super.getAttributes() | Attributes.ASSIGNABLE;
  }

  /**
   *
   */
  public writeTo(exporter:IMarkupExporter = null):boolean{
    if(exporter){
      const w:MmlWriter = exporter.writer;

      const r:RegExp = new RegExp('<(sub|sup)>(.+?)<\\/(sub|sup)>', 'gi');

      if(!r.test(this.id)){
        exporter.writeIdentifier(this.id);
        return true;
      }

      const o:any[] = this.id.replace(r, '\n$&\n').split('\n');
      while(o.length > 0){
        // Find sub/sup
        let i:number = 0;
        while(i < o.length){
          if(r.test(o[i])){
            break;
          }
          i++;
        }
        if(i >= o.length){
          // Write rest
          while(o.length > 0){
            w.appendIdentifier(o.shift());
          }
        }else{
          // Create sup or sub struct with the previous token as base.
          const base:string = i > 0 ? o[i - 1] : null;
          let script:string = o[i];

          if(i > 0){
            o.splice(i - 1, 2);
          }else{
            o.splice(i, 1);
          }

          const sub:boolean = script.indexOf('<sub>') !== -1;

          // open struct
          if(sub){
            w.beginSub();
          }else{
            w.beginSup();
          }

          script = script.replace(r, '$2');
          const n:number = Number(script);

          // base
          if(base){
            w.appendIdentifier(base);
          }else{
            w.appendSpace();
          }

          // script
          if(isNaN(n)){
            w.appendIdentifier(script);
          }else{
            w.appendNumber(String(n));
          }

          // close struct
          if(sub){
            w.endSub();
          }else{
            w.endSup();
          }
        }
      }
    }

    return true;
  }

  /**
   *
   */
  public getElementCode():string{
    return ElementCodes.TOKEN_ID;
  }

  /**
   *
   */
  public getType():string{
    return 'id';
  }

}
