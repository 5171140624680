import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { TDDiagram } from '../../elements/models/tree/TDDiagram';
import { TDNode } from '../../elements/models/tree/TDNode';
import { TDProb } from '../../elements/models/tree/TDProb';
import { TDResult } from '../../elements/models/tree/TDResult';
import { WTreeDiagram } from '../../elements/tokens/WTreeDiagram';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class ShowShortProbs extends FunctionElement {

  private probFormat:string;

  /**
   *
   */
  constructor(probFormat:string = 'short'){
    super();
    this.probFormat = probFormat;
  }

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length < 1 || args.length > 2){
      return args.expectingArguments(1, 2);
    }

    const tree:WTreeDiagram = args.getTreeDiagram(0);
    if(!tree){
      return null;
    }

    if(args.length === 1){
      return this.applyResultFormat(tree, null);
    }

    if(args.length === 2){
      if(args.getStrings(1)){
        return this.applyResultFormat(tree, args.getStrings(1).toStrings());
      }
    }

    return null;
  }

  /**
   *
   */
  private applyResultFormat(
      value:WTreeDiagram,
      path:string[]):WTreeDiagram{

    const diagram:TDDiagram = value.model.clone();
    const nodes:TDNode[] = path ? diagram.root.search(path) : diagram.root.leafs;
    for(let i:number = 0 ; i < nodes.length ; i++){
      const node:TDNode = nodes[i];
      if(!node.result){
        node.result = new TDResult();
      }
      node.result.c_prob_format = this.probFormat;
      node.result.c_prob_list = [];
      for(let j:number = 0 ; j < node.branch.length ; j++){
        const node2:TDNode = node.branch[j];
        const prob:TDProb = node2.getProb();
        node.result.c_prob = node.result.c_prob ? node.result.c_prob.multiply(prob) : prob;
        node.result.c_prob_list.push(prob);
      }
    }

    return new WTreeDiagram(diagram, value.displayMode);
  }

}
