import React from 'react';
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';

export interface ISortableItemProps {
  readonly id: string;
  readonly className?: string;
  readonly renderItem: (setNodeRef: (node: HTMLElement) => void) => JSX.Element;
}

export const SortableItem: React.FC<ISortableItemProps> = (props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isSorting,
    isDragging,
  } = useSortable({
    id: props.id,
  });

  React.useEffect(
    () => {
      document.body.style.cursor = isSorting ? 'grabbing' : '';

      return () => {
        document.body.style.cursor = '';
      };
    },
    [isSorting]);

  const style = {
    transition,
    transform: CSS.Translate.toString(transform),
    cursor: isSorting ? 'grabbing' : 'grab',
    opacity: isDragging ? 0.5 : 1,
    filter: isDragging ? 'grayscale(100%)' : null,
  };

  return (
    <div
      className={props.className}
      style={style}
      {...listeners}
      {...attributes}
    >
      {props.renderItem(setNodeRef)}
    </div>
  );
};
