import {action, observable, runInAction} from 'mobx';
import {ThemeName} from '@scolab/publisher-ui-kit';
import {ClientApplicationModel} from '../ClientApplicationModel';
import {Store} from '../../dependencyInjection/Store';
import {AuthenticationStatus, IUserSession} from './IUserSession';
import {IMemberInfo} from '../IMemberInfo';
import { Locales } from '../ILocales';
import { api } from '../../utils/fetch';

const USER_LANGUAGE_LOCAL_STORAGE_KEY = 'USER_LANGUAGE_LOCAL_STORAGE_KEY';
const USER_THEME_LOCAL_STORAGE_KEY = 'USER_THEME_LOCAL_STORAGE_KEY';

@Store('UserSessionStore.ts/UserSessionStore', true)
export class UserSessionStore {

  @observable
  public session: IUserSession;

  @observable
  public memberInfo: IMemberInfo;

  @observable
  public locale: Locales;

  @observable
  public theme: ThemeName;

  constructor() {
    runInAction(() => {
      this.session = {
        authenticationStatus: AuthenticationStatus.Unauthenticated
      };
      this.locale = this.getDefaultLocale();
      this.theme = this.getDefaultTheme();
      this.propagateCurrentLang();
    });
  }

  public get languageCode(): 'fr' | 'en' {
    return this.locale?.substring(0,2) as 'fr' | 'en';
  }

  public invalidateCurrentJWT = async (): Promise<boolean> => {
    const response = await api('/newApi/auth/logout', { method: 'POST' });
    return response.ok;
  }

  @action.bound
  public startAuthentication(): void {
    this.session.authenticationStatus = AuthenticationStatus.Authenticating;
  }

  @action.bound
  public stopAuthentication(): void {
    this.session.authenticationStatus = AuthenticationStatus.Unauthenticated;
  }

  @action.bound
  public setAuthenticationFlag(isAuthenticated: boolean): void {
    this.session.authenticationStatus = isAuthenticated
      ? AuthenticationStatus.Authenticated
      : AuthenticationStatus.Unauthenticated;
  }

  @action.bound
  public setAuthenticationError(): void {
    this.session.authenticationStatus = AuthenticationStatus.Error;
  }

  public get isAuthenticated(): boolean {
    return this.session.authenticationStatus === AuthenticationStatus.Authenticated;
  }

  @action.bound
  public setMemberInfo(memberInfo: IMemberInfo): void {
    this.memberInfo = memberInfo;
  }

  @action.bound
  public setLocale(locale: Locales): void {
    localStorage.setItem(USER_LANGUAGE_LOCAL_STORAGE_KEY, locale);
    this.locale = locale;
    this.propagateCurrentLang();
  }

  @action.bound
  public setTheme(theme: ThemeName): void {
    localStorage.setItem(USER_THEME_LOCAL_STORAGE_KEY, theme);
    this.theme = theme;
  }

  private propagateCurrentLang(): void {
    ClientApplicationModel.getInstance().clientApplicationLang = this.locale.substring(0, 2);
  }

  private getDefaultLocale = () => {
    const savedUserLanguage = localStorage.getItem(USER_LANGUAGE_LOCAL_STORAGE_KEY);
    if (savedUserLanguage) {
      return savedUserLanguage;
    }
    const navigatorLanguage = navigator.language;
    if (Locales[navigatorLanguage]) {
      return Locales[navigatorLanguage];
    }
    const languageCode = navigatorLanguage.substring(0, 2);
    switch (languageCode) {
      case 'fr': return Locales.fr_CA;
      case 'en': return Locales.en_US;
      default: return Locales.en_US;
    }
  }

  private getDefaultTheme = (): ThemeName => {
    return localStorage.getItem(USER_THEME_LOCAL_STORAGE_KEY) as ThemeName ?? ThemeName.dark;
  }

}
