import { Point } from '../../../../js/geom/Point';
import { XRound } from '../../../core/XRound';
import { XSort } from '../../../core/XSort';
import { ContentElement } from '../../../elements/abstract/ContentElement';
import { FunctionElement } from '../../../elements/abstract/FunctionElement';
import { RealElement } from '../../../elements/abstract/RealElement';
import { WBoolean } from '../../../elements/tokens/WBoolean';
import { WList } from '../../../elements/tokens/WList';
import { WListOfPoints } from '../../../elements/tokens/WListOfPoints';
import { WPoint } from '../../../elements/tokens/WPoint';
import { ArgumentsObject } from '../../../expr/ArgumentsObject';

/**
 * Compare with tolerance.
 */
export class SloppyCompare extends FunctionElement {

  public callReturnElement(args:ArgumentsObject):ContentElement {
    if (args.length < 3 || args.length > 4) {
      return args.expectingArguments(3, 4);
    }

    if(args.length === 3){
      if(args.getReal(0) && args.getReal(1) && args.getReal(2)){
        return this.numbers(args.getReal(0), args.getReal(1), args.getReal(2));
      }
      if(args.getPoint(0) && args.getPoint(1) && args.getReal(2)){
        return this.points(args.getPoint(0), args.getPoint(1), args.getReal(2));
      }
      if(args.getReals(0) && args.getReals(1) && args.getReal(2)){
        return this.lists(args.getReals(0), args.getReals(1), args.getReal(2));
      }
      if(args.getPoints(0) && args.getPoints(1) && args.getReal(2)){
        return this.lpoints(args.getPoints(0), args.getPoints(1), args.getReal(2), null, null);
      }
    }else if(args.length === 4){
      if(args.getPoint(0) && args.getPoint(1) && args.getReal(2) && args.getReal(3)){
        return this.points2(args.getPoint(0), args.getPoint(1), args.getReal(2), args.getReal(3));
      }
      if(args.getPoints(0) && args.getPoints(1) && args.getReal(2) && args.getReal(3)){
        return this.lpoints(args.getPoints(0), args.getPoints(1), null, args.getReal(2), args.getReal(3));
      }
    }

    return null;
  }

  /**
   *
   */
  private numbers(
      a:RealElement,
      b:RealElement,
      d:RealElement):WBoolean{

    return WBoolean.parse(XRound.safeRound(Math.abs(a.toNumber() - b.toNumber())) <= d.toNumber());
  }

  /**
   *
   */
  private points(
      a:WPoint,
      b:WPoint,
      d:RealElement):WBoolean{

    const test:boolean =
      d.toNumber() < 0 ?
      false :
      Point.distance(a.toPoint(), b.toPoint()) <= d.toNumber();

    return WBoolean.parse(test);
  }

  /**
   * Different tolerance for x-axis and
   * y-axis expressed as a point.
   */
  private points2(
      a:WPoint,
      b:WPoint,
      dx:RealElement,
      dy:RealElement):WBoolean{

    let test:boolean = false;
    if(dx.toNumber() >= 0 && dy.toNumber() >= 0){
      test = this.numbers(a.x, b.x, dx).toBoolean() && this.numbers(a.y, b.y, dy).toBoolean();
    }

    return WBoolean.parse(test);
  }

  /**
   *
   */
  private lists(
      a:WList,
      b:WList,
      d:RealElement):WBoolean{

    if(a.count !== b.count){
      return WBoolean.FALSE;
    }
    if(a.count === 0 && b.count === 0){
      return WBoolean.TRUE;
    }
    if(d.toNumber() < 0){
      return WBoolean.FALSE;
    }

    let na:number[] = a.toNumbersV();
    let nb:number[] = b.toNumbersV();

    na = na.sort(XSort.numeric);
    nb = nb.sort(XSort.numeric);

    for(let i:number = 0 ; i < na.length ; i++){
      if(XRound.safeRound(Math.abs(na[i] - nb[i])) > d.toNumber()){
        return WBoolean.FALSE;
      }
    }

    return WBoolean.TRUE;
  }

  /**
   *
   */
  private lpoints(
      a:WListOfPoints,
      b:WListOfPoints,
      distance:RealElement,
      dx:RealElement,
      dy:RealElement):WBoolean{

    if(a.count !== b.count){
      return WBoolean.FALSE;
    }
    if(a.count === 0 && b.count === 0){
      return WBoolean.TRUE;
    }

    if(distance && distance.toNumber() < 0){
      return WBoolean.FALSE;
    }
    if(dx && dx.toNumber() < 0){
      return WBoolean.FALSE;
    }
    if(dy && dy.toNumber() < 0){
      return WBoolean.FALSE;
    }

    let i:number;
    let j:number;

    const n:number = a.count;

    const cols:any[] = [];

    for(i = 0 ; i < n ; i++){
      const col:number[] = [];

      for(j = 0 ; j < n ; j++){
        if(distance && Point.distance(a.getValueAt(i), b.getValueAt(j)) <= distance.toNumber()){
          col.push(j);
        }
        if(dx && dy && this.numbers(a.getTypedItemAt(i).x, b.getTypedItemAt(i).x, dx).toBoolean() && this.numbers(a.getTypedItemAt(i).y, b.getTypedItemAt(i).y, dy).toBoolean()){
          col.push(j);
        }
      }

      if(col.length === 0){
        return WBoolean.FALSE;
      }
      cols.push(col);
    }

    return WBoolean.parse(this.outcome(cols, []));
  }

  /**
   *
   */
  private outcome(
      cols:any[],
      combination:number[]):boolean{

    const i:number = combination.length;

    if(i < cols.length){
      const col:number[] = cols[i];
      for(let k:number = 0 ; k < col.length ; k++){
        const temp:number[] =
          combination.concat();

        temp.push(col[k]);
        if(this.outcome(cols, temp)){
          return true;
        }
      }
    }

    if(combination.length < cols.length){
      return false;
    }
    for(let j:number = 0 ; j < combination.length ; j++){
      if(combination.indexOf(combination[j], j + 1) !== -1){
        return false;
      }
    }

    return true;
  }

}
