import { AbstractStep } from '../../AbstractStep';
import { Compartment } from '../../Compartment';
import { LongDivisionOperationEnglish } from '../../LongDivisionOperationEnglish';
import { DivisionModel } from '../../models/DivisionModel';
import { DivQuotientEnglish } from './DivQuotientEnglish';
import { DivAddSeparatorEnglish } from './DivAddSeparatorEnglish';

/**
 *
 */
export class DivNewDecimalEnglish extends AbstractStep {

  private model:DivisionModel;

  private division:LongDivisionOperationEnglish;

  private insertedSeperator:boolean = false;

  constructor(
      division:LongDivisionOperationEnglish,
      consecutive:boolean){
    super(division);
    this.division = division;
    this.model = division.model;

    let zero:Compartment;

    if(consecutive){
      zero = Compartment.createDigit(0);
      this.target.push(zero);
      division.result.push(zero);
    }

    zero = Compartment.createDigit(0);
    this.target.push(zero);

    division.lastStep.push(zero);
    division.rest.push(0);
    division.decimals++;

    this.setDescription(division.culture.getString('LongDivisionEnglish.newDecimal'));
  }

  public next():AbstractStep {
    if( !this.division.separatorAdded && this.insertedSeperator ) {
      return new DivAddSeparatorEnglish(this.division);
    }
    const _rest:number = Number(this.division.rest.join(''));
    return new DivQuotientEnglish(this.division, _rest, this.model.normalizedDivisor);
  }

}
