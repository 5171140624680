import { BaseNumberFormatter } from '../../../elements/formats/BaseNumberFormatter';
import { WriteShortOrdinalEnFormatter } from '../../../elements/formats/write/WriteShortOrdinalEnFormatter';
import { WriteShortOrdinalFrFormatter } from '../../../elements/formats/write/WriteShortOrdinalFrFormatter';
import { CultureInfo } from '../../../localization/CultureInfo';

/**
 *
 */
export class ShortOrdinalNumberFormatter extends BaseNumberFormatter {

  /**
   *
   */
  constructor(culture:CultureInfo){
    super(culture);
  }

  /**
   *
   */
  public toLocaleString(value:number):string{
    if(!this.willFormat(value)){
      return this.culture.formatNumber(value);
    }
    return this.getLocalizedFormatter().toLocaleString(value);
  }

  /**
   *
   */
  public toSpeakText(value:number):string{
    return this.willFormat(value) ?
        null :
        this.culture.numberFormatter.toSpeakText(value);
  }

  /**
   *
   */
  public willFormat(value:number):boolean{
    return this.getLocalizedFormatter().willFormat(value);
  }

  /**
   *
   */
  private getLocalizedFormatter():BaseNumberFormatter{
    switch(this.culture.languageCode){
      case 'fr':
        return new WriteShortOrdinalFrFormatter(this.culture);
      default:
        return new WriteShortOrdinalEnFormatter(this.culture);
    }
  }

}
