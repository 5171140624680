import { Point } from '../../../js/geom/Point';
import { Match } from '../../core/Match';
import { MathError } from '../../core/MathError';
import { RealElement } from '../../elements/abstract/RealElement';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { WNumber } from '../../elements/tokens/WNumber';
import { CultureInfo } from '../../localization/CultureInfo';
import { ShipPositionModel } from '../../funcs/battleship/ShipPositionModel';
import { HitPositionModel } from '../../funcs/battleship/HitPositionModel';

/**
 *
 */
export class BattleshipBoardImpl {

  private culture:CultureInfo;

  /**
   *
   */
  constructor(culture:CultureInfo) {

    this.culture = culture;
  }

  /**
   *
   */
  public createEmpty():WMatrix{
    const emptyTile:WNumber = this.culture.createNumber(1);
    const o:RealElement[] = [];
    for(let i:number = 0 ; i < 100 ; i++){
      o.push(emptyTile);
    }
    return new WMatrix(o, 10, this.culture.formats.matrixFormatImpl);
  }

  /**
   * Returns {x: col, y: row}
   */
  public getPosition(notation:string):Point {
    const r:RegExp = new RegExp('^([a-jA-J])([1-9]|10)$');
    const z:Match = Match.tryParse(r.exec(notation));
    if(z){
      return new Point(String(z.groups[1]).toLowerCase().charCodeAt(0) - 97, Number(z.groups[2]) - 1);
    }
    return null;
  }

  /**
   *
   */
  public getHitPosition(
      notation:string):HitPositionModel{

    if(notation.length !== 2 && notation.length !== 3){
      return null;
    }
    const position:Point = this.getPosition(notation);
    if(position){
      return new HitPositionModel(position.x, position.y);
    }
    return null;
  }

  /**
   *
   */
  public getShipPosition(notation:string):ShipPositionModel{
    if(notation.length !== 4 && notation.length !== 5){
      return null;
    }

    const size:number = parseInt(notation.charAt(0), 10);
    if(size < 2 || size > 5){
      return null;
    }
    const orientationLetter:string = notation.charAt(1).toLowerCase();
    if(orientationLetter !== 'h' && orientationLetter !== 'v'){
      return null;
    }
    const isHorizontal:boolean = (orientationLetter === 'h');
    const position:Point = this.getPosition(notation.substring(2));
    if(position){
      if(isHorizontal){
        if(position.x + size > 10){
          return null;
        }
      }else if(position.y + size > 10){
          return null;
        }
    }else{
      return null;
    }
    return new ShipPositionModel(size, isHorizontal, position);
  }

  /**
   *
   */
  public hit(
      board:WMatrix,
      notations:string[]):WMatrix{

    this.validateBoard(board);

    const copy:RealElement[] = board.values.concat();
    for(let i:number = 0 ; i < notations.length ; i++){
      const notation:string = notations[i];
      const o:HitPositionModel = this.getHitPosition(notation);
      if(o){
        const newValue:number = -board.valueAt(o.y, o.x).toNumber();
        copy[o.y * 10 + o.x] = this.culture.createNumber(newValue);
      }
    }

    return new WMatrix(copy, 10, board.formatter);
  }

  /**
   * Notation example: 5vf6. The first character represents the boat's size,
   * the second character represents the orientation (v=vertical, h=horizontal) and
   * the 2 last characters represent the insertion position.
   *
   * Exemple de notation : 5vf6. Le premier caractère représente la grosseur du bateau,
   * le 2ème représente l'orientation (v=vertical, h=horizontal) et les 2 derniers
   * représente la position à laquelle le bateau est inséré. Si la grille n'est pas spécifié,
   * on assume qu'on place un bateau sur une grille vide.
   */
  public place(
      board:WMatrix,
      notations:string[]):WMatrix{

    this.validateBoard(board);

    const copy:RealElement[] = board.values.concat();

    for(let i:number = 0 ; i < notations.length ; i++){
      const notation:string = notations[i];
      const o:ShipPositionModel = this.getShipPosition(notation);
      if(o){
        // adds the boat id in every cell it occupies
        for(let g:number = 0; g < o.size; g++){
          const index:number =
            o.isHorizontal ?
            (o.position.y * 10) + (o.position.x + g) :
            ((o.position.y + g) * 10) + o.position.x;

          copy[index] = this.culture.createNumber(o.size);
        }
      }
    }

    return new WMatrix(copy, 10, board.formatter);
  }

  /**
   *
   */
  private validateBoard(board:WMatrix):void{
    if(board.rows !== 10 || board.columns !== 10){
      throw new MathError('Battleship board must be 10x10');
    }
  }

}
