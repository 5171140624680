import React, { Suspense, lazy } from 'react';
import {
  Route,
  RouteComponentProps,
  withRouter,
  Switch,
  Redirect,
} from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { PageNotFound, Spinner, ThemeDefault } from '@scolab/publisher-ui-kit';
import { ErrorLogger, DatadogRUM, PublisherApiAuth } from '@scolab/common-editor';
import {
  ProtectedComponent,
  LoginApp,
} from '@scolab/login';
import { ThemeProvider } from 'styled-components';
import { linkTo, PageEditorRouteGroup, Routes } from '../config/routes';
import { GlobalStyle } from './PublisherApp.styles';
import ErrorBoundary from './ErrorBoundary';

const WorkbenchModule = lazy(() => import('../modules/WorkbenchModule'));
const PageEditorModule = lazy(() => import('../modules/PageEditorModule'));
const ActivityPreviewModule = lazy(() => import('../modules/ActivityPreviewModule'));
const VariableEditorModule = lazy(() => import('../modules/VariableEditorModule'));

const DefaultTheme = new ThemeDefault();

export class PublisherAppComponent extends React.Component<RouteComponentProps> {

  public constructor(props: RouteComponentProps) {
    super(props);

    ErrorLogger.initialize('publisher-app');
    DatadogRUM.initialize('publisher-app', ScolabConfiguration.DATADOG_RUM_ENV.split(','), ScolabConfiguration.GIT_SHA);
    PublisherApiAuth.addUnauthorizedRequestEventListener(this.handleUnauthorizedRequest);
  }

  public render(): React.ReactNode {
    const { location } = this.props;

    return (
      <HelmetProvider context={{}}>
        <Helmet>
          <title>
            Publisher
          </title>
        </Helmet>
        <GlobalStyle />
        <ThemeProvider theme={DefaultTheme.theme}>
          <ErrorBoundary>
            <Switch>
              <Route exact path={Routes.Home}>
                <Redirect to={{ pathname: linkTo(Routes.Workbench), search: location.search }} />
              </Route>
              <Route
                path={Routes.Login}
                component={LoginApp}
              />
              <Route
                path={Routes.Workbench}
                render={() => (
                  <ProtectedComponent>
                    <Suspense fallback={<Spinner />}>
                      <WorkbenchModule />
                    </Suspense>
                  </ProtectedComponent>
                )}
              />
              <Route
                path={PageEditorRouteGroup}
                render={() => (
                  <ProtectedComponent>
                    <Suspense fallback={<Spinner />}>
                      <PageEditorModule />
                    </Suspense>
                  </ProtectedComponent>
                )}
              />
              <Route
                path={Routes.ActivityPreview}
                render={() => (
                  <ProtectedComponent>
                    <Suspense fallback={<Spinner />}>
                      <ActivityPreviewModule />
                    </Suspense>
                  </ProtectedComponent>
                )}
              />
              <Route
                path={Routes.VariableEditor}
                render={() => (
                  <Suspense fallback={<Spinner />}>
                    <VariableEditorModule />
                  </Suspense>
                )}
              />
              <Route component={PageNotFound} />
            </Switch>
          </ErrorBoundary>
        </ThemeProvider>
      </HelmetProvider>
    );
  }

  private handleUnauthorizedRequest = (): void => {
    const { history } = this.props;
    const login = linkTo(Routes.Login);

    // Prevent infinite redirection loop in case
    // the login page is also generating 401 responses.
    if (!window.location.pathname.includes(login)) {
      // Forward using pure javascript because of possible competing
      // React Router instances with their own prefixes.
      window.location.href = history.createHref({
        pathname: `${login}/`,
        search: `Cause=unauthorizedRequestCallback&ReturnUrl=${encodeURIComponent(`${window.location}`)}`,
      });
    }
  };
}

const PublisherApp: React.ComponentClass = withRouter(PublisherAppComponent);
export default PublisherApp;
