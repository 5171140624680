import React from 'react';
import { ReadonlyContext } from './ReadonlyContext';

export const PreventReadonly: React.FC = (props) => {
  const { children } = props;
  return (
    <ReadonlyContext.Provider value={false}>
      {children}
    </ReadonlyContext.Provider>
  );
};
