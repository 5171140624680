import { CSSProperties } from 'react';
import { merge } from 'lodash';
import { ColorSet } from '../../../themes/ColorSet';

export const DROPDOWN_BORDER_SIZE = 1;

export interface IDropdownTheme {
  border?: CSSProperties['border'];
  borderColor?: CSSProperties['borderColor'];
  color: CSSProperties['color'];
  closed: {
    background: CSSProperties['background'];
  };
  opened: {
    background: CSSProperties['background'];
  };
  error: {
    borderColor: CSSProperties['borderColor'];
  };
  disabled: {
    color: CSSProperties['color'];
    background: CSSProperties['background'];
    border: CSSProperties['border'];
  };
  icon: {
    color: CSSProperties['color'];
  };
}

export const darkDropdownTheme: IDropdownTheme = {
  color: ColorSet.white,
  borderColor: ColorSet.grey100,
  closed: {
    background: ColorSet.penumbra,
  },
  opened: {
    background: ColorSet.darkness,
  },
  error: {
    borderColor: ColorSet.alert400,
  },
  disabled: {
    color: ColorSet.grey500,
    background: ColorSet.grey900,
    border: `${DROPDOWN_BORDER_SIZE}px solid ${ColorSet.grey500}`,
  },
  icon: {
    color: ColorSet.white,
  },
};

export const darkDropdownTheme2: IDropdownTheme = merge({}, darkDropdownTheme, {
  closed: {
    background: ColorSet.grey900,
  },
  opened: {
    background: ColorSet.grey900,
  },
});

export const lightDropdownTheme: IDropdownTheme = {
  border: `${DROPDOWN_BORDER_SIZE}px solid ${ColorSet.grey100}`,
  borderColor: ColorSet.grey100,
  color: ColorSet.penumbra,
  closed: {
    background: ColorSet.white,
  },
  opened: {
    background: `linear-gradient(326.5deg, ${ColorSet.grey025} 0%, ${ColorSet.white} 100%);`,
  },
  error: {
    borderColor: ColorSet.alert400,
  },
  disabled: {
    color: ColorSet.grey400,
    border: `${DROPDOWN_BORDER_SIZE}px solid ${ColorSet.grey100}`,
    background: ColorSet.white,
  },
  icon: {
    color: ColorSet.penumbra,
  },
};
