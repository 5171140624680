import {
  Transforms,
  Range,
} from '../TypedSlate';
import { ICustomEditor } from '../models';
import { IMarkupElement } from '../models/elements';

export const insertMarkup = (editor: ICustomEditor, markup: string, range?: Range) => {
  const text = { text: '' };
  const image: IMarkupElement = { markup, type: 'markup', children: [text] };
  Transforms.insertNodes(editor, image, { at: range });
};
