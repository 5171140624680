import { XMath } from '../../core/XMath';
import { XStatistics } from '../../core/XStatistics';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class Sum extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 1){
      return args.expectingArguments(1, 1);
    }

    if(args.getReal(0)){
      return args.getReal(0);
    }
    if(args.getReals(0)){
      return args.env.culture.createNumber(XStatistics.sum(args.getReals(0).toNumbersV()));
    }
    if(args.getMatrix(0)){
      return args.env.culture.createNumber(this.matrix(args.getMatrix(0)));
    }

    return null;
  }

  /**
   *
   */
  private matrix(matrix:WMatrix):number{
    let s:number = 0;
    for(let i:number = 0 ; i < matrix.count ; i++){
      s = XMath.safeAdd(s, matrix.values[i].toNumber());
    }
    return s;
  }

}
