import React from 'react';
import { Editor, RangeRef } from '../../TypedSlate';
import { AddMarkupDialog } from './AddMarkupDialog';
import { ICustomEditor } from '../../models';
import { Button } from '../../../Button';
import FunctionIcon from '../../../../../assets/function.svg';
import { Tooltip } from '../../../../utils/Tooltip/Tooltip';

interface IAddMarkupProps {
  editor: ICustomEditor;
  label: string;
  addLabel: string;
}

export const AddMarkupControl: React.FC<IAddMarkupProps> = ({ editor, label, addLabel }) => {
  const [open, setOpen] = React.useState(false);
  const selectionRefRef = React.useRef<RangeRef>(null); // Name is not a typo. It's a react ref of a slate ref.
  const onOpen = () => {
    const rangeRef = Editor.rangeRef(editor, editor.selection);
    selectionRefRef.current = rangeRef;
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
    selectionRefRef.current.unref();
    selectionRefRef.current = null;
  };
  return (
    <>
      <Tooltip
        title={label}
      >
        <Button
          onMouseDown={onOpen}
          aria-label={label}
          icon
        >
          <FunctionIcon />
        </Button>
      </Tooltip>
      <AddMarkupDialog
        open={open}
        handleClose={onClose}
        editor={editor}
        addLabel={addLabel}
        selectionRef={selectionRefRef.current}
      />
    </>
  );
};
