import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { ListElement } from '../../elements/abstract/ListElement';
import { WString } from '../../elements/tokens/WString';
import { WStruct } from '../../elements/tokens/WStruct';
import { WStructDef } from '../../elements/tokens/WStructDef';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { ContextModelUtil } from '../../expr/ContextModelUtil';
import { Environment } from '../../expr/Environment';

/**
 * Décrit le contenu d'une structure.
 */
export class Desc extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 1){
      return args.expectingArguments(1, 1);
    }

    if (args.getStruct(0)) {
      return this.fromInstance(args.getStruct(0), args.env);
    }
    if (args.getStructDef(0)) {
      return this.fromDefinition(args.getStructDef(0), args.env);
    }
    return null;
  }

  /**
   *
   */
  private fromInstance(struct:WStruct, env:Environment):ListElement{
    return env.culture.listFactory.createFromArray(struct.names);
  }

  /**
   *
   */
  private fromDefinition(struct:WStructDef, env:Environment):ListElement{
    const names:string[] =
      ContextModelUtil.getDefinedVariables(struct.definition.getContextModel());
    const o:ContentElement[] = [];
    for(let i:number = 0 ; i < names.length ; i++){
      o.push(new WString(names[i]));
    }
    return env.culture.listFactory.createList(o);
  }

}
