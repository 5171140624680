import { RealElement } from '../../../elements/abstract/RealElement';
import { IWriter } from '../../../expr/conversion/writers/IWriter';
import { IWriterBase } from '../../../expr/conversion/writers/IWriterBase';

/**
 *
 */
export class OldWriterFacade implements IWriterBase {

  private w:IWriter;

  constructor(w:IWriter){
    this.w = w;
  }

  public get fractionsAvailable():boolean{
    return false;
  }

  public writeNumber(value:number):void{
    if(this.exponentMode){
      this.w.writeSuperscript(value);
    }else if(this.subscriptMode){
      this.w.writeSubscript(value);
    }else{
      this.w.writeNumber(value);
    }
  }

  public writeReal(value:RealElement):void{
    // TODO: check numeric symbols like constants
    if(this.exponentMode){
      this.w.writeSuperscript(value.toNumber());
    }else if(this.subscriptMode){
      this.w.writeSubscript(value.toNumber());
    }else{
      this.w.writeNumber(value.toNumber());
    }
  }

  public writePrefixOperator(value:string):void{
    if(this.exponentMode || this.subscriptMode){
      this.err();
    }
    this.w.writeOperator(value, 'prefix');
  }

  public writeOperator(value:string):void{
    if(this.exponentMode || this.subscriptMode){
      this.err();
    }
    this.w.writeInfixOperator(value);
  }

  public writeIdentifier(value:string):void{
    if(this.exponentMode || this.subscriptMode){
      this.err();
    }
    this.w.writeSymbol(value);
  }

  public writeSymbol(value:string, italic:boolean):void{
    if(italic){
      this.w.writeSymbol(value);
    }else{
      this.w.beginText();
      this.w.writeRaw(value);
      this.w.endText();
    }
  }

  public beginSimpleStructure():void{ /* do nothing */ }

  public endSimpleStructure():void{ /* do nothing */ }

  // Fractions
  public beginWriteFractionNumerator():void{this.err();}

  public beginWriteFractionDenominator():void{this.err();}

  public endWriteFraction():void{this.err();}

  // Exponents
  private exponentMode:boolean = false;

  public beginWriteExponentBase():void{/* Do nothing */}

  public beginWriteExponentScript():void{this.exponentMode = true;}

  public endWriteExponentScript():void{this.exponentMode = false;}

  public endWriteExponent():void{/* Do nothing */}

  // Subscripts
  private subscriptMode:boolean = false;

  public beginWriteSubscriptBase():void{/* Do nothing */}

  public beginWriteSubscriptScript():void{this.subscriptMode = true;}

  public endWriteSubscriptScript():void{this.subscriptMode = false;}

  public endWriteSubscript():void{/* Do nothing */}

  private err():void{throw new Error('Not supported');}

}
