import { instantiate } from '../dependencyInjection/instantiate';
import { PageLockStore } from '../models/store/PageLockStore';
import { PageVersionStore } from '../models/store/PageVersionStore';
import { UserSessionStore } from '../models/store/UserSessionStore';

export const canEditPage = (pageGuid: string) => {
  const pageVersionStore = instantiate(PageVersionStore);
  const isLatestVersion = pageVersionStore.isLatestVersion(pageGuid);
  const isLockOwnedByUser = isLockOwner(pageGuid);

  return isLockOwnedByUser && isLatestVersion;
};

export const isLockOwner = (pageGuid: string) => {
  const pageLockStore = instantiate(PageLockStore);
  const userSessionStore = instantiate(UserSessionStore);
  const pageLock = pageLockStore.getPageLock(pageGuid);
  return pageLock &&
    pageLock.status === 'acquired' &&
    pageLock.lockedBy === userSessionStore.memberInfo?.internalId;
};
