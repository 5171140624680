/**
 *
 */
export class StringNavigator {

  public static ACCENTS:string = 'ÀÂÄÇÈÉÊËÎÏÔŒÙÛÜŸàâäçèéêëîïôœùûüÿ';

  public static VOWELS:string = 'aeiouyÀÂÄÈÉÊËÎÏÔŒÙÛÜŸàâäèéêëîïôœùûüÿ';

  public static CONSONANTS:string = 'bcdfghjklmnpqrstvwxzçÇ';

  private _str:string;

  private _pos:number;

  public get str():string{return this._str;}

  public get pos():number{return this._pos;}

  constructor(
      str:string,
      pos:number = -1){
    this._str = str;
    this._pos = pos;
  }

  /**
   * Move cursor to the next line, or at the end of the string if we are at the last line.
   * Returns the active line after having the cursor moved.
   */
  public nextLine():string{
    if(StringNavigator.isLineChar(this.char)){
      // Move to the next non line caracter
      do{ this._pos++;
      }while(StringNavigator.isLineChar(this.char));
    }

    // Move to the next line char
    while(this.pos < this.str.length && !StringNavigator.isLineChar(this.char)){
      this._pos++;
    }

    return this.line;
  }

  /**
   * Move cursor to the previous line, or before the begining of the string if we are at the first line.
   * Returns the active line after having the cursor moved.
   */
  public previousLine():string{
    if(StringNavigator.isLineChar(this.char)){
      // Move to the previous non line caracter
      do{ this._pos--;
      }while(StringNavigator.isLineChar(this.char));
    }

    // Move to the previous line char
    while(this.pos > 0 && !StringNavigator.isLineChar(this.char)){
      this._pos--;
    }

    if(StringNavigator.isLineChar(this.char)){
      // Move to the begining of the line
      do{ this._pos--;
      }while(StringNavigator.isLineChar(this.char));
      this._pos++; // we went one char too far
    }

    return this.line;
  }

  /**
   *
   */
  public nextWord():string{
    if(StringNavigator.isWordChar(this.char)){
      // Move to the next non word caracter
      do{ this._pos++;
      }while(StringNavigator.isWordChar(this.char));
    }

    // Move to the next word char
    while(this.pos < this.str.length && !StringNavigator.isWordChar(this.char)){
      this._pos++;
    }

    return this.word;
  }

  /**
   *
   */
  public previousWord():string{
    if(StringNavigator.isWordChar(this.char)){
      // Move to the previous non word caracter
      do{ this._pos--;
      }while(StringNavigator.isWordChar(this.char));
    }

    // Move to the previous word char
    while(this.pos > 0 && !StringNavigator.isWordChar(this.char)){
      this._pos--;
    }

    if(StringNavigator.isWordChar(this.char)){
      // Move to the begining of the word
      do{ this._pos--;
      }while(StringNavigator.isWordChar(this.char));
      this._pos++; // we went one char too far
    }

    return this.word;
  }

  /**
   *
   */
  public nextChar():string{
    if(this.pos < this.str.length){
      this._pos++;
    }
    return this.char;
  }

  /**
   *
   */
  public previousChar():string{
    if(this.pos >= 0){
      this._pos--;
    }
    return this.char;
  }

  /**
   * Returns the active line. Returns null if not over a line.
   */
  public get line():string{
    if(!StringNavigator.isLineChar(this.char)){
      return null;
    }

    let i:number = this.pos; // Must be positionned on the first char of the word
    let j:number = this.pos; // Must be positionned right after the last char

    do{
      i--;
    }while(StringNavigator.isLineChar(this.charAt(i)));
    i++; // we went back one extra position

    do{ j++;
    }while(StringNavigator.isLineChar(this.charAt(j)));

    return this.str.substring(i, j);
  }

  /**
   * Returns the active word. Returns null if not over a word.
   */
  public get word():string{
    if(!StringNavigator.isWordChar(this.char)){
      return null;
    }

    let i:number = this.pos; // Must be positionned on the first char of the word
    let j:number = this.pos; // Must be positionned right after the last char

    do{
      i--;
    }while(StringNavigator.isWordChar(this.charAt(i)));
    i++; // we went back one extra position

    do{ j++;
    }while(StringNavigator.isWordChar(this.charAt(j)));

    return this.str.substring(i, j);
  }

  /**
   * Returns the active char. Returns null if not over a char.
   */
  public get char():string{
    return this.charAt(this.pos);
  }

  private charAt(pos:number):string{
    if(pos >= 0 && pos < this.str.length){
      return this.str.charAt(pos);
    }
    return null;
  }

  public static isLineChar(
      chr:string):boolean{
    if(!chr){
      return false;
    }
    if(chr.length !== 1){
      return false;
    }
    return 	chr !== '\r' &&
        chr !== '\n';
  }

  public static isWordChar(
      chr:string):boolean{
    if(!chr){
      return false;
    }
    if(chr.length !== 1){
      return false;
    }
    if(chr >= 'a' && chr <= 'z'){
      return true; // lowercase letters
    }
    if(chr >= 'A' && chr <= 'Z'){
      return true; // uppercase letters
    }
    if(StringNavigator.ACCENTS.indexOf(chr) !== -1){
      return true; // accents
    }
    return false;
  }

}
