import { TokenElement } from '../../elements/abstract/TokenElement';
import { IInputAdapter } from '../../elements/markers/IInputAdapter';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';

/**
 *
 */
export class WInput extends TokenElement {

  private _adapter:IInputAdapter;

  public get adapter():IInputAdapter{return this._adapter;}

  constructor(adapter:IInputAdapter){
    super();
    this._adapter = adapter;
  }

  public writeTo(exporter:IMarkupExporter = null):boolean{
    if(exporter){
      exporter.writer.appendText('[Input]');
    }
    return true;
  }

  /**
   *
   */
  public getType():string{
    return 'input';
  }

}
