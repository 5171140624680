import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WList } from '../../elements/tokens/WList';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { ListElement } from '../../elements/abstract/ListElement';
import { WListOfString } from '../../elements/tokens/WListOfString';

/**
 * Liste de doublons.
 */
export class NotDistinct extends FunctionElement {
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length === 2) {
      const reals = [args.getReals(0), args.getReals(1)];
      if (reals[0] && reals[1]) {
        const list1 = reals[0].transform(this.getListNotUniqueIndices(reals[0]));
        const list2 = reals[1].transform(this.getListNotUniqueIndices(reals[1]));
        return new WList(list1.items.concat(list2.items), list1.formatter);
      }

      const strings = [args.getStrings(0), args.getStrings(1)];
      if (strings[0] && strings[1]) {
        const list1 = strings[0].transform(this.getListNotUniqueIndices(strings[0]));
        const list2 = strings[1].transform(this.getListNotUniqueIndices(strings[1]));
        return new WListOfString(list1.items.concat(list2.items), list1.formatter);
      }
    }

    if (args.length !== 1) {
      return args.expectingArguments(1, 2);
    }

    const list = args.getList(0);
    if (list) {
      return list.transform(this.getListNotUniqueIndices(list));
    }

    return null;
  }

  private getListNotUniqueIndices(list: ListElement): number[] {
    const indices: number[] = [];
    const map: Record<string, boolean> = {};
    for (let i = 0; i < list.count; i++) {
      const hash = list.getItemAt(i).hashCode();
      if (!hash) {
        throw new Error('Hashcode not implemented.');
      }
      if (!map.hasOwnProperty(hash)) {
        map[hash] = false;
      } else if (!map[hash]) {
        map[hash] = true;
        indices.push(i);
      }
    }
    return indices;
  }
}
