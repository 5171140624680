import { Point } from '../../../../js/geom/Point';
import { ContentElement } from '../../../elements/abstract/ContentElement';
import { TranslationImpl } from '../../../elements/models/transforms/TranslationImpl';
import { ArgumentsObject } from '../../../expr/ArgumentsObject';
import { BaseTransform } from '../../../funcs/geom/transforms/BaseTransform';

/**
 * Translation.
 */
export class Translation extends BaseTransform {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length < 2 || args.length > 3){
      return args.expectingArguments(2, 3);
    }

    let transform:TranslationImpl = null;

    if(args.length === 2){
      if(args.getPoint(1)){
        transform = new TranslationImpl(args.getPoint(1).toPoint());
      }else if(args.getBoundVector(1)){
        transform = new TranslationImpl(new Point(args.getBoundVector(1).dx, args.getBoundVector(1).dy));
      }
    }else if(args.length === 3){
      if(args.getReal(1) && args.getReal(2)){
        transform = new TranslationImpl(new Point(args.getReal(1).toNumber(), args.getReal(2).toNumber()));
      }
    }

    if(transform){
      return super.transform(args, transform);
    }

    return null;
  }

}
