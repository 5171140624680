import { Point } from '../../../../js/geom/Point';
import { IGeomTransform } from '../../../elements/models/transforms/IGeomTransform';

/**
 *
 */
export class TranslationImpl implements IGeomTransform {

  private offset:Point;

  /**
   *
   */
  constructor(offset:Point){
    this.offset = offset;
  }

  /**
   *
   */
  public transformPoint(value:Point):Point{
    return value.add(this.offset);
  }

}
