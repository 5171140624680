import { IPageCopy } from '../IPageCopy';

export const ACTIVITY_UPDATE = 'activity-update';

export interface IActivityUpdateMessage {
  type: 'activity-update';
  payload: IEditorActivity;
}

/**
 * Represents the model of an opened activity inside the editor.
 */
export interface IEditorActivity {
  activity: {
    id: number;
    name: string;
  };
  pages: ReadonlyArray<IEditorActivityPage>;
  editorPageIndex: number;
  previewId: string;
}

export interface IEditorActivityPage {
  readonly pageGuid: string;
  readonly pageCopy: IPageCopy;
  readonly variants: string;
}
