import { XString } from '../../core/XString';
import { AbstractStep } from '../AbstractStep';
import { Compartment } from '../Compartment';
import { LongAdditionOperation } from '../LongAdditionOperation';

/**
 *
 */
export class AddColumnSum extends AbstractStep {

  private digits:number[] = [];

  constructor(operation:LongAdditionOperation){
    super(operation);

    let i:number;
    let c:Compartment;

    const index:number = operation.columnIndex(operation.position);

    c = operation.carries[0];
    if(c){
      this.source.push(c);
      this.digits.push(c.value);
    }

    for(i = 0 ; i < operation.operands.length ; i++){
      const operand:Compartment[] = operation.operands[i];
      c = index < operand.length ? operand[index] : null;
      if(c){
        this.source.push(c);
        this.digits.push(c.value);
      }
    }

    let s:number = 0;
    for(i = 0 ; i < this.digits.length ; i++){
      s += this.digits[i];
    }

    c = Compartment.createDigit(s % 10);
    this.target.push(c);
    operation.result.unshift(c);

    if(s > 9){
      operation.addCarry = Math.floor(s / 10);
    }else{
      operation.addCarried = 0;
      operation.position++;
      if(operation.position < operation.model.integerLength){
        operation.carries.unshift(null);
      }
    }

    const label:string = XString.substitute('{0}', this.digits.join(' + '));
    const resourceName =
      this.digits.length === 1 ?
        'digitDownLabel' :
        'addColumnLabel';
    this.setDescription(operation.culture.getString(`LongAdditionOperation.${resourceName}`, label));
  }

}
