import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WBoolean } from '../../elements/tokens/WBoolean';
import { WUnhandled } from '../../elements/tokens/WUnhandled';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class IsUnhandled extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 1){
      return args.expectingArguments(1, 1);
    }
    // Do not use args.getUnhandled because it could do implicit conversion.
    if(args.getContentElement(0)){
      return WBoolean.parse(args.getContentElement(0) instanceof WUnhandled);
    }
    return null;
  }

}
