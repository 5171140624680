/**
 * ♔♕♖♗♘♙♚♛♜♝♞♟
 */
export class Pieces {

  public static BLACK_KING:number = 1; // BK

  public static WHITE_KING:number = -1; // WK

  public static BLACK_QUEEN:number = 2; // BQ

  public static WHITE_QUEEN:number = -2; // WQ

  public static BLACK_ROOK:number = 3; // tour // BR

  public static WHITE_ROOK:number = -3; // WR

  public static BLACK_BISHOP:number = 4; // fou // BB

  public static WHITE_BISHOP:number = -4; // WB

  public static BLACK_KNIGHT:number = 5; // cavalier // BN

  public static WHITE_KNIGHT:number = -5; // WK

  public static BLACK_PAWN:number = 6; // pion // BP

  public static WHITE_PAWN:number = -6; // WP

  public static parse(figurine:string):number{
    switch(figurine){
      case '♔': 	return Pieces.WHITE_KING;
      case '♕':	return Pieces.WHITE_QUEEN;
      case '♖':	return Pieces.WHITE_ROOK;
      case '♗':	return Pieces.WHITE_BISHOP;
      case '♘':	return Pieces.WHITE_KNIGHT;
      case '♙':	return Pieces.WHITE_PAWN;
      case '♚':	return Pieces.BLACK_KING;
      case '♛':	return Pieces.BLACK_QUEEN;
      case '♜':	return Pieces.BLACK_ROOK;
      case '♝':	return Pieces.BLACK_BISHOP;
      case '♞':	return Pieces.BLACK_KNIGHT;
      case '♟':	return Pieces.BLACK_PAWN;
    }
    return 0;
  }

}
