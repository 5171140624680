import { Point } from '../../../../js/geom/Point';

import { ContentElement } from '../../../elements/abstract/ContentElement';
import { FunctionElement } from '../../../elements/abstract/FunctionElement';
import { WNotANumber } from '../../../elements/tokens/WNotANumber';
import { WPolygon } from '../../../elements/tokens/WPolygon';
import { ArgumentsObject } from '../../../expr/ArgumentsObject';

/**
 * http://valis.cs.uiuc.edu/~sariel/research/CG/compgeom/msg00831.html
 */
export class Area extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 1){
      return args.expectingArguments(1, 1);
    }

    const polygon:WPolygon = args.getPolygon(0);

    if(polygon){
      if(polygon.isCrossed){
        return WNotANumber.getInstance();
      }

      return args.env.culture.createNumber(Area.polygonImpl(polygon.vertices));
    }

    return null;
  }

  /**
   *
   */
  private static polygonImpl(v:Point[]):number{
    let area:number = 0;
    let j:number = v.length - 1;

    for (let i:number = 0 ; i < v.length ; i++){
      area += (v[j].x + v[i].x) * (v[j].y - v[i].y);
      j = i;
    }

    return Math.abs(area / 2);
  }

}
