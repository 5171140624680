import { XRound } from '../../core/XRound';
import { RealElement } from '../../elements/abstract/RealElement';
import { ILine } from '../../elements/markers/ILine';
import { MathWriter } from '../../expr/conversion/writers/MathWriter';
import { CultureInfo } from '../../localization/CultureInfo';
import { AbstractFormatter } from '../../elements/formats/AbstractFormatter';

/**
 *
 */
export class BaseLinearEquationFormatter extends AbstractFormatter {

  protected precision:number = Number.MAX_SAFE_INTEGER;

  constructor(
      culture:CultureInfo,
      precision:number = Number.MAX_SAFE_INTEGER){
    super(culture);
    this.precision = precision;
  }

  protected strRoundCoef(value:RealElement):string{
    return this.culture.formatNumber(this.roundCoef(value).toNumber());
  }

  protected roundCoef(value:RealElement):RealElement{
    if(this.precision === Number.MAX_SAFE_INTEGER){
      return value;
    }
    return this.culture.createNumber(XRound.halfAway(value.toNumber(), this.precision));
  }

  protected strNormalize(value:string):string{
    return value.replace(/\+−/gi, '−');
  }

  public flushTo(w:MathWriter, value:ILine, operator:string):void{
    throw new Error();
  }

  public toLocaleString(value:ILine, operator:string):string{
    throw new Error();
  }

  public round(precision:number):BaseLinearEquationFormatter{
    throw new Error();
  }

  public validateFormat(value:string, yLabel:string, xLabel:string):boolean{
    throw new Error();
  }

  public formatError(operator:string):number{
    throw new Error();
  }

}
