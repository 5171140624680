import { MElement } from '../../../core/mml/MElement';
import { IMarkupExporter } from '../../../elements/markers/IMarkupExporter';
import { IMarkupFactory } from '../../../elements/markers/IMarkupFactory';
import { CultureInfo } from '../../../localization/CultureInfo';

/**
 *
 */
export class StaticMarkupFactory implements IMarkupFactory {

  private _element:MElement;

  private _culture:CultureInfo;

  public get culture():CultureInfo{return this._culture;}

  /**
   *
   */
  constructor(element:MElement, culture:CultureInfo) {

    this._element = element;
    this._culture = culture;
  }

  /**
   *
   */
  public writeTo(exporter:IMarkupExporter):void{
    exporter.writer.appendElement(this._element);
  }

}
