import { XString } from '../core/XString';
import { ContentElement } from '../elements/abstract/ContentElement';
import { Node } from '../elements/abstract/Node';
import { WPolynomial } from '../elements/tokens/WPolynomial';
import { WTransform } from '../elements/tokens/WTransform';
import { Environment } from '../expr/Environment';
import { IWriter } from '../expr/conversion/writers/IWriter';
import { KeyboardConfiguration } from './KeyboardConfiguration';
import { CommonError } from './CommonError';
import { COptions } from './COptions';
import { CList } from './CList';
import { CPolynomial } from './CPolynomial';
import { BaseCorrector } from './BaseCorrector';

/**
 *
 */
export class CTransform extends BaseCorrector {

  private cpoly:CPolynomial;

  private clist:CList;

  public configure(origin:ContentElement, options:COptions, env:Environment, useLatex:boolean):void{
    super.configure(origin, options, env, useLatex);
    this.cpoly = new CPolynomial(false, false, false);
    this.clist = new CList();
    super.configureOther(this.cpoly);
    super.configureOther(this.clist);
  }

  public parse(value:string):Node{
    const value2:string[] = this.parseTransform(this.translateInput(value));
    if(value2 == null){
      return null;
    }

    const x:WPolynomial = this.corceComponent(value2[0]);
    const y:WPolynomial = this.corceComponent(value2[1]);

    if(x == null || y == null){
      return null;
    }

    const node:Node = new Node(new WTransform(x, y));
    node.userData = `Transform(${x.userData}, ${y.userData})`;
    return node;
  }

  public correct(
      value:string,
      target:ContentElement,
      ...targets:any[]):boolean{

    const value2:string[] = this.parseTransform(this.translateInput(value));
    if(!value2){
      this.raiseError(CommonError.TRANSFORM_INVALID);
    }

    const target2:WTransform = <WTransform>target ;

    return 	this.cpoly.correct(value2[0], target2.x) &&
        this.cpoly.correct(value2[1], target2.y);
  }

  private corceComponent(value:string):WPolynomial{
    const polynomial:WPolynomial = this.env.expressions.toPolynomial(value.replace(/,/g, '.'));
    if(polynomial){
      polynomial.userData = value;
    }
    return polynomial;
  }

  private parseTransform(valueArg:string):string[]{
    let value = valueArg;
    if(value.charAt(0) === '(' && value.charAt(value.length -1) === ')'){
      value = value.substring(1, value.length - 1);

      const items:any[] = this.clist.parseItems(value, this.env.culture);
      if(items.length === 2){
        const o:string[] = [];
        o.push(items[0], items[1]);
        return o;
      }
    }

    return null;
  }

  private translateInput(valueArg:string):string{
    let value = valueArg;
    if(this.useLatex){
      value = this.sanitizeInput(value);
    }
    value = XString.trim(value);
    return value;
  }

  public get mathKeyboard():number{
    return KeyboardConfiguration.TRANSFORM;
  }

  public writeTo(
      w:IWriter,
      target:ContentElement,
      ...targets:any[]):void{

    const t:WTransform = <WTransform>target ;
    w.writeInfixOperator('(');
    CPolynomial.writePolynomial(w, t.x);
    w.writeInfixOperator(',');
    CPolynomial.writePolynomial(w, t.y);
    w.writeInfixOperator(')');
  }

}
