import { WNumber } from '../tokens/WNumber';
import { ElementCodes } from '../abstract/ElementCodes';
import { ContentElement } from '../abstract/ContentElement';
import { SymbolElement } from '../abstract/SymbolElement';

/**
 * Abstract class for representing a constant.
 */
export class ConstantElement extends SymbolElement {

  /**
   *
   */
  private _value:WNumber;

  public get value():WNumber{
    return this._value;
  }

  /**
   *
   */
  constructor(
      symbol:string,
      value:WNumber){
    super(symbol, value.formatter);
    this._value = value;
  }

  /**
   *
   */
  public toNumber():number{
    return this._value.toNumber();
  }

  /**
   *
   */
  public get isNegative():boolean{
    return this._value.toNumber() < 0;
  }

  /**
   *
   */
  public isConstant():boolean{
    return true;
  }

  /**
   *
   */
  public narrow():ContentElement{
    return this._value;
  }

  /**
   *
   */
  public getElementCode():string{
    return ElementCodes.TOKEN_CONSTANT;
  }

}
