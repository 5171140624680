import { XRound } from '../../core/XRound';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { QProbabilityDistribution } from '../../elements/models/stats/QProbabilityDistribution';
import { WDistribution } from '../../elements/tokens/WDistribution';
import { WList } from '../../elements/tokens/WList';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class ProbabilityDistribution extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 2){
      return args.expectingArguments(2, 2);
    }
    if(args.getReals(0) && args.getReals(1)){
      return this.distribution(args.getReals(0), args.getReals(1));
    }
    return null;
  }

  /**
   *
   */
  private distribution(
      modalities:WList,
      probabilityVector:WList):WDistribution{

    let sum:number = 0;
    for(let i:number = 0 ; i < probabilityVector.count ; i++){
      const n:number = probabilityVector.getValueAt(i);
      if(n < 0 || n > 1){
        return null;
      }
      sum += n;
    }
    if(XRound.safeRound(sum) !== 1){
      return null;
    }

    if(probabilityVector.toNumbersV().length > modalities.toNumbersV().length){
      return null;
    }

    return new WDistribution(
      new QProbabilityDistribution(
        modalities.toReals(),
        probabilityVector.toNumbersV()));
  }

}
