import { ClientApplicationModel } from '../../models/ClientApplicationModel';
import { IHistoryEventData } from '../../models/history/IHistoryEventData';
import { IHistoryEvent } from '../../models/history';
import { api } from '../../utils/fetch';

export const postHistoryLogEvent = async (eventData: IHistoryEventData,
                                          description: string): Promise<boolean> => {
  return postData('/Publisher/History/LogEvent', { eventData, description });
};

export const postHistoryLogEvents = async (eventsData: ReadonlyArray<IHistoryEventData>,
                                           descriptions: ReadonlyArray<string>): Promise<boolean> => {

  const data = eventsData.map((eventData, index) => ({
    eventData,
    description: descriptions[index],
  }));

  return postData('/Publisher/History/BatchLogEvents', { historyEvents: data });
};

export const cancelHistoryLogEvent = (eventData: IHistoryEvent,
                                      description: string) => {
  return postData('/Publisher/History/CancelEvent', { eventData, description });
};

export const cancelHistoryLogEvents = async (eventsData: ReadonlyArray<IHistoryEvent>,
                                             descriptions: ReadonlyArray<string>): Promise<boolean> => {

  const data = eventsData.map((eventData, index) => ({
    eventData,
    description: descriptions[index],
  }));

  return postData('/Publisher/History/BatchCancelEvents', { historyEvents: data });
};

const postData = async (url: string, body: {}): Promise<boolean> => {
  const clientApplication = ClientApplicationModel.getInstance();
  const init = {
    body: JSON.stringify(body),
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  };

  const response = await api(`${url}?${clientApplication.params}`, init);
  return response.ok;
}
