import { MElement } from './MElement';
const XMLWriter = require('xml-writer');

function writeElement(element: MElement, xw: typeof XMLWriter): void {
  xw.startElement(element.name);
  Object.keys(element.attributes).forEach((attributeName: string) => {
    xw.writeAttribute(attributeName, element.attributes[attributeName]);
  });
  if(element.text){
    xw.text(element.text);
  }
  if(element.children){
    element.children.forEach((child:MElement) => {
      writeElement(child, xw);
    });
  }
  xw.endElement();
}

export const mElementToXml = (root: MElement) => {
  const xw = new XMLWriter();
  xw.startDocument();
  writeElement(root, xw);
  xw.endDocument();
  return xw.toString();
};
