import { RelationalElement } from '../../elements/abstract/RelationalElement';
import { GreaterThanOrEqualTo } from '../../funcs/relational/GreaterThanOrEqualTo';

/**
 *
 */
export class LessThanOrEqualTo extends RelationalElement {

  /**
   *
   */
  constructor(){
    super('≤');
  }

  /**
   *
   */
  protected hasNumberComparer():boolean{
    return true;
  }

  /**
   *
   */
  protected compareNumbers(na:number, nb:number):boolean{
    return na <= nb;
  }

  /**
   *
   */
  public get reverse():RelationalElement{
    return new GreaterThanOrEqualTo();
  }

}
