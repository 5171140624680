import { IKeyboard } from './Keyboard.models';

/*
KeyboardManager -- Singleton -- call the function to show / hide keyboard in ManagedKeyboard
ManagedKeyboard -- hide and show the keyboard class.  The actual JSX element in the WorkbookPages
KeyboardWrapper - The element in the input responsible to build the props of the keyboard.
				- Show the keyboard by calling KeyboardManager.getInstance().showKeyboard
				- Use the layoutConfigs to create the JSON that define the layout.
Keyboard --	build the different parts of the keyboard, receive the toolbox(tabs) from the KeyboardWrapper.
  4 different sections:
    toolbox - Tab on top for mathInput
    navPad - arrows for navigation
    ActionsKeypad - Mostly backspace and hide keyboard.  Create a CustomKeypad
    MainKeypad - Keys in sections.  Sections are build by the KeyboardWrapper from the layoutConfigs:
      - symbolsDefs
      - numericKeypad by default if layout extends baseKeyboard, or a complete set of keys, like in the qwerty keyboard.
      - operatorsDefs
    CustomKeypad - All MainKeypad sections.  Create the rows from the Keys.
*/

export interface IKeyboardManagerListener {
  onShowKeyboard?: (keyboardConfiguration: IKeyboard, onKeyPress: (key: string) => void) => void;
  onHideKeyboard?: () => void;
}

export default class KeyboardManager {
  private static instance: KeyboardManager;

  private currentKeyboardId: string;

  private listeners: IKeyboardManagerListener[];

  private constructor() {
    this.listeners = [];
  }

  public static getInstance = () => {
    if (!KeyboardManager.instance) {
      KeyboardManager.instance = new KeyboardManager();
    }
    return KeyboardManager.instance;
  }

  public showKeyboard = (id: string, keyboardConfiguration: IKeyboard, onKeyPress: (key: string) => void) => {
    this.currentKeyboardId = id;
    this.listeners.forEach(listener => listener.onShowKeyboard(keyboardConfiguration, onKeyPress));
  }

  public hideKeyboard = (id: string) => {
    if (this.currentKeyboardId === id) {
      this.listeners.forEach(listener => listener.onHideKeyboard());
    }
  }

  public addListener = (listener: IKeyboardManagerListener) => {
    this.listeners.push(listener);
  }

  public removeListener = (listener: IKeyboardManagerListener) => {
    const index = this.listeners.indexOf(listener);
    if (index !== -1) {
      this.listeners.splice(index, 1);
    }
  }
}
