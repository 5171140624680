import { Attributes } from '../../elements/abstract/Attributes';
import { TokenElement } from '../../elements/abstract/TokenElement';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';

/**
 *
 */
export class WFraction extends TokenElement {

  private _numerator:TokenElement;

  public get numerator():TokenElement{return this._numerator;}

  private _denominator:TokenElement;

  public get denominator():TokenElement{return this._denominator;}

  /**
   *
   */
  constructor(
      numerator:TokenElement,
      denominator:TokenElement){
    super();
    this._numerator = numerator;
    this._denominator = denominator;
  }

  /**
   *
   */
  public getAttributes():number{
    return super.getAttributes() | Attributes.COMPLEX_CONTENT;
  }

  /**
   *
   */
  public writeTo(exporter:IMarkupExporter = null):boolean{
    if(exporter){
      exporter.writer.beginFraction();
      this.numerator.writeTo(exporter);
      this.denominator.writeTo(exporter);
      exporter.writer.endFraction();
    }
    return true;
  }

  /**
   *
   */
  public getType():string{
    return 'fraction';
  }

}
