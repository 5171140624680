import { ContentElement } from '../../elements/abstract/ContentElement';
import { ListElement } from '../../elements/abstract/ListElement';
import { BaseListFormatter } from '../../elements/formats/BaseListFormatter';
import { WSegment } from '../../elements/tokens/WSegment';

/**
 * Collection of segments.
 */
export class WListOfSegments extends ListElement {

  constructor(
      segments:ContentElement[],
      formatter:BaseListFormatter){
    super(segments, formatter);
  }

  public getTypedItemAt(index:number):WSegment{
    return <WSegment>this.getItemAt(index) ;
  }

  public acceptElement(element:ContentElement):boolean{
    return element.getListItemCode() === 'segment';
  }

  public equalsTo(value:ContentElement):boolean{
    if(value instanceof WListOfSegments){
      return this.unorderedEqualsTo(<WListOfSegments>value);
    }
    return false;
  }

  public strictlyEqualsTo(value:ContentElement):boolean{
    if(value instanceof WListOfSegments){
      return this.strictlyEqualsToImpl(value);
    }
    return false;
  }

  public toSegments():WSegment[]{
    const o:WSegment[] = [];
    for(let i:number = 0 ; i < this.count ; i++){
      o.push(this.getTypedItemAt(i));
    }
    return o;
  }

  protected createList(
    items:ContentElement[],
    formatter:BaseListFormatter):ListElement{
    return new WListOfSegments(items, formatter);
  }

  protected compareElements(a:ContentElement, b:ContentElement):number{
    return WSegment.compare(<WSegment>a , <WSegment>b );
  }

  protected elementsEquals(
      a:ContentElement,
      b:ContentElement,
      unordered:boolean):boolean{

    const sa:WSegment = <WSegment>a ;
    const sb:WSegment = <WSegment>b ;

    if(unordered){
      return sa.overlap(sb);
    }

    return sa.equalsTo(sb);
  }

  /**
   *
   */
  public getType():string{
    return 'segments';
  }

}
