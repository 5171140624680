import { XRound } from '../../core/XRound';

/**
 *
 */
export class TriangleModel {

  /**
   * Mesure du côté a.
   */
  public a:number;

  /**
   * Mesure du côté b.
   */
  public b:number;

  /**
   * Mesure du côté c.
   */
  public c:number;

  /**
   * Mesure de l'angle A en degrés.
   */
  public A:number;

  /**
   * Mesure de l'angle B en degrés.
   */
  public B:number;

  /**
   * Mesure de l'angle C en degrés.
   */
  public C:number;

  /**
   *
   */
  constructor(
      a:number,
      b:number,
      c:number,
      A:number,
      B:number,
      C:number){

    this.a = a;
    this.b = b;
    this.c = c;
    this.A = A;
    this.B = B;
    this.C = C;
  }

  /**
   * Valide les propriétés du triangle en vérifiant la loi des sinus.
   */
  public validate():boolean{
    const rA:number = XRound.safeRound(this.a / Math.sin(TriangleModel.rad(this.A)));
    const rB:number = XRound.safeRound(this.b / Math.sin(TriangleModel.rad(this.B)));
    const rC:number = XRound.safeRound(this.c / Math.sin(TriangleModel.rad(this.C)));
    const sAngles:number = XRound.safeRound(this.A + this.B + this.C);
    const rrA:number =  XRound.halfAway(rA,8);
    const rrB:number =  XRound.halfAway(rB,8);
    const rrC:number =  XRound.halfAway(rC,8);
    return rrA === rrB && rrB === rrC && sAngles === 180;
  }

  /**
   * Attempt to parse measurement info and fill the missing measures.
   */
  public static parse(
      aArg:number,
      bArg:number,
      cArg:number,
      Aarg:number,
      Barg:number,
      Carg:number):TriangleModel{

    let a = aArg;
    let b = bArg;
    let c = cArg;
    let A = Aarg;
    let B = Barg;
    let C = Carg;
    const da:boolean = a !== 0 && !isNaN(a);
    const db:boolean = b !== 0 && !isNaN(b);
    const dc:boolean = c !== 0 && !isNaN(c);
    let dA:boolean = A !== 0 && !isNaN(A);
    let dB:boolean = B !== 0 && !isNaN(B);
    let dC:boolean = C !== 0 && !isNaN(C);

    // Trouver la troisième mesure d'angle si deux angles sont définis
    if(dA && dB){
      C = 180 - (A + B);
      dC = true;
    }else if(dA && dC){
      B = 180 - (A + C);
      dB = true;
    }else if(dB && dC){
      A = 180 - (B + C);
      dA = true;
    }

    // Les trois côtés
    if(da && db && dc){
      A = TriangleModel.cosineLaw2(a, b, c);
      B = TriangleModel.cosineLaw2(b, a, c);
      C = TriangleModel.cosineLaw2(c, a, b);
      return new TriangleModel(a, b, c, A, B, C);
    }

    // Aucun côté défini mais un angle, on divise l'autre angle en 2
    if(!da && !db && !dc){
      if((dA ? 1 : 0) + (dB ? 1 : 0) + (dC ? 1 : 0) === 1){
        if(dA){
          B = C = (180 - A) / 2;
        }else if(dB){
          A = C = (180 - B) / 2;
        }else if(dC){
          A = B = (180 - C) / 2;
        }
        dA = dB = dC = true;
      }
    }

    // Les trois angles et aucuns côté défini
    if(dA && dB && dC && !da && !db && !dc){
      // Retourne un triangle dont le périmètre est 1
      a = TriangleModel.perimeter1(A, B, C);
      b = TriangleModel.perimeter1(B, A, C);
      c = TriangleModel.perimeter1(C, A, B);
      return new TriangleModel(a, b, c, A, B, C);
    }

    // Un angle et les deux côtés adjacents
    if(dA && db && dc){
      a = TriangleModel.cosineLaw(A, b, c);
      B = TriangleModel.cosineLaw2(b, a, c);
      C = TriangleModel.cosineLaw2(c, a, b);
      return new TriangleModel(a, b, c, A, B, C);
    }
    if(dB && da && dc){
      b =  TriangleModel.cosineLaw(B, a, c);
      A = TriangleModel.cosineLaw2(a, b, c);
      C = TriangleModel.cosineLaw2(c, a, b);
      return new TriangleModel(a, b, c, A, B, C);
    }
    if(dC && da && db){
      c = TriangleModel.cosineLaw(C, a, b);
      A = TriangleModel.cosineLaw2(a, b, c);
      B = TriangleModel.cosineLaw2(b, a, c);
      return new TriangleModel(a, b, c, A, B, C);
    }

    // Un angle et son côté opposé, et un autre côté
    if(dA && da && db){
      B = TriangleModel.sineLaw(A, a, b);
      C = 180 - (A + B);
      c = TriangleModel.cosineLaw(C, a, b);
      return new TriangleModel(a, b, c, A, B, C);
    }
    if(dA && da && dc){
      C = TriangleModel.sineLaw(A, a, c);
      B = 180 - (A + C);
      b = TriangleModel.cosineLaw(B, a, c);
      return new TriangleModel(a, b, c, A, B, C);
    }
    if(dB && db && da){
      A = TriangleModel.sineLaw(B, b, a);
      C = 180 - (A + B);
      c = TriangleModel.cosineLaw(C, a, b);
      return new TriangleModel(a, b, c, A, B, C);
    }
    if(dB && db && dc){
      C = TriangleModel.sineLaw(B, b, c);
      A = 180 - (B + C);
      a = TriangleModel.cosineLaw(A, b, c);
      return new TriangleModel(a, b, c, A, B, C);
    }
    if(dC && dc && da){
      A = TriangleModel.sineLaw(C, c, a);
      B = 180 - (A + C);
      b = TriangleModel.cosineLaw(B, a, c);
      return new TriangleModel(a, b, c, A, B, C);
    }
    if(dC && dc && db){
      B = TriangleModel.sineLaw(C, c, b);
      A = 180 - (A + C);
      a = TriangleModel.cosineLaw(A, b, c);
      return new TriangleModel(a, b, c, A, B, C);
    }

    // Deux angles et un côté
    if(dA && dB && dC){
      if(da){
        b = TriangleModel.sineLaw2(A, a, B);
        c = TriangleModel.sineLaw2(A, a, C);
      }else if(db){
        a = TriangleModel.sineLaw2(B, b, A);
        c = TriangleModel.sineLaw2(B, b, C);
      }else if(dc){
        a = TriangleModel.sineLaw2(C, c, A);
        b = TriangleModel.sineLaw2(C, c, B);
      }
      return new TriangleModel(a, b, c, A, B, C);
    }

    // Retourne un triangle par défaut si on ne peut pas déterminer toutes ses mesures
    return new TriangleModel(1, 1, 1, 60, 60, 60);
  }

  /**
   * Retourne la mesure du côté opposé à l'angle A, de sorte que le périmètre
   * du triangle est 1.
   */
  private static perimeter1(Aarg:number, Barg:number, Carg:number):number{
    const A = TriangleModel.rad(Aarg);
    const B = TriangleModel.rad(Barg);
    const C = TriangleModel.rad(Carg);
    const a:number = 1;
    const b:number = a * Math.sin(B) / Math.sin(A);
    const c:number = a * Math.sin(C) / Math.sin(A);
    const p:number = a + b + c;
    return a / p;
  }

  /**
   * Retourne la mesure du côté opposé à l'angle
   * a1 où c1 et c2 sont les côtés adjacents.
   */
  private static cosineLaw(a1:number, c1:number, c2:number):number{
    return Math.sqrt(c1 * c1 + c2 * c2 - 2 * c1 * c2 * Math.cos(TriangleModel.rad(a1)));
  }

  /**
   * Retourne la mesure de l'angle en degrés
   * opposé à c2 où a1 est opposé à c1.
   */
  private static sineLaw(a1:number, c1:number, c2:number):number{
    const sinA2:number = Math.sin(TriangleModel.rad(a1)) * c2 / c1;
    return TriangleModel.deg(Math.asin(sinA2));
  }

  /**
   * Retourne la mesure du côté opposé à l'angle a2 où
   * l'angle a1 est à l'opposé de c1.
   */
  private static sineLaw2(a1:number, c1:number, a2:number):number{
    return c1 * Math.sin(TriangleModel.rad(a2)) / Math.sin(TriangleModel.rad(a1));
  }

  /**
   * Retourne la mesure de l'angle opposé à c1 en degrés.
   */
  private static cosineLaw2(
      c1:number,
      c2:number,
      c3:number):number{
    return TriangleModel.deg(Math.acos(((c2 * c2) + (c3 * c3) - (c1 * c1)) / (2 * c2 * c3)));
  }

  /**
   * Convertit un angle de radians en degrés.
   */
  private static deg(rad:number):number{
    return rad * 180 / Math.PI;
  }

  /**
   * Convertit un angle de radians en degrés.
   */
  private static rad(deg:number):number{
    return deg * Math.PI / 180;
  }

}
