import { Attributes } from '../../elements/abstract/Attributes';
import { ConstructorElement } from '../../elements/abstract/ConstructorElement';

/**
 *
 */
export class Declare extends ConstructorElement {

  public getAttributes():number{
    return super.getAttributes() | Attributes.ASSIGNABLE;
  }

}
