import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WList } from '../../elements/tokens/WList';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { WListOfList } from '../../elements/tokens/WListOfList';
import { WListOfListOfString } from '../../elements/tokens/WListOfListOfString';

/**
 * Convert to a list.
 * Can be a list (reals), a list of list (reals) or a list of list of strings
 */
export class Listify extends FunctionElement {
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length > 2) {
      return args.expectingArguments(0, 2);
    }

    if (args.length === 0) {
      return args.env.culture.createEmptyList();
    }

    if (args.length === 2) {
      const reals = [args.getReals(0), args.getReals(1)];
      if (reals[0] && reals[1]) {
        return new WListOfList(reals, args.env.culture.listFormatter);
      }

      const strings = [args.getStrings(0), args.getStrings(1)];
      if (strings[0] && strings[1]) {
        return new WListOfListOfString(strings, args.env.culture.listFormatter);
      }
    }

    const list = args.getList(0);
    if (list) {
      return list;
    }

    const finiteSet = args.getFiniteSet(0);
    if (finiteSet) {
      if (finiteSet.isNumeric) {
        return finiteSet.toList();
      }

      if (finiteSet.isStrings) {
        return finiteSet.toListOfString();
      }

      if (finiteSet.hasNumericElements) {
        return finiteSet.toListOfList();
      }

      if (finiteSet.hasStringElements) {
        return finiteSet.toListOfListOfString();
      }
    }

    const range = args.getRange(0);
    if (range) {
      return args.env.culture.listFactory.createList(range.toItems());
    }

    const matrix = args.getMatrix(0);
    if (matrix) {
      return WList.createFromReals(matrix.values, args.env.culture.listFormatter);
    }

    return null;
  }
}
