/**
 * Set of primes numbers
 */
export class Primes {

  private static _instance:Primes;

  public static getInstance():Primes{
    if(!Primes._instance){
      Primes._instance = new Primes();
    }
    return Primes._instance;
  }

  private static cache:any[] = [];

  public elementOf(n:number):boolean{
    if(isNaN(Number(n))){
      return false;
    }
    if(n < 2){
      return false; // Inférieur à 2 : pas premier
    }
    if(Math.floor(n) !== n){
      return false; // Nombre décimal, pas premier
    }
    if(n === 2){
      return true; // Egal à 2 : premier
    }
    if(Primes.cache[n]){
      return Primes.cache[n];
    }
    Primes.cache[n] = this.bruteForce(n);
    return Primes.cache[n];
  }

  public notElementOf(n:number):boolean{
    // REFACTOR: can do better
    return !this.elementOf(n);
  }

  /**
   * Indique avec une certitude absolue si un nombre est premier
   */
  private bruteForce(n:number):boolean{
    let i:number;

    // Inférieur à 2 : pas premier
    if (n < 2) {
      return false;
    }

    // Egal à 2 : premier
    if (n === 2) {
      return true;
    }

    // Teste tous les diviseur inférieur au nombre (de 2 à x-1)
    i = 2;
    while (i < n) {
      // Si on peut diviser ce nombre : il n'est pas premier
      if (n % i === 0) {
        return false;
      }

      // Passe au prochain diviseur
      i++;
    }

    // Aucun ne le divise : il est premier
    return true;
  }

}
