/* eslint-disable no-restricted-imports */
import { IMmlpx, modelNameSymbol } from 'mmlpx/esm/core/dependency-inject/meta';
import { instantiate } from 'mmlpx';
/* eslint-enable no-restricted-imports */

type Constructor<T> = new(...args: any[]) => T;

export const inject = <T>(InjectedClass: Constructor<T>, ...args: any[]): any => (target: any, property: string) => {
  const symbol = Symbol(property);

  return {
    enumerable: true,
    configurable: true,
    get(this: any) {
      if (!this[symbol] || this[symbol][modelNameSymbol] !== InjectedClass[modelNameSymbol]) {
        const initializedValue = instantiate.apply(this, [InjectedClass as IMmlpx<T>, ...args]);
        this[symbol] = initializedValue;
        return initializedValue;
      }
      return this[symbol];
    },
    set() {},
  };
};
