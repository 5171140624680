import React from 'react';
import styled from 'styled-components';
import SearchIcon from '../../assets/search.svg';
import { isNullOrEmptyOrUndefined } from '../../utils/string';
import { setHexColorTransparency, ColorSet } from '../../themes/ColorSet';
import { themedColor } from '../../themes/utils';
import { Tooltip } from '../utils/Tooltip';

export interface IExpandableSearchInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  readonly className?: string;
  readonly tooltip?: React.ReactNode;
  readonly onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  readonly onEnter?: () => void;
  readonly alwaysExpanded?: boolean;
}

export const EXPANDABLE_SEARCH_INPUT_TEST_ID = 'EXPANDABLE_SEARCH_INPUT_TEST_ID';

const ExpandableSearchInputComponent: React.ForwardRefRenderFunction<HTMLInputElement, IExpandableSearchInputProps> = (props, ref) => {
  const {
    value,
    className,
    disabled,
    onBlur,
    onFocus,
    tooltip = 'Search',
    onChange,
    onEnter,
    alwaysExpanded,
    ...otherProps
  } = props;

  const [hasFocus, setHasFocus] = React.useState(false);
  const isDisabled = disabled;

  const inputRef = React.useRef<HTMLInputElement>(null);
  React.useImperativeHandle<HTMLInputElement | null, HTMLInputElement | null>(ref, () => inputRef.current);

  const localOnKeyUp =
    React.useCallback(
      (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' && onEnter) {
          onEnter();
        }
      },
      [onEnter]);
  const localOnFocus =
    React.useCallback(
      (event: React.FocusEvent<HTMLInputElement>) => {
        setHasFocus(true);
        if (onFocus) {
          onFocus(event);
        }
      },
      [onFocus]);
  const localOnBlur =
    React.useCallback(
      (event: React.FocusEvent<HTMLInputElement>) => {
        setHasFocus(false);
        if (onBlur) {
          onBlur(event);
        }
      },
      [onBlur]);

  const onClick = () => {
    inputRef.current.focus();
  }

  const expanded = hasFocus || !isNullOrEmptyOrUndefined(value as string) || alwaysExpanded;

  const input = (
    <Input
      ref={inputRef}
      value={value}
      onChange={onChange}
      onKeyUp={localOnKeyUp}
      onBlur={localOnBlur}
      onFocus={localOnFocus}
      disabled={isDisabled}
      expanded={expanded}
      data-testid={EXPANDABLE_SEARCH_INPUT_TEST_ID}
      {...otherProps}
    />
  );

  const container = (
    <Container
      className={className}
      disabled={isDisabled}
      hasFocus={hasFocus}
      expanded={expanded}
    >
      {input}
      <Tooltip title={tooltip}>
        <SearchIconContainer
          expanded={expanded}
          onClick={onClick}
        >
          <SearchIcon/>
        </SearchIconContainer>
      </Tooltip>
    </Container>
  );

  return container;
};

export const ExpandableSearchInput = React.forwardRef(ExpandableSearchInputComponent);

interface IContainer {
  disabled: boolean;
  hasFocus: boolean;
}

interface IExpandable {
  expanded: boolean;
}

const SearchIconContainer = styled.div<IExpandable>`
  position: absolute;
  bottom: 0;
  top: 0;
  margin:auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  border-radius: 4px;
  cursor: ${(props) => props.expanded ? 'default' : 'pointer'};

  >svg {
    height: ${(props) => props.expanded ? '18px' : '24px'};
    width: ${(props) => props.expanded ? '18px' : '24px'};
    transition: height 100ms linear, width 100ms linear;
    color: ${themedColor({ dark: ColorSet.white, light: ColorSet.grey900 })};
  }
`;

const Container = styled.div<IContainer & IExpandable>`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  gap: 0px;
  box-sizing: border-box;
  position: relative;
  transition: border-color 100ms linear, width 100ms linear;
  border-radius: 4px;
  color: ${(props) => props.theme.input.default.color};
  border: 1px solid transparent;
  justify-content: flex-end;
  width: 0%;
  min-width: 32px;


  ${(props) => props.hasFocus && `
    border: ${props.theme.input.default.focus.border};
  `}

  ${(props) => props.disabled && `
    background-color: ${props.theme.input.default.disabled.backgroundColor};
    border: ${props.theme.input.default.disabled.border};
    color: ${props.theme.input.default.disabled.color};
    pointer-events: none;
  `}

  ${(props) => props.expanded && `
    border: ${props.theme.input.default.border};
    background-color: ${props.theme.input.default.backgroundColor};
    width: 100%;
  `}

  &:hover {
    ${SearchIconContainer} {
      background-color:
        ${(props) => props.expanded ? props.theme.input.default.backgroundColor : setHexColorTransparency(props.theme.colorset.grey07, 0.2)};
    }
  }
`;

const Input = styled.input<IExpandable>`
  border: unset;
  outline: unset;
  background-color: transparent;
  font: ${(props) => props.theme.typeset.body2SemiBold};
  font-size: 14px;
  color: ${(props) =>
    (props.disabled ?
      props.theme.input.default.disabled :
      props.theme.input.default).color};
  width: ${(props) => props.expanded ? '100%' : '0%'};
  min-width: 32px;
  margin-right: ${(props) => props.expanded ? '32px' : '0px'};
  padding-left: ${(props) => props.expanded ? '8px' : '0px'};
  cursor: ${(props) => props.expanded ? 'text' : 'pointer'};
  height: 32px;
  box-sizing: border-box;
  transition: width 100ms linear, margin-right 100ms linear;
`;
