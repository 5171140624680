import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WBoundVector } from '../../elements/tokens/WBoundVector';
import { WPoint } from '../../elements/tokens/WPoint';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { WSpecialCase } from '../../elements/tokens/WSpecialCase';

/**
 * Constructeur de vecteur.
 */
export class BoundVector extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 2){
      return args.expectingArguments(2, 2);
    }
    if(args.getPoint(0) && args.getPoint(1)) {
      return this.points(args.getPoint(0), args.getPoint(1));
    }
    return null;
  }

  /**
   *
   */
  private points(a:WPoint, b:WPoint):WBoundVector | WSpecialCase {
    if(a.equalsTo(b)){
      return new WSpecialCase('Degenerate vector');
    }
    return new WBoundVector(a.toPoint(), b.toPoint());
  }

}
