import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WList } from '../../elements/tokens/WList';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class QuintileRank extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 2){
      return args.expectingArguments(2, 2);
    }

    const list:WList = args.getReals(0);
    if(!list){
      return null;
    }

    const n:RealElement = args.getReal(1);
    if(!n){
      return null;
    }

    return args.env.culture.createNumber(this.rank(list.toNumbersV(), n.toNumber()));
  }

  /**
   *
   */
  private rank(
      values:number[],
      n:number):number{

    let gt:number = 0;
    let e:number = 0;
    const c:number = values.length;

    for(let i:number = 0 ; i < c ; i++){
      const o:number = values[i];
      if(o > n){
        gt++;
      }
      if(o === n){
        e++;
      }
    }

    return Math.ceil(5 * (gt + 0.5 * e) / c);
  }

}
