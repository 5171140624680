import { runInAction } from 'mobx';
import { instantiate } from '../dependencyInjection/instantiate';
import { ActivityStore } from '../models/store/ActivityStore';
import { PagesStore } from '../models/store/PagesStore';

export function activityClearAll() {
  const activity = instantiate(ActivityStore);
  const pages = instantiate(PagesStore);

  runInAction(() => {
    activity.clear();
    pages.clear();
  });
}
