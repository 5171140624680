import { Attributes } from '../../elements/abstract/Attributes';
import { SetElement } from '../../elements/abstract/SetElement';
import { BaseIntervalFormatter } from '../../elements/formats/BaseIntervalFormatter';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';
import { IntervalClosure } from '../../elements/models/IntervalClosure';
import { WComplement } from '../../elements/tokens/WComplement';
import { WInterval } from '../../elements/tokens/WInterval';

/**
 *
 */
export class WUnion extends SetElement {

  private _parts:SetElement[];

  public get parts():SetElement[]{return this._parts;}

  /**
   *
   */
  private _formatter:BaseIntervalFormatter;

  public get formatter():BaseIntervalFormatter{
    return this._formatter;
  }

  /**
   *
   */
  constructor(
      parts:SetElement[],
      formatter:BaseIntervalFormatter) {

    super();
    this._parts = parts.sort(WUnion.compareSets);
    this._formatter = formatter;
  }

  /**
   *
   */
  private static compareSets(a:SetElement, b:SetElement):number{
    if(a instanceof WInterval && b instanceof WInterval){
      return WInterval.compareBounds(
        (<WInterval>a ).lBoundP,
        (<WInterval>b ).rBoundP);
    }
    return 0;
  }

  /**
   *
   */
  public getAttributes():number{
    return super.getAttributes() | Attributes.COMPLEX_CONTENT;
  }

  /**
   * Cases:
   * -1. Interval U interval --> R \ interval ?
   */
  public toComplement():WComplement{
    if(this._parts.length === 2 && this._parts[0] instanceof WInterval && this._parts[1] instanceof WInterval){
      const i0:WInterval = <WInterval>this._parts[0] ;
      const i1:WInterval = <WInterval>this._parts[1] ;

      // (0, 10) --> 0 < x < 10
      // (inf, 0) --> x < 0
      // (0, inf) --> x > 0

      if(i0.intersection(i1) == null && !i0.isFinite && !i1.isFinite){
        if(i0.lBound != null){
          return new WComplement(
            this.formatter.culture.createNumbers('ℝ'),
            new WInterval(
              IntervalClosure.parse(
                !i1.closure.upper,
                !i0.closure.lower),
              i1.rBound,
              i0.lBound,
              this.formatter));
        }
        if(i0.rBound != null){
          return new WComplement(
            this.formatter.culture.createNumbers('ℝ'),
            new WInterval(
              IntervalClosure.parse(
                !i0.closure.upper,
                !i1.closure.lower),
              i0.rBound,
              i1.lBound,
              this.formatter));
        }
      }
    }
    return null;
  }

  /**
   *
   */
  public writeTo(exporter:IMarkupExporter = null):boolean{
    if(exporter){
      exporter.writer.beginRow();
      for(let i:number = 0 ; i < this.parts.length; i++){
        if(i > 0){
          exporter.writer.appendOperator('∪');
        }
        this.parts[i].writeTo(exporter);
      }
      exporter.writer.endRow();
    }
    return true;
  }

  public getType():string {
    return 'union';
  }
}
