import React from 'react';

interface IContextMenuContext {
  lastHoveredMenuItemId: string;
  subMenuDirection: 'left' | 'right';
  onMenuItemMouseEnter: (menuItemId: string) => void;
}

export const ContextMenuContext = React.createContext<IContextMenuContext>({
  lastHoveredMenuItemId: null,
  onMenuItemMouseEnter: null,
  subMenuDirection: 'right',
});
