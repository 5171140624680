import { ConstructorElement } from '../../elements/abstract/ConstructorElement';
import { IntervalClosure } from '../../elements/models/IntervalClosure';

/**
 *
 */
export class Interval extends ConstructorElement {

  /**
   * open|closed|open-closed|closed-open
   */
  public closure:IntervalClosure;

  /**
   *
   */
  constructor(closure:IntervalClosure){
    super();
    this.closure = closure;
  }

}
