import { AbstractStep } from '../AbstractStep';
import { Compartment } from '../Compartment';
import { LongMultiplicationOperation } from '../LongMultiplicationOperation';

/**
 *
 */
export class MultCarry extends AbstractStep {

  private carry:number;

  constructor(mult:LongMultiplicationOperation){
    super(mult);
    this.carry = mult.multCarry;
    const carry:Compartment = Compartment.createCarry(mult.multCarry);
    mult.lastMultCarries.unshift(carry);
    mult.multCarried = mult.multCarry;
    mult.multCarry = 0;
    mult.nextMult();
    this.target.push(carry);
    this.setDescription(mult.culture.getString('LongMultiplicationOperation.multCarryLabel', this.carry));
  }

}
