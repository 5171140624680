import { Editor, Element, Node, Path, Transforms } from '../TypedSlate';
import { ICustomEditor } from '../models/editor';
import { IParagraphElement } from '../models/elements';

/**
 * @param editor
 * @param value
 */
export const alignText = (editor: ICustomEditor, value: 'left' | 'center' | 'right' | 'justify'): void => {
  const paragraphs = Editor.nodes<IParagraphElement>(editor, {
    mode: 'all',
    match: (n) => {
      return !Editor.isEditor(n) && Element.isElement(n) && n.type === 'paragraph';
    },
  });

  for (const paragraph of paragraphs) {
    const newNodeValue: Partial<IParagraphElement> = {
      textAlign: value,
    };
    Transforms.setNodes(
      editor,
      newNodeValue,
      {
        match: (node: Node, path: Path) => {
          return node === paragraph[0];
        },
      });
  }
};

/**
 * Default left.
 *
 * @param editor
 * @param value
 */
export const isTextAligned = (editor: ICustomEditor, value: 'left' | 'center' | 'right' | 'justify'): boolean => {
  if (!editor.selection) return false;
  const paragraphs = Editor.nodes<IParagraphElement>(editor, {
    mode: 'all',
    match: (n) => {
      return !Editor.isEditor(n) && Element.isElement(n) && n.type === 'paragraph';
    },
  });

  let hasParagraphs = false;
  for (const paragraph of paragraphs) {
    hasParagraphs = true;
    const textAlign = paragraph[0].textAlign ? paragraph[0].textAlign : 'left';
    if (textAlign !== value) {
      return false;
    }
  }

  return hasParagraphs;
};
