import { XRound } from '../../core/XRound';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WDistribution } from '../../elements/tokens/WDistribution';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class SampleDistribution extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length < 1 || args.length > 3){
      return args.expectingArguments(1, 3);
    }

    const distribution:WDistribution = args.getDistribution(0);
    if(!distribution){
      return null;
    }

    if(args.length === 1){
      return distribution.sample(args.prng);
    }

    const count:RealElement = args.getWholeNumber(1);
    if(!count){
      return null;
    }

    const precision:RealElement = args.length > 2 ? args.getInteger(2) : null;
    if(args.length > 2 && !precision){
      return null;
    }

    const o:number[] = [];
    for(let i:number = 0 ; i < count.toNumber() ; i++){
      let n:number = distribution.sample(args.prng).toNumber();
      if(precision){
        n = XRound.halfAway(n, precision.toNumber());
      }
      o.push(n);
    }

    return args.env.culture.listFactory.createFromNumbers(o);
  }

}
