import { Rectangle } from '../../../js/geom/Rectangle';

import { XMath } from '../../core/XMath';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { TokenElement } from '../../elements/abstract/TokenElement';
import { TDDiagram } from '../../elements/models/tree/TDDiagram';
import { TDNode } from '../../elements/models/tree/TDNode';
import { TDObject } from '../../elements/models/tree/TDObject';
import { TDResult } from '../../elements/models/tree/TDResult';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';

/**
 *
 */
export class WTreeDiagram extends TokenElement {

  private _model:TDDiagram;

  public get model():TDDiagram{return this._model;}

  private _displayMode:Rectangle;

  public get displayMode():Rectangle{return this._displayMode;}

  /**
   *
   */
  constructor(
      model:TDDiagram,
      displayMode:Rectangle){
    super();
    this._model = model;
    this._displayMode = displayMode;
  }

  /**
   *
   */
  public writeTo(exporter:IMarkupExporter = null):boolean{
    if(exporter){
      exporter.writer.appendText('[TreeDiagram]');
    }
    return true;
  }

  /**
   * Two tree are equals if they have the same structure,
   * the same propabilities and the same results.
   */
  public equalsTo(value:ContentElement):boolean{
    if(value instanceof WTreeDiagram){
      return this.nodeEquals(this.model.root, (<WTreeDiagram>value ).model.root);
    }
    return false;
  }

  /**
   *
   */
  private nodeEquals(a:TDNode, b:TDNode):boolean{
    // 1. Check value
    if(a.value && b.value){
      if(!a.value.equalsTo(b.value)){
        return false;
      }
    }else if(a.value || b.value){
      return false;
    }

    // 2. Check prob
    if(a.prob && b.prob){
      if(!XMath.safeEquals(a.prob.toNumber(), b.prob.toNumber())){
        return false;
      }
    }else if(a.prob || b.prob){
      return false;
    }

    // 3. Check results
    if(a.isLeaf && b.isLeaf){
      if(a.result && b.result){
        if(!this.resultEquals(a.result, b.result)){
          return false;
        }
      }else if(a.result || b.result){
        return false;
      }
    }else if(a.isLeaf || b.isLeaf){
      return false;
    }

    // 4. Check child list
    if(a.numChildren !== b.numChildren){
      return false;
    }

    const ca:TDNode[] = a.children.concat().sort(TDNode.compare);
    const cb:TDNode[] = b.children.concat().sort(TDNode.compare);

    for(let i:number = 0 ; i < ca.length ; i++){
      if(!this.nodeEquals(ca[i], cb[i])){
        return false;
      }
    }

    return true;
  }

  /**
   *
   */
  private resultEquals(a:TDResult, b:TDResult):boolean{
    // 1. Check values
    if(a.values && b.values){
      if(a.values.length !== b.values.length){
        return false;
      }

      const va:TDObject[] = a.ordered ? a.values : a.values.concat().sort(TDObject.compare);
      const vb:TDObject[] = b.ordered ? b.values : b.values.concat().sort(TDObject.compare);

      for(let i:number = 0 ; i < va.length ; i++){
        if(!va[i].equalsTo(vb[i])){
          return false;
        }
      }
    }

    // 2. Check numeric value
    if(!isNaN(a.numericValue) && !isNaN(b.numericValue)){
      if(!XMath.safeEquals(a.numericValue, b.numericValue)){
        return false;
      }
    }else if(!isNaN(a.numericValue) || !isNaN(b.numericValue)){
      return false;
    }

    // 3. Check cumulative probability
    if(a.c_prob && b.c_prob){
      if(!XMath.safeEquals(a.c_prob.toNumber(), b.c_prob.toNumber())){
        return false;
      }
    }else if(a.c_prob || b.c_prob){
      return false;
    }

    return true;
  }

  public getType():string {
    return 'treeDiagram';
  }
}
