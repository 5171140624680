import { XMath } from '../../core/XMath';
import { MmlWriter } from '../../core/mml/MmlWriter';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { SymbolElement } from '../../elements/abstract/SymbolElement';
import { TokenElement } from '../../elements/abstract/TokenElement';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';
import { WRational } from '../../elements/tokens/WRational';

/**
 *
 */
export class WExponential extends TokenElement {

  private _coefficient:RealElement;

  public get coefficient():RealElement{return this._coefficient;}

  private _base:RealElement;

  public get base():RealElement{return this._base;}

  private _exponent:SymbolElement;

  public get exponent():SymbolElement{return this._exponent;}

  /**
   *
   */
  constructor(
      coefficient:RealElement,
      base:RealElement,
      exponent:SymbolElement){
    super();
    this._coefficient = coefficient;
    this._base = base;
    this._exponent = exponent;
  }

  /**
   *
   */
  public equalsTo(value:ContentElement):boolean{
    if(value instanceof WExponential){
      return  XMath.safeEquals(this.base.toNumber(), (<WExponential>value ).base.toNumber()) &&
          XMath.safeEquals(this.coefficient.toNumber(), (<WExponential>value ).coefficient.toNumber());
    }
    return false;
  }

  /**
   *
   */
  public writeTo(exporter:IMarkupExporter = null):boolean{
    if(exporter){
      const w:MmlWriter = exporter.writer;

      w.beginRow();

      // coefficient
      if(this.coefficient.toNumber() === -1){
        w.appendOperator('−');
      }else if(this.coefficient.toNumber() !== 1){
        this.coefficient.writeTo(exporter);
        w.appendOperator('\u2062'); // invisible times
      }

      w.beginSup();

      // base
      if(this.encloseBase){
        w.beginFenced();
      }
      this.base.writeTo(exporter);
      if(this.encloseBase){
        w.endFenced();
      }

      // exponent
      w.appendIdentifier(this.exponent.getSymbol());

      w.endSup();
      w.endRow();
    }
    return true;
  }

  /**
   *
   */
  public get encloseBase():boolean{
    return this.coefficient.toNumber() !== 1 || this.base instanceof WRational;
  }

  /**
   *
   */
  public getType():string{
    return 'exponential';
  }

}
