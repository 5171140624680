import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class ArcTan extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }

    const tan:RealElement = args.getReal(0);
    if(!tan){
      return null;
    }

    return args.env.culture.parseNumber(Math.atan(tan.toNumber()));
  }

}
