import { Point, Path, Text, Node, Element, Selection, Descendant } from '../TypedSlate';
import { ICustomDescendant, ICustomText, ICustomElement, ICustomEditor } from '../models/editor';

export const findPath = (rootElements: ICustomDescendant[], element: ICustomDescendant): Path => {
  if (!rootElements) {
    return null;
  }
  for (let i = 0; i < rootElements.length; i += 1) {
    const currentElement = rootElements[i];
    if (currentElement === element) {
      return [i];
    }
    if (Element.isElement(currentElement)) {
      const path = findPath(currentElement.children, element);
      if (path) {
        return [i].concat(path);
      }
    }
  }
  return null;
};

export const getValidatedSelection = (selection: Selection, descendants: ReadonlyArray<Descendant>): Selection => {
  if (!selection) {
    return null;
  }
  return {
    anchor: getValidatedPoint(selection.anchor, descendants),
    focus: getValidatedPoint(selection.focus, descendants),
  };
};

export const getValidatedPoint = (point: Point, descendants: ReadonlyArray<Descendant>): Point => {
  const validatedPath = getValidatedPath(point.path, descendants);
  const elementAtPath = Node.get({ children: descendants } as ICustomEditor, validatedPath);
  let validatedOffset = 0;
  if (Text.isText(elementAtPath)) {
    validatedOffset = Math.min(point.offset, elementAtPath.text.length);
  }
  return {
    path: validatedPath,
    offset: validatedOffset,
  };
};

export const getValidatedPath = (path: Path, descendants: ReadonlyArray<Descendant>): Path => {
  const currentIndex = path[0];
  const descendant = descendants[currentIndex];
  if (!descendant) {
    return [descendants.length - 1];
  }
  if (!(descendant as ICustomElement).children) {
    return [currentIndex];
  }
  return [currentIndex].concat(
    getValidatedPath(
      path.slice(1),
      (descendant as ICustomElement).children,
    ),
  );
};

export const getDocumentEnd = (descendants: ReadonlyArray<ICustomDescendant>): Point => {
  const lastDescendantIndex = descendants.length - 1;
  const lastDescendant = descendants[lastDescendantIndex];
  if ((lastDescendant as ICustomText).text !== undefined) {
    return {
      path: [lastDescendantIndex],
      offset: (lastDescendant as ICustomText).text.length - 1,
    };
  }
  const childEnd = getDocumentEnd((lastDescendant as ICustomElement).children);
  return {
    path: [lastDescendantIndex].concat(childEnd.path),
    offset: childEnd.offset,
  };
};
