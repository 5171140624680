import { Point } from '../../../../js/geom/Point';

import { ContentElement } from '../../../elements/abstract/ContentElement';
import { Node } from '../../../elements/abstract/Node';
import { RealElement } from '../../../elements/abstract/RealElement';
import { BaseRadicalFormatter } from '../../../elements/formats/BaseRadicalFormatter';
import { RadicalFormatter } from '../../../elements/formats/radicals/RadicalFormatter';
import { RadicalPrimeFactorizationFormatter } from '../../../elements/formats/radicals/RadicalPrimeFactorizationFormatter';
import { WRadical } from '../../../elements/tokens/WRadical';
import { Environment } from '../../../expr/Environment';
import { TokensImporter } from '../../../expr/conversion/input/TokensImporter';
import { GroupClose } from '../../../expr/conversion/input/tempTokens/GroupClose';
import { GroupOpen } from '../../../expr/conversion/input/tempTokens/GroupOpen';
import { Skeleton } from '../../../expr/manipulation/Skeleton';
import { Divide } from '../../../funcs/arithmetic/Divide';
import { Times } from '../../../funcs/arithmetic/Times';
import { ReduceRadical } from '../../../expr/manipulation/rules/ReduceRadical';
import { AbstractRule } from '../../../expr/manipulation/rules/AbstractRule';

/**
 * Simplify radicals division and try
 * to remove radicals in the denominator.
 *
 * This rule only applies when detailed
 * steps are requested.
 */
export class RadicalsDivision extends AbstractRule {

  /**
   *
   */
  constructor(lockFlag:number){
    super(true, false);
    this._lockFlag = lockFlag;
  }

  /**
   * Handles division of radical with number:
   * - n/z
   * - z/n
   * - z/z
   */
  public applyNode(node:Node, stateMode:number, env:Environment):Node{
    if(!node.isSimple){
      return null;
    }

    const s:string = Skeleton.createSkeleton(node);
    if(s !== '/(z,z)' && s !== '/(n,z)' && s !== '/(z,n)'){
      return null;
    }

    this._comment = null;
    this._pause = false;

    let numerator:WRadical = WRadical.parseElement(node.childs[1].value);
    let denominator:WRadical = WRadical.parseElement(node.childs[2].value);
    const defaultFormat:BaseRadicalFormatter = env.culture.formats.radicalFormatImpl;

    const factorizationFormat1:BaseRadicalFormatter = new RadicalPrimeFactorizationFormatter(env.culture, 1, 1);
    const factorizationFormat2:BaseRadicalFormatter = new RadicalPrimeFactorizationFormatter(env.culture, 2, 1);

    // If numerator or denominator is not a radical,
    // then coerce into a radical with a base of 1.
    if(!numerator){
      numerator = this.coerceRadical(node.childs[1].value, denominator.index, defaultFormat);
    }
    if(!denominator){
      denominator = this.coerceRadical(node.childs[2].value, numerator.index, defaultFormat);
    }

    // Check index, must be a natural number to continue.
    if(!numerator.index.isNaturalNumber()){
      return null;
    }

    if(!denominator.index.isNaturalNumber()){
      return null;
    }

    const numeratorF:Point = numerator.factorizeBase;
    const denominatorF:Point = denominator.factorizeBase;

    // Check that one of the two base can be simplified.
    if(numeratorF.x !== 1 || denominatorF.x !== 1){
      if(this.initialize(numerator, denominator, env) && (ReduceRadical.splitBaseFactorization(numerator) || ReduceRadical.splitBaseFactorization(denominator))){
        // Step 1 of base simplification
        this._tag = 'base-simplification-1a';
        return this.initialize(numerator, denominator, env);
      }
      if(numerator.formatter.factorizationLevel <= 1 ||
           denominator.formatter.factorizationLevel <= 1){
        // Step 2 of base simplification
        this._tag = 'base-simplification-1b';
        return TokensImporter.importTokens([
          numerator.applyFormat(factorizationFormat2),
          Divide.getInstance(),
          denominator.applyFormat(factorizationFormat2)], env);
      }
      // Do base simplification
      numerator = numeratorF.x !== 1 ? <WRadical>numerator.toReduced(env.reals).applyFormat(factorizationFormat1)  : numerator;
      denominator = denominatorF.x !== 1 ? <WRadical>denominator.toReduced(env.reals).applyFormat(factorizationFormat1)  : denominator;
      this._tag = 'base-simplification-1c';
      return TokensImporter.importTokens([
        numerator,
        Divide.getInstance(),
        denominator], env);

    }
    // Check that coefficients can be simplified.
    const newCoefs:RealElement[] = env.reals.reduceFactors(numerator.coefficient, denominator.coefficient);
    if(!newCoefs){

      if(numerator.index.toNumber() === denominator.index.toNumber()){

        // Check if we can simplify the base √(ab) --> √a*√(b)
        const newBases:RealElement[] = env.reals.reduceFactors(numerator.base, denominator.base);
        if(newBases){

          if(this.initialize(numerator, denominator, env)){
            // Step 1 of base simplification
            this._tag = 'base-simplification-2a';
            return this.initialize(numerator, denominator, env);
          }

          if(		numerator.formatter.factorizationLevel < 4 ||
              denominator.formatter.factorizationLevel < 4){
            numerator = <WRadical>numerator.applyFormat(new RadicalPrimeFactorizationFormatter(env.culture, 4, Number(denominator.base.toNumber()), denominator.isBaseOne ? 1 : 3)) ;
            denominator = <WRadical>denominator.applyFormat(new RadicalPrimeFactorizationFormatter(env.culture, 4, Number(numerator.base.toNumber()), numerator.isBaseOne ? 1 : 3)) ;
            this._tag = 'prime-factorization';
            return TokensImporter.importTokens([
              numerator,
              Divide.getInstance(),
              denominator],
              env);
          }

          // Simplify bases
          this._tag = 'base-simplification-2b';
          return TokensImporter.importTokens([
            new WRadical(newBases[0], numerator.index, numerator.coefficient, defaultFormat),
            Divide.getInstance(),
            new WRadical(newBases[1], denominator.index, denominator.coefficient, defaultFormat)], env);
        }

        if(!denominator.isBaseOne){
          const multiplyBy:WRadical =
            new WRadical(
              denominator.base,
              denominator.index,
              env.culture.createNumber(1),
              new RadicalFormatter(env.culture, true, true));

          const dot:Times = new Times();
          dot.other = Times.CROSS;

          const div:Divide = new Divide();
          div.tempClass = 'right';

          this._pause = true;

          let i:number;
          const newExpr:any[] = [new GroupOpen(), numerator];
          for(i = 1 ; i < numerator.index.toNumber() ; i++){
            newExpr.push(dot, multiplyBy);
          }
          // Cancel factorization but do not revert to RadicalNotation
          // in order to avoid initialization of radical division procedure.
          denominator = <WRadical>denominator.applyFormat(new RadicalPrimeFactorizationFormatter(env.culture, 1, 1, 0)) ;
          newExpr.push(new GroupClose(), div, new GroupOpen(), denominator);
          for(i = 1 ; i < denominator.index.toNumber() ; i++){
            newExpr.push(dot, multiplyBy);
          }
          newExpr.push(new GroupClose());

          // Make radical disappear from the denominator
          this._tag = 'rationalize-denominator';
          return TokensImporter.importTokens(newExpr, env);
        }
      }

      // Remove special formatting
      if(		numerator.formatter.factorizationLevel > 0 ||
          denominator.formatter.factorizationLevel > 0){
        this._tag = 'remove-formatting';
        return TokensImporter.importTokens([
          numerator.applyFormat(defaultFormat),
          Divide.getInstance(),
          denominator.applyFormat(defaultFormat)], env);
      }

      return null;
    }

    // Check if there's factors common to the numerator's
    // coefficient and the denominator's coefficient.
    if(	numerator.formatter.factorizationLevel < 3 ||
      denominator.formatter.factorizationLevel < 3){
      // Show crossed-out factors
      numerator = <WRadical>numerator.applyFormat(new RadicalPrimeFactorizationFormatter(env.culture, 3, Number(denominator.coefficient.toNumber()), denominator.isBaseOne ? 1 : 3)) ;
      denominator = <WRadical>denominator.applyFormat(new RadicalPrimeFactorizationFormatter(env.culture, 3, Number(numerator.coefficient.toNumber()), numerator.isBaseOne ? 1 : 3)) ;

      this._tag = 'crossed-out-factors';
      return TokensImporter.importTokens([
        numerator,
        Divide.getInstance(),
        denominator], env);
    }

    // Do coefficient simplification
    numerator = new WRadical(numerator.base, numerator.index, newCoefs[0], new RadicalPrimeFactorizationFormatter(env.culture, 1, 1, denominator.isBaseOne ? 1 : 3));
    denominator = new WRadical(denominator.base, denominator.index, newCoefs[1], new RadicalPrimeFactorizationFormatter(env.culture, 1, 1, numerator.isBaseOne ? 1 : 3));
    this._tag = 'coefficient-simplification';
    return TokensImporter.importTokens([
      numerator.isBaseOne ? numerator.coefficient : numerator,
      Divide.getInstance(),
      denominator.isBaseOne ? denominator.coefficient : denominator], env);
  }

  /**
   *
   */
  private initialize(numerator:WRadical, denominator:WRadical, env:Environment):Node{
    if(		numerator.formatter.factorizationLevel === 0 ||
        denominator.formatter.factorizationLevel === 0){

      return TokensImporter.importTokens([
        numerator.applyFormat(new RadicalPrimeFactorizationFormatter(env.culture, 1, 1, denominator.isBaseOne ? 1 : 3)),
        Divide.getInstance(),
        denominator.applyFormat(new RadicalPrimeFactorizationFormatter(env.culture, 1, 1, numerator.isBaseOne ? 1 : 3))], env);
    }

    return null;
  }

  /**
   *
   */
  private coerceRadical(
      value:ContentElement,
      index:RealElement,
      format:BaseRadicalFormatter):WRadical{

    return value instanceof WRadical ?
      <WRadical>value  :
      new WRadical(
        format.culture.createNumber(1),
        index,
        <RealElement>value ,
        format);
  }

}
