import { XNumber } from '../../core/XNumber';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Truncate number without rounding.
 */
export class Truncate extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length < 1 || args.length > 2){
      return args.expectingArguments(1, 2);
    }

    const value:RealElement = args.getReal(0);
    const precision:RealElement = args.length === 2 ? args.getWholeNumber(1) : args.env.culture.createNumber(0);

    if(value && precision){
      return args.env.culture.createNumber(XNumber.truncate(value.toNumber(), precision.toNumber()));
    }

    return null;
  }

}
