import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WFraction } from '../../elements/tokens/WFraction';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { WPower } from '../../elements/tokens/WPower';
import { Environment } from '../../expr/Environment';

/**
 * Crée une fraction impropre ou bien transforme un nombre fractionnaire en une fraction impropre.
 */
export class Fraction extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length < 1 || args.length > 2){
      return args.expectingArguments(1, 2);
    }

    if(args.length === 1){
      if(args.getRational(0)){
        return args.env.culture.createRational(
          args.getRational(0).numerator,
          args.getRational(0).denominator);
      }
    }else if(args.length === 2){
      const numerator:RealElement = this.validateNumerator(args.getReal(0));
      const denominator:RealElement = this.validateNumerator(args.getReal(1));

      if(numerator && denominator && !this.preserve(numerator, args.env) && !this.preserve(denominator, args.env)){
        return args.env.culture.createRational(
          numerator.toNumber(),
          denominator.toNumber());
      }
      if(args.getTokenElement(0) && args.getTokenElement(1)){
        return new WFraction(
          args.getTokenElement(0),
          args.getTokenElement(1));
      }
    }

    return null;
  }

  /**
   *
   */
  private preserve(value: RealElement, env: Environment): boolean {
    if(value instanceof WPower){
      return env.options.preservePowers;
    }
    return false;
  }

  /**
   * Comparison with int max and min values fix a compatibility issue in
   * 205227D4-55AC-25B8-B1B1-FAC6F91805BB.
   */
  private validateNumerator(value:RealElement):RealElement{
    if(!value){
      return null;
    }

    const n:number = value.toNumber();

    return 	value.isInteger() &&
        n <= Number.MAX_SAFE_INTEGER &&
        n >= Number.MIN_SAFE_INTEGER ? value : null;
  }

  /**
   *
   */
  private validateDenominator(value:RealElement):RealElement{
    if(!value){
      return null;
    }

    const n:number = value.toNumber();

    return 	value.isInteger() &&
        n <= Number.MAX_SAFE_INTEGER &&
        n >= Number.MIN_SAFE_INTEGER &&
        n !== 0 ? value : null;
  }

}
