import React from 'react';
import { ICustomRenderElementProps } from '../../models';
import { IListItemElement } from '../../models/elements';

export const ListItemElement: React.FC<ICustomRenderElementProps<IListItemElement>> = (props) => {
  return (
    <li>
      {props.children}
    </li>
  );
};
