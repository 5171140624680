import { Expression } from '../../elements/abstract/Expression';
import { Node } from '../../elements/abstract/Node';
import { RealElement } from '../../elements/abstract/RealElement';
import { Apply } from '../../elements/constructs/Apply';
import { Minus } from '../../funcs/arithmetic/Minus';

/**
 * Trasform unary minus operations that involve
 * a number into a single negative number.
 */
export class ApplyUnaryMinus {

  private expr:Expression;

  constructor(
      expr:Expression){

    this.expr = expr;
  }

  public simplify():Expression{
    const copy:Node = this.expr.root.clone();
    this.applyRecursive(copy);
    return new Expression(copy);
  }

  private applyRecursive(node:Node):void{
    for(let i:number = 0 ; i < node.numChildren ; i++){
      this.applyRecursive(node.childs[i]);
    }
    if(node.value instanceof Apply){
      if(node.numChildren === 2){
        if(node.childs[0].value instanceof Minus && node.childs[1].value instanceof RealElement){
          const n = <RealElement>node.childs[1].value;
          node.clear();
          node.value = n.toOpposite();
        }
      }
    }

  }

}
