import { Match } from '../../core/Match';
import { XString } from '../../core/XString';

/**
 *
 */
export class XYC {

  public x:number = 0;

  public y:number = 0;

  public c:number = 0;

  public yLabel:string;

  public xLabel:string;

  /**
   *
   */
  public static tryParse(valueArg:string, yLabel: string, xLabel: string):XYC{
    // (-?\d+\.?\d*)[xy]?|-?[xy]

    let value = valueArg.split(' ').join('');
    value = value.split('−').join('-');
    value = value.split('-').join('+-');

    const o:any[] = value.split('+');
    const p:XYC = new XYC();
    for(let i:number = 0 ; i < o.length ; i++){
      let m:string = o[i];
      if(m === ''){
        continue;
      }
      /*
      Decimal only
      (-?\d+[.,]?\d*)[xy]?|-?[xy]
      */

      /*
      Decimal and fractions
      -?\d+/\d+[xy]?|-?\d+[xy]?/\d+|(-?\d+[.,]?\d*)[xy]?|-?[xy]
      */

      const xy:string = yLabel + xLabel;
      const r:RegExp = new RegExp(`-?\\d+/\\d+[${xy}]?|-?\\d+[${xy}]?/\\d+|(-?\\d+[.,]?\\d*)[${xy}]?|-?[${xy}]`);
      const z:Match = Match.tryParse(r.exec(m));
      if(z == null){
        return null;
      }
      if(String(z.groups[0]).length === m.length){
        const assignX:boolean = m.indexOf(xLabel) !== -1;
        const assignY:boolean = m.indexOf(yLabel) !== -1;

        m = m.split(xLabel).join('');
        m = m.split(yLabel).join('');

        let n:number;
        if(m.indexOf('/') === -1){
          n = XYC.parseNumber(m);
        }else{
          const f:any[] = m.split('/');
          n = XYC.parseNumber(f[0]) / XYC.parseNumber(f[1]);
        }

        if(assignX){
          p.x += n;
        }else if(assignY){
          p.y += n;
        }else{
          p.c += n;
        }
      }else{
        return null;
      }
    }

    p.yLabel = yLabel;
    p.xLabel = xLabel;
    return p;
  }

  /**
   *
   */
  private static parseNumber(valueArg:string):number{
    let value = valueArg;
    if(value === ''){
      return 1;
    }
    if(value === '-'){
      return -1;
    }
    if(value.charAt(0) === '+'){
      value = value.substring(1);
    }
    return Number(value.split(',').join('.'));
  }

  /**
   *
   */
  public toString():string{
    return XString.substitute(`{0}${this.xLabel} + {1}${this.yLabel} + {2}`, this.x, this.y, this.c);
  }

}
