import Cookies from 'js-cookie';
import { datadogRum } from '@datadog/browser-rum';
import DatadogConfig from '../configs/DatadogConfig.json';

function initialize(service: string, envs: string[], version: string): void {
  const branchCookie = Cookies.get('env-switcher.selected-branch');
  const currentBranch = branchCookie ? UnicodeDecodeB64(branchCookie) : '';

  if (envs.includes(currentBranch)) {
    datadogRum.init({
      service,
      version,
      applicationId: DatadogConfig.RUM.publisherApplication,
      clientToken: DatadogConfig.apiKey,
      site: 'datadoghq.com',
      env: currentBranch,
      traceSampleRate: 100,
      trackUserInteractions: true,
      defaultPrivacyLevel: 'mask-user-input',
    });

    datadogRum.startSessionReplayRecording();
  }
}

const UnicodeDecodeB64 = (str: string): string => {
  return decodeURIComponent(atob(str));
};

const setUser = (
  id: string,
  internalId: string,
  name: string,
  email: string,
  title: string
) => {

  datadogRum.setUser({
    id,
    internalId,
    name,
    email,
    title
  });
};

export const DatadogRUM = {
  initialize,
  setUser,
};
