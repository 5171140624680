import React from 'react';
import styled from 'styled-components';

interface ICollapsableProps {
  isOpen: boolean;
  className?: string;
}

export const Collapsable: React.FC<ICollapsableProps> = (props) => {
  const {
    isOpen,
    className,
    children,
  } = props;
  const contentRef = React.useRef(null);
  const timeoutRef = React.useRef<number>(null);

  const [hideableHeight, setHideableHeight] = React.useState(isOpen ? 'auto' : '0px');

  React.useEffect(() => {
    window.clearTimeout(timeoutRef.current);
    if (isOpen && hideableHeight !== 'auto') {
      const contentHeight = contentRef.current.getBoundingClientRect().height;
      setHideableHeight(`${contentHeight}px`);
      timeoutRef.current = window.setTimeout(() => {
        setHideableHeight('auto');
      },                                     ANIMATION_DURATION_MS);
    } else if (!isOpen && hideableHeight !== '0px') {
      const contentHeight = contentRef.current.getBoundingClientRect().height;
      setHideableHeight(`${contentHeight}px`);
      timeoutRef.current = window.setTimeout(() => {
        setHideableHeight('0px');
      },                                     1);
    }
  },              [isOpen, hideableHeight]);
  React.useEffect(() => () => { // triggered on unmount
    window.clearTimeout(timeoutRef.current)
  }, [])

  return (
    <HideableContainer
      height={hideableHeight}
      aria-expanded={isOpen}
    >
      <Content
        ref={contentRef}
        className={className}
      >
        {children}
      </Content>
    </HideableContainer>
  );
};

const ANIMATION_DURATION_MS = 200;

interface IHideableContainer {
  readonly height: string;
}

const HideableContainer = styled.div<IHideableContainer>`
  height: ${(props) => props.height};
  overflow: ${(props) => props.height === 'auto' ? 'visible' : 'hidden'};
  transition: height ${ANIMATION_DURATION_MS}ms cubic-bezier(0.4, 0, 0.2, 1);
`;

const Content = styled.div`
`;
