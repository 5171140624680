import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import {
  ContextMenuItem,
  EllipsisButton,
  EllipsisVIcon,
  ExpandableItem,
  HtmlLabel,
  setHexColorTransparency,
  SubContextMenu,
  Tooltip,
} from '@scolab/publisher-ui-kit';
import { AlignmentQuality } from '../../../models/IStandardAlignment';
import { locales } from '../../../autoGenerate/locale';
import { LocaleProvider } from '../../LocaleProvider';

interface IAlignmentItemProps {
  title: string;
  rawDescription: string;
  isOpen: boolean;
  quality: AlignmentQuality;
  subjectIcon?: React.ReactNode;
  isActivity?: boolean;
  entryId: number;
  setIsOpen: (index: number, isOpen: boolean) => void;
  setAlignmentStrength: (id: number, strength: AlignmentQuality) => void;
  remove?: (id: number) => void;
  setToAllPages?: (id: number, quality: number) => void;
  removeFromAllPages?: (id: number) => void;
}

export const AlignmentItem: React.FC<IAlignmentItemProps> = (props) => {
  const {
    title,
    rawDescription,
    quality,
    subjectIcon,
    isOpen,
    isActivity,
    entryId,
    setIsOpen,
    remove,
    setAlignmentStrength,
    setToAllPages,
    removeFromAllPages,
  } = props;
  const [isContextMenuOpen, setIsContextMenuOpen] = React.useState(false);
  const onSetIsOpen = (isOpen: boolean) => setIsOpen(entryId, isOpen);

  const onRemove = React.useCallback(() => {
    remove(entryId);
    setIsContextMenuOpen(false);
  }, []);

  const onSetWeak = React.useCallback(() => {
    if (quality === AlignmentQuality.strong) {
      setAlignmentStrength(entryId, AlignmentQuality.weak);
    }
    setIsContextMenuOpen(false);
  }, [quality]);

  const onSetStrong = React.useCallback(() => {
    if (quality !== AlignmentQuality.strong) {
      setAlignmentStrength(entryId, AlignmentQuality.strong);
    }
    setIsContextMenuOpen(false);
  }, [quality]);

  const onSetPartial = React.useCallback(() => {
    if (quality === AlignmentQuality.strong) {
      setAlignmentStrength(entryId, AlignmentQuality.partial)
    }
    setIsContextMenuOpen(false);
  }, [quality]);

  const onSetToAllPages = React.useCallback(() => {
    setToAllPages(entryId, quality);
    setIsContextMenuOpen(false);
  }, []);

  const onRemoveFromAllPages = React.useCallback(() => {
    removeFromAllPages(entryId);
    setIsContextMenuOpen(false);
  }, []);

  const kebabMenu = isActivity ? (
    <EllipsisButton
      isOpen={isContextMenuOpen}
      onSetIsOpen={setIsContextMenuOpen}
      horizontalAlignment="left"
      modalLayer={document.getElementById('modalLayer')}
      icon={<EllipsisVIcon />}
    >
      <ContextMenuItem
        label={
          <FormattedMessage
            id='alignmentAndStandard.removeStandardFromActivity'
          />
        }
        onClick={onRemove}
      />
      <ContextMenuItem
        label={
          <FormattedMessage
            id='alignmentAndStandard.addStandardToEveryPage'
          />
        }
        onClick={onSetToAllPages}
      />
      <ContextMenuItem
        label={
          <FormattedMessage
            id='alignmentAndStandard.removeStandardFromEveryPage'
          />
        }
        onClick={onRemoveFromAllPages}
      />
      <SubContextMenu
        label={
          <FormattedMessage
            id='alignmentAndStandard.alignmentTypeForActivity'
          />
        }
      >
        <ContextMenuItem
          label={
            <FormattedMessage
              id='alignmentAndStandard.strongAlignment'
            />
          }
          selected={quality === AlignmentQuality.strong}
          onClick={onSetStrong}
        />
        <ContextMenuItem
          label={
            <FormattedMessage
              id='alignmentAndStandard.partialAlignment'
            />
          }
          selected={quality !== AlignmentQuality.strong}
          onClick={onSetPartial}
        />
      </SubContextMenu>
    </EllipsisButton>
  ) : (
    <EllipsisButton
      isOpen={isContextMenuOpen}
      onSetIsOpen={setIsContextMenuOpen}
      horizontalAlignment="left"
      modalLayer={document.getElementById('modalLayer')}
      icon={<EllipsisVIcon />}
    >
      <ContextMenuItem
        label={
          <FormattedMessage
            id='alignmentAndStandard.removeStandardFromPage'
          />
        }
        onClick={onRemove}
      />
      <SubContextMenu
        label={
          <FormattedMessage
            id='alignmentAndStandard.alignmentTypeForPage'
          />
        }
      >
        <ContextMenuItem
          label={
            <FormattedMessage
              id='alignmentAndStandard.strongAlignment'
            />
          }
          selected={quality === AlignmentQuality.strong}
          onClick={onSetStrong}
        />
        <ContextMenuItem
          label={
            <FormattedMessage
              id='alignmentAndStandard.weakAlignment'
            />
          }
          selected={quality !== AlignmentQuality.strong}
          onClick={onSetWeak}
        />
      </SubContextMenu>
    </EllipsisButton>
  );

  const menu = (
    <Controls>
      {quality === AlignmentQuality.strong ?
        <Tooltip title={<FormattedMessage id='alignmentAndStandard.alignmentToStandard.1' />}><FullAlign/></Tooltip> :
        <Tooltip title={<FormattedMessage id='alignmentAndStandard.alignmentToStandard.2' />}><HalfCircle/></Tooltip>
      }
      {kebabMenu}
    </Controls>
  )

  return (
    <LocaleProvider locales={locales}>
      <Container
        data-testid='AlignmentItem'
      >
        <ExpandableItem
          TitleIcon={subjectIcon}
          title={title}
          isOpen={isOpen}
          setIsOpen={onSetIsOpen}
          Menu={menu}
          closeTooltip={<FormattedMessage id='common.close' />}
          openTooltip={<FormattedMessage id='common.open' />}
        >
          <DescriptionContainer htmlText={rawDescription} />
        </ExpandableItem>
      </Container>
    </LocaleProvider>
  );
};

const Container = styled.div`
  align-items: baseline;
  display: flex;
`;

const DescriptionContainer = styled(HtmlLabel)`
  padding-top: 4px;
  font-size: 12px;
  line-height: 16px;

  ul, ol {
    padding-inline-start: 22px;
    margin-block: 0;
  }
`;

const Circle = styled.div`
  border-radius: 50%;
  box-sizing: border-box;
  height: 16px;
  width: 16px;
`;

const FullAlign = styled(Circle)`
  background-color: ${(props) => setHexColorTransparency(props.theme.colorset.primary500, 0.2)};
  border: 1px solid ${(props) => props.theme.colorset.primary500};
`;

const HalfCircle = styled(Circle)`
  background: linear-gradient(
    45deg,
    ${(props) => setHexColorTransparency(props.theme.colorset.primary500, 0.2)} 10px,
    ${(props) => props.theme.colorset.primary500} 10px 11px,
    transparent 11px
  );
  border: 1px dashed ${(props) => props.theme.colorset.primary500};
  border-left-style: solid;
  border-bottom-style: solid;
  transform: rotate(45deg);
`;

const Controls = styled.div`
  align-items: center;
  display: flex;
  gap: 12px;
`;
