import { IDictionary } from '../../../../js/utils/IDictionary';

import { RealElement } from '../../../elements/abstract/RealElement';
import { Zeros } from '../../../elements/functions/Zeros';
import { AbstractNode } from '../../../elements/functions/tree/AbstractNode';
import { EmptyLeaf } from '../../../elements/functions/tree/EmptyLeaf';
import { NodeArithmetic } from '../../../elements/functions/tree/NodeArithmetic';
import { NodeCoefficient } from '../../../elements/functions/tree/NodeCoefficient';
import { NodeConstant } from '../../../elements/functions/tree/NodeConstant';
import { NodeGroup } from '../../../elements/functions/tree/NodeGroup';
import { NodePower } from '../../../elements/functions/tree/NodePower';
import { NodeVariable } from '../../../elements/functions/tree/NodeVariable';
import { MParam } from '../../../expr/conversion/models/MParam';
import { CultureInfo } from '../../../localization/CultureInfo';
import { IFunctionForm } from '../../../elements/functions/models/IFunctionForm';
import { AbstractFunctionForm } from '../../../elements/functions/models/AbstractFunctionForm';

/**
 * 1.22 = fonction polynomiale du second degré f(x) = A(B(x - H))² + K
 */
export class TQuad extends AbstractFunctionForm {

  /**
   *
   */
  constructor(
      culture:CultureInfo,
      A:RealElement,
      B:RealElement,
      H:RealElement,
      K:RealElement){

    super(culture, A, B, null, null, null, null, H, K);
  }

  /**
   *
   */
  public map(value:number):number{
    return this.nA * (this.nB * (value - this.nH) ** 2) + this.nK;
  }

  public get continuous():number{
    return 1;
  }

  /**
   *
   */
  public getZeros():Zeros{
    const z:Zeros = new Zeros();

    const nA:number = this.nA;
    const nB:number = this.nB;
    const nH:number = this.nH;
    const nK:number = this.nK;

    if((nK < 0 && nA < 0) || (nK > 0 && nA > 0)){
      z.list = [];
    }else if((nK > 0 && nA < 0) || (nK < 0 && nA > 0)){
      z.list = [];
      z.list.push((-1/nB) * Math.sqrt(-nK / nA) + nH);
      z.list.push((1/nB) * Math.sqrt(-nK / nA) + nH);
    }else if((nK === 0 && nA < 0) || (nK === 0 && nA > 0)){
      z.list = [];
      z.list.push(nH);
    }
    return z;
  }

  /**
   * A(B(x - H))² + K
   */
  public getRawTokens(parameters:IDictionary, varName:string):any[]{
    const o:any[] = [varName];
    if(parameters.hasOwnProperty('H')){
      o.unshift('(');
      o.push('−', this.getH(), ')');
    }
    if(parameters.hasOwnProperty('B')){
      o.unshift(this.getB());
    }
    if(parameters.hasOwnProperty('A')){
      o.unshift('(');
      o.unshift(this.getA());
      o.push(')');
    }
    o.push('²');
    if(parameters.hasOwnProperty('K')){
      o.push('+', this.getK());
    }
    return o;
  }

  /**
   * 1.22 = fonction polynomiale du second degré f(x) = A(B(x - H))² + K
   */
  public getSimplifyTokens(parameters:IDictionary, varName:string):any[]{

    let leftNode:AbstractNode = new NodeVariable( varName );

    leftNode = ( new NodeArithmetic( NodeArithmetic.SUBSTRACTION ) )
      .setLeft(
        leftNode
      ).setRight(
        new NodeConstant( this.getH() )
      );

    leftNode = new NodeCoefficient( new NodeConstant( this.getB() ), new NodeGroup( leftNode, '(', ')' ) );
    leftNode = new NodePower( new NodeGroup( leftNode, '(', ')' ), '²' );
    leftNode = new NodeCoefficient( new NodeConstant( this.getA() ), leftNode );

    let operation:AbstractNode = ( new NodeArithmetic(NodeArithmetic.ADDITION) )
      .setLeft( leftNode )
      .setRight( new NodeConstant( this.getK() ) ).simplify();

    if( operation instanceof EmptyLeaf ) {
      operation = new NodeConstant( this.getK() );
    }

    return operation.getToken();

  }

  /**
   * 1.22 = fonction polynomiale du second degré f(x) = A(B(x - H))² + K
   */
  public getSimplifyTokensOld(parameters:IDictionary, varName:string):any[] {
    const o:any[] = [varName];
    if(parameters.hasOwnProperty('H') && this.getH().value.toNumber() !== 0 ){
      o.unshift('(');

      if( this.getH().value.toNumber() < 0 ){
        o.push('+', this.getAbsoluteParam(this.getH()), ')');
      } else {
        o.push('−', this.getH(), ')');
      }

    }
    if(parameters.hasOwnProperty('B') && this.B.toNumber() !== 1){
      o.unshift(this.getB());
    }
    if(parameters.hasOwnProperty('A') && this.A.toNumber() !== 1){
      o.unshift('(');
      o.unshift(this.getA());
      o.push(')');
    }
    o.push('²');
    if(parameters.hasOwnProperty('K') && this.K.toNumber() !== 0 ) {

      if( this.getK().value.toNumber() < 0 ){
        const kAbs:MParam = this.getAbsoluteParam(this.getK());

        o.push('-', kAbs);
      } else {
        o.push('+', this.getK());
      }
    }
    return o;
  }

  private getAbsoluteParam(p:MParam):MParam {
    return new MParam( p.type, p.name, p.value.toAbsoluteValue(), p.emptyValue, p.minusValue );
  }

  /**
   *
   */
  public copy(parameters:IDictionary):IFunctionForm{
    return new TQuad(
      this.culture,
      parameters.hasOwnProperty('A') ? parameters.A : this.A,
      parameters.hasOwnProperty('B') ? parameters.B : this.B,
      parameters.hasOwnProperty('H') ? parameters.H : this.H,
      parameters.hasOwnProperty('K') ? parameters.K : this.K);
  }

}
