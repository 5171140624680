export const preventAndStopPropagation = (event: React.MouseEvent) => {
  event.preventDefault();
  event.stopPropagation();
};

const IS_MAC = (
  typeof window !== 'undefined' &&
  /Mac|iPod|iPhone|iPad/.test(window.navigator.platform)
);

export const isModMouseEvent = (event: MouseEvent): boolean => {
  return IS_MAC ? event.metaKey : event.ctrlKey;
};
