import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WBoolean } from '../../elements/tokens/WBoolean';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { MagicSquare } from '../../funcs/patterns/MagicSquare';

/**
 *
 */
export class IsMagicSquare extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 1){
      return args.expectingArguments(1, 1);
    }

    if (args.getMatrix(0)) {
      return this.test(args.getMatrix(0));
    }
    return null;
  }

  /**
   *
   */
  private test(m:WMatrix):WBoolean{
    return WBoolean.parse(MagicSquare.validate(m));
  }

}
