import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { WPoint } from '../../elements/tokens/WPoint';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Partie d'une matrice.
 */
export class SubMatrix extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 3){
      return args.expectingArguments(3, 3);
    }

    if(args.getMatrix(0) && args.getPoint(1) && args.getPoint(2)){
      return this.part(
        args.getMatrix(0),
        args.getPoint(1),
        args.getPoint(2),
        args.env.culture.createNumber(0));
    }

    return null;
  }

  /**
   * Retourne une nouvelle matrice qui correspond aux élément à
   * l'intérieur du rectangle défini par les paramètres de début et de fin.
   */
  private part(
      value:WMatrix,
      topLeft:WPoint,
      bottomRight:WPoint,
      outsideValue:RealElement):WMatrix{

    const o:RealElement[] = [];

    const cols:number = Math.round(bottomRight.y.toNumber() - topLeft.y.toNumber()) + 1;

    for(let r:number = topLeft.x.toNumber(); r <= bottomRight.x.toNumber() ; r++){
      for(let c:number = topLeft.y.toNumber(); c <= bottomRight.y.toNumber() ; c++){
        if(		r < value.rows &&
            c < value.columns &&
            r >= 0 &&
            c >= 0){
          o.push(value.valueAt(r, c));
        }else{
          o.push(outsideValue);
        }
      }
    }

    return new WMatrix(o, cols, value.formatter);
  }

}
