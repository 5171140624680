import { WNumber } from '../../elements/tokens/WNumber';

/**
 * Util class to evaluate polynomial functions.
 * Encapsulate the value of the variable x.
 */
export class VariableX {

  public xValue:WNumber;

}
