import { ContentElement } from '../../../elements/abstract/ContentElement';
import { CultureInfo } from '../../../localization/CultureInfo';

/**
 * This class specialize into preparing token elements to be
 * read by text-to-speech synthetizer for cases where the simple export
 * to plain text would be ambiguous.
 */
export class AudioExporter {

  /**
   *
   */
  private culture:CultureInfo;

  /**
   *
   */
  constructor(culture:CultureInfo){
    this.culture = culture;
  }

  /**
   * Returns null if no tts substitution text exists for that element.
   */
  public exportLeaf(leaf:ContentElement):string{
    if(!leaf){
      return null;
    }

    if(leaf.toSpeakText()){
      return leaf.toSpeakText();
    }

    /*
    if(leaf instanceof WPolynomial){
      return this.exportPolynomial(<WPolynomial>leaf );
    }
    */

    return null;
  }

  /**
   *
   */
  /*
  private exportPolynomial(value:WPolynomial):string{
    var o:string[] = new Array<string>();
    value.flush(new AudioWriter(o, this.culture));
    return o.join("");
  }
  */

  /**
   *
   */
  public exportNumber(value:number):string{
    let s:string = String(value);
    s = s.replace('.', this.culture.numberFormatter.decimalSeparator);
    s = s.replace('-', '−');
    return s;
  }

}
