import React from 'react';
import { RenderLeafProps } from 'slate-react';

export const SyntaxHighlightLeaf: React.FC<RenderLeafProps> = (props) => {
  const style = {
    color: 'color' in props.leaf ? (props.leaf as { color?: string }).color : null,
    paddingLeft: 'paddingLeft' in props.leaf ? (props.leaf as { paddingLeft?: string }).paddingLeft : null,
    paddingRight: 'paddingRight' in props.leaf ? (props.leaf as { paddingRight?: string }).paddingRight : null,
  };
  return (
    <span
      {...props.attributes}
      style={style}
    >
      {props.children}
    </span>
  );
};
