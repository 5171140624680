import { AbstractStep } from '../AbstractStep';
import { Compartment } from '../Compartment';
import { LongAdditionOperation } from '../LongAdditionOperation';

/**
 *
 */
export class AddDecimalSep extends AbstractStep {

  constructor(operation:LongAdditionOperation) {

    super(operation);
    const decimalSeparator:string = operation.culture.numberFormatter.decimalSeparator;
    const c:Compartment = operation.decimalSeparator();
    operation.decSep = c;
    operation.result.unshift(c);
    if(!operation.carriesSepSpace){
      operation.carries.unshift(Compartment.createSpace());
      operation.carriesSepSpace = true;
    }
    this.target.push(c);

    this.setDescription(
      operation.culture.getString(
        'LongAdditionOperation.decimalSeparatorLabel',
        operation.culture.numberFormatter.getDecimalSeparatorLabel(false, ['le', 'la'])));
  }

}
