import React from 'react';
import styled from 'styled-components';
import { LoadScriptOnce } from '../../../utils/ScriptLoadingUtils';

const SCRIPT = 'https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.5/MathJax.js?config=TeX-MML-AM_HTMLorMML';

export interface IMathJax2ImplProps extends React.HTMLAttributes<HTMLSpanElement> {
  readonly math: string;
  readonly onRef?: (element: HTMLSpanElement) => void;
}

/**
 * This class is a slightly modified version of the workbook's MathLabel
 * This version does not attempt to load MathJax v2 and assumes it is already loaded
 */
export class MathJax2Impl extends React.Component<IMathJax2ImplProps> {

  private readonly container: React.RefObject<HTMLSpanElement>;

  private readonly hiddenContainer: React.RefObject<HTMLSpanElement>;

  private isUpdatingSource: boolean;

  private currentlyDisplayedValue: string;

  private nextValueToDisplay: string;

  constructor(props: IMathJax2ImplProps) {
    super(props);

    this.isUpdatingSource = false;
    this.currentlyDisplayedValue = '';
    this.container = React.createRef<HTMLSpanElement>();
    this.hiddenContainer = React.createRef<HTMLSpanElement>();
  }

  public componentDidMount(): void {
    this.nextValueToDisplay = this.props.math;
    if (window.MathJax) {
      this.updateSource();
    } else {
      LoadScriptOnce.loadScript(SCRIPT, this.updateSource);
    }
  }

  public shouldComponentUpdate(nextProps: IMathJax2ImplProps): boolean {
    if (!nextProps.math) {
      return false;
    }
    return nextProps.math !== this.props.math;
  }

  public componentDidUpdate(prevProps: IMathJax2ImplProps): void {
    this.nextValueToDisplay = this.props.math;
    this.updateSource();
  }

  public render(): JSX.Element {
    const {
      math,
      onRef,
      ...otherProps
    } = this.props;
    // We need to insert the raw MathML so that text-to-speech will
    // read the MathML and not the DOM modified by math-jax.
    return (
      <span
        {...otherProps}
        ref={onRef}
      >
        <span
          ref={this.container}
        />
        <HiddenSpan
          ref={this.hiddenContainer}
        />
      </span>
    );
  }

  private swapSource = (value: string) => {
    this.isUpdatingSource = true;
    if (window.MathJax && this.container.current) {
      const correctedValue = this.handleProblematicCharacters(value);
      this.hiddenContainer.current.innerHTML = correctedValue;
      window.MathJax.Hub.Queue(
        ['Typeset', window.MathJax.Hub, this.hiddenContainer.current],
        () => {
          if (this.container.current) { // with ReactDnD container, sometimes the container is garbage collect before this callback.
            this.removeDataMathMLAttribute();
            this.container.current.innerHTML = this.hiddenContainer.current.innerHTML;

            this.currentlyDisplayedValue = value;
            this.isUpdatingSource = false;
            this.updateSource();
          }
        },
      );
    } else {
      window.requestAnimationFrame(() => this.swapSource(value));
    }
  }

  private updateSource = () => {
    if (!this.isUpdatingSource &&
        this.currentlyDisplayedValue !== this.nextValueToDisplay &&
        window.MathJax) {
      this.container.current.innerHTML = '';
      this.swapSource(this.nextValueToDisplay);
    }
  }

  private removeDataMathMLAttribute = () => {
    if (!this.hiddenContainer.current) {
      return;
    }
    // Remove data-mathml because it is not escaped properly to be parsed as XML.
    const spans = Array.prototype.slice.call(this.hiddenContainer.current.getElementsByClassName('MathJax'));
    if (spans.length > 0) {
      spans[0].removeAttribute('data-mathml');
    }
  }

  private handleProblematicCharacters = (sourceMath: string) => {
    // force an empty square root symbol to display a bar over an empty space (PP-2345)
    return sourceMath.replace('<msqrt></msqrt>', '<msqrt><mphantom><mn>1</mn></mphantom></msqrt>');
  }

}

const HiddenSpan = styled.span`
  display: none;
`;
