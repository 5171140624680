import { ContentElement } from '../../elements/abstract/ContentElement';
import { ElementCodes } from '../../elements/abstract/ElementCodes';
import { OperatorElement } from '../../elements/abstract/OperatorElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WInfinity } from '../../elements/tokens/WInfinity';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Division entière.
 */
export class Quotient extends OperatorElement {

  private static _instance:Quotient;

  public static getInstance():Quotient{
    if(!Quotient._instance){
      Quotient._instance = new Quotient();
    }
    return Quotient._instance;
  }

  /**
   *
   */
  public toString():string{
    return '\\';
  }

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 2){
      return args.expectingArguments(2, 2);
    }

    const a:RealElement = args.getReal(0);
    const b:RealElement = args.getReal(1);

    if(a && b){
      if(b.toNumber() === 0){
        return WInfinity.POSITIVE;
      }
      return args.env.culture.createNumber(Math.floor(a.toNumber() / b.toNumber()));
    }

    return null;
  }

  /**
   *
   */
  public getElementCode():string{
    return ElementCodes.OP_QUOTIENT;
  }

}
