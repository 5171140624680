import { BaseNumberFormatter } from '../../../elements/formats/BaseNumberFormatter';
import { CultureInfo } from '../../../localization/CultureInfo';

/**
 *
 */
export class MultiplierNameFormatter extends BaseNumberFormatter {

  /**
   *
   */
  constructor(culture:CultureInfo){
    super(culture);
  }

  /**
   *
   */
  public toLocaleString(value:number):string{
    return this.willFormat(value) ?
      this.culture.getString(`Multipliers.${String(value)}`) :
      this.culture.formatNumber(value);
  }

  /**
   *
   */
  public toSpeakText(value:number):string{
    return this.willFormat(value) ?
        null :
        this.culture.numberFormatter.toSpeakText(value);
  }

  /**
   *
   */
  public willFormat(value:number):boolean{
    const keys = this.culture.getStringArray('Multipliers.keys');
    return keys.indexOf(value.toString()) !== -1;
  }

}
