import { AbstractFormatter } from '../../elements/formats/AbstractFormatter';
import { CardNameFormatter } from '../../elements/formats/cards/CardNameFormatter';
import { AbstractApplyFormat } from '../../funcs/formats/AbstractApplyFormat';
import { CultureInfo } from '../../localization/CultureInfo';

/**
 *
 */
export class CardName extends AbstractApplyFormat {

  /**
   *
   */
  protected getFormat(culture:CultureInfo):AbstractFormatter{
    return new CardNameFormatter(culture);
  }

}
