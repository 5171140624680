import { BaseNumberFormatter } from '../../../elements/formats/BaseNumberFormatter';
import { CultureInfo } from '../../../localization/CultureInfo';

/**
 *
 */
export class CardShortNameFormatter extends BaseNumberFormatter {

  private static T:any[] = ['♠', '♥', '♦', '♣'];

  private static V:any[] = ['A', '2', '3', '4', '5', '6', '7', '8', '9', '10', 'J', 'Q', 'K'];

  /**
   *
   */
  constructor(culture:CultureInfo){
    super(culture);
  }

  /**
   *
   */
  public toLocaleString(value:number):string{
    if(!this.willFormat(value)){
      return this.culture.formatNumber(value);
    }

    return 	String(CardShortNameFormatter.V[(value - 1) % 13]) +
        String(CardShortNameFormatter.T[Math.floor((value - 1) / 13)]);
  }

  /**
   *
   */
  public toSpeakText(value:number):string{
    return this.willFormat(value) ? null : this.culture.numberFormatter.toSpeakText(value);
  }

  /**
   *
   */
  public willFormat(value:number):boolean{
    return Math.round(value) === value && value >= 1 && value <= 52;
  }

  /**
   *
   */
  public isDictionaryKey():boolean{
    return true;
  }

}
