import { IGetActivityPagesDependentsResponse } from './ActivityPagesDependentsRequest';
import { IStandardItemRef } from '../models/IStandardItemRef';
import { IStandard } from '../models/IStandard';
import { AlignmentQuality } from '../models/IStandardAlignment';
import { api } from '../utils/fetch';

export const AddActivityAlignment = async (activityId: number, standardEntryId: number, quality: AlignmentQuality): Promise<Response> => {
  const path = `/Publisher/Alignments/AddActivityAlignment?activityId=${activityId}&standardEntryId=${standardEntryId}&quality=${quality}`;
  return api(path);
};

export const AddActivitiesAlignments =
  async (activityId: number,
         standards: ReadonlyArray<IStandard>): Promise<Response> => {

    const data = new URLSearchParams();
    data.append('activityId', activityId.toString());
    standards.forEach(standard => data.append('standardEntriesIds[]', standard.id.toString()));
    data.append('quality', AlignmentQuality.partial.toString());

    return api('/Publisher/Alignments/AddActivitiesAlignments', {
      method: 'post',
      body: data,
    });
  };

export const AddPageAlignment = async (pageGuid: string, standardEntryId: number, quality: AlignmentQuality): Promise<Response> => {
  const path = `/Publisher/Alignments/AddPageAlignment?pageGuid=${pageGuid}&standardEntryId=${standardEntryId}&quality=${quality}`;
  return api(path);
};

export const AddPagesAlignments =
  async (pageGuid: string,
         standards: ReadonlyArray<IStandard>): Promise<Response> => {

    const data = new URLSearchParams();
    data.append('pageGuid', pageGuid);
    standards.forEach(standard => data.append('standardEntriesIds[]', standard.id.toString()));
    data.append('quality', AlignmentQuality.weak.toString());

    return api('/Publisher/Alignments/AddPagesAlignments', { method: 'post', body: data });
  };

export const ChangeActivityAlignmentQuality = async (activityId: number, standardEntryId: number, quality: AlignmentQuality): Promise<Response> => {
  const path = `/Publisher/Alignments/ChangeActivityAlignmentQuality?activityId=${activityId}&standardEntryId=${standardEntryId}&quality=${quality}`;
  return api(path);
};

export const ChangePageAlignmentQuality = async (pageGuid: string, standardEntryId: number, quality: AlignmentQuality): Promise<Response> => {
  const path = `/Publisher/Alignments/ChangePageAlignmentQuality?pageGuid=${pageGuid}&standardEntryId=${standardEntryId}&quality=${quality}`;
  return api(path);
}

export const RemoveActivityAlignment = async (activityId: number, standardEntryId: number): Promise<Response> => {
  const path = `/Publisher/Alignments/RemoveActivityAlignment?activityId=${activityId}&standardEntryId=${standardEntryId}`;
  return api(path);
}

export const RemovePageAlignment = async (pageGuid: string, standardEntryId: number): Promise<Response> => {
  const path = `/Publisher/Alignments/RemovePageAlignment?pageGuid=${pageGuid}&standardEntryId=${standardEntryId}`;
  return api(path);
}

export const RemoveActivityPagesAlignments = async (activityId: number, standardEntryId: number): Promise<Response> => {
  const path = `/Publisher/Alignments/RemoveActivityPagesAlignments?activityId=${activityId}&standardEntryId=${standardEntryId}`;
  return api(path);
}

export const AddActivityPagesAlignments = async (activityId: number, standardEntryId: number, quality: number): Promise<Response> => {
  const path = `/Publisher/Alignments/AddActivityPagesAlignments?activityId=${activityId}&includeInvisiblePages=true&standardEntryId=${standardEntryId}&quality=${quality}`;
  return api(path);
}

export interface IGetActivityAlignmentsResponse {
  readonly activityId: number;
  readonly activityAlignments: ReadonlyArray<{
    readonly activityId: string;
    readonly quality: AlignmentQuality;
    readonly standardCourseEntry: IStandardItemRef;
  }>;
  readonly pagesAlignments: ReadonlyArray<{
    readonly pageGuid: string;
    readonly quality: AlignmentQuality;
    readonly standardCourseEntry: IStandardItemRef;
  }>;
}

export const getAlignments = async (activityId: number): Promise<IGetActivityPagesDependentsResponse> => {
  const path = `/Publisher/Alignments/Activity?activityId=${activityId}`;
  const response = await api<IGetActivityPagesDependentsResponse>(path);
  return response.json();
}
