import { IContextModel } from '@scolab/content-model';
import { InlineContext } from '../../../expr/InlineContext';

export class RandomIntegerLine extends InlineContext {

  /**
   *
   */
  constructor(){
    super('RandomIntegerLine', [], 'points');
  }

  /**
   *
   */
  public getContextModel():IContextModel{
    const o:IContextModel = {
      variables: [{
        value: 'Random(-6, 6)',
        id: 'xa'
      }, {
        value: 'Random(xa+4, xa+8)',
        id: 'xb'
      }, {
        value: 'Random(-5, 5)',
        id: 'm'
      }, {
        validation: 'b≠m',
        value: 'Random(-5, 5)',
        id: 'b'
      }, {
        value: 'm*xa+b',
        id: 'ya'
      }, {
        value: 'm*xb+b',
        id: 'yb'
      }, {
        value: '(xa, ya), (xb, yb)',
        id: 'points'
      }
      ]
    };

    return o;
  }

}
