import { Point } from '../../../../js/geom/Point';
import { Delegate } from '../../../../js/utils/Delegate';
import { ContentElement } from '../../../elements/abstract/ContentElement';
import { FormatProvider } from '../../../elements/factories/FormatProvider';
import { RadicalPrimeFactorizationFormatter } from '../../../elements/formats/radicals/RadicalPrimeFactorizationFormatter';
import { WRadical } from '../../../elements/tokens/WRadical';
import { Environment } from '../../../expr/Environment';
import { FactorInteger } from '../../../expr/manipulation/alt/FactorInteger';
import { AbstractRule } from '../../../expr/manipulation/rules/AbstractRule';

/**
 *
 */
export class ReduceRadical extends AbstractRule {

  constructor(){
    super(false, false);
  }

  public applyValue(
      element:ContentElement,
      format:FormatProvider,
      stateMode:number,
      env:Environment,
      isLastOpportunity:boolean):ContentElement{

    if(!env.options.simplifyRadicals){
      return null;
    }
    if(!(element instanceof WRadical)){
      return null;
    }

    const r:WRadical = <WRadical>element ;
    if(r){
      if(stateMode === -1){
        return r.toReduced(env.reals);
      }
      if(!r.index.isNaturalNumber()){
        return null;
      }

      const rF:Point = r.factorizeBase;
      if(rF.x !== 1){
        if(r.formatter.factorizationLevel === 0 && ReduceRadical.splitBaseFactorization(r)){
          return r.applyFormat(new RadicalPrimeFactorizationFormatter(env.culture, 1, 1, 2));
        }
        if(r.formatter.factorizationLevel <= 1){
          return r.applyFormat(new RadicalPrimeFactorizationFormatter(env.culture, 2, 1));
        }
        return r.toReduced(env.reals).applyFormat(new RadicalPrimeFactorizationFormatter(env.culture, 1, 1));
      }

      if(r.formatter.factorizationLevel > 0){
        return r.applyFormat(env.culture.formats.radicalFormatImpl);
      }
    }

    return null;
  }

  /**
   * Returns true if we need two steps to factorize the base,
   * for example sqrt(8) --> sqrt(2^3) --> sqrt(2^2*2).
   */
  public static splitBaseFactorization(value:WRadical):boolean{
    if(value.factorizeBase.x === 1){
      return false;
    }

    const factors:any[] =
      FactorInteger.powers(value.base.toNumber()); /* of [base, power] */

    const index:number = value.index.toNumber();

    return factors.some(
      Delegate.partial(ReduceRadical.detectTwoSteps, index),
      null);
  }

  /**
   *
   */
  private static detectTwoSteps(index:number, o:any[], ..._:any[]):boolean{
    return o[1] > index && o[1] % index !== 0;
  }

}
