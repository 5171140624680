import React from 'react';
import styled from 'styled-components';
import { ICustomEditor } from '../../models';
import { getActiveListType, isOrderedListActive, removeList, toggleList } from '../../utils';
import { IListItem, ListControl } from '../../components/ListControl/ListControl';
import { ListStyleTypes } from '../../models/elements';
import NumberedListIcon from '../../../../../assets/numberedList.svg';
import { Tooltip } from '../../../../utils/Tooltip/Tooltip';

interface IOrderedListControlProps {
  editor: ICustomEditor;
  label: string;
}

const Items: ReadonlyArray<IListItem> = [
  {
    Icon: '1.',
    value: 'decimal',
  },
  {
    Icon: '01.',
    value: 'decimalLeadingZero',
  },
  {
    Icon: 'a.',
    value: 'lowerLatin',
  },
  {
    Icon: 'A.',
    value: 'upperLatin',
  },
  {
    Icon: 'i.',
    value: 'lowerRoman',
  },
  {
    Icon: 'I.',
    value: 'upperRoman',
  },
];

export const OrderedListControl: React.FC<IOrderedListControlProps> = (props) => {
  const { editor, label } = props;
  const isActive = isOrderedListActive(editor);
  const onMouseDown = (event: React.MouseEvent) => {
    event.preventDefault();
    if (isActive) {
      removeList(editor);
    } else {
      toggleList(editor, 'decimal');
    }
  };
  const onClickItem = (listStyleType: ListStyleTypes) => {
    toggleList(editor, listStyleType);
  };
  const value = getActiveListType(editor);

  const icon = (
    <Tooltip title={label}>
      <IconContainer>
        <NumberedListIcon />
      </IconContainer>
    </Tooltip>
  );

  return (
    <ListControl
      Icon={icon}
      isActive={isActive}
      onClickIcon={onMouseDown}
      items={Items}
      onClickItem={onClickItem}
      value={value}
      aria-label={label}
    />
  );
};

const IconContainer = styled.div`
  line-height: 0;
`;
