import { IDictionary } from '../../../js/utils/IDictionary';

import { XObject } from '../../core/XObject';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WList } from '../../elements/tokens/WList';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';

/**
 *
 */
export class Multiplicities extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 1){
      return args.expectingArguments(1, 1);
    }
    if(args.getReals(0)){
      return this.list(args.getReals(0), args.env);
    }
    return null;
  }

  /**
   * Returns multiplicities of every distinct element in the list.
   */
  public list(value:WList, env:Environment):WList{
    const o:IDictionary = {};
    let s:string;
    for(let i:number = 0 ; i < value.count ; i++){
      s = String(value.getValueAt(i));
      if(!o.hasOwnProperty(s)){
        o[s] = 0;
      }
      o[s]++;
    }

    const names:any[] = XObject.getProps(o);
    const m:number[] = [];
    for(let j:number = 0 ; j < names.length ; j++){
      m.push(o[names[j]]);
    }

    return env.culture.listFactory.createFromNumbers(m);
  }

}
