import { XMath } from '../../core/XMath';
import { MmlWriter } from '../../core/mml/MmlWriter';
import { RealElement } from '../../elements/abstract/RealElement';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';

/**
 *
 */
export class WRepeatingDecimal extends RealElement {

  private _base:number;

  public get base():number{
    return this._base;
  }

  private _period:string;

  public get period():string{
    return this._period;
  }

  constructor(
      base:number,
      period:string){
    super();
    this._base = base;
    this._period = period;
  }

  private get isIntBase():boolean{
    return XMath.isInteger(this.base);
  }

  /**
   * Start with 20 decimals string, and remove decimals until the
   * converting the string to number and back to string gives the
   * same result to avoid rounding error at the end of the number.
   *
   * Decimal(RepeatingDecimal(11, 4)) --> 11.444444444444445
   *
   * @returns {Number}
   */
  public toNumber():number{
    let n:string = this.base.toString();
    if(this.isIntBase){
      n += '.';
    }
    n += this.rep(this.period, 20);
    while (n !== Number(n).toString() && n.length > 0) {
      n = n.substring(0, n.length - 1);
    }
    return Number(n);
  }

  private rep(value:string, n:number):string{
    const s:any[] = [];
    for(let i:number = 0 ; i < n ; i++){
      s.push(value);
    }
    return s.join('');
  }

  public toText(strict:boolean):string{
    return null;
  }

  public writeTo(exporter:IMarkupExporter = null):boolean{
    if(exporter){
      const w:MmlWriter = exporter.writer;
      w.beginRow();
      w.appendNumber(exporter.culture.formatNumber(this.base) + (this.isIntBase ? exporter.culture.numberFormatter.decimalSeparator : ''));
      w.beginOver();
      w.appendNumber(this.period);
      w.appendOperator('\u00AF');
      w.accent = false;
      w.endOver();
      w.endRow();
    }

    return true;
  }

  public toAbsoluteValue():RealElement {
    return new WRepeatingDecimal( Math.abs( this.base ), this.period );
  }

  public toOpposite():RealElement {
    return new WRepeatingDecimal(-this.base, this.period);
  }

  public getType():string {
    return 'repeatingDecimal';
  }

}
