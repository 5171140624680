/* [ResourceBundle("CommonErrors")]*/

/**
 *
 */
export class CommonError extends Error {

  // Numbers
  public static NAN:number = 60001;

  public static MUST_USE_COMMA:number = 60019;

  public static MUST_USE_DOT:number = 60020;

  public static DEC_SEP_DUPLIC:number = 60032;

  public static THOUSAND_SEPARATOR_COMMA:number = 60033;

  public static THOUSAND_SEPARATOR_SPACE:number = 60096;

  public static ROUND_TO_1_DEC_PLACE:number = 60034;

  public static ROUND_TO_N_DEC_PLACES:number = 60035;

  public static NOT_AN_ESTIMATE:number = 60056;

  public static NUM_NOT_IN_DIGITS:number = 60085;

  public static NUM_NOT_IN_WORDS:number = 60057;

  public static NUM_IN_WORDS_DASH_MISSING:number = 60071;

  public static NUM_IN_WORDS_INVARIABLE:number = 60072; // fr only

  public static NUM_IN_WORDS_DASH_EXTRA:number = 60073;

  public static NUM_IN_WORDS_DASH_MISUSE:number = 60074;

  public static NO_SPACE_BEFORE_OR_AFTER_DASH:number = 60095;

  public static MISSING_ZERO_BEFORE_DEC:number = 60084;

  // Numbers formats
  public static BASE_NUMBER_FORMAT:number = 60003;

  public static SCIENTIFIC_NOTATION_FORMAT:number = 60036;

  public static PERCENT_NUMBER_FORMAT:number = 60054;

  public static SIGNED_NUMBER_FORMAT:number = 60055;

  public static CURRENCY_NUMBER_PRECISION:number = 60075;

  public static MIN_DEC_PLACES:number = 60076;

  // Number in various forms
  public static EXPANDED_NUMBER0_FORMAT:number = 60004;

  public static EXPANDED_NUMBER1_FORMAT:number = 60005;

  // public static EXPANDED_NUMBER1_ORDER:number = 60092; // obsolete, non-ordered terms are now accepted
  public static EXPANDED_NUMBER2_FORMAT:number = 60006;

  // public static EXPANDED_NUMBER2_ORDER:number = 60093; // obsolete, non-ordered terms are now accepted
  public static EXPANDED_NUMBER_SIGNIFICANT_DIGITS:number = 60043;

  public static FACTOR_INTEGER_DEFAULT:number = 60039;

  public static FACTOR_INTEGER_EXP:number = 60040;

  // Sequences
  public static LIST_FORMAT_INVALID:number = 60007;

  public static LIST_MISSING_SPACE_AFTER_COMMA:number = 60079; // this error is only valid for fr locale.

  public static LIST_MISSING_SPACES_AFTER_COMMAS:number = 60094;  // this error is only valid for fr locale.

  public static LIST_TALLY_FORMAT_INVALID:number = 60070;

  // Points
  public static POINT_FORMAT_INVALID:number = 60008;

  public static POINTS_LIST_FORMAT_INVALID:number = 60058;

  // Sets, Intervals, Inequalities
  public static SET_BRACES_MISSING:number = 60009;

  public static SET_NOT_NUMERIC:number = 60010;

  public static SET_DUPLICATES_ITEMS:number = 60011;

  public static SET_INCONSISTENT_SPACES_DEC_SEP: number = 60097;

  public static SET_ITEM_EMPTY:number = 60013;

  public static INTERVAL_INVALID:number = 60051;

  public static UNION_INVALID:number = 60063;

  public static INEQUALITY_INVALID:number = 60052;

  public static SET_BUILDER_INVALID:number = 60053;

  public static RELATION_INVALID:number = 60081;

  public static INTERVAL_BOUNDS_ORDER:number = 60082;

  // Fractions
  public static RATIONAL_FORMAT_INVALID:number = 60014;

  public static RATIONAL_NOT_IMPROPER:number = 60015;

  public static RATIONAL_NOT_MIXED:number = 60016;

  public static RATIONAL_NOT_SIMPLIFIED:number = 60017;

  public static RATIONAL_DIFF_DENOMINATOR:number = 60041;

  public static RATIONAL_DIFF_NUMERATOR:number = 60042;

  public static RATIONAL_DIFF_FRACTION:number = 60045;

  public static USE_REP_DEC_NOTATION:number = 60078;

  // Polynomials
  public static LOWERCASE_VARIABLES:number = 60018;

  public static POLY_NOT_SIMPLIFIED:number = 60046;

  public static POLY_PLUS_IN_FRONT:number = 60024; // N'inscrivez pas le + au début du polynome!

  public static POLY_SIMILAR_TERMS:number = 60025; // N'inscrivez pas de termes semblables

  public static POLY_POWER1:number = 60026; // N'inscrivez pas l'exposant 1!

  public static POLY_COEF_0:number = 60027; // N'inscrivez pas de coefficient 0!

  public static POLY_COEF_1:number = 60028; // N'inscrivez pas de coefficient 1!

  public static MONO_DUPL_SYMBOL:number = 60029; // On ne peut répéter la même variable dans un monôme!

  public static NOT_A_POLYNOMIAL:number = 60030; // Pas un polynôme.

  public static NOT_A_MONOMIAL:number = 60031; // Pas un monôme.

  // Ratios
  public static RATIO_FORMAT_INVALID:number = 60021;

  public static RATIO_NOT_SIMPLIFIED:number = 60022;

  // Numeric expressions
  public static NOT_A_NUM_EXPR:number = 60037;

  public static NOT_SAME_NUM_EXPR:number = 60038;

  // Powers
  public static POWERS_FORMAT:number = 60044;

  // Logarithms
  public static LOGARITHM_FORMAT:number = 60080;

  // Time
  public static TIME_HMS_INVALID:number = 60047;

  public static TIME_HM_INVALID:number = 60048;

  // Linear equations and inequalities, 60050 obsolete
  public static LINE_EQ_SLOPE_INTERCEPT_INVALID:number = 60086;

  public static LINE_EQ_GENERAL_INVALID:number = 60087;

  public static LINE_EQ_STANDARD_INVALID:number = 60088;

  public static LINE_IN_SLOPE_INTERCEPT_INVALID:number = 60089;

  public static LINE_IN_GENERAL_INVALID:number = 60090;

  public static LINE_IN_STANDARD_INVALID:number = 60091;

  public static TRANSFORM_INVALID:number = 60061;

  public static RADICAL_INVALID:number = 60062;

  public static PARTIAL_FACTORIZATION:number = 60064;

  public static EXPR_RESTRICTED_OP:number = 60065;

  public static EXPR_RESTRICTED_TERM:number = 60066;

  public static EXPR_LOW_OPERATIONS:number = 60067;

  public static EXPR_HIGH_OPERATIONS:number = 60068;

  public static EXPR_DIFF_OPERATIONS:number = 60069;

  // String
  public static STR_DISTANCE_CLOSE:number = 60077;

  // Exponential
  public static EXPONENTIAL_INVALID:number = 60083;

  // Invalid expression
  public static INVALID_EXPRESSION:number = 60098;

  public classType:string = 'commonError'; // NOTE: typescript does not allow to type Error so var.constructor.namme always return "Error", to work around this we created a type;

  /**
   *
   */
  private _type:number;

  public get type():number{return this._type;}

  /**
   * Used to test equality of two errors in combination with the id.
   *
   * We can then make a decision about ignoring subsequent
   * occurences of the exact same error.
   */
  private _tag:string;

  public get tag():string{return this._tag;}

  private _data:any;

  public get data():string{return this._data;}

  /**
   * Same error.
   */
  public equals(error:CommonError):boolean{
    return 	this.type === error.type &&
        this.tag === error.tag;
  }

  /**
   *
   */
  constructor(
      message:string,
      type:number,
      tag:string,
      data:any){

    super(message);
    Object.setPrototypeOf(this, new.target.prototype);
    this._type = type;
    this._tag = tag;
    this._data = data;
  }

}
