import { Point } from '../../../js/geom/Point';

import { MathError } from '../../core/MathError';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WPolygon } from '../../elements/tokens/WPolygon';
import { WString } from '../../elements/tokens/WString';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Constructeur de polyomino.
 * Crée un polyomino libre d'ordre 1 à 5, selon une lettre identifiant la forme.
 */
export class Polyomino extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 2){
      return args.expectingArguments(2, 2);
    }
    if (args.getReal(0) && args.getString(1)) {
      return this.polygon(args.getReal(0), args.getString(1));
    }
    return null;
  }

  /**
   *
   */
  private polygon(
      order:RealElement,
      shape:WString):WPolygon{

    if(!order.isWholeNumber()){
      return null;
    }

    const n:number = order.toNumber();

    if(n === 0){
      throw new MathError('Polyomino of order 0 is invalid');
    }
    if(n > 5){
      throw new MathError('Polyomino of order grater than 5 is not handled');
    }

    const o:Point[] = [];

    switch(n){
      case 1:
        // O
        o.push(Polyomino.pt(0, 0), Polyomino.pt(1, 0), Polyomino.pt(1, 1), Polyomino.pt(0, 1));
        break;
      case 2:
        // I
        o.push(Polyomino.pt(0, 0), Polyomino.pt(1, 0), Polyomino.pt(1, 2), Polyomino.pt(0, 2));
        break;
      case 3:
        switch(shape.getString()){
          case 'I':
            o.push(Polyomino.pt(0, 0), Polyomino.pt(1, 0), Polyomino.pt(1, 3), Polyomino.pt(0, 3));
            break;
          case 'L':
          case 'V':
            o.push(Polyomino.pt(0, 0), Polyomino.pt(0, 2), Polyomino.pt(2, 2), Polyomino.pt(2, 1), Polyomino.pt(1, 1), Polyomino.pt(1, 0));
            break;
          default:
            throw new MathError('Polyomino of order 3 must be I, L or V');
        }
        break;
      case 4:
        switch(shape.getString()){
          case 'I':
            o.push(Polyomino.pt(0, 0), Polyomino.pt(1, 0), Polyomino.pt(1, 4), Polyomino.pt(0, 4));
            break;
          case 'O':
            o.push(Polyomino.pt(0, 0), Polyomino.pt(2, 0), Polyomino.pt(2, 2), Polyomino.pt(0, 2));
            break;
          case 'Z':
            o.push(Polyomino.pt(0, 2), Polyomino.pt(2, 2), Polyomino.pt(2, 1), Polyomino.pt(3, 1), Polyomino.pt(3, 0), Polyomino.pt(1, 0), Polyomino.pt(1, 1), Polyomino.pt(0, 1));
            break;
          case 'T':
            o.push(Polyomino.pt(0, 0), Polyomino.pt(0, 3), Polyomino.pt(1, 3), Polyomino.pt(1, 2), Polyomino.pt(2, 2), Polyomino.pt(2, 1), Polyomino.pt(1, 1), Polyomino.pt(1, 0));
            break;
          case 'L':
            o.push(Polyomino.pt(0, 0), Polyomino.pt(0, 3), Polyomino.pt(1, 3), Polyomino.pt(2, 3), Polyomino.pt(2, 2), Polyomino.pt(1, 0));
            break;
          default:
            throw new MathError('Polyomino of order 4 must be I, O, Z, T or L');
        }
        break;
      case 5:
        switch(shape.getString()){
          case 'F':
          case 'f':
            o.push(Polyomino.pt(0, 1), Polyomino.pt(0, 2), Polyomino.pt(1, 2), Polyomino.pt(1, 3), Polyomino.pt(3, 3), Polyomino.pt(3, 2), Polyomino.pt(2, 2), Polyomino.pt(2, 0), Polyomino.pt(1, 0), Polyomino.pt(1, 1));
            break;
          case 'I':
            o.push(Polyomino.pt(0, 0), Polyomino.pt(1, 0), Polyomino.pt(1, 4), Polyomino.pt(0, 4));
            break;
          case 'L':
            o.push(Polyomino.pt(0, 0), Polyomino.pt(0, 4), Polyomino.pt(1, 4), Polyomino.pt(1, 1), Polyomino.pt(2, 1), Polyomino.pt(2, 0));
            break;
          case 'N':
            o.push(Polyomino.pt(0, 0), Polyomino.pt(0, 3), Polyomino.pt(1, 3), Polyomino.pt(1, 4), Polyomino.pt(2, 4), Polyomino.pt(2, 2), Polyomino.pt(1, 2), Polyomino.pt(1, 0));
            break;
          case 'P':
            o.push(Polyomino.pt(0, 0), Polyomino.pt(0, 3), Polyomino.pt(2, 3), Polyomino.pt(2, 1), Polyomino.pt(1, 1), Polyomino.pt(1, 0));
            break;
          case 'T':
            o.push(Polyomino.pt(0, 3), Polyomino.pt(3, 3), Polyomino.pt(3, 2), Polyomino.pt(2, 2), Polyomino.pt(2, 0), Polyomino.pt(1, 0), Polyomino.pt(1, 2), Polyomino.pt(0, 2));
            break;
          case 'U':
            o.push(Polyomino.pt(0, 0), Polyomino.pt(3, 0), Polyomino.pt(3, 2), Polyomino.pt(2, 2), Polyomino.pt(2, 1), Polyomino.pt(1, 1), Polyomino.pt(1, 2), Polyomino.pt(0, 2));
            break;
          case 'V':
            o.push(Polyomino.pt(0, 0), Polyomino.pt(3, 0), Polyomino.pt(3, 1), Polyomino.pt(1, 1), Polyomino.pt(1, 3), Polyomino.pt(0, 3));
            break;
          case 'W':
            o.push(Polyomino.pt(0, 3), Polyomino.pt(1, 3), Polyomino.pt(1, 2), Polyomino.pt(2, 2), Polyomino.pt(2, 1), Polyomino.pt(3, 1), Polyomino.pt(3, 0), Polyomino.pt(1, 0), Polyomino.pt(1, 1), Polyomino.pt(0, 1));
            break;
          case 'X':
            o.push(Polyomino.pt(0, 1), Polyomino.pt(0, 2), Polyomino.pt(1, 2), Polyomino.pt(1, 3), Polyomino.pt(2, 3), Polyomino.pt(2, 2), Polyomino.pt(3, 2), Polyomino.pt(3, 1), Polyomino.pt(2, 1), Polyomino.pt(2, 0), Polyomino.pt(1, 0), Polyomino.pt(1, 1));
            break;
          case 'Y':
          case 'y':
            o.push(Polyomino.pt(0, 2), Polyomino.pt(0, 3), Polyomino.pt(1, 3), Polyomino.pt(1, 4), Polyomino.pt(2, 4), Polyomino.pt(2, 0), Polyomino.pt(1, 0), Polyomino.pt(1, 2));
            break;
          case 'Z':
            o.push(Polyomino.pt(0, 2), Polyomino.pt(0, 3), Polyomino.pt(2, 3), Polyomino.pt(2, 1), Polyomino.pt(3, 1), Polyomino.pt(3, 0), Polyomino.pt(1, 0), Polyomino.pt(1, 2));
            break;
          default:
            throw new MathError('Polyomino of order 5 must be F, f, I, L, N, P, T, U, V, W, X, Y, y or Z');
        }
        break;
    }

    return new WPolygon(o);
  }

  /**
   *
   */
  private static pt(x:number, y:number):Point{
    return new Point(x, y);
  }

}
