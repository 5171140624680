import { ClientApplicationModel } from '../../models/ClientApplicationModel';
import { api } from '../../utils/fetch';

export interface IPageContentMigratePageContentFormatResponse {
  pageLock: {
    pageGuid: string,
    status: 'acquired' | 'failed' | 'requireMigration',
    lockedBy: string,
  };
}

export const pageContentMigratePageContentFormat = async (pageGuid: string, clientApplication: ClientApplicationModel): Promise<IPageContentMigratePageContentFormatResponse> => {
  const response = await api<IPageContentMigratePageContentFormatResponse>(`/Publisher/PageContent/MigratePageFolder?pageGuid=${pageGuid}&${clientApplication.params}`);
  return response.json();
};
