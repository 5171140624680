import { AbstractStep } from '../AbstractStep';
import { Compartment } from '../Compartment';
import { LongMultiplicationOperation } from '../LongMultiplicationOperation';

/**
 *
 */
export class MultAddColumnSum extends AbstractStep {

  private carry:number;

  private digits:number[] = [];

  constructor(operation:LongMultiplicationOperation){
    super(operation);
    this.carry = operation.addCarried;

    let c:Compartment;
    let s:number = 0;
    const index:number = operation.addPosition;

    c = operation.sumCarries[0];
    if(c){
      this.source.push(c);
    }

    for (let i:number = 0 ; i < operation.multSteps.length ; i++){
      const step:Compartment[] = operation.multSteps[i];
      c = index < step.length ? step[step.length - (index + 1)] : null;
      if(c){
        this.source.push(c);
        this.digits.push(c.value);
        s += c.value;
      }
    }

    s += this.carry;

    c = Compartment.createDigit(s % 10);
    this.target.push(c);
    operation.sum.unshift(c);

    if(s > 9){
      operation.addCarry = Math.floor(s / 10);
    }else{
      operation.addCarried = 0;
      operation.addPosition++;
      if(operation.addPosition < operation.addColumns){
        operation.sumCarries.unshift(null);
      }
    }

    const numDigits:number = this.digits.length + (this.carry > 0 ? 1 : 0);

    const resourceKey =
      numDigits > 1 ?
        'addColumnSumLabel' :
        'digitDownSumLabel';

    this.setDescription(operation.culture.getString(`LongMultiplicationOperation.${resourceKey}`));
  }

}
