import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WBoolean } from '../../elements/tokens/WBoolean';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Indique si les mots reliés à une
 * variable numérique doivent être au pluriel.
 */
export class IsPlural extends FunctionElement {

  /**
   * Indique si les mots doivent s'accorder au
   * pluriel selon la quantité spécifiée.
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 1){
      return args.expectingArguments(1, 1);
    }
    if(args.getReal(0)){
      return WBoolean.parse(args.env.culture.isPlural(args.getReal(0).toNumber()));
    }
    return null;
  }

}
