import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WString } from '../../elements/tokens/WString';
import { WTimeSpan } from '../../elements/tokens/WTimeSpan';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class TimeSpan extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length < 1 || args.length > 2){
      return args.expectingArguments(1, 2);
    }

    if (args.length === 1) {
      if (args.getTimeOfDay(0)) {
        return new WTimeSpan(args.getTimeOfDay(0).totalSeconds * WTimeSpan.S_MS);
      }
    } else if(args.length === 2) {
      if (args.getReal(0) && args.getString(1)) {
        return this.impl(args.getReal(0), args.getString(1));
      }
    }

    return null;
  }

  /**
   * s
   * ms
   * m
   * h
   * d
   */
  private impl(valueR:RealElement, unit:WString):WTimeSpan{
    if(!valueR.isWholeNumber()){
      return null;
    }
    return WTimeSpan.createSpan(valueR.toNumber(), unit.getString());
  }

}
