import { runInAction } from 'mobx';
import { PagesStore } from '../models/store/PagesStore';
import { IPage } from '../models/IPage';
import { instantiate } from '../dependencyInjection/instantiate';
import { createPageCommand } from './createPageCommand';

export function setCurrentPagesCommand(pages: readonly IPage[]) {
  runInAction(() => {
    const store = instantiate(PagesStore);
    store.clear();
    for (const page of pages) {
      createPageCommand(page);
    }
  });
}
