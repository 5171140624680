import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, PencilIcon, DisabledButtonTooltip } from '@scolab/publisher-ui-kit';
import { VariantManagerModal } from './VariantManagerModal';
import { LocaleProvider } from '../../LocaleProvider';
import { locales } from '../../../autoGenerate/locale';
import { useMediator } from '../../../dependencyInjection/useMediator';
import { AccessAndRightsMediator } from '../../../mediators/AccessAndRightsMediator';

interface IShowVariantManagerModalButton {
  className?: string;
}

export const ShowVariantManagerModalButton: React.FC<IShowVariantManagerModalButton> = (props) => {
  const { className } = props;
  const [isOpen, setIsOpen] = React.useState(false);
  const onToggle = () => setIsOpen((prevState) => !prevState);
  const { canManageVariants } = useMediator(AccessAndRightsMediator);

  return (
    <LocaleProvider locales={locales}>
      <DisabledButtonTooltip
        disabled={!canManageVariants}
        intlKey='variants.cannotManageVariants'
      >
        <Button
          onClick={onToggle}
          disabled={!canManageVariants}
          className={className}
        >
          <PencilIcon />
          <FormattedMessage id="variants.manageVariants" />
        </Button>
      </DisabledButtonTooltip>
      <VariantManagerModal
        isOpen={isOpen}
        onClose={onToggle}
      />
    </LocaleProvider>
  )
};
