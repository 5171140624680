import { XGeom } from '../../../core/XGeom';
import { XMath } from '../../../core/XMath';
import { XSort } from '../../../core/XSort';
import { ContentElement } from '../../../elements/abstract/ContentElement';
import { FunctionElement } from '../../../elements/abstract/FunctionElement';
import { WBoolean } from '../../../elements/tokens/WBoolean';
import { WPolygon } from '../../../elements/tokens/WPolygon';
import { ArgumentsObject } from '../../../expr/ArgumentsObject';

/**
 * Sont similaires?
 */
export class AreSimilar extends FunctionElement {

  public callReturnElement(args:ArgumentsObject):ContentElement {
    if (args.length !== 2){
      return args.expectingArguments(2, 2);
    }

    if(args.getPolygon(0) && args.getPolygon(1)){
      return this.areSimilarPolygon(args.getPolygon(0), args.getPolygon(1));
    }

    return null;
  }

  /**
   * t()
   */
  private areSimilarPolygon(a:WPolygon, b:WPolygon):WBoolean{
    if(a.vertices.length !== b.vertices.length){
      return WBoolean.FALSE;
    }
    const test:boolean = this.proportional(
      XGeom.edges(a.vertices).sort(XSort.numeric),
      XGeom.edges(b.vertices).sort(XSort.numeric));
    return WBoolean.parse(test);
  }

  /**
   *
   */
  private proportional(a:number[], b:number[]):boolean{
    const r:number = a[0] / b[0];

    for(let i:number = 1 ; i < a.length ; i++){
      const r2:number = a[i] / b[i];

      if(!XMath.safeEquals(r, r2)){
        return false;
      }
    }

    return true;
  }

}
