import React from 'react';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import {
  ITreeProps,
  MindIcon,
  Spinner,
  Tree,
  TreeNodeItem
} from '@scolab/publisher-ui-kit';
import { CurriculumTreeNodeType, isCourseNode, isCurriculumNode } from '../../../../models/CurriculumsTreeDataDescriptor';
import { inject } from '../../../../dependencyInjection/inject';
import { CurriculumTreeNodeId } from '../../../../models/ICurriculumTreeHeader';
import { ICourseRef } from '../../../../models/ICourseRef';
import { ICurriculum } from '../../../../models/ICurriculum';
import {
  NavigationTreeViewItem,
  NavigationTreeViewItemType,
} from '../../../layouts/NavigationTreeViewItem';
import { CurriculumTreeViewItem } from './CurriculumTreeViewItem';
import { CourseTreeViewItem } from './CourseTreeViewItem';
import AddAlignmentModalMediator from '../../../../mediators/AddAlignmentModalMediator';

interface ICurriculumsTreeView {
  onSelect?: () => void;
}

@observer
export class CurriculumsTreeView extends React.Component<ICurriculumsTreeView> {

  @inject(AddAlignmentModalMediator)
  private mediator: AddAlignmentModalMediator;

  public render(): JSX.Element {
    const {
      curriculumsTreeData,
      curriculumsOpenedNodes,
      selectedNode,
      searchResults,
    } = this.mediator;

    const tree: ITreeProps = {
      showRootNode: true,
      dataDescriptor: curriculumsTreeData,
      nodeRenderer: this.renderTreeNode,
      opened: curriculumsOpenedNodes,
      selection: [selectedNode],
      onToggle: this.toggleOpenNode,
      onSelect: this.onSelectNode,
      gap: 8,
    };

    if (!curriculumsTreeData) {
      return (
        <Spinner />
      );
    }

    return (
      <StyledTree
        hasSearch={searchResults !== null}
        closeTooltip={<FormattedMessage id='common.close' />}
        openTooltip={<FormattedMessage id='common.open' />}
        {...tree}
      />
    );
  }

  /**
   * When closing a node that has a descendant selected, we need to select the node that we are closing.
   *
   * @param nodeId
   */
  private toggleOpenNode = (nodeId: string): void => {
    this.mediator.toggleCurriculumsOpenNode(nodeId);
  }

  private onSelectNode = (nodeId: string): void => {
    const { onSelect } = this.props;

    if (onSelect) {
      onSelect();
    }

    this.mediator.setSearchResults(null);
    this.mediator.setSelectedNode(nodeId);
  }

  private renderTreeNode = (node: CurriculumTreeNodeType): JSX.Element => {
    switch (node.id) {
      case CurriculumTreeNodeId.curriculums:
        return this.renderCurriculumsNode();
    }

    if (isCurriculumNode(node)) {
      return this.renderCurriculumNode(node as ICurriculum);
    }

    if (isCourseNode(node)) {
      return this.renderCourseNode(node as ICourseRef);
    }

    return null;
  }

  private renderCurriculumsNode = (): JSX.Element => {
    return (
      <NavigationTreeViewItem
        type={NavigationTreeViewItemType.header}
        icon={<MindIcon/>}
        label={(<FormattedMessage id='alignmentAndStandard.curriculums' />)}
      />
    );
  }

  private renderCurriculumNode = (node: ICurriculum): JSX.Element => {
    return (
      <CurriculumTreeViewItem
        curriculum={node}
      />
    );
  }

  private renderCourseNode = (node: ICourseRef): JSX.Element => {
    return (
      <CourseTreeViewItem
        course={node}
      />
    );
  }

}

interface IStyledTree {
  hasSearch: boolean;
}

const StyledTree = styled(Tree)<IStyledTree>`
  width: 100%;

  ${(props) => props.hasSearch && `
    ${TreeNodeItem} {
      background: transparent;
    }
  `}
`;
