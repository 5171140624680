import { XMath } from '../../core/XMath';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WBoolean } from '../../elements/tokens/WBoolean';
import { WList } from '../../elements/tokens/WList';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class ContainsPattern extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 2){
      return args.expectingArguments(2, 2);
    }

    let source:WMatrix = args.getMatrix(0);
    if(!source){
      const sourceL:WList = args.getReals(0);
      if(!sourceL){
        return null;
      }
      source = new WMatrix(sourceL.toReals(), sourceL.count, args.env.culture.formats.matrixFormatImpl);
    }

    let pattern:WMatrix = args.getMatrix(1);
    if(!pattern){
      const patternL:WList = args.getReals(1);
      if(!patternL){
        return null;
      }
      pattern = new WMatrix(patternL.toReals(), patternL.count, args.env.culture.formats.matrixFormatImpl);
    }

    return WBoolean.parse(this.containsImpl(source, pattern));
  }

  /**
   *
   */
  private containsImpl(
      source:WMatrix,
      pattern:WMatrix):boolean{

    if(		pattern.columns > source.columns ||
        pattern.rows > source.rows){
      return false;
    }

    for(let r:number = 0 ; r <= (source.rows - pattern.rows) ; r++){
      for(let c:number = 0 ; c <= (source.columns - pattern.columns) ; c++){
        let temp:boolean = true;
        for(let i:number = 0 ; i < pattern.rows ; i++){
          for(let j:number = 0 ; j < pattern.columns ; j++){

            const a:number = source.valueAt(r + i, c + j).toNumber();
            const b:number = pattern.valueAt(i, j).toNumber();

            if(!XMath.safeEquals(a, b)){
              temp = false;
              break;
            }
          }
          if(!temp){
            break;
          }
        }
        if(temp){
          return true;
        }
      }
    }

    return false;
  }

}
