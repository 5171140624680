import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WString } from '../../elements/tokens/WString';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Index-ième caractère d'une chaîne.
 */
export class Char extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 2){
      return args.expectingArguments(2, 2);
    }

    const s = args.getString(0);
    if(!s){
      return null;
    }

    const index = args.getWholeNumber(1);
    if(!index){
      return null;
    }

    return index.toNumber() < s.getString().length ?
      new WString(s.getString().charAt(index.toNumber()), null, s.getSource()) :
      null;
  }

}
