import { Match } from '../core/Match';
import { ContentElement } from '../elements/abstract/ContentElement';
import { Node } from '../elements/abstract/Node';
import { SymbolElement } from '../elements/abstract/SymbolElement';
import { WNumber } from '../elements/tokens/WNumber';
import { TokensImporter } from '../expr/conversion/input/TokensImporter';
import { IWriter } from '../expr/conversion/writers/IWriter';
import { Skeleton } from '../expr/manipulation/Skeleton';
import { Power } from '../funcs/arithmetic/Power';
import { KeyboardConfiguration } from './KeyboardConfiguration';
import { InputCapabilities } from './InputCapabilities';
import { BaseCorrector } from './BaseCorrector';

/**
 *
 */
export class CNegativeExponent extends BaseCorrector {

  public parse(value:string):Node{
    const value2:ContentElement[] = this.parseInput(value);
    if(value2){
      const base:ContentElement = value2[0];
      const exp:ContentElement = value2[1];
      const node:Node =
        TokensImporter.importTokens(
          [base, Power.getInstance(), exp],
          this.env);
      node.userData = base.userData + '^' + exp.userData;
    }
    return null;
  }

  public correct(
      value:string,
      target:ContentElement,
      ...targets:any[]):boolean{

    const value2:ContentElement[] = this.parseInput(value);
    if(!value2){
      return false;
    }

    const base:SymbolElement = <SymbolElement>value2[0] ;
    const exponent:WNumber = <WNumber>value2[1] ;

    const base2:SymbolElement = <SymbolElement>target ;
    const exponent2:WNumber = <WNumber>targets[0] ;

    return 	base.getSymbol() === base2.getSymbol() &&
        exponent.toNumber() === exponent2.toNumber();
  }

  private parseInput(valueArg:string):ContentElement[]{
    const value = this.translateInput(valueArg);

    const r:RegExp = new RegExp('^([a-z])<sup>(-?[0-9]+)</sup>$');
    const m:Match = Match.tryParse(r.exec(value));
    if(m == null){
      return null;
    }

    const base:string = String(m.groups[1]);
    const exponent:number = this.numberParser.parseNumber(String(m.groups[2]));

    if(base.length !== 1){
      return null;
    }

    if(isNaN(exponent)){
      return null;
    }

    const o:ContentElement[] = [];
    o.push(this.env.culture.createVariable(base));
    o.push(this.env.culture.createNumber(exponent));
    return o;
  }

  public get inputCapabilities():InputCapabilities{
    const i:InputCapabilities = new InputCapabilities();
    i.superscript = true;
    return i;
  }

  public get mathKeyboard():number{
    return KeyboardConfiguration.NEGATIVE_EXP;
  }

  public writeTo(
      w:IWriter,
      target:ContentElement,
      ...targets:any[]):void{
    w.writeSymbol((<SymbolElement>target ).getSymbol());
    w.writeSuperscript((<WNumber>targets[0] ).toNumber());
  }

  public static validate(node:Node):boolean{
    return Skeleton.createSkeleton(node) === '^(x,n)';
  }

  private translateInput(valueArg:string):string{
    let value = valueArg;
    if(this.useLatex){
      value = this.sanitizeInput(value);
      value = value.replace(/\^\{\}/g, '');
      value = value.replace(/\^\{([^\}]+)\}/g, '<sup>$1</sup>');
    }

    // x<sup>−1</sup>
    value = value.split(' ').join('');
    value = value.split('−').join('-');
    return value;
  }

}
