import { XString } from '../../../core/XString';
import { CultureInfo } from '../../../localization/CultureInfo';
import { WriteNumberBaseFormatter } from '../../../elements/formats/write/WriteNumberBaseFormatter';

/**
 *
 */
export class WriteNumberFrFormatter extends WriteNumberBaseFormatter {

  /**
   *
   */
  private static CHIFFRES:any[] =
    ['', 'un', 'deux', 'trois', 'quatre', 'cinq',
    'six', 'sept', 'huit', 'neuf', 'dix', 'onze',
    'douze', 'treize', 'quatorze', 'quinze', 'seize',
    'dix-sept', 'dix-huit', 'dix-neuf'];

  private static TRANCHES_UP:any[] = ['','mille','million','milliard','billion','mille billions','trillion','mille trillions','quatrillion','mille quatrillions'];

  private static TRANCHES_DW:any[] = ['dixièmes','centièmes','millièmes','dix-millièmes','cent-millièmes','millionnièmes','dix-millionnièmes','cent-millionnièmes','milliardièmes','dix-milliardièmes','cent-milliardièmes'];

  constructor(culture:CultureInfo){
    super(culture, -9999999999999, 9999999999999, 10);
  }

  public toLocaleString(valueArg:number):string{
    let value = valueArg;
    const negatif:boolean = value < 0;
    value = Math.abs(value);

    let nombre:string = value.toString().split(',').join('.');
    let nombreUp:string = nombre.split('.')[0];
    let nombreDown:string = nombre.split('.')[1];
    if (!nombreUp) {
      nombreUp = '';
    }
    if (!nombreDown) {
      nombreDown = '';
    }
    const nombreDownLength:number = nombreDown.length;
    const singulariserPartieDecimales:boolean = new RegExp('^0*1$').test(nombreDown);

    nombreUp = this.orthographe1990(this.manipulation(nombreUp));
    nombreDown = this.orthographe1990(this.manipulation(nombreDown));

    if (nombreUp !== '' && nombreDown !== '') {
      nombre = `${nombreUp} et ${nombreDown} ${this.singlariser(WriteNumberFrFormatter.TRANCHES_DW[nombreDownLength - 1], singulariserPartieDecimales)} `;
    } else if (nombreUp !== '' && nombreDown === '') {
      nombre = nombreUp;
    } else if (nombreUp === '' && nombreDown !== '') {
      nombre = `${nombreDown} ${this.singlariser(WriteNumberFrFormatter.TRANCHES_DW[nombreDownLength - 1], singulariserPartieDecimales)} `;
    }

    nombre = nombre.split('zéro et ').join('');
    nombre = nombre.split('  ').join(' ');
    nombre = nombre.split('  ').join(' ');
    nombre = nombre.split('ss').join('s');

    // 20 et 100 s'accordent quand ils sont multipliés par un nombre sans être suivis par un autre nombre.
    nombre = nombre.replace(new RegExp('vingts(.)', 'gi'), 'vingt$1');
    nombre = nombre.replace(new RegExp('cents(.)', 'gi'), 'cent$1');

    return (negatif ? 'moins ' : '') + XString.trim(nombre);
  }

  private singlariser(
      str:string,
      value:boolean):string{
    return value ? str.substring(0, str.length - 1) : str;
  }

  // https://github.com/scolab/buzzmath/issues/308
  // http://fr.wikipedia.org/wiki/Trait_d%27union#.C3.89criture_des_nombres
  private orthographe1990(valueArg:string):string{
    const ds:string = '  '; // double espace
    const dt:string = '--'; // double tiret
    let value = XString.trim(valueArg);
    while(value.indexOf(ds) !== -1){
      value = value.split(ds).join(' ');
    }
    value = value.split(' ').join('-');
    while(value.indexOf(dt) !== -1){
      value = value.split(dt).join('-');
    }
    return value;
  }

  private manipulation(nombre:string):string {
    let c0:string = '';
    let c1:string = '';
    let c2:string = '';
    let sl:string = '';
    let index:number = 0;
    const str:any[] = [];
    let return_str:string = '';
    while (true) {
      c0 = nombre.charAt(nombre.length-index-1);
      c1 = nombre.charAt(nombre.length-index-2);
      c2 = nombre.charAt(nombre.length-index-3);
      sl = c2 + c1 + c0;
      if (c0 === '') { break; }
      str[index] = '';
      if (String((index+1)/3).indexOf('.') === -1 && Number(c0) > 1) { // dans les centaines
        str[index] = str[index]+this.tradChaLet(String(c0)); // " "+chiffres[Number(c0)]
      } else if (String((index)/3).indexOf('.') === -1) { // dizaine et unité
        str[index] = str[index]+this.tradChaLet(String(c1+c0)); // " "+chiffres[Number(c1+c0)]
        if (str[index] === 'zéro' && nombre.length > 1) {
          str[index] = '';
        }
      }

      if (String((index+1)/3).indexOf('.') === -1 && index !== 0 && Number(c0) === 1) {
        str[index] = `${str[index]} cent`;
      }
      if (String((index+1)/3).indexOf('.') === -1 && index !== 0 && Number(c0) > 1) {
        if (Number(nombre.slice(nombre.length-index,nombre.length-index+2)) === 0) {
          str[index] = `${str[index]} cents`;
        } else {
          str[index] = `${str[index]} cent`;
        }
      }
      // detect TRANCHES_UP
      if (String((index)/3).indexOf('.') === -1 && index !== 0 && Number(sl) > 0) {
        if (Number(sl) > 1 || (index/3) > 1) {
          if (Number(sl) > 1) {
            str[index] = `${str[index]} ${WriteNumberFrFormatter.TRANCHES_UP[(index / 3)]}${(WriteNumberFrFormatter.TRANCHES_UP[(index / 3)] === 'mille') ? '' : 's'}`;
          } else {
            str[index] = `${str[index]} ${WriteNumberFrFormatter.TRANCHES_UP[(index / 3)]}`;
          }
        } else {
          str[index] = WriteNumberFrFormatter.TRANCHES_UP[(index/3)];
        }
      }
      index++;
    }
    index = nombre.length-1;
    while (true) {
      if (str[index] === undefined) {
        break;
      } else {
        return_str += ` ${str[index]}`;
      }
      index--;
    }

    return return_str;
  }

  private tradChaLet(tr3Arg:string):string {
    let z1:number;
    let z2:number;
    let z3:number;
    let tr3 = tr3Arg;
    let dz:string;
    let tempo:string;
    while (tr3.length<3) {
      tr3 = `0${tr3}`;
    }
    // traduction des centaines
    switch (Number(tr3)) {
    case 0 :
      tempo = 'zéro';
      break;
    default :
      z1 = Number(tr3.charAt(0));
      dz = tr3.charAt(1)+tr3.charAt(2);
      z2 = Number(tr3.charAt(1));
      z3 = Number(tr3.charAt(2));

      switch (Number(z1)) {
        case 0 :
          tempo = '';
          break;
        case 1 :
          tempo = '';
          break;
        default :
          tempo = WriteNumberFrFormatter.CHIFFRES[0+Number(z1)];
      }
      // case z1
      // if (z1 === 0) {
      //   tempo = tempo;
      // }
      if (z1 === 1) {
        tempo = `${tempo} cent `;
      }
      if (z1 > 1) {
        tempo = `${tempo} cents `;
      }
      // traduction des dizaines et unités
      switch (dz) {
        case '0' :
          tempo = `${tempo} `;
          break;
        case '10' :
          tempo = `${tempo} dix`;
          break;
        case '11' :
          tempo = `${tempo} onze`;
          break;
        case '12' :
          tempo = `${tempo} douze`;
          break;
        case '13' :
          tempo = `${tempo} treize`;
          break;
        case '14' :
          tempo = `${tempo} quatorze`;
          break;
        case '15' :
          tempo = `${tempo} quinze`;
          break;
        case '16' :
          tempo = `${tempo} seize`;
          break;
        case '17' :
          tempo = `${tempo} dix-sept`;
          break;
        case '18' :
          tempo = `${tempo} dix-huit`;
          break;
        case '19' :
          tempo = `${tempo} dix-neuf`;
          break;
        default :
          if ((z2 === 2) || (z2 === 3) || z2 === 4 || z2 === 5 || z2 === 6 || z2 === 8) {
            if (z2 === 2) {
              tempo = `${tempo} vingt`;
            }
            if (z2 === 3) {
              tempo = `${tempo} trente`;
            }
            if (z2 === 4) {
              tempo = `${tempo} quarante`;
            }
            if (z2 === 5) {
              tempo = `${tempo} cinquante`;
            }
            if (z2 === 6) {
              tempo = `${tempo} soixante`;
            }
            if (z2 === 8) {
              tempo = `${tempo} quatre-vingt`;
            }
            if ((z2 === 8) && (z3 === 1)) {
              tempo = `${tempo}-${WriteNumberFrFormatter.CHIFFRES[z3]}`;
            } else if (z3 === 1) {
              tempo = `${tempo} et ${WriteNumberFrFormatter.CHIFFRES[z3]}`;
            } else if (z3!==0) {
              tempo = `${tempo}-${WriteNumberFrFormatter.CHIFFRES[z3]}`;
            } else if ((z2 === 8) && z3 === 0) {
               tempo += 's';
            }
          } else {
            switch (z2) {
              case 7 :
                tempo = `${tempo} soixante`;
                break;
              case 9 :
                tempo = `${tempo} quatre-vingt`;
                break;
            }
            // switch (z2) {
            if (z2!==0 && z3 === 1) {
              if (z2 === 7) {
                tempo = `${tempo} et ${WriteNumberFrFormatter.CHIFFRES[10 + Number(z3)]}`;
              } else {
                tempo = `${tempo}-${WriteNumberFrFormatter.CHIFFRES[10 + Number(z3)]}`;
              }
            }
            if (z2!==0 && z3!==1) {
              tempo = `${tempo}-${WriteNumberFrFormatter.CHIFFRES[10 + Number(z3)]}`;
            }
            if (z2 === 0 && z3!==0) {
              tempo = `${tempo} ${WriteNumberFrFormatter.CHIFFRES[Number(z3)]}`;
            }
          }
      }
      // switch (dz) {
    }
    // case tr3 + 0
    return tempo;
  }

}
