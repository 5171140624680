import { ICourseRef } from './ICourseRef';
import { HistoryEventEntityType, IHistoryEventEntity } from './history';
import { StringUtils } from '../utils/StringUtils';

export interface ICurriculum {
  readonly id: number;
  name: string;
  shortName: string;
  lang: string;
  countryCode: string;
  regionCode: string;
  curriculumCode: string;
  referenceUrl: string;
  publicationDate: string; // ISO date string
  archived: boolean;
  mappedCurriculums: number[];
  courses: ICourseRef[];
}

export const compareCurriculumsByName = (c1: ICurriculum, c2: ICurriculum) => {
  return StringUtils.compare(c1.name, c2.name);
};

export const curriculumEntity = (curriculum: ICurriculum): IHistoryEventEntity => {
  return {
    entityType: HistoryEventEntityType.Curriculum,
    entityId: curriculum.id,
    entityDescription: curriculum.name,
  };
};
