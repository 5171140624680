export class ArrayCollection {

  private _source:any[];

  constructor(source:any[] = null) {

    this._source = source || [];
  }

  public get source():any[]{
    return this._source.concat();
  }

  public get length():number{
    return this._source.length;
  }

  public contains(item:Object):boolean{
    return this._source.indexOf(item) !== -1;
  }

  public addItem(item:Object):void{
    this._source.push(item);
  }

  public getItemAt(index:number):Object{
    return this._source[index];
  }

  public toArray():any[]{
    return this._source.concat();
  }

}
