import { XNumber } from '../../core/XNumber';
import { XString } from '../../core/XString';
import { IPrng } from '../../core/prng/IPrng';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { RandomDigits } from '../../funcs/compatibility/RandomDigits';

/**
 * Compatibility
 */
export class RandomSignificantFigures extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 2){
      return args.expectingArguments(2, 2);
    }

    const template:RealElement = args.getReal(0);
    if(!template){
      return null;
    }

    const cc:RealElement = args.getWholeNumber(1);
    if(!cc){
      return null;
    }

    return args.env.culture.parseNumber(
      this.randomNumber(
        template.toNumber(),
        cc.toNumber(),
        args.env.prng));
  }

  /**
   *
   */
  private randomNumber(template:number, ccArg:number, prng:IPrng):number{
    let cc = ccArg;
    if(cc < 2){
      cc = 2;
    }
    const s:string = String(template);
    let p:string = '';
    let i:number;
    for(i = 0 ; i < s.length ; i++){
      if(s.charAt(i) === '.'){
        p += '.';
      }else if(i === 0 || (i === s.length - 1 && XNumber.decimals(template) > 0)){
        p += '#';
      }else{
        p += '0';
      }
    }

    while(XString.countChar(p, '0') !== (XString.countDigits(p) + XString.countChar(p, '#')) - cc && XString.countChar(p, '0') !== 0){
      i = prng.randomInt(1, p.length);
      let k:number = i;

      do{
        if(p.charAt(k) === '0'){
          p = `${p.substring(0, k)}#${p.substring(k + 1)}`;
          k = i;
        }else{
          k++;
          if(k === p.length){
            k = 1;
          }
        }
      }while(k !== i);
    }

    return RandomDigits.parse(p, true, prng);
  }

}
