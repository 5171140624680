import React from 'react';
import styled from 'styled-components';
import { setHexColorTransparency } from '../../themes/ColorSet';
import ChevronDownIcon from '../../assets/chevronDown.svg';
import { ReadonlyContext } from '../../contexts/ReadonlyContext';
import { isNullOrUndefined } from '../../utils/ObjectUtils';

export interface INumberInputProps {
  value: number | null;
  onChange: (value: number) => void;
  className?: string;
  disabled?: boolean;
  isNullable?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
  onMouseDown?: (event: React.MouseEvent) => void;
  label?: React.ReactNode | null;
  hasError?: boolean;
  'data-testid'?: string;
}

export const NumberInput: React.FC<INumberInputProps> = (props) => {
  const {
    label,
    value,
    onChange,
    className,
    isNullable,
    disabled,
    onFocus,
    onBlur,
    onMouseDown,
    hasError,
    'data-testid': testid,
  } = props;

  const [tempValue, setTempValue] = React.useState<string>(null);
  const isReadonly = React.useContext(ReadonlyContext);
  const isDisabled = disabled || isReadonly;
  const [hasFocus, setHasFocus] = React.useState(false);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const hasFocusRef = React.useRef(false);

  const currentValue = tempValue ?? (isNullOrUndefined(value) ? '' : String(value));

  const clearTempValue = () => {
    if (tempValue !== null) {
      setTempValue(null);
    }
  };

  const onChangeLocal = (event: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = event.target.value;
    const isValid = rawValue !== '' && !isNaN(Number(rawValue));
    if (isValid) {
      onChange(Number(rawValue));
      clearTempValue();
    } else if (isNullable) {
      onChange(null);
      clearTempValue();
    } else {
      setTempValue(rawValue);
    }
  };
  const onIncrementLocal = () => {
    onChange(value + 1);
    clearTempValue();
  };
  const onDecrementLocal = () => {
    onChange(value - 1);
    clearTempValue();
  };

  const onContainerFocus = (event: React.FocusEvent) => {
    if (!hasFocusRef.current) {
      hasFocusRef.current = true;
      onFocus?.();
    }
    setHasFocus(true);
  };
  const onContainerBlur = (event: React.FocusEvent) => {
    const isBlurHappeningInside = event.currentTarget.contains(event.relatedTarget as Node);
    if (!isBlurHappeningInside) {
      hasFocusRef.current = false;
      onBlur?.();
    }
    setHasFocus(false);
    clearTempValue();
  };

  const hasLabel = label !== null && label !== undefined;

  const labelComponent =
    hasLabel
      ? (
        <Label
          hasError={hasError}
        >
          {label}
        </Label>
      )
      : null;

  return (
    <Container
      className={className}
      tabIndex={1}
      onFocus={onContainerFocus}
      onBlur={onContainerBlur}
      onMouseDown={onMouseDown}
      hasError={hasError}
      hasFocus={hasFocus}
      disabled={isDisabled}
      data-testid={testid ? `NumberInput-${testid}` : 'NumberInput'}
    >
      {labelComponent}
      <Input
        ref={inputRef}
        hasLabel={hasLabel}
        value={currentValue}
        onChange={onChangeLocal}
        readOnly={isDisabled}
        pattern={'\d+'}
      />
      <ControlsContainer>
        <IncreaseButton
          onMouseDown={onIncrementLocal}
          disabled={isDisabled}
          data-testid="increment-button"
        >
          <ChevronDownIcon />
        </IncreaseButton>
        <DecreaseButton
          onMouseDown={onDecrementLocal}
          disabled={isDisabled}
          data-testid="decrement-button"
        >
          <ChevronDownIcon />
        </DecreaseButton>
      </ControlsContainer>
    </Container>
  );
};

const Container = styled.span<{hasError: boolean, hasFocus: boolean, disabled: boolean}>`
  position: relative;
  display: inline-flex;
  flex-direction: row;
  border-radius: 4px;
  background-color: ${props => props.theme.colorset.grey02};

  border: ${(props) => (props.disabled ? props.theme.input.default.disabled : props.theme.input.default).border};

  ${(props) => props.hasFocus && `
    border: ${props.theme.input.default.focus.border};
  `}

  ${(props) => props.hasError && `
    border: ${props.theme.input.default.error.border};
  `}

  &:hover {
    border: ${(props) =>
    (props.disabled ? props.theme.input.default.disabled :
      (props.hasError ? props.theme.input.default.error :
        props.theme.input.default.focus)).border};
  }
`;

const Label = styled.div<{hasError: boolean}>`
  position: absolute;
  color: ${(props) => props.hasError ? props.theme.colorset.error2 : props.theme.colorset.grey08};
  font: ${(props) => props.theme.typeset.captionRegular};
  font-size: 12px;
  line-height: 16px;
  left: 8px;
  top: 4px;
  user-select: none;
  pointer-events: none;
`;

const Input = styled.input<{hasLabel: boolean}>`
  padding: ${(props) => props.hasLabel ? '20px 0px 4px 8px' : '12px 0px 12px 8px'} ;
  outline: unset;
  border: none;
  flex: 1;
  background: transparent;
  width: 100%;
  font-weight: 600;
  color: ${props => props.theme.colorset.grey13};
`;

const ControlsContainer = styled.span`
  display: inline-flex;
  flex-direction: column;
  width: 32px;
`;

interface IButton {
  disabled: boolean;
}

const Button = styled.span<IButton>`
  cursor: pointer;
  flex: 1;
  display: flex;
  justify-content: center;

  ${(props) => props.disabled && `
    pointer-events: none;
    cursor: unset;
    opacity: 0.5;
  `}

  &:hover {
    background-color: ${props => props.theme.colorset.grey05};
  }

  &:active {
    background-color: ${props => setHexColorTransparency(props.theme.colorset.primary, 0.4)};
  }

  & svg {
    color: ${props => props.theme.colorset.grey13};
    width: 16px;
    height: 16px;
  }
`;

const IncreaseButton = styled(Button)`
  border-top-right-radius: 4px;
  align-items: flex-end;

  & svg {
    transform: rotate(180deg);;
  }
`;

const DecreaseButton = styled(Button)`
  border-bottom-right-radius: 4px;
  align-items: flex-start;
`;
