import { RealElement } from '../../elements/abstract/RealElement';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';
import { WNumber } from '../../elements/tokens/WNumber';

/**
 *
 */
export class WEulerConstant extends RealElement {

  private static symbol:string = '\u2107'; // "ℇ"

  private E:WNumber;

  /**
   *
   */
  constructor(E:WNumber){
    super();
    this.E = E;
  }

  /**
   *
   */
  public toNumber():number{
    return this.E.toNumber();
  }

  /**
   *
   */
  public toAbsoluteValue():RealElement{
    return this;
  }

  /**
   *
   */
  public toOpposite():RealElement{
    return new WNumber(-this.E.toNumber(), 1, false, this.E.formatter);
  }

  /**
   *
   */
  public writeTo(exporter:IMarkupExporter = null):boolean{
    if(exporter){
      exporter.writer.appendIdentifier(WEulerConstant.symbol);
    }
    return true;
  }

  /**
   *
   */
  public toText(strict:boolean):string{
    if(!strict){
      return WEulerConstant.symbol;
    }
    return null;
  }

  /**
   *
   */
  public getType():string {
    return 'eulerConstant';
  }

}
