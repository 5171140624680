import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { ListElement } from '../../elements/abstract/ListElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WList } from '../../elements/tokens/WList';
import { WString } from '../../elements/tokens/WString';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';

/**
 * Répèteur.
 */
export class Repeat extends FunctionElement {

  /**
   *
   */
  public static MAX_RESULT_CAPACITY:number = 1000;

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 2){
      return args.expectingArguments(2, 2);
    }

    // compatibility
    if(args.getString(0) && args.getReal(1)){
      return this.stringN(args.getString(0), args.getReal(1));
    }

    const repList:WList = args.getReals(1);
    if(!repList){
      return null;
    }
    for(let i:number = 0 ; i < repList.count ; i++){
      if(!repList.getTypedItemAt(i).isWholeNumber()){
        return null;
      }
    }

    let list:ListElement = args.getList(0);
    if(!list){
      const item:ContentElement = args.getContentElement(0);
      if(item && item.getListItemCode() != null){
        list = args.env.culture.listFactory.createList(args.args.slice(0, 1));
      }
    }

    if(list){
      if(repList.count === 0){
        return list.transform([]);
      }

      return this.repeatImpl(list, repList, args.env);
    }

    return null;
  }

  /**
   * Répète un élément n fois.
   * Répète les éléments d'une liste n fois.
   * Répète les éléments d'une liste en fonction d'un tableau indiquant le nombre de répétition par item.
   */
  private repeatImpl(list:ListElement, repList:WList, env:Environment):ListElement{
    let n:number = 0;
    for(let i:number = 0 ; i < list.count ; i++){
      n += repList.getValueAt(i < repList.count ? i : repList.count - 1);
    }
    if(n > Repeat.MAX_RESULT_CAPACITY){
      return null;
    }

    const o:ContentElement[] = [];

    let k:number;
    let j:number;
    let r:number;

    // compatibility: if repList has one element, repeat the whole list n times instead
    // of repeating each element of the list n times. The end result is the same list expect
    // for the ordering of the elements.
    if(repList.count === 1){
      r = repList.getValueAt(0);
      for(j = 0 ; j < r ; j++){
        for(k = 0 ; k < list.count ; k++){
          o.push(list.getItemAt(k));
        }
      }
    }else{
      for(k = 0 ; k < list.count ; k++){
        r = repList.getValueAt(k < repList.count ? k : repList.count - 1);
        for(j = 0 ; j < r ; j++){
          o.push(list.getItemAt(k));
        }
      }
    }

    if(o.length === 0){
      return list.transform([]);
    }

    return env.culture.listFactory.createList(o);
  }

  /**
   *
   */
  private stringN(c:WString, rep:RealElement):WString{
    if(!rep.isWholeNumber()){
      return null;
    }

    const n:number = rep.toNumber();
    if(n === 0){
      return new WString('');
    }

    const o:string[] = [];

    for(let i:number = 0 ; i < n ; i++){
      o.push(c.getString());
    }

    return new WString(o.join(''), null, c.getSource());
  }

}
