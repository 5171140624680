import { RealElement } from '../../elements/abstract/RealElement';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';
import { CultureInfo } from '../../localization/CultureInfo';
import { AbstractFormatter } from '../../elements/formats/AbstractFormatter';

/**
 *
 */
export class BasePointFormatter extends AbstractFormatter {

  /**
   *
   */
  constructor(culture:CultureInfo) {

    super(culture);
  }

  /**
   *
   */
  public writeTo(exporter:IMarkupExporter, x:RealElement, y:RealElement):boolean{
    throw new Error();
  }

  /**
   *
   */
  public toLocaleString(x:RealElement, y:RealElement, strict:boolean):string{
    throw new Error();
  }

}
