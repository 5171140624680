import { AuthTokenProvider } from './AuthTokenProvider';

class TetrisApiAuthService extends AuthTokenProvider {

  protected get storageKey() {
    return 'AccessToken';
  }

}

export const TetrisApiAuth = new TetrisApiAuthService();

