import { IMemberInfo } from '../models/IMemberInfo';
import { api } from '../utils/fetch';

export interface IGetPublishingMembersByIdsResponse {
  members: ReadonlyArray<IMemberInfo>;
  unknownMembers: ReadonlyArray<string>;
}

export const getPublishingMembersByIds = async (ids: ReadonlyArray<string>): Promise<IGetPublishingMembersByIdsResponse> => {
  const response = await api<IGetPublishingMembersByIdsResponse>('/Publisher/Workbench/GetPublishingMembersByIds', {
    method: 'POST',
    body: JSON.stringify(ids),
    headers: {
      'Content-Type': 'application/json'
    }
  });
  return response.json();
};
