import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';

/**
 * Retourne la position de toutes les occurences du nombre spécifié dans la matrice.
 */
export class MatrixFind extends FunctionElement {
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 2) {
      return args.expectingArguments(2, 2);
    }

    const matrix = args.getMatrix(0);
    if (!matrix) {
      return null;
    }

    const search = args.getReal(1);
    if (!search) {
      return null;
    }

    return args.env.culture.listFactory.createList(this.find(matrix, search, args.env), 'point');
  }

  private find(matrix: WMatrix, search: RealElement, env: Environment): ContentElement[] {
    const elements: ContentElement[] = [];

    for (let row = 0; row < matrix.rows; row++) {
      for (let column = 0; column < matrix.columns; column++) {
        if (matrix.valueAt(row, column).toNumber() === search.toNumber()) {
          elements.push(env.culture.parseCoor(row, column));
        }
      }
    }

    return elements;
  }
}
