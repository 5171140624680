import { XString } from '../../../core/XString';
import { MmlWriter } from '../../../core/mml/MmlWriter';
import { Node } from '../../../elements/abstract/Node';
import { RelationalElement } from '../../../elements/abstract/RelationalElement';
import { Apply } from '../../../elements/constructs/Apply';
import { IMarkupExporter } from '../../../elements/markers/IMarkupExporter';
import { WNumber } from '../../../elements/tokens/WNumber';
import { WRelation } from '../../../elements/tokens/WRelation';
import { IRowAnnotation } from '../../../expr/conversion/output/IRowAnnotation';

/**
 *
 */
export class AutoAnnotation implements IRowAnnotation {

  /**
   *
   */
  public annotate(
      exporter:IMarkupExporter,
      node:Node,
      comment:string,
      rowIndex:number,
      firstLinePrefix:string,
      linePrefix:string,
      tag:string):void{

    const writer:MmlWriter = exporter.writer;

    writer.beginTr();

    if(node.value instanceof Apply &&
      node.childs[0].value instanceof RelationalElement){

      writer.columnalign = 'right center left';

      writer.beginTd();
      exporter.writeNode(node.childs[1]);
      writer.endTd();

      writer.beginTd();
      exporter.writeOperator((<RelationalElement>node.childs[0].value ).toString());
      writer.endTd();

      writer.beginTd();
      exporter.writeNode(node.childs[2]);
      writer.endTd();

    }else if(node.value instanceof WRelation){
      const relation:WRelation = <WRelation>node.value ;

      writer.columnalign = 'right center left';

      writer.beginTd();
      relation.lhs.writeTo(exporter);
      writer.endTd();

      writer.beginTd();
      exporter.writeOperator(relation.rel.toString());
      writer.endTd();

      writer.beginTd();
      relation.rhs.writeTo(exporter);
      writer.endTd();

    }else{

      writer.columnalign = 'left';

      const prefix:string = rowIndex === 0 ? firstLinePrefix : linePrefix;

      if(firstLinePrefix != null || linePrefix != null){
        writer.beginTd(); // If prefix are defined, always add a cell
        if(prefix){ // Prefix defined for this row?
          if(prefix === '='){
            writer.appendText(this.equalOrAlmostEqualTo(node));
          }else if(prefix === '1.'){
            writer.appendText(XString.substitute('{0}.', rowIndex + 1));
          }else if(prefix === 'a.'){
            writer.appendText(XString.substitute('{0}.', String.fromCharCode(rowIndex + 97)));
          }else{
            writer.appendText(prefix);
          }
        }
        writer.endTd();
      }

      writer.beginTd();
      exporter.writeNode(node);
      writer.endTd();

      if(comment){
        writer.beginTd();
        exporter.writeParagraph(comment, 40);
        writer.endTd();
      }

      // tag is useful for debugging only
      /*
      if(tag){
        writer.beginTd();
        exporter.writeParagraph(tag, 40);
        writer.endTd();
      }
      */
    }

    writer.endTr();
  }

  private equalOrAlmostEqualTo(node:Node):string{
    if(node.value instanceof WNumber){
      return (<WNumber>node.value ).approximate ? '≈' : '=';
    }
    return '=';
  }

}
