import { IDictionary } from '../../../js/utils/IDictionary';

import { MmlWriter } from '../../core/mml/MmlWriter';
import { Compartment } from '../../elementary/Compartment';
import { ElementaryOperation } from '../../elementary/ElementaryOperation';
import { HLine } from '../../elementary/HLine';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';
import { IMarkupFactory } from '../../elements/markers/IMarkupFactory';
import { CultureInfo } from '../../localization/CultureInfo';

/**
 *
 */
export class LongOperationImpl implements IMarkupFactory {

  /**
   *
   */
  private operation:ElementaryOperation;

  /**
   *
   */
  constructor(operation:ElementaryOperation){
    this.operation = operation;
  }

  /**
   *
   */
  public get culture():CultureInfo{
    return this.operation.culture;
  }

  /**
   * Convert a long operation model into it's
   * MathML markup representation.
   */
  public writeTo(exporter:IMarkupExporter):void{
    const w:MmlWriter = exporter.writer;

    let c:Compartment;
    let i:number;
    let r:number; // row index iterator
    let k:number; // column index iterator

    let rows:number = 0;
    let columns:number = 0;
    const compartments:any[] = this.operation.compartments.toArray();
    const content:IDictionary = {};
    const crossouts:IDictionary = {};
    let key:string;

    // Find number of rows and columns
    for(i = 0 ; i < compartments.length ; i++){
      c = compartments[i];
      rows = Math.max(rows, c.row + 1);
      columns = Math.max(columns, c.column + 1);
    }

    // Build map of content and crossouts
    for(i = 0 ; i < compartments.length ; i++){
      c = compartments[i];
      key = String(c.row * columns + c.column);
      if(c.type === Compartment.CROSSOUT){
        crossouts[key] = true;
      }else{
        content[key] = compartments[i];
      }
    }

    w.beginTable();
    w.rowspacing = '0';
    w.columnspacing = '0';

    const rowlines:string[] = [];
    for(r = 0 ; r < rows ; r++){
      rowlines.push('none');
    }
    for(i = 0 ; i < this.operation.lines.length ; i++){
      const line:HLine = <HLine>this.operation.lines.getItemAt(i) ;
      rowlines[line.row] = 'solid';
    }
    w.rowlines = rowlines.join(' ');

    for(r = 0 ; r < rows ; r++){
      w.beginTr();
      for(k = 0 ; k < columns ; k++){
        w.beginTd();
        key = String(r * columns + k);
        if(content.hasOwnProperty(key)){
          c = content[key];

          if(crossouts.hasOwnProperty(key)){
            w.beginEnclose('updiagonalstrike');
          }

          if(c.frame){
            const notation:any[] = [];
            if(c.frame.left){
              notation.push('left');
            }
            if(c.frame.right){
              notation.push('right');
            }
            if(c.frame.top){
              notation.push('top');
            }
            if(c.frame.bottom){
              notation.push('bottom');
            }
            w.beginEnclose(notation.join(' '));
          }

          this.writeCompartment(w, c);

          if(c.frame){
            w.endEnclose();
          }

          if(crossouts.hasOwnProperty(key)){
            w.endEnclose();
          }
        }
        w.endTd();
      }
      w.endTr();
    }

    // Align the column with the result on the left
    w.endTable();
  }

  /**
   *
   */
  private writeCompartment(w:MmlWriter, compartment:Compartment):void{
    if(compartment.faded){
      w.beginStyle();
      w.mathcolor = '#AAAAAA';
    }

    switch(compartment.type){
      case Compartment.DIGIT:
      case Compartment.CARRY:
      case Compartment.REGROUP:
        w.appendNumber(compartment.text);
        if(compartment.type === Compartment.CARRY || compartment.type === Compartment.REGROUP){
          w.mathsize = '71%';
        }

        break;
      case Compartment.BORROW:
        // text is 1, value is between 10 and 19.
        w.appendNumber(compartment.text);
        w.mathsize = '71%';
        break;
      case Compartment.DECIMAL_SEPARATOR:
      case Compartment.OPERATOR:
        w.appendOperator(compartment.text);
        w.lspace = '0px';
        w.rspace = '0px';
        break;
      case Compartment.CHAR:
        if( compartment.text != null && compartment.text) {
          w.appendText(compartment.text);
        } else {
          w.beginRow();
          w.endRow();
        }
        break;
      case Compartment.BLANK_SPACE:
        w.appendSpace();
        break;
      case Compartment.CROSSOUT:
        // Cross-out compartment is handled somewhere else.
        break;
    }

    if(compartment.faded){
      w.endStyle();
    }

  }

}
