import { XRound } from '../../core/XRound';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WList } from '../../elements/tokens/WList';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';

/**
 * Compatibility
 * Too complicated to map generation of visible labels
 * to the new number line model with actual variables.
 */
export class NumberLineLabels extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 4){
      return args.expectingArguments(4, 4);
    }

    if (args.getReal(0) && args.getReal(1) && args.getReal(2) && args.getReal(3)) {
      return this.getLabels(args.getReal(0), args.getReal(1), args.getReal(2), args.getReal(3), args.env);
    }

    return null;
  }

  /* mode:
  ' 1: 0-1
  ' 2: 0-dernier
  ' 3: 0-un autre qui n'est ni le deuxième ni le dernier
  ' 4: 3-4 un sauf le premier et le suivant
  ' 5: 3-5 deux sauf le premier, ne sont pas consécutifs
  */

  /**
   * labels: how labels were initially sets
   * mode: how to generate the visible labels
   * ticks: number of ticks to show
   */
  private getLabels(
      labelsR:RealElement,
      modeR:RealElement,
      majorR:RealElement,
      minorR:RealElement,
      env:Environment):WList{

    if(!labelsR.isWholeNumber()){
      return null;
    }
    if(!modeR.isWholeNumber()){
      return null;
    }
    if(!majorR.isNaturalNumber()){
      return null;
    }
    if(!minorR.isNaturalNumber()){
      return null;
    }

    let labels:number = labelsR.toNumber();
    const mode:number = modeR.toNumber();
    const major:number = majorR.toNumber();
    const minor:number = minorR.toNumber();

    let pos:number;
    if(major >= 4){
      switch(mode){
        case 1:
          labels = 1 + 2;
          break;
        case 2:
          labels = 1 + (2 ** major);
          break;
        case 3:
          labels = 1 + (2 ** env.prng.randomInt(2, major));
          break;
        case 4:
          pos = env.prng.randomInt(1, major);
          labels = (2 ** pos) + (2 ** (pos + 1));
          break;
        case 5:
          pos = env.prng.randomInt(1, major - 1);
          labels = 2 ** pos;
          pos = env.prng.randomInt(pos + 2, major);
          labels += 2 ** pos;
          break;
      }
    }

    let i:number;
    let k:number;

    let c:number = 0;
    let labels2:number = 0;
    for(i = 0 ; i <= major ; i++){
      if(((2 ** i) & labels) > 0){
        c++;
        labels2 |= 2 ** i;
      }
    }

    while(c < 2){
      pos = env.prng.randomInt(0, major + 1);
      if(((2 ** pos) & labels2) === 0){
        labels2 |= 2 ** pos;
        c++;
      }
    }

    let l:number[] = this.baseDigits(labels2, 2);
    l = l.reverse();

    while(l.length < major + 1){
      l.push(0); // Pad right with invisible labels
    }

    if(minor > 1){
      for(i = l.length - 1 ; i >= 1 ; i--){
        for(k = 0 ; k < minor - 1 ; k++){
          l.splice(i, 0, 0); // Insert placeholders for minor ticks
        }
      }
    }

    return env.culture.listFactory.createFromNumbers(l);
  }

  /**
   *
   */
  private baseDigits(
      value:number,
      base:number):number[]{

    if(base < 2){
      return null;
    }

    let e:number = 0;

    const d:number[] = [];

    if(value === 0){
      d.push(0);
    }else{
      while((base ** e) <= value){
        const n:number =
          Math.floor(XRound.safeRound(value / (base ** e)));
        d.unshift(n % base);
        e++;
      }
    }

    return d;
  }

}
