import React from 'react';
import styled from 'styled-components';
import { Breakpoint } from '../surfaces/breakpoint/Breakpoint';
import SearchIcon from '../../assets/search.svg';
import { Button } from './Button';
import { LeftLabeledInput } from './LeftLabeledInput';
import { themedColor } from '../../themes/utils';
import { ColorSet } from '../../themes/ColorSet';
import { Tooltip } from '../utils/Tooltip';

export interface ISearchBoxProps {
  readonly value: string;
  readonly onChange: (value: string) => void;
  readonly onSubmit?: (event: any) => void;
  readonly placeholder: string;
  readonly disabled: boolean;
  readonly searchLabel: React.ReactNode;
  readonly clearLabel?: React.ReactNode;
}

export const SearchBox: React.FC<ISearchBoxProps> = (props: ISearchBoxProps) => {
  const {
    placeholder,
    onChange,
    onSubmit,
    value,
    disabled,
    searchLabel,
    clearLabel = 'Clear',
  } = props;

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => onChange(event.target.value);

  const onKeyUp = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      onSubmit(event);
    }
  };
  return (
    <SearchWrapper>
      <StyledInput
        label=''
        disabled={disabled}
        placeholder={placeholder}
        onChange={changeHandler}
        onKeyUp={onKeyUp}
        value={value}
        clearLabel={clearLabel}
        showClearButton
      />
      <div>
        <Breakpoint gte={800}>
          <StyledButton
            onClick={disabled ? null : onSubmit}
            searchButtonSmall={false}
          >
            <StyledSearchIcon />
            {searchLabel}
          </StyledButton>
        </Breakpoint>
        <Breakpoint lt={800}>
          <Tooltip title={searchLabel}>
            <StyledButton
              onClick={disabled ? null : onSubmit}
              searchButtonSmall={false}
            >
              <StyledSearchIcon />
            </StyledButton>
          </Tooltip>
        </Breakpoint>
      </div>
    </SearchWrapper>
  );
};

const StyledSearchIcon = styled(SearchIcon)`
  fill: ${(props) => props.theme.colorset.grey900};
`;

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
`;

const StyledInput = styled(LeftLabeledInput)`
  height: 40px;
  flex-grow: 1;
  border: none;
  background-color: ${(props) => props.theme.colorset.white};
`;

interface IStyledButton {
  readonly searchButtonSmall: boolean;
}

const StyledButton = styled(Button)`
  align-self: center;
  height: ${(props: IStyledButton) => props.searchButtonSmall ? 32 : 40}px;
  border-color: ${themedColor({ light: ColorSet.white })};

  &:hover {
    border-color: ${themedColor({ light: `${ColorSet.grey500}33` })}; // 33 = 20% opacity
  }
`;
