import styled from 'styled-components';
import { ColorSet } from '../../themes/ColorSet';
import { themedColor } from '../../themes/utils';

const ThemedSeparator = styled.div`
  background-color: ${themedColor({ dark: ColorSet.grey800, light: ColorSet.grey100 })};
`;

export const HorizontalSeparator = styled(ThemedSeparator)`
  width: 100%;
  height: 1px;
`;

export const VerticalSeparator = styled(ThemedSeparator)`
  width: 1px;
  height: 100%;
`;
