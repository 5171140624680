import { MathError } from '../../core/MathError';
import { XRound } from '../../core/XRound';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { TriangleModel } from '../../elements/models/TriangleModel';
import { WList } from '../../elements/tokens/WList';
import { WString } from '../../elements/tokens/WString';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';

/**
 *
 */
export class SolveTriangle extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement {
    if (args.length !== 2) {
      return args.expectingArguments(2, 2);
    }

    if(args.getString(0) && args.getReals(1)){
      return this.solve(args.getString(0), args.getReals(1), args.env);
    }
    return null;
  }

  /**
   *
   */
  private solve(
      knownMeasuresS:WString,
      measures:WList,
      env:Environment):WList{

    const knownMeasures:string = knownMeasuresS.getString();
    const o = {a: 0, b: 0, c: 0, A: 0, B: 0, C: 0};

    for(let i:number = 0 ; i < knownMeasures.length ; i++){
      const c:string = knownMeasures.charAt(i);
      if(!o.hasOwnProperty(c)){
        throw new MathError(`${c} is not one of the valid measure names (abcABC)`);
      }
      if(i < measures.count){
        o[c] = measures.getValueAt(i);
      }else{
        throw new MathError(`Value of ${c} is not defined`);
      }
    }

    const T:TriangleModel = TriangleModel.parse(o.a, o.b, o.c, o.A, o.B, o.C);
    if(!T.validate()){
      throw new MathError('Invalid set of constraints');
    }else if(o.a !==0 && o.a !==T.a){
      throw new MathError('Side a is invalid');
    }else if(o.b !==0 && o.b !==T.b){
      throw new MathError('Side b is invalid');
    }else if(o.c !==0 && o.c !==T.c){
      throw new MathError('Side c is invalid');
    }else if(o.A !==0 && o.A !==T.A){
      throw new MathError('Angle A is invalid');
    }else if(o.B !==0 && o.B !==T.B){
      throw new MathError('Angle B is invalid');
    }else if(o.C !==0 && o.C !==T.C){
      throw new MathError('Angle C is invalid');
    }

    const t:number[] = [];

    t.push(
      XRound.safeRound(T.a),
      XRound.safeRound(T.b),
      XRound.safeRound(T.c),
      XRound.safeRound(T.A),
      XRound.safeRound(T.B),
      XRound.safeRound(T.C));

    return env.culture.listFactory.createFromNumbers(t);
  }

}
