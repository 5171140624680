import {
  AddActivitiesAlignments,
} from '../requests/AlignmentsRequests';
import { updateActivityAlignmentsCommand } from '../frameworks/commands/UpdateActivityAlignmentsCommand';
import { IStandard } from '../models/IStandard';

export const AddActivitiesAlignmentsCommand = async (activityId: number, standards: ReadonlyArray<IStandard>) => {
  await AddActivitiesAlignments(activityId, standards);
  updateActivityAlignmentsCommand(activityId);
}
