import { XMath } from '../core/XMath';
import { ContentElement } from '../elements/abstract/ContentElement';
import { Node } from '../elements/abstract/Node';
import { RadicalFormatter } from '../elements/formats/radicals/RadicalFormatter';
import { WRadical } from '../elements/tokens/WRadical';
import { IWriter } from '../expr/conversion/writers/IWriter';
import { KeyboardConfiguration } from './KeyboardConfiguration';
import { InputCapabilities } from './InputCapabilities';
import { CommonError } from './CommonError';
import { BaseCorrector } from './BaseCorrector';

/**
 *
 */
export class CRadical extends BaseCorrector {

  private rootIndices:number[];

  constructor(rootIndices:number[]){
    super();
    this.rootIndices = rootIndices;
  }

  public parse(value:string):Node{
    const radical:WRadical = this.parseRadical(this.translateInput(value));
    if(radical){
      if(!isNaN(radical.toDecimal())){
        const node:Node = new Node(radical);
        const c:string = String(radical.coefficient.toNumber());
        const b:string = String(radical.base.toNumber());
        const i:string = String(radical.index.toNumber());
        node.userData = `${c}*NthRoot(${b}, ${i})@2048`; // preserveRadicals
        return node;
      }
    }
    return null;
  }

  public correct(
      value:string,
      target:ContentElement,
      ...targets:any[]):boolean{

    // a. Validate presence of radical symbol.
    // b. Validate that there's a base
    // c. Compare decimal values

    const value2:WRadical = this.parseRadical(this.translateInput(value));
    const target2:WRadical = <WRadical>target ;

    if(value2){
      if(isNaN(value2.toDecimal())){
        this.raiseError(CommonError.RADICAL_INVALID);
      }else if(XMath.safeEquals(value2.toDecimal(), target2.toDecimal())){
          return this.options.acceptEquivalent ?
            true :
            target2.equalsTo(value2);
        }
    }else{
      this.raiseError(CommonError.RADICAL_INVALID);
    }

    return false;
  }

  private translateInput(valueArg:string):string{
    if(this.useLatex){
      let value = this.sanitizeInput(valueArg);
      value = value.replace(/\\sqrt\{([^\}]+)\}/, '√$1');
      value = value.replace(/\\sqrt\[2\]\{([^\}]+)\}/, '√$1');
      value = value.replace(/\\sqrt\[3\]\{([^\}]+)\}/, '∛$1');
      value = value.replace(/\\sqrt\[4\]\{([^\}]+)\}/, '∜$1');
      return value;
    }

    return valueArg;
  }

  private parseRadical(
      valueArg:string):WRadical{

    const value = valueArg.split(' ').join('');

    let i:number = value.indexOf('√');
    if(i === -1){
      i = value.indexOf('∛');
    }
    if(i === -1){
      i = value.indexOf('∜');
    }
    if(i === -1){
      const n:number = Number(value);
      if(!isNaN(n)){
        return new WRadical(
          this.env.culture.createNumber(1),
          this.env.culture.createNumber(2),
          this.env.culture.createNumber(n),
          new RadicalFormatter(this.env.culture));
      }
    }

    let coef:number;
    let index:number;

    if(i === 0){
      // pas de coefficient
      coef = 1;
    }else{
      // avec coefficient
      coef = this.numberParser.parseNumber(value.substring(0, i));
    }

    const base = this.numberParser.parseNumber(value.substring(i + 1));
    switch(value.charAt(i)){
      case '√': index = 2; break;
      case '∛': index = 3; break;
      case '∜': index = 4; break;
    }

    if(isNaN(base) || isNaN(coef)){
      return null;
    }

    return new WRadical(
      this.env.culture.createNumber(base),
      this.env.culture.createNumber(index),
      this.env.culture.createNumber(coef),
      new RadicalFormatter(this.env.culture));
  }

  public get inputCapabilities():InputCapabilities{
    const symbols:string =
      this.rootIndices.indexOf(3) !== -1 || this.rootIndices.indexOf(4) !== -1 ?
        '√∛∜' :
        '√';

    const o:InputCapabilities = super.inputWithSymbols(symbols);
    o.radical = true;
    return o;
  }

  public get mathKeyboard():number{return KeyboardConfiguration.RADICAL;}

  public writeTo(
      w:IWriter,
      target:ContentElement,
      ...targets:any[]):void{

    CRadical.writeRadical(w, <WRadical>target , this.useLatex);
  }

  public static writeRadical(
      w:IWriter,
      value:WRadical,
      useLatex:boolean):void{

    switch(value.coefficient.toNumber()){
      case -1:
        w.writeOperator('−', 'prefix');
        break;
      case 1:
        break;
      default:
        w.writeNumber(value.coefficient.toNumber());
        break;
    }

    if(useLatex){
      if(value.index.toNumber() === 2){
        w.beginSqrt();
      }else{
        w.beginRoot();
        w.writeNumber(value.index.toNumber());
        w.endIndex();
      }
    }else{
      switch(value.index.toNumber()){
        case 2: w.writeRaw('√'); break;
        case 3: w.writeRaw('∛'); break;
        case 4: w.writeRaw('∜'); break;
      }
    }

    w.writeNumber(value.base.toNumber());

    if(useLatex){
      if(value.index.toNumber() === 2){
        w.endSqrt();
      }else{
        w.endRoot();
      }
    }
  }

}
