import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { SetElement } from '../../elements/abstract/SetElement';
import { SymbolElement } from '../../elements/abstract/SymbolElement';
import { WComplement } from '../../elements/tokens/WComplement';
import { WInterval } from '../../elements/tokens/WInterval';
import { WNumberSet } from '../../elements/tokens/WNumberSet';
import { WSetBuilder } from '../../elements/tokens/WSetBuilder';
import { WUnion } from '../../elements/tokens/WUnion';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Constructeur d'ensemble.
 */
export class SetBuilder extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 3){
      return args.expectingArguments(3, 3);
    }

    const symbol:SymbolElement = args.getSymbol(0);
    if(!symbol){
      return null;
    }

    const numbers:WNumberSet = args.getNumbersSet(1);
    if(!numbers){
      return null;
    }

    const condition:SetElement = args.getSet(2);
    if(!condition){
      return null;
    }

    if(condition instanceof WInterval){
      return new WSetBuilder(symbol, numbers, condition);
    }

    if(condition instanceof WUnion){
      const complement:WComplement = (<WUnion>condition ).toComplement();

      if(		complement.B instanceof WNumberSet &&
          (<WNumberSet>complement.B ).toSymbol() === 'ℝ' &&
          complement.A instanceof WInterval &&
          (<WInterval>complement.A ).isFinite){

        return new WSetBuilder(symbol, numbers, complement);
      }
    }

    return null;
  }

}
