import { HistoryEventEntityType, IHistoryEventEntity } from './history';

/**
 * Partial book information, only useful to identify a book and
 * display it as a label using it's name and icon. The full book
 * information must be fetched using other APIs.
 */
export interface IBookRef {
  readonly id: number;
  readonly title: string;
  readonly icon: string;
  readonly subjectId: number
}

export const bookRefEntity = (bookRef: IBookRef): IHistoryEventEntity => {
  return {
    entityType: HistoryEventEntityType.Book,
    entityId: bookRef.id,
    entityDescription: bookRef.title,
  };
};
