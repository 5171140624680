import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { ListElement } from '../../elements/abstract/ListElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { WString } from '../../elements/tokens/WString';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';
import { WList } from '../../elements/tokens/WList';

export class Replace extends FunctionElement {
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 3) {
      return args.expectingArguments(3, 3);
    }

    const string = [args.getString(0), args.getString(1), args.getString(2)];
    if (string[0] && string[1] && string[2]) {
      return this.string(string[0], string[1], string[2]);
    }

    const matrix = [args.getMatrix(0), args.getReals(1), args.getReals(2)] as const;
    if (matrix[0] && matrix[1] && matrix[2]) {
      return this.matrix(matrix[0], matrix[1], matrix[2]);
    }

    const list = [args.getList(0), args.getList(1), args.getList(2)];
    if (list[0] && list[1] && list[2]) {
      return this.list(list[0], list[1], list[2], args.env);
    }

    return null;
  }

  private string(string: WString, search: WString, replacement: WString): WString {
    const value = string.getString().split(search.getString()).join(replacement.getString());
    return new WString(value, null, string.combineSources(replacement));
  }

  private matrix(matrix: WMatrix, search: WList, replacement: WList): WMatrix {
    if (matrix.count === 0 || search.count === 0 || replacement.count === 0) {
      return matrix;
    }

    const elements: RealElement[] = [];
    matrix: for (let i = 0; i < matrix.count; i++) {
      const element = matrix.values[i];
      for (let k = 0; k < search.count; k++) {
        if (element.toNumber() === search.getValueAt(k)) {
          elements.push(replacement.getTypedItemAt(k < replacement.count ? k : replacement.count - 1));
          continue matrix;
        }
      }
      elements.push(element);
    }

    return new WMatrix(elements, matrix.columns, matrix.formatter);
  }

  private list(list: ListElement, search: ListElement, replacement: ListElement, env: Environment): ContentElement {
    if (list.count === 0 || search.count === 0 || replacement.count === 0) {
      return list;
    }

    if (list.getListItemCode() !== search.getListItemCode() || search.getListItemCode() !== replacement.getListItemCode()) {
      return list;
    }

    const items: ContentElement[] = [];
    list: for (let i = 0; i < list.count; i++) {
      const item = list.getItemAt(i);
      for (let k = 0; k < search.count; k++) {
        if (item.equalsTo(search.getItemAt(k))) {
          items.push(replacement.getItemAt(k < replacement.count ? k : replacement.count - 1));
          continue list;
        }
      }
      items.push(item);
    }

    return env.culture.listFactory.createList(items).applyFormat(list.formatter);
  }
}
