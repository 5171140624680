import { ContentElement } from '../../elements/abstract/ContentElement';
import { ElementCodes } from '../../elements/abstract/ElementCodes';
import { ListElement } from '../../elements/abstract/ListElement';
import { OperatorElement } from '../../elements/abstract/OperatorElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { TokenElement } from '../../elements/abstract/TokenElement';
import { WFiniteSet } from '../../elements/tokens/WFiniteSet';
import { WPoint } from '../../elements/tokens/WPoint';
import { WRatio } from '../../elements/tokens/WRatio';
import { WRational } from '../../elements/tokens/WRational';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Extrait l'élément d'une liste à l'index spécifié.
 * L'index 0 correspond au premier élément de la liste.
 */
export class ItemAt extends OperatorElement {
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 2) {
      return args.expectingArguments(2, 2);
    }

    const index = args.getWholeNumber(1)?.toNumber() ?? null;
    if (index === null) {
      return null;
    }

    const element = args.getContentElement(0);

    if (element instanceof ListElement) {
      // Check list first (without auto-convert rules)
      return this.list(element, index);
    }

    const point = args.getPoint(0);
    if (point) {
      return this.point(point, index);
    }

    const rational = args.getRational(0);
    if (rational) {
      return args.env.culture.createNumber(this.rational(rational, index));
    }

    const ratio = args.getRatio(0);
    if (ratio) {
      return args.env.culture.createNumber(this.ratio(ratio, index));
    }

    const list = args.getList(0);
    if (list) {
      return this.list(list, index);
    }

    const finiteSet = args.getFiniteSet(0);
    if (finiteSet) {
      return this.finiteSet(finiteSet, index);
    }

    if (element.getListItemCode() != null && index === 0) {
      return element;
    }

    return null;
  }

  /**
   * Extract the element of a list at the specified index.
   */
  private list(value: ListElement, index: number): ContentElement {
    if (index >= value.count) {
      return null;
    }
    this.wasCondensedWork = true;
    return value.getItemAt(index);
  }

  /**
   * Extract the x or y value of a point.
   *
   * p_0 --> x
   * p_1 --> y
   */
  private point(value: WPoint, index: number): RealElement {
    if (index > 1) {
      return null;
    }
    this.wasCondensedWork = true;
    if (index === 0) {
      return value.x;
    }
    if (index === 1) {
      return value.y;
    }
    return null;
  }

  /**
   * Extract the raw numerator or raw denominator
   * of the specified rational number.
   *
   * r_0 --> numerator
   * r_1 --> denominator
   */
  private rational(value: WRational, index: number): number {
    if (index > 1) {
      return NaN;
    }
    this.wasCondensedWork = true;
    if (index === 0) {
      return value.numerator;
    }
    if (index === 1) {
      return value.denominator;
    }
    return NaN;
  }

  /**
   * Extract the left or right part of a ratio.
   *
   * r_0 --> left
   * r_1 --> right
   */
  private ratio(value: WRatio, index: number): number {
    if (index > 1) {
      return NaN;
    }
    this.wasCondensedWork = true;
    if (index === 0) {
      return value.r;
    }
    if (index === 1) {
      return value.s;
    }
    return NaN;
  }

  private finiteSet(value: WFiniteSet, index: number): TokenElement {
    if (index >= value.cardinal) {
      return null;
    }
    this.wasCondensedWork = true;
    return value.getElementAt(index);
  }

  public getElementCode(): string {
    return ElementCodes.OP_ITEM;
  }
}
