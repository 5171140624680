import {
  Editor,
  Element,
  Node,
  Path,
  Transforms,
} from '../TypedSlate';
import {
  ICustomElement,
  IListElement,
  IParagraphElement,
  ICustomEditor
} from '../models';

const INDENTABLE_ELEMENTS_TYPES: ReadonlyArray<ICustomElement['type']> = [
  'paragraph',
  'list',
];

/**
 * Apply indent level on paragraphs.
 */
const changeIndent = (editor: ICustomEditor, change: (indent?: number) => number): void => {
  const isUnindenting = change(1) < 1;

  const indentableElements = Editor.nodes<(IParagraphElement | IListElement)>(editor, {
    mode: isUnindenting ? 'all' : 'highest',
    match: (n) => {
      return !Editor.isEditor(n) && Element.isElement(n) && INDENTABLE_ELEMENTS_TYPES.includes(n.type);
    },
  });

  for (const indentableElement of indentableElements) {
    const indent = indentableElement[0].indent ?? 0;
    const newIndent = change(indent);
    if (newIndent === 0) {
      Transforms.unsetNodes(
        editor,
        'indent',
        {
          mode: 'all',
          match: (node: Node, path: Path) => {
            return node === indentableElement[0];
          },
        });
    } else {
      const newNodeValue: Partial<(IParagraphElement | IListElement)> = {
        indent: newIndent,
      };
      Transforms.setNodes<IParagraphElement | IListElement>(
        editor,
        newNodeValue,
        {
          mode: 'all',
          match: (node: Node, path: Path) => {
            return node === indentableElement[0];
          },
        });
    }

  }
};

export const indentMore = (editor: ICustomEditor) => {
  changeIndent(editor, indent => indent + 1);
};

export const indentLess = (editor: ICustomEditor) => {
  changeIndent(editor, indent => Math.max(indent - 1, 0));
};
