import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WSequence } from '../../elements/tokens/WSequence';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class Sequence extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 2){
      return args.expectingArguments(2, 2);
    }

    if(		WSequence.isValidElement(args.getTokenElement(0)) &&
        WSequence.isValidElement(args.getTokenElement(1))){
      return new WSequence(args.getTokenElement(0), args.getTokenElement(1));
    }

    return null;
  }

}
