/**
 * Encapsulate the logic required to prepend variants to a content locale string.
 * If some variants are already defined, then the new variants are added before the existing ones.
 *
 * @param contentLocale
 * @param variants
 * @private
 */
export const prependVariantsToContentLocale = (contentLocale: string, variants: ReadonlyArray<string>): string => {
    return insertVariants(contentLocale, variants, 'before');
};

export const appendVariantsToContentLocale = (contentLocale: string, variants: ReadonlyArray<string>): string => {
    return insertVariants(contentLocale, variants, 'after');
};

export const insertVariants = (contentLocale: string, variants: ReadonlyArray<string>, insertPosition: 'before' | 'after'): string => {
    if (!variants || variants.length === 0) {
        return contentLocale;
    }
    if (!contentLocale) {
        return `,,${variants.join('|')}`;
    }
    const o = contentLocale.split(',');
    if (o.length === 1) {
        return o.concat('', variants.join('|')).join(',');
    }
    if (o.length === 2) {
        return o.concat(variants.join('|')).join(',');
    }
    return insertPosition === 'before'
        ?  `${o[0]},${o[1]},${variants.join('|')}|${o[2]}`
        : `${o[0]},${o[1]},${o[2]}|${variants.join('|')}`;
};

export const clearVariantsInContentLocale = (contentLocale: string) => {
    if (!contentLocale) {
        return contentLocale;
    }
    const o = contentLocale.split(',');
    if (o.length < 3) {
        return contentLocale;
    }
    return `${o[0]},${o[1]}`;
};

export const extractVariantsFromContentLocale = (contentLocale: string): ReadonlyArray<string> => {
    if (!contentLocale) {
        return [];
    }
    const o = contentLocale.split(',');
    if (o.length < 3) {
        return [];
    }
    return o[2].split('|');
};
