import { Point } from '../../js/geom/Point';

import { MultiplicationModel } from './models/MultiplicationModel';
import { MultAddCarry } from './multiplication/MultAddCarry';
import { MultAddCarryDown } from './multiplication/MultAddCarryDown';
import { MultAddColumnSum } from './multiplication/MultAddColumnSum';
import { MultAddInit } from './multiplication/MultAddInit';
import { MultCarry } from './multiplication/MultCarry';
import { MultCarryDown } from './multiplication/MultCarryDown';
import { MultEnd } from './multiplication/MultEnd';
import { MultEndDecSep } from './multiplication/MultEndDecSep';
import { MultInit } from './multiplication/MultInit';
import { MultMultiply } from './multiplication/MultMultiply';
import { MultZeroPlaceholders } from './multiplication/MultZeroPlaceholders';
import { CultureInfo } from '../localization/CultureInfo';
import { AbstractStep } from './AbstractStep';
import { HLine } from './HLine';
import { Compartment } from './Compartment';
import { ElementaryOperation } from './ElementaryOperation';

/* [ResourceBundle("LongMultiplicationOperation")]*/

/**
 *
 */
export class LongMultiplicationOperation extends ElementaryOperation {

  public model:MultiplicationModel;

  constructor(
      model:MultiplicationModel,
      culture:CultureInfo){
    super(culture);
    this.model = model;
    super.init();
  }

  public static createOperation(
      operand1:number,
      operand2:number,
      culture:CultureInfo):LongMultiplicationOperation{

    return new LongMultiplicationOperation(
      new MultiplicationModel(
        operand1,
        operand2),
      culture);
  }

  // State variables
  public rawMult:Compartment[] = [];

  public multCarries:any[] = [];

  public operand1:Compartment[] = [];

  public multSign:Compartment;

  public multLine:HLine;

  public operand2:Compartment[] = [];

  public sumCarries:Compartment[] = [];

  public multSteps:any[] = [];

  public addSign:Compartment;

  public addLine:HLine;

  public sum:Compartment[] = [];

  public result:Compartment[] = [];

  // Execution variables
  public multCursor:Point = new Point(0, 0); // {x: position in the first operand, position in the second operand}

  public multCarry:number = 0;

  public multCarried:number = 0;

  public multZeroPlaceholders:number = 0;

  public addPosition:number = 0;

  public addColumns:number = 0;

  public addCarry:number = 0;

  public addCarried:number = 0;

  public nextMult():void{
    const p:Point = this.multCursor.clone();
    p.x += 1;
    if(p.x >= this.model.digits1.length){
      p.y += 1;
      p.x = 0;
    }
    this.multCursor = p.y >= this.model.digits2.length ? null : p;
  }

  public get lastMultStep():Compartment[]{
    if(this.multSteps.length === 0){
      return null;
    }
    return this.multSteps[this.multSteps.length - 1];
  }

  public get lastMultCarries():Compartment[]{
    if(this.multCarries.length === 0){
      return null;
    }
    return this.multCarries[this.multCarries.length - 1];
  }

  protected next():AbstractStep{
    if(this.lastOperation == null){
      return new MultInit(this);
    }
    if(this.lastOperation instanceof MultEnd){
      return null;
    }
    if(this.multCarry !== 0){
      return new MultCarry(this);
    }

    if(this.multCursor){
      if(this.multCarried !== 0 && this.multCursor.x === 0){
        return new MultCarryDown(this);
      }
      if (this.multZeroPlaceholders < this.multCursor.y && this.multCursor.x === 0){
        return new MultZeroPlaceholders(this);
      }
      return new MultMultiply(this);

    }
    if(this.multCarried !== 0){
      return new MultCarryDown(this);
    }

    if(this.multSteps.length > 1){
      if(this.addSign == null){
        return new MultAddInit(this);
      }
      if(this.addCarry !== 0){
        return new MultAddCarry(this);
      }
      if(this.addPosition < this.addColumns){
        return new MultAddColumnSum(this);
      }
      if(this.addCarried !== 0){
        return new MultAddCarryDown(this);
      }
    }

    if(this.model.requireNormalize && !(this.lastOperation instanceof MultEndDecSep)){
      return new MultEndDecSep(this);
    }

    return new MultEnd(this);
  }

  protected finalize():void{
    let voffset:number = 0;
    let rightcol:number = 0; // right column

    // Determine right column
    let i:number;
    for(i = 0 ; i < this.multSteps.length ; i++){
      rightcol = Math.max(rightcol, this.multSteps[i].length);
    }
    for(i = 0 ; i < this.multCarries.length ; i++){
      rightcol = Math.max(rightcol, this.multCarries[i].length);
    }
    rightcol = Math.max(rightcol, this.sumCarries.length);
    rightcol = Math.max(rightcol, this.sum.length);
    rightcol += 1; // leave space for the multiplication sign

    // Place raw multiplication
    if(this.layoutRow(this.rawMult, voffset, 0) > 0){
      voffset += 1; // raw mult row
      voffset++; // empty line
    }

    // Place mult carries
    for(let l:number = this.multCarries.length - 1 ; l >= 0 ; l--){
      voffset += this.layoutRow(this.multCarries[l], voffset, rightcol - this.multCarries[l].length);
    }

    // Place operand 1
    voffset += this.layoutRow(this.operand1, voffset, rightcol - this.operand1.length);

    // Place operand 2 with multiplication sign on the left
    this.multSign.column = 0;
    this.multSign.row = voffset;
    this.multLine.row = voffset;

    voffset += this.layoutRow(this.operand2, voffset, rightcol - this.operand2.length);

    // Place sum carries
    voffset += this.layoutRow(this.sumCarries, voffset, rightcol - this.sumCarries.length);

    // Place multiplication steps
    for(i = 0 ; i < this.multSteps.length ; i++){
      const multStep:Compartment[] = this.multSteps[i];
      voffset += this.layoutRow(multStep, voffset, rightcol - multStep.length);
    }

    // Place the addition sign on the last multiplication row + draw the line
    if(this.addSign){
      this.addSign.row = voffset - 1;
      this.addSign.column = 0;
      this.addLine.row = voffset - 1;
    }

    if(this.hasCompartments(this.sum)){
      voffset += this.layoutRow(this.sum, voffset, rightcol - this.sum.length);
    }

    if(this.hasCompartments(this.result)){
      voffset++;
      voffset += this.layoutRow(this.result, voffset, 1);
    }
  }

  /**
   * Actual product
   */
  public get product():number{
    return Number(this.productStr);
  }

  private get productStr():string{
    const o:any[] = [];
    for(let i:number = 0 ; i < this.resultRow.length ; i++){
      const c:Compartment = this.resultRow[i];
      if(c){
        o.push(c.text);
      }
    }
    let s:string = o.join('');
    s = s.split(this.culture.numberFormatter.decimalSeparator).join('.');
    s = s.split('−').join('-');
    return s;
  }

  public get sumRow():Compartment[]{
    if(this.hasCompartments(this.sum)){
      return this.sum;
    }
    if(this.multSteps.length === 1){
      if(this.hasCompartments(this.multSteps[0])){
        return this.multSteps[0];
      }
    }
    return null;
  }

  public get resultRow():Compartment[]{
    if(this.hasCompartments(this.result)){
      return this.result;
    }
    if(this.hasCompartments(this.sum)){
      return this.sum;
    }
    if(this.multSteps.length === 1){
      if(this.hasCompartments(this.multSteps[0])){
        return this.multSteps[0];
      }
    }
    return null;
  }

  protected validate():void{
    this.error = this.product !== this.model.product;
  }

}
