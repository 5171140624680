import { XString } from '../../../core/XString';
import { BaseNumberFormatter } from '../../../elements/formats/BaseNumberFormatter';
import { CultureInfo } from '../../../localization/CultureInfo';

/**
 *
 */
export class StringFormatter extends BaseNumberFormatter {

  private format:string;

  /**
   *
   */
  constructor(culture:CultureInfo, format:string){
    super(culture);
    this.format = format;
  }

  /**
   *
   */
  public toLocaleString(value:number):string{
    return XString.substitute(
        this.format,
        this.culture.formatNumber(value));
  }

}
