import { runInAction } from 'mobx';
import { UserSessionStore } from '../models/store/UserSessionStore';
import { instantiate } from '../dependencyInjection/instantiate';
import { PublisherApiAuth } from '../services/PublisherApiAuth';

export const authenticationRequiredCommand = () => {
  runInAction(() => {
    const userSessionStore = instantiate(UserSessionStore);
    userSessionStore.setAuthenticationFlag(false);
    PublisherApiAuth.clearToken();
  });
};
