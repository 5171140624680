import React from 'react';
import { useVirtual } from 'react-virtual';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { ColorSet, themedColor } from '@scolab/publisher-ui-kit';
import { VariantListItem } from './VariantListItem';
import { IVariant } from '../../../models/IVariant';

interface IVariantListProps {
  variantList: ReadonlyArray<IVariant>;
  changeDescription: (variant: IVariant, newDescription: string) => void;
  toggleObsolete: (variant: IVariant) => void;
}

export const VARIANT_LIST_TEST_ID = 'VARIANT_LIST_TEST_ID';

export const VariantList: React.FC<IVariantListProps> = (props) => {
  const {
    variantList,
    changeDescription,
    toggleObsolete,
  } = props;
  const parentRef = React.useRef<HTMLDivElement>(null);
  const rowVirtualizer = useVirtual({
    parentRef,
    size: variantList.length,
  });
  const rowRenderer = (index: number) => {
    const variant = variantList[index];
    return (
      <VariantListItem
        key={variant.name}
        variant={variant}
        onChangeDescription={changeDescription}
        onToggleObsolete={toggleObsolete}
      />
    );
  };
  return (
    <Container>
      <HeaderRow>
        <NameLabel>
          <FormattedMessage id="variants.name" />
        </NameLabel>
        <FormattedMessage id="variants.description" />
      </HeaderRow>
      <ListItemsContainer
        ref={parentRef}
      >
        <div
          style={{
            height: rowVirtualizer.totalSize,
            width: '100%',
            position: 'relative',
          }}
          data-testid={VARIANT_LIST_TEST_ID}
        >
          {rowVirtualizer.virtualItems.map((virtualRow) => (
            <div
              key={virtualRow.index}
              ref={virtualRow.measureRef}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                transform: `translateY(${virtualRow.start}px)`,
              }}
            >
              {rowRenderer(virtualRow.index)}
            </div>
          ))}
        </div>
      </ListItemsContainer>
    </Container>
  );
};

const Container = styled.div`
  flex: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const HeaderRow = styled.div`
  display: flex;
  gap: 16px;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: ${themedColor({ dark: ColorSet.grey100, light: ColorSet.grey900 })};
`;

const NameLabel = styled.span`
  padding-left: 8px;
  width: 208px;
  box-sizing: border-box;
`;

const ListItemsContainer = styled.div`
  flex: 1;
  min-height: 0;
  overflow: overlay;
  margin-right: -16px;
  padding-right: 16px;
`;
