import { IDictionary } from '../../js/utils/IDictionary';

import { Node } from '../elements/abstract/Node';
import { WVariable } from '../elements/tokens/WVariable';
import { SymbolsFactory } from '../expr/SymbolsFactory';
import { Environment } from '../expr/Environment';
import { IVariablesResolver } from '../expr/IVariablesResolver';

/**
 *
 */
export class Variables implements IVariablesResolver {

  private definitions:IDictionary;

  private env:Environment;

  private factory:SymbolsFactory;

  /**
   *
   */
  constructor(definitions:IDictionary, env:Environment) {

    this.definitions = definitions;
    this.env = env;
    this.factory = new SymbolsFactory(env.culture.numberFormatter);
  }

  public isDefined(name:string):boolean{
    return this.definitions.hasOwnProperty(name);
  }

  public getVariable(name:string):WVariable{
    return this.factory.create(name);
  }

  public getSubstitution(name:string):Node{
    return new Node(this.definitions[name]);
  }

  public getEnvironment():Environment{
    return this.env;
  }

}
