import { NodeGroup } from '../../../elements/functions/tree/NodeGroup';
import { EmptyLeaf } from '../../../elements/functions/tree/EmptyLeaf';
import { AbstractNode } from '../../../elements/functions/tree/AbstractNode';

/**
 *
 */
export class NodeCoefficient extends AbstractNode {

  private _param:AbstractNode;

  private _node:AbstractNode;

  constructor(param:AbstractNode, node:AbstractNode ) {
    super();
    this._param = param;
    this._node = node;
    this._node.setParent( this );
  }

  public getToken():any[] {
    let value:any[] = this._param.getToken();

    if( this._param.isNumber() ) {
      const coefficient:number = this._param.toNumber();

      if( coefficient === -1 ) {
        value = ['-'];
      }
    }

    return value.concat( this._node.getToken() );
  }

  public simplify():AbstractNode {
    let nodeSimplified:AbstractNode = this._node.simplify();
    const paramSimplified:AbstractNode = this._param.simplify();

    if( nodeSimplified instanceof EmptyLeaf || paramSimplified instanceof EmptyLeaf ) {
      return nodeSimplified;
    }

    if( paramSimplified.isNumber() ) {
      const numeric:number = paramSimplified.toNumber();

      if( numeric === 0 ) {
        return new EmptyLeaf();
      }

      if( numeric === 1 ) {

        if( nodeSimplified instanceof NodeGroup ) {
          nodeSimplified = ( <NodeGroup>nodeSimplified  ).content;
        }

        return nodeSimplified;
      }
    }

    return new NodeCoefficient( paramSimplified, nodeSimplified );
  }

}
