import { IContextModel } from '@scolab/content-model';
import { InlineContext } from '../../../expr/InlineContext';

export class DoublePyramidProperties extends InlineContext {

  /**
   *
   */
  constructor(){
    super('DoublePyramidProperties', (<string[]>['s']), null);
    // s: number of sides
  }

  /**
   *
   */
  public getContextModel():IContextModel{
    const o:IContextModel = {
      variables: [{
        value: '"double pyramid"',
        id: 'nameEn'
      }, {
        value: '"double pyramide"',
        id: 'nameFr'
      }, {
        value: 'if(isfr(), nameFr, nameEn)',
        id: 'name'
      }, {
        value: '2s',
        id: 'faces'
      }, {
        value: 's+2',
        id: 'vertices'
      }, {
        value: '3s',
        id: 'edges'
      }, {
        value: '2s',
        id: 'triangles'
      }, {
        value: '0',
        id: 'squares'
      }, {
        value: '0',
        id: 'rectangles'
      }, {
        value: '0',
        id: 'polygons'
      }
      ]
    };

    return o;
  }

}
