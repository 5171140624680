import React from 'react';
import styled from 'styled-components';
import {
  LabelNoSelection,
  LabelWithSelection,
  SelectedLabel
} from './Labels.styles';

export interface ITopLabeledSelectedItemProps {
  label: React.ReactNode;
  selectedLabel: React.ReactNode;
  disabled?: boolean;
  className?: string;
}

export const TopLabeledSelectedItem: React.FC<ITopLabeledSelectedItemProps> = (props) => {
  const {
    label,
    selectedLabel,
    disabled,
    className,
  } = props;

  const hasSelectedLabel = Boolean(selectedLabel);
  return (
    <Wrapper
      className={className}
    >
      {hasSelectedLabel ?
        <LabelWithSelection>
          {label}
        </LabelWithSelection> :
        <LabelNoSelection>
          {label}
        </LabelNoSelection>
      }
      {hasSelectedLabel ?
        <SelectedLabel
          disabled={disabled}
        >
          {selectedLabel}
        </SelectedLabel> :
        null
      }
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 2px 0 2px 8px;
  display: flex;
  align-items: start;
  flex-direction: column;
  min-width: 0;
`;
