import React from 'react';
import styled, { ThemeProps } from 'styled-components';
import {
  ITheme,
  setHexColorTransparency,
  TagInstance,
  ThemeName,
} from '@scolab/publisher-ui-kit';
import { FormattedMessage } from 'react-intl';
import { TSubjectName } from '../../models/ISubject';
import { LocaleProvider } from '../LocaleProvider';
import { locales } from '../../autoGenerate/locale';


export interface ISubjectTag {
  subjectName: TSubjectName;
}

export const SubjectTag: React.FC<ISubjectTag> = (props) => {
  const {
    subjectName,
    children,
  } = props;
  if (!subjectName) return null;
  return (
    <LocaleProvider locales={locales}>
      <StyledTagInstance
        subjectName={subjectName}
      >
        <FormattedMessage id={`subject.${subjectName}`} />
        {children}
      </StyledTagInstance>
    </LocaleProvider>
  );
};

interface IStyledTagInstance {
  subjectName: TSubjectName;
}

const getSubjectColor = (props: ThemeProps<ITheme> & IStyledTagInstance) => {
  const opacity = props.theme.name === ThemeName.light ? 0.2 : 1;
  switch (props.subjectName) {
    case 'mathematics': return setHexColorTransparency(props.theme.colorset.mathematics, opacity);
    case 'french': return setHexColorTransparency(props.theme.colorset.french, opacity);
    default: return null;
  }
};

const StyledTagInstance = styled(TagInstance)<IStyledTagInstance>`
  color: ${(props) => props.theme.colorset.grey12};
  background-color: ${getSubjectColor};
  border-color: ${getSubjectColor};
  &:hover {
    background-color: ${getSubjectColor};
    border-color: ${getSubjectColor};
  }
`;
