import { XMath } from '../../core/XMath';
import { XNumber } from '../../core/XNumber';
import { XRound } from '../../core/XRound';
import { MmlWriter } from '../../core/mml/MmlWriter';
import { RealElement } from '../../elements/abstract/RealElement';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';

/**
 *
 */
export class WScientificNumber extends RealElement {

  private _mantissa:number;

  public get mantissa():number{return this._mantissa;}

  private _exponent:number;

  public get exponent():number{return this._exponent;}

  private _sigfigs:number;

  public get sigfigs():number{return this._sigfigs;}

  constructor(
      mantissa:number,
      exponent:number,
      sigfigs:number){
    super();
    this._mantissa = mantissa;
    this._exponent = exponent;
    this._sigfigs = sigfigs;
  }

  public toNumber():number{
    return XMath.safeTimes(this.mantissa, (10 ** this.exponent));
  }

  public static parse(
      value:number):WScientificNumber{

    let n:number = Number(value);
    if(isNaN(n)){
      return null;
    }
    if(n === 0){
      return null;
    }

    let e:number = 0;
    const negative:boolean = n < 0;
    n = Math.abs(n);

    while(n < 1){
      n *= 10;
      e--;
    }

    while(n >= 10){
      n /= 10;
      e++;
    }

    n = XRound.safeRound(n);
    if(negative){
      n = -n;
    }

    return new WScientificNumber(n, e, -1);
  }

  private trailingZeros(decimalSeparator:string):string{
    let s:string = '';
    if(this.sigfigs !== -1){
      const c:number =
        (Math.round(this.mantissa) === 0 ?
          0 :
          Math.round(Math.abs(this.mantissa)).toString().length) +
        XNumber.decimals(this.mantissa);

      if(XMath.isInteger(this.mantissa)){
        s += decimalSeparator;
      }

      for(let i:number = 0 ; i < (this.sigfigs - c) ; i++){
        s += '0';
      }
    }

    return s;
  }

  public toText(strict:boolean):string{
    return null;
  }

  public writeTo(exporter:IMarkupExporter = null):boolean{
    if(exporter){
      const w:MmlWriter = exporter.writer;
      w.beginRow();
      w.appendNumber(exporter.culture.formatNumber(this.mantissa) + this.trailingZeros(exporter.culture.numberFormatter.decimalSeparator));
      w.appendOperator('×');
      w.beginSup();
      w.appendNumber('10');
      w.appendNumber(exporter.culture.formatNumber(this.exponent));
      w.endSup();
      w.endRow();
    }
    return true;
  }

  public toAbsoluteValue():RealElement {
    return new WScientificNumber(Math.abs(this.mantissa), this.exponent, this.sigfigs);
  }

  public toOpposite():RealElement {
    return new WScientificNumber(-this.mantissa, this.exponent, this.sigfigs);
  }

  public getType():string {
    return 'scientificNumber';
  }

}
