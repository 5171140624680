import { MathError } from '../../core/MathError';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WString } from '../../elements/tokens/WString';
import { WTable } from '../../elements/tokens/WTable';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class Frame extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 2){
      return args.expectingArguments(2, 2);
    }

    if (args.getTable(0) && args.getString(1)) {
      return this.setattr(args.getTable(0), args.getString(1));
    }
    return null;
  }

  /**
   *
   */
  private setattr(
      table:WTable,
      valueS:WString):WTable{

    const value:string = valueS.getString();

    if(value !== 'none' && value !== 'solid' && value !== 'dashed'){
      throw new MathError('Valid frame values are none, solid and dashed');
    }

    return new WTable(
      table.columns,
      table.header,
      table.vheader,
      table.footer,
      table.vfooter,
      value,
      table.rowlines,
      table.columnlines,
      table.equalrows,
      table.equalcolumns);
  }

}
