import { MathError } from '../../core/MathError';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WFormula } from '../../elements/tokens/WFormula';
import { WString } from '../../elements/tokens/WString';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { InlineContext } from '../../expr/InlineContext';
import { DoublePyramidProperties } from '../../funcs/formulas/impl/DoublePyramidProperties';
import { PolygonsList } from '../../funcs/formulas/impl/PolygonsList';
import { PrismProperties } from '../../funcs/formulas/impl/PrismProperties';
import { PyramidProperties } from '../../funcs/formulas/impl/PyramidProperties';
import { RandomEasyLine } from '../../funcs/formulas/impl/RandomEasyLine';
import { RandomHardLine } from '../../funcs/formulas/impl/RandomHardLine';
import { RandomIntegerLine } from '../../funcs/formulas/impl/RandomIntegerLine';

/**
 *
 */
export class Formula extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 1){
      return args.expectingArguments(1, 1);
    }

    if(args.getString(0)){
      return new WFormula(
        this.getFormula(args.getString(0)),
        args.prng.randomSeed());
    }

    return null;
  }

  /**
   *
   */
  private getFormula(idS:WString):InlineContext{
    const id:string = idS.getString().toLowerCase();
    switch(id){
      case 'doublepyramidproperties':
        return new DoublePyramidProperties();
      case 'polygonslist':
        return new PolygonsList();
      case 'prismproperties':
        return new PrismProperties();
      case 'pyramidproperties':
        return new PyramidProperties();
      case 'randomeasyline':
        return new RandomEasyLine();
      case 'randomhardline':
        return new RandomHardLine();
      case 'randomintegerline':
        return new RandomIntegerLine();
      default:
        throw new MathError('MissingFormula');
    }
  }

}
