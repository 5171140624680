import { IThemeColorset, ITheme, ThemeName } from './ITheme';
import { ColorSet } from './ColorSet';
import { ThemeDefault } from './ThemeDefault';
import { darkTooltipTheme } from '../components/utils/Tooltip';
import { darkButtonTheme, darkButtonTheme2 } from '../components/inputs/Button';
import { darkSwitchButtonTheme, darkSwitchButtonTheme2 } from '../components/inputs/SwitchButton';
import { darkSectionHeaderTheme } from '../components/layout/SectionHeader';
import { darkCheckboxTheme, darkCheckboxTheme2 } from '../components/inputs/Checkbox';
import { darkInputTheme, darkInputTheme2 } from '../components/inputs/Input';
import { darkTreeviewTheme } from '../components/navigation/Treeview';
import { darkRadioTheme, darkRadioTheme2 } from '../components/inputs/Radio';
import { darkTagInstanceTheme } from '../components/tagging/theme';
import { darkDropdownTheme, darkDropdownTheme2 } from '../components/utils/Dropdown/theme';
import { darkTreeTheme } from '../components/navigation/Tree/theme';
import { darkCardTheme } from '../components/surfaces/card/theme';
import { darkSelectItemTheme } from '../components/inputs/Select/SelectItem.theme';
import { darkContextMenu } from '../components/utils/ContextMenu/theme';
import { darkModalTheme } from '../components/utils/Modal/theme';
import { darkPopoverTheme } from '../components/utils/Popups/theme';
import { darkTabViewTheme } from '../components/navigation/tabView/TabView.theme';

export class ThemeDark extends ThemeDefault {
  public readonly name = ThemeName.dark;

  public readonly nestingLevel: number = 1;

  protected colorset: IThemeColorset = {
    // New pallet according to the design system
    white: '#FFFFFF',
    black: '#000000',
    grey025: '#F2F2F5',
    grey050: '#E9ECF2',
    grey100: '#CED5DF',
    grey400: '#818C99',
    grey500: '#6B747D',
    grey700: '#51575E',
    grey800: '#42474D',
    grey900: '#303438',
    penumbra: '#1C1F21',
    darkness: '#111213',

    primary050: '#EAF6FF',
    primary075: '#D7EDFF',
    primary500: '#0086EC',
    primary700: '#0068B8',
    alert400: '#FF4E5F',
    alert500: '#DA4453',
    warning: '#FF7700',
    brand: '#72D200',

    french: ColorSet.frenchDark,
    mathematics: ColorSet.mathematicsDark,

    grey01: '#000000',
    grey02: '#1C1F21',
    grey03: '#282C30',
    grey04: '#303438',
    grey05: '#42474D',
    grey06: '#51575E',
    grey07: '#6B747D',
    grey08: '#818C99',
    grey09: '#98A1AF',
    grey10: '#CED5DF',
    grey11: '#E9ECF2',
    grey12: '#F2F2F5',
    grey13: '#FFFFFF',
    primary: '#0086EC',
    primaryDark: '#0068B8',
    secondary: '#ff8aff',
    secondaryDark: '#E9157F',
    alt1: '#00fffe',
    alt1Dark: '#00ffb2',
    alt2: '#e4ff00',
    alt3: '#fcaeff',
    alt4: '#ff88a6',
    alt5: '#ffee00',
    shadow: 'rgba(255, 255, 255, 0.15)',
    shadowAlt: 'rgba(0, 0, 0, 0.15)',
    error: '#FF2525',
    error2: '#FF4E5F',
  };

  public get theme(): ITheme {
    return {
      ...super.theme,
      baseForeground: this.colorset.grey07,
      baseBorderColor: this.colorset.primary,
      popupBackgroundColor: '#111213',
      modalBackgroundColor: this.colorset.grey900,
      button: darkButtonTheme,
      tooltip: darkTooltipTheme,
      switchButton: darkSwitchButtonTheme,
      sectionHeader: darkSectionHeaderTheme,
      checkbox: darkCheckboxTheme,
      input: darkInputTheme,
      card: darkCardTheme,
      tree: darkTreeTheme,
      treeview: darkTreeviewTheme,
      radio: darkRadioTheme,
      tagInstance: darkTagInstanceTheme,
      dropdown: darkDropdownTheme,
      selectItem: darkSelectItemTheme,
      contextMenu: darkContextMenu,
      modal: darkModalTheme,
      popover: darkPopoverTheme,
      tabView: darkTabViewTheme,
    };
  }
}

export class ThemeDark2 extends ThemeDark {
  public readonly nestingLevel = 2;

  public get theme(): ITheme {
    return {
      ...super.theme,
      colorset: {
        ...super.theme.colorset,
        french: ColorSet.french,
        mathematics: ColorSet.mathematics,
      },
      button: darkButtonTheme2,
      switchButton: darkSwitchButtonTheme2,
      checkbox: darkCheckboxTheme2,
      input: darkInputTheme2,
      radio: darkRadioTheme2,
      dropdown: darkDropdownTheme2,
    };
  }
}
