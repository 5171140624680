import { XNumber } from '../../core/XNumber';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Constructeur de plage.
 */
export class Range extends FunctionElement {

  /**
   * NOTE: When increment is specified, the minimum value cannot be generated unless minimum + increment > maximum
   * NOTE: This restriction comme from the fact that in NM1, the minimum is never generated when increment is specified.
   * NOTE: In order to avoid the need to refactor content and avoid problems when the minimum is generated,
   * NOTE: I implemented the generator with the same specification as in NM1
   * NOTE: I don't know if it was intended or an accident in NM1
   *
   * Retourne le nombre passé en paramètre et ne construit pas une plage.
   * (rétrocompatibilité)
   *
   * Builds a new range object from min to max, with step computed from the maximum number of decimals.
   *
   * Construit une plage entre <i>a</i> et <i>b</i> dont le saut est automatiquement calculé
   * selon le nombre de décimales des extrèmes.
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length < 1 || args.length > 3){
      return args.expectingArguments(1, 3);
    }

    const minimum:RealElement = args.getReal(0);
    if(args.length === 1){
      return minimum;
    }

    const maximum:RealElement = args.getReal(1);
    if(!maximum){
      return null;
    }

    let step:RealElement;
    if(args.length === 2){
      // auto-step
      const decimals:number = XNumber.decimals(minimum.toNumber(), maximum.toNumber());
      step = args.env.culture.createNumber(10 ** -decimals);
    }else if(args.length === 3){
      step = args.getReal(2);
      if(!step){
        return null;
      }
      if(step.toNumber() === 0){
        return null;
      }
    }

    const minimumN:number = minimum.toNumber();
    const maximumN:number = maximum.toNumber();
    const stepN:number = step.toNumber();

    return args.env.culture.createNormalizedRange(
      Math.min(minimumN, maximumN),
      Math.max(minimumN, maximumN),
      Math.abs(stepN));
  }

}
