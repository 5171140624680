import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { ListElement } from '../../elements/abstract/ListElement';
import { WList } from '../../elements/tokens/WList';
import { WString } from '../../elements/tokens/WString';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { ListOfListElement } from '../../elements/abstract/ListOfListElement';

/**
 * Concat list of numbers. Maintain order of items.
 */
export class Concat extends FunctionElement {
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 2) {
      return args.expectingArguments(2, 2);
    }

    const string1: WString = args.getString(0);
    const string2: WString = args.getString(1);

    if (string1 && string2) {
      // NOTE: string+string -> string, it's the only case that doesn't produce a list.
      return new WString(string1.getString() + string2.getString(), null, string1.combineSources(string2));
    }

    const reals1: WList = args.getReals(0);
    const reals2: WList = args.getReals(1);

    if (reals1 && reals2) {
      if (reals1.count + reals2.count === 0) {
        return reals1;
      }
      return args.env.culture.listFactory.createList(reals1.items.concat(reals2.items));
    }

    const list1: ListElement = args.getList(0);
    const list2: ListElement = args.getList(1);

    if (list1 && list2) {
      if (list1.count + list2.count === 0) {
        return list1;
      }

      const list1ListOfList = list1 instanceof ListOfListElement;
      const list2ListOfList = list2 instanceof ListOfListElement;

      if (list1ListOfList === list2ListOfList) {
        return args.env.culture.listFactory.createList(list1.items.concat(list2.items));
      }

      if (list1ListOfList) {
        return args.env.culture.listFactory.createList(list1.items.concat(list2));
      }

      if (list2ListOfList) {
        return args.env.culture.listFactory.createList([list1, ...list2.items]);
      }
    }

    return null;
  }
}
