import { ContentElement } from '../../../elements/abstract/ContentElement';
import { FormatProvider } from '../../../elements/factories/FormatProvider';

/**
 * Plain text function. Some formatting information can be lost,
 * for example when we have a list of fractions.
 */
export class TextExporter {

  /**
   * Unsafe, some markup could be lost.
   */
  public static toString(
      value:ContentElement,
      format:FormatProvider,
      strict:boolean = false):string{

    if(value == null){
      return null;
    }

    if(value.toText(strict) != null){
      return format.translateString(value.toText(strict));
    }

    return null;
  }

}
