import { MConstruct } from '../../../expr/conversion/models/MConstruct';
import { MParam } from '../../../expr/conversion/models/MParam';
import { AbstractNode } from '../../../elements/functions/tree/AbstractNode';

/**
 *
 */
export class NodeLog extends AbstractNode {

  private _base:MParam;

  private _content:AbstractNode;

  constructor(base:MParam, content:AbstractNode) {

    super();
    this._base = base;
    this._content = content;

    this._content.setParent( this );
  }

  public getToken():any[] {
    const group:MConstruct = new MConstruct(MConstruct.TYPE_FENCE, [this._content.getToken()]);

    const tokens:any[] = [ new MConstruct(MConstruct.TYPE_SUB, [['log'], [this._base]] ), group ];

    return tokens;
  }

  public simplify():AbstractNode {
    const simplified:AbstractNode = this._content.simplify();

    return new NodeLog( this._base, simplified );
  }
}
