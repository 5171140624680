import { IDictionary } from '../../js/utils/IDictionary';
import { ContentElement } from '../elements/abstract/ContentElement';
import { Node } from '../elements/abstract/Node';
import { RealElement } from '../elements/abstract/RealElement';
import { RelationalElement } from '../elements/abstract/RelationalElement';
import { SymbolElement } from '../elements/abstract/SymbolElement';
import { TokenElement } from '../elements/abstract/TokenElement';
import { WPolynomial } from '../elements/tokens/WPolynomial';
import { WRelation } from '../elements/tokens/WRelation';
import { WVariable } from '../elements/tokens/WVariable';
import { Variables } from '../expr/Variables';
import { IWriter } from '../expr/conversion/writers/IWriter';
import { OldWriterFacade } from '../expr/conversion/writers/OldWriterFacade';
import { Equal } from '../funcs/relational/Equal';
import { GreaterThan } from '../funcs/relational/GreaterThan';
import { GreaterThanOrEqualTo } from '../funcs/relational/GreaterThanOrEqualTo';
import { LessThan } from '../funcs/relational/LessThan';
import { LessThanOrEqualTo } from '../funcs/relational/LessThanOrEqualTo';
import { KeyboardConfiguration } from './KeyboardConfiguration';
import { InputCapabilities } from './InputCapabilities';
import { CommonError } from './CommonError';
import { BaseCorrector } from './BaseCorrector';
import { WNumber } from '../elements/tokens/WNumber';
import { XMath } from '../core/XMath';

/**
 *
 */
export class CRelation extends BaseCorrector {

  /**
   *
   */
  public parse(value:string):Node{
    const r:WRelation = this.tryParse(this.translateInput(value));
    if(r){
      const node:Node = new Node(r);
      node.userData = r.userData;
      return node;
    }
    return null;
  }

  /**
   *
   */
  private tryParse(valueArg:string):WRelation{
    let value = valueArg.replace(/,/g, '.');
    value = value.replace(/\s/g, '');
    value = value.replace(/<=/g, '≤');
    value = value.replace(/>=/g, '≥');

    const k:number = value.search(new RegExp('[=<>≤≥]'));
    if(k === -1){
      return null;
    }

    const op:string = value.charAt(k);

    const o:any[] = value.split(op);

    if(o.length !== 2){
      return null;
    }

    const l:TokenElement = this.env.expressions.toTokenElement(o[0], null, -1, true);
    const r:TokenElement = this.env.expressions.toTokenElement(o[1], null, -1, true);

    if(!l || !r){
      return null;
    }

    const relation:WRelation = new WRelation(l, CRelation.relationalOperator(op), r);
    relation.userData = o[0] + op + o[1] + '@16384';
    return relation;
  }

  /**
   *
   */
  public correct(
      value:string,
      target:ContentElement,
      ...targets:any[]):boolean{

    const value2:WRelation = this.tryParse(this.translateInput(value));
    const target2:WRelation = <WRelation>target ;

    if(!value2){
      this.raiseError(
        CommonError.RELATION_INVALID,
        [String(target2.rel)]);
    }

    if( this.options.acceptEquivalent && value2.rel instanceof Equal && target2.rel instanceof Equal ) {
      return this.isEquivalent( value2, target2 );
    }
    return value2.equalsTo(target2);
  }

  private translateInput(value:string):string{
    if(this.useLatex){
      return this.sanitizeInput(value);
    }
    return value;
  }

  /**
   * Accept equivalent forms:
   * 1) x=1
   * 2) x-1=0
   * 3) -x+1=0
   * 4) 2x+2=0
   */
  private isEquivalent( value:WRelation, target:WRelation):boolean {
    const args1:IDictionary = {};
    args1.a = value.lhs;
    args1.b = value.rhs;
    const value2:TokenElement = this.env.expressions.toTokenElement('a-b', new Variables(args1, this.env));

    const args2:IDictionary = {};
    args2.a = target.lhs;
    args2.b = target.rhs;
    const target2:TokenElement = this.env.expressions.toTokenElement('a-b', new Variables(args2, this.env));

    if(value2 == null || target2 == null){
      return false;
    }

    if(value2.equalsTo(target2)){
      return true;
    }

    const args3:IDictionary = {};
    args3.a = value2;
    args3.b = this.env.culture.createNumber(-1);
    const value3:TokenElement = this.env.expressions.toTokenElement('a*b', new Variables(args3, this.env));

    if(value3.equalsTo(target2)){
      return true;
    }

    if(value2 instanceof WPolynomial && target2 instanceof WPolynomial){
      const valueP:WPolynomial = <WPolynomial>value2 ;
      const targetP:WPolynomial = <WPolynomial>target2 ;
      const cc:number[] = valueP.compareCoefficients(targetP);
      if(!cc){
        return false;
      }
      for(let i:number = 1 ; i < cc.length ; i++){
        if(cc[0] !== cc[i]){
          return false;
        }
      }
      return true;
    }

    return false;
  }

  private corcePolynomial(value:ContentElement):WPolynomial{
    if(value instanceof SymbolElement){
      return <WPolynomial>(<SymbolElement>value ).widen() ;
    }

    if(value instanceof WPolynomial){
      return <WPolynomial>value ;
    }

    return null;
  }

  private getRelationVariable( rel:WRelation ):string {
    if( rel.lhs instanceof WVariable ) {
      return (<WVariable>rel.lhs ).toString();
    }
    if( rel.rhs instanceof WVariable ) {
      return (<WVariable>rel.rhs ).toString();
    }
    if( rel.lhs instanceof WPolynomial ) {
      return ( <WPolynomial>rel.lhs  ).symbols.sort(SymbolElement.compare).join('');
    }
    if( rel.rhs instanceof WPolynomial ) {
      return ( <WPolynomial>rel.rhs  ).symbols.sort(SymbolElement.compare).join('');
    }
    return '';
  }

  /**
   *
   */
  public get inputCapabilities():InputCapabilities{
    const i:InputCapabilities = super.inputWithSymbols(this.useElementaryKeyboard12 ? '=<>' : '=<>≤≥');
    i.polynomial = true;
    i.relation = true;
    return i;
  }

  /**
   *
   */
  public get mathKeyboard():number{
    return this.useElementaryKeyboard12 ?
      KeyboardConfiguration.ELEMENTARY12 :
      KeyboardConfiguration.RELATION;
  }

  /**
   * Digits: 0-9
   * Symbols: <>-+=
   */
  public get useElementaryKeyboard12(): boolean {
    if(!this.env.culture.configuration.useElementaryKeyboard12){
      return false;
    }

    if(this.origin instanceof WRelation){
      if(this.origin.lhs instanceof WNumber && this.origin.rhs instanceof WNumber){
        return XMath.isInteger(this.origin.lhs.toNumber()) &&
          XMath.isInteger(this.origin.rhs.toNumber()) &&
          (this.origin.rel.getOperator() === '<' || this.origin.rel.getOperator() === '>' || this.origin.rel.getOperator() === '=');
      }
    }

    return false;
  }

  /**
   *
   */
  public writeTo(
      w:IWriter,
      target:ContentElement,
      ...targets:any[]):void{

    const relation:WRelation = <WRelation>target ;
    this.flushToken(relation.lhs, w);
    w.writeInfixOperator(String(relation.rel));
    this.flushToken(relation.rhs, w);
  }

  /**
   *
   */
  private flushToken(value:TokenElement, w:IWriter):void{
    if(value instanceof RealElement){
      w.writeNumber((<RealElement>value ).toNumber());
    }else if(value instanceof SymbolElement){
      w.writeSymbol((<SymbolElement>value ).getSymbol());
    }else if(value instanceof WPolynomial){
      (<WPolynomial>value ).flush(new OldWriterFacade(w));
    }
  }

  /**
   *
   */
  public static relationalOperator(op:string):RelationalElement{
    const opR:RelationalElement = CRelation.relationalOperator2(op);
    if(opR){
      opR.userData = op;
    }
    return opR;
  }

  /**
   *
   */
  private static relationalOperator2(op:string):RelationalElement{
    switch(op){
      case '=': return new Equal();
      case '<': return new LessThan();
      case '>': return new GreaterThan();
      case '≤': return new LessThanOrEqualTo();
      case '≥': return new GreaterThanOrEqualTo();
    }
    return null;
  }

}
