import { AbstractFormatter } from '../formats/AbstractFormatter';
import { BaseListFormatter } from '../formats/BaseListFormatter';
import { IMarkupExporter } from '../markers/IMarkupExporter';
import { ElementCodes } from '../abstract/ElementCodes';
import { ContentElement } from '../abstract/ContentElement';
import { TokenElement } from '../abstract/TokenElement';

export class ListElement extends TokenElement {
  private readonly _items: ContentElement[];

  private readonly _formatter: BaseListFormatter;

  constructor(items: ContentElement[], formatter: BaseListFormatter) {
    super();
    if (!formatter) {
      throw new Error('Formatter required');
    }
    this._items = items;
    this._formatter = formatter;
  }

  public get items(): ContentElement[] {
    return this._items;
  }

  public get formatter(): BaseListFormatter {
    return this._formatter;
  }

  public get count(): number {
    return this._items.length;
  }

  public narrow(): ContentElement {
    return this.count === 1 ? this.getItemAt(0) : null;
  }

  public getFormat(): AbstractFormatter {
    return this._formatter;
  }

  public applyFormat(formatter: AbstractFormatter): ContentElement {
    return this.createList(this._items, formatter as BaseListFormatter);
  }

  public isNumeric(): boolean {
    return false;
  }

  public useUnambiguousItemSeparator(): boolean {
    return false;
  }

  public acceptElement(element: ContentElement): boolean {
    throw new Error();
  }

  public contains(element: ContentElement): boolean {
    for (const item of this.items) {
      if (item.equalsTo(element)) {
        return true;
      }
    }
    return false;
  }

  public getItemAt(index: number): ContentElement {
    return this._items[index];
  }

  public transform(manifest: number[]): ListElement {
    const items: ContentElement[] = [];
    for (let i = 0; i < manifest.length; i++) {
      const index: number = Math.floor(Math.abs(manifest[i]));
      if (index < this.count) {
        items.push(this._items[index]);
      }
    }
    return this.createList(items, this.formatter);
  }

  public sublist(startIndex: number, endIndex: number): ListElement {
    const items: ContentElement[] = [];
    for (let i = startIndex; i < endIndex; i++) {
      if (i >= this.count) {
        break;
      }
      items.push(this._items[i]);
    }
    return this.createList(items, this.formatter);
  }

  public toText(strict: boolean): string {
    return this.formatter.toText(this.items, strict, false, this.useUnambiguousItemSeparator());
  }

  public writeTo(exporter: IMarkupExporter = null): boolean {
    if (exporter) {
      this.formatter.writeTo(exporter, this.items, false, this.useUnambiguousItemSeparator());
    }
    return true;
  }

  public unorderedEqualsTo(value: ListElement): boolean {
    return this.equalsToImpl(value, true);
  }

  protected equalsToImpl(value: ListElement, unordered: boolean): boolean {
    if (this.count !== value.count) {
      return false;
    }

    let a: ContentElement[] = this._items;
    let b: ContentElement[] = value.items;

    if (unordered) {
      a = a.concat();
      a = a.sort(this.compareElements);
      b = b.concat();
      b = b.sort(this.compareElements);
    }

    for (let i = 0; i < a.length; i++) {
      if (!this.elementsEquals(a[i], b[i], unordered)) {
        return false;
      }
    }

    return true;
  }

  protected strictlyEqualsToImpl(value: ListElement): boolean {
    if (this.count !== value.count) {
      return false;
    }

    const a: ContentElement[] = this._items;
    const b: ContentElement[] = value.items;

    for (let i = 0; i < a.length; i++) {
      if (!a[i].strictlyEqualsTo(b[i])) {
        return false;
      }
    }

    return true;
  }

  protected compareElements(a: ContentElement, b: ContentElement): number {
    throw new Error('Must inherit');
  }

  protected elementsEquals(a: ContentElement, b: ContentElement, unordered: boolean): boolean {
    return a.equalsTo(b);
  }

  protected createList(items: ContentElement[], formatter: BaseListFormatter): ListElement {
    throw new Error('Must inherit');
  }

  public getElementCode(): string {
    return ElementCodes.TOKEN_LIST;
  }
}
