import { ContentElement } from '../../elements/abstract/ContentElement';
import { ElementCodes } from '../../elements/abstract/ElementCodes';
import { OperatorElement } from '../../elements/abstract/OperatorElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { TokenElement } from '../../elements/abstract/TokenElement';
import { WPair } from '../../elements/tokens/WPair';
import { WRatio } from '../../elements/tokens/WRatio';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';

/**
 * Constructeur de ratio ou de clé/valeur pour un dictionaire.
 */
export class Colon extends OperatorElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 2){
      return args.expectingArguments(2, 2);
    }
    if(args.getContentElement(0) && args.getContentElement(1)) {
      return this.pair(args.getContentElement(0), args.getContentElement(1), args.env);
    }
    return null;
  }

  /**
   *
   */
  public toString():string{return ':';}

  /**
   *
   */
  private pair(
      a:ContentElement,
      b:ContentElement,
      env:Environment):TokenElement{

    const na:RealElement = RealElement.parseElement(a);
    const nb:RealElement = RealElement.parseElement(b);

    if(na && nb){
      if(nb.toNumber() === 0){
        return null;
      }
      return new WRatio(
        na.toNumber(),
        nb.toNumber(),
        env.culture.formats.ratioFormatImpl).normalize(env.options.simplifyRationals);
    }

    this.wasCondensedWork = true;
    return new WPair(a, b);
  }

  /**
   *
   */
  public getElementCode():string{
    return ElementCodes.OP_COLON;
  }

}
