import { IActivity } from '../models/IActivity';
import { IPage } from '../models/IPage';
import { api } from '../utils/fetch';

export interface IOpenActivityResponse {
  readonly activity: IActivity,
  readonly pages: IPage[],
}

export const OpenActivityLocator = async (itemId: string, lang: string): Promise<IOpenActivityResponse> => {
  const endpoint = getEndpoint(itemId, lang);

  const response = await api(endpoint, { method: 'POST' });
  const data = await response.json();

  const activity: IActivity = {
    activityId: data.id,
    name: data.name,
  };

  const pages = data.pages.map((page: any): IPage => {
    return {
      pageGuid: page.pageRef.pageGuid,
      pageId: page.id,
      visible: page.visible,
      variants: page.variants,
    };
  });

  return {
    activity,
    pages,
  };
};

const getEndpoint = (itemId: string, lang: string) => {
  const matching = itemId.match(/[^a-z ]\ *([.0-9])*\d/g);
  if (matching && matching[0] === itemId) { // NOTE: if its not a number we assume its a bookId.
    return `/Publisher/Activity/Open?activityId=${itemId}&fixCamelCasing=true`;
  }

  // TODO: support countryCode and regionCode
  return `/Publisher/Activity/OpenFromBookItemId?bookItemId=${itemId}&lang=${lang}&fixCamelCasing=true`;
}
