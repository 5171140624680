import { ReplacePipe } from './ReplacePipe';
import { StringCompare } from './StringCompare';

/**
 * (x + 1, y + 10)
 */
export class PointTransformExpr extends StringCompare {

  /**
   *
   */
  constructor() {

    super('xy');
    // Remove spaces
    // Normalize minus operator
    this.sourcePipe.setNext(new ReplacePipe(' ', '', 'gi')).setNext(new ReplacePipe('−', '-', 'gi'));
    this.targetPipe.setNext(new ReplacePipe(' ', '', 'gi')).setNext(new ReplacePipe('−', '-', 'gi'));
  }

}
