import { MmlWriter } from '../../core/mml/MmlWriter';
import { RealElement } from '../../elements/abstract/RealElement';
import { SymbolElement } from '../../elements/abstract/SymbolElement';
import { TokenElement } from '../../elements/abstract/TokenElement';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';
import { WPolynomial } from '../../elements/tokens/WPolynomial';

/**
 *
 */
export class WFactors extends TokenElement {

  private elements:TokenElement[];

  /**
   *
   */
  constructor(elements:TokenElement[]){
    super();
    this.elements = elements;
  }

  /**
   *
   */
  public toFactors():TokenElement[]{
    return this.elements;
  }

  /**
   * Hide the first factor if it's 1 and there's more than one element.
   */
  public getDisplayElements():TokenElement[]{
    const skip1:boolean =
      this.elements.length > 1 &&
      this.elements[0] instanceof RealElement &&
      (<RealElement>this.elements[0] ).toNumber() === 1;

    return skip1 ? this.elements.slice(1) : this.elements;
  }

  /**
   *
   */
  public writeTo(exporter:IMarkupExporter = null):boolean{
    if(exporter){
      if(this.elements.length === 0){
        return true;
      }
      this.flush(exporter, null, false);
    }
    return true;
  }

  /**
   *
   */
  public toText(strict:boolean):string{
    if(this.elements.length === 0){
      return null;
    }

    for(let i:number = 0 ; i < this.elements.length ; i++){
      if(this.elements[i].toText(strict) == null){
        return null;
      }
    }

    const output:string[] = [];
    this.flush(null, output, strict);
    return output.join('');
  }

  /**
   *
   */
  private flush(
      exporter:IMarkupExporter = null,
      output:string[] = null,
      strict:boolean = false):void{

    const w:MmlWriter = exporter ? exporter.writer : null;
    const displayElements:TokenElement[] = this.getDisplayElements();

    if(w){
      w.beginRow();
    }

    for(let i:number = 0 ; i < displayElements.length ; i++){
      const factor:TokenElement = displayElements[i];
      const enclose:boolean = i > 0 || !WFactors.isSimpleElement(factor);

      // start enclose?
      if(enclose){
        if(w){
          w.beginFenced('(', ')');
        }
        if(output){
          output.push('(');
        }
      }

      // write factor
      if(exporter){
        factor.writeTo(exporter);
      }
      if(output){
        output.push(factor.toText(strict));
      }

      // end enclose?
      if(enclose){
        if(w){
          w.endFenced();
        }
        if(output){
          output.push(')');
        }
      }
    }

    if(w){
      w.endRow();
    }
  }

  /**
   * Returns true if factor represent a single term, as opposed to a polynomial
   * with many monomials which would represent multiple terms.
   */
  public static isSimpleElement(factor:TokenElement):boolean{
    if(factor instanceof RealElement){
      return true;
    }
    if(factor instanceof SymbolElement){
      return true;
    }
    if(factor instanceof WPolynomial){
      return (<WPolynomial>factor ).numMonomials === 1;
    }
    return false;
  }

  /**
   *
   */
  public getType():string{
    return 'factors';
  }

}
