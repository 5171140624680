import { XString } from '../../../core/XString';
import { BaseNumberFormatter } from '../../../elements/formats/BaseNumberFormatter';
import { CultureInfo } from '../../../localization/CultureInfo';

/**
 *
 */
export class PositiveNegativeFormatter extends BaseNumberFormatter {

  private _positiveFormat:string;

  public get positiveFormat():string{return this._positiveFormat;}

  private _negativeFormat:string;

  public get negativeFormat():string{return this._negativeFormat;}

  private _absoluteValue:boolean;

  public get absoluteValue():boolean{return this._absoluteValue;}

  /**
   *
   */
  constructor(
      culture:CultureInfo,
      positiveFormat:string = '{0}',
      negativeFormat:string = '{0}',
      absoluteValue:boolean = false){
    super(culture);
    this._positiveFormat = positiveFormat;
    this._negativeFormat = negativeFormat;
    this._absoluteValue = absoluteValue;
  }

  /**
   *
   */
  public toLocaleString(valueArg:number):string{
    let value = valueArg;
    const format:string =
      value < 0 ?
        this.negativeFormat :
        this.positiveFormat;

    if(this.absoluteValue){
      value = Math.abs(value);
    }

    return XString.substitute(
        format,
        this.culture.formatNumber(value));
  }

  /**
   *
   */
  public isPrefixedWithSign():boolean{
    return true;
  }

}
