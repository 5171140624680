import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WScatterPlot } from '../../elements/tokens/WScatterPlot';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class ScatterPlot extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 1){
      return args.expectingArguments(1, 1);
    }
    if (args.getPoints(0)) {
      return new WScatterPlot(args.getPoints(0));
    }
    return null;
  }

}
