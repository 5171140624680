import { ClientApplicationModel } from '../../models/ClientApplicationModel';
import { IStandard } from '../../models/IStandard';
import { IActivityAlignmentQualityPair } from '../../models/IActivityAlignmentQualityPair';
import { IPageAlignmentQualityPair } from '../../models/IPageAlignmentQualityPair';
import { api } from '../../utils/fetch';

export interface IStandardsActivitiesCoverageMap {
  [key: number]: ReadonlyArray<IActivityAlignmentQualityPair>;
}

export interface IStandardsPagesCoverageMap {
  [key: number]: ReadonlyArray<IPageAlignmentQualityPair>;
}

export interface IGetStandardsWithActivitiesAndPagesCoverageResponse {
  standardItems: ReadonlyArray<IStandard>;
  activitiesCoverage: IStandardsActivitiesCoverageMap;
  pagesCoverage: IStandardsPagesCoverageMap;
}

export const getStandardsWithActivitiesAndPagesCoverage = async (courseId: number): Promise<IGetStandardsWithActivitiesAndPagesCoverageResponse> => {
  const response = await api<IGetStandardsWithActivitiesAndPagesCoverageResponse>(`/Publisher/Curriculums/GetStandardsWithActivitiesAndPagesCoverage?courseId=${courseId}&${ClientApplicationModel.getInstance().params}`);
  return response.json();
};
