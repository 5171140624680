import { datadogLogs } from '@datadog/browser-logs';
import { EnvironmentType, getEnvironment } from './environment';
import DatadogConfig from '../configs/DatadogConfig.json';

function initialize(service: string): void {
  if (window.DD_LOGS) {
    return;
  }

  datadogLogs.init({
    service,
    clientToken: DatadogConfig.apiKey,
    env: EnvironmentType[getEnvironment()],
    forwardErrorsToLogs: true,
    version: ScolabConfiguration.GIT_SHA,
  });
}

const configure = (memberId: number, culture: string) => {
  if (!datadogLogs.logger) return;

  datadogLogs.setGlobalContextProperty('memberId', memberId);
  datadogLogs.setGlobalContextProperty('culture', culture);
};

const info = (msg: string) => {
  if (!datadogLogs.logger) return;

  datadogLogs.logger.info(msg);
};

const error = (msg: string) => {
  if (!datadogLogs.logger) return;

  datadogLogs.logger.error(msg);
};

export const ErrorLogger = {
  info,
  error,
  initialize,
  configure,
};
