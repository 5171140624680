import { XString } from '../../../core/XString';
import { MmlWriter } from '../../../core/mml/MmlWriter';
import { ContentElement } from '../../../elements/abstract/ContentElement';
import { TokenElement } from '../../../elements/abstract/TokenElement';
import { BaseListNFormatter } from '../../../elements/formats/BaseListNFormatter';
import { IMarkupExporter } from '../../../elements/markers/IMarkupExporter';
import { CultureInfo } from '../../../localization/CultureInfo';

/**
 *
 */
export class ListFormatter extends BaseListNFormatter {

  public static maxDisplayedItems:number = Number.MAX_SAFE_INTEGER;

  private open:string;

  private close:string;

  private separator:string;

  /**
   *
   */
  constructor(
      culture:CultureInfo,
      open:string = '(',
      close:string = ')',
      separator:string = ','){
    super(culture);
    this.open = open;
    this.close = close;
    this.separator = separator;
  }

  /**
   *
   */
  public writeTo(
      exporter:IMarkupExporter,
      items:ContentElement[],
      noParenthesis:boolean,
      useUnambiguousItemSeparator:boolean):boolean{

    const writer:MmlWriter = exporter.writer;

    exporter.beginWriteList();

    const separator:string = this.separator != null ? this.separator : ',';
    const useSpaceSeparator:boolean = separator === ' ';

    writer.addCommaCheck();
    if(items.length > ListFormatter.maxDisplayedItems){
      (<TokenElement>items[0] ).writeTo(exporter);
      if(useSpaceSeparator){
        exporter.appendItemSeparatorSpace();
      }
      (<TokenElement>items[1] ).writeTo(exporter);
      if(useSpaceSeparator){
        exporter.appendItemSeparatorSpace();
      }
      (<TokenElement>items[2] ).writeTo(exporter);
      writer.appendText('...');
      (<TokenElement>items[items.length - 1] ).writeTo(exporter);
    }else{
      for(let i:number = 0 ; i < items.length ; i++){
        (<TokenElement>items[i] ).writeTo(exporter);
        if(i < items.length - 1){
          if(useSpaceSeparator){
            exporter.appendItemSeparatorSpace();
          }
        }
      }
    }

    const open:string = this.getOpenChar(false, noParenthesis);
    const close:string = this.getCloseChar(false, noParenthesis);

    if(open !== '('){
      writer.open = open;
    }
    if(close !== ')'){
      writer.close = close;
    }

    if(useSpaceSeparator){
      writer.separators = '';
    }else if(this.isAmbiguousSeparator(separator) && useUnambiguousItemSeparator){
      writer.separators = exporter.listSeparator(writer.checkComma());
    }else{
      writer.separators = separator;
    }

    writer.endFenced();
    return true;
  }

  /**
   *
   */
  public toText(
      items:ContentElement[],
      strict:boolean,
      noParenthesis:boolean,
      useUnambiguousItemSeparator:boolean):string{

    const o:any[] = [];

    if(items.length > ListFormatter.maxDisplayedItems){
      const s0:string = items[0].toText(strict);
      const s1:string = items[1].toText(strict);
      const s2:string = items[2].toText(strict);
      const sN:string = items[items.length - 1].toText(strict);
      if(s0 == null || s1 == null || s2 == null || sN == null){
        return null;
      }
      o.push(s0, s1, s2, '...', sN);
    }else{
      for(let i:number = 0 ; i < items.length ; i++){
        const s:string = items[i].toText(strict);
        if(s == null){
          return null;
        }
        o.push(s);
      }
    }

    const open:string = this.getOpenChar(true, noParenthesis);
    const close:string = this.getCloseChar(true, noParenthesis);

    const useSpaceSeparator:boolean = this.separator === ' ';
    const rspace:string = items.length === 2 ? '\u00A0' : '\u0020\u0020';
    let separator:string = this.separator != null ? this.separator : ',';

    if(useSpaceSeparator){
      separator = rspace;
    }else if(this.isAmbiguousSeparator(separator) && useUnambiguousItemSeparator){
      separator = this.outputSeparator(o.join('')) + rspace;
    }else{
      separator += rspace;
    }

    return open + o.join(separator) + close;
  }

  /**
   *
   */
  private getOpenChar(
      useWordJoiner:boolean,
      noParenthesis:boolean):string{

    let open:string = this.open != null ? this.open : '(';

    if(open === '(' && noParenthesis){
      open = '';
    }

    if(open !== '' && useWordJoiner){
      open = open + XString.WORD_JOINER;
    }

    return open;
  }

  /**
   *
   */
  private getCloseChar(
      useWordJoiner:boolean,
      noParenthesis:boolean):string{

    let close:string = this.close != null ? this.close : ')';

    if(close === ')' && noParenthesis){
      close = '';
    }

    if(close !== '' && useWordJoiner){
      close = XString.WORD_JOINER + close;
    }

    return close;
  }

  /**
   * rawList contains the formatted items but no separator.
   *
   * Returns the first separator that is not found in the rawList string,
   * or else, return the last defined separator.
   */
  public outputSeparator(rawList:string):string{
    const separators = this.culture.configuration.listItemsSeparators;
    const separatorIndex = separators.findIndex((listItemSeparator) => {
      return rawList.indexOf(listItemSeparator) === -1;
    });
    return separators[separatorIndex === -1 ? separators.length - 1 : separatorIndex];
  }

  /**
   * list contains the separators
   */
  public inputSeparator(list:string):string{
    const separators = this.culture.configuration.listItemsSeparators;
    for (let i = separators.length - 1 ; i >= 0 ; i--) {
      const separator = separators[i];
      if (this.isAmbiguousSeparator(separator)) {
        if(list.indexOf(`${separator} `) !== -1){
          return `${separator} `;
        }
      } else if(list.indexOf(separator) !== -1){
          return separator;
        }
    }
    return separators[0];
  }

  public isAmbiguousSeparator(separator: string): boolean {
    return separator === this.culture.numberFormatter.decimalSeparator ||
      separator === this.culture.numberFormatter.thousandSeparator;
  }

}
