import React from 'react';
import styled from 'styled-components';
import {
  LabelNoSelection,
  LabelWithSelection,
  SelectedLabel
} from './Labels.styles';

export interface ITopLabeledSelectedItemProps {
  label: React.ReactNode;
  icon: React.ReactNode;
  selectedLabel: React.ReactNode;
  disabled?: boolean;
  className?: string;
}

export const TopLabeledIconSelectedItem: React.FC<ITopLabeledSelectedItemProps> = (props) => {
  const {
    label,
    icon,
    selectedLabel,
    disabled,
    className,
  } = props;

  return (
    <Container
      className={className}
    >
      {icon}
      <Wrapper>
        {selectedLabel !== null ?
          <LabelWithSelection>
            {label}
          </LabelWithSelection> :
          <LabelNoSelection>
            {label}
          </LabelNoSelection>
        }
        {
          selectedLabel !== null ?
            <SelectedLabel
              disabled={disabled}
            >
              {selectedLabel}
            </SelectedLabel> :
            null
        }
      </Wrapper>
    </Container>
  );
};

const Container = styled.div`
  padding: 2px 0 2px 8px;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 8px;
  max-width: 77%;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  overflow: hidden;
`;
