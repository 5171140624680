import { IBookRef } from './IBookRef';
import { HistoryEventEntityType, IHistoryEventEntity } from './history';

export interface ICourse {
  readonly id: number;
  name: string;
  subjectId: number;
  visible: boolean;
  courseCode: string;
  gradeLevelCode: string;
  referenceUrl: string;
  books: IBookRef[];
  mappedCourses: number[];
  standardsCount: number;
}

export const courseEntity = (course: ICourse): IHistoryEventEntity => {
  return {
    entityType: HistoryEventEntityType.Course,
    entityId: course.id,
    entityDescription: course.name,
  };
};
