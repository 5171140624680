import { CSSProperties } from 'react';
import { ColorSet } from '../../../themes/ColorSet';

export interface IContextMenuTheme {
  border?: CSSProperties['border'];
  background: CSSProperties['background'];
  separator: {
    backgroundColor: CSSProperties['backgroundColor'];
  };
}

export const darkContextMenu: IContextMenuTheme = {
  background: ColorSet.darkness,
  separator: {
    backgroundColor: ColorSet.grey800,
  }
};

export const lightContextMenu: IContextMenuTheme = {
  border: `1px solid ${ColorSet.grey100}`,
  background: `linear-gradient(326.5deg, ${ColorSet.grey025} 0%, ${ColorSet.white} 100%);`,
  separator: {
    backgroundColor: ColorSet.grey100,
  }
};
