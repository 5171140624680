export class Delegate
{
  /**
   *
   * @param fn
   * @param _
   * @returns {()=>any}
   */
  public static partial(fn: any, ..._: any[]): any {
    /* eslint-disable-next-line prefer-rest-params */
    const args = Array.prototype.slice.call(arguments, 1);
    return function(): any {
      // Clone the array (with slice()) and append additional arguments
      // to the existing arguments.
      const newArgs = args.slice();
      /* eslint-disable-next-line prefer-rest-params */
      newArgs.push.apply(newArgs, arguments);
      return fn.apply(this, newArgs);
    };
  }

}
