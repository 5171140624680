import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { TokenElement } from '../../elements/abstract/TokenElement';
import { IntervalsFactory } from '../../elements/factories/IntervalsFactory';
import { SetFormatter } from '../../elements/formats/sets/SetFormatter';
import { IntervalClosure } from '../../elements/models/IntervalClosure';
import { WFiniteSet } from '../../elements/tokens/WFiniteSet';
import { WLine } from '../../elements/tokens/WLine';
import { WQuadratic } from '../../elements/tokens/WQuadratic';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class Image extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 1){
      return args.expectingArguments(1, 1);
    }

    const intervalsFactory:IntervalsFactory = args.env.culture.intervalsFactory;

    const line:WLine = args.getLineOrPoly(0);
    if(line){
      if(line.slope){
        if(line.slope.toNumber() === 0){
          const o:TokenElement[] = [];
          o.push(line.yintercept);
          return new WFiniteSet(o, new SetFormatter(args.env.culture));
        }
      }

      return intervalsFactory.createReals();
    }

    const quadratic:WQuadratic = args.getQuadraticOrPoly(0);
    if(quadratic){
      const cup:boolean = quadratic.A.toNumber() > 0;
      return intervalsFactory.createInterval(
        cup ? IntervalClosure.CLOSED_OPEN : IntervalClosure.OPEN_CLOSED,
        cup ? quadratic.apex.y : null,
        cup ? null : quadratic.apex.y);
    }

    return null;
  }

}
