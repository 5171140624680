import React from 'react';
import styled from 'styled-components';
import {
  ColorSet,
  CroissantIcon,
  ShapeIcon,
} from '@scolab/publisher-ui-kit';
import { TSubjectName } from '../../models/ISubject';

interface ISubjectIconProps {
  subjectName: TSubjectName
  className?: string;
}

export const SubjectIcon: React.FC<ISubjectIconProps> = (props) => {
  const {
    subjectName,
    className,
  } = props;
  switch (subjectName) {
    case 'mathematics': return <MathIcon className={className} />;
    case 'french': return <FrenchIcon className={className} />;
    default: return null;
  }
}

const MathIcon = styled(ShapeIcon)`
  color: ${ColorSet.mathematics};
  height: 24px;
  width: 24px;
  flex-shrink: 0;
`;

const FrenchIcon = styled(CroissantIcon)`
  color: ${ColorSet.french};
  height: 24px;
  width: 24px;
  flex-shrink: 0;
`;
