import React from 'react';
import { alignText, isTextAligned } from '../../utils/alignUtils';
import { ICustomEditor } from '../../models';
import { Button } from '../../../Button';
import AlignLeftIcon from '../../../../../assets/alignLeft.svg';
import { Tooltip } from '../../../../utils/Tooltip/Tooltip';

interface IAlignLeftControlProps {
  editor: ICustomEditor;
  label: string;
}

export const AlignLeftControl: React.FC<IAlignLeftControlProps> = ({ editor, label }) => {
  const onMouseDown = (event: React.MouseEvent) => {
    event.preventDefault();
    alignText(editor, 'left');
  };
  return (
    <Tooltip
      title={label}
    >
      <Button
        active={isTextAligned(editor, 'left')}
        onMouseDown={onMouseDown}
        aria-label={label}
        icon
      >
        <AlignLeftIcon />
      </Button>
    </Tooltip>
  );
};
