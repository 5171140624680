import { TokenElement } from '../../elements/abstract/TokenElement';
import { WPolynomial } from '../../elements/tokens/WPolynomial';
import { IEval } from '../../elements/effofeks/IEval';

export class FxPoly implements IEval {

  private value:WPolynomial;

  constructor(value:WPolynomial) {

    this.value = value;
  }

  public evalX(x:number):number{
    return this.value.eval2(x);
  }

  public getUnderlyingElement():TokenElement{
    return this.value;
  }

}
