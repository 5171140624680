/**
 *
 */
export class RomanNumerals {

  public toRoman(value:number):string{
    if (value < 1 || value > 4999) {
      return null; // ERR_ARABIC_OUTSIDE_RANGE
    }
    return this.cvts(value);
  }

  public fromRoman(roman:string):number{
    if (roman) {
      return this.eval(roman);
    }
    return NaN;
  }

  private eval(romanArg:string):number{
    // evaluate Roman numeral string

    let i:number = 0;
    let h:number = 0;
    let n:number;
    let t:number = 0;
    let u:number = 0;
    // ensure upper case & evaluate thousands

    const roman = romanArg.toUpperCase();

    while (roman.charAt(i) === 'M'){
      i++;
    }

    n = i * 1000;

    // evaluate hundreds
    if (roman.substr(i, 2) === 'CM') {
      h = 9;
      i += 2;
    } else if (roman.substr(i, 1) === 'D') {
      h = 5;
      i++;
    } else if (roman.substr(i, 2) === 'CD') {
      h = 4;
      i += 2;
    }
    if (h === 0 || h === 5) {
      while (roman.charAt(i) === 'C') {
        h++;
        i++;
      }
    }
    n += h * 100;
    // evaluate tens
    if (roman.substr(i, 2) === 'XC') {
      t = 9;
      i += 2;
    } else if (roman.substr(i, 1) === 'L') {
      t = 5;
      i++;
    } else if (roman.substr(i, 2) === 'XL') {
      t = 4;
      i += 2;
    }
    if (t === 0 || t === 5) {
      while (roman.charAt(i) === 'X') {
        t++;
        i++;
      }
    }
    n += t * 10;
    // evaluate units
    if (roman.substr(i, 2) === 'IX') {
      u = 9;
      i += 2;
    } else if (roman.substr(i, 1) === 'V') {
      u = 5;
      i++;
    } else if (roman.substr(i, 2) === 'IV') {
      u = 4;
      i += 2;
    }
    if (u === 0 || u === 5) {
      while (roman.charAt(i) === 'I') {
        u++;
        i++;
      }
    }
    n += u;

    if (!((roman === this.cvt1000(n) + this.cvt100s(n) + this.cvt10s(n) + this.cvt1s(n)) ||
        (roman === this.cvt1000(n) + this.cvt100s(n) + this.cvt10s(n) + this.cvt1a(n)) ||
        (roman === this.cvt1000(n) + this.cvt100s(n) + this.cvt10a(n) + this.cvt1s(n)) ||
        (roman === this.cvt1000(n) + this.cvt100s(n) + this.cvt10a(n) + this.cvt1a(n)) ||
        (roman === this.cvt1000(n) + this.cvt100a(n) + this.cvt10s(n) + this.cvt1s(n)) ||
        (roman === this.cvt1000(n) + this.cvt100a(n) + this.cvt10s(n) + this.cvt1a(n)) ||
        (roman === this.cvt1000(n) + this.cvt100a(n) + this.cvt10a(n) + this.cvt1s(n)) ||
        (roman === this.cvt1000(n) + this.cvt100a(n) + this.cvt10a(n) + this.cvt1a(n)))) {

      return NaN;
    }
    return (n);
    // with n in range 1 - 4999, or n = -1 for invalid numeral
  }

  private cvts(n:number):string{
    // converts number 1 - 4999 to a subtractive Roman numeral
    return 	this.cvt1000(n) +
        this.cvt100s(n) +
        this.cvt10s(n) +
        this.cvt1s(n);
  }

  private cvt1000(n:number):string{
    // converts thousands digit to a Roman numeral
    return ('MMMM'.substr(0, (Math.floor(n / 1000))));
  }

  private cvt100s(hArg:number):string{
    // converts hundreds digit to a subtractive Roman numeral
    const h:number = Math.floor((hArg % 1000) / 100);
    if (h === 9) {
      return 'CM';
    }
    if (h>4) {
      return 'DCCC'.substr(0, h - 4);
    }
    if (h === 4) {
      return 'CD';
    }
    return 'CCC'.substr(0, h);
  }

  private cvt100a(hArg:number):string{
    // converts hundreds digit to an additive Roman numeral
    // used only in error-trapping Roman input
    const h:number = Math.floor((hArg % 1000) / 100);
    if (h > 4){
      return 'DCCC'.substr(0, h - 4);
    }
    return 'CCC'.substr(0, h);
  }

  private cvt10s(tArg:number):string{
    // converts tens digit to a subtractive Roman numeral
    const t:number = Math.floor((tArg % 100) / 10);
    if (t === 9) {
      return 'XC';
    }
    if (t>4) {
      return 'LXXX'.substr(0, t - 4);
    }
    if (t === 4) {
      return 'XL';
    }
    return 'XXX'.substr(0, t);
  }

  private cvt10a(tArg:number):string{
    // converts tens digit to an additive Roman numeral
    // used only in error-trapping Roman input
    const t:number = Math.floor((tArg % 100) / 10);
    if (t > 4){
      return 'LXXX'.substr(0, t - 4);
    }
    return 'XXX'.substr(0, t);
  }

  private cvt1s(uArg:number):string{
    // converts units digit to a subtractive Roman numeral
    const u = uArg % 10;
    if (u === 9) {
      return 'IX';
    }
    if (u>4) {
      return 'VIII'.substr(0, u - 4);
    }
    if (u === 4) {
      return 'IV';
    }
    return 'III'.substr(0, u);
  }

  private cvt1a(uArg:number):string{
    // converts units digit to an additive Roman numeral
    // used only in error-trapping Roman input
    const u = uArg % 10;
    if(u > 4){
      return 'VIII'.substr(0, u-4);
    }
    return 'III'.substr(0, u);
  }

}
