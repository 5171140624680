import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { Zeros } from '../../elements/functions/Zeros';
import { IFunctionAttributes } from '../../elements/markers/IFunctionAttributes';
import { WFunction } from '../../elements/tokens/WFunction';
import { WFunctionGraph } from '../../elements/tokens/WFunctionGraph';
import { WLine } from '../../elements/tokens/WLine';
import { WListOfPoints } from '../../elements/tokens/WListOfPoints';
import { WNotANumber } from '../../elements/tokens/WNotANumber';
import { WPoint } from '../../elements/tokens/WPoint';
import { WQuadratic } from '../../elements/tokens/WQuadratic';
import { WSpecialCase } from '../../elements/tokens/WSpecialCase';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';

/**
 *
 */
export class XIntercept extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length < 1 || args.length > 2){
      return args.expectingArguments(1, 2);
    }

    if(args.length === 1){
      if (args.getLineOrPoly(0)) {
        return this.line(args.getLineOrPoly(0));
      }
      if (args.getQuadraticOrPoly(0)) {
        return this.quadratic(args.getQuadraticOrPoly(0), args.env);
      }
      if (args.getFunction(0)) {
        return this.functionForm(args.getFunction(0), args.env);
      }
      if (args.getFunctionGraph(0)) {
        return this.functionGraph(args.getFunctionGraph(0), args.env);
      }
    }else if(args.length === 2){
      if (args.getPoint(0) && args.getPoint(1)) {
        return args.env.culture.parseNumber(
          this.pair(args.getPoint(0), args.getPoint(1)));
      }
    }

    return null;
  }

  /**
   *
   */
  private pair(a:WPoint, b:WPoint):number{
    if(a.equalsTo(b)){
      return NaN;
    }

    const ax:number = a.x.toNumber();
    const ay:number = a.y.toNumber();
    const bx:number = b.x.toNumber();
    const by:number = b.y.toNumber();

    if(ay === 0 && by === 0) {
      return NaN; // ?
    }
    if(ay === by){
      return NaN;
    }
    if(ax === bx){
      return ax;
    }

    const m:number = (ay - by) / (ax - bx);

    // y = mx + b
    // 0 = mx + b
    // x = -b / m
    return -(ay - m * ax) / m;
  }

  /**
   *
   */
  private line(a:WLine):ContentElement{
    if(a.xintercept){
      return a.xintercept;
    }
    return WNotANumber.getInstance();
  }

  /**
   *
   */
  private quadratic(value:WQuadratic, env:Environment):ContentElement{
    if(value.noxintercept){
      return new WSpecialCase('NoSolution');
    }
    const o:number[] = value.xintercept;
    if(o.length === 0){
      return null; // do not exist
    }
    if(o.length === 1){
      return env.culture.createNumber(o[0]);
    }
    if(o.length > 0){
      return env.culture.listFactory.createFromNumbers(o);
    }

    return null;
  }

  /**
   *
   */
  private functionForm(value:WFunction, env:Environment):ContentElement{
    return this.parseAttributes(value.form.attributes, env);
  }

  /**
   *
   */
  private functionGraph(value:WFunctionGraph, env:Environment):ContentElement{
    return this.parseAttributes(value.adapter.attributes, env);
  }

  /**
   *
   */
  private parseAttributes(
      attributes:IFunctionAttributes,
      env:Environment):ContentElement{

    const zeros:Zeros = attributes.getZeros();
    const xs:number[] = zeros.list;
    if(xs){
      switch(xs.length){
        case 0:
          return new WListOfPoints(
            [],
            env.culture.listFormatter);
        case 1:
          return env.culture.parseCoor(xs[0], 0);
        default:
          const o:ContentElement[] = [];
          for(let i:number = 0 ; i < xs.length ; i++){
            o.push(env.culture.parseCoor(xs[i], 0));
          }
          return env.culture.listFactory.createList(o);
      }
    }else if(zeros.interval){
      return zeros.interval;
    }

    return new WSpecialCase('N/A');
  }

}
