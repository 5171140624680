import { Match } from '../../core/Match';

/**
 * Fraction structure, used for analysing the user input.
 */
export class FractionModel {

  public negative:boolean;

  public integer:number;

  public numerator:number;

  public denominator:number;

  constructor(
      negative:boolean = false,
      integer:number = 0,
      numerator:number = 0,
      denominator:number = 1){

    this.negative = negative;
    this.integer = integer;
    this.numerator = numerator;
    this.denominator = denominator;
  }

  public get signedInteger():number{
    return (this.negative ? -1 : 1) * this.integer;
  }

  public toString():string{
    if(this.numerator === 0){
      return (this.negative ? '-' : '') +
          String(this.integer);
    }
    if(this.integer === 0){
      return (this.negative ? '-' : '') +
          String(this.numerator) + '/' +
          String(this.denominator);
    }
    return (this.negative ? '-' : '') +
        String(this.integer) + '+' +
        String(this.numerator) + '/' +
        String(this.denominator);
  }

  public toNumber():number{
    return (this.negative ? -1 : 1) * (this.integer + this.numerator / this.denominator);
  }

  /**
   * Break fraction string into fraction structure
   * NOTE: value contient le signe moins U+2212(−)
   * NOTE: value contient le sign plus(+) comme séparateur de l'entier et de la fraction
   * NOTE: value contient / comme séparateur du numérateur/dénominateur
   */
  public static tryParse(
      valueArg:string,
      useLatex:boolean = false):FractionModel{
    let value = valueArg;
    if(!value){
      return null;
    }

    value = value.replace(/\s/g, ''); // Remove spaces everywhere in the string
    value = value.replace(/^\+/, ''); // Remove + when it's at the beginning of the string
    value = value.replace(/^−\+/, '−'); // Remove + when it's immediately following the minus sign at the beginning of the string
    value = value.replace('−', '-'); // Replace unicode minus sign by hyphen

    // Indicates which group contains the integer, numerator, denominator. If -1 then it means
    const groupRefs:any[] =
      [
        [1, 2, 3],
        [1, 2, 3],
        [-1, 1, 2],
        [-1, 1, 2],
        [1, -1, -1],
        [-1, -1, -1]
      ];

    const patterns:any[] =
      useLatex ?
        [
          /^[-]?([1-9][0-9]*)\\frac\{([-]?[1-9][0-9]*)\}\{([-]?[1-9][0-9]*)\}$/,
          /^[-]?([1-9][0-9]*)\\frac\{(0)\}\{([-]?[1-9][0-9]*)\}$/,
          /^[-]?\\frac\{([-]?[1-9][0-9]*)\}\{([-]?[1-9][0-9]*)\}$/,
          /^\\frac\{(0)\}\{([-]?[1-9][0-9]*)\}$/,
          /^[-]?([1-9][0-9]*)$/,
          /^0$/
        ] :
        [
          /^[-]?([1-9][0-9]*)[\+]([1-9][0-9]*)\/([1-9][0-9]*)$/, // fraction a b/c
          /^[-]?([1-9][0-9]*)[\+](0)\/([1-9][0-9]*)$/, // fraction a 0/c
          /^[-]?([1-9][0-9]*)\/([1-9][0-9]*)$/, // fraction a/b
          /^(0)\/([1-9][0-9]*)$/, // fraction 0/b
          /^[-]?([1-9][0-9]*)$/, // fraction a 0/1, simplement un entier
          /^0$/ // fraction 0 0/1
        ];

    for(let i:number = 0 ; i < patterns.length ; i++){
      const pattern:RegExp = patterns[i];
      const groupRef:any[] = groupRefs[i];
      const result:Match = Match.tryParse(pattern.exec(value));
      if(result){
        const fraction:FractionModel = new FractionModel();
        fraction.negative = value.charAt(0) === '-';
        fraction.integer = groupRef[0] !== -1 ? Number(result.groups[groupRef[0]]) : 0;
        fraction.numerator = groupRef[1] !== -1 ? Number(result.groups[groupRef[1]]) : 0;
        fraction.denominator = groupRef[2] !== -1 ? Number(result.groups[groupRef[2]]) : 1;

        if(fraction.numerator < 0 || fraction.denominator < 0){
          if(fraction.negative){
            // Minus was put in front of fraction and at numerator or denominator.
            // Minus sign should be put only in front or at the numerator and/or the denominator.
            return null;
          }
          fraction.numerator = Math.abs(fraction.numerator);
          fraction.denominator = Math.abs(fraction.denominator);
          fraction.negative = true;

        }
        if(fraction.denominator === 0){
          return null; // Division par 0, il ne s'agit pas d'une fraction.
        }
        return fraction;
      }
    }

    return null;
  }

}
