import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { ColorSet, themedColor } from '@scolab/publisher-ui-kit';
import { NAME_WIDTH, SUBJECT_WIDTH } from '../constants';

interface IListHeadersProps {
  hasCheckbox?: boolean;
}

export const ListHeaders: React.FC<IListHeadersProps> = (props) => {
  const { hasCheckbox } = props;
  return (
    <HeaderRow>
      {hasCheckbox ? (
        <CheckboxPlaceholder />
      ) : null}
      <SubjectLabel>
        <FormattedMessage id="subject.subject" />
      </SubjectLabel>
      <NameLabel>
        <FormattedMessage id="contentTags.name" />
      </NameLabel>
      <DescriptionLabel>
        <FormattedMessage id="contentTags.description" />
      </DescriptionLabel>
    </HeaderRow>
  );
};

const CheckboxPlaceholder = styled.div`
  width: 24px;
`;

const HeaderRow = styled.div`
  display: flex;
  gap: 8px;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: ${themedColor({ dark: ColorSet.grey100, light: ColorSet.grey900 })};
`;

const NameLabel = styled.span`
  padding-left: 8px;
  width: ${NAME_WIDTH}px;
  box-sizing: border-box;
`;

const DescriptionLabel = styled.span`
  padding-left: 8px;
  box-sizing: border-box;
`;

const SubjectLabel = styled.span`
  padding-left: 8px;
  width: ${SUBJECT_WIDTH}px;
  box-sizing: border-box;
`;
