import { XString } from '../../../core/XString';
import { MElement } from '../../../core/mml/MElement';
import { Expression } from '../../../elements/abstract/Expression';
import { Environment } from '../../../expr/Environment';
import { IVariablesResolver } from '../../../expr/IVariablesResolver';
import { Plus } from '../../../funcs/arithmetic/Plus';
import { Times } from '../../../funcs/arithmetic/Times';
import { StringImporter } from '../../../expr/conversion/input/StringImporter';

/**
 *
 */
export class MarkupImporter {

  private env:Environment;

  private context:IVariablesResolver;

  /**
   *
   */
  constructor(
      env:Environment,
      context:IVariablesResolver = null){
    this.env = env;
    this.context = context;
  }

  /**
   *
   */
  public getExpression(markup:MElement):Expression{
    const expression = this.getRawExpression(markup);
    return new StringImporter(expression, this.context, this.env, false).expr;
  }

  /**
   *
   */
  public getRawExpression(markup:MElement):string{
    return this.stringify(markup);
  }

  /**
   *
   */
  private stringify(markup:MElement):string{
    try{
      return this.convertNode(markup);
    }catch(e){
      return null;
    }
  }

  /**
   *
   */
  private convertNode(value:MElement):string{
    const name:string = value.name;
    const text:string = XString.replaceCharEntities(value.text);

    switch(name){
      case 'math':
      case 'mrow':
      case 'mstyle':
        return this.convertChildren(value.children);
      case 'mn':
        return text.split(this.env.culture.numberFormatter.decimalSeparator).join('.').split(this.env.culture.numberFormatter.thousandSeparator).join('');
      case 'mi':
        return text;
      case 'mtext':
      case 'ms':
        if(XString.isEmptyOrSpaces(text)){
          return '';
        }
        return String.fromCharCode(34) + text + String.fromCharCode(34);
      case 'mo':
        return text;
      case 'mfrac':
        return XString.substitute('(({0})/({1}))', this.convertNode(value.children[0]), this.convertNode(value.children[1]));
      case 'msqrt':
        return XString.substitute('Sqrt({0})', this.convertChildren(value.children));
      case 'mroot':
        const index:number = this.parseRootIndex(String(value.children[1].text));
        if(isNaN(index)){
          return XString.substitute('Root(({0}), ({1}))', this.convertNode(value.children[0]), this.convertNode(value.children[1]));
        }
        switch(index){
          case 2:
            return XString.substitute('Sqrt({0})', this.convertNode(value.children[0]));
          case 3:
            return XString.substitute('Root({0}, 3)', this.convertNode(value.children[0]));
          case 4:
            return XString.substitute('Root({0}, 4)', this.convertNode(value.children[0]));
          default:
            return XString.substitute('Root(({0}), ({1}))', this.convertNode(value.children[0]), index);
        }
      case 'msup':
        // NOTE: the base must not be wrapped around parenthesis, otherwise,
        // if the user write -(x-1)^2. it would translate to (-(x-1))^(2) which
        // would change the expression.
        return XString.substitute('({0}^({1}))', this.convertNode(value.children[0]), this.convertNode(value.children[1]));
      case 'msub':
        return XString.substitute('({0}_({1}))', this.convertNode(value.children[0]), this.convertNode(value.children[1]));
      case 'mfenced':
        const open:string = value.attributes.hasOwnProperty('open') ? String(value.attributes.open) : '(';
        const close:string = value.attributes.hasOwnProperty('close') ? String(value.attributes.close) : ')';
        return XString.substitute('({0}{1}{2})', open, this.convertChildren(value.children), close);
      case 'mspace':
        return ' ';
      case 'mphantom':
        return '';
    }
    throw new Error();
  }

  /**
   *
   */
  private parseRootIndex(value:string):number{
    if(value === '' || value == null){
      return 2;
    }
    return Number(value);
  }

  /**
   *
   */
  private convertChildren(children:any[]):string{
    const o:any[] = []; /* of String */

    const c:any[] = children.filter(this.isNotIgnored, this);
    for(let i:number = 0 ; i < c.length ; i++){
      const child:MElement = c[i];
      let closeGroup:boolean = false;
      if(i > 0){
        const previous:MElement = c[i - 1];
        if(this.insertInvisibleTimes(previous, child)){
          o.push(Times.INVISIBLE_TIMES);
        }else if(this.insertInvisiblePlus(previous, child)){
          closeGroup = true;
          o.splice(o.length - 1, 0, '(');
          o.push(Plus.INVISIBLE_PLUS);
        }
      }
      o.push(this.convertNode(child));
      if(closeGroup){
        o.push(')');
      }
    }
    return o.join('');
  }

  /**
   *
   */
  private isNotIgnored(child:MElement, ..._:any[]):boolean{
    return !this.ignoreElement(child);
  }

  /**
   *
   */
  private ignoreElement(child:MElement):boolean{
    if(child.name === 'mspace'){
      return true;
    }
    if(child.name === 'mphantom'){
      return true;
    }
    if(child.name === 'mtext'){
      if(!child.text){
        return false;
      }
      let text:string = child.text.split(' ').join('');
      text = text.split('\u00A0').join('');
      if(text === ''){
        return true;
      }
    }
    return false;
  }

  /**
   * mi, mn, mfrac followed by
   * -mi
   * -msqrt
   * -mroot
   * -mo (ex. sin)
   *
   * @param value
   * @param after
   */
  private insertInvisibleTimes(
      value:MElement,
      after:MElement):boolean{

    if(this.isVariableOrNumeric(value)){
      if(this.isVariable(after) || this.isNamedOperator(after) || this.isRadical(after)){
        return true;
      }

    }
    return false;
  }

  /**
   * Number followed by a fraction is considered to be a mixed number (ex. 1+2/3).
   */
  private insertInvisiblePlus(
      value:MElement,
      after:MElement):boolean{

    if (value.name === 'mn' && this.isNumericFraction(after)) {
      return true;
    }

    return false;
  }

  /**
   *
   */
  private isNumericFraction(value: MElement): boolean {
    if(value.name !== 'mfrac'){
      return false;
    }
    if(!value.children){
      return false;
    }
    if(value.children.length !== 2){
      return false;
    }
    return value.children[0].name === 'mn' && value.children[1].name === 'mn';
  }

  /**
   *
   */
  private isVariableOrNumeric(value: MElement): boolean {
    return value.name === 'mi' ||
      value.name === 'mn' ||
      value.name === 'mfrac';
  }

  /**
   *
   */
  private isVariable(value: MElement): boolean {
    return value.name === 'mi';
  }

  /**
   *
   */
  private isRadical(value: MElement): boolean {
    return value.name === 'mroot' || value.name === 'msqrt';
  }

  /**
   *
   */
  private isNamedOperator(value: MElement): boolean {
    return value.name === 'mo' && /[a-zA-Z]/.test(value.text);
  }

}
