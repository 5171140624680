/**
 *
 */
export class OutputProvider {

  private static _defaultInstance:OutputProvider;

  public static getDefaultInstance():OutputProvider{
    if(!OutputProvider._defaultInstance){
      OutputProvider._defaultInstance = new OutputProvider(null, false);
    }
    return OutputProvider._defaultInstance;
  }

  /**
   * null(simplified)|raw|steps|raw_steps
   */
  private out:string;

  /**
   *
   */
  private verbose:boolean;

  /**
   *
   */
  constructor(out:string, verbose:boolean) {

    this.out = out;
    this.verbose = verbose;
  }

  /**
   *
   */
  public get isVerbose():boolean{
    return this.verbose;
  }

  /**
   *
   */
  public get isStepsOrRawSteps():boolean{
    return this.out === 'steps' || this.out === 'raw_steps';
  }

  /**
   *
   */
  public get isRawSteps():boolean{
    return this.out === 'raw_steps';
  }

  /**
   *
   */
  public get isRaw():boolean{
    return this.out === 'raw';
  }

  /**
   *
   */
  public get isSimplified():boolean{
    return this.out == null || this.out === 'simplified';
  }

}
