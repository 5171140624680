import { IDictionary } from '../../../../js/utils/IDictionary';

import { XBase64 } from '../../../core/XBase64';
import { XObject } from '../../../core/XObject';
import { XString } from '../../../core/XString';
import { BasicWriter } from '../../../core/mml/BasicWriter';
import { MElement } from '../../../core/mml/MElement';
import { MmlWriter } from '../../../core/mml/MmlWriter';
import { ContentElement } from '../../../elements/abstract/ContentElement';
import { FormatProvider } from '../../../elements/factories/FormatProvider';
import { MarkupExporter } from '../../../expr/conversion/output/MarkupExporter';
import { OperatorChars } from '../../../expr/conversion/output/OperatorChars';

/**
 * Attempt to export a MathML content to Html (no css).
 */
export class HtmlExporter {

  /**
   *
   */
  private element:MElement;

  /**
   *
   */
  private format:FormatProvider;

  /**
   * Allow more conversion possibilities.
   */
  private withCss:boolean;

  /**
   *
   */
  constructor(
      element:MElement,
      format:FormatProvider,
      withCss:boolean){
    this.element = element;
    this.format = format;
    this.withCss = withCss;
  }

  /**
   *
   */
  public toHtmlString():string{
    const value:string[] = [];

    // Transform into htmlText
    try{
      this.writeTo(this.element, value, false, false, 0);
    }catch(e){
      return null;
    }

    return value.join('');
  }

  // for mfrac, we must check if it is bevelled or not
  // for mpadded, it will work but will ignore the padding
  // for menclose, we could handle the underline
  private static nonHtml:any[] =
    [	'mglyph',
      'msqrt',
      'mroot',
      'merror',
      'mphantom',
      'menclose',
      'msubsup',
      'munder',
      'mover',
      'munderover',
      'mmultiscripts',
      'mlabeledtr',
      'maligngroup',
      'malignmark',
      'maction',
      'mstack',
      'mlongdiv',
      'msgroup',
      'msrow',
      'mscarries',
      'mscarry',
      'msline'];

  private moSpace(
      mo:string,
      lineBreak:string):string{

    if(mo === ':'){

      const ratioFormat =
        this.format.ratioFormatImpl ?
          this.format.ratioFormatImpl :
          this.format.defaultFormats.ratioFormatImpl;

      if(!ratioFormat.useSpaces()){
        return '';
      }
    }

    if(mo === '(' || mo === ')'){
      return '';
    }

    if(lineBreak === 'goodbreak' || lineBreak === 'auto'){
      return ' ';
    }

    return '\u00A0';
  }

  /**
   *
   */
  private writeTo(
      node:MElement,
      value:string[],
      script:boolean,
      fenced:boolean,
      level:number):void{

    const name:string = node.name;

    if(HtmlExporter.nonHtml.indexOf(name) !== -1){
      throw new Error(`Tag ${name} not supported`); // will cause toHtmlString to return null, this is telling us that the mathml could not be transformed into html
    }

    switch(name){
      case 'mspace':
        if(node.attributes.linebreak === 'newline'){
          value.push('<br/>');
        }else if(node.attributes.width === '20px'){
          // itemSeparatorSpace
          value.push('    '); // 4 spaces
        }else{
          value.push(' ');
        }
        return;
      case 'mn':
        value.push(this.sanitizeXml(node.text));
        return;
      case 'mi':
        switch(node.attributes.mathvariant){
          case 'normal':
            value.push(this.sanitizeXml(node.text));
            break;
          default:
            let identifier:string = this.sanitizeXml(node.text);
            if(identifier.length === 1){
              identifier = `<i>${identifier}</i>`;
            }
            value.push(identifier);
            break;
        }
        return;
      case 'mo':

        let mo:string = node.text;
        const form = node.attributes && node.attributes.hasOwnProperty('form') ? node.attributes.form : null;

        let skipWrite:boolean = false;
        let lspace:boolean = false;
        let rspace:boolean = false;

        if(mo === '-'){
          mo = '\u2212'; // Minus sign
        }

        switch(mo){
          case '&it;':
            value.push('\u2062');
            skipWrite = true;
            break;
          case '&ApplyFunction;':
            value.push('\u2061');
            skipWrite = true;
            break;
          default:
            lspace = form !== 'postfix' && OperatorChars.hasLSpace(mo);
            rspace = form !== 'prefix' && OperatorChars.hasRSpace(mo);
            break;
        }

        if(!skipWrite){

          /*
          1. Handle all line break (auto, newline, nobreak, goodbreak, badbreak)
          2. Handle linebreakstyle attribute (before, after, duplicate)
          a. Duplicate could only work with linebreak "newline"
          3.
          */

          if(lspace){
            value.push(this.moSpace(mo, null));
          }

          value.push(this.sanitizeXml(mo));

          if(rspace){
            const linebreak:string =
              node.attributes.hasOwnProperty('linebreak') ?
                node.attributes.linebreak :
                'auto';

            value.push(this.moSpace(mo, linebreak));
          }
        }

        return;
      case 'mtext':
      case 'ms':
        // Cancel td style no-wrap.
        let textStyles = 'white-space: normal;';

        const bold = String(node.attributes.fontweight) === 'bold' || String(node.attributes.mathvariant).indexOf('bold') !== -1;
        const italic = String(node.attributes.fontstyle) === 'italic' || String(node.attributes.mathvariant).indexOf('italic') !== -1;

        if(bold){
          textStyles += ' font-weight: bold;';
        }

        if(italic){
          textStyles += ' font-style: italic;';
        }

        let text:string = `<span style='${textStyles}'>${this.sanitizeXml(node.text)}</span>`;

        if(name === 'ms'){
          const lquote:string =
            node.attributes.hasOwnProperty('lquote') ?
            node.attributes.lquote :
            String.fromCharCode(34);

          const rquote:string =
            node.attributes.hasOwnProperty('rquote') ?
            node.attributes.rquote :
            String.fromCharCode(34);

          text = lquote + text + rquote;
        }

        value.push(text);
        return;
      case 'mfrac':
        this.writeFracTo(node, value, script, fenced, level);
        return;
    }

    let i:number;
    let child:MElement;

    if(name === 'mtable'){

      if(script){
        // Do no write table within sup/sub
        throw new Error('Table not supported within scripts');
      }

      if(fenced){
        // Do no write table within fences
        throw new Error('Table not supported within fences');
      }

      this.writeTableTo(node, value, script, fenced, level);

    }else if(name === 'mstyle'){

      const styles:string[] = [];

      const mathvariant:string =
        node.attributes.hasOwnProperty('mathvariant') ?
          node.attributes.mathvariant :
          null;

      if(mathvariant){
        if(mathvariant === 'bold'){
          styles.push(`font-weight: ${mathvariant};`);
        }else if(mathvariant === 'italic'){
          styles.push(`font-style: ${mathvariant};`);
        }else{
          throw new Error(`Unhandled mathvariant ${mathvariant}`);
        }
      }

      const mathcolor:string =
        node.attributes.hasOwnProperty('mathcolor') ?
          node.attributes.mathcolor :
          null;

      if(mathcolor){
        styles.push(`color: ${mathcolor};`);
      }

      if(styles.length > 0){
        value.push('<span style="');
        value.push(styles.join(''));
        value.push('">');
      }

      for(i = 0 ; i < node.children.length ; i++){
        child = node.children[i];
        this.writeTo(child, value, script, fenced, level + 1);
      }

      if(styles.length > 0){
        value.push('</span>');
      }

    }else{
      let separator:string = '';
      let spacing:string = '';

      if(name === 'mfenced'){
        const open:string =
          node.attributes.hasOwnProperty('open') ?
            String(node.attributes.open) :
            '(';

        if(open !== ''){
          value.push(open);
          value.push(XString.WORD_JOINER);
        }

        if(!node.attributes.hasOwnProperty('separators')){
          separator = ',';
        }else if(String(node.attributes.separators).length > 0){
          // NOTE: one character separator, we take the first
          separator = String(node.attributes.separators).charAt(0);
        }

        // Double space for readability.
        // Use single non-breaking space for list of 2 items.
        spacing = node.children.length === 2 ? '\u00A0' : '\u0020\u0020';
      }

      const isFenced:boolean = name === 'mfenced';
      for(i = 0 ; i < node.children.length ; i++){
        child = node.children[i];
        if(i > 0){
          if(separator !== ''){
            value.push(separator + spacing);
          }
        }

        const isSuperscript:boolean = name === 'msup' && i === 1;
        const isSubscript:boolean = name === 'msub' && i === 1;

        if(isSuperscript){
          value.push('<sup>');
        }
        if(isSubscript){
          value.push('<sub>');
        }

        this.writeTo(
          child,
          value,
          isSuperscript || isSubscript || script,
          fenced || isFenced,
          level + 1);

        if(isSuperscript){
          value.push('</sup>');
        }
        if(isSubscript){
          value.push('</sub>');
        }
      }

      if(name === 'mfenced'){
        const close:string =
          node.attributes.hasOwnProperty('close') ?
            String(node.attributes.close) :
            ')';

        if(close !== ''){
          value.push(XString.WORD_JOINER);
          value.push(close);
        }
      }
    }
  }

  /**
   *
   */
  private writeFracTo(node:MElement, value:string[], script:boolean, fenced:boolean, level:number):void{
    const bevelled:boolean = String(node.attributes.bevelled) === String(true);

    if(!bevelled && !this.withCss){
      throw new Error('Fraction not supported without css flag'); // Non html
    }

    const numerator:MElement = node.children.length > 0 ? node.children[0] : null;
    const denominator:MElement = node.children.length > 1 ? node.children[1] : null;

    if(bevelled){
      if(numerator){
        this.writeTo(numerator, value, script, fenced, level + 1);
        value.push('/');
        if(denominator){
          this.writeTo(denominator, value, script, fenced, level + 1);
        }
      }
    }else{
      if(script){
        // Doesn't display well when inside sup/sub.
        throw new Error('Fraction not supported within scripts');
      }

      if(fenced){
        // Doesn't display well when inside sup/sub.
        throw new Error('Fraction not supported within fences');
      }

      const numalign:string = numerator.attributes.hasOwnProperty('numalign') ? numerator.attributes.numalign : 'center';
      const denomalign:string = numerator.attributes.hasOwnProperty('numalign') ? numerator.attributes.numalign : 'center';

      let mfracTemplate:string = XBase64.decode('PHNwYW4gc3R5bGU9ImRpc3BsYXk6IGlubGluZS10YWJsZTsgdmVydGljYWwtYWxpZ246IG1pZGRsZTsgYm9yZGVyLWNvbGxhcHNlOiBjb2xsYXBzZTsgbWFyZ2luOiAwOyBwYWRkaW5nOiAwOyB0ZXh0LWluZGVudDogaW5pdGlhbDsgd2hpdGUtc3BhY2U6IHByZSI+PHNwYW4gc3R5bGU9ImRpc3BsYXk6IHRhYmxlLXJvdzsgYm9yZGVyLWJvdHRvbTogMXB4IHNvbGlkOyI+PHNwYW4gc3R5bGU9ImRpc3BsYXk6IHRhYmxlLWNlbGw7IHBhZGRpbmctbGVmdDogMnB4OyBwYWRkaW5nLXJpZ2h0OiAycHg7IHRleHQtYWxpZ246IHsyfTsiPjxzcGFuIHN0eWxlPSJwYWRkaW5nOiAwOyBtYXJnaW46IDA7Ij57MH08L3NwYW4+PC9zcGFuPjwvc3Bhbj48c3BhbiBzdHlsZT0iZGlzcGxheTogdGFibGUtcm93OyI+PHNwYW4gc3R5bGU9ImRpc3BsYXk6IHRhYmxlLWNlbGw7IHBhZGRpbmctbGVmdDogMnB4OyBwYWRkaW5nLXJpZ2h0OiAycHg7IHRleHQtYWxpZ246IHszfTsiPjxzcGFuIHN0eWxlPSJwYWRkaW5nOiAwOyBtYXJnaW46IDA7Ij57MX08L3NwYW4+PC9zcGFuPjwvc3Bhbj48L3NwYW4+');
      mfracTemplate = XString.substitute(mfracTemplate, '|', '|', numalign, denomalign);

      const parts:any[] = mfracTemplate.split('|');

      value.push(XString.WORD_JOINER);
      value.push(parts[0]);
      if(numerator){
        this.writeTo(numerator, value, script, fenced, level + 1);
      }
      value.push(parts[1]);
      if(denominator){
        this.writeTo(denominator, value, script, fenced, level + 1);
      }
      value.push(parts[2]);
    }
  }

  /**
   *
   */
  private writeTableTo(table:MElement, value:string[], script:boolean, fenced:boolean, level:number):void{
    if(level > 1){
      throw new Error(`Unhandled nested table inside ${table.parent.name}`);
    }

    this.validateAttributes(table.attributes, ['frame', 'rowlines', 'rowalign', 'columnlines', 'columnalign', 'equalrows', 'equalcolumns']);

    // equalrows and equalcolumns are ignored

    let tableStyles:string = 'display: inline-table; vertical-align: middle; border-collapse: collapse; margin: 0; padding: 0; text-indent: initial;';

    const frame = table.attributes.hasOwnProperty('frame') ? table.attributes.frame : 'none';

    const rowalign:string[] =
      table.attributes.hasOwnProperty('rowalign') ?
        table.attributes.rowalign.split(' ') : ['baseline'];

    const columnalign:string[] =
      table.attributes.hasOwnProperty('columnalign') ?
        table.attributes.columnalign.split(' ') : ['center'];

    const rowlines:string[] =
      table.attributes.hasOwnProperty('rowlines') ?
        table.attributes.rowlines.split(' ') : ['none'];

    const columnlines:string[] =
      table.attributes.hasOwnProperty('columnlines') ?
        table.attributes.columnlines.split(' ') : ['none'];

    if(frame === 'solid'){
      tableStyles += ' border: 1px solid black;';
    }else if(frame === 'dashed'){
      tableStyles += ' border: 1px dashed black;';
    }

    value.push(`<table style="${tableStyles}"><tbody>`);
    for(let i:number = 0 ; i < table.children.length ; i++){
      this.writeTableRowTo(table.children[i], value, script, fenced, level, i, rowalign, columnalign, rowlines, columnlines);
    }

    value.push('</tbody></table>');
  }

  /**
   *
   */
  private writeTableRowTo(tr: MElement,
                          value:string[],
                          script:boolean,
                          fenced:boolean,
                          level:number,
                          rowIndex:number,
                          rowalignArg:string[],
                          columnalignArg:string[],
                          rowlines:string[],
                          columnlines:string[]): void {

    let rowalign = rowalignArg;
    let columnalign = columnalignArg;

    if(tr.name !== 'mtr'){
      throw new Error('Table only accepts tr');
    }
    this.validateAttributes(tr.attributes, ['columnalign', 'rowalign']);

    if(tr.attributes.hasOwnProperty('rowalign')){
      rowalign = [tr.attributes.rowalign];
    }

    if(tr.attributes.hasOwnProperty('columnalign')){
      columnalign = tr.attributes.columnalign.split(' ');
    }

    const trStyles:string = '';

    value.push(`<tr style="${trStyles}">`);

    for(let j:number = 0 ; j < tr.children.length ; j++){
      this.writeTableCellTo(tr.children[j], value, script, fenced, level, rowIndex, j, rowalign, columnalign, rowlines, columnlines);
    }

    value.push('</tr>');
  }

  /**
   *
   */
  private writeTableCellTo(td: MElement,
                           value:string[],
                           script:boolean,
                           fenced:boolean,
                           level:number,
                           rowIndex:number,
                           colIndex:number,
                           rowalignArg:string[],
                           columnalignArg:string[],
                           rowlines:string[],
                           columnlines:string[]): void {
    let rowalign = rowalignArg;
    let columnalign = columnalignArg;

    if(td.name !== 'mtd'){
      throw new Error('Tr only accepts td');
    }
    this.validateAttributes(td.attributes, ['columnalign', 'columnspan', 'rowspan']);

    let tdStyles:string = 'padding: 4px; white-space: nowrap;';

    if(td.attributes.hasOwnProperty('rowalign')){
      rowalign = [td.attributes.rowalign];
    }

    if(td.attributes.hasOwnProperty('columnalign')){
      columnalign = [td.attributes.columnalign];
    }

    if(rowalign){
      const valign:string =
        rowIndex < rowalign.length ?
          rowalign[rowIndex] :
          rowalign[rowalign.length - 1];
      tdStyles += `valign: ${valign};`;
    }

    if(columnalign){
      const align:string =
        colIndex < columnalign.length ?
          columnalign[colIndex] :
          columnalign[columnalign.length - 1];
      tdStyles += `text-align: ${align};`;
    }

    if(rowlines){
      const rowline:string =
        rowIndex < rowlines.length ?
          rowlines[rowIndex] :
          rowlines[rowalign.length - 1];

      if(rowline === 'solid'){
        tdStyles += ' border-bottom: 1px solid black;';
      }else if(rowline === 'dashed'){
        tdStyles += ' border-bottom: 1px dashed black;';
      }
    }

    if(columnlines){
      const columnline:string =
        colIndex < columnlines.length ?
          columnlines[colIndex] :
          columnlines[columnlines.length - 1];

      if(columnline === 'solid'){
        tdStyles += ' border-right: 1px solid black;';
      }else if(columnline === 'dashed'){
        tdStyles += ' border-right: 1px dashed black;';
      }
    }

    const colspan = td.attributes.hasOwnProperty('columnspan') ? td.attributes.columnspan : 1;
    const colspanAttr = colspan !== 1 ? ` colspan="${colspan}"` : '';

    const rowspan = td.attributes.hasOwnProperty('rowspan') ? td.attributes.rowspan : 1;
    const rowspanAttr = rowspan !== 1 ? ` rowspan="${rowspan}"` : '';

    value.push(`<td style="${tdStyles}"${rowspanAttr}${colspanAttr}>`);

    for(let k:number = 0 ; k < td.children.length ; k++){
      this.writeTo(td.children[k], value, script, fenced, level + 1 + 1 + 1); // tr +1, td +1
    }

    value.push('</td>');
  }

  /**
   *
   */
  private validateAttributes(attributes:IDictionary, handledAttributes:any[]):boolean{
    if(!attributes){
      return true;
    }

    const names:any[] = XObject.getProps(attributes);
    for(let i:number = 0 ; i < names.length ; i++){
      if(handledAttributes.indexOf(names[i]) === -1){
        throw new Error(`Unhandled table attribute ${names[i]}`);
      }
    }

    return true;
  }

  /**
   *
   * @param value
   * @returns {string}
   */
  private sanitizeXml(value: string): string {
    return HtmlExporter.sanitizeXml(value);
  }

  /**
   * Encode xml chars but preserve html tags found in value.
   *
   * The problem here is that HtmlExporter could receive a string that contains the operator < or >, but could also
   * receive a string that contains some Html tags. Html tags should be preserved whereas operator < or > should be
   * xml|html-encoded.
   *
   * @param value
   */
  public static sanitizeXml(value: string): string {
    const rx = new RegExp('<\\/?([a-z]+)[^>]*>', 'gi');
    const temp = [];

    let index = 0;
    let o = rx.exec(value);
    while(o){
      temp.push(XString.escapeXml(value.substring(index, o.index)));
      const tagName = String(o[1]).toLowerCase();
      if(HtmlExporter.isValidHtmlTag(tagName)){
        temp.push(o[0]);
      } else {
        temp.push(XString.escapeXml(o[0]));
      }
      index = o.index + o[0].length;
      o = rx.exec(value);
    }

    temp.push(XString.escapeXml(value.substring(index, value.length)));
    return temp.join('');
  }

  private static htmlTagNames: ReadonlyArray<string> = [
    'a', 'abbr', 'acronym', 'address', 'applet', 'area', 'article',
    'aside', 'audio', 'b', 'base', 'basefont', 'bdi', 'bdo', 'big',
    'blockquote', 'body', 'br', 'button', 'canvas', 'caption', 'center',
    'cite', 'code', 'col', 'colgroup', 'data', 'datalist', 'dd', 'del',
    'details', 'dfn', 'dialog', 'dir', 'div', 'dl', 'dt', 'em', 'embed',
    'fieldset', 'figcaption', 'figure', 'font', 'footer', 'form',
    'frame', 'frameset', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'head',
    'header', 'hr', 'html', 'i', 'iframe', 'img', 'input', 'ins',
    'kbd', 'label', 'legend', 'li', 'link', 'main', 'map', 'mark',
    'meta', 'meter', 'nav', 'noframes', 'noscript', 'object', 'ol',
    'optgroup', 'option', 'output', 'p', 'param', 'picture', 'pre',
    'progress', 'q', 'rp', 'rt', 'ruby', 's', 'samp', 'script',
    'section', 'select', 'small', 'source', 'span', 'strike', 'strong',
    'style', 'sub', 'summary', 'sup', 'svg', 'table', 'tbody', 'td',
    'template', 'textarea', 'tfoot', 'th', 'thead', 'time', 'title',
    'tr', 'track', 'tt', 'u', 'ul', 'var', 'video', 'wbr'
  ];

  /**
   *
   * @param tagName
   */
  public static isValidHtmlTag(tagName: string): boolean {
    // TODO: optimize using trie-search.
    return HtmlExporter.htmlTagNames.indexOf(tagName) !== -1;
  }

  /**
   *
   */
  public static toString(
      element:ContentElement,
      format:FormatProvider,
      withCss:boolean):string{

    const writer:MmlWriter = new MmlWriter(new BasicWriter());

    element.writeTo(
      new MarkupExporter(
        writer,
        format,
        false));

    const exporter:HtmlExporter =
      new HtmlExporter(
        <MElement>writer.content ,
        format,
        withCss);

    return exporter.toHtmlString();
  }

}
