import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { ListOfListElement } from '../../elements/abstract/ListOfListElement';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Insert one or many elements at a specified index.
 */
export class InsertAt extends FunctionElement {
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 3) {
      return args.expectingArguments(3, 3);
    }

    const oldValues = args.getList(0);
    const newValues = args.getList(1);
    const index = args.getWholeNumber(2);

    if (!oldValues || !newValues || !index) {
      return null;
    }

    if (index.toNumber() > oldValues.count) {
      return oldValues;
    }

    const indexN = index.toNumber();
    const elements: ContentElement[] = [];

    for (let i = 0; i < indexN; i++) {
      elements.push(oldValues.getItemAt(i));
    }

    if (oldValues instanceof ListOfListElement) {
      elements.push(newValues);
    } else {
      for (let i = 0; i < newValues.count; i++) {
        elements.push(newValues.getItemAt(i));
      }
    }

    for (let i = indexN; i < oldValues.count; i++) {
      elements.push(oldValues.getItemAt(i));
    }

    return args.env.culture.listFactory.createList(elements);
  }
}
