import { Point } from '../../../js/geom/Point';

import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { ListElement } from '../../elements/abstract/ListElement';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';

/**
 * Compatibility
 */
export class RandomTilesFigure extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 0){
      return args.expectingArguments(0, 0);
    }
    return this.rand(args.env);
  }

  /**
   *
   */
  private static tiles:any[] =
    [new Point(0, 0),
     new Point(1, 0),
     new Point(2, 0),
     new Point(3, 0),
     new Point(0, 1),
     new Point(1, 1),
     new Point(2, 1),
     new Point(3, 1),
     new Point(0, 2),
     new Point(1, 2),
     new Point(2, 2),
     new Point(3, 2),
     new Point(0, 3),
     new Point(1, 3),
     new Point(2, 3),
     new Point(3, 3)];

  /**
   *
   */
  private static figures:any[] =
    [[0*4+0, 1*4+0, 2*4+0, 3*4+0, 0*4+1, 2*4+1, 0*4+2, 1*4+2, 2*4+2, 0*4+3, 2*4+3],
     [0*4+0, 0*4+1, 0*4+2, 1*4+2, 2*4+2, 3*4+2, 0*4+3, 3*4+3],
     [0*4+0, 1*4+0, 2*4+0, 3*4+0, 0*4+1, 0*4+2, 1*4+2, 2*4+2, 0*4+3],
     [0*4+0, 1*4+0, 2*4+0, 3*4+0, 0*4+1, 0*4+2, 1*4+2, 2*4+2, 3*4+2, 0*4+3],
     [0*4+0, 0*4+1, 1*4+1, 2*4+1, 0*4+2, 2*4+2, 0*4+3, 1*4+3, 2*4+3],
     [0*4+0, 0*4+1, 0*4+2, 1*4+2, 0*4+3, 1*4+3],
     [0*4+0, 0*4+1, 1*4+1, 2*4+1, 3*4+1, 0*4+2, 3*4+2, 0*4+3, 1*4+3, 2*4+3, 3*4+3],
     [0*4+0, 0*4+1, 0*4+2, 1*4+2, 2*4+2, 0*4+3, 2*4+3],
     [0*4+0, 0*4+1, 1*4+1, 2*4+1, 3*4+1, 0*4+2, 2*4+2, 0*4+3, 1*4+3, 2*4+3]];

  /**
   *
   */
  private rand(env:Environment):ListElement{
    const o:ContentElement[] = [];
    const figure:number = env.prng.randomInt(0, RandomTilesFigure.figures.length);
    for(let i:number = 0 ; i < RandomTilesFigure.figures[figure].length ; i++){
      const p:number = RandomTilesFigure.figures[figure][i];
      o.push(env.culture.parsePoint(RandomTilesFigure.tiles[p]));
    }
    return env.culture.listFactory.createList(o);
  }

}
