import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { CurrencyInfo } from '../../localization/CurrencyInfo';

/**
 * Dénominations US
 */
export class USD extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 0){
      return args.expectingArguments(0, 0);
    }
    return args.env.culture.listFactory.createFromArray(
      CurrencyInfo.USD.denominations2.concat().reverse());
  }

}
