import { ContentElement } from '../../../../elements/abstract/ContentElement';
import { FunctionElement } from '../../../../elements/abstract/FunctionElement';
import { WBoolean } from '../../../../elements/tokens/WBoolean';
import { WPolygon } from '../../../../elements/tokens/WPolygon';
import { ArgumentsObject } from '../../../../expr/ArgumentsObject';

/**
 * Indique si un polygone contient au moins une paire de côtés parallèles.
 */
export class HasParallels extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }

    if(args.getPolygon(0) ){
      return this.polygon(args.getPolygon(0));
    }
    return null;
  }

  /**
   *
   */
  private polygon(value:WPolygon):WBoolean{
    return WBoolean.parse(this.test(value));
  }

  /**
   *
   */
  private test(value:WPolygon):boolean{
    if(value.segments < 4){
      return false;
    }
    for(let i:number = 0 ; i < value.segments - 2 ; i++){
      const k:number = value.segments - (i === 0 ? 1 : 0);
      for(let j:number = i + 2 ; j < k ; j++){
        if(value.segmentAt(i).isParallelTo(value.segmentAt(j))){
          return true;
        }
      }
    }
    return false;
  }

}
