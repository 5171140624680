import React from 'react';

export enum PublisherContexts {
  pageEditor = 'pageEditor',
  activityPreview = 'activityPreview',
  variableEditor = 'variableEditor',
}

export interface IPublisherContext {
  readonly environment: PublisherContexts;
}

export const PublisherContext = React.createContext<IPublisherContext>({
  environment: null,
});
