import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Sum of all digits in a number.
 *
 * http://www.netmaths.net/Lexique/Default.aspx#racine%20num%C3%A9rique%20d%27un%20nombre
 */
export class DigitalRoot extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 1){
      return args.expectingArguments(1, 1);
    }

    if(args.getWholeNumber(0)){
      return args.env.culture.createNumber(1 + (args.getWholeNumber(0).toNumber() - 1) % 9);
    }
    return null;
  }

}
