import { MathError } from '../../core/MathError';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WRational } from '../../elements/tokens/WRational';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Convertir en fraction.
 */
export class Rationalize extends FunctionElement {

  /**
   * Convertit une valeur décimale en fraction avec un dénominateur plus petit ou égal à <i>k</i>.
   * La fraction résultante n'est pas necessairement égale au nombre décimale
   * mais s'en approche le plus possible.
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 2){
      return args.expectingArguments(2, 2);
    }

    const value:RealElement = args.getReal(0);
    if(!value){
      return null;
    }

    const maxden:RealElement = args.getNaturalNumber(1);
    if(!maxden){
      throw new MathError('Max denominator must be an integer greater than 0');
    }

    return value.toNumber() === 0 ?
      args.env.culture.createRational(0, maxden.toNumber()) :
      WRational.rationalize(
        value.toNumber(),
        maxden.toNumber(),
        args.env.culture.formats.rationalFormatImpl);
  }

}
