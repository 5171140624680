import { NodeEntry, Element, Editor, Transforms, Path, Node } from '../TypedSlate';
import { ICustomEditor, IPlugin } from '../models';
import { isList } from '../utils';

export const withLists: IPlugin = (editor: ICustomEditor) => {
  const { normalizeNode } = editor;

  editor.normalizeNode = (entry: NodeEntry) => {
    const [node, path] = entry;
    if (Element.isElement(node) && isList(node)) {
      const currentListType = node.listStyleType;

      // If the previous sibling is a list of the same type, merge with it
      const previousNodePath = Path.hasPrevious(path) && Path.previous(path);
      if (previousNodePath) {
        const [previousNode] = Editor.node(editor, previousNodePath);
        if (previousNode && Element.isElement(previousNode) && isList(previousNode) && previousNode.listStyleType === currentListType) {
          Transforms.mergeNodes(editor, { at: path });
        }
      }

      // If the next sibling is a list of the same type, merge with it
      const nextNodePath = Path.next(path);
      const nextNodeExists = Node.has(editor, nextNodePath);
      if (nextNodeExists) {
        const [nextNode] = Editor.node(editor, nextNodePath);
        if (nextNode && Element.isElement(nextNode) && isList(nextNode) && nextNode.listStyleType === currentListType) {
          Transforms.mergeNodes(editor, { at: nextNodePath });
        }
      }
    }

    normalizeNode(entry);
  };

  return editor;
};
