import { Point } from '../../../js/geom/Point';

import { XGeom } from '../../core/XGeom';
import { XSort } from '../../core/XSort';
import { TokenElement } from '../../elements/abstract/TokenElement';
import { SegmentModel } from '../../elements/models/SegmentModel';
import { WPolygon } from '../../elements/tokens/WPolygon';
import { WPolyline } from '../../elements/tokens/WPolyline';
import { WSegment } from '../../elements/tokens/WSegment';

/**
 *
 */
export class SegmentsUtil {

  /**
   * Converts a list of segments into a polygon or a polyline.
   *
   * Normalize theses cases:
   * 		a) two segments lies on the same line and touch.
   */
  public static toPoly(
      segments:WSegment[]):TokenElement{

    if(segments.length === 0){
      return null;
    }

    const s:WSegment[] = segments.concat();

    while(SegmentsUtil.normalizeSegmentsCollection(s)){continue;}

    const o:Point[] = [];
    o.push(s[0].a, s[0].b);
    s.splice(0, 1);

    while(s.length > 0){
      let i:number = 0;
      let p:Point = null;
      let u:boolean;
      while(i < s.length){
        // Find a segment that connects to the current path
        const a:Point = s[i].a;
        const b:Point = s[i].b;

        if(a.equals(o[0])){
          p = b;
          u = true;
          break;
        }else if(a.equals(o[o.length - 1])){
          p = b;
          u = false;
          break;
        }else if(b.equals(o[0])){
          p = a;
          u = true;
          break;
        }else if(b.equals(o[o.length - 1])){
          p = a;
          u = false;
          break;
        }else{
          i++;
        }
      }

      if(p){
        if(u){
          o.unshift(p);
        }else{
          o.push(p);
        }
        s.splice(i, 1);
        continue;
      }else{
        return null;
      }
    }

    if(o[0].equals(o[o.length - 1])){
      o.pop();
      if(o.length >= 3){
        return new WPolygon(o.slice());
      }
    }

    return new WPolyline(o);
  }

  /**
   *
   */
  private static normalizeSegmentsCollection(segments:WSegment[]):boolean{
    for(let j:number = 0 ; j < segments.length - 1 ; j++){
      for(let k:number = j + 1 ; k < segments.length ; k++){
        const s1:WSegment = segments[j];
        const s2:WSegment = segments[k];

        if(XGeom.segmentsOverlap(s1.a, s1.b, s2.a, s2.b)){
          let p:Point[] = [];
          p.push(s1.a, s1.b, s2.a, s2.b);
          p = p.sort(XSort.xycoordinate);
          segments.splice(k, 1);
          segments.splice(j, 1, new WSegment(p[0], p[p.length - 1]));
          return true;
        }
      }
    }

    return false;
  }

  /**
   * close: indicates if last vertex is linked to the first vertex.
   */
  public static polylineLength(
      vertices:Point[],
      close:boolean):number{

    let n:number = 0;

    for(let v:number = 0 ; v < vertices.length - 1 ; v++){
      n += Point.distance(vertices[v], vertices[v + 1]);
    }

    if(close){
      n += Point.distance(vertices[vertices.length - 1], vertices[0]);
    }

    return n;
  }

  /**
   *
   */
  public static equals(
      a:Point[],
      b:Point[]):boolean{

    if(a.length !== b.length){
      return false;
    }

    for(let v:number = 0 ; v < a.length ; v++){
      if(!a[v].equals(b[v])){
        return false;
      }
    }

    return true;
  }

  /**
   * Returns true if one of the segment formed by the list of points self-intersects.
   * close: a segment between first and last vertices exist.
   */
  public static selfIntersects(
      vertices:Point[],
      close:boolean):boolean{

    const s:SegmentModel[] =
      SegmentsUtil.toSegments(vertices, close);

    for(let i:number = 0 ; i < s.length - 1 ; i++){
      for(let j:number = 0 ; j < s.length ; j++){
        const a:Point = s[i].a;
        const b:Point = s[i].b;
        const c:Point = s[j].a;
        const d:Point = s[j].b;
        if(XGeom.segmentsIntersection(a, b, c, d)){
          if(b.equals(c) || a.equals(d)){
            continue;
          }
          return true;
        }
      }
    }

    return false;
  }

  /**
   *
   */
  public static toSegments(
      vertices:Point[],
      close:boolean):SegmentModel[]{

    const o:SegmentModel[] = [];
    for(let i:number = 0 ; i < vertices.length - 1 ; i++){
      o.push(new SegmentModel(vertices[i], vertices[i + 1]));
    }
    if(close){
      o.push(new SegmentModel(vertices[vertices.length - 1], vertices[0]));
    }
    return o;
  }

}
