import React from 'react';
import { ICustomEditor } from '../../models';
import { IPresetColorGroup } from '../../../ColorPicker';
import { getColor, setColor } from '../../utils/characterFormatUtils';
import { GenericColorControl } from './GenericColorControl';
import { IClassDefinition } from '../../models/configuration';

interface IColorControlProps {
  editor: ICustomEditor;
  label: string;
  colorPresets: ReadonlyArray<IPresetColorGroup>;
  classes: ReadonlyArray<IClassDefinition>;
  className?: string;
}

export const ColorControl: React.FC<IColorControlProps> = ({ editor, label, colorPresets, classes, className }) => {
  return (
    <GenericColorControl
      Label={label}
      editor={editor}
      colorPresets={colorPresets}
      classes={classes}
      setColorValue={setColor}
      getColorValue={getColor}
      className={className}
    />
  );
};
