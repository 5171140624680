import React from 'react';
import styled from 'styled-components';
import { Modal } from './Modal';
import { Button } from '../inputs/Button';

export interface IDeleteWorklistDialogProps {
  labelConfirm: string;
  labelCancel: string;
  onCancel: () => void;
  onConfirmAction: () => void;
  text: string;
}

export const ConfirmationDialog: React.FC<IDeleteWorklistDialogProps> = (props) => {
  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const handleConfirm = React.useCallback(() => {
    setSubmitting(true);
    props.onConfirmAction();
  }, []);

  return (
    <Modal open>
      <ModalContainer>
        <div>
          {props.text}
        </div>
        <ButtonContainer>
          <Button onClick={props.onCancel}>
            {props.labelCancel}
          </Button>
          <Button disabled={submitting} onClick={handleConfirm}>
            {props.labelConfirm}
          </Button>
        </ButtonContainer>
      </ModalContainer>
    </Modal>
  );
};

const ModalContainer = styled.div`
  font: ${props => props.theme.typeset.body1Regular};
  padding: 2em;
  gap: 0.5em;
  display: flex;
  min-width: 400px;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 10px;
  padding: 4px;
  margin-top: 1em;
  box-sizing: border-box;
`;
