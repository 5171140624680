import { XMath } from '../../../core/XMath';
import { XNumber } from '../../../core/XNumber';
import { AbstractStep } from '../../AbstractStep';
import { Compartment } from '../../Compartment';
import { FrameBorders } from '../../FrameBorders';
import { LongDivisionOperationEnglish } from '../../LongDivisionOperationEnglish';
import { DivisionModel } from '../../models/DivisionModel';
import { DivRestEnglish } from './DivRestEnglish';
import { DivEndEnglish } from './DivEndEnglish';
import { DivQuotientEnglish } from './DivQuotientEnglish';
import { DivWriteNewDividendEnglish } from './DivWriteNewDividendEnglish';

/**
 *
 */
export class DivInitEnglish extends AbstractStep {

  private division:LongDivisionOperationEnglish;

  private get model():DivisionModel{
    return this.division.model;
  }

  constructor(division:LongDivisionOperationEnglish){
    super(division);
    this.division = division;

    let i:number;
    let c:Compartment;

    let digits:any[];

    if( this.model.divisorHasDecimals ) {
      for(i = 0 ; i < division.rawDivisor.length ; i++){
        this.source.push(division.rawDivisor[i]);
      }

      c = Compartment.createChar('×');
      division.normalize.push(c);
      this.source2.push( c );

      const p10:number = XMath.logX(this.model.normalizedFactor, 10);
      for(i = 1 ; i <= p10 ; i++){
        this.select.push(String(10 ** i));
      }

      digits = String(this.model.normalizedFactor).split('');
      for(i = 0 ; i < digits.length ; i++){
        c = Compartment.createDigit(digits[i]);
        this.target.push(c);
        division.normalize.push(c);
      }

      const enterPowerOfTen = division.culture.getString('LongDivisionEnglish.enterPowerOfTen');

      this.setDescription(
        division.culture.getString(
          'LongDivisionEnglish.commonFactor',
          division.culture.formatNumber(this.model.originalDividend),
          division.culture.formatNumber(this.model.originalDivisor),
          division.culture.formatNumber(this.model.normalizedDividend),
          division.culture.formatNumber(this.model.normalizedDivisor)) +
        (this.model.interactive ? `\n\n${enterPowerOfTen}` : ''));
    }else{
      // 1. Dividend
      const o:Compartment[] = [];
      o.push(null); // give space to the first minus sign in case the first multiply aligns at the left

      const diffDecimals:number = this.model.quotientDecimals - XNumber.decimals( this.model.normalizedDividend );
      digits = division.dividend.toString().split('');

      if( diffDecimals > 0 ) {
        if( digits.indexOf( '.' ) === -1 ) {
          digits.push( '.' );
        }
        for(  i = 0; i < diffDecimals; i++ ) {
          digits.push( '' );
        }
      }

      for(i = 0 ; i < digits.length ; i++){
        if(digits[i] === ''){
          c = Compartment.createInvisibleZero();
        }else if(digits[i] === '.') {
          c = division.decimalSeparator();
        } else {
          c = Compartment.createDigit(digits[i]);
        }

        c.frame = new FrameBorders();
        c.frame.top = true;
        c.frame.left = i === 0;
        o.push(c);
        this.related.push( c );
      }
      division.steps.push(o);

      // Nor
      while(Number(division.rest.join('')) < this.model.normalizedDivisor && division.dividend.hasDigitLeft() ){
        if( division.dividend.isSeparator() ) {
          break;
        } else {
          division.rest.push(division.dividend.getNextDigit());
          division.dividend.shift();
        }
      }

      // 2. Divisor
      digits = String(this.model.normalizedDivisor).split('');
      for(i = 0 ; i < digits.length ; i++){
        c = Compartment.createDigit(digits[i]);
        division.divisor.push(c);
        this.related.push( c );
      }

      this.setDescription(
        division.culture.getString(
          'LongDivisionEnglish.initLabel',
          division.culture.formatNumber(this.model.originalDividend),
          division.culture.formatNumber(this.model.originalDivisor),
          division.culture.formatNumber(this.model.normalizedDividend),
          division.culture.formatNumber(this.model.normalizedDivisor)));
    }
  }

  public next():AbstractStep {
    if( this.model.normalizedDivisor > this.model.originalDivisor ) {
      return new DivWriteNewDividendEnglish(this.division);
    }
    const _rest:number = Number(this.division.rest.join(''));
    if(_rest > this.model.normalizedDivisor
      || ( this.model.decimals === -1 && this.division.rest.length > 0 )
      || ( this.division.steps.length === 1 )){
      return new DivQuotientEnglish(this.division, _rest, this.model.normalizedDivisor);
    }
    if( ( this.division.rest.length === 0 || _rest === 0 ) ) {
      return new DivEndEnglish(this.division, 'exact' );
    }
    if(this.model.decimals === -1 && this.division.rest.length > 0 && this.division.steps.length !== 1 ){
      return new DivRestEnglish(this.division);
    }
    return new DivQuotientEnglish(this.division, _rest, this.model.normalizedDivisor);

  }

}
