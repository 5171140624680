import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WNotANumber } from '../../elements/tokens/WNotANumber';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

export class NotANumber extends FunctionElement {

  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length > 0){
      return args.expectingArguments(0, 0);
    }
    return WNotANumber.getInstance();
  }

}
