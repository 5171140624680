import React from 'react';
import styled from 'styled-components';
import { Button, PlusIcon, Tooltip } from '@scolab/publisher-ui-kit';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { LocaleProvider } from '../../LocaleProvider';
import { locales } from '../../../autoGenerate/locale';
import { AddAlignmentModal } from '../addAlignmentModal/AddAlignmentModal';
import { AlignmentType } from './AlignmentModel';

interface ISectionTitleProps extends WrappedComponentProps {
  Label?: React.ReactNode;
  type: AlignmentType;
  className?: string;
}

const SectionTitleComponent: React.FC<ISectionTitleProps> = (props) => {
  const {
    Label,
    type,
    className,
    intl
  } = props;
  const [isOpen, setIsOpen] = React.useState(false);
  const onToggle = () => setIsOpen((prevState) => !prevState);
  return (
    <LocaleProvider locales={locales}>
      <Container>
        <LabelContainer>
          {Label}
        </LabelContainer>
        <Tooltip
          title={intl.formatMessage({
            id: 'alignmentAndStandard.addStandard'
          })}
        >
          <Button
            primary
            className={className}
            onClick={onToggle}
          >
            <PlusIcon />
            <FormattedMessage id='alignmentAndStandard.standard' />
          </Button>
        </Tooltip>
        <AddAlignmentModal
          isOpen={isOpen}
          onClose={onToggle}
          type={type}
        />
      </Container>
    </LocaleProvider>
  )
};

export const SectionTitle = injectIntl(SectionTitleComponent);

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
`;

const LabelContainer = styled.div`
  flex: 1;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  color: ${(props) => props.theme.colorset.grey10};
`;
