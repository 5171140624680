import { XMath } from '../../../core/XMath';
import { BaseNumberFormatter } from '../../../elements/formats/BaseNumberFormatter';
import { CultureInfo } from '../../../localization/CultureInfo';
import { TimeFormatUtil } from '../../../elements/formats/time/TimeFormatUtil';

/**
 *
 */
export class HoursFormatter extends BaseNumberFormatter {

  /**
   *
   */
  constructor(culture:CultureInfo){
    super(culture);
  }

  /**
   *
   */
  public toLocaleString(value:number):string{
    if(!this.willFormat(value)){
      return this.culture.formatNumber(value);
    }

    let time: string;
    if (this.culture.configuration.twelveHoursClock) {
      time =
        this.culture.getString(
          'Time.hoursformat12',
          TimeFormatUtil.hours12hourclock(value),
          this.culture.getString(value < 12 ? 'Time.amLabel' : 'Time.pmLabel'));
    } else {
      time = this.culture.getString('Time.hoursformat24', value);
    }

    // Replace all spaces by non-breaking spaces
    time = time.split(' ').join('\u00A0');
    return time;
  }

  /**
   *
   */
  public toSpeakText(value:number):string{
    return this.willFormat(value) ?
        null :
        this.culture.numberFormatter.toSpeakText(value);
  }

  /**
   *
   */
  public willFormat(value:number):boolean{
    return XMath.isInteger(value) && value >= 0 && value < 24;
  }

}
