import React from 'react';
import {
  Route,
  RouteComponentProps,
  withRouter,
  Switch,
} from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { PageNotFound, Spinner } from '@scolab/publisher-ui-kit';
import { inject } from '@scolab/common-editor';
import { observer } from 'mobx-react';
import { Routes } from '../config/routes';
import { GetAccessToken } from '../pages/GetAccessToken';
import { StartSessionFromAccessToken } from '../pages/StartSessionFromAccessToken';
import FeatureFlagsMediator from '../mediators/FeatureFlagsMediator';

@observer
class LoginAppComponent extends React.Component<RouteComponentProps> {

  @inject(FeatureFlagsMediator)
  private flags: FeatureFlagsMediator;

  public render(): React.ReactNode {
    if (!this.flags.isLoaded) {
      return (<Spinner />);
    }

    return (
      <HelmetProvider context={{}}>
        <Helmet>
          <title>
            Authentification
          </title>
        </Helmet>
        <Switch>
          <Route exact path={Routes.Login}>
            <GetAccessToken />
          </Route>
          <Route exact path={Routes.LoginCallback}>
            <StartSessionFromAccessToken />
          </Route>
          <Route component={PageNotFound} />
        </Switch>
      </HelmetProvider>
    );
  }
}

export const LoginApp = withRouter(LoginAppComponent);
