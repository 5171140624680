import { FxQuadratic } from '../../../elements/effofeks/FxQuadratic';
import { WQuadratic } from '../../../elements/tokens/WQuadratic';
import { IFunctionAttributes } from '../../../elements/markers/IFunctionAttributes';
import { Zeros } from '../../../elements/functions/Zeros';
import { FunctionStyles } from '../../../elements/functions/adapters/FunctionStyles';
import { EvaluableAdapter } from '../../../elements/functions/adapters/EvaluableAdapter';

/**
 *
 */
export class QuadraticAdapter extends EvaluableAdapter implements IFunctionAttributes {

  private _quad:WQuadratic;

  constructor(quad:WQuadratic) {

    super(new FxQuadratic(quad));
    this._quad = quad;
  }

  public get continuous():number{return 1;}

  public get type():string{return FunctionStyles.UNKNOWN;}

  public get attributes():IFunctionAttributes{return this;}

  public getZeros():Zeros{
    const z:Zeros = new Zeros();
    z.list = [];
    for(let i:number = 0 ; i < this._quad.xintercept.length ; i++){
      z.list.push(this._quad.xintercept[i]);
    }
    return z;
  }

  public getHashCode(): string {
    return this._quad.hashCode();
  }

}
