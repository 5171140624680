export const scrollToElement = (scrollContainer: Element, scrollTarget: Element) => {
  if (scrollContainer && scrollTarget) {
    const scrollContainerBox = scrollContainer.getBoundingClientRect();
    const elementBox = scrollTarget.getBoundingClientRect();
    const bottomDiff = elementBox.bottom - scrollContainerBox.bottom;
    if (bottomDiff > 0) {
      scrollContainer.scrollTo(0, scrollContainer.scrollTop + bottomDiff);
      return;
    }
    const topDiff = elementBox.top - scrollContainerBox.top;
    if (topDiff < 0) {
      scrollContainer.scrollTo(0, scrollContainer.scrollTop + topDiff);
      
    }
  }
};
