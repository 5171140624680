import { Point } from '../../../js/geom/Point';

import { Match } from '../../core/Match';
import { MathError } from '../../core/MathError';
import { RealElement } from '../../elements/abstract/RealElement';
import { ChessBoardFormatter } from '../../elements/formats/chess/ChessBoardFormatter';
import { ChessPieceFormatter } from '../../elements/formats/chess/ChessPieceFormatter';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { WNumber } from '../../elements/tokens/WNumber';
import { CultureInfo } from '../../localization/CultureInfo';
import { MoveModel } from '../../funcs/chess/MoveModel';
import { PiecePositionModel } from '../../funcs/chess/PiecePositionModel';
import { Pieces } from '../../funcs/chess/Pieces';

/**
 *
 */
export class ChessBoardImpl {

  /**
   *
   */
  private _boardFormat:ChessBoardFormatter;

  public get boardFormat():ChessBoardFormatter{
    return this._boardFormat;
  }

  /**
   *
   */
  private _pieceFormat:ChessPieceFormatter;

  public get pieceFormat():ChessPieceFormatter{
    return this._pieceFormat;
  }

  /**
   *
   */
  private _emptyTile:WNumber;

  public get emptyTile():WNumber{
    return this._emptyTile;
  }

  /**
   *
   */
  constructor(culture:CultureInfo) {
    this._boardFormat = new ChessBoardFormatter(culture);
    this._pieceFormat = new ChessPieceFormatter(culture);
    this._emptyTile = new WNumber(0, 1, false, this._pieceFormat);
  }

  /**
   *
   */
  public createEmpty():WMatrix{
    const o:RealElement[] = [];
    for(let i:number = 0 ; i < 64 ; i++){
      o.push(this.emptyTile);
    }
    return new WMatrix(o, 8, this.boardFormat);
  }

  /**
   *
   */
  public createInitial():WMatrix{
    const o:RealElement[] = [];
    o.push(new WNumber(Pieces.BLACK_ROOK, 1, false, this.pieceFormat));
    o.push(new WNumber(Pieces.BLACK_KNIGHT, 1, false, this.pieceFormat));
    o.push(new WNumber(Pieces.BLACK_BISHOP, 1, false, this.pieceFormat));
    o.push(new WNumber(Pieces.BLACK_QUEEN, 1, false, this.pieceFormat));
    o.push(new WNumber(Pieces.BLACK_KING, 1, false, this.pieceFormat));
    o.push(new WNumber(Pieces.BLACK_BISHOP, 1, false, this.pieceFormat));
    o.push(new WNumber(Pieces.BLACK_KNIGHT, 1, false, this.pieceFormat));
    o.push(new WNumber(Pieces.BLACK_ROOK, 1, false, this.pieceFormat));

    let i:number;
    for(i = 0 ; i < 8 ; i++){
      o.push(new WNumber(Pieces.BLACK_PAWN, 1, false, this.pieceFormat));
    }

    for(i = 0 ; i < 32 ; i++){
      o.push(this.emptyTile);
    }

    for(i = 0 ; i < 8 ; i++){
      o.push(new WNumber(Pieces.WHITE_PAWN, 1, false, this.pieceFormat));
    }

    o.push(new WNumber(Pieces.WHITE_ROOK, 1, false, this.pieceFormat));
    o.push(new WNumber(Pieces.WHITE_KNIGHT, 1, false, this.pieceFormat));
    o.push(new WNumber(Pieces.WHITE_BISHOP, 1, false, this.pieceFormat));
    o.push(new WNumber(Pieces.WHITE_QUEEN, 1, false, this.pieceFormat));
    o.push(new WNumber(Pieces.WHITE_KING, 1, false, this.pieceFormat));
    o.push(new WNumber(Pieces.WHITE_BISHOP, 1, false, this.pieceFormat));
    o.push(new WNumber(Pieces.WHITE_KNIGHT, 1, false, this.pieceFormat));
    o.push(new WNumber(Pieces.WHITE_ROOK, 1, false, this.pieceFormat));

    return new WMatrix(o, 8, this.boardFormat);
  }

  /**
   * Returns {x: col, y: row}
   */
  public getPosition(notation:string):Point {
    const r:RegExp = new RegExp('^([a-hA-H])([1-8])$');
    const z:Match = Match.tryParse(r.exec(notation));
    if(z){
      return new Point(String(z.groups[1]).toLowerCase().charCodeAt(0) - 97, 8 - Number(z.groups[2]));
    }
    return null;
  }

  /**
   *
   */
  public getPiecePosition(notation:string):PiecePositionModel{
    if(notation.length !== 3){
      return null;
    }

    const piece:WNumber = new WNumber(Pieces.parse(notation.charAt(0)), 1, false, this.pieceFormat);
    const position:Point = this.getPosition(notation.substring(1));

    if(piece && position){
      return new PiecePositionModel(piece, position);
    }

    return null;
  }

  /**
   *
   */
  public getIndex(position:Point):number{
    return position.y * 8 + position.x;
  }

  /**
   *
   */
  public getMove(notationArg:string):MoveModel{
    const notation = notationArg.replace(/\s/g, '');
    const r:RegExp = new RegExp('^([a-h][1-8])-([a-h][1-8])$');
    const z:Match = Match.tryParse(r.exec(notation));
    if(z){
      return new MoveModel(
        this.getPosition(String(z.groups[1])),
        this.getPosition(String(z.groups[2])));
    }
    return null;
  }

  /**
   *
   */
  public move(board:WMatrix, notations:string[]):WMatrix{
    this.validateBoard(board);

    const copy:RealElement[] = board.values.concat();

    for(let i:number = 0 ; i < notations.length ; i++){
      const notation:string = notations[i];
      const move:MoveModel = this.getMove(notation);
      if(move){
        copy[this.getIndex(move.destination)] =
          copy[this.getIndex(move.origin)];

        copy[this.getIndex(move.origin)] = this.emptyTile;
      }
    }

    return new WMatrix(copy, 8, this.boardFormat);
  }

  /**
   *
   */
  public place(board:WMatrix, notations:string[]):WMatrix{
    this.validateBoard(board);

    const copy:RealElement[] = board.values.concat();

    for(let i:number = 0 ; i < notations.length ; i++){
      const notation:string = notations[i];
      const o:PiecePositionModel = this.getPiecePosition(notation);
      if(o){
        copy[o.position.y * 8 + o.position.x] = o.piece;
      }
    }

    return new WMatrix(copy, 8, this.boardFormat);
  }

  /**
   *
   */
  public remove(board:WMatrix, positions:string[]):WMatrix{
    this.validateBoard(board);

    const copy:RealElement[] = board.values.concat();

    for(let i:number = 0 ; i < positions.length ; i++){
      const position:string = positions[i];
      const p:Point = this.getPosition(position);
      if(p){
        copy[p.y * 8 + p.x] = this.emptyTile;
      }
    }

    return new WMatrix(copy, 8, this.boardFormat);
  }

  /**
   *
   */
  private validateBoard(board:WMatrix):void{
    if(board.rows !== 8 || board.columns !== 8){
      throw new MathError('Chess board must be 8x8');
    }
  }

}
