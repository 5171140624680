import React, {} from 'react';
import styled from 'styled-components';

interface IResizableProps {
  initialHeight: number;
  initialWidth: number;
  minimumHeight: number;
  minimumWidth: number;
  className?: string;
  onRef?: (element: HTMLDivElement) => void;
  onFinishResizing?: (width: number, height: number) => void;
}

export class Resizable extends React.Component<IResizableProps> {
  private containerDomElement: HTMLDivElement;

  private containerTopLeft: { x: number; y: number; };

  private width: number;

  private height: number;

  constructor(props: IResizableProps) {
    super(props);
    this.containerDomElement = null;
    this.containerTopLeft = {
      x: 0,
      y: 0,
    };
    this.width = 0;
    this.height = 0;
  }

  public componentWillUnmount(): void {
    this.removeDocumentListeners();
  }

  public render(): React.ReactNode {
    const {
      children,
      initialHeight,
      initialWidth,
      className,
    } = this.props;
    return (
      <Container
        ref={this.onContainerRef}
        initialWidth={initialWidth}
        initialHeight={initialHeight}
        className={className}
      >
        {children}
        <DragHandle
          onMouseDown={this.onMouseDown}
        />
      </Container>
    );
  }

  private onContainerRef = (element: HTMLDivElement) => {
    this.containerDomElement = element;
    this.props.onRef?.(element);
  }

  private onMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();

    const containerBoundingBox = this.containerDomElement.getBoundingClientRect();
    this.containerTopLeft = {
      x: containerBoundingBox.x,
      y: containerBoundingBox.y,
    };
    document.addEventListener('mousemove', this.onMouseMove);
    document.addEventListener('mouseup', this.onMouseUp);
  }

  private onMouseMove = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    const width = event.clientX - this.containerTopLeft.x;
    const height = event.clientY - this.containerTopLeft.y;
    this.setContainerSize(width, height);
  }

  private onMouseUp = (event: MouseEvent) => {
    this.props.onFinishResizing?.(this.width, this.height);
    this.removeDocumentListeners();
  }

  private removeDocumentListeners = () => {
    document.removeEventListener('mousemove', this.onMouseMove);
    document.removeEventListener('mouseup', this.onMouseUp);
  }

  private setContainerSize = (width: number, height: number) => {
    this.width = width;
    this.height = height;
    this.containerDomElement.style.width = `${Math.max(width, this.props.minimumWidth)}px`;
    this.containerDomElement.style.height = `${Math.max(height, this.props.minimumHeight)}px`;
  }
}

interface IContainer {
  initialWidth: number;
  initialHeight: number;
}

const Container = styled.div<IContainer>`
  position: relative;
  width: ${props => props.initialWidth}px;
  height: ${props => props.initialHeight}px;
`;

const DragHandle = styled.div`
  background: linear-gradient(135deg, #ddd 25%, #eee 25%, #eee 50%, #ddd 50%, #ddd 75%, #eee 75%);
  background-size: 5px 5px;
  border: 1px solid #dedede;
  position: absolute;
  right: 0;
  bottom: 0;
  height: 15px;
  width: 15px;
  cursor: se-resize !important;
`;
