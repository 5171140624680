import { RealElement } from '../../../elements/abstract/RealElement';
import { WRational } from '../../../elements/tokens/WRational';
import { MToken } from '../../../expr/conversion/models/MToken';

/**
 *
 */
export class MParam extends MToken {

  /**
   * One of the values defined by ParamTypes
   */
  public type:string;

  /**
   *
   */
  public name:string;

  /**
   * Actual value for that parameter
   */
  public value:RealElement;

  /**
   * Value of the parameter if the input is empty.
   */
  public emptyValue:RealElement;

  /**
   * Value of the parameter if the input only contains the minus sign.
   */
  public minusValue:RealElement;

  /**
   *
   */
  constructor(
      type:string,
      name:string,
      value:RealElement,
      emptyValue:RealElement,
      minusValue:RealElement){
    super();
    this.type = type;
    this.name = name;
    this.value = value;
    this.emptyValue = emptyValue;
    this.minusValue = minusValue;
  }

  public clearEmpty():MParam{return new MParam(this.type, this.name, this.value, null, this.minusValue);}

  public clearMinus():MParam{return new MParam(this.type, this.name, this.value, this.emptyValue, null);}

  public setEmpty(emptyValue:RealElement):MParam{return new MParam(this.type, this.name, this.value, emptyValue, this.minusValue);}

  public setValue(newValue:RealElement):MParam{return new MParam(this.type, this.name, newValue, this.emptyValue, this.minusValue);}

  public get neutral():boolean{
    if( this.value == null || this.emptyValue == null ) {
      return false;
    }
    return this.value.toNumber() === this.emptyValue.toNumber();
  }

  public static realString(
      value:RealElement,
      undefinedEmptyString:boolean = false):string{

    if(value instanceof WRational){
      return `${(<WRational>value).numerator.toString()}/${(<WRational>value).denominator.toString()}`;
    }

    if(value){
      return value.toNumber().toString();
    }
    return undefinedEmptyString ? '' : 'NaN';
  }

  public toString():string{
    return '{type: ' + this.doubleQuotes(this.type) +
        ', name: ' + this.doubleQuotes(this.name) +
        ', value: ' + this.doubleQuotes(MParam.realString(this.value)) +
        ', emptyValue: ' + this.doubleQuotes(MParam.realString(this.emptyValue)) +
        ', minusValue: ' + this.doubleQuotes(MParam.realString(this.minusValue)) + '}';
  }

  private doubleQuotes(value:string):string{
    return 	String.fromCharCode(34) +
        value +
        String.fromCharCode(34);
  }

}
