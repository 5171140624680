import { XString } from '../../core/XString';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { TokenElement } from '../../elements/abstract/TokenElement';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';

/**
 *
 */
export class WInfinity extends TokenElement {

  public static NEGATIVE:WInfinity = new WInfinity(true);

  public static POSITIVE:WInfinity = new WInfinity(false);

  private _negative:boolean;

  public get negative():boolean{return this._negative;}

  constructor(negative:boolean = false){
    super();
    this._negative = negative;
  }

  public toString():string{
    return XString.substitute(
      '{0}{1}∞',
      this.negative ? '−' : '+',
      XString.WORD_JOINER);
  }

  public equalsTo(value:ContentElement):boolean{
    if(value instanceof WInfinity){
      return (<WInfinity>value ).negative === this.negative;
    }
    return false;
  }

  public writeTo(exporter:IMarkupExporter = null):boolean{
    if(exporter){
      exporter.writer.appendText(this.toString());
    }
    return true;
  }

  public toText(strict:boolean):string{
    return this.toString();
  }

  public getType():string {
    return 'infinity';
  }
}
