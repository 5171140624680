import { MathError } from '../../core/MathError';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

export class ListTray extends FunctionElement {

  /**
   * Items are not converted and items of same
   * types are grouped into the same columns.
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    throw new MathError('Not implemented');
  }

}
