import React from 'react';
import styled from 'styled-components';
import { useSelected, useSlate } from '../../TypedSlate';
import { ImageElementPopover } from './ImageElementPopover';
import { ICustomRenderElementProps } from '../../models';
import { IImageElement } from '../../models/elements';
import { findPath } from '../../utils';

interface IImageElementProps {
  srcInputPlaceholder: string;
  altInputPlaceholder: string;
}

export const ImageElement: React.FC<ICustomRenderElementProps<IImageElement> & IImageElementProps> = (props) => {
  const editor = useSlate();
  const selected = useSelected();
  const anchorRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  const onOpen = () => { setOpen(true); };
  const onClose = () => { setOpen(false); };

  const onMouseDown = () => {
    editor.selection = {
      focus: {
        path: findPath(editor.children, props.element),
        offset: 0,
      },
      anchor: {
        path: findPath(editor.children, props.element),
        offset: 0,
      },
    };
    onOpen();
  };

  return (
    <>
      <span
        {...props.attributes}
      >
        <Img
          ref={anchorRef}
          src={props.element.src}
          width={props.element.width}
          height={props.element.height}
          onMouseDown={onMouseDown}
          selected={selected}
        />
        {props.children}
      </span>
      <ImageElementPopover
        open={open}
        anchorEl={anchorRef.current}
        handleClose={onClose}
        element={props.element}
        editor={editor}
        srcInputPlaceholder={props.srcInputPlaceholder}
        altInputPlaceholder={props.altInputPlaceholder}
      />
    </>
  );
};

interface IImg {
  selected: boolean;
  width: number;
  height: number;
}

const Img = styled.img<IImg>`
  height: ${props => props.width ? `${props.width}px` : null};
  width: ${props => props.height ? `${props.height}px` : null};
  cursor: pointer;
  box-shadow: ${props => props.selected ? '0 0 0 3px #B4D5FF' : 'none'};
`;
