import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { ListElement } from '../../elements/abstract/ListElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WBoolean } from '../../elements/tokens/WBoolean';
import { WList } from '../../elements/tokens/WList';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Teste si une liste contient un élément.
 */
export class Contains extends FunctionElement {
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 2) {
      return args.expectingArguments(2, 2);
    }

    if (args.getReals(0) && args.getReal(1)) {
      return this.real(args.getReals(0), args.getReal(1));
    }

    if (args.getReals(0) && args.getReals(1)) {
      return this.reals(args.getReals(0), args.getReals(1));
    }

    const list: ListElement = args.getList(0);
    if (!list) {
      return null;
    }

    const element: ContentElement = args.getContentElement(1);
    if (!element) {
      return null;
    }

    if (element instanceof ListElement) {
      for (const item of element.items) {
        if (!list.acceptElement(item) || !list.contains(item)) {
          return WBoolean.FALSE;
        }
      }
      return WBoolean.TRUE;
    }
    if (list.acceptElement(element) && list.contains(element)) {
      return WBoolean.TRUE;
    }

    return WBoolean.FALSE;
  }

  /**
   * Returns true if a contains b.
   */
  private real(a: WList, b: RealElement): WBoolean {
    return WBoolean.parse(a.toNumbersV().indexOf(b.toNumber()) !== -1);
  }

  /**
   * Returns true if a contains all members of b.
   * Retourne vrai si tous les éléments de <i>b</i> sont dans <i>a</i>. L'ordre n'a pas d'importance.
   */
  private reals(a: WList, b: WList): WBoolean {
    const reals = a.toNumbersV();
    for (let i: number = 0; i < b.count; i++) {
      if (reals.indexOf(b.getTypedItemAt(i).toNumber()) === -1) {
        return WBoolean.FALSE;
      }
    }
    return WBoolean.TRUE;
  }
}
