import { Point } from '../../../js/geom/Point';
import { IPrng } from '../../core/prng/IPrng';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RandomPointGenerator } from '../../elements/factories/RandomPointGenerator';
import { WPoint } from '../../elements/tokens/WPoint';
import { WPolygon } from '../../elements/tokens/WPolygon';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Quadrilatère aléatoire.
 */
export class RandomQuadrilateral extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 2){
      return args.expectingArguments(2, 2);
    }
    if (args.getPoint(0) && args.getPoint(1)) {
      return this.fromPoints(args.getPoint(0),args.getPoint(1), args.prng);
    }
    return null;
  }

  /**
   * Retourne un quadrilatère dont les sommets sont des coordonnées
   * entières qui se trouvent dans une région rectangulaire définie par deux points.
   */
  private fromPoints(a:WPoint, b:WPoint, prng:IPrng):WPolygon{
    const v:Point[] = RandomQuadrilateral.createQuadrilateral(a.toPoint(), b.toPoint(), prng);
    return v ? new WPolygon(v) : null;
  }

  /**
   *
   */
  public static createQuadrilateral(aArg:Point, bArg:Point, prng:IPrng):Point[]{
    let a:Point = aArg;
    let b:Point = bArg;
    const rpg:RandomPointGenerator = new RandomPointGenerator(a, b, 2, prng);
    let c:Point;
    let d:Point;

    do{
      a = rpg.next1();
      b = rpg.next2();
      c = rpg.next3();
      d = rpg.next4();
    }while(!WPolygon.validatePolygon([a, b, c, d]));

    return (<Point[]>[a, b, c, d]);
  }

}
