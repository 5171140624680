import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WPolygon } from '../../elements/tokens/WPolygon';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { TriangleSAS } from '../../funcs/ctor/TriangleSAS';

/**
 * Constructeur de triangle CCC.
 */
export class TriangleSSS extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 3){
      return args.expectingArguments(3, 3);
    }
    if (args.getReal(0) && args.getReal(1) && args.getReal(2)) {
      return this.sss(args.getReal(0), args.getReal(1), args.getReal(2));
    }
    return null;
  }

  /**
   *
   */
  private sss(sideA:RealElement, sideB:RealElement, sideC:RealElement):WPolygon{
    const a:number = sideA.toNumber();
    const b:number = sideB.toNumber();
    const c:number = sideC.toNumber();

    if(a + b <= c ||
      b + c <= a ||
      c + a <= b){

      return null;
    }

    // a² = b²  + c² - 2bc cos A
    const A:number = Math.acos((a * a - b * b - c * c) / (-2 * b * c));
    return TriangleSAS.createTriangle(b, A, c);
  }

}
