import { IDictionary } from '../../../js/utils/IDictionary';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { ListElement } from '../../elements/abstract/ListElement';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { WList } from '../../elements/tokens/WList';
import { WListOfString } from '../../elements/tokens/WListOfString';

/**
 * Returns the list of distinct elements from a list.
 */
export class Distinct extends FunctionElement {
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length === 2) {
      const reals = [args.getReals(0), args.getReals(1)];
      if (reals[0] && reals[1]) {
        const list1 = reals[0].transform(this.getListUniqueIndices(reals[0]));
        const list2 = reals[1].transform(this.getListUniqueIndices(reals[1]));
        return new WList(list1.items.concat(list2.items), list1.formatter);
      }

      const strings = [args.getStrings(0), args.getStrings(1)];
      if (strings[0] && strings[1]) {
        const list1 = strings[0].transform(this.getListUniqueIndices(strings[0]));
        const list2 = strings[1].transform(this.getListUniqueIndices(strings[1]));
        return new WListOfString(list1.items.concat(list2.items), list1.formatter);
      }
    }

    if (args.length !== 1) {
      return args.expectingArguments(1, 2);
    }

    const list = args.getList(0);
    if (list) {
      const indices = this.getListUniqueIndices(list);
      switch (indices.length) {
        case 0:
          return null;
        case 1:
          return list.getItemAt(indices[0]);
        default:
          return list.transform(indices);
      }
    }

    return args.getContentElement(0);
  }

  /**
   * get a list of first index for every value
   * @param list
   */
  private getListUniqueIndices(list: ListElement): number[] {
    const indices: number[] = [];
    const hashTable: IDictionary = {};
    for (let i = 0; i < list.count; i++) {
      const hashCode = list.getItemAt(i).hashCode();
      if (!hashCode) {
        throw new Error('Hashcode not implemented.');
      }
      if (!hashTable.hasOwnProperty(hashCode)) {
        hashTable[hashCode] = true;
        indices.push(i);
      }
    }
    return indices;
  }
}
