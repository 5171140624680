import { ContentElement } from '../../elements/abstract/ContentElement';
import { ElementCodes } from '../../elements/abstract/ElementCodes';
import { OperatorElement } from '../../elements/abstract/OperatorElement';
import { TokensList } from '../../elements/factories/TokensList';
import { WFiniteSet } from '../../elements/tokens/WFiniteSet';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';

/**
 * ⊖
 * U+2296
 *
 * ou
 *
 * ∆
 * U+2206
 *
 * Différence symétrique d'ensembles
 */
export class SymmetricDifference extends OperatorElement {

  /**
   *
   */
  public toString():string{return '∆';}

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 2){
      return args.expectingArguments(2, 2);
    }

    const A:WFiniteSet = args.getFiniteSet(0);
    const B:WFiniteSet = args.getFiniteSet(1);

    if(A && B){
      return this.symmetricDifference(A, B, args.env);
    }

    return null;
  }

  /**
   *
   */
  private symmetricDifference(
      a:WFiniteSet,
      b:WFiniteSet,
      env:Environment):WFiniteSet{

    const dAB:TokensList = a.toTokens().getDifference(b.toTokens());
    const dBA:TokensList = b.toTokens().getDifference(a.toTokens());

    return env.createNormalizedSet(dAB.getUnion(dBA).getItems());
  }

  /**
   *
   */
  public getElementCode():string{
    return ElementCodes.OP_SETS_SYMMETRIC_DIFFERENCE;
  }

}
