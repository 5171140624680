import { ContentElement } from '../elements/abstract/ContentElement';
import { Node } from '../elements/abstract/Node';
import { WBoolean } from '../elements/tokens/WBoolean';
import { IWriter } from '../expr/conversion/writers/IWriter';
import { InputCapabilities } from './InputCapabilities';
import { BaseCorrector } from './BaseCorrector';

/**
 *
 */
export class CBoolean extends BaseCorrector {

  public parse(value:string):Node{
    const valueB:WBoolean = WBoolean.parse(value === '1');
    const node:Node = new Node(valueB);
    node.userData = valueB.toBoolean() ? 'True()' : 'False()';
    return node;
  }

  public correct(
      value:string,
      target:ContentElement,
      ...targets:any[]):boolean{

    const value2:boolean = value === '1';
    return value2 === (<WBoolean>target ).toBoolean();
  }

  public get inputCapabilities():InputCapabilities{
    const i:InputCapabilities = new InputCapabilities();
    i.binary = true;
    return i;
  }

  public writeTo(
      w:IWriter,
      target:ContentElement,
      ...targets:any[]):void{

    w.writeRaw((<WBoolean>target ).toBoolean() ? '1' : '0');
  }

}
