import { MathError } from '../../core/MathError';
import { XRound } from '../../core/XRound';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { ListElement } from '../../elements/abstract/ListElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { BaseIntervalFormatter } from '../../elements/formats/BaseIntervalFormatter';
import { IntervalFormatter } from '../../elements/formats/intervals/IntervalFormatter';
import { IFunctionAdapter } from '../../elements/functions/adapters/IFunctionAdapter';
import { IntervalClosure } from '../../elements/models/IntervalClosure';
import { WFunctionGraph } from '../../elements/tokens/WFunctionGraph';
import { WInterval } from '../../elements/tokens/WInterval';
import { WRange } from '../../elements/tokens/WRange';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';
import { Plot } from '../../funcs/plotting/Plot';

/**
 *
 */
export class RandomScatterData extends FunctionElement {

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length < 4 || args.length > 5){
      return args.expectingArguments(4, 5);
    }

    if(args.length === 4){
      if(args.getContentElement(0) && args.getInterval(1) && args.getReal(2) && args.getReal(3)){
        return this.unit(
          args.getContentElement(0),
          args.getInterval(1),
          args.getReal(2),
          args.getReal(3),
          args.env);
      }
    }else if(args.length === 5){
      if(args.getContentElement(0) && args.getRange(1) && args.getInterval(2) && args.getInterval(3) && args.getReal(4)){
        return this.advanced(
          args.getContentElement(0),
          args.getRange(1),
          args.getInterval(2),
          args.getInterval(3),
          args.getReal(4),
          args.env);
      }
    }

    return null;
  }

  /**
   *
   */
  private unit(
      trend:ContentElement,
      domain:WInterval,
      density:RealElement,
      delta:RealElement,
      env:Environment):ListElement{

    if(!density.isWholeNumber()){
      return null;
    }

    const trend2:WFunctionGraph = Plot.tryParse(trend, null, env);

    if(!trend2){
      throw new MathError('Invalid trend');
    }

    if(!domain.isFinite){
      throw new MathError('Invalid domain');
    }

    if(delta.toNumber() < 0){
      throw new MathError('Invalid distance');
    }

    const intervalFormat:BaseIntervalFormatter = new IntervalFormatter(env.culture);

    return this.create(
      trend2.adapter,
      new WRange(
        Math.ceil(Math.min(domain.lBoundN, domain.rBoundN)),
        Math.floor(Math.max(domain.lBoundN, domain.rBoundN)),
        1,
        env.culture.numberFormatter),
      new WInterval(IntervalClosure.CLOSED, density, density, intervalFormat),
      new WInterval(IntervalClosure.CLOSED, delta.toOpposite(), delta, intervalFormat),
      1,
      env);
  }

  /**
   *
   */
  private advanced(
      trend:ContentElement,
      domain:WRange,
      density:WInterval,
      delta:WInterval,
      precision:RealElement,
      env:Environment):ListElement{

    if(!precision.isWholeNumber()){
      return null;
    }

    const trend2:WFunctionGraph = Plot.tryParse(trend, null, env);

    if(!trend2){
      throw new MathError('Invalid trend');
    }

    if(!density.isFinite){
      throw new MathError('Invalid density');
    }

    if(density.rBoundN < 0){
      throw new MathError('Invalid density');
    }

    if(!delta.isFinite){
      throw new MathError('Invalid delta');
    }

    return this.create(
      trend2.adapter,
      domain,
      density,
      delta,
      precision.toNumber(),
      env);
  }

  /**
   *
   */
  private create(
      trend:IFunctionAdapter,
      domain:WRange,
      density:WInterval,
      delta:WInterval,
      precision:number,
      env:Environment):ListElement{

    const o:ContentElement[] = [];
    let n:number;

    for(n = domain.minimum ; n < domain.maximum ; n += domain.step){
      const _density:number = Math.round(env.prng.randomNumber(density.lBoundN, density.rBoundN));

      for(let d:number = 0 ; d < _density ; d++){
        let x:number = env.prng.randomNumber(n, Math.min(n + domain.step, domain.maximum));
        let y:number = trend.map(x);

        if(isNaN(y)){
          continue;
        }

        y += env.prng.randomNumber(delta.lBoundN, delta.rBoundN);

        x = XRound.halfAway(x, precision);
        y = XRound.halfAway(y, precision);

        o.push(env.culture.parseCoor(x, y));
      }
    }

    return env.culture.listFactory.createList(o);
  }

}
