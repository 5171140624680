import {
  ArgumentsObject,
  ContentElement,
  Environment,
  FormatProvider,
  HtmlExporter,
  IDictionary,
  IntervalClosure,
  MarkupExporter,
  MConstruct,
  MmlWriter,
  MmlXmlProxy,
  MParam,
  RealElement,
  TokenElement,
  Variable,
  WInterval,
  WList,
  WRational,
} from '../../core';

import { IVector2d } from '../models/IVector2d';
import { IIntervalModel } from '../models/IIntervalModel';
import { ILabeledValue } from '../models/ILabeledValue';
import { ILabeledValues } from '../models/ILabeledValues';
import { IMatrixModel } from '../models/IMatrixModel';
import { IRangeModel } from '../models/IRangeModel';
import { ILabeledValueWithLabels } from '../models/ILabeledValueWithLabels';
import { IFunctionGraph } from '../models/IFunctionGraph';
import { IEndPoints } from '../models/IEndPoints';
import { IHalfPlane } from '../models/IHalfPlane';
import { ILine } from '../models/ILine';
import { IPolygon } from '../models/IPolygon';
import { IScatterPlot } from '../models/IScatterPlot';
import { IPolyline } from '../models/IPolyline';
import { IQuadratic } from '../models/IQuadratic';
import { ILongOperation } from '../models/longOperations/ILongOperation';
import { IFunction } from '../models/IFunction';
import { IInput } from '../models/IInput';
import { IInputParam } from '../models/input/IInputParam';
import { IInputConstruct } from '../models/input/IInputConstruct';
import { IInputRow } from '../models/input/IInputRow';
import { ITimeOfDay } from '../models/ITimeOfDay';
import { IFiniteSet } from '../models/IFiniteSet';
import { ITableModel } from '../models/ITableModel';

export class VariableWrapper {
  private readonly env: Environment;
  private readonly value?: ContentElement;
  private readonly variable?: Variable;
  private argumentsObj: ArgumentsObject;

  constructor(env: Environment, variable?: Variable, value?: ContentElement) {
    this.env = env;
    this.variable = variable;
    this.value = value;

    if (variable) {
      this.argumentsObj =
        new ArgumentsObject(
          variable.toLeaf(false) !== null ?
            [variable.toLeaf(false)] :
            [],
          env,
          true);
    } else if (value) {
      this.argumentsObj = new ArgumentsObject([value], env, true);
    } else {
      this.argumentsObj = new ArgumentsObject([], env, true);
    }
  }

  public getVariable(): Variable {
    return this.variable;
  }

  public getValue(): ContentElement {
    return this.value;
  }

  public getEnvironment(): Environment {
    return this.env;
  }

  public getElement(): ContentElement {
    return this.argumentsObj.length > 0
      ? this.argumentsObj.getContentElement(0)
      : null;
  }

  public isValid(): boolean {
    return this.getElement() != null;
  }

  public getError(): string {
    if (this.argumentsObj.length === 0) {
      return null;
    }
    const err = this.argumentsObj.getError(0);
    if(err !== null){
      return err.getMessage();
    }
    return null;
  }

  /**
   * @defaultValue: return value when variable is not a WBoolean token.
   */
  public getBoolean(defaultValue: boolean = false): boolean {
    if (this.argumentsObj.length === 0) {
      return defaultValue;
    }
    return this.argumentsObj.getBoolean(0) ?
      this.argumentsObj.getBoolean(0).toBoolean() :
      defaultValue;
  }

  public getString(): string {
    if (this.argumentsObj.length === 0) {
      return null;
    }
    const str = this.argumentsObj.getString(0);
    if(str !== null){
      return this.formatString(str.getString());
    }
    return null;
  }

  public getStrings(): ReadonlyArray<string> {
    if (this.argumentsObj.length === 0) {
      return null;
    }
    const str = this.argumentsObj.getStrings(0);
    if (str !== null) {
      return str.toStrings().map((s: string) => this.formatString(s));
    }
    return null;
  }

  public getListOfListOfString(): ReadonlyArray<ReadonlyArray<string>> {
    if (this.argumentsObj.length === 0) {
      return null;
    }
    const str = this.argumentsObj.getListOfListOfString(0);
    if (str !== null) {
      return str.toArrayOfArray().map((strings: string[]) => strings.map((s: string) => this.formatString(s)));
    }
    return null;
  }

  public getNumber(): ILabeledValue<number> {
    if (this.argumentsObj.length === 0) {
      return null;
    }
    const number = this.argumentsObj.getReal(0);
    if (number === null) {
      return null;
    }
    return {
      label: this.getLabel(),
      value: number.toNumber(),
    };
  }

  /**
   * Returns a value if it's an integer.
   *
   * -integers = { ..., -5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, ... }
   * -negative integers = { ..., -5, -4, -3, -2, -1 }
   * -positive integers = { 1, 2, 3, 4, 5, ... }
   * -non-negative integers = { 0, 1, 2, 3, 4, 5, ... }
   */
  public getInteger(domain: 'integers' | 'negative' | 'positive' | 'non-negative' = 'integers'): ILabeledValue<number> {
    if (this.argumentsObj.length === 0) {
      return null;
    }
    const integer = this.argumentsObj.getInteger(0);
    if (integer === null) {
      return null;
    }

    const n = integer.toNumber();

    if(domain === 'negative' && n >= 0) {
      return null;
    }
    if(domain === 'positive' && n <= 0) {
      return null;
    }
    if(domain === 'non-negative' && n < 0) {
      return null;
    }

    return {
      label: this.getLabel(),
      value: integer.toNumber(),
    };
  }

  /**
   * Returns the label of the list and the numeric values of each element.
   *
   * label: label of the list of numbers
   * values: numeric value of each element of the list
   * labels: formatted string for each element of the list
   * @returns {any}
   */
  public getNumbers(): ILabeledValues<number> {
    if (this.argumentsObj.length === 0) {
      return null;
    }
    const numbers = this.argumentsObj.getReals(0);
    if (numbers === null) {
      return null;
    }
    return {
      label: this.getLabel(),
      values: numbers.toNumbersV(),
      labels: numbers.items.map((n) => this.getLabelForElement(n)),
    };
  }

  public getListOfListOfNumber(): ReadonlyArray<ILabeledValues<number>> {
    if (this.argumentsObj.length === 0) {
      return null;
    }
    const numbersList = this.argumentsObj.getListOfListOfReals(0);
    if (numbersList === null) {
      return null;
    }
    const array: Array<ILabeledValues<number>> = [];
    for (let i = 0; i < numbersList.count; i++) {
      const numbers = numbersList.getTypedItemAt<WList>(i);
      array.push({
        label: this.getLabel(),
        values: numbers.toNumbersV(),
        labels: numbers.items.map((n) => this.getLabelForElement(n)),
      });
    }
    return array;
  }

  public getPoint(): ILabeledValue<IVector2d> {
    if (this.argumentsObj.length === 0) {
      return null;
    }
    const point = this.argumentsObj.getPoint(0);
    if (point === null) {
      return null;
    }
    return {
      label: this.getLabel(),
      value: {
        x: point.x.toNumber(),
        y: point.y.toNumber(),
      },
    };
  }

  public getPoints(): ILabeledValues<IVector2d> {
    if (this.argumentsObj.length === 0) {
      return null;
    }
    const points = this.argumentsObj.getPoints(0);
    if (points === null) {
      return null;
    }
    return {
      label: this.getLabel(),
      values: points.toPoints(),
      labels: points.items.map((p) => this.getLabelForElement(p)),
    };
  }

  public getInterval(): ILabeledValue<IIntervalModel> {
    if (this.argumentsObj.length === 0) {
      return null;
    }
    const interval = this.argumentsObj.getInterval(0);
    if (interval === null) {
      return null;
    }
    return {
      label: this.getLabel(),
      value: this.mapInterval(interval)
    };
  }

  public getIntervals(): ILabeledValues<IIntervalModel> {
    if (this.argumentsObj.length === 0) {
      return null;
    }
    const intervals = this.argumentsObj.getIntervals(0);
    if (intervals === null) {
      return null;
    }

    return {
      label: this.getLabel(),
      values: intervals.toIntervals().map((interval) => this.mapInterval(interval)),
      labels: intervals.items.map((i) => this.getLabelForElement(i)),
    };
  }

  public getMatrix(listConversion: 'none' | 'row' | 'column' = 'row'): ILabeledValueWithLabels<IMatrixModel> {
    return this.getMatrixImpl(false, listConversion);
  }

  public getMatrixWithLabels(listConversion: 'none' | 'row' | 'column' = 'row'): ILabeledValueWithLabels<IMatrixModel> {
    return this.getMatrixImpl(true, listConversion);
  }

  public getTable(): ITableModel {
    if (this.argumentsObj.length === 0) {
      return null;
    }
    const table = this.argumentsObj.getTable(0);
    if (table === null) {
      return null;
    }

    const nbColumns = table.columns.length;
    const columnItems = table.columns.map((c) => c.items);
    const nbRows = Math.max(...columnItems.map((c) => c.length));

    const rows = Array.from({ length: nbRows }).map((_, rowIndex) => {
      return Array.from({ length: nbColumns }).map((__, columnIndex) => {
        const cell = columnItems[columnIndex]?.[rowIndex];
        if (!cell) {
          return null;
        }
        return new VariableWrapper(this.env, null, cell);
      });
    });

    return {
      nbColumns,
      nbRows,
      rows,
    };
  }

  public getRange(): ILabeledValue<IRangeModel> {
    if (this.argumentsObj.length === 0) {
      return null;
    }
    const range = this.argumentsObj.getRange(0);
    if (range === null) {
      return null;
    }
    return {
      label: this.getLabel(),
      value: {
        minimum: range.minimum,
        maximum: range.maximum,
        step: range.step,
        cardinal: range.getCardinal(),
      }
    };
  }

  public getRangeValues(startIndex: number = 0, endIndex: number = Number.MAX_SAFE_INTEGER): ILabeledValues<number> {
    if (this.argumentsObj.length === 0) {
      return null;
    }
    const range = this.argumentsObj.getRange(0);
    if (range === null) {
      return null;
    }

    const items = range.toItems().slice(startIndex, endIndex);
    return {
      label: this.getLabel(),
      values: items.map((item) => (item as RealElement).toNumber()),
      labels: items.map((n) => this.getLabelForElement(n)),
    };
  }

  public getSymbol():ILabeledValue<string>{
    if (this.argumentsObj.length === 0) {
      return null;
    }
    const symbol = this.argumentsObj.getSymbol(0);
    if (symbol === null) {
      return null;
    }
    return {
      label: this.getLabel(),
      value: symbol.getSymbol()
    };
  }

  public getSegment(): ILabeledValue<IEndPoints>{
    if (this.argumentsObj.length === 0) {
      return null;
    }
    const segment = this.argumentsObj.getSegment(0);
    if (segment === null) {
      return null;
    }
    return {
      label: this.getLabel(),
      value: {
        a: segment.a,
        b: segment.b
      }
    };
  }

  public getBoundVector(): ILabeledValue<IEndPoints>{
    if (this.argumentsObj.length === 0) {
      return null;
    }
    const boundVector = this.argumentsObj.getBoundVector(0);
    if (boundVector === null) {
      return null;
    }
    return {
      label: this.getLabel(),
      value: {
        a: boundVector.a,
        b: boundVector.b
      }
    };
  }

  public getHalfPlane(): ILabeledValue<IHalfPlane> {
    if (this.argumentsObj.length === 0) {
      return null;
    }
    const halfPlane = this.argumentsObj.getHalfPlane(0);
    if (halfPlane === null) {
      return null;
    }
    return {
      label: this.getLabel(),
      value: {
        op: halfPlane.op,
        line: {
          A: halfPlane.line.A.toNumber(),
          B: halfPlane.line.B.toNumber(),
          C: halfPlane.line.C.toNumber(),
          p0: halfPlane.line.p0 ? halfPlane.line.p0.toPoint() : null,
          p1: halfPlane.line.p1 ? halfPlane.line.p1.toPoint() : null,
        }
      }
    };
  }

  public getLine(): ILabeledValue<ILine> {
    if (this.argumentsObj.length === 0) {
      return null;
    }
    const line = this.argumentsObj.getLine(0);
    if (line === null) {
      return null;
    }
    return {
      label: this.getLabel(),
      value: {
        A: line.A.toNumber(),
        B: line.B.toNumber(),
        C: line.C.toNumber(),
        p0: line.p0 ? line.p0.toPoint() : null,
        p1: line.p1 ? line.p1.toPoint() : null,
      }
    };
  }

  public getQuadratic(): ILabeledValue<IQuadratic> {
    if (this.argumentsObj.length === 0) {
      return null;
    }
    const quad = this.argumentsObj.getQuadratic(0);
    if (quad === null) {
      return null;
    }
    return {
      label: this.getLabel(),
      value: {
        A: quad.A.toNumber(),
        B: quad.B.toNumber(),
        C: quad.C.toNumber(),
        p0: quad.p0 ? quad.p0.toPoint() : null,
        p1: quad.p1 ? quad.p1.toPoint() : null,
        p2: quad.p2 ? quad.p2.toPoint() : null,
      }
    };
  }

  public getPolygon(): ILabeledValue<IPolygon> {
    if (this.argumentsObj.length === 0) {
      return null;
    }
    const polygon = this.argumentsObj.getPolygon(0);
    if (polygon === null) {
      return null;
    }
    return {
      label: this.getLabel(),
      value: {
        vertices: polygon.vertices
      }
    };
  }

  public getPolyline(): ILabeledValue<IPolyline> {
    if (this.argumentsObj.length === 0) {
      return null;
    }
    const polyline = this.argumentsObj.getPolyline(0);
    if (polyline === null) {
      return null;
    }
    return {
      label: this.getLabel(),
      value: {
        vertices: polyline.vertices
      }
    };
  }

  public getScatterPlot(): ILabeledValue<IScatterPlot> {
    if (this.argumentsObj.length === 0) {
      return null;
    }
    const scatterPlot = this.argumentsObj.getScatterPlot(0);
    if (scatterPlot === null) {
      return null;
    }
    return {
      label: this.getLabel(),
      value: {
        data: scatterPlot.data.toPoints()
      }
    };
  }

  public getInput(parameters: IDictionary): ILabeledValue<IInput> {
    if (this.argumentsObj.length === 0) {
      return null;
    }
    const input = this.argumentsObj.getInput(0);
    if (input === null) {
      return null;
    }
    const structure = this.getInputRow(input.adapter.getTokens(parameters));
    return {
      label: this.getLabel(),
      value: {
        structure,
      }
    };
  }

  public getFunction(): ILabeledValue<IFunction> {
    if (this.argumentsObj.length === 0) {
      return null;
    }
    const _function = this.argumentsObj.getFunction(0);
    if (_function === null) {
      return null;
    }
    return {
      label: this.getLabel(),
      value: {
        varName: _function.varName,
      }
    };
  }

  public getFunctionGraph(): ILabeledValue<IFunctionGraph> {
      if (this.argumentsObj.length === 0) {
          return null;
      }
      const functionGraph = this.argumentsObj.getFunctionGraph(0);
      if (functionGraph === null) {
          return null;
      }

      const hashCode = functionGraph.hashCode();
      const { adapter,  axis,  op, domain } = functionGraph;
      const { continuous, limit, type, period, constantPiece } = adapter;

      return {
          label: this.getLabel(),
          value: {
            hashCode,
            continuous,
            limit,
            axis,
            op,
            type,
            domain: this.mapInterval(domain),
            period: this.mapInterval(period),
            constantPiece: this.mapInterval(constantPiece),
            map: (value: number) => adapter.map(value),
            previous: (value: number) => adapter.previous(value),
            next: (value: number) => adapter.next(value),
            piecesInRange: (range: IIntervalModel) => {
              const range2 =
                  this.env.culture.intervalsFactory.createIntervalN(
                    IntervalClosure.parse(
                      range.lIncluded,
                      range.rIncluded),
                    range.lBound,
                    range.rBound);
              const pieces = adapter.piecesInRange(range2);
              return pieces ? pieces.map((value: WInterval) => this.mapInterval(value)) : null;
            }
          }
      };
  }

  public getTimeOfDay(): ILabeledValue<ITimeOfDay> {
    if (this.argumentsObj.length === 0) {
      return null;
    }

    const time = this.argumentsObj.getTimeOfDay(0);
    if (time === null) {
      return null;
    }

    return {
      label: this.getLabel(),
      value: {
        hours: time.hours,
        minutes: time.minutes,
        seconds: time.seconds,
        useTwelveHoursClock: time.formatter.useTwelveHoursClock()
      }
    };
  }

  public getLongOperation(withLabel: boolean = false): ILabeledValue<ILongOperation> {
    if (this.argumentsObj.length === 0) {
      return null;
    }

    const longOperation = this.argumentsObj.getLongOperation(0);
    if (longOperation === null) {
      return null;
    }

    const operation = longOperation.operation;
    const compartments = operation.compartments.source;
    const steps = operation.operations.source;

    return {
      label: withLabel ? this.getLabel() : null,
      value: {
        error: operation.error,
        compartments: compartments.map((c) => {
          return {
            type: c.type,
            row: c.row,
            column: c.column,
            frame: {
              left: c.frame ? c.frame.left : false,
              right: c.frame ? c.frame.right : false,
              top: c.frame ? c.frame.top : false,
              bottom: c.frame ? c.frame.bottom : false,
            },
            value: c.value,
            text: c.text,
            faded: c.faded,
          };
        }),
        steps: steps.map((o) => {
          return {
            description: o.description,
            source: o.source ? o.source.map((c: number) => compartments.indexOf(c)) : [],
            source2: o.source2 ? o.source2.map((c: number) => compartments.indexOf(c)) : [],
            related: o.related ? o.related.map((c: number) => compartments.indexOf(c)) : [],
            target: o.target ? o.target.map((c: number) => compartments.indexOf(c)) : [],
            select: o.select ? o.select.concat() : [],
            lines: o.lines ? o.lines.map((l: any) => l.row) : [],
            targetText: o.targetText,
            targetPosition: o.targetPosition,
          };
        })
      }
    };
  }

  public getFiniteSet():ILabeledValueWithLabels<IFiniteSet> {
    if (this.argumentsObj.length === 0) {
      return null;
    }

    const finiteSet = this.argumentsObj.getFiniteSet(0);
    if (finiteSet === null) {
      return null;
    }

    return {
      label: this.getLabel(),
      value: {
        cardinal: finiteSet.cardinal
      },
      labels: finiteSet.toElements().map((v) => this.getLabelForElement(v))
    };
  }

  /**
   * Break collection into an array of VariableWrapper, one for each item.
   *
   * Works with finiteSet.
   */
  public breakApart(): ReadonlyArray<VariableWrapper> {
    if (this.argumentsObj.length === 0) {
      return null;
    }

    const finiteSet = this.argumentsObj.getFiniteSet(0);
    if (finiteSet !== null) {
      return finiteSet.toElements().map((element: TokenElement) => {
        return new VariableWrapper(this.env, null, element);
      });
    }

    return null;
  }

  /**
   * If the element is a list, then return the labels of each item in the list.
   * Otherwise, simply return the label for this element.
   */
  public getLabels(withTtsMetadata: boolean = true): ReadonlyArray<string> {
    if (this.argumentsObj.length === 0) {
      return null;
    }
    const list = this.argumentsObj.getList(0);
    if (list) {
      return list.items.map((element) => this.getLabelForElement(element, withTtsMetadata));
    }
    return [this.getLabel(withTtsMetadata)];
  }

  /**
   * Returns the MathML representation for this variable even
   * if there's an alternative markup (html, plain text).
   */
  public getMathML(): string {
    const writer: MmlWriter = new MmlWriter(new MmlXmlProxy());
    if (this.variable !== null) {
      this.variable.writeTo(writer);
    } else if (this.value !== null) {
      const format = new FormatProvider(this.env.culture);
      const exporter = new MarkupExporter(new MmlWriter(new MmlXmlProxy()), format);
      this.value.writeTo(exporter);
    }
    return writer.content ? writer.content.outerHTML : null;
  }

  /**
   * Returns a string representation of the variable
   * that can be used to display as text.
   *
   * The returned value could be:
   * 1. plain text,
   * 2. Html markup,
   * 3. MathML markup.
   *
   * The simplest form available will be returned.
   */
  public getLabel(withTtsMetadata: boolean = true): string {
    if (this.variable === null) {
      if (this.value !== null) {
        return this.getLabelForElement(this.value, withTtsMetadata);
      }
      return '';
    }

    let dataTtsText = null;
    let dataTtsXml = null;

    let label = this.variable.toEncodedText();
    if(label === null){
      label = this.variable.toHtml(false);
      if(label === null){
        label = this.variable.toHtml(true);
        if(label === null || withTtsMetadata){
          const writer: MmlWriter = new MmlWriter(new MmlXmlProxy());
          this.variable.writeTo(writer);
          if (label === null) {
            label = writer.content ? writer.content.outerHTML : '';
          } else {
            dataTtsXml = writer.content ? writer.content.outerHTML : null;
          }
        }
      }
    }else{
      label = this.variable.format.translateString(label);
    }

    if(withTtsMetadata){
      dataTtsText = this.validateSpeakText(this.variable.toSpeakText());
      label = this.tryApplyTtsMetadata(label, dataTtsText, dataTtsXml);
    }

    return label;
  }

  /**
   * Return null when speak text is XML.
   * @param value
   */
  private validateSpeakText(value: string):string{
    if (!value) {
      return value;
    }
    if(value.indexOf('<') === -1 && value.indexOf('>') === -1){
      return value;
    }
    return /<[a-z]+>/.test(value) ? null : value;
  }

  /**
   * Apply formatting rules on a string:
   * -capitalize,
   * -feminize (fr)
   * @param value
   */
  private formatString(value: string): string {
    if (this.variable !== null) {
      return this.variable.format.translateString(value);
    }
    return value;
  }

  private getMatrixImpl(withLabels: boolean, listConversion: 'none' | 'row' | 'column' = 'row'): ILabeledValueWithLabels<IMatrixModel> {
    if (this.argumentsObj.length === 0) {
      return null;
    }
    const matrix = this.argumentsObj.getMatrix(0);
    if (matrix === null) {
      const list = this.argumentsObj.getReals(0);
      if(list !== null && listConversion !== 'none'){
        return {
          label: this.getLabel(),
          value: {
            columns: listConversion === 'row' ? list.count : 1,
            values: list.toNumbersV(),
          },
          labels: withLabels ? list.items.map((n) => this.getLabelForElement(n)) : null,
        };
      }
      return null;
    }
    return {
      label: this.getLabel(),
      value: {
        columns: matrix.columns,
        values: matrix.values.map((v) => v.toNumber())
      },
      labels: withLabels ? matrix.values.map((v) => this.getLabelForElement(v)) : null,
    };
  }

  private mapInterval(value: WInterval): IIntervalModel {
    if(!value) {
      return null;
    }
    return {
        lIncluded: value.closure.lower,
        rIncluded: value.closure.upper,
        lBound: value.lBound === null ? Number.NEGATIVE_INFINITY : value.lBound.toNumber(),
        rBound: value.rBound === null ? Number.POSITIVE_INFINITY : value.rBound.toNumber(),
    };
  }

  private getLabelForElement(element: ContentElement, withTtsMetadata: boolean = true): string {
    if (element === null) {
      return '';
    }

    let dataTtsText = null;
    let dataTtsXml = null;

    let label = element.toEncodedText(true);
    if(label === null){
      const format = new FormatProvider(this.env.culture);
      label = HtmlExporter.toString(element, format, false);
      if(label === null){
        label = HtmlExporter.toString(element, format, true);
        if(label === null || withTtsMetadata){
          const exporter = new MarkupExporter(new MmlWriter(new MmlXmlProxy()), format);
          element.writeTo(exporter);
          if(label === null){
            label = exporter.writer.content ? exporter.writer.content.outerHTML : '';
          }else{
            dataTtsXml = exporter.writer.content ? exporter.writer.content.outerHTML : null;
          }
        }
      }
    }

    if(withTtsMetadata){
      dataTtsText = this.validateSpeakText(element.toSpeakText());
      label = this.tryApplyTtsMetadata(label, dataTtsText, dataTtsXml);
    }

    return label;
  }

  private tryApplyTtsMetadata(text: string, dataTtsText: string, dataTtsXml: string): string {
    const escapeXml = (value: string) => {
      return value.replace(/"/g, '&quot;')
                  .replace(/'/g, '&apos;')
                  .replace(/</g, '&lt;')
                  .replace(/>/g, '&gt;')
                  .replace(/&/g, '&amp;');
    };

    if (dataTtsText) {
      return `<span data-tts-text="${escapeXml(dataTtsText)}">${text}</span>`;
    }
    if(dataTtsXml){
      return `<span data-tts-xml="${escapeXml(dataTtsXml)}">${text}</span>`;
    }
    return text;
  }

  private getInputRow(tokens: any[]): IInputRow {
    return {
      children: tokens.map((token) => this.mapToken(token))
    };
  }

  private mapToken(token: any): IInputRow | IInputConstruct | IInputParam | string {
    if(Array.isArray(token)){
      return this.getInputRow(token);
    }
    if (token instanceof MParam) {
      return {
        type: token.type as 'reals' | 'positive',
        name: token.name,
        value: token.value ? token.value.toNumber() : NaN,
        fractionValue: token.value instanceof WRational ? {
          numerator: token.value.numerator,
          denominator: token.value.denominator
        } : {
          numerator: token.value.toNumber(),
          denominator: 1
        },
        emptyValue: token.emptyValue ? token.emptyValue.toNumber() : NaN,
        minusValue: token.minusValue ? token.minusValue.toNumber() : NaN,
      };
    }
    if(token instanceof MConstruct) {
      return {
        type: token.type as 'frac' | 'sup' | 'sub' | 'sqrt' | 'fence',
        children: token.args.map((arg) => this.mapToken(arg)),
        open: token.open,
        close: token.close,
      };
    }
    return String(token);
  }
}
